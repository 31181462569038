import React, {useRef, useState} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import type {ColumnType} from 'antd/es/table';
import type {FilterConfirmProps} from 'antd/es/table/interface';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	ProjectProcessType,
	type ProjectProcessMaster,
} from '../ProjectProcessMaster/project-process-master';
import {isMobile, toTitleCase, formatDateFunction, parseNum} from 'app/helpers';
import {get, setFilterValue, stop, create, update, updateAll, addAll, restart, restartAll, stopAll} from './actions';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {lbBaseUrl, parentTab, uiPaths} from 'app/constants';
import moment from 'moment';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {
	Button,
	Col,
	Modal,
	message,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Typography,
	Popconfirm,
	DatePicker,
	Input,
	Upload,
} from 'antd';
import {
	type ProjectProcessSchedule,
	ProjectScheduleStatus,
} from '../ProjectProcessSchedule/project-process-schedule';
import {getParents} from '../ProjectProcessMaster/actions';
import {getAuthToken} from 'app/services/common-service';
import {type DrawingSchedule, DrawingStatusType, type WorkOrderSchedule, WorkOrderScheduleStatus} from 'app/models';

type DataType = {
	id: number;
	processName: string;
	parentName: string;
	projectProcessType: string;
	schedule?: ProjectProcessSchedule | DrawingSchedule | WorkOrderSchedule;
	startDate?: string;
	endDate?: string;
	projectedStartDate?: string;
	projectedEndDate?: string;
	key?: string;
};

type DataIndex = keyof DataType;

export const ViewScheduleManualEntry: React.FC = () => {
	const dispatch = useDispatch();
	const {allIds, byIds, loading, filterValue, dataUpdated}
		= useSelector((state: Types.RootState) => state.scheduleManualEntry);
	const {parents} = useSelector(
		(state: Types.RootState) => state.projectProcessMaster,
	);
	const {allSites: sites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [updateId, setUpdateId] = React.useState<number>(0);
	const [showUpdateModal, setShowUpdateModal] = React.useState<boolean>(false);
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const searchInput = useRef<any>(null);

	const typeOptions = Object.values(ProjectProcessType).map((status: string) => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	const {RangePicker} = DatePicker;
	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const parentOptions: FilterOptionItem[] = parents?.length
		? parents.map(parent => ({
			value: String(parent?.id),
			label: parent.processName ?? '',
		}))
		: [];

	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewScheduleManualEntry.componentDidMount');
		dispatch(
			getParents({
				where: {
					projectProcessType: ProjectProcessType.DESIGN,
					processName: {
						inq: [parentTab.drawings, parentTab.quantity, parentTab.designProgressTracker],
					},
				},
			}),
		);
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewScheduleManualEntry.componentDidMount');
		if (filterValue) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	const filterData = (f: FilterDataType = {}) => {
		if (!f.siteId || f.ProjectProcessType) {
			return;
		}

		const filter: any = {
			where: {},
		};

		filter.where = {
			siteId: f.siteId,
			projectProcessType: f.projectProcessType,
		};
		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	const canAddAll = (selectedRowKeys?: React.Key[]) => {
		if (!selectedRowKeys?.length) {
			return false;
		}

		for (const key of selectedRowKeys) {
			const item = byIds[parseNum(key)];
			if (
				item.projectSchedule
				?? item.drawingSchedule
				?? item.orderSchedule
			) {
				return false;
			}
		}

		return true;
	};

	const canUpdateAll = (selectedRowKeys?: React.Key[]) => {
		if (!selectedRowKeys?.length) {
			return false;
		}

		for (const key of selectedRowKeys) {
			const item = byIds[parseNum(key)];
			if (
				(!item.projectSchedule || item.projectSchedule?.status === ProjectScheduleStatus.STOPPED)
				&& (!item.drawingSchedule || item.drawingSchedule?.status === DrawingStatusType.STOPPED)
				&& (!item.orderSchedule || item.orderSchedule?.status === WorkOrderScheduleStatus.STOPPED)
			) {
				return false;
			}
		}

		return true;
	};

	const canStopAll = (selectedRowKeys?: React.Key[]) => {
		if (!selectedRowKeys?.length) {
			return false;
		}

		for (const key of selectedRowKeys) {
			const item = byIds[parseNum(key)];
			if (
				(!item.projectSchedule || item.projectSchedule?.status === ProjectScheduleStatus.STOPPED)
				&& (!item.drawingSchedule || item.drawingSchedule?.status === DrawingStatusType.STOPPED)
				&& (!item.orderSchedule || item.orderSchedule?.status === WorkOrderScheduleStatus.STOPPED)
			) {
				return false;
			}
		}

		return true;
	};

	const canRestartAll = (selectedRowKeys?: React.Key[]) => {
		if (!selectedRowKeys?.length) {
			return false;
		}

		for (const key of selectedRowKeys) {
			const item = byIds[parseNum(key)];
			if (
				(!item.projectSchedule || item.projectSchedule?.status !== ProjectScheduleStatus.STOPPED)
				&& (!item.drawingSchedule || item.drawingSchedule?.status !== DrawingStatusType.STOPPED)
				&& (!item.orderSchedule || item.orderSchedule?.status !== WorkOrderScheduleStatus.STOPPED)
			) {
				return false;
			}
		}

		return true;
	};

	const confirm = (id: number, data) => {
		void message.success('Schedule Stopped !');
		dispatch(stop(id, data));
	};

	const confirmRestart = (id: number, data) => {
		void message.success('Schedule Restarted !');
		dispatch(restart(id, data));
	};

	const onAddConfirm = data => {
		void message.success('Schedule Added!');
		dispatch(create(data));
	};

	const onUpdateClicked = () => {
		const data: any = {
			projectProcessType: filterValue.projectProcessType,
			startDate:
				startDate
					? moment(startDate).format('YYYY-MM-DDTHH:mm:ss.sssZ').toString()
					: '',
		};
		if (
			filterValue.projectProcessType === ProjectProcessType.APPROVALS
			|| filterValue.projectProcessType === ProjectProcessType.CONSTRUCTION
		) {
			data.endDate
				= endDate
					? moment(endDate).format('YYYY-MM-DDTHH:mm:ss.sssZ').toString()
					: '';
		}

		if (updateId) {
			dispatch(update(updateId, data));
			setUpdateId(0);
		} else if (selectedRowKeys?.length && filterValue.siteId) {
			dispatch(updateAll(parseNum(filterValue.siteId as string), selectedRowKeys.map(key => parseNum(key)), data));
			setSelectedRowKeys([]);
		}

		setShowUpdateModal(false);
		setStartDate('');
		setEndDate('');
	};

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'projectProcessType',
			items: typeOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status Tab Type',
			label: 'Status Tab Type',
			defaultValue: filterValue ? filterValue.projectProcessType : '',
		},
		{
			filterKey: 'parentId',
			items: parentOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Parent',
			label: 'Parent',
			defaultValue: filterValue ? filterValue.parentId : '',
		},
	];

	const handleSearch = (
		selectedKeys: string[],
		confirm: (param?: FilterConfirmProps) => void,
		dataIndex: DataIndex,
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (
		dataIndex: DataIndex,
	): ColumnType<DataType> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{padding: 8}} onKeyDown={e => {
				e.stopPropagation();
			}}>
				<Input
					ref={searchInput}
					placeholder={`Search ${toTitleCase(dataIndex)}`}
					value={selectedKeys[0]}
					onChange={e => {
						setSelectedKeys(e.target.value ? [e.target.value] : []);
					}
					}
					onPressEnter={() => {
						handleSearch(selectedKeys as string[], confirm, dataIndex);
					}
					}
					style={{marginBottom: 8, display: 'block'}}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => {
							handleSearch(selectedKeys as string[], confirm, dataIndex);
						}
						}
						icon={<SearchOutlined />}
						size='small'
						style={{width: 90}}
					>
						Search
					</Button>
					<Button
						onClick={() => {
							if (clearFilters) {
								handleReset(clearFilters);
							}
						}}
						size='small'
						style={{width: 90}}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
		),
		onFilter: (value, record) =>
			record[dataIndex]?.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase()) ?? false,
		onFilterDropdownVisibleChange(visible) {
			if (visible) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: text => text as string,
	});

	let data: ProjectProcessMaster[] = allIds?.length
		? allIds.map(id => byIds[id])
		: [];

	if (filterValue.parentId) {
		data = data.filter(item => item.parentId === parseNum(filterValue.parentId as string));
	}

	const dataSource: DataType[] = data
		.filter(
			entry =>
				entry.processName !== parentTab.drawings
				&& entry.processName !== parentTab.quantity
				&& entry.processName !== parentTab.designProgressTracker,
		)
		.map(entry => {
			let schedule: ProjectProcessSchedule | DrawingSchedule | WorkOrderSchedule | undefined;
			let startDate: string | undefined;
			let endDate: string | undefined;
			let projectedStartDate: string | undefined;
			let projectedEndDate: string | undefined;
			if (
				filterValue.projectProcessType as string === ProjectProcessType.APPROVALS as string
				|| filterValue.projectProcessType === ProjectProcessType.CONSTRUCTION
			) {
				schedule = entry.projectSchedule;
				startDate = entry.projectSchedule?.startDate;
				endDate = entry.projectSchedule?.endDate;
				projectedStartDate = entry.projectSchedule?.projectedStartDate;
				projectedEndDate = entry.projectSchedule?.projectedEndDate;
			} else if (filterValue.projectProcessType === ProjectProcessType.DESIGN) {
				schedule = entry.drawingSchedule;
				startDate = entry.drawingSchedule?.scheduleDate;
			} else if (filterValue.projectProcessType === ProjectProcessType.ORDER) {
				schedule = entry.orderSchedule;
				startDate = entry.orderSchedule?.scheduleDate;
			}

			return {
				id: entry.id,
				key: String(entry.id),
				processName: entry.processName,
				parentName: entry.parentProcessMaster?.processName ?? '',
				projectProcessType: entry.projectProcessType,
				schedule,
				startDate,
				endDate,
				projectedStartDate,
				projectedEndDate,
			};
		});

	return (
		<DefaultLayout currentPath={uiPaths.scheduleManualEntry}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Process Schedule
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f1: FilterDataType) => {
								filterData(f1);
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col span={24} className='mb-10'>
						<Space>
							<Popconfirm
								title={`Are you sure you want to add all ${selectedRowKeys.length} schedules?`}
								onConfirm={() => {
									dispatch(addAll(parseNum(filterValue.siteId as string), selectedRowKeys.map(key => parseNum(key))));
									setSelectedRowKeys([]);
								}}
								okText='Yes'
								cancelText='No'
							>
								<Button
									type={'primary'}
									disabled={!canAddAll(selectedRowKeys)}
								>
									Add All
								</Button>
							</Popconfirm>
							<Popconfirm
								title={`Are you sure you want to update all ${selectedRowKeys.length} schedules?`}
								onConfirm={() => {
									setShowUpdateModal(true);
								}
								}
								okText='Yes'
								cancelText='No'
							>
								<Button
									type={'primary'}
									disabled={!canUpdateAll(selectedRowKeys)}
								>
									Update All
								</Button>
							</Popconfirm>
							<Popconfirm
								title={`Are you sure you want to restart all ${selectedRowKeys.length} schedules?`}
								onConfirm={() => {
									dispatch(restartAll(parseNum(filterValue.siteId as string), selectedRowKeys.map(key => parseNum(key)), {
										projectProcessType: filterValue.projectProcessType as string,
									}));
									setSelectedRowKeys([]);
								}}
								okText='Yes'
								cancelText='No'
							>
								<Button
									type={'primary'}
									disabled={!canRestartAll(selectedRowKeys)}
								>
									Restart All
								</Button>
							</Popconfirm>
							<Popconfirm
								title={`Are you sure you want to stop all ${selectedRowKeys.length} schedules?`}
								onConfirm={() => {
									dispatch(stopAll(parseNum(filterValue.siteId as string), selectedRowKeys.map(key => parseNum(key)), {
										projectProcessType: filterValue.projectProcessType as string,
									}));
									setSelectedRowKeys([]);
								}}
								okText='Yes'
								cancelText='No'
							>
								<Button
									type={'primary'}
									disabled={!canStopAll(selectedRowKeys)}
								>
									Stop All
								</Button>
							</Popconfirm>

							{filterValue.siteId ? (
								<Upload
									name={'file'}
									action={`${lbBaseUrl}/schedule-manual-entries/${filterValue.siteId as string}/upload`}
									headers={{Authorization: `Bearer ${getAuthToken()}`}}
									onChange={(info: any) => {
										if (info.file.status === 'done') {
											void message.success(`${info.file.name} file uploaded successfully`);
											window.location.reload();
										} else if (info.file.status === 'error') {
											void message.error(`${info.file.name} file upload failed.`);
										}
									}}
									multiple={false}
									// FileList={[]}
								>
									<Button>Upload CSV file</Button>
								</Upload>
							) : []}
						</Space>
					</Col>
				</Row>

				<br />
				{dataSource.length ? (
					<>
						<Table
							size={'small'}
							bordered={true}
							dataSource={dataSource}
							pagination={false}
							rowSelection={{
								selectedRowKeys,
								onChange: setSelectedRowKeys,
							}}
						>
							<Table.Column
								width={500}
								title='Process Name'
								dataIndex='processName'
								key='processName'
								{...getColumnSearchProps('processName')}
							/>
							<Table.Column
								title={<b>Parent</b>}
								dataIndex='parentName'
								key='parentName'
								render={parentName => parentName as string ?? 'N/A'}
								sorter={{
									compare: (a: DataType, b: DataType) =>
										a.parentName > b.parentName ? 1 : -1,
									multiple: 1,
								}}
							/>
							<Table.Column
								width={500}
								title='Projected Start Date'
								dataIndex='projectedStartDate'
								key='projectedStartDate'
								render={(projectedStartDate: string) =>
									projectedStartDate ? formatDateFunction(projectedStartDate, false) : ''
								}
								sorter={{
									compare: (a: DataType, b: DataType) =>
										a.projectedStartDate
											&& b.projectedStartDate
											&& new Date(a.projectedStartDate) > new Date(b.projectedStartDate)
											? 1
											: -1,
									multiple: 1,
								}}
							/>
							<Table.Column
								width={500}
								title='Projected End Date'
								dataIndex='projectedEndDate'
								key='projectedEndDate'
								render={(projectedEndDate: string) =>
									projectedEndDate ? formatDateFunction(projectedEndDate, false) : ''
								}
								sorter={{
									compare: (a: DataType, b: DataType) =>
										a.projectedEndDate
											&& b.projectedEndDate
											&& new Date(a.projectedEndDate) > new Date(b.projectedEndDate)
											? 1
											: -1,
									multiple: 2,
								}}
							/>
							<Table.Column
								width={500}
								title='Start Date'
								dataIndex='startDate'
								key='startDate'
								render={(startDate: string) =>
									startDate ? formatDateFunction(startDate, false) : ''
								}
								sorter={{
									compare: (a: DataType, b: DataType) =>
										a.startDate
											&& b.startDate
											&& new Date(a.startDate) > new Date(b.startDate)
											? 1
											: -1,
									multiple: 1,
								}}
							/>
							<Table.Column
								width={500}
								title='End Date'
								dataIndex='endDate'
								key='endDate'
								render={(endDate: string) =>
									endDate ? formatDateFunction(endDate, false) : ''
								}
								sorter={{
									compare: (a: DataType, b: DataType) =>
										a.endDate
											&& b.endDate
											&& new Date(a.endDate) > new Date(b.endDate)
											? 1
											: -1,
									multiple: 2,
								}}
							/>
							<Table.Column
								title='Action'
								dataIndex='action'
								key='action'
								render={(action: string, item: any) => (
									<Space direction={isMobile ? 'vertical' : 'horizontal'}>
										{item.schedule ? (item.schedule.status && (String(item.schedule.status).toUpperCase() === (ProjectScheduleStatus.STOPPED).toUpperCase()) ? (
											<>
												<Tag color={'red'}>This schedule has been stopped</Tag>
												<Popconfirm
													title='Are you sure you want to restart this schedule?'
													onConfirm={() => {
														confirmRestart(item.schedule.id as number, {
															projectProcessType: item.projectProcessType as string,
														});
													}
													}
													okText='Yes'
													cancelText='No'
												>
													<Button type='link'>Restart</Button>
												</Popconfirm>
											</>
										) : (
											<>
												<Button
													size={'small'}
													type='primary'
													onClick={() => {
														setUpdateId(item.schedule.id as number);
														setShowUpdateModal(true);
													}}
												>
														Update Dates
												</Button>
												<Popconfirm
													title='Are you sure you want to stop this schedule?'
													onConfirm={() => {
														confirm(item.schedule.id as number, {
															projectProcessType: item.projectProcessType as string,
														});
													}
													}
													okText='Yes'
													cancelText='No'
												>
													<Button type='link'>Stop</Button>
												</Popconfirm>
											</>
										)
										) : (
											<Popconfirm
												title='Are you sure you want to add a schedule?'
												onConfirm={() => {
													onAddConfirm({
														siteId: parseNum(filterValue.siteId as string),
														projectProcessMasterId: item.id as number,
														projectProcessType: item.projectProcessType as string,
													});
												}
												}
												okText='Yes'
												cancelText='No'
											>
												<Button type='link'>Add</Button>
											</Popconfirm>
										)}
									</Space>
								)}
							/>
						</Table>
						<Modal
							title='Update dates of this schedule'
							open={showUpdateModal}
							okText={'Update Dates'}
							onOk={onUpdateClicked}
							onCancel={() => {
								setUpdateId(0);
								setShowUpdateModal(false);
								setStartDate('');
								setEndDate('');
							}}
							destroyOnClose
						>
							{filterValue.projectProcessType
								=== ProjectProcessType.APPROVALS
								|| filterValue.projectProcessType
								=== ProjectProcessType.CONSTRUCTION ? (
									<Space direction={'vertical'}>
										<label>Please Select Start Date and End Date </label>
										<RangePicker
											onChange={(date, dateString) => {
												setStartDate(dateString[0]);
												setEndDate(dateString[1]);
											}}
										/>
									</Space>
								) : (
									<Space direction={'vertical'}>
										<label>Please Select a Schedule Date</label>
										<DatePicker
											onChange={(_, dateString) => {
												setStartDate(dateString);
											}}
										/>
									</Space>
								)}
						</Modal>
					</>
				) : (
					[]
				)}
			</Spin>
		</DefaultLayout>
	);
};
