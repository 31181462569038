import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@scheduleManualEntry/API_FILTER',
	getApi: '@scheduleManualEntry/GET_API',
	createApi: '@scheduleManualEntry/CREATE_API',
	updateApi: '@scheduleManualEntry/UPDATE_API',
};

const url = lbBaseUrl + '/schedule-manual-entries';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const stop = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/stop`, data, getHeaders(true)),
	meta: {process: true},
});

export const restart = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/restart`, data, getHeaders(true)),
	meta: {process: true},
});

export const addAll = (siteId: number, ids: number[]) => ({
	type: actionTypes.updateApi,
	payload: axios.post(
		`${url}/add-all?ids=${ids.join(',')}&site_id=${siteId}`,
		{},
		getHeaders(true),
	),
	meta: {process: true},
});

export const updateAll = (siteId: number, ids: number[], data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(
		`${url}/update-all?ids=${ids.join(',')}&site_id=${siteId}`,
		data,
		getHeaders(true),
	),
	meta: {process: true},
});

export const restartAll = (siteId: number, ids: number[], data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(
		`${url}/restart-all?ids=${ids.join(',')}&site_id=${siteId}`,
		data,
		getHeaders(true),
	),
	meta: {process: true},
});

export const stopAll = (siteId: number, ids: number[], data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(
		`${url}/stop-all?ids=${ids.join(',')}&site_id=${siteId}`,
		data,
		getHeaders(true),
	),
	meta: {process: true},
});
