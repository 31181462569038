export type Notification = {
	id: number;
	userId: number;
	title: string;
	content: string;
	notificationType: NotificationType;
	notificationLink?: string;
	isViewed: boolean;
	createdAt?: string;
	createdBy?: number;
};

export enum NotificationType {
	DRAWING_STATUS_CHANGE = 'DRAWING_STATUS_CHANGE',
	DRAWING_STATUS_REVISE = 'DRAWING_STATUS_REVISE',
	SERVICE_TICKET_RAISED = 'SERVICE_TICKET_RAISED',
	SERVICE_TICKET_REASSIGNED = 'SERVICE_TICKET_REASSIGNED',
	SERVICE_TICKET_ESCALATED = 'SERVICE_TICKET_ESCALATED',
	SERVICE_TICKET_REPLIED = 'SERVICE_TICKET_REPLIED',
	QUERY_UNASSIGNED = 'QUERY_UNASSIGNED',
	SERVICE_TICKET_CLOSED = 'SERVICE_TICKET_CLOSED',
	CLIENT_DECISION_CREATED = 'CLIENT_DECISION_CREATED',
	CLIENT_DECISION_SUBMITTED = 'CLIENT_DECISION_SUBMITTED',
	CLIENT_DECISION_APPROVED = 'CLIENT_DECISION_APPROVED',
	SERVICE_TICKET_FOR_DECISION_RAISED = 'SERVICE_TICKET_FOR_DECISION_RAISED',
	MATERIAL_QUERY_RAISED = 'MATERIAL_QUERY_RAISED',
	FEEDBACK = 'FEEDBACK',
	NOTIFICATION = 'NOTIFICATION',
}
