import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Row,
	Select,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {createAll} from './actions';
import {get as getSites} from '../Site/actions';
import {useHistory} from 'react-router';
import {parseNum, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';
import {SiteStatus} from 'app/models';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type WarrantyPeriod} from './warranty-period';
import dayjs from 'dayjs';

export const ViewWarrantyPeriodForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {loading, dataUpdated, errorMessage}
		= useSelector((state: Types.RootState) => state.warrantyPeriod);
	const {byIds: siteByIds, allIds: allSiteIds} = useSelector((state: Types.RootState) => state.site);

	const [siteIds, setSiteIds] = React.useState<string[]>([]);
	const [siteStatus, setSiteStatus] = React.useState<string>('');
	const [name, setName] = React.useState<string>('');
	const [periodInYears, setPeriodInYears] = React.useState<number>(0);
	const [startDate, setStartDate] = React.useState<string>('');

	const allSites = allSiteIds
		.map(id => siteByIds[id]);

	const siteStatusOptions = Object.values(SiteStatus).map(status => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	const warrantyOptions = Array.from({length: 16}, (_, index) => ({
		value: index,
		label: `${index} year${index > 1 ? 's' : ''}`,
	}));

	React.useEffect(() => {
		dispatch(getSites({where: {isLive: true}}));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully!');
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const handleSiteIdsChange = (values: string[]) => {
		// If "selectAll" is in the array, toggle select all options
		const filteredSites = allSites.filter(site =>
			siteStatus ? String(site.status) === siteStatus : true,
		);
		if (values.includes('selectAll')) {
			if (siteIds.length < filteredSites.length) {
				setSiteIds(filteredSites.map(site => String(site.id)));
			} else {
				setSiteIds([]);
			}
		} else {
			setSiteIds(values);
		}
	};

	const onSubmitClicked = () => {
		const data: Array<Omit<WarrantyPeriod, 'id'>> = [];

		if (!name) {
			void message.error('Please enter a category name!');
			return;
		}

		siteIds.forEach(siteId => {
			data.push({
				name,
				siteId: parseNum(siteId),
				periodInYears,
				startDate: startDate ? new Date(startDate) : undefined,
			});
		});

		console.log({data});

		if (!data?.length) {
			void message.error('Please select sites!');
		}

		// C eslint-disable-next-line no-alert
		// if (data?.length > 10 && !confirm(`Are you sure you want to add ${numberWithCommas(data.length)} items. This might take some time to process.`)) {
		// 	return;
		// }

		dispatch(createAll(data));
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<DefaultLayout currentPath={uiPaths.addPermissions}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							User Permission Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
						<Form {...layout} name='basic'>
							<Form.Item label='Site Status'>
								<Select
									style={{width: 400}}
									placeholder='Select a Status'
									onChange={value => {
										setSiteStatus(String(value));
										setSiteIds([]);
									}}
								>
									<Select.Option key='-1' value=''>
										Select All
									</Select.Option>
									{siteStatusOptions.map((option: FilterOptionItem, ix: number) =>
										<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
									)}
								</Select>
							</Form.Item>
							<Form.Item label='Site*'>
								<Select
									mode='multiple'
									style={{width: '100%'}}
									placeholder='Select Sites'
									optionFilterProp='children'
									value={siteIds}
									onChange={handleSiteIdsChange}
								>
									<Select.Option key='selectAll' value='selectAll'>
										Select All
									</Select.Option>
									{allSites.filter(site =>
										siteStatus ? String(site.status) === siteStatus : true,
									).map(site => (
										<Select.Option key={String(site.id)} value={String(site.id)}>
											{site.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label='Category Name*'
								rules={[{required: true, message: 'Please Enter Name'}]}
							>
								<Input
									defaultValue={name}
									value={name}
									onChange={(e: any) => {
										setName(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label='Warranty Started Date'>
								<DatePicker
									value={
										startDate ? dayjs(startDate) : undefined
									}
									onChange={(date, dateString) => {
										setStartDate(dateString);
									}
									}
								/>
							</Form.Item>

							<Form.Item name='durationDays' label='Warranty Period'>
								<Select
									defaultValue={periodInYears}
									onChange={value => {
										setPeriodInYears(value);
									}}
									options={warrantyOptions}
								/>
							</Form.Item>

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
