import type * as actions from './auth-actions';
import {type ActionType} from 'typesafe-actions';
import {actionTypes, logoutSuccessful} from './auth-actions';
import {loadState} from 'app/store/helpers';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';
import {type User} from 'app/models';

export type MyAction = ActionType<typeof actions>;

export type MyState = Readonly<{
	lang: string;
	token: string;
	isAuthenticated: boolean;
	user?: User;
	passwordChanged: boolean;
	passwordReset: boolean;
	loading: boolean;
	errorMessage?: string;
	lastUpdated: boolean;
}>;

const initialState: MyState = {
	lang: 'en',
	token: '',
	isAuthenticated: false,
	user: undefined,
	passwordChanged: false,
	passwordReset: false,
	loading: false,
	errorMessage: '',
	lastUpdated: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
export default function auth(state: MyState = loadState('auth', initialState), action: any) {
	switch (action.type) {
		case actionTypes.initPage:
			return {
				...state,
				...{
					loading: false,
					passwordChanged: false,
					passwordReset: false,
					lastUpdated: false,
					errorMessage: undefined,
				},
			};
		case logoutSuccessful:
			localStorage.removeItem('appToken');
			localStorage.removeItem('appState');
			return initialState;
		case getRequestActionType(actionTypes.loginSuccessful):
		case getRequestActionType(actionTypes.passwordChangedSuccessful):
		case getRequestActionType(actionTypes.passwordReset):
			return {
				...state,
				...{
					loading: true,
					lastUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.loginSuccessful):
		case getFailureActionType(actionTypes.passwordChangedSuccessful):
		case getFailureActionType(actionTypes.passwordReset):
			return {
				...state,
				...{
					errorMessage: String(action.payload?.response?.data?.error?.message) || undefined,
					loading: false,
					lastUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.loginSuccessful):
			localStorage.setItem('appToken', String(action.payload.data.token));
			return {
				...state,
				...{
					token: String(action.payload.data.token),
					isAuthenticated: true,
					errorMessage: undefined,
					loading: false,
				},
			};
		case getSuccessActionType(actionTypes.passwordChangedSuccessful):
			return {
				...state,
				...{
					passwordChanged: true,
					errorMessage: undefined,
					loading: false,
				},
			};
		case getSuccessActionType(actionTypes.passwordReset):
			return {
				...state,
				...{
					passwordReset: true,
					errorMessage: undefined,
					loading: false,
				},
			};
		default:
			return state;
	}
}
