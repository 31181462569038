import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {AttachmentsPreviewBlock} from 'app/components/ProjectProcessSchedule/AttachmentsPreviewBlock';
import {
	Button,
	Col,
	Row,
	Spin,
	Form,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {parseNum} from 'app/helpers';
import {getById} from '../ProjectProcessSchedule/actions';
import {useHistory, useParams} from 'react-router';

type MyParams = {
	id?: string;
};

export const ViewWarrantyDocumentDetail: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();
	const id = parseNum(params?.id ?? '0');
	const {
		byIds,
		loading,
	} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	const schedule = id ? byIds[id] : undefined;

	React.useEffect(() => {
		console.log('ViewWarrantyDocumentDetail.componentDidMount');
		dispatch(getById(id));
	}, []);

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};

	return (
		<DefaultLayout currentPath={uiPaths.warrantyDocumentDetail}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							{schedule?.projectProcessMaster?.processName ?? ''}
						</Typography.Title>
						<Typography.Title level={5} style={{textAlign: 'center'}}>
							{schedule?.site?.name}
						</Typography.Title>
						<br/>
						<br/>
						<Form {...layout} name='basic'>
							<Form.Item label='Warranty Documents'>
								{schedule?.warrantyDocuments?.length ? (
									<AttachmentsPreviewBlock
										attachments={schedule.warrantyDocuments}
										canDelete={false}
										showLabel={true}
									/>
								) : (
									[]
								)}
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
