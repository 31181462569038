// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import packageJson from '../../package.json';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const {version} = packageJson;
const storageKey = `__SERIALIZED_STATE_TREE_v${version}__`;

export function saveState<T = Record<string, unknown>>(storeState: T): boolean {
	if (!localStorage) {
		return false;
	}

	try {
		const serializedState = JSON.stringify(storeState);
		localStorage.setItem(storageKey, serializedState);
		return true;
	} catch (error) {
		throw new Error('store serialization failed');
	}
}

export function loadState<T = Record<string, unknown>>(): T | undefined {
	if (!localStorage) {
		return;
	}

	try {
		const serializedState = localStorage.getItem(storageKey);
		if (serializedState === undefined || serializedState === null) {
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return JSON.parse(serializedState);
	} catch (error) {
		throw new Error('store deserialization failed');
	}
}
