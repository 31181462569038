import React from 'react';
import {Input, Space, Form, Select} from 'antd';
import {FileUpload} from '../Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import {type Drawing} from 'app/models';

type Pops = {
	drawing: Drawing;
	onDrawingChange: (drawing: Drawing) => void;
};

export const DrawingForm: React.FC<Pops> = ({drawing, onDrawingChange}) => {
	const [uploadFile, setUploadFile] = React.useState<UploadFile>();
	const [cadFile, setCadFile] = React.useState<UploadFile>();
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});

	const onFileUploadChange = (fileList: UploadFile[], filePrefixKeys: Record<string, string>) => {
		if (!fileList.length) {
			return;
		}

		const uploadFile = fileList[0];
		setUploadFile(uploadFile);
		setFilePrefixKeys(filePrefixKeys);
		onDrawingChange({...drawing, ...{key: `${filePrefixKeys[uploadFile.uid]}/${uploadFile.name}`}});
	};

	const onCadFileUploadChange = (fileList: UploadFile[], filePrefixKeys: Record<string, string>) => {
		if (!fileList.length) {
			return;
		}

		const cadFile = fileList[0];
		setCadFile(cadFile);
		setFilePrefixKeys(filePrefixKeys);
		onDrawingChange({...drawing, ...{cadKey: `${filePrefixKeys[cadFile.uid]}/${cadFile.name}`}});
	};

	return (
		<Space direction={'vertical'} style={{width: '100%'}}>
			<Form.Item label={'Title*'}>
				<Input
					placeholder={'Enter Title'}
					value={drawing.name}
					onChange={e => {
						onDrawingChange({...drawing, ...{name: e.target.value}});
					}}
				/>
			</Form.Item>
			<Form.Item label={'File'}>
				<FileUpload
					label={'Upload Single File'}
					prefix={'drawing-schedule'}
					single={true}
					fileList={uploadFile ? [uploadFile] : []}
					filePrefixKeys={filePrefixKeys}
					onFileChange={onFileUploadChange}
				/>
			</Form.Item>
			<Form.Item label={'CAD File'}>
				<FileUpload
					label={'Upload Single File'}
					prefix={'drawing-schedule'}
					single={true}
					fileList={cadFile ? [cadFile] : []}
					filePrefixKeys={filePrefixKeys}
					onFileChange={onCadFileUploadChange}
				/>
			</Form.Item>
			<Form.Item label={'Description'}>
				<Input.TextArea
					placeholder={'Enter Description'}
					defaultValue={drawing.description ?? ''}
					onChange={e => {
						onDrawingChange({...drawing, ...{description: e.target.value}});
					}}
				/>
			</Form.Item>
		</Space>
	);
};

