import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {get} from './actions';
import {get as getTabs} from '../ProjectProcessMaster/actions';
import {ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {Link} from 'react-router-dom';
import {
	Col,
	Row,
	Spin,
	Typography,
	Button,
	Table,
} from 'antd';
import {
	DrawingScopeType,
	type DrawingSchedule,
	DrawingType,
	ModuleName,
	ModulePermission,

} from 'app/models';
import {getPermissionSites, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';

export const ViewClientDrawingSchedule: React.FC = () => {
	const dispatch = useDispatch();
	const {ids, records, loading} = useSelector((state: Types.RootState) => state.drawingSchedule);
	const {byIds: tabByIds, allIds: tabAllIds} = useSelector((state: Types.RootState) => state.projectProcessMaster);
	const {byModule: permissions, allSites} = useSelector((state: Types.RootState) => state.userPermission);
	const sites = getPermissionSites(permissions, ModuleName.DRAWING_SCHEDULE, ModulePermission.READ, allSites);
	const items = ids.map(id => records[id]);
	let tabItems = tabAllIds.map(id => tabByIds[id]);
	tabItems = tabItems.map(tabItem => {
		tabItem.designSchedule = items.find(item => item.projectProcessMasterId === tabItem.id);
		return tabItem;
	});
	useEffect(() => {
		dispatch(getTabs({
			where: {
				projectProcessType: ProjectProcessType.DESIGN,
			},
		}));
		dispatch(get({
			where: {
				siteId: sites[0].id,
				drawingType: DrawingType.DRAWING_SCHEDULE,
			},
		}));
	}, []);
	tabItems = tabItems.filter(item => item.designSchedule?.scope === DrawingScopeType.IN_SCOPE && item.designSchedule?.status);
	const dataSource = tabItems.filter(item => !item.isInternal && item.designSchedule?.scheduleDate).sort((a, b) => {
		if (a.designSchedule?.scheduleDate && b.designSchedule?.scheduleDate) {
			return new Date(a.designSchedule?.scheduleDate) > new Date(b.designSchedule?.scheduleDate) ? 1 : -1;
		}

		return -1;
	});

	return (
		<DefaultLayout currentPath={uiPaths.clientDrawingSchedule}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Drawing Schedule</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<br />
						<Button>{`${dataSource.length} Results`}</Button>
						<br /><br />
						<Table
							size={'small'}
							bordered={true}
							dataSource={dataSource}
							pagination={false}
							scroll={{x: 800, y: 500}}
						>
							<Table.Column
								title='Work Activity'
								dataIndex='processName'
								key='processName'
								render={(title: string | undefined, record: DrawingSchedule) => (
									<Link
										to={{
											pathname: `/${uiPaths.clientDrawingProcessScheduleDetail
												.replace(':siteId', String(sites[0].id))
												.replace(':tabId', String(record.id))}`,
										}}
									>
										{title}
									</Link>
								)}
							/>
							<Table.Column
								title='Status'
								dataIndex='designSchedule'
								key='designSchedule.status'
								render={(item: DrawingSchedule | undefined) => toTitleCase(item?.status)
								}
								sorter={{
									compare: (a: DrawingSchedule, b: DrawingSchedule) =>
										a?.projectProcessMaster?.designSchedule?.status && b?.projectProcessMaster?.designSchedule?.status && a?.projectProcessMaster?.designSchedule?.status > b?.projectProcessMaster?.designSchedule?.status ? 1 : -1,
									multiple: 1,
								}}
							/>

						</Table>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
