import {loadState} from '../../store/helpers';
import {type ActionType} from 'typesafe-actions';
import type * as actions from './actions';
import {logoutSuccessful} from '../Common/auth-actions';
import {type PrithuWallet} from '../../models';
import {actionTypes} from './actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from '../../store/action-type.util';

export type MyAction = ActionType<typeof actions>;

export type MyState = Readonly<{
	wallets: PrithuWallet[];
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState = {
	wallets: [],
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

export default function prithuWallet(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('prithuWallet', initialState),
	action: any,
) {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.resetState:
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
		case getRequestActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
		case getFailureActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as PrithuWallet[];
			return {
				...state,
				...{
					wallets: items,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
		case getSuccessActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
}
