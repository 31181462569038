import type * as actions from './actions';
import {actionTypes} from './actions';
import {type PaymentMilestone, type WorkOrderBill, type WorkOrderDebit} from 'app/models';
import {loadState} from 'app/store/helpers';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<WorkOrderBill['id'], WorkOrderBill>;
	byMilestoneIds: Record<PaymentMilestone['id'], WorkOrderBill>;
	byDebitIds: Record<WorkOrderDebit['id'], WorkOrderBill>;
	allIds: Array<WorkOrderBill['id']>;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
	reApprovedCurrentPos: number;
	reApprovedPerPageSize: number;
	pageType?: string;
	filterValue: FilterDataType;
	reApprovedFilterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	byMilestoneIds: {},
	byDebitIds: {},
	allIds: [],
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
	reApprovedCurrentPos: 1,
	reApprovedPerPageSize: 10,
	pageType: undefined,
	filterValue: {},
	reApprovedFilterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
const workOrderBillReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('workOrderBill', initialState),
	action: any,
): MyState => {
	switch (action.type) {
		case logoutSuccessful:
		case actionTypes.resetState:
			return {
				...state,
				...{
					byIds: {},
					byMilestoneIds: {},
					byDebitIds: {},
					allIds: [],
					totalCount: 0,
					currentPos: 1,
					perPageSize: 10,
					reApprovedCurrentPos: 1,
					reApprovedPerPageSize: 10,
					filterValue: {},
					reApprovedFilterValue: {},
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case actionTypes.changePage:
			return {...state, pageType: action.payload as string};
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.apiFilterReApproved:
			return {...state, reApprovedFilterValue: action.payload as FilterDataType};
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: action.payload.currentPos as number,
					perPageSize: action.payload.perPageSize as number,
				},
			};
		case actionTypes.reApprovedPaginationChange:
			return {
				...state,
				...{
					reApprovedCurrentPos: action.payload.reApprovedCurrentPos as number,
					reApprovedPerPageSize: action.payload.reApprovedPerPageSize as number,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getSingleApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getSingleApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage: action.payload?.response?.data?.error?.message as string ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as WorkOrderBill[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					byMilestoneIds: items.filter(item => item.paymentMilestones?.length)
						.reduce<MyState['byMilestoneIds']>((byMilestoneIds, event) => {
						event.paymentMilestones.forEach(({id}) => {
							byMilestoneIds[id] = event;
						});
						return byMilestoneIds;
					}, {}),
					byDebitIds: items.filter(item => item.workOrderDebits?.length)
						.reduce<MyState['byDebitIds']>((byDebitIds, event) => {
						event.workOrderDebits?.forEach(({id}) => {
							byDebitIds[id] = event;
						});
						return byDebitIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: action.payload.data.count as number,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getSingleApi): {
			const newItem = action.payload.data as WorkOrderBill;
			const newAllIds = [...state.allIds];
			if (!newAllIds.includes(newItem.id)) {
				newAllIds.push(newItem.id);
			}

			const newByIds = {...state.byIds};
			newByIds[newItem.id] = newItem;
			return {
				...state,
				...{
					byIds: newByIds,
					allIds: newAllIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default workOrderBillReducer;
