import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Button, Col, Empty, Row, Space, Spin, Table, Typography} from 'antd';
import {uiPaths} from 'app/constants';
import {get, setFilterValue, onPaginationChange, count} from './actions';
import {Link} from 'react-router-dom';
import {isMobile, toTitleCase} from 'app/helpers';
import {moduleLabelName, ModuleName} from 'app/models';
import {
	type ProcessChecklist,
	type ProcessChecklistOnActionType,
} from 'app/models/process-checklist.model';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {PaginationView} from '../Common/Pagination/PaginationView';

export const ViewProcessChecklist: React.FC = () => {
	const dispatch = useDispatch();
	const {allIds, byIds, loading, filterValue, totalCount, currentPos, perPageSize} = useSelector(
		(state: Types.RootState) => state.processChecklist,
	);

	React.useEffect(() => {
		filterData(filterValue);
	}, []);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: (currentPos ? perPageSize * (currentPos - 1) : 0),
		};

		if (f.moduleName) {
			filter.where.moduleName = f.moduleName;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const datasource
    = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const moduleOptions: FilterOptionItem[] = [
		{
			label: moduleLabelName[ModuleName.CONSTRUCTION_SCHEDULE],
			value: ModuleName.CONSTRUCTION_SCHEDULE,
		},
		{
			label: moduleLabelName[ModuleName.DRAWING_SCHEDULE],
			value: ModuleName.DRAWING_SCHEDULE,
		},
		{
			label: moduleLabelName[ModuleName.APPROVAL_SCHEDULE],
			value: ModuleName.APPROVAL_SCHEDULE,
		},
	];
	const uiFilters: UiFilter[] = [
		{
			filterKey: 'moduleName',
			items: moduleOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Module',
			label: 'Module',
			defaultValue: filterValue?.moduleName,
		},
	];
	return (
		<DefaultLayout currentPath={uiPaths.processChecklist}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Process Checklist Masters
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
							filterData(f);
						}} />
						<br />
					</Col>
				</Row>
				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Link to={{pathname: `/${uiPaths.createProcessChecklist}`}}>
							<Button type='primary'>Add New Checklist</Button>
						</Link>
						<br />
						<br />
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={datasource}
								pagination={false}
							>
								<Table.Column
									title='Module'
									dataIndex='moduleName'
									key='moduleName'
									render={(moduleName: ModuleName) => moduleLabelName[moduleName] }
								/>
								<Table.Column title='Name' dataIndex='title' key='title' />
								<Table.Column
									title='Description'
									dataIndex='description'
									key='description'
								/>
								<Table.Column
									title='On Action Type'
									dataIndex='onActionType'
									key='onActionType'
									render={(onActionType: ProcessChecklistOnActionType) =>
										toTitleCase(onActionType)
									}
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(action: string, item: ProcessChecklist) => (
										<Space direction={isMobile ? 'vertical' : 'horizontal'}>
											<Link
												to={{
													pathname: `/${uiPaths.editProcessChecklist.replace(
														':id',
														item.id,
													)}`,
												}}
											>
												<Button size={'small'} type='primary'>
                          Edit
												</Button>
											</Link>
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
