
export const getFilterStartDate = (date: string) =>
	new Date(new Date(date).setHours(0, 0, 0, 0));

export const getFilterEndDate = (date: string) =>
	new Date(new Date(date).setHours(23, 59, 59, 999));

export const getFilterStartDateForDays = (date: string, days: number) =>
	new Date(new Date(new Date(date).setDate(new Date(date).getDate() - days))
		.setHours(0, 0, 0, 0));

export const getFilterDateForDays = (date: string, days: number) =>
	new Date(new Date(new Date(date).setDate(new Date(date).getDate() - days)));

export const getFilterEndDateForDays = (date: string, days: number) =>
	new Date(new Date(new Date(date).setDate(new Date(date).getDate() - days))
		.setHours(23, 59, 59, 999));

export const getQueryDateRange = (d1: string, d2: string, key: string) =>
	[
		{
			[key]: {
				gte: getFilterStartDate(d1),
			},
		},
		{
			[key]: {
				lte: getFilterEndDate(d2),
			},
		},
	];

export const getQueryDateRangeForDays = (d1: string, fromDays: number, toDays: number, key: string) =>
	[
		{
			[key]: {
				lte: getFilterStartDateForDays(d1, fromDays),
			},
		},
		{
			[key]: {
				gte: getFilterEndDateForDays(d1, toDays),
			},
		},
	];
