import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useParams} from 'react-router-dom';
import {parseNum, getOrdinalNumberWords, toTitleCase} from 'app/helpers';
import {useHistory} from 'react-router';
import {DrawingBlock} from './DrawingBlock';
import {
	type Drawing,
} from 'app/models';
import {createAndGet} from './actions';
import {
	Col,
	Row,
	Spin,
	Typography,
	Button,
	Form,
	Divider,
} from 'antd';
import type Types from 'MyTypes';

type MyParams = {
	siteId?: string;
	tabId?: string;
};

export const ViewClientDrawingScheduleDetail: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();
	const {ids, records, loading} = useSelector((state: Types.RootState) => state.drawingSchedule);
	const siteId = parseNum(params?.siteId ?? '0');
	const projectProcessMasterId = parseNum(params?.tabId ?? '0');
	const getData = () => ids.map(id => records[id]).find(item => item.projectProcessMasterId === projectProcessMasterId);
	const drawingScheduleEntry = getData();
	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};

	useEffect(() => {
		const data = {
			siteId: parseNum(String(siteId)),
			projectProcessMasterId,
		};
		dispatch(createAndGet(data));
	}, []);

	return (
		<DefaultLayout currentPath={uiPaths.clientDrawingProcessScheduleDetail}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Button className='btn-primary' onClick={() => {
							history.goBack();
						}}>Back</Button>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Drawing Schedule {drawingScheduleEntry?.projectProcessMaster?.processName ? `for ${drawingScheduleEntry?.projectProcessMaster?.processName}` : ''}
						</Typography.Title>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form {...layout} name='basic'>
							{drawingScheduleEntry?.status ? (
								<Form.Item label={<b>Status</b>}>
									{toTitleCase(drawingScheduleEntry?.status, '_') ?? ''}
									{drawingScheduleEntry?.revisionCount && `( ${getOrdinalNumberWords(drawingScheduleEntry?.revisionCount)} revision )`}
								</Form.Item>
							) : []}
							{drawingScheduleEntry?.drawings ? (
								<Form.Item label={<b>Drawings</b>}>
									{drawingScheduleEntry?.drawings
										.filter(drawing => !drawing.inactive)
										.map((drawing: Drawing, ix: number) => (
											<DrawingBlock
												key={ix}
												drawing={drawing}
												canRemove={false}
											/>
										))}
								</Form.Item>
							) : []}
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
