import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from '../constants';
import {DefaultLayout} from './Layout/DefaultLayout';
import {Button, Col, Form, Input, message, Row, Spin, Typography} from 'antd';
import {type UploadFile} from 'app/models/upload-file';
import {getById, updateProfile} from 'app/components/User/actions';
import {type UserDetail, UserType} from 'app/models';
import {checkFileErr} from 'app/helpers';
import {FileUpload} from 'app/components/Common/FileUpload';
import {useHistory} from 'react-router';
import type Types from 'MyTypes';

export const EditProfileView: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {dataUpdated, errorMessage, loading} = useSelector((state: Types.RootState) => state.user);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);

	const [mobileNumbers, setMobileNumbers] = useState<string>(authUser?.mobileNumbers ?? '');
	const [name, setName] = useState<string>(authUser?.name ?? '');
	const [bloodGroup, setBloodGroup] = useState<string>(authUser?.userDetail?.bloodGroup ?? '');
	const [localAddressLine, setLocalAddressLine] = useState<string>(authUser?.userDetail?.localAddress?.addressLine ?? '');
	const [localAddressPin, setLocalAddressPin] = useState<string>(authUser?.userDetail?.localAddress?.googlePin ?? '');
	const [permanentAddressLine, setPermanentAddressLine] = useState<string>(authUser?.userDetail?.permanentAddress?.addressLine ?? '');
	const [permanentAddressPin, setPermanentAddressPin] = useState<string>(authUser?.userDetail?.permanentAddress?.googlePin ?? '');
	const [emergencyContactName, setEmergencyContactName] = useState<string>(authUser?.userDetail?.emergencyContact?.name ?? '');
	const [emergencyContactNumber, setEmergencyContactNumber] = useState<string>(authUser?.userDetail?.emergencyContact?.phone ?? '');
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = useState<Record<string, string>>({});

	useEffect(() => {
		if (authUser) {
			dispatch(getById(authUser.id));
		}
	}, [authUser]);

	useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	const onSubmitClicked = () => {
		if (!name) {
			void message.error('Please enter your name.');
			return;
		}

		const data: any = {
			mobileNumbers,
			name,
		};

		if (fileList?.length && filePrefixKeys) {
			checkFileErr(fileList);
			const file = fileList[0];
			data.profileImage = `${filePrefixKeys[file.uid]}/${file.name}`;
		}

		const userDetail: UserDetail = {
			bloodGroup,
			localAddress: {
				addressLine: localAddressLine,
				googlePin: localAddressPin,
			},
			permanentAddress: {
				addressLine: permanentAddressLine,
				googlePin: permanentAddressPin,
			},
			emergencyContact: {
				name: emergencyContactName,
				phone: emergencyContactNumber,
			},
		};
		data.detail = JSON.stringify(userDetail);

		if (authUser) {
			dispatch(updateProfile(authUser.id, data));
		}
	};

	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<DefaultLayout currentPath={uiPaths.editProfile}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Edit Profile</Typography.Title>
						<Button onClick={e => {
							history.goBack();
						}}>Back</Button>
					</Col>
					<Col span={24}>
						<Form
							{...layout}
							name='basic'
						>
							<Form.Item label='Name*'>
								<Input
									placeholder={'Enter First Name'}
									value={name}
									onChange={(e: any) => {
										setName(String(e.target.value));
									}}
								/>
							</Form.Item>

							<Form.Item label='Profile Image'>
								<FileUpload
									single={true}
									prefix={'profile-image'}
									fileList={fileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={((list, prefixKeys) => {
										setFileList(list);
										setFilePrefixKeys(prefixKeys);
									})}
								/>
							</Form.Item>

							<Form.Item
								label='Mobile Numbers'
								extra={'Enter Multiple Numbers Separated by Comma'}
							>
								<Input
									value={mobileNumbers}
									onChange={(e: any) => {
										setMobileNumbers(String(e.target.value));
									}}
								/>
							</Form.Item>

							{authUser?.userType === UserType.EMPLOYEE ? (
								<>
									<Form.Item label='Blood Group'>
										<Input
											value={bloodGroup}
											onChange={(e: any) => {
												setBloodGroup(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item label='Local Address'>
										<Input
											value={localAddressLine}
											onChange={(e: any) => {
												setLocalAddressLine(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item label='Local Address Google Pin'>
										<Input
											value={localAddressPin}
											onChange={(e: any) => {
												setLocalAddressPin(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item label='Permanent Address'>
										<Input
											value={permanentAddressLine}
											onChange={(e: any) => {
												setPermanentAddressLine(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item label='Permanent Address Google Pin'>
										<Input
											value={permanentAddressPin}
											onChange={(e: any) => {
												setPermanentAddressPin(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item label='Emergency Contact Name'>
										<Input
											value={emergencyContactName}
											onChange={(e: any) => {
												setEmergencyContactName(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item label='Emergency Contact Number'>
										<Input
											value={emergencyContactNumber}
											onChange={(e: any) => {
												setEmergencyContactNumber(String(e.target.value));
											}}
										/>
									</Form.Item>
								</>
							) : []}

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>Submit</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>

				<br />
				<br />
			</Spin>
		</DefaultLayout>
	);
};
