import {loadState} from 'app/store/helpers';
import {type DrawingSchedule} from 'app/models';
import {logoutSuccessful} from '../Common/auth-actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {actionTypes} from './actions';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyState = Readonly<{
	ids: number[];
	records: Record<number, DrawingSchedule>;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	ids: [],
	records: {},
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
export default function drawingSchedule(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('drawingSchedule', initialState),
	action: any,
) {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.initPage:
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.createGetApi):
		case getRequestActionType(actionTypes.updateApi):
		case getRequestActionType(actionTypes.uploadDrawingApi):
		case getRequestActionType(actionTypes.removeDrawingApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createGetApi):
		case getFailureActionType(actionTypes.updateApi):
		case getFailureActionType(actionTypes.uploadDrawingApi):
		case getFailureActionType(actionTypes.removeDrawingApi):
			return {
				...state,
				...{
					errorMessage:
            action.payload?.response?.data?.error?.message as string ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as DrawingSchedule[];
			return {
				...state,
				...{
					ids: items.map(({id}) => id),
					records: items.reduce<MyState['records']>((record, item) => {
						record[item.id] = item;
						return record;
					}, {}),
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.getSingleApi):
		case getSuccessActionType(actionTypes.createGetApi): {
			const item = action.payload.data as DrawingSchedule;
			const newIds = state.ids?.length ? [...state.ids] : [];
			if (!newIds.includes(item.id)) {
				newIds.push(item.id);
			}

			const newRecords = state.records ? {...state.records} : {};
			newRecords[item.id] = item;
			return {
				...state,
				...{
					ids: newIds,
					records: newRecords,
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.updateApi):
		case getSuccessActionType(actionTypes.uploadDrawingApi):
		case getSuccessActionType(actionTypes.removeDrawingApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
}
