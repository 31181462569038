import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {
	formatDateFunction,
	getCurrencyString,
	getFormattedDateMonth,
	getS3Url,
	getStartEndDate,
	isMobile,
} from 'app/helpers';
import {pdfReportFile, uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {Button, Col, DatePicker, Popconfirm, Row, Space, Spin, Table} from 'antd';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {get, pay, setFilterValue, payAll} from './actions';
import {type PaidExpense, PaidExpenseStatus, type Site} from 'app/models';
import {Link} from 'react-router-dom';
import {CSVLink} from 'react-csv';
import {CheckOutlined} from '@ant-design/icons';
import moment from 'moment';

type OwnProps = {
	sites: Site[];
};

const mapStateToProps = (
	{paidExpense}: Types.RootState,
	ownProps: OwnProps,
) => ({
	...paidExpense,
	...ownProps,
});

const mapDispatchToProps = {
	get,
	pay,
	payAll,
	setFilterValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

type State = {
	selectedRowKeys?: React.Key[];
};

class ViewPaidExpense extends React.Component<Props, State> {
	public filter: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedRowKeys: undefined,
		};
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			this.filterData();
		}
	}

	filterData = (date: string | undefined = undefined) => {
		if (!date) {
			date = new Date().toString();
		}

		const filter = {
			where: {
				and: getQueryDateRangeForDays(date, -2, 4, 'expenseFrom'),
			},
			limit: 500,
			skip: 0,
			order: ['expenseFrom DESC'],
		};
		if (date) {
			filter.where = {
				and: getQueryDateRangeForDays(date, -2, 4, 'expenseFrom'),
			};
		}

		this.props.setFilterValue({date});
		this.props.get(filter);
	};

	handlePayClick = (id: string) => {
		this.props.pay(id);
	};

	handlePaidAllClick = (ids: string[]) => {
		this.props.payAll(ids);
	};

	onWeakChange = (weakString: string) => {
		const splitWeak = weakString.split('-');
		const year = parseInt(splitWeak[0], 10);
		const weak = parseInt(splitWeak[1], 10);
		const date = moment()
			.day('Wednesday')
			.year(year)
			.week(weak)
			.toDate()
			.toString();
		// Tslint:disable-next-line:no-console
		console.log({year, weak, date});
		this.filterData(date);
	};

	render() {
		const {
			ids = [],
			records = {},
			loading,
			filterValue,
			reportDate,
		} = this.props;
		const [firstDay, lastDay] = getStartEndDate(
			reportDate ?? filterValue?.date as string,
		).map((date: string) => (date ? getFormattedDateMonth(date) : ''));
		const inWeek = `From ${lastDay} to ${firstDay}`;
		const inTotal = `Till ${firstDay}`;

		const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
			this.setState({
				selectedRowKeys: newSelectedRowKeys,
			});
		};

		const canPayAll = (selectedRowKeys: React.Key[]) => selectedRowKeys.reduce((canPay, key) => {
			// Returning false when any item can not be marked paid.
			if (!canPay) {
				return false;
			}

			const record = records[key.toString()];
			console.log(key, record);
			if (record?.status !== PaidExpenseStatus.CREATED) {
				return false;
			}

			return true;
		}, true);

		const columns: any[] = [
			{
				title: 'User',
				fixed: 'left',
				width: 120,
				key: 'user',
				render: (text: string, record: PaidExpense) =>
					record.user ? record.user.name : 'N/A',
			},
			{
				title: 'Employee No',
				key: 'empNo',
				render: (text: string, record: PaidExpense) =>
					record.user ? record.user.empNo : '',
			},
			{
				title: 'Expense From',
				key: 'expenseFrom',
				render: (expenseFrom: string, record: PaidExpense) =>
					formatDateFunction(record.expenseFrom, false),
			},
			{
				title: `Paid Amount ${inWeek}`,
				key: 'amount',
				render: (text: string, record: PaidExpense) => (
					<Link
						to={{
							pathname: `/${uiPaths.approvedPaidExpense.replace(
								':paidExpenseId',
								record.id,
							)}`,
						}}
					>
						{record.amount ? getCurrencyString(record.amount) : 0}
					</Link>
				),
			},
			{
				title: `Pending ${inTotal}`,
				key: 'pendingAmount',
				render: (text: string, record: PaidExpense) =>
					record.pendingAmount ? getCurrencyString(record.pendingAmount) : 0,
			},
			{
				title: `Balance ${inTotal}`,
				key: 'balanceAmount',
				render: (text: string, record: PaidExpense) =>
					record.balanceAmount ? getCurrencyString(record.balanceAmount) : 0,
			},
			{
				title: `Total ${inTotal}`,
				key: 'total',
				render(text: string, record: PaidExpense) {
					const total
            = (record.amount ?? 0)
            + (record.pendingAmount ?? 0)
            + (record.balanceAmount ?? 0);
					return total ? getCurrencyString(total) : 0;
				},
			},
			{
				title: 'Limit',
				key: 'limit',
				render: (text: string, record: PaidExpense) =>
					record.limitAmount ? getCurrencyString(record.limitAmount) : 0,
			},
			{
				title: 'Difference',
				key: 'difference',
				render(text: string, record: PaidExpense) {
					const total
            = (record.amount ?? 0)
            + (record.pendingAmount ?? 0)
            + (record.balanceAmount ?? 0);
					const difference = total - (record.limitAmount ?? 0);
					return difference ? getCurrencyString(difference) : 0;
				},
			},
			{
				title: 'Action',
				fixed: 'right',
				width: 80,
				key: 'action',
				render: (text: string, record: PaidExpense) => (
					<Space>
						{(!record.status || record.status === PaidExpenseStatus.CREATED) ? (
							<Popconfirm
								title={'Are you sure you want to pay this expense paid. Amount will be credited automatically in the user wallet!'}
								onConfirm={() => {
									this.handlePayClick(record.id);
								}}
							>
								<Button type={'primary'}>Pay</Button>
							</Popconfirm>
						) : []}
						{record.status && record.status === PaidExpenseStatus.PAID ? (
							<span><CheckOutlined /> Paid</span>
						) : []}
					</Space>
				),
			},
		];

		const dataSource: PaidExpense[] = ids.map((id: string) => ({
			...{key: id},
			...records[id],
		}));

		const rowSelection = {
			selectedRowKeys: this.state.selectedRowKeys,
			onChange: onSelectChange,
		};

		const csvData: any[] = [columns.map(column => column.title as string)];
		ids.forEach((id: string) => {
			const record = records[id];
			csvData.push([
				record.user ? record.user.name : 'N/A',
				record.user ? record.user.empNo : '',
				record.amount ?? 0,
				record.pendingAmount ?? 0,
				record.balanceAmount ?? 0,
				(record.amount ?? 0)
          + (record.pendingAmount ?? 0)
          + (record.balanceAmount ?? 0),
				record.limitAmount ?? 0,
				(record.amount ?? 0)
          + (record.pendingAmount ?? 0)
          + (record.balanceAmount ?? 0)
          - (record.limitAmount ?? 0),
			]);
		});

		return (
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={12} className='mb-10'>
						<Space>
							{this.state.selectedRowKeys?.length ? (
								<Popconfirm
									title={'Are you sure you want to mark paid to all!'}
									disabled={!canPayAll(this.state.selectedRowKeys)}
									onConfirm={() => {
										this.handlePaidAllClick(
											this.state.selectedRowKeys?.length
												? this.state.selectedRowKeys.map(key =>
													key.toString(),
												)
												: [],
										);
									}}
								>
									<Button
										type={'primary'}
										disabled={!canPayAll(this.state.selectedRowKeys)}
									>
										Pay All
									</Button>
								</Popconfirm>
							) : []}
						</Space>
					</Col>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<Space>
							<DatePicker
								picker='week'
								format={'YYYY-w'}
								onChange={(date, dateString) => {
									this.onWeakChange(dateString);
								}}
							/>
							<CSVLink data={csvData} filename={'Paid_Expense_Report.csv'}>
								<Button type={'primary'}>Download</Button>
							</CSVLink>
							<a
								href={getS3Url(pdfReportFile.paidExpense)}
								target='_blank'
								rel='noreferrer'
							>
								<Button type={'primary'}>Open PDF Report</Button>
							</a>
						</Space>
						<br />
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Table
							size={'small'}
							pagination={false}
							dataSource={dataSource}
							columns={columns}
							scroll={isMobile ? undefined : {x: 800, y: 500}}
							rowSelection={rowSelection}
						/>
					</Col>
				</Row>
			</Spin>
		);
	}
}

export default connector(ViewPaidExpense);
