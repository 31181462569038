import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {ViewAllWorkOrder} from '../WorkOrder/ViewAllWorkOrder';

export const ViewApprovalWorkOrder: React.FC = () => (
	<DefaultLayout currentPath={uiPaths.allWorkOrder}>
		<Row>
			<Col span={24}>
				<ViewAllWorkOrder toBeApproved={true} />
			</Col>
		</Row>
	</DefaultLayout>
);
