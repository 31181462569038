export enum FilterFormType {
	SELECT,
	TEXT,
	DATE,
	MONTH,
	DATE_RANGE,
	BOOLEAN,
	MULTI_SELECT,
	WEEK,
	SITE_SELECT,
}

export type FilterOptionItem = {
	label: string;
	value: string;

};

export type UiFilter = {
	filterKey: string;
	formType: FilterFormType;
	items?: FilterOptionItem[];
	formWidth?: number;
	placeholder?: string;
	label?: string;
	defaultValue?: string | boolean | string[];
	onChange?: (value: any, key: string) => void;
	disabledDateDays?: number;
};
