import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type ProjectProcessMaster} from './project-process-master';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@projectProcessMaster/API_FILTER',
	getApi: '@projectProcessMaster/GET_API',
	getActivityApi: '@projectProcessMaster/GET_ACTIVITY_API',
	getSingleApi: '@projectProcessMaster/GET_SINGLE_API',
	createApi: '@projectProcessMaster/CREATE_API',
	updateApi: '@projectProcessMaster/UPDATE_API',
	paginationChange: '@projectProcessMaster/PAGINATION_CHANGE',
	getCountApi: '@projectProcessMaster/GET_COUNT_API',
	getParentsApi: '@projectProcessMaster/GET_PARENTS_API',
};

const url = lbBaseUrl + '/project-process-masters';
const activityUrl = lbBaseUrl + '/schedule-activities';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getById = (id: number) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const getParents = (filter: any) => ({
	type: actionTypes.getParentsApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const getAllWorkActivity = (filter: any) => ({
	type: actionTypes.getActivityApi,
	payload: axios.get(
		`${activityUrl}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const search = async (type: string, query: string) =>
	axios.get<ProjectProcessMaster[]>(
		`${url}/search?type=${type}&query=${query}`,
		getHeaders(true),
	);
