import React from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import {Card, Col, Popconfirm, Space, Typography} from 'antd';
import {type Drawing, UserType} from 'app/models';
import {getS3Url} from 'app/helpers';
import {useSelector} from 'react-redux';
import type Types from 'MyTypes';

type Props = {
	key: number;
	drawing: Drawing;
	canRemove: boolean;
	onRemove?: (id: number) => void;
};

export const DrawingBlock: React.FC<Props> = ({key, drawing, canRemove, onRemove}) => {
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);

	const onRemoveClick = () => {
		if (onRemove && drawing?.id) {
			onRemove(drawing.id);
		}
	};

	const actions: React.ReactNode[] = [];
	if (canRemove) {
		actions.push(
			<Popconfirm
				title={'Are you sure you want to remove this drawing!'}
				onConfirm={onRemoveClick}
			>
				<DeleteOutlined key={drawing.id}/>
			</Popconfirm>,
		);
	}

	return (
		<Col key={key} span={24} className={'mb-10'}>
			<Card size='small' actions={actions}>
				<Space direction='vertical'>
					{drawing.key ? (
						<a href={getS3Url(drawing.key ?? '')} title={drawing.key} target='_blank' rel='noreferrer'>
							{drawing.name}
						</a>
					) : []}
					{authUser?.userType === UserType.EMPLOYEE && drawing.cadKey ? (
						<a href={getS3Url(drawing.cadKey ?? '')} title={drawing.cadKey} target='_blank' rel='noreferrer'>
							Drawing CAD File
						</a>
					) : []
					}
					{drawing.description ? <Typography.Text><b>Description:</b> {drawing.description}</Typography.Text> : ''}
				</Space>
			</Card>
		</Col>
	);
};
