import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getClients} from '../Common/summary-actions';
import {get as getUserPermissions} from '../UserPermission/actions';
import {Col, Row, Spin, Table, Typography} from 'antd';
import {type ClientQueryReport, type User} from 'app/models';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	calculateClientQueryReportDataForClients,
	mutateTree,
} from 'app/services/report-service';
import {get as getSites} from '../Site/actions';
import {getClientQueryReport} from './client-query-report-reducer';
import {formatDateFunction, parseNum} from 'app/helpers';

type DataSourceType = {
	key: string;
	name: string;
	weeklyData?: ClientWeeklyDataType[];
	children?: DataSourceType[];
};

export type ClientWeeklyDataType = {
	date: string;
	open: number;
};

export const ViewClientQueryReportForClients: React.FC = () => {
	const dispatch = useDispatch();
	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
		= useSelector((state: Types.RootState) => state.userPermission);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.clientQueryReport,
	);
	const today = new Date();
	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {clientUsers} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const clientIds = clientUsers.map(client => client.id);
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		children: [],
	};

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, ClientQueryReport[]>>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});
	React.useEffect(() => {
		dispatch(
			getClientQueryReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						34,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(getSites({}));
		dispatch(getClients());
		dispatch(
			getUserPermissions({
				where: {
					userId: {inq: clientIds},
				},
			}),
		);
	}, []);

	// Creating a permissions record to uniquely identify Client users ids and the sites associated with them
	const siteClientPermissionMap = userPermissions.reduce<
	Record<number, number[]>
	>((record, item) => {
		if (!record[item.siteId]) {
			record[item.siteId] = [];
		}

		if (!record[item.siteId].includes(item.userId)) {
			record[item.siteId].push(item.userId);
		}

		return record;
	}, {});
	// Creating a map of project architects and their sites
	const projectArchitectSitesMap = allSites.reduce<Record<number, number[]>>(
		(record, item) => {
			if (item.projectArchitectId) {
				if (!record[item.projectArchitectId]) {
					record[item.projectArchitectId] = [];
				}

				record[item.projectArchitectId].push(item.id);
			}

			return record;
		},
		{},
	);

	// Traversing the tree to assign delays to all nodes and to create sites as child nodes of site incharges(leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.key = String(user.id);
		node.weeklyData = calculateClientQueryReportDataForClients(
			user,
			mapOfRecords,
			projectArchitectSitesMap,
			siteClientPermissionMap,
		);
		node.name = user.name;
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else if (projectArchitectSitesMap[user.id]?.length) {
			node.children = projectArchitectSitesMap[user.id].map(siteId => {
				const delays: ClientWeeklyDataType[] = Object.entries(
					mapOfRecords,
				).map(([key, records]) => {
					let open = 0;
					if (siteClientPermissionMap[siteId]) {
						siteClientPermissionMap[siteId].forEach(userId => {
							open
								+= records.find(entry => parseNum(entry.userId) === userId)
									?.open ?? 0;
						});
					}

					return {
						date: key,
						open,
					};
				});

				const x: DataSourceType = {
					key: String(siteId),
					weeklyData: delays,
					name: allSites.find(site => site.id === siteId)?.name ?? '',
				};
				return x;
			});
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites.reduce((
			projectArchitectIds: number[],
			{projectArchitectId},
		) => {
			if (projectArchitectId) {
				projectArchitectIds.push(projectArchitectId);
			}

			return projectArchitectIds;
		},
		[]),
	);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);
	return (
		<DefaultLayout currentPath={uiPaths.clientQueryReportForClients}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Client Query Report : Open Queries(From Internal Users to the
							clients)</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='mb-10'>
						<Table
							bordered={true}
							size={'small'}
							dataSource={dataSource}
							scroll={{x: 2000, y: 1000}}
							pagination={false}
						>
							<Table.Column
								title='Name'
								dataIndex='name'
								key='name'
								fixed='left'
								width={240}
							/>
							{[0, 1, 2, 3, 4].map(i => (
								<Table.Column
									title={Object.keys(mapOfRecords)[i]}
									dataIndex='weeklyData'
									key='weeklyDataOpen'
									render={weeklyData =>
										// eslint-disable-next-line @typescript-eslint/no-unsafe-return
										weeklyData?.length ? weeklyData[i]?.open : []
									}
									width={120}
								/>
							))}
						</Table>
					</Col>
				</Row>
				<br />
				<Row>
					<Col span={24}>
						<Typography.Text><b>Report Description:</b> This report presents how many client queries are open which
							are assigned to the client by our internal users.Data is captured every week on
							Friday at 12:00 AM in the night.If there is any increase in the number of client queries
							which are in open status as compared to the previous week, it means more queries
							are assigned to the client by the internal user in the last 7 days. If there is any
							decrease in the number as compared to the previous week, it means that client
							queries have been marked as closed either by the internal user or by the client to
							whom the client query was assigned.</Typography.Text>
					</Col>
				</Row>
				<br />
				<Row>
					<Col span={24}>
						<Typography.Text><b>Report Logic:</b> Based upon the number of client queries assigned to the client by our
							internal team which are in open status are captured in this client Query Report. To
							read the report, you can expand the “+” sign in front of the Reporting Manager name
							to see the list of employees reporting to him.In the lowest Branch, we have included
							site name as a reference of the client name.
							If you have any query related to this report or a suggestion to improvise the report.
							Please Contact ruchika.singh@prithu.in</Typography.Text>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
