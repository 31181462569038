import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';

export const actionTypes = {
	initPage: '@auth/INIT_PAGE',
	loginSuccessful: '@auth/LOGIN_SUCCESSFUL',
	passwordChangedClear: '@auth/PASSWORD_CHANGED_CLEAR',
	passwordChangedSuccessful: '@auth/PASSWORD_CHANGED_SUCCESSFUL',
	passwordReset: '@auth/PASSWORD_RESET',
	apiError: '@auth/API_ERROR',
};

export const logoutSuccessful = '@auth/LOGOUT_SUCCESSFUL';

const loginUrl = lbBaseUrl + '/users/login';
const changePasswordUrl = lbBaseUrl + '/users/change-password';
const resetPasswordUrl = lbBaseUrl + '/users/reset-password';

export const login = (data: any) => ({
	type: actionTypes.loginSuccessful,
	payload: axios.post(loginUrl, data, getHeaders(true)),
	meta: {process: true},
});

export const updatePassword = (data: any) => ({
	type: actionTypes.passwordChangedSuccessful,
	payload: axios.patch(changePasswordUrl, data, getHeaders(true)),
	meta: {process: true},
});

export const resetPassword = (data: any) => ({
	type: actionTypes.passwordReset,
	payload: axios.patch(resetPasswordUrl, data, getHeaders(true)),
	meta: {process: true},
});

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const logout = () => ({type: logoutSuccessful, meta: {}});

