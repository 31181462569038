import React, {useState} from 'react';
import {
	message,
	Spin,
	Row,
	Col,
	Form,
	Input,
	Button,
	Select,
	DatePicker,
} from 'antd';
import {type AttachmentData, type Site, ModuleName, ModulePermission, WorkOrderStatus} from 'app/models';
import {get as getCategories} from '../Category/actions';
import {get as getWorkOrders} from 'app/components/WorkOrder/actions';
import {FileUpload} from '../Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import {useDispatch, useSelector} from 'react-redux';
import {create, initPage} from './actions';
import {checkFileErr, getPermissionSites, parseNum, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {CategoryType} from '../Category/category';
import dayjs from 'dayjs';
import {siteResourceDirectorySheetLink, workOrderQueryLeadTimeSheetLink} from 'app/constants';
import {WorkOrderQueryDrawingAvailability, WorkOrderQueryOrderType, workOrderQueryOrderTypeLabel} from './work-order-query.model';

export const ViewWorkOrderQueryForm: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, dataUpdated} = useSelector(
		(state: Types.RootState) => state.workOrderQuery,
	);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {byIds: categoryByIds, allIds: allCategoryIds} = useSelector((state: Types.RootState) => state.category);

	const sites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER_QUERY,
		ModulePermission.WRITE,
		allSites,
	);

	const {byIds: byIdsWo, allIds: allIdsWo} = useSelector(
		(state: Types.RootState) => state.workOrder,
	);

	const categories = allCategoryIds.map(id => categoryByIds[id]).filter(category => !category.isDeactivated);

	const categoryOptions = categories
		.filter(category => !category.parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const subCategoryOptions = parentId => categories
		.filter(category => category.parentId === parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const [siteId, setSiteId] = useState<number>(0);
	const [content, setContent] = React.useState<string>('');
	const [targetDate, setTargetDate] = React.useState<string>('');
	const [categoryId, setCategoryId] = useState<number>(0);
	const [subCategoryId, setSubCategoryId] = useState<number>(0);
	const [workOrderId, setWorkOrderId] = useState<number>(0);
	const [workOrderType, setWorkOrderType] = useState<string>('');
	const [drawingAvailability, setDrawingAvailability] = useState<string>('');
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<
	Record<string, string>
	>({});

	React.useEffect(() => {
		dispatch(initPage());
		dispatch(getCategories({
			where: {
				categoryType: CategoryType.WORK_ORDER_QUERY,
			},
		}));

		dispatch(
			getWorkOrders({
				where: {siteId: {inq: sites.map((site: Site) => site.id)}},
			}),
		);
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Created successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	const onSubmitClicked = () => {
		if (!siteId) {
			void message.error('Please Select a site');
			return;
		}

		if (!content) {
			void message.error('Please Enter Query Point');
			return;
		}

		if (!categoryId) {
			void message.error('Please select a category!');
			return;
		}

		if (!subCategoryId) {
			void message.error('Please select a sub category!');
			return;
		}

		checkFileErr(fileList);

		const attachments = fileList.map((file: UploadFile) => {
			const x: AttachmentData = {
				name: file.name,
				key: `${filePrefixKeys[file.uid]}/${file.name}`,
			};
			return x;
		});

		const data = {
			siteId,
			categoryId,
			subCategoryId,
			workOrderId,
			content,
			targetDate: targetDate ? dayjs(targetDate).format('YYYY-MM-DDTHH:mm:ss.sssZ') : undefined,
			workOrderType,
			drawingAvailability,
			attachments,
		};

		dispatch(create(data));
	};

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row className='mb-15'>
				<Col span={24}>
					<Form {...layout} name='basic'>
						<Form.Item label='Site*'>
							<Select
								showSearch={true}
								style={{width: 250}}
								placeholder='Select a Site'
								optionFilterProp='children'
								onChange={value => {
									setSiteId(parseNum(String(value)));
								}}
							>
								{sites.map((site: Site, ix: number) => (
									<Select.Option key={ix} value={site.id}>
										{site.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Category*'>
							<Select
								showSearch={true}
								style={{width: 250}}
								placeholder='Select a Category'
								optionFilterProp='children'
								onChange={value => {
									setCategoryId(parseNum(String(value)));
								}}
							>
								{categoryOptions.map((option: FilterOptionItem, ix: number) => (
									<Select.Option key={ix} value={option.value}>
										{option.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						{categoryId ? (
							<Form.Item label='Sub Category*'>
								<Select
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Sub-Category'
									optionFilterProp='children'
									onChange={value => {
										setSubCategoryId(parseNum(String(value)));
									}}
								>
									{subCategoryOptions(categoryId).map((option: FilterOptionItem, ix: number) => (
										<Select.Option key={ix} value={option.value}>
											{option.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						) : []}
						<Form.Item label='Work Order Type'>
							<Select
								showSearch={true}
								style={{width: 250}}
								placeholder='Select a Work Order Type'
								optionFilterProp='children'
								onChange={value => {
									setWorkOrderType(value as string);
								}}
							>
								{Object.values(WorkOrderQueryOrderType).map(val => (
									<Select.Option key={val} value={val}>
										{workOrderQueryOrderTypeLabel[val]}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						{workOrderType === String(WorkOrderQueryOrderType.EXISTING) ? (
							<>
								<Form.Item label='Work Order of Contractor'>
									<Select
										showSearch={true}
										style={{width: 250}}
										placeholder='Select a Work Order'
										optionFilterProp='children'
										onChange={value => {
											setWorkOrderId(parseNum(String(value)));
										}}
									>
										{allIdsWo
											.filter(
												(id: number) =>
													(!siteId || byIdsWo[id].siteId === siteId)
                          && byIdsWo[id].status === WorkOrderStatus.PUBLISHED,
											)
											.map((id: number) => ({
												value: id.toString(),
												label: byIdsWo[id].title,
											}))
											.map((option: FilterOptionItem, ix: number) => (
												<Select.Option key={ix} value={option.value}>
													{option.label}
												</Select.Option>
											))}
									</Select>
								</Form.Item>
							</>
						) : (
							[]
						)}
						<Form.Item label='Lead Time Sheet Link' name='leadTimeSheetLink'>
							<a
								href={workOrderQueryLeadTimeSheetLink}
								target='_blank'
								rel='noreferrer'
							>
								{workOrderQueryLeadTimeSheetLink}
							</a>
						</Form.Item>
						<Form.Item label='Site Resources Directory' name='siteResourceDirectorySheetLink'>
							<a
								href={siteResourceDirectorySheetLink}
								target='_blank'
								rel='noreferrer'
							>
								{siteResourceDirectorySheetLink}
							</a>
						</Form.Item>
						<Form.Item label='Query Point*'>
							<Input.TextArea
								id={'content'}
								rows={3}
								placeholder={'Enter Your Query'}
								value={content ?? ''}
								onChange={(e: any) => {
									setContent(String(e.target.value));
								}}
							/>
						</Form.Item>
						<Form.Item label='Requirement Date' help='Date on which the contractor is required to start the work on site'>
							<DatePicker
								onChange={(_, dateString) => {
									setTargetDate(dateString);
								}}
							/>
						</Form.Item>

						<Form.Item label='Is the Drawing Available with you?'>
							<Select
								showSearch={true}
								style={{width: 250}}
								placeholder='Select'
								optionFilterProp='children'
								onChange={value => {
									setDrawingAvailability(value as string);
								}}
							>
								{Object.values(WorkOrderQueryDrawingAvailability).map(val => (
									<Select.Option key={val} value={val}>
										{toTitleCase(val)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label='Attachments (if any)'>
							<FileUpload
								prefix={'query'}
								fileList={fileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={(fileList, filePrefixKeys) => {
									setFileList(fileList);
									setFilePrefixKeys(filePrefixKeys);
								}}
							/>
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button id={'submit'} type='primary' onClick={onSubmitClicked}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Spin>
	);
};
