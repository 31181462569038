import React from 'react';
import type Types from 'MyTypes';
import {useSelector, useDispatch} from 'react-redux';
import {uiPaths} from 'app/constants';
import {get} from './actions';
import {Spin, Row, Col, Typography, Table} from 'antd';
import {ModuleName, ModulePermission} from 'app/models';
import {getPermissionSites} from 'app/helpers';
import {type ProjectProcessMaster, ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {type ProjectProcessSchedule} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {Link} from 'react-router-dom';
import {ProjectScheduleStatus} from 'app/components/ProjectProcessSchedule/project-process-schedule';

type Props = {
	projectProcessType: ProjectProcessType;
	title: string;
};

export const ViewClientProjectProcessSchedule: React.FC<Props> = ({title, projectProcessType}) => {
	const dispatch = useDispatch();
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const {allIds, byIds, loading} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	const sites = getPermissionSites(permissions, ModuleName.CONSTRUCTION_SCHEDULE, ModulePermission.READ, allSites);

	React.useEffect(() => {
		console.log('ViewClientProjectProcessSchedule component');
		dispatch(get({
			where: {
				siteId: sites[0].id,
				projectProcessType,
			},
		}));
	}, []);

	const rawItems = allIds?.length ? allIds.map(id => byIds[id]).filter(item => item.siteId === sites[0].id) : [];
	const dataSource = rawItems.filter(item => item.startDate).sort((a, b) => {
		if (a.startDate && b.startDate) {
			return new Date(a.startDate) > new Date(b.startDate) ? 1 : -1;
		}

		return -1;
	}).filter(item => item.status).filter((e => e.status !== ProjectScheduleStatus.STOPPED));

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>
						{title ?? 'n/a'}
					</Typography.Title>
				</Col>
			</Row>
			<br />
			<br />
			<Row>
				<Col span={24}>
					<Table
						size={'small'}
						bordered={true}
						dataSource={dataSource}
						pagination={false}
					>
						<Table.Column
							title='Work Activity'
							dataIndex='projectProcessMaster'
							key='projectProcessMaster'
							render={(
								projectProcessMaster: ProjectProcessMaster,
								schedule: ProjectProcessSchedule,
							) => (
								<Link
									to={{
										pathname: `/${uiPaths.clientProjectProcessScheduleDetail.replace(
											':id',
											String(schedule.id),
										)}`,
									}}
								>
									{projectProcessMaster?.processName ?? 'N/A'}
								</Link>
							)}
						/>
						{projectProcessType === ProjectProcessType.CONSTRUCTION && (
							<Table.Column
								title='Floor Name'
								dataIndex='projectProcessMaster'
								key='projectProcessMaster'
								render={(projectProcessMaster: ProjectProcessMaster) =>
									projectProcessMaster?.workActivityMaster?.floorName ?? ''
								}
							/>
						)
						}
						<Table.Column title='Status' dataIndex='status' key='status' />
					</Table>
				</Col>
			</Row>
		</Spin>
	);
};
