import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getClients} from '../Common/summary-actions';
import {get as getUserPermissions} from '../UserPermission/actions';
import {Col, Row, Spin, Table, Typography} from 'antd';
import type Types from 'MyTypes';
import {type ClientQueryDeviceUsageReport, type User} from 'app/models';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {formatDateFunction, parseNum} from 'app/helpers';
import {get as getSites} from '../Site/actions';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {getClientQueryDeviceUsageReport} from './client-query-device-usage-report-reducer';
import {
	calculateClientQueryDeviceUsageReportData,
	mutateTree,
} from 'app/services/report-service';

type DataSourceType = {
	key: string;
	name: string;
	weeklyData?: ClientWeeklyDataType[];
	children?: DataSourceType[];
};

export type ClientWeeklyDataType = {
	date: string;
	total: number;
	ios: number;
	android: number;
};

export const ViewClientQueryDeviceUsageReport: React.FC = () => {
	const dispatch = useDispatch();
	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
		= useSelector((state: Types.RootState) => state.userPermission);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.clientQueryDeviceUsageReport,
	);
	const today = new Date();
	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {clientUsers} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const clientIds = clientUsers.map(client => client.id);
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		children: [],
	};

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, ClientQueryDeviceUsageReport[]>>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	React.useEffect(() => {
		dispatch(
			getClientQueryDeviceUsageReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						34,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(getSites({}));
		dispatch(getClients());
		dispatch(
			getUserPermissions({
				where: {
					userId: {inq: clientIds},
				},
			}),
		);
	}, []);

	// Creating a permissions record to uniquely identify Client users ids and the sites associated with them
	const siteClientPermissionMap = userPermissions.reduce<
	Record<number, number[]>
	>((record, item) => {
		if (!record[item.siteId]) {
			record[item.siteId] = [];
		}

		if (!record[item.siteId].includes(item.userId)) {
			record[item.siteId].push(item.userId);
		}

		return record;
	}, {});

	console.log(siteClientPermissionMap);

	// Creating a map of project architects and their sites
	const projectArchitectSitesMap = allSites.reduce<Record<number, number[]>>(
		(record, item) => {
			if (item.projectArchitectId) {
				if (!record[item.projectArchitectId]) {
					record[item.projectArchitectId] = [];
				}

				record[item.projectArchitectId].push(item.id);
			}

			return record;
		},
		{},
	);

	// Traversing the tree to assign weekly data to all nodes and to create sites as child nodes of project architects(leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.key = String(user.id);
		node.weeklyData = calculateClientQueryDeviceUsageReportData(
			user,
			mapOfRecords,
			projectArchitectSitesMap,
			siteClientPermissionMap,
		);
		node.name = user.name;
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else if (projectArchitectSitesMap[user.id]?.length) {
			node.children = projectArchitectSitesMap[user.id].map(siteId => {
				const delays: ClientWeeklyDataType[] = Object.entries(
					mapOfRecords,
				).map(([key, records]) => {
					let total = 0;
					let ios = 0;
					let android = 0;
					if (siteClientPermissionMap[siteId]) {
						siteClientPermissionMap[siteId].forEach(userId => {
							total
								+= records.find(entry => parseNum(entry.userId) === userId)
									?.total ?? 0;
							ios
								+= records.find(entry => parseNum(entry.userId) === userId)
									?.ios ?? 0;
							android
								+= records.find(entry => parseNum(entry.userId) === userId)
									?.android ?? 0;
						});
					}

					return {
						date: key,
						total,
						ios,
						android,
					};
				});

				return {
					key: String(siteId),
					weeklyData: delays,
					name: allSites.find(site => site.id === siteId)?.name ?? '',
				};
			});
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites.reduce((
			projectArchitectIds: number[],
			{projectArchitectId},
		) => {
			if (projectArchitectId) {
				projectArchitectIds.push(projectArchitectId);
			}

			return projectArchitectIds;
		},
		[]),
	);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);

	return (
		<DefaultLayout currentPath={uiPaths.clientQueryDeviceUsageReport}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Client Query Device Usage Report
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='mb-10'>
						<Table
							bordered={true}
							size={'small'}
							dataSource={dataSource}
							scroll={{x: 2000, y: 1000}}
							pagination={false}
						>
							<Table.Column
								title='Name'
								dataIndex='name'
								key='name'
								fixed='left'
								width={50}
							/>
							{[0, 1, 2, 3, 4].map(i => (
								<Table.ColumnGroup key={i} title={Object.keys(mapOfRecords)[i]}>
									<Table.Column
										title='Total'
										dataIndex='weeklyData'
										key='weeklyData'
										render={weeklyData =>
											// eslint-disable-next-line @typescript-eslint/no-unsafe-return
											weeklyData?.length ? weeklyData[i]?.total : []
										}
										width={20}
									/>
									<Table.Column
										title='IOS'
										dataIndex='weeklyData'
										key='weeklyData'
										render={weeklyData =>
											// eslint-disable-next-line @typescript-eslint/no-unsafe-return
											weeklyData?.length ? weeklyData[i]?.ios : []
										}
										width={20}
									/>
									<Table.Column
										title='Android'
										dataIndex='weeklyData'
										key='weeklyData'
										render={weeklyData =>
											// eslint-disable-next-line @typescript-eslint/no-unsafe-return
											weeklyData?.length ? weeklyData[i]?.android : []
										}
										width={20}
									/>
								</Table.ColumnGroup>
							))}
						</Table>
					</Col>
				</Row>
				<br />
			</Spin>
		</DefaultLayout>
	);
};
