import {type WeeklyDataType} from 'app/components/Reports/ViewProjectQueryReport';
import {addDays, equalNum, getFormattedDate, parseNum} from 'app/helpers';
import {
	type ClientQueryReport,
	type ClientQueryOverdueReport,
	type MonthlyPettyCashReport,
	type QueryReport,
	type ScheduleDelayReport,
	type User,
	type ChecklistResponseReport,
	type ClientQueryDeviceUsageReport,
	type ApprovalScheduleDelayReport,
	type LabourAttendance,
	type CorporateQueryReport,
	type DrawingScheduleActivityReport,
	type ConstructionSchedulePhotoReport,
} from 'app/models';
import {type ClientWeeklyDataType as ClientWeeklyDataTypeForClientsReport} from 'app/components/Reports/ViewClientQueryReportForClients';
import {type ClientWeeklyDataType as ClientWeeklyDataTypeForDeviceUsageReport} from 'app/components/Reports/ViewClientQueryDeviceUsageReport';
import {type ExpenseType} from 'app/components/Reports/ViewMonthlyPettyCashReport';
import {reduceUniqueIds} from 'app/helpers';
import {type DelayType} from 'app/components/Reports/ViewHierarchyReport';

export const getKey = (val: string, suffix?: string, prefix?: string) => {
	let key = val.split(' ').join('-');
	if (suffix) {
		key += suffix;
	}

	if (prefix) {
		key = prefix + key;
	}

	return key;
};

export const getWeekLabel = (date: string) => {
	const d = new Date(date);
	const day = d.getDay();
	const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is sunday
	const firstWeekDay = new Date(d.setDate(diff));
	const firstDay = getFormattedDate(firstWeekDay.toString());
	const lastDay = getFormattedDate(addDays(firstWeekDay.toString(), 6).toString());
	return `${firstDay} to ${lastDay}`;
};

export const getAllSubordinateSiteIds = (
	user: User,
	permissionMapOfRecord: Record<number, number[]>,
	siteIds: number[],
) => {
	if (user.subordinates?.length) {
		user.subordinates.forEach(usr =>
			getAllSubordinateSiteIds(usr, permissionMapOfRecord, siteIds),
		);
	} else if (permissionMapOfRecord[user.id]?.length) {
		siteIds.push(...permissionMapOfRecord[user.id]);
	}

	return siteIds.reduce<number[]>(
		(ids, id) => reduceUniqueIds<number>(ids, id),
		[],
	);
};

export const getAllSubordinates = (user: User, subordinates: User[]) => {
	if (user.subordinates?.length) {
		subordinates.push(...user.subordinates);
		user.subordinates.forEach(usr => getAllSubordinates(usr, subordinates));
	}

	return subordinates;
};

export const calculateDelay = (
	user: User,
	mapOfRecords: Record<string, ScheduleDelayReport[]>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const delays: DelayType[] = [];
	Object.entries(mapOfRecords).forEach(([key, reports]) => {
		let sum = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					sum += report.delayed;
				}
			});
		}

		const average = parseNum(sum / siteIds.length);
		delays.push({date: key, delay: average});
	});
	return delays;
};

export const calculateNotCompletedDelay = (
	user: User,
	mapOfRecords: Record<string, ScheduleDelayReport[]>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const delays: DelayType[] = [];
	Object.entries(mapOfRecords).forEach(([key, reports]) => {
		let sum = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					sum += report.notCompletedDelayed;
				}
			});
		}

		const average = parseNum(sum / siteIds.length);
		delays.push({date: key, delay: average});
	});
	return delays;
};

export const calculateDailyLabourReportData = (
	user: User,
	mapOfRecords: Record<string, LabourAttendance[]>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([date, reports]) => {
		let actualCount = 0;
		let requiredCount = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					actualCount += report.actualCount ?? 0;
					requiredCount += report.requiredCount ?? 0;
				}
			});
		}

		data[getKey(date, '-actualCount')] = actualCount ?? 0;
		data[getKey(date, '-requiredCount')] = requiredCount ?? 0;
	});
	return data;
};

export const calculateChecklistResponseReportData = (
	user: User,
	mapOfRecords: Record<string, ChecklistResponseReport[]>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([key, reports]) => {
		let approved = 0;
		let cancelled = 0;
		let approvalPercentage = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					approved += report.approved;
					cancelled += report.cancelled;
					approvalPercentage = approved
						? Math.floor((approved * 100) / (approved + cancelled))
						: 0;
				}
			});
		}

		data[getKey(key, '-approved')] = approved ?? 0;
		data[getKey(key, '-cancelled')] = cancelled ?? 0;
		data[getKey(key, '-approvepercent')] = approvalPercentage ?? 0;
	});
	return data;
};

export const calculateDrawingScheduleChecklistResponseReportData = (
	user: User,
	mapOfRecords: Record<string, ChecklistResponseReport[]>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([key, reports]) => {
		let approved = 0;
		let cancelled = 0;
		let approvalPercentage = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					approved += report.drawingScheduleApproved;
					cancelled += report.drawingScheduleCancelled;
					approvalPercentage = approved
						? Math.floor((approved * 100) / (approved + cancelled))
						: 0;
				}
			});
		}

		data[getKey(key, '-approved')] = approved ?? 0;
		data[getKey(key, '-cancelled')] = cancelled ?? 0;
		data[getKey(key, '-approvepercent')] = approvalPercentage ?? 0;
	});
	return data;
};

export const calculateDrawingScheduleActivityReportData = (
	user: User,
	mapOfRecords: Record<string, DrawingScheduleActivityReport[]>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([date, reports]) => {
		let completedActivities = 0;
		let revisedActivities = 0;
		let notCompletedActivities = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					completedActivities += report.completedActivities;
					revisedActivities += report.revisedActivities;
					notCompletedActivities += report.notCompletedActivities;
				}
			});
		}

		data[getKey(date, '-completedActivities')] = completedActivities ?? 0;
		data[getKey(date, '-revisedActivities')] = revisedActivities ?? 0;
		data[getKey(date, '-notCompletedActivities')] = notCompletedActivities ?? 0;
	});
	return data;
};

export const calculateLaisioningExpense = (
	user: User,
	mapOfRecords: Record<string, MonthlyPettyCashReport[]>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const expenses: ExpenseType[] = [];
	Object.entries(mapOfRecords).forEach(([key, reports]) => {
		let sum = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					sum += report.laisioningExpense;
				}
			});
		}

		// Const average = parseNum(sum / siteIds.length);
		expenses.push({date: key, expense: sum});
	});
	return expenses;
};

export const calculateActivityDelay = (
	user: User,
	mapOfRecords: Record<
	string,
	ScheduleDelayReport[] | ApprovalScheduleDelayReport[]
	>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([date, reports]) => {
		let sum = 0;
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		if (siteIds.length) {
			reports.forEach(report => {
				if (siteIds.includes(report.siteId)) {
					sum += report.delayedActivities;
				}
			});
		}

		// Removing replacing average with sum
		// const average = parseNum(sum / siteIds.length);
		// data[getKey(date)] = average ?? 0;
		data[getKey(date)] = sum;
	});
	return data;
};

export const calculatePhotoCount = (
	user: User,
	mapOfRecords: Record<
	string,
	ConstructionSchedulePhotoReport[]
	>,
	permissionMapOfRecord: Record<number, number[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([date, reports]) => {
		const siteIds = getAllSubordinateSiteIds(user, permissionMapOfRecord, []);
		const sum: number = siteIds.length ? reports.reduce((sum: number, report: ConstructionSchedulePhotoReport) => {
			if (siteIds.includes(report.siteId)) {
				sum += report.photoCount;
			}

			return sum;
		}, 0) : 0;

		data[getKey(date)] = sum;
	});
	return data;
};

export const calculateQueryReportData = (
	user: User,
	mapOfRecords: Record<string, QueryReport[] | CorporateQueryReport[]>,
) => {
	const data: WeeklyDataType[] = [];
	Object.entries(mapOfRecords).forEach(([date, reports]) => {
		let subordinatesOpen = 0;
		const open
      = reports.find(report => equalNum(report.userId, user.id))?.open ?? 0;
		const allSubordinates = getAllSubordinates(user, []);
		if (allSubordinates.length) {
			const subordinateIds = allSubordinates.map(({id}) => id);
			subordinatesOpen = reports
				.filter(report => subordinateIds.includes(report.userId))
				.reduce((sum, item) => {
					sum += item.open;
					return sum;
				}, 0);
		}

		data.push({date, open, subordinatesOpen});
	});
	return data;
};

export const calculateClientQueryReportData = (
	user: User,
	mapOfRecords: Record<string, ClientQueryReport[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([date, reports]) => {
		let subordinatesOpen = 0;
		const allSubordinates = getAllSubordinates(user, []);
		if (allSubordinates.length) {
			const subordinateIds = allSubordinates.map(({id}) => id);
			subordinatesOpen = reports
				.filter(report => subordinateIds.includes(report.userId))
				.reduce((sum, item) => {
					sum += item.open;
					return sum;
				}, 0);
		}

		const userReport = reports.find(report =>
			equalNum(report.userId, user.id),
		);
		data[getKey(date, '-open')] = userReport?.open ?? 0;
		data[getKey(date, '-subordinatesOpen')] = subordinatesOpen;
	});
	return data;
};

export const calculateClientQueryOverdueReportData = (
	user: User,
	mapOfRecords: Record<string, ClientQueryOverdueReport[]>,
) => {
	const data: Record<string, number> = {};
	Object.entries(mapOfRecords).forEach(([date, reports]) => {
		let overdue = 0;
		const allSubordinates = getAllSubordinates(user, []);
		if (allSubordinates.length) {
			const subordinateIds = allSubordinates.map(({id}) => id);
			overdue = reports
				.filter(report => subordinateIds.includes(report.userId))
				.reduce((sum, item) => {
					sum += item.overdue;
					return sum;
				}, 0);
		}

		data[getKey(date, '-overdue')]
      = reports.find(report => equalNum(report.userId, user.id))?.overdue ?? 0;
		data[getKey(date, '-subordinatesOverdue')] = overdue ?? 0;
	});
	return data;
};

export const calculateClientQueryReportDataForClients = (
	user: User,
	mapOfRecords: Record<string, ClientQueryReport[]>,
	projectArchitectSitesMap: Record<number, number[]>,
	siteClientPermissionMap: Record<number, number[]>,
) => {
	const delays: ClientWeeklyDataTypeForClientsReport[] = [];
	Object.entries(mapOfRecords).forEach(([key, reports]) => {
		let open = 0;
		const siteIds = getAllSubordinateSiteIds(
			user,
			projectArchitectSitesMap,
			[],
		);
		if (siteIds.length) {
			siteIds.forEach(siteId => {
				if (siteClientPermissionMap[siteId]) {
					siteClientPermissionMap[siteId].forEach(userId => {
						open += reports.find(entry => entry.userId === userId)?.open ?? 0;
					});
				}
			});
		}

		delays.push({date: key, open});
	});
	return delays;
};

export const calculateClientQueryDeviceUsageReportData = (
	user: User,
	mapOfRecords: Record<string, ClientQueryDeviceUsageReport[]>,
	projectArchitectSitesMap: Record<number, number[]>,
	siteClientPermissionMap: Record<number, number[]>,
) => {
	const delays: ClientWeeklyDataTypeForDeviceUsageReport[] = [];
	Object.entries(mapOfRecords).forEach(([key, reports]) => {
		let total = 0;
		let ios = 0;
		let android = 0;
		const siteIds = getAllSubordinateSiteIds(
			user,
			projectArchitectSitesMap,
			[],
		);
		if (siteIds.length) {
			siteIds.forEach(siteId => {
				if (siteClientPermissionMap[siteId]) {
					siteClientPermissionMap[siteId].forEach(userId => {
						total
              += reports.find(entry => entry.userId === userId)?.total ?? 0;
						ios += reports.find(entry => entry.userId === userId)?.ios ?? 0;
						android
              += reports.find(entry => entry.userId === userId)?.android ?? 0;
					});
				}
			});
		}

		delays.push({date: key, total, ios, android});
	});
	return delays;
};

export const populateSubordinates = (user: User, list: User[]) => {
	user.subordinates = user.id
		? list.filter(item => item.reportingUserId === user.id)
		: [];
	if (user.subordinates?.length) {
		user.subordinates.forEach(subordinate =>
			populateSubordinates(subordinate, list),
		);
	}

	return user;
};

export const existNode = (user: User, ids: number[], existIds: number[]) => {
	if (ids.find(id => id === user.id)) {
		existIds.push(user.id);
	}

	if (user.subordinates?.length) {
		user.subordinates.forEach(u => existNode(u, ids, existIds));
	}

	return existIds;
};

export const mutateTree = (users?: User[], includeIds?: number[]) => {
	if (!users?.length) {
		return [];
	}

	console.log({users});
	// First we are creating tree for all the users and its subordinates
	const userTree = users
		.filter(user => !user.reportingUserId)
		.map(user => populateSubordinates(user, users));
	if (includeIds?.length) {
		// Next we are pruning out those branch which doesn't contain the include ids
		return mutateTreeToInclude(userTree, includeIds);
	}

	return userTree;
};

export const mutateTreeToInclude = (userTree: User[], includeIds: number[]) => {
	// Return users whose children node and node itself included in the tree.
	for (let i = userTree.length - 1; i >= 0; i--) {
		const user = userTree[i];
		const existingIds = existNode(user, includeIds, []);
		if (!existingIds?.length) {
			userTree.splice(i, 1);
		}
	}

	userTree.forEach(user => {
		if (user.subordinates?.length) {
			mutateTreeToInclude(user.subordinates, includeIds);
		}
	});
	return userTree;
};
