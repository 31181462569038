import {createStore, applyMiddleware} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {createBrowserHistory} from 'history';
import {routerMiddleware as createRouterMiddleware} from 'connected-react-router';
import {compose} from 'redux';

import rootReducer from './root-reducer';
import services from '../services';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import {loadingBarMiddleware} from 'react-redux-loading-bar';

// Browser history
export const history = createBrowserHistory();

const saveToLocalStorage = (state: any) => {
	// If (state.lastAction && state.lastAction.type === ACTIONS.LOGOUT_SUCCESSFUL) {
	//   return
	// }
	try {
		const serializedState = JSON.stringify(state);
	  localStorage.setItem('appState', serializedState);
	} catch (err) {
		console.log({err});
	}
};

export const epicMiddleware = createEpicMiddleware({
	dependencies: services,
});

const routerMiddleware = createRouterMiddleware(history);

// Configure middlewares
const middlewares = [
	epicMiddleware,
	routerMiddleware,
	promiseMiddleware,
	loadingBarMiddleware(),
	thunkMiddleware,
];
// Compose enhancers
const enhancer = compose(applyMiddleware(...middlewares));

// Rehydrate state on app start
const initialState = {};

// Create store
const store = createStore(rootReducer(history), initialState, enhancer);

// EpicMiddleware.run(rootEpic);

store.subscribe(() => {
	saveToLocalStorage(store.getState());
});

// Export store singleton instance
export default store;
