import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {get as getUserPermission} from '../UserPermission/actions';
import {get as getSites} from '../Site/actions';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
} from './ViewHierarchyReport';
import {
	SiteStatus,
	ModuleName,
	ModulePermission,
	type ApprovalScheduleDelayReport,
	type User,
} from 'app/models';
import {getApprovalScheduleActivityDelayReport} from './approval-schedule-activity-delay-report-reducer';
import {formatDateFunction} from 'app/helpers';
import {
	calculateActivityDelay,
	mutateTree,
	getKey,
} from 'app/services/report-service';

export const ViewApprovalScheduleActivityDelayReport: React.FC = () => {
	const dispatch = useDispatch();
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
    = useSelector((state: Types.RootState) => state.userPermission);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.approvalScheduleActivityDelayReport,
	);
	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];
	const today = new Date();
	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		records: {},
		children: [],
	};

	// Fetching all sites under construction
	React.useEffect(() => {
		dispatch(
			getSites({
				where: {
					status: SiteStatus.UNDER_CONSTRUCTION,
				},
			}),
		);
	}, []);

	// Fetching user permissions of READ for approval schedule module,and the reports of past 5 weeks from the current date
	React.useEffect(() => {
		if (allSites.length > 0) {
			dispatch(
				getApprovalScheduleActivityDelayReport({
					where: {
						and: getQueryDateRangeForDays(
							today.toDateString(),
							0,
							34,
							'reportDate',
						),
					},
					order: ['reportDate DESC'],
				}),
			);
			dispatch(
				getUserPermission({
					where: {
						moduleName: ModuleName.APPROVAL_SCHEDULE,
						permission: ModulePermission.READ,
						siteId: {inq: allSites.map(({id}) => id ?? 0)},
					},
				}),
			);
		}
	}, [allSites]);

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];

	const mapOfRecords = reports.reduce<
	Record<string, ApprovalScheduleDelayReport[]>
	>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.slice(0, 5)
		.map(d => ({
			title: d,
			key: getKey(d),
			width: 50,
		}));

	// Creating a permissions record to uniquely identify users and the sites associated with them
	const permissionMapOfRecord = userPermissions.reduce<
	Record<number, number[]>
	>((record, item) => {
		if (!record[item.userId]) {
			record[item.userId] = [];
		}

		record[item.userId].push(item.siteId);
		return record;
	}, {});

	// Traversing the tree to assign delays to all nodes and to create sites as child nodes of approval in charges(leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.key = String(user.id);
		node.records = calculateActivityDelay(
			user,
			mapOfRecords,
			permissionMapOfRecord,
		);
		node.name = user.name;
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else if (permissionMapOfRecord[user.id]?.length) {
			node.children = permissionMapOfRecord[user.id].map(siteId => {
				const dataRecords: Record<string, number> = {};
				Object.entries(mapOfRecords).forEach(([date, records]) => {
					dataRecords[getKey(date)]
              = records.find(entry => entry.siteId === siteId)?.delayedActivities ?? 0;
				});
				const x: DataSourceType = {
					key: String(siteId),
					records: dataRecords,
					name: allSites.find(site => site.id === siteId)?.name ?? '',
				};
				return x;
			});
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites.map(({approvalsInchargeId}) => approvalsInchargeId ?? 0),
	);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);

	return (
		<DefaultLayout
			currentPath={uiPaths.approvalScheduleActivityDelayReport}
		>
			<ViewHierarchyReport
				title='Started, But not completed on time Approval Schedule Activity
        Delay Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={`
        This report presents how many approval schedule activities are in the “Started” stage but were not completed
        in their ideal duration. Data is captured every week on Wednesday at 12:00 AM in the night.
        If there is any increase in the number of activities which are in the “started” stage but not completed
        on time as compared to the previous week, it means that in the last 7 days, more activities have come
        under the category that started but not completed in the ideal duration. Similarly, If there is any
        decrease in the number compared to the previous week, it means that in the last 7 days,
        some of the activities have been marked as “Completed”.
       `}
				reportLogic={`
        Based upon the delayed completion of the Started activities of each site, total number of activities 
		"which are started but not completed in ideal duration + 2 days" is calculated for Approval Head 
		and Approval Managers under him and the list of sites is mentioned under the particular approval manager. 
		To read the report, you can expand the "+" sign in front of the Reporting Manager's name to see the 
		list of employees reporting to him. If you have any query related to this report or a suggestion 
		to improvise the report. Please contact ruchika.singh@prithu.in
      `}
			/>
		</DefaultLayout>
	);
};
