import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type ProcessChecklistResponse} from 'app/models/process-checklist-response.model';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<number, ProcessChecklistResponse>;
	allIds: number[];
	checklistResponse?: ProcessChecklistResponse;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	checklistResponse: undefined,
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

const processChecklistResponseReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('processChecklistResponse', initialState),
	action: any,
): MyState => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.removeApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.removeApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as ProcessChecklistResponse[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id ?? 0),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id ?? 0] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getSingleApi):
		case getSuccessActionType(actionTypes.createApi): {
			const item = action.payload.data as ProcessChecklistResponse;
			return {
				...state,
				...{
					checklistResponse: item,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.updateApi):
		case getSuccessActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default processChecklistResponseReducer;
