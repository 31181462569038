import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';
import {type Filter, type Where} from 'app/types/query';
import {type Approval} from './approval';

export const actionTypes = {
	changePage: '@approval/CHANGE_PAGE',
	apiFilter: '@approval/API_FILTER',
	paginationChange: '@approval/PAGINATION_CHANGE',
	getCountApi: '@approval/GET_COUNT_API',
	getApi: '@approval/GET_API',
};

const url = lbBaseUrl + '/approvals';

export const changePage = (pageType: string) => ({type: actionTypes.changePage, payload: pageType, meta: {}});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const onPaginationChange = (currentPos: number, perPageSize: number) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: Where<Approval>) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(`${url}/count?where=${JSON.stringify(where)}`, getHeaders(true)),
	meta: {process: true},
});

export const get = (filter: Filter<Approval>) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});
