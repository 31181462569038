
import type * as actions from './comment-actions';
import {actionTypes} from './comment-actions';
import {loadState} from 'app/store/helpers';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type Comment} from 'app/models';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<Comment['id'], Comment>;
	allIds: Array<Comment['id']>;
	loading: boolean;
	dataFetched: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	loading: false,
	dataFetched: false,
	dataUpdated: false,
	errorMessage: undefined,
};

const commentReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('aws', initialState),
	action: any,
) => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					dataFetched: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage: action.payload?.response?.data?.error?.message as string ?? undefined,
					loading: false,
					dataUpdated: false,
					dataFetched: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as Comment[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataFetched: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataFetched: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default commentReducer;
