import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {type FilterOptionItem} from 'app/models/ui-filter';

export type ActivityTemplateItem = {
	id: number;
	templateId?: number;
	isRootActivity?: boolean;
	name: string;
	uniqueId: string;
	sequence?: number;
	activityType: ActivityType;
	formula?: 'min' | 'max' | undefined;
	operator?: '+' | '-' | undefined;
	operandDays?: number;
	duration?: number;
	linkedUniqueIds?: string;
	linkedFromType?: 'start' | 'end' | undefined;
	linkedChecklistIds?: string;
	linkedActivities?: ActivityTemplateItem[];
	linkedChecklists?: ProcessChecklist[];
};

export enum ActivityType {
	DESIGN = 'DESIGN',
	CONSTRUCTION = 'CONSTRUCTION',
	ORDER = 'ORDER',
	APPROVALS = 'APPROVALS',
	QUANTITY = 'QUANTITY',
}

export type ActivityItem = {
	isRootActivity?: boolean;
	name: string;
	sequence?: number;
	activityType: ActivityType;
	formula?: 'min' | 'max' | undefined;
	operator?: '+' | '-' | undefined;
	operandDays?: number;
	duration?: number;
	linkedUniqueIds?: string;
	linkedFromType?: 'start' | 'end' | undefined;
	itemValues?: FilterOptionItem[];
	queryType: ActivityType;
	checklistValues?: FilterOptionItem[];
};
