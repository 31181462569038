import {type User} from 'app/models/user.model';
import {type Site} from 'app/models/site.model';
import {type Attachment} from 'app/models/attachment.model';
import {type Category} from 'app/components/Category/category';
import {type WorkOrder} from 'app/models';

export type WorkOrderQuery = {
	id: number;
	siteId: number;
	content: string;
	status?: WorkOrderQueryStatus;
	categoryId?: number;
	subCategoryId?: number;
	workOrderId?: number;
	approvedLevel?: number;
	nextApprovedBy?: number;
	cancelReason?: string;
	cancelReasonType?: CancelReasonType;
	targetDate?: string;
	leadTargetDate?: string;
	workOrderType?: WorkOrderQueryOrderType;
	drawingAvailability?: WorkOrderQueryDrawingAvailability;
	createdAt?: string;
	updatedAt?: string;
	approvedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	approvedBy?: number;
	attachments?: Attachment[];
	createdUser?: User;
	approvedUser?: User;
	nextApprovedUser?: User;
	site: Site;
	category?: Category;
	subCategory?: Category;
	workOrder?: WorkOrder;
};

export enum WorkOrderQueryStatus {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	CANCELED = 'CANCELED',
	RESOLVED = 'RESOLVED',
	DRAWING_NOT_AVAILABLE = 'DRAWING_NOT_AVAILABLE',
	QUANTITY_UNDER_REVIEW = 'QUANTITY_UNDER_REVIEW',
	CONTRACTOR_TENDERING = 'CONTRACTOR_TENDERING',
	CONTRACTOR_DISCUSSION = 'CONTRACTOR_DISCUSSION',
	WORK_ORDER_UPLOAD = 'WORK_ORDER_UPLOAD',
	WORK_ORDER_PUBLISHED = 'WORK_ORDER_PUBLISHED',
	WORK_ORDER_APPOINTED = 'WORK_ORDER_APPOINTED',
	WORK_STARTED = 'WORK_STARTED',
}

export const workOrderQueryStatusLabel: Record<string, string> = {
	[WorkOrderQueryStatus.PENDING]: 'Pending',
	[WorkOrderQueryStatus.APPROVED]: 'Approved',
	[WorkOrderQueryStatus.CANCELED]: 'Canceled',
	[WorkOrderQueryStatus.RESOLVED]: 'Resolved',
	[WorkOrderQueryStatus.WORK_ORDER_APPOINTED]: 'Work Order Appointed',
};

export enum WorkOrderQueryOrderType {
	NEW = 'NEW',
	EXISTING = 'EXISTING',
}

export const workOrderQueryOrderTypeLabel = {
	[WorkOrderQueryOrderType.NEW]: 'New Work Order',
	[WorkOrderQueryOrderType.EXISTING]: 'Change/Addition in Existing Work Order',
};

export enum WorkOrderQueryDrawingAvailability {
	YES = 'YES',
	NO = 'NO',
	NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum CancelReasonType {
	WRONG_CATEGORY = 'WRONG_CATEGORY',
	OTHERS = 'OTHERS',
}
