import React from 'react';
import {
	Col,
	Row,
	Spin,
	Table,
} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {get} from './actions';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {formatDateFunction, toTitleCase} from 'app/helpers';
import {type ProcessChecklistResponse} from 'app/models/process-checklist-response.model';
import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {Link} from 'react-router-dom';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';
import {type Approval} from './approval';
import type Types from 'MyTypes';

export const ViewApprovalChecklistResponse: React.FC = () => {
	const dispatch = useDispatch();
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {loading, byIds, allIds} = useSelector((state: Types.RootState) => state.approval);
	const approvals: Approval[] = allIds.map(id => byIds[id]);
	const dataSource = approvals?.reduce<ProcessChecklistResponse[]>((items, approval) => {
		if (approval.processChecklistResponse) {
			items.push(approval.processChecklistResponse);
		}

		return items;
	}, []);

	React.useEffect(() => {
		dispatch(get({where: {nextApprovedBy: authUser?.id ?? 0}}));
	}, []);

	return (
		<DefaultLayout currentPath={uiPaths.checklistResponseApproval}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Table
							size={'small'}
							bordered={true}
							dataSource={dataSource}
							pagination={false}
						>
							<Table.Column
								title='Site'
								dataIndex='site'
								key='site'
								render={(text: string, response: ProcessChecklistResponse) =>
									response?.drawingSchedule ? response?.drawingSchedule?.site?.name : response?.projectProcessSchedule?.site?.name}
							/>
							<Table.Column
								title='Checklist'
								dataIndex='processChecklist'
								key='processChecklist'
								render={(processChecklist: ProcessChecklist, response: ProcessChecklistResponse) => (
									<Link
										to={{
											pathname: `/${uiPaths.viewProcessChecklistResponseDetail
												.replace(':scheduleId', String(response.projectProcessScheduleId ?? '0'))
												.replace(':drawingScheduleId', String(response.drawingScheduleId ?? '0'))
												.replace(':checklistId', processChecklist?.id ?? '0')
												.replace(':siteId', String(response.projectProcessSchedule?.siteId ?? '0'))
												.replace(':processType', String(response.drawingScheduleId ? ProjectProcessType.DESIGN : response.projectProcessSchedule?.projectProcessType))}`,
										}}
									>
										{processChecklist?.title ?? 'N/A'}
									</Link>
								)}
							/>
							<Table.Column
								title='Work Activity'
								dataIndex='workActivity'
								key='workActivity'
								render={(text: string, response: ProcessChecklistResponse) => response?.drawingScheduleId
									? (response?.drawingSchedule?.projectProcessMaster?.processName ?? 'N/A')
									: (response?.projectProcessSchedule?.projectProcessMaster?.processName ?? 'N/A')
								}
							/>
							<Table.Column
								title='Floor Name'
								dataIndex='workActivity'
								key='workActivity'
								render={(text: string, response: ProcessChecklistResponse) => response?.drawingScheduleId
									? (response?.drawingSchedule?.projectProcessMaster?.workActivityMaster?.floorName ?? 'N/A')
									: (response?.projectProcessSchedule?.projectProcessMaster?.workActivityMaster?.floorName ?? 'N/A')
								}
							/>
							<Table.Column
								title='Submission Details'
								dataIndex='submittedAt'
								key='submittedAt'
								render={(submittedAt: string) =>
									formatDateFunction(submittedAt)
								}
							/>
							<Table.Column
								title='Checklist Status'
								dataIndex='status'
								key='status'
								render={status =>
									toTitleCase(String(status))
								}
							/>
						</Table>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
