import {Button, Card, Descriptions, Form, message, Select, Space} from 'antd';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {ClientNumbers, type User, UserType} from 'app/models';
import {saveClients} from '../actions';
import {ProjectClientForm} from './ProjectClientForm';
import {PlusOutlined} from '@ant-design/icons';

type Props = {
	canEdit: boolean;
	siteId: number;
	currentValue?: User[];
};

export const EditClientForm: React.FC<Props> = ({canEdit, siteId, currentValue}) => {
	const dispatch = useDispatch();
	const emptyClient: User = {email: '', id: 0, name: '', username: '', userType: UserType.CLIENT, panNumber: ''};
	const [isEditing, setIsEditing] = useState(false);
	const [clients, setClients] = useState<User[]>(currentValue?.length ? [...currentValue] : [{...emptyClient}]);
	const [clientOneOrMany, setClientOneOrMany] = useState<string>(ClientNumbers.ONE);

	const onProjectClientChange = (i: number, value: User) => {
		const items = [...clients];
		items[i] = value;
		setClients(items);
	};

	const onProjectClientAdd = () => {
		const items = [...clients];
		items.push({...emptyClient} as User);
		setClients(items);
	};

	const onProjectClientRemove = (i: number) => {
		const items = [...clients];
		items.splice(i, 1);
		setClients(items);
	};

	const checkMembersMandatoryFields = (client: User) => {
		if (client?.members?.length) {
			return client?.members?.every(member => member.name && member.email);
		}

		return true;
	};

	const saveClient = () => {
		let submit = true;
		clients.forEach(client => {
			if (!client.name || !client.email || !client.panNumber || !checkMembersMandatoryFields(client)) {
				void message.error('Please enter all the mandatory fields.');
				submit = false;
			}
		});

		if (submit) {
			clients.forEach(client => {
				const data = {
					id: client.id ?? 0,
					name: client.name,
					username: client.email,
					password: '',
					members: client.members ?? [],
					email: client.email,
					mobileNumbers: client.mobileNumbers ?? '',
					clientCurrentAddress: client.clientCurrentAddress,
					panNumber: client.panNumber,
					isActive: true,
					shareCredential: client.shareCredential,
				};
				dispatch(saveClients(siteId, data));
			});
		}
	};

	return (
		isEditing ? (
			<Card>
				<Select
					style={{width: 250}}
					placeholder='Select number of decision makers'
					optionFilterProp='children'
					dropdownStyle={{
						marginBottom: '10px',
					}}
					defaultValue={ClientNumbers.ONE}
					onChange={(value: ClientNumbers) => {
						setClientOneOrMany(value);
					}}
					// C filterOption={(input, option) => String(option?.label).includes(input)}
				>
					<Select.Option key={0} value={ClientNumbers.ONE}>One</Select.Option>,
					<Select.Option key={1} value={ClientNumbers.MANY}>Many</Select.Option>,
				</Select>
				<br/>
				{clients.map((client, ix) => (
					<Card
						key={ix}
						size={'small'}
						actions={clients.length > 1 ? [
							<Button key={ix} type={'dashed'} danger={true} onClick={() => {
								onProjectClientRemove(ix);
							}}>Remove Client</Button>,
						] : []}
					>
						<ProjectClientForm
							canAddClient={true}
							client={client}
							onChange={(value: User) => {
								onProjectClientChange(ix, value);
							}}
						/>
					</Card>
				))}
				{(clientOneOrMany === String(ClientNumbers.MANY)) ? (
					<Form.Item>
						<Button
							disabled={false}
							type='dashed'
							onClick={() => {
								onProjectClientAdd();
							}}
							block
							icon={<PlusOutlined />}
						>
              Add Client
						</Button>
					</Form.Item>
				) : []}
				<br/>
				<Form.Item>
					<Button type='primary' onClick={() => {
						saveClient();
					}}>Save</Button>
					{' '}
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Form.Item>
			</Card>
		) : (
			<Space direction='vertical'>
				{currentValue?.map((client, ix) => (
					<Card key={ix} size={'small'}>
						<Descriptions column={1}>
							<Descriptions.Item label='Name'>{client.name}</Descriptions.Item>
							<Descriptions.Item label='Email'>{client.email}</Descriptions.Item>
							<Descriptions.Item label='Pan Card'>{client.panNumber}</Descriptions.Item>
							<Descriptions.Item label='Mobile Number(s)'>{client.mobileNumbers}</Descriptions.Item>
							<Descriptions.Item label='Current Address'>{client.clientCurrentAddress}</Descriptions.Item>
							<Descriptions.Item label='Family Members'>
								{client.members?.map((member, ixx) => (
									<Card key={ixx} size={'small'}>
										<Descriptions column={1}>
											<Descriptions.Item label='Name'>{member.name}</Descriptions.Item>
											<Descriptions.Item label='Email'>{member.email}</Descriptions.Item>
											<Descriptions.Item label='Pan Card'>{member.panNumber}</Descriptions.Item>
											<Descriptions.Item label='Mobile Number(s)'>{member.mobileNumbers}</Descriptions.Item>
											<Descriptions.Item label='Current Address'>{member.clientCurrentAddress}</Descriptions.Item>
										</Descriptions>
									</Card>
								))}
							</Descriptions.Item>
						</Descriptions>
					</Card>
				))}
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
