import {Button, Col, Form, Input, message, Row, Select, Spin, Typography} from 'antd';
import {uiPaths} from 'app/constants';
import {toTitleCase} from 'app/helpers';
import {type Site, ProjectType, SiteStatus, ProjectCity} from 'app/models';
import {type FilterOptionItem} from 'app/models/ui-filter';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {reset, save, getProjectNumber} from './actions';
import type Types from 'MyTypes';
import {useHistory} from 'react-router';

export const ViewProjectCreateForm: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const canWrite = true;
	const statusOptions = Object.values(SiteStatus).map(status => ({
		value: status,
		label: toTitleCase(status as string) ?? '',
	}));

	const {loading, projectNumber, newSite, dataUpdated} = useSelector((state: Types.RootState) => state.site);

	const [projectType, setProjectType] = useState(undefined);
	const [projectCity, setProjectCity] = useState(undefined);
	const [address, setAddress] = useState('');
	const [status, setStatus] = useState(undefined);

	useEffect(() => {
		dispatch(reset());
		dispatch(getProjectNumber());
	}, []);

	useEffect(() => {
		if (dataUpdated && newSite?.id) {
			history.push({
				pathname: `/${uiPaths.editSite.replace(':siteId', newSite.id.toString())}`,
			});
		}
	}, [dataUpdated, newSite]);

	const handleSubmit = () => {
		if (!projectNumber) {
			void message.error('Something went wrong. Please try again.');
			return;
		}

		if (!projectType || !projectType || !address || !status) {
			void message.error('Please enter mandatory fields.');
			return;
		}

		const data: Site = {
			projectNumber,
			projectType,
			projectCity,
			address,
			status,
			name: 'NA',
			id: 0,
		};

		dispatch(save(data));
	};

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};

	return (
		<DefaultLayout>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24} className='text-center'>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Create New Project
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Button onClick={e => {
						history.goBack();
					}}>Back</Button>
					<Col span={24}>
						<Form
							{...layout}
							name='basic'
						>
							<Form.Item label={<b>{'Name*'}</b>}>
								<Input.Group compact>
									<Button disabled={true}>{projectNumber}</Button>
									<Button disabled={true}>/</Button>
									<Select
										id={'SelectProjectType'}
										disabled={!canWrite}
										style={{width: '20%'}}
										defaultValue={projectType}
										placeholder={'Select Project Type'}
										value={projectType}
										onChange={value => {
											setProjectType(value);
										}}
									>
										<Select.Option value=''>Select Project Type</Select.Option>
										{Object.values(ProjectType).map((value, ix) => (
											<Select.Option key={ix} value={value}>{value}</Select.Option>
										))}
									</Select>
									<Button disabled={true}>/</Button>
									<Select
										id={'SelectProjectCity'}
										disabled={!canWrite}
										style={{width: '20%'}}
										placeholder={'Select Project City'}
										defaultValue={projectCity}
										value={projectCity}
										onChange={value => {
											setProjectCity(value);
										}}
									>
										<Select.Option value=''>Select Project City</Select.Option>
										{Object.values(ProjectCity).map((value, ix) => (
											<Select.Option key={ix} value={value}>{value}</Select.Option>
										))}
									</Select>
									<Button disabled={true}>/</Button>
									<Input
										id={'EnterAddress'}
										disabled={!canWrite}
										style={{width: '30%'}}
										placeholder={'Enter Project Address'}
										value={address}
										required={true}
										onChange={(e: any) => {
											setAddress(String(e.target.value));
										}}
									/>
								</Input.Group>
							</Form.Item>

							<Form.Item label={<b>{'Status*'}</b>}>
								<Select
									id={'SelectStatus'}
									disabled={!canWrite}
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Status'
									optionFilterProp='children'
									value={status}
									defaultValue={status}
									onChange={value => {
										setStatus(value);
									}}
									// C filterOption={(input, option) => String(option?.label).includes(input)}
								>

									{statusOptions.map((option: FilterOptionItem, ix: number) =>
										<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
									)}
								</Select>
							</Form.Item>

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={handleSubmit} id={'Submit'}>Submit</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
