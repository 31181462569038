import {type Attachment} from './attachment.model';
import {type User} from './user.model';
import {type Site} from './site.model';
import {type UserRole} from './enums/user-role.enum';
import {type PaidExpense} from './paid-expense.model';
import {type WorkOrder} from './work-order.model';
import {type Comment} from 'app/models/comment.model';

export type ExpenseReport = {
	id: number;
	siteId: number;
	reportDate: string;
	amountReceived?: number;
	expenseDescription?: string;
	foodBeverageExpense?: number;
	siteLoadingUnloadingExpense?: number;
	siteMaterialExpense?: number;
	siteStationeryExpense?: number;
	conveyance?: number;
	labourExpense?: number;
	waterAndElectricityBill?: number;
	laisioningExpense?: number;
	malwaRemoval?: number;
	scrapAmount?: number;
	solarAndWaterAutomation?: number;
	otherExpense?: number;
	closingBalance: number;
	status: ExpenseStatus;
	approver?: UserRole;
	cancelReason?: string;
	sno: number;
	paidExpenseId: number;
	debitedToContractor?: boolean;
	notDebitedToContractorReason?: string;
	contractorInformed?: boolean;
	contractorInformedFiles?: string;
	contractorNotInformedReason?: string;
	debitWorkOrderId?: number;
	createdAt: string;
	updatedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	approvedLevel?: number;
	nextApprovedBy?: number;
	attachments: Attachment[];
	comments: Comment[];
	prithuWalletId?: number;
	createdUser?: User;
	updatedUser?: User;
	nextApprovedUser?: User;
	site?: Site;
	paidExpense?: PaidExpense;
	debitWorkOrder?: WorkOrder;
	notifyCount?: number;
};

export enum ExpenseType {
	FOOD_BEVERAGE_EXPENSE = 'FOOD_BEVERAGE_EXPENSE',
	SITE_LOADING_UNLOADING_EXPENSE = 'SITE_LOADING_UNLOADING_EXPENSE',
	SITE_MATERIAL_EXPENSE = 'SITE_MATERIAL_EXPENSE',
	SITE_STATIONERY_EXPENSE = 'SITE_STATIONERY_EXPENSE',
	CONVEYANCE = 'CONVEYANCE',
	LABOUR_EXPENSE = 'LABOUR_EXPENSE',
	WATER_AND_ELECTRICITY_BILL = 'WATER_AND_ELECTRICITY_BILL',
	LAISIONING_EXPENSE = 'LAISIONING_EXPENSE',
	MALWA_REMOVAL = 'MALWA_REMOVAL',
	SCRAP_AMOUNT = 'SCRAP_AMOUNT',
	SOLAR_AND_WATER_AUTOMATION = 'SOLAR_AND_WATER_AUTOMATION',
	OTHER_EXPENSE = 'OTHER_EXPENSE',
}

export const fieldMap = {
	[ExpenseType.FOOD_BEVERAGE_EXPENSE]: 'foodBeverageExpense',
	[ExpenseType.SITE_LOADING_UNLOADING_EXPENSE]: 'siteLoadingUnloadingExpense',
	[ExpenseType.SITE_MATERIAL_EXPENSE]: 'siteMaterialExpense',
	[ExpenseType.SITE_STATIONERY_EXPENSE]: 'siteStationeryExpense',
	[ExpenseType.CONVEYANCE]: 'conveyance',
	[ExpenseType.LABOUR_EXPENSE]: 'labourExpense',
	[ExpenseType.WATER_AND_ELECTRICITY_BILL]: 'waterAndElectricityBill',
	[ExpenseType.LAISIONING_EXPENSE]: 'laisioningExpense',
	[ExpenseType.MALWA_REMOVAL]: 'malwaRemoval',
	[ExpenseType.SCRAP_AMOUNT]: 'scrapAmount',
	[ExpenseType.SOLAR_AND_WATER_AUTOMATION]: 'solarAndWaterAutomation',
	[ExpenseType.OTHER_EXPENSE]: 'otherExpense',
};

export enum ExpenseStatus {
	PENDING = 'PENDING',
	PRE_APPROVED = 'PRE_APPROVED',
	CANCELLED = 'CANCELLED',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	REFUND = 'REFUND',
	RECEIVED = 'RECEIVED',
}
