import {type User} from './user.model';

export type WorkOrderDebit = {
	id: number;
	workOrderId?: number;
	workOrderBillId?: number;
	labourRequestId?: number;
	expenseReportId?: number;
	extraWorkOrderBillId?: number;
	amount: number;
	description?: string;
	debitType?: WorkOrderDebitType;
	addedAt?: string;
	addedBy?: number;
	validFrom?: string;
	addedByUser?: User;
};

export enum WorkOrderDebitType {
	// LABOUR_REQUEST = 'LABOUR_REQUEST',
	EXTRA_WORK_ORDER_BILL = 'EXTRA_WORK_ORDER_BILL',
	LABOUR_EXPENSE = 'LABOUR_EXPENSE',
}
