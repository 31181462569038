import React from 'react';
import {useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {uiPaths} from 'app/constants';
import {ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {ViewProjectProcessSchedule} from 'app/components/ProjectProcessSchedule/ViewProjectProcessSchedule';
import {ModuleName, ModulePermission, UserType} from 'app/models';
import {getPermissionSites} from 'app/helpers';
import {Redirect} from 'react-router';

export const ViewApprovalSchedule: React.FC = () => {
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const sites = getPermissionSites(permissions, ModuleName.APPROVAL_SCHEDULE, ModulePermission.READ, allSites);
	const approveSites = getPermissionSites(permissions, ModuleName.APPROVAL_SCHEDULE, ModulePermission.APPROVE, allSites);
	return (
		<>
			{authUser?.userType === UserType.CLIENT ? (
				<Redirect to={'/' + uiPaths.clientApprovalSchedule} />
			) : (
				<DefaultLayout currentPath={uiPaths.approvalSchedule}>
					<ViewProjectProcessSchedule
						title={'Approval Schedule'}
						projectProcessType={ProjectProcessType.APPROVALS}
						sites={sites}
						approveSites={approveSites}
					/>
				</DefaultLayout>
			)}
		</>
	);
};
