import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Empty,
	message,
	Popconfirm,
	Row,
	Space,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
	remove,
} from './actions';
import {Link, useParams} from 'react-router-dom';
import {isMobile, parseNum, toTitleCase} from 'app/helpers';
import {FilterFormType} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';
import {type ActivityTemplateItem, ActivityType} from './activity-template-item';
import {CheckCircleOutlined} from '@ant-design/icons';

type MyParams = {
	templateId?: string;
};

export const ViewActivityTemplateItem: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams<MyParams>();

	const templateId = parseNum(params?.templateId);
	const {
		loading,
		byIds,
		allIds,
		filterValue,
		dataUpdated,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
	} = useSelector((state: Types.RootState) => state.activityTemplateItem);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			if (filterValue) {
				filterData(filterValue, currentPos, perPageSize);
			} else {
				filterData();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {templateId},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f?.activityType) {
			filter.where.activityType = f.activityType;
		}

		if (f.name) {
			filter.where.name = {like: `%25${f.name as string}%25`};
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const typeOptions = Object.values(ActivityType).map((status: string) => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	const dataSource: ActivityTemplateItem[] = allIds?.length
		? allIds.map(id => byIds[id]).sort((a, b) => {
			if (a.sequence && b.sequence) {
				return a.sequence > b.sequence ? 1 : -1;
			}

			return -1;
		})
		: [];

	const nameRecord: Record<string, string> = dataSource.reduce((record, item) => {
		record[item.uniqueId] = item.name;
		return record;
	}, {});

	const uiFilters = [
		{
			filterKey: 'activityType',
			items: typeOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Type',
			label: 'Activity Type',
			defaultValue: filterValue ? filterValue.activityType : '',
		},
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Name',
			label: 'Name',
			defaultValue: filterValue ? filterValue.name : '',
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.activityTemplateItems}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Activity Template Items
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f1: FilterDataType) => {
								filterData(f1);
							}}
						/>
					</Col>
				</Row>
				<br />

				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Link
							to={{
								pathname: `/${uiPaths.addActivityTemplateItem.replace(
									':templateId',
									String(templateId),
								)}`,
							}}
						>
							<Button type='primary'>
								Add new Item
							</Button>
						</Link>
						<br />
						<br />
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								pageSizeOptions={['10', '50', '100', '500', '1000', '5000']}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
							>
								<Table.Column
									title='Sequence'
									dataIndex='sequence'
									key='sequence'
								/>
								<Table.Column
									title='Name'
									dataIndex='name'
									key='name'
								/>
								<Table.Column
									title='Logic'
									dataIndex='linkedUniqueIds'
									key='linkedUniqueIds'
									render={(linkedUniqueIds: string | undefined, item: ActivityTemplateItem) =>
										linkedUniqueIds
											? `${item.linkedFromType === 'end' ? 'End date' : 'Start Date'} of ${item.formula === 'min' ? 'Min' : 'Max'}(${item.linkedUniqueIds?.split(',').map(uid => nameRecord[uid]).join(',')}) ${item.operator} ${item.operandDays}`
											: []
									}
								/>
								<Table.Column
									title='Duration'
									dataIndex='duration'
									key='duration'
								/>
								<Table.Column
									title='Checklists'
									dataIndex='linkedChecklistIds'
									key='linkedChecklistIds'
									render={(linkedChecklistIds: string | undefined) =>
										linkedChecklistIds ? (
											<Space>
												<CheckCircleOutlined />
											</Space>
										) : []
									}
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(_: string, item: ActivityTemplateItem) => (
										<Space direction={isMobile ? 'vertical' : 'horizontal'}>
											<Link
												to={{
													pathname: `/${uiPaths.editActivityTemplateItem.replace(
														':id',
														String(item.id),
													)}`,
												}}
											>
												<Button size={'small'} type='primary'>
													Edit
												</Button>
											</Link>
											<Popconfirm
												title={'Are you sure you want to remove this?'}
												onConfirm={() => {
													dispatch(remove(item.id));
												}}
												okText='Yes'
												cancelText='No'
											>
												<Button type={'primary'} size={'small'} danger={true}>
													Delete
												</Button>
											</Popconfirm>
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
