import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {equalNum, formatDateFunction, getCurrencyString, getS3Url, parseNum, toTitleCase} from 'app/helpers';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {
	Button,
	Col,
	Empty,
	Input,
	Modal,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import {type ExpenseReport, ExpenseStatus, PaidExpenseStatus, type Site, type Attachment, ExpenseType, fieldMap} from 'app/models';
import {FilterFormType, type FilterOptionItem, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {log} from 'app/services/logger-service';
import {CheckOutlined, CloseOutlined, PaperClipOutlined} from '@ant-design/icons';
import {getQueryDateRange} from 'app/query_helpers';
import {CSVLink} from 'react-csv';
import {
	approve,
	changeAllPage,
	count,
	get,
	getUserWallet,
	initPage,
	onPaginationChange,
	reject,
	setFilterValue,
} from './actions';
import {get as getAllWallet} from 'app/components/Wallet/actions';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {type History} from 'history';
import {Link} from 'react-router-dom';
import {expensePathType} from './constants';

type OwnProps = {
	history: History;
	reportStatus?: ExpenseStatus;
	toBeApproved?: boolean;
	canViewPaid?: boolean;
	pageType?: string;
	sites: Site[];
};

const mapStateToProps = ({
	expenseReport,
	prithuWallet: {wallets},
	summary: {user: authUser, subordinateOptions: userOptions},
}: Types.RootState, ownProps: OwnProps) => ({
	...expenseReport,
	wallets,
	userOptions,
	authUser,
	...ownProps,
});

const mapDispatchToProps = {
	initPage,
	get,
	count,
	onPaginationChange,
	setFilterValue,
	getUserWallet,
	approve,
	reject,
	changeAllPage,
	getAllWallet,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

type State = {
	dev: boolean;
	changeApproverId?: number;
	newApprover?: string;
	cancelReason: string;
	cancelExpenseId?: number;
	csvData: any[];
};

class ViewAllExpenseReport extends React.Component<Props, State> {
	reportStatusOptions: FilterOptionItem[];
	expenseTypeOptions: FilterOptionItem[];

	constructor(props: Props) {
		super(props);

		this.state = {
			dev: false,
			cancelReason: '',
			cancelExpenseId: undefined,
			csvData: [],
		};

		this.reportStatusOptions = Object.values(ExpenseStatus).map(status => ({
			value: status.toString(),
			label: toTitleCase(status.toString(), '_') ?? '',
		}));
		this.reportStatusOptions.push({value: '', label: 'None'});

		this.expenseTypeOptions = Object.values(ExpenseType).map(status => ({
			value: status.toString(),
			label: toTitleCase(status.toString(), '_') ?? '',
		}));
		this.expenseTypeOptions.push({value: '', label: 'None'});
	}

	componentDidMount() {
		log('ViewAllExpenseReport.componentDidMount');
		if (this.props.pageType !== this.props.allPageType) {
			if (this.props.pageType) {
				this.props.changeAllPage(this.props.pageType);
			}
		}

		if (this.props.pageType === expensePathType.all) {
			this.props.getAllWallet({});
		}

		if (this.props.authUser) {
			this.props.getUserWallet(this.props.authUser.id);
		}

		if (this.props.filterValue) {
			this.filterData(this.props.filterValue);
		} else {
			this.filterData();
		}
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			if (this.props.filterValue) {
				this.filterData(this.props.filterValue, this.props.currentPos, this.props.perPageSize);
			} else {
				this.filterData();
			}
		}
	}

	getCsvData = (reports: ExpenseReport[]) => ([
		['Site Name', 'N/A'],
		['User Name', 'N/A'],
		[
			'Date',
			'Time',
			'S No',
			'Amount received',
			'Description',
			'Food and Beverage',
			'Site Loading-unloading',
			'Site Material',
			'Site Stationery',
			'Conveyance',
			'Labour',
			'Water and Electricity Bill',
			'Laisioning Expense',
			'Malwa Removal',
			'Scrap Amount',
			'Solar and Water Automation',
			'Other',
			'User',
			'Site',
		],
		...reports.map(report => ([
			formatDateFunction(report.createdAt, false),
			new Date(report.createdAt).toLocaleTimeString(),
			report.sno ? report.sno : 'N/A',
			report.amountReceived,
			report.expenseDescription,
			report.foodBeverageExpense,
			report.siteLoadingUnloadingExpense,
			report.siteMaterialExpense,
			report.siteStationeryExpense,
			report.conveyance,
			report.labourExpense,
			report.waterAndElectricityBill,
			report.laisioningExpense,
			report.malwaRemoval,
			report.scrapAmount,
			report.solarAndWaterAutomation,
			report.otherExpense,
			report.createdUser ? report.createdUser.name : 'N/A',
			report.site ? report.site.name : 'N/A',
		])),
	]);

	filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const {userOptions, sites, toBeApproved, authUser, userWallet, reportStatus} = this.props;
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: (currentPos ? perPageSize * (currentPos - 1) : 0),
			order: [
				'createdAt DESC',
			],
			include: [
				{relation: 'attachments'},
			],
		};

		const orQuery: any[] = [];

		if (toBeApproved) {
			filter.where.nextApprovedBy = authUser?.id;
		} else if (reportStatus) {
			filter.where.status = reportStatus;
			if (userWallet) {
				filter.where.prithuWalletId = userWallet.id;
			}
		} else if (f.walletId) {
			filter.where.prithuWalletId = f.walletId;
		} else {
			orQuery.push({
				createdBy: {inq: [...userOptions.map(({value}) => parseNum(value)), authUser?.id]},
			});
			orQuery.push({
				siteId: {inq: sites.map(({id}) => id)},
			});
		}

		if (orQuery.length) {
			filter.where.and = [{or: orQuery}];
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.userId) {
			filter.where.createdBy = f.userId;
		}

		if (f.date1 && f.date2) {
			if (!filter.where.and) {
				filter.where.and = [];
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(...getQueryDateRange(f.date1 as string, f.date2 as string, 'reportDate'));
		}

		if (f.reportStatus) {
			filter.where.status = f.reportStatus;
		}

		if (f.expenseType && fieldMap[f.expenseType as string]) {
			// Directly return the Boolean value of the item's expense property
			// return Boolean(item[fieldMap[f.expenseType as string]]);

			filter.where[fieldMap[f.expenseType as string]] = {neq: 0};
		}

		this.props.setFilterValue(f);

		if (perPageSize && currentPos) {
			this.props.get(filter);
		} else {
			this.props.count(filter.where);
		}
	};

	handleApproveClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to approve.')) {
			this.props.approve(id);
		}
	};

	handleRejectClick = (id: number) => {
		this.setState({cancelExpenseId: id});
	};

	handleRejectExpenseOk = () => {
		const {cancelReason, cancelExpenseId: id} = this.state;
		this.setState({cancelExpenseId: undefined});
		if (id) {
			this.props.reject(id, {cancelReason});
		}
	};

	handleRejectExpenseCancel = () => {
		this.setState({cancelExpenseId: undefined});
	};

	render() {
		const {
			authUser,
			filterValue,
			userOptions,
			sites,
			loading,
			totalCount,
			currentPos,
			perPageSize,
			byIds,
			allIds,
			wallets,
			canViewPaid,
		} = this.props;

		const reports = allIds?.length ? allIds.map((id: number) => byIds[id]) : [];

		const siteOptions: FilterOptionItem[] = sites.map(site => ({label: site.name, value: String(site.id)}));
		const walletOptions: FilterOptionItem[] = wallets.map(wallet => ({label: wallet.user?.name ?? 'N/A', value: String(wallet.id)}));

		const uiFilters: UiFilter[] = [
			{
				filterKey: 'date',
				formType: FilterFormType.DATE_RANGE,
				label: 'Date',
				defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
			},
		];

		uiFilters.push({
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			formWidth: 240,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		});

		uiFilters.push({
			filterKey: 'userId',
			items: userOptions,
			formType: FilterFormType.SELECT,
			formWidth: 240,
			placeholder: 'Select User',
			label: 'User',
			defaultValue: filterValue?.userId,
		});

		uiFilters.push({
			filterKey: 'expenseType',
			items: this.expenseTypeOptions,
			formType: FilterFormType.SELECT,
			formWidth: 240,
			placeholder: 'Select Type',
			label: 'Expense Type',
			defaultValue: filterValue?.expenseType,
		});

		if (!this.props.reportStatus && !this.props.toBeApproved) {
			uiFilters.push({
				filterKey: 'reportStatus',
				items: this.reportStatusOptions,
				formType: FilterFormType.SELECT,
				formWidth: 120,
				placeholder: 'Select Status',
				label: 'Status',
				defaultValue: filterValue?.reportStatus,
			});
		}

		if (canViewPaid) {
			uiFilters.push({
				filterKey: 'walletId',
				items: walletOptions,
				formType: FilterFormType.SITE_SELECT,
				formWidth: 240,
				placeholder: 'Select Wallet',
				label: 'Wallet',
				defaultValue: filterValue?.walletId,
			});
		}

		const columns = [
			{
				title: 'Serial No.', dataIndex: 'serialNo', key: 'serialNo',
				render(serialNo: string, record: any) {
					let color: string;
					if (record.status === ExpenseStatus.APPROVED) {
						color = 'green';
					} else if (record.status === ExpenseStatus.REJECTED) {
						color = 'red';
					} else if (record.status === ExpenseStatus.REFUND) {
						color = 'blue';
					} else {
						color = 'orange';
					}

					return (
						<>
							{serialNo}
							<br />
							<Tag color={color} key={serialNo}>
								{record.status ?? ExpenseStatus.PENDING}
							</Tag>
						</>
					);
				},
			},
			{title: 'Date', dataIndex: 'date', key: 'date'},
			{
				title: 'Expense', dataIndex: 'expenseDescription', key: 'expenseDescription', render: (desc: string, record: ExpenseReport) => (
					<Link to={{pathname: `/${uiPaths.pettyCashDetail.replace(':id', String(record.id))}`}}>
						{desc ?? 'N/A'}
					</Link>
				),
			},
			{
				title: 'Attachments', dataIndex: 'attachments', key: 'attachments', render: (attachments: Attachment[]) => (
					<Space>
						{attachments?.length
							? attachments.map((attachment, ixx: number) => (
								<a key={ixx} href={getS3Url(attachment.key ?? '')} title={attachment.name} target='_blank' rel='noreferrer'>
									<PaperClipOutlined />
								</a>
							))
							: []}
					</Space>
				),
			},
			{
				// eslint-disable-next-line complexity
				title: 'Expense Amount', dataIndex: 'amount', key: 'amount', render: (text: string, record: ExpenseReport) => (
					<Space direction={'vertical'}>
						<span>
							{record.foodBeverageExpense ? `Food and Beverage: ${getCurrencyString(record.foodBeverageExpense * -1)}` : ''}
							{record.siteLoadingUnloadingExpense ? `Site Loading-unloading: ${getCurrencyString(record.siteLoadingUnloadingExpense * -1)}` : ''}
							{record.siteMaterialExpense ? `Site Material: ${getCurrencyString(record.siteMaterialExpense * -1)}` : ''}
							{record.siteStationeryExpense ? `Site Stationery: ${getCurrencyString(record.siteStationeryExpense * -1)}` : ''}
							{record.conveyance ? `Conveyance: ${getCurrencyString(record.conveyance * -1)}` : ''}
							{record.labourExpense ? `Labour: ${getCurrencyString(record.labourExpense * -1)}` : ''}
							{record.waterAndElectricityBill ? `Water and Electricity Bill: ${getCurrencyString(record.waterAndElectricityBill * -1)}` : ''}
							{record.laisioningExpense ? `Laisioning Expense: ${getCurrencyString(record.laisioningExpense * -1)}` : ''}
							{record.malwaRemoval ? `Malwa Removal: ${getCurrencyString(record.malwaRemoval * -1)}` : ''}
							{record.scrapAmount ? `Scrap Amount: ${getCurrencyString(record.scrapAmount * -1)}` : ''}
							{record.solarAndWaterAutomation ? `Solar and Water Automation: ${getCurrencyString(record.solarAndWaterAutomation * -1)}` : ''}
							{record.otherExpense ? `Other: ${getCurrencyString(record.otherExpense * -1)}` : ''}
							{record.amountReceived ? `Amount received: ${getCurrencyString(Number(record.amountReceived))}` : ''}
						</span>
						{record.labourExpense
							&& (!record.status || record.status === ExpenseStatus.PENDING) ? (
								<>
									<span>
										<Typography.Text type={'secondary'}>Debited To Contractor:</Typography.Text>
										{' '}
										{record.debitedToContractor ? 'Yes' : 'No'}
									</span>
									{record.debitedToContractor ? (
										<span>
											<Typography.Text type={'secondary'}>Debit Work Order:</Typography.Text>
											{' '}
											{record.debitWorkOrder ? record.debitWorkOrder.title : ''}
										</span>
									) : (
										<span>
											<Typography.Text type={'secondary'}>Not Debited To Contractor Reason:</Typography.Text>
											{' '}
											{record.notDebitedToContractorReason ?? ''}
										</span>
									)}
									<span>
										<Typography.Text type={'secondary'}>Contractor Informed:</Typography.Text>
										{' '}
										{record.contractorInformed ? 'Yes' : 'No'}
									</span>
									{record.contractorInformed ? (
										<span>
											<Typography.Text type={'secondary'}>Attachments:</Typography.Text>
											{' '}
											{record.contractorInformedFiles?.split(',').length
												? record.contractorInformedFiles.split(',').map((fileKey: string, ixx: number) => (
													<a key={ixx} href={getS3Url(fileKey)} target='_blank' rel='noreferrer'><PaperClipOutlined /></a>
												)) : []}
										</span>
									) : (
										<span>
											<Typography.Text type={'secondary'}>Contractor Not Informed Reason:</Typography.Text>
											{' '}
											{record.contractorNotInformedReason ?? ''}
										</span>
									)}
								</>
							) : []}
					</Space>
				),
			},
			{title: 'Created by', dataIndex: 'createdByName', key: 'createdByName'},
			{title: 'Site', dataIndex: 'siteName', key: 'siteName'},
			{
				title: 'Action', key: 'action', render: (text: string, record: ExpenseReport) => {
					const canApprove = equalNum(record.nextApprovedBy, authUser?.id);
					return (
						<Space direction='vertical'>
							{record.nextApprovedUser ? `Pending Approval from ${record.nextApprovedUser.name}` : ''}
							{record.status === ExpenseStatus.APPROVED && record.paidExpense && record.paidExpense.status === PaidExpenseStatus.PAID ? (
								<small>{'Amount credited to your wallet.'}</small>
							) : []}
							{record.status === ExpenseStatus.APPROVED && !record.paidExpenseId ? (
								<small>{'Amount will be credited to your wallet by Tuesday next week.'}</small>
							) : []}
							{record.status === ExpenseStatus.APPROVED && record.paidExpense
								&& record.paidExpense.status !== PaidExpenseStatus.PAID ? (
									<small>{'Amount will be credited to your wallet this week.'}</small>
								) : []}
							{record.status === ExpenseStatus.REJECTED && record.cancelReason ? (
								<small><b>Cancel Reason:</b>{' '}{record.cancelReason}</small>
							) : []}
							{canApprove ? (
								<Space>
									<Tooltip placement='topLeft' title={'Approve'}>
										<Button type={'primary'} size={'small'} onClick={() => {
											this.handleApproveClick(record.id);
										}}>
											<CheckOutlined />
										</Button>
									</Tooltip>
									<Tooltip placement='topLeft' title={'Reject'}>
										<Button
											type={'primary'}
											danger={true}
											size={'small'}
											onClick={() => {
												this.handleRejectClick(record.id);
											}}
										>
											<CloseOutlined />
										</Button>
									</Tooltip>
								</Space>
							) : []}
						</Space>
					);
				},
			},
		];

		const dataSource: ExpenseReport[] = reports/* .filter(item => {
			if (filterValue.expenseType && fieldMap[filterValue.expenseType as string]) {
				// Directly return the Boolean value of the item's expense property
				return Boolean(item[fieldMap[filterValue.expenseType as string]]);
			}

			return true;
		}) */
			.map((report: ExpenseReport) => ({
				...report,
				date: formatDateFunction(report.createdAt),
				serialNo: report.sno ? report.sno : 'N/A',
				createdByName: report.createdUser ? report.createdUser.name : 'N/A',
				siteName: report.site ? report.site.name : 'N/A',
			}));

		const csvData: any[] = this.getCsvData(reports);

		return (
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f: FilterDataType) => {
								this.filterData(f);
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<Space>
							<CSVLink
								data={csvData}
								filename={'Expense_Report.csv'}
								className='btn btn-primary'
							>
								Download
							</CSVLink>
						</Space>
					</Col>
				</Row>
				<br />

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								total={totalCount}
								currentPos={currentPos ?? 1}
								perPageSize={perPageSize ?? 10}
								pageSizeOptions={['10', '50', '100', '500', '1000', '5000', '10000']}
								filterValue={filterValue}
								filterData={this.filterData}
								onPaginationChange={this.props.onPaginationChange}
							/>
							<Table
								size={'small'}
								pagination={false}
								dataSource={dataSource}
								columns={columns}
								bordered={true}
								scroll={{x: 800, y: 500}}
							/>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}

				<Modal
					title='Cancel Expense'
					open={Boolean(this.state.cancelExpenseId)}
					onOk={this.handleRejectExpenseOk}
					onCancel={this.handleRejectExpenseCancel}
				>
					<Space direction={'vertical'}>
						<label>Please provide a reason to cancel this bill</label>
						<Input.TextArea
							placeholder={'Enter Cancel Reason'}
							defaultValue={this.state.cancelReason ?? ''}
							value={this.state.cancelReason ?? ''}
							onChange={(e: any) => {
								this.setState({cancelReason: String(e.target.value)});
							}}
						/>
					</Space>
				</Modal>
			</Spin>
		);
	}
}

export default connector(ViewAllExpenseReport);

