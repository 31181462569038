import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {getCurrencyString, parseNum} from '../../helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Button, Col, Form, Input, message, Row, Spin} from 'antd';
import {addMoney} from './actions';
import {type match as Match} from 'react-router';
import {type History} from 'history';

type MyParams = {
	walletId?: string;
};

type OwnProps = {
	match: Match<MyParams>;
	history: History;
};

const mapStateToProps = ({
	prithuWallet: {wallets, dataUpdated, errorMessage, loading},
}: Types.RootState, ownProps: OwnProps) => ({
	wallets,
	dataUpdated,
	errorMessage,
	loading,
	...ownProps,
});

const mapDispatchToProps = {
	addMoney,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

type State = {
	walletAmount: number;
};

class AddWalletMoney extends React.Component<Props, State> {
	public walletId?: number;

	constructor(props: Props) {
		super(props);
		this.walletId = parseInt(props.match?.params?.walletId ?? '0', 10);

		this.state = {
			walletAmount: 0,
		};
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			void message.success('Money Added Successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}

		if (nextProps.errorMessage) {
			void message.error(nextProps.errorMessage);
		}
	}

	handleSubmit = () => {
		const {wallets} = this.props;
		const wallet = this.walletId && wallets?.length ? wallets.find(o => o.id === this.walletId) : undefined;

		if (!this.state.walletAmount && this.state.walletAmount > 0) {
			void message.error('Please add any amount received');
			return;
		}

		if (!this.walletId) {
			void message.error('Something went wrong. Please refresh page and try again.');
			return;
		}

		if (wallet && wallet.limit < this.state.walletAmount) {
			void message.error('Amount exceeds the wallet limit');
			return;
		}

		const data = {
			id: this.walletId,
			balance: this.state.walletAmount,
		};

		this.props.addMoney(data);
	};

	render() {
		const {loading, wallets} = this.props;
		const wallet = this.walletId && wallets?.length ? wallets.find(o => o.id === this.walletId) : undefined;

		const layout = {
			labelCol: {span: 8},
			wrapperCol: {span: 16},
		};
		const tailLayout = {
			wrapperCol: {offset: 8, span: 16},
		};

		return (
			<DefaultLayout>
				<Spin
					size='large'
					spinning={loading}
					tip={'Loading...'}
				>
					<Row>
						<Col span={24} className='text-center'>
							<h4>Add Money to {wallet?.user?.name ?? ''} Wallet</h4>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Button onClick={e => {
								this.props.history.goBack();
							}}>Back</Button>
							<br /><br />
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<Form {...layout} name='basic'>
								<Form.Item label='Limit'>
									{wallet?.limit ? getCurrencyString(wallet.limit, false) : 'N/A'}
								</Form.Item>
								<Form.Item label='Amount'>
									<Input
										addonBefore={'₹'}
										type={'number'}
										defaultValue={this.state.walletAmount ?? ''}
										value={this.state.walletAmount ?? ''}
										onChange={(e: any) => {
											this.setState({walletAmount: parseNum(e.target.value as string)});
										}}
									/>
								</Form.Item>
								<Form.Item {...tailLayout}>
									<Button type='primary' onClick={this.handleSubmit}>
										Add Money
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
					<br />
					<br />

				</Spin>
			</DefaultLayout>
		);
	}
}

export default connector(AddWalletMoney);
