import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {getById, update, create} from './actions';
import {parseNum} from 'app/helpers';
import {
	Button,
	Col,
	Form,
	message,
	Row,
	Space,
	Spin,
	Typography,
	Select,
	Input,
	Card,
} from 'antd';
import {
	triggerPointMap,
	triggerToWhomMap,
	topTemplate,
	bottomTemplate,
	type TriggerPoint,
} from 'app/models/email-template.model';
import {moduleLabelName, ModuleName} from 'app/models';
import type Types from 'MyTypes';

type MyParams = {
	id?: string;
};

export const ViewEmailTemplateForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();
	const id = parseNum(params?.id ?? '0');
	const {loading, byIds, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.emailTemplate,
	);
	const emailTemplate = byIds[id] ? (byIds[id]) : undefined;

	const [moduleName, setModuleName] = React.useState(
		emailTemplate?.moduleName ?? '',
	);
	const [header, setHeader] = React.useState(emailTemplate?.header ?? '');

	const [content, setContent] = React.useState(emailTemplate?.content ?? '');

	const [triggerPoint, setTriggerPoint] = React.useState(
		emailTemplate?.triggerPoint ?? undefined,
	);

	const [triggerToWhom, setTriggerToWhom] = React.useState(
		emailTemplate?.triggerToWhom ?? undefined,
	);

	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	React.useEffect(() => {
		console.log('ViewEmailTemplateForm.componentDidMount');
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully.');
			if (id) {
				dispatch(getById(id));
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};

	const onSubmitClicked = () => {
		const data: any = {
			moduleName,
			header,
			content,
			triggerPoint,
			triggerToWhom,
		};
		if (!data.moduleName) {
			void message.error('Please select a module!');
			return;
		}

		if (!data.header) {
			void message.error('Please enter a header!');
			return;
		}

		if (!data.content) {
			void message.error('Please enter content!');
			return;
		}

		if (!data.triggerPoint) {
			void message.error('Please select a trigger point!');
			return;
		}

		if (!data.triggerToWhom) {
			void message.error('Please select a trigger to whom!');
			return;
		}

		if (emailTemplate?.id) {
			dispatch(update(emailTemplate.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const previewHtml = `${topTemplate}${content}${bottomTemplate}`;
	return (
		<DefaultLayout currentPath={uiPaths.createEmailTemplate}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Email Template Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
              Back
						</Button>
						<Form {...layout} name='basic'>
							<Form.Item label='Module Name*' name='module'>
								<Select
									showSearch={true}
									placeholder='Select a Module'
									defaultValue={moduleLabelName[moduleName] }
									onChange={value => {
										setModuleName(value);
									}}
								>
									{Object.values(ModuleName).map((module: string, ix: number) => (
										<Select.Option key={ix} value={module}>
											{moduleLabelName[module]}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label='Header*'
								rules={[{required: true, message: 'Please Enter Header'}]}
							>
								<Input
									defaultValue={header}
									value={header}
									onChange={(e: any) => {
										setHeader(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label={<b>Preview:</b>}>
								<Col span={24}>
									<Card bordered={true} {...layout}>
										<div
											id='preview'
											dangerouslySetInnerHTML={{
												__html: previewHtml,
											}}
										></div>
									</Card>
								</Col>
							</Form.Item>
							<br />
							<Form.Item name='content' label='Content*'>
								<Input.TextArea
									defaultValue={content}
									value={content}
									onChange={e => {
										setContent(String(e.target.value));
									}}
								/>
							</Form.Item>
							{moduleName && triggerPointMap[moduleName] ? (
								<Form.Item label='Trigger Point*' name='triggerPoint'>
									<Select
										showSearch={true}
										placeholder='Select a TriggerPoint'
										defaultValue={
											triggerPointMap[moduleName] && triggerPoint
												? triggerPointMap[moduleName][triggerPoint]
												: ''
										}
										onChange={value => {
											setTriggerPoint(value as TriggerPoint);
										}}
									>
										{Object.keys(triggerPointMap[moduleName]).map(
											(key, ix: number) => (
												<Select.Option key={ix} value={key}>
													{triggerPointMap[moduleName]
														? triggerPointMap[moduleName][key]
														: ''}
												</Select.Option>
											),
										)}
									</Select>
								</Form.Item>
							) : (
								[]
							)}
							{moduleName && triggerToWhomMap[moduleName] ? (
								<Form.Item label='Trigger To Whom*' name='triggerToWhom'>
									<Select
										mode='multiple'
										allowClear
										showSearch={true}
										placeholder='Select recipients'
										defaultValue={triggerToWhom ? triggerToWhom.split(',') : []}
										onChange={value => {
											setTriggerToWhom(value.toString());
										}}
									>
										{Object.keys(triggerToWhomMap[moduleName]).map(
											(key: string, ix: number) => (
												<Select.Option key={ix} value={key}>
													{triggerToWhomMap[moduleName]
														? triggerToWhomMap[moduleName][key]
														: ''}
												</Select.Option>
											),
										)}
									</Select>
								</Form.Item>
							) : (
								[]
							)}
							<Form.Item {...tailLayout}>
								<Space>
									<Button type='primary' onClick={onSubmitClicked}>
										Save
									</Button>
								</Space>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
