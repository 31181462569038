import {DefaultLayout} from '../Layout/DefaultLayout';
import React from 'react';
import {formatDateFunction, reduceUniqueIds} from 'app/helpers';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {
	Col,
	Row,
	Spin,
	Typography,
	Table,
	Space,
	Button,
	Input,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	getKey,
} from 'app/services/report-service';
import {SiteStatus, DrawingType} from 'app/models';
import {get as getSites} from '../Site/actions';
import {get as getMasters} from '../ProjectProcessMaster/actions';
import {get as getDrawingSchedules} from '../DrawingSchedule/actions';
import {type ProjectProcessMaster, ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
	populateDataSourceTree,
} from './ViewHierarchyReport';

export const ViewDesignProgressTrackerReport: React.FC = () => {
	const dispatch = useDispatch();
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {byIds: masterByIds, allIds: masterAllIds} = useSelector((state: Types.RootState) => state.projectProcessMaster);
	const masters: ProjectProcessMaster[] = masterAllIds.map(id => masterByIds[id]);
	const {ids, records, loading, filterValue} = useSelector(
		(state: Types.RootState) => state.drawingSchedule,
	);
	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewDesignProgressTracker.componentDidMount');
		// Get Under Construction and Under Design Sites
		dispatch(
			getSites({
				where: {
					status: {inq: [SiteStatus.UNDER_CONSTRUCTION, SiteStatus.UNDER_DESIGN]},
					isLive: true,
				},
			}),
		);
		// Get Project Process Masters of type design and parent as design Progress tracker
		dispatch(getMasters({where: {projectProcessType: ProjectProcessType.DESIGN, parentId: 933}}));
	}, []);

	React.useEffect(() => {
		if (allSites.length > 0 && masterAllIds.length > 0) {
			const siteIds = allSites.reduce<number[]>(
				(ids, {id}) => reduceUniqueIds<number>(ids, id),
				[],
			);
			// Fetch drawing schedules
			dispatch(getDrawingSchedules({
				where: {
					siteId: {inq: siteIds},
					drawingType: DrawingType.DRAWING_SCHEDULE,
					projectProcessMasterId: {inq: masterAllIds},
				},
			}));
		}
	}, [allSites, masterAllIds]);

	const items = ids.map(id => records[id]);

	const reportColumns: ReportColumnType[] = masters
		.map(master => ({
			title: master.processName,
			key: getKey(master.processName),
			children: [
				{title: 'Planned Date', key: getKey(master.processName, '-plannedDate'), width: 130},
				{title: 'Actual Date', key: getKey(master.processName, '-actualDate'), width: 130},
			],
		}));

	const dataSource = allSites.map(site => {
		const dataRecords: Record<string, string> = {};
		const siteEntries = items.filter(item => item.siteId === site.id);
		siteEntries.forEach(entry => {
			dataRecords[getKey(entry.projectProcessMaster?.processName ?? '', '-plannedDate')]
          = formatDateFunction(entry.scheduleDate, false);
			dataRecords[getKey(entry.projectProcessMaster?.processName ?? '', '-actualDate')]
          = formatDateFunction(entry.completedAt, false);
		});
		return {
			key: String(site.id),
			records: dataRecords,
			name: allSites.find(s => s.id === site.id)?.name ?? '',
		};
	});

	return (
		<DefaultLayout
			currentPath={uiPaths.designProgressTrackerReport}
		>
			<ViewHierarchyReport
				title='Design Progress Tracker Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
			/>
		</DefaultLayout>
	);
};
