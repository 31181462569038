import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import type Types from 'MyTypes';
import {
	Button,
	Col,
	message,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import {idNotExist, uiPaths} from 'app/constants';
import {
	forceComplete,
	get,
	setFilterValue,
	updateDatesBySite,
} from './actions';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {Link} from 'react-router-dom';
import {
	formatDateFunction,
	addDays,
	equalNum,
	toTitleCase,
	getStartDate,
	parseNum,
} from 'app/helpers';
import {
	type ProjectProcessMaster,
	type ProjectProcessType,
} from 'app/components/ProjectProcessMaster/project-process-master';
import {
	type ProjectProcessSchedule,
	ProjectScheduleStatus,
	ProjectScheduleStatusOptions,
} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {type Site} from 'app/models';
import {updateScheduleDates} from '../GoogleScheduleSheet/actions';

type Props = {
	projectProcessType: ProjectProcessType;
	canForceComplete?: boolean;
	title: string;
	sites: Site[];
	approveSites: Site[];
};

export const ViewProjectProcessSchedule: React.FC<Props> = ({
	projectProcessType,
	canForceComplete,
	title,
	sites,
	approveSites,
}) => {
	const dispatch = useDispatch();
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {
		allIds,
		byIds,
		loading,
		floorOptions,
		filterValue,
		dataUpdated,
		errorMessage,
	} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	const {
		loading: sheetLoading,
		errorMessage: sheetErrorMessage,
		dataUpdated: sheetDataUpdated,
	} = useSelector((state: Types.RootState) => state.googleScheduleSheet);

	React.useEffect(() => {
		console.log('ViewProjectProcessSchedule component');
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully.');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (sheetDataUpdated) {
			void message.success('Sheet Updated Successfully.');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [sheetDataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	React.useEffect(() => {
		if (sheetErrorMessage) {
			void message.error(sheetErrorMessage);
		}
	}, [sheetErrorMessage]);

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {where: {}, limit: 1000};
		filter.where.projectProcessType = projectProcessType;
		if (f.siteId) {
			filter.where.siteId = f.siteId;
		} else {
			filter.where.siteId = idNotExist;
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	const handleForceCompleteClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to complete this activity.')) {
			dispatch(forceComplete(id, {}));
		}
	};

	const handleUpdateFromSheetClick = (siteId: number) => {
		if (
			// eslint-disable-next-line no-alert
			window.confirm(
				'Are you sure you want to update the dates from google sheet.',
			)
		) {
			dispatch(updateScheduleDates(siteId, {}));
		}
	};

	const handleUpdateDatesClick = (siteId: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to update the dates.')) {
			dispatch(updateDatesBySite(siteId));
		}
	};

	// Created to calculate EndDiff Days for a particular schedule based on status of that particular schedule
	const getEndDateDiff = (item: ProjectProcessSchedule) => {
		let endDiffTime;
		let endDiffDays = 0;
		const today = new Date();
		if (item.status === ProjectScheduleStatus.STARTED) {
			if (item.endDate && new Date(item.endDate) < today) {
				endDiffTime = Math.abs(
					getStartDate(today.toDateString()).valueOf()
					- getStartDate(item.endDate).valueOf(),
				);
				endDiffDays = Math.ceil(endDiffTime / (1000 * 60 * 60 * 24));
			}
		}

		if (
			item.status === ProjectScheduleStatus.COMPLETED
			&& item.completedAt
			&& new Date(item.completedAt) > new Date(item.endDate ?? '')
		) {
			if (item.endDate && item.completedAt) {
				endDiffTime = Math.abs(
					getStartDate(item.completedAt).valueOf()
					- getStartDate(item.endDate).valueOf(),
				);
				endDiffDays = Math.ceil(endDiffTime / (1000 * 60 * 60 * 24));
			}
		}

		return endDiffDays;
	};

	// Created to calculate StartDiff Days for a particular schedule based on status of that particular schedule
	const getStartDateDiff = (item: ProjectProcessSchedule) => {
		let startDiffTime = 0;
		let startDiffDays = 0;
		const today = new Date();
		// Logic to calculate delay for To be started activities
		if (item.status === null) {
			//	Console.log('To be started item id', item.id);
			if (item.startDate && today > new Date(item.startDate)) {
				startDiffTime = Math.abs(
					getStartDate(today.toDateString()).valueOf()
					- getStartDate(item.startDate).valueOf(),
				);
				startDiffDays = Math.ceil(startDiffTime / (1000 * 60 * 60 * 24));
			}
			//	Console.log("Get Start Date Diff", startDiffDays)
		}

		// Logic to calculate delay for In progress activities
		if (item.status === ProjectScheduleStatus.STARTED) {
			if (
				item.startDate
				&& item.startedAt
				&& new Date(item.startedAt) > new Date(item.startDate)
			) {
				startDiffTime = Math.abs(
					getStartDate(item.startedAt).valueOf()
					- getStartDate(item.startDate).valueOf(),
				);
				startDiffDays = Math.ceil(startDiffTime / (1000 * 60 * 60 * 24));
			}
		}

		// Logic to calculate delay for Completed activities
		if (item.status === ProjectScheduleStatus.COMPLETED) {
			if (
				item.startDate
				&& item.startedAt
				&& new Date(item.startedAt) > new Date(item.startDate)
			) {
				startDiffTime = Math.abs(
					getStartDate(item.startedAt).valueOf()
					- getStartDate(item.startDate).valueOf(),
				);
				startDiffDays = Math.ceil(startDiffTime / (1000 * 60 * 60 * 24));
			}
		}

		return startDiffDays;
	};

	// Created to calculate maXDelay and maxCompletionDate for a set of projectProcessSchedule activities for a particular Site
	const getMaxDelayAndMaxCompletionDate = (dataSource: ProjectProcessSchedule[]) => {
		const today = new Date();
		let maxPlannedCompletionDate = '';
		let maxDelay = 0;
		//	Console.log(dataSource.length);
		dataSource.forEach(item => {
			// Logic to calculate delay for To be started activities
			if (item.status === null) {
				//	Console.log('To be started item id', item.id);
				let startDiffTime;
				let startDiffDays = 0;
				if (item.startDate && today > new Date(item.startDate)) {
					startDiffTime = Math.abs(
						getStartDate(today.toDateString()).valueOf()
						- getStartDate(item.startDate).valueOf(),
					);
					startDiffDays = Math.ceil(startDiffTime / (1000 * 60 * 60 * 24));
				}

				maxDelay = Math.max(maxDelay, startDiffDays);
				//	Console.log('TO be started activities startDiffDays and maxDelay', startDiffDays, maxDelay)
			}

			// Logic to calculate delay for In progress activities
			if (item.status === ProjectScheduleStatus.STARTED) {
				//	Console.log('In progress item id', item.id);
				let startDiffTime;
				let startDiffDays = 0;
				let endDiffTime;
				let endDiffDays = 0;
				if (
					item.startDate
					&& item.startedAt
					&& new Date(item.startedAt) > new Date(item.startDate)
				) {
					startDiffTime = Math.abs(
						getStartDate(item.startedAt).valueOf()
						- getStartDate(item.startDate).valueOf(),
					);
					startDiffDays = Math.ceil(startDiffTime / (1000 * 60 * 60 * 24));
				}

				if (item.endDate && new Date(item.endDate) < today) {
					endDiffTime = Math.abs(
						getStartDate(today.toDateString()).valueOf()
						- getStartDate(item.endDate).valueOf(),
					);
					endDiffDays = Math.ceil(endDiffTime / (1000 * 60 * 60 * 24));
				}

				maxDelay = Math.max(maxDelay, Math.max(startDiffDays, endDiffDays));
				//		Console.log('In progress activities startdiffdays, endDiffDays and maxDelay', startDiffDays, endDiffDays, maxDelay)
			}

			// Logic to calculate delay for Completed activities
			if (item.status === ProjectScheduleStatus.COMPLETED) {
				let startDiffTime;
				let startDiffDays = 0;
				let endDiffTime;
				let endDiffDays = 0;
				//	Console.log('Completed item id', item.id);
				if (
					item.startDate
					&& item.startedAt
					&& new Date(item.startedAt) > new Date(item.startDate)
				) {
					startDiffTime = Math.abs(
						getStartDate(item.startedAt).valueOf()
						- getStartDate(item.startDate).valueOf(),
					);
					startDiffDays = Math.ceil(startDiffTime / (1000 * 60 * 60 * 24));
				}

				if (
					item.endDate
					&& item.completedAt
					&& new Date(item.completedAt) > new Date(item.endDate)
				) {
					endDiffTime = Math.abs(
						getStartDate(item.completedAt).valueOf()
						- getStartDate(item.endDate).valueOf(),
					);
					endDiffDays = Math.ceil(endDiffTime / (1000 * 60 * 60 * 24));
				}

				maxDelay = Math.max(maxDelay, Math.max(startDiffDays, endDiffDays));
			}

			if (item.endDate) {
				maxPlannedCompletionDate
					= new Date(maxPlannedCompletionDate) > new Date(item.endDate)
						? maxPlannedCompletionDate
						: item.endDate;
			}
		});
		return {maxDelay, maxPlannedCompletionDate};
	};

	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));
	const statusOptions: FilterOptionItem[] = Object.values(
		ProjectScheduleStatusOptions,
	).map((status: string) => ({
		value: status,
		label: toTitleCase(status, '_') ?? '',
	}));
	const rawItems = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const today = new Date();
	const items = rawItems
		.filter(item => item.startDate && item.projectProcessMaster)
		.sort((a, b) => {
			if (a.startDate && b.startDate) {
				return new Date(a.startDate) > new Date(b.startDate) ? 1 : -1;
			}

			return -1;
		});
	const {maxDelay, maxPlannedCompletionDate}
		= getMaxDelayAndMaxCompletionDate(items);
	const dataSource = items
		.filter(
			item =>
				item.status?.toUpperCase() !== (ProjectScheduleStatus.STOPPED).toUpperCase(),
		)
		.filter(item => {
			let returnVal = true;
			if (filterValue.floorName) {
				returnVal
					= returnVal
					&& item.projectProcessMaster?.workActivityMaster?.floorName
					=== filterValue.floorName;
			}

			if (filterValue.activityName) {
				const activityName = item.projectProcessMaster?.processName;
				returnVal
					= returnVal
					&& (activityName
						? activityName
							.toLowerCase()
							.includes((filterValue.activityName as string).toLowerCase())
						: false);
			}

			if (filterValue.status) {
				if (
					filterValue.status === ProjectScheduleStatusOptions.NOT_YET_STARTED
				) {
					returnVal = returnVal && item.status === null;
				} else {
					returnVal = returnVal && item.status === filterValue.status;
				}
			}

			return returnVal;
		});
	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'activityName',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Activity Name',
			label: 'Activity Name',
			defaultValue: filterValue.activityName,
		},
		{
			filterKey: 'floorName',
			items: floorOptions
				? [...floorOptions, {value: '', label: 'None'}]
				: [],
			formType: FilterFormType.SELECT,
			placeholder: 'Select a Floor',
			label: 'Floor Name',
			defaultValue: filterValue?.floorName,
		},
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			label: 'Status',
			placeholder: 'Select a Status',
			defaultValue: filterValue.status,
		},
	];
	const canApprove
		= filterValue.siteId
		&& approveSites.find(({id}) => equalNum(String(filterValue.siteId), id));
	const monthAhead = new Date(
		new Date(new Date().setDate(new Date().getDate() + 30)),
	);
	return (
		<Spin size='large' spinning={loading || sheetLoading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>
						{title}
					</Typography.Title>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
						filterData(f);
					}} />
					<br />
				</Col>
			</Row>
			{authUser?.isAdmin && filterValue.siteId ? (
				<Row>
					<Col span={24} style={{textAlign: 'right', marginBottom: 10}}>
						<Space>
							<Button
								type={'primary'}
								onClick={() => {
									handleUpdateDatesClick(parseNum(filterValue.siteId as string));
								}}
							>
								Update Dates
							</Button>
							<Button
								type={'primary'}
								onClick={() => {
									handleUpdateFromSheetClick(parseNum(filterValue.siteId as string));
								}}
							>
								Update Dates from Google Sheet
							</Button>
						</Space>
					</Col>
				</Row>
			) : (
				[]
			)}
			<Row>
				<Col span={24}>
					{maxPlannedCompletionDate && dataSource.length > 0 ? (
						<Tag
							color={'#BC982F'}
						>{`Expected Date of Completion: ${formatDateFunction(
								addDays(maxPlannedCompletionDate, maxDelay).toDateString(),
								false,
							)}`}</Tag>
					) : []}
					{dataSource.length > 0 ? (
						<Tag color={'#BC982F'}>{`Delayed By: ${maxDelay} days`}</Tag>
					) : []}
					<br />
					<br />
					<Table
						size={'small'}
						bordered={true}
						dataSource={dataSource}
						pagination={false}
					>
						<Table.Column
							title='Work Activity'
							dataIndex='projectProcessMaster'
							key='projectProcessMaster'
							render={(
								projectProcessMaster: ProjectProcessMaster,
								schedule: ProjectProcessSchedule,
							) => (
								<Link
									to={{
										pathname: `/${uiPaths.editProjectProcessSchedule.replace(
											':id',
											String(schedule.id),
										)}`,
									}}
								>
									{projectProcessMaster?.processName ?? 'N/A'}
								</Link>
							)}
						/>
						<Table.Column
							title='Floor Name'
							dataIndex='projectProcessMaster'
							key='projectProcessMaster'
							render={(projectProcessMaster: ProjectProcessMaster) =>
								projectProcessMaster?.workActivityMaster?.floorName ?? ''
							}
						/>
						<Table.Column
							title='Planned Start Date'
							dataIndex='startDate'
							key='startDate'
							render={(startDate: string, schedule: ProjectProcessSchedule) => {
								if (!startDate) {
									return '';
								}

								let color = '';
								if (!schedule.status) {
									if (
										new Date(startDate) < monthAhead
										&& new Date(startDate) > today
									) {
										color = 'orange';
									}

									if (new Date(startDate) < today) {
										color = 'red';
									}
								}

								return (
									<Tag color={color}>
										<Space>
											{formatDateFunction(startDate, false)}
											{color === 'red' ? (
												<Tooltip title="Activity hasn't been started before planned start date.">
													<QuestionCircleOutlined />
												</Tooltip>
											) : (
												[]
											)}
										</Space>
									</Tag>
								);
							}}
							sorter={{
								compare(
									a: ProjectProcessSchedule,
									b: ProjectProcessSchedule,
								) {
									if (a.startDate && b.startDate) {
										if (new Date(a.startDate) > new Date(b.startDate)) {
											return 1;
										}

										if (new Date(a.startDate) < new Date(b.startDate)) {
											return -1;
										}

										if (
											a?.projectProcessMaster?.sequence
											&& b.projectProcessMaster?.sequence
										) {
											return a?.projectProcessMaster?.sequence
												> b?.projectProcessMaster.sequence
												? 1
												: -1;
										}
									}

									return 1;
								},
								multiple: 1,
							}}
						/>
						<Table.Column
							title='Actual Start Date'
							dataIndex='startedAt'
							key='startedAt'
							render={(startedAt: string) =>
								formatDateFunction(startedAt, false)
							}
						/>
						<Table.Column
							title='Delayed Start'
							key='delayedStart'
							render={(_: string, schedule: ProjectProcessSchedule) =>
								getStartDateDiff(schedule)
							}
						/>
						<Table.Column
							title='Planned Completion Date'
							dataIndex='endDate'
							key='endDate'
							render={(endDate: string, schedule: ProjectProcessSchedule) => {
								if (!endDate) {
									return '';
								}

								let color = '';
								if (schedule.status !== ProjectScheduleStatus.COMPLETED) {
									if (
										new Date(endDate) < monthAhead
										&& new Date(endDate) > today
									) {
										color = 'orange';
									}

									if (new Date(endDate) < today) {
										color = 'red';
									}
								}

								return (
									<Tag color={color}>
										<Space>
											{formatDateFunction(endDate, false)}
											{color === 'red' ? (
												<Tooltip title="Activity hasn't been completed before planned completion date.">
													<QuestionCircleOutlined />
												</Tooltip>
											) : (
												[]
											)}
										</Space>
									</Tag>
								);
							}}
						/>
						<Table.Column
							title='Actual Completion Date'
							dataIndex='completedAt'
							key='completedAt'
							render={(completedAt: string) =>
								formatDateFunction(completedAt, false)
							}
						/>
						<Table.Column
							title='Delayed Complete'
							key='delayedComplete'
							render={(_: string, schedule: ProjectProcessSchedule) =>
								getEndDateDiff(schedule)
							}
						/>
						<Table.Column title='Status' dataIndex='status' key='status' />
						{canForceComplete ? (
							<Table.Column
								title='Action'
								dataIndex='action'
								key='action'
								render={(action: string, schedule: ProjectProcessSchedule) => (
									<Space wrap={true}>
										<Button
											type={'primary'}
											danger={true}
											size={'small'}
											onClick={() => {
												handleForceCompleteClick(schedule.id);
											}}
										>
											Force Complete
										</Button>
									</Space>
								)}
							/>
						) : (
							[]
						)}
					</Table>
				</Col>
			</Row>
		</Spin>
	);
};
