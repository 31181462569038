
import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';

export const actionTypes = {
	getApi: '@comment/GET_API',
	createApi: '@comment/CREATE_API',
	updateApi: '@comment/UPDATE_API',
};

const url = `${lbBaseUrl}/comments`;

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const save = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (data: any, where: any): any => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}?where=${JSON.stringify(where)}`, data, getHeaders(true)),
	meta: {process: true},
});
