import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row, Spin, Table, Typography, Space, Button, Input, Empty} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {get as getSites} from '../Site/actions';
import {get as getUserPermission} from '../UserPermission/actions';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {CSVLink} from 'react-csv';
import {
	calculateDrawingScheduleActivityReportData,
	mutateTree,
	getKey,
} from 'app/services/report-service';
import {
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {type DrawingScheduleActivityReport, SiteStatus, ModuleName, ModulePermission, type User} from 'app/models';
import type Types from 'MyTypes';
import {formatDateFunction} from 'app/helpers';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
} from './ViewHierarchyReport';
import {getDrawingScheduleActivityReport, setFilterValue} from './drawing-schedule-activity-report-reducer';
import {getQueryDateRangeForDays, getQueryDateRange} from 'app/query_helpers';

export const ViewDailyDrawingScheduleActivityReport: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds, filterValue} = useSelector(
		(state: Types.RootState) => state.drawingScheduleActivityReport,
	);
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const today = new Date();
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);

	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
		= useSelector((state: Types.RootState) => state.userPermission);

	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];

	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		records: {},
		children: [],
	};

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {
			where: {},
			limit: 50000,
			order: ['reportDate DESC'],
		};

		filter.where.isWeekly = false;

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'reportDate');
		}

		if (!f.date1 || !f.date2) {
			filter.where.and = getQueryDateRangeForDays(
				today.toString(),
				0,
				6,
				'reportDate',
			);
			f.date1 = new Date(new Date().setDate(new Date().getDate() - 6))
				.toISOString()
				.split('T')[0];
			f.date2 = new Date().toISOString().split('T')[0];
		}

		dispatch(setFilterValue(f));
		dispatch(getDrawingScheduleActivityReport(filter));
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewDailyDrawingScheduleActivityReport.componentDidMount');
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}

		dispatch(
			getSites({
				where: {
					status: {
						inq: [SiteStatus.UNDER_CONSTRUCTION, SiteStatus.UNDER_DESIGN],
					},
				},
			}),
		);
		dispatch(
			getUserPermission({
				where: {
					moduleName: ModuleName.DRAWING_SCHEDULE,
					permission: ModulePermission.READ,
				},
			}),
		);
	}, []);

	const reportDates: string[] = [];

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, DrawingScheduleActivityReport[]>>(
		(record, item) => {
			const date = formatDateFunction(item.reportDate, false);
			if (record[date] === undefined) {
				record[date] = [];
				reportDates.push(date);
			}

			record[date].push(item);
			return record;
		},
		{},
	);

	// Creating a permissions record to uniquely identify users and the sites associated with them
	const permissionMapOfRecord = userPermissions.reduce<
	Record<number, number[]>
	>((record, item) => {
		if (!record[item.userId]) {
			record[item.userId] = [];
		}

		if (allSites.find(({id}) => id === item.siteId)) {
			record[item.userId].push(item.siteId);
		}

		return record;
	}, {});

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.slice(0, 5)
		.map(d => ({
			title: d,
			key: getKey(d),
			width: 50,
			children: [
				{title: 'Completed Tabs', key: getKey(d, '-completedActivities'), width: 120},
				{
					title: 'Revised Tabs',
					key: getKey(d, '-revisedActivities'),
					width: 120,
				},
				{
					title: 'Empty Tabs',
					key: getKey(d, '-notCompletedActivities'),
					width: 120,
				},
			],
		}));

	// Traversing the tree to assign delays to all nodes and to create sites as child nodes of site in charges (leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.key = String(user.id);
		node.records = calculateDrawingScheduleActivityReportData(
			user,
			mapOfRecords,
			permissionMapOfRecord,
		);
		node.name = user.name;
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else if (permissionMapOfRecord[user.id]?.length) {
			node.children = permissionMapOfRecord[user.id].map(siteId => {
				const data: Record<string, number> = {};
				Object.entries(mapOfRecords).forEach(([date, reports]) => {
					const item = reports.find(report => report.siteId === siteId);
					data[getKey(date, '-completedActivities')] = item?.completedActivities ?? 0;
					data[getKey(date, '-revisedActivities')] = item?.revisedActivities ?? 0;
					data[getKey(date, '-notCompletedActivities')] = item?.notCompletedActivities ?? 0;
				});
				const x: DataSourceType = {
					key: String(siteId),
					records: data,
					name: allSites.find(site => site.id === siteId)?.name ?? '',
				};
				return x;
			});
		} else {
			node.children = undefined;
		}

		return node;
	};

	const csvDataSource = allSites.map(site => {
		const data: Record<string, number> = {};
		Object.entries(mapOfRecords).forEach(([date, reports]) => {
			const item = reports.find(report => report.siteId === site.id);
			data[getKey(date, '-completedActivities')] = item?.completedActivities ?? 0;
			data[getKey(date, '-revisedActivities')] = item?.revisedActivities ?? 0;
			data[getKey(date, '-notCompletedActivities')] = item?.notCompletedActivities ?? 0;
		});
		return {
			key: String(site.id),
			name: site.name,
			records: data,
		};
	});

	const csvColumns: any[] = ['Site Name'];
	for (const date of reportDates) {
		csvColumns.push(date);
		csvColumns.push('');
		csvColumns.push('');
	}

	const csvSubColumns: any[] = [''];
	for (const date of reportDates) {
		csvSubColumns.push('Completed Activities');
		csvSubColumns.push('Revised Activities');
		csvSubColumns.push('Not Completed Activities');
	}

	const csvDataItems: any[][] = [];

	for (const data of csvDataSource) {
		const csvDataItem: any[] = [data.name];
		for (const date of reportDates) {
			csvDataItem.push(data.records[getKey(date, '-completedActivities')]);
			csvDataItem.push(data.records[getKey(date, '-revisedActivities')]);
			csvDataItem.push(data.records[getKey(date, '-notCompletedActivities')]);
		}

		csvDataItems.push(csvDataItem);
	}

	const csvData: any[][] = [csvColumns, csvSubColumns, ...csvDataItems];

	const roots: User[] = mutateTree(
		users,
		allSites
			.filter(({projectArchitectId}) => Boolean(projectArchitectId))
			.map(({projectArchitectId}) => projectArchitectId ?? 0),
	);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
			disabledDateDays: 7,
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.dailyDrawingScheduleActivityReport}>
			<Row>
				<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
					<CSVLink
						data={csvData}
						filename={'Daily_Drawing_Schedule_Activity_Report.csv'}
						className='btn btn-primary'
					>
						<Button type='primary'>Download</Button>
					</CSVLink>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						onSubmit={f => {
							filterData(f);
						}}
					/>
					<br />
				</Col>
			</Row>
			<ViewHierarchyReport
				title='Daily Drawing Schedule Uploads Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={`
				This report presents how many tabs in the drawing schedule have been marked as completed i.e had fresh uploads in a respective day, marked as revised i.e the had revised drawings or uploads in a respective day. Additionally the report also keeps track of not completed tabs or empty tabs(excluding Not in scope marked tabs) in the drawing schedule for a site in a respective day.Data is captured every day at 06:00 PM in the evening
      `}
				reportLogic={'For every site in under construction and under design stage we capture all the drawing schedules which are not marked as "NOT IN SCOPE" and we check for the completion dates and revised dates and determine whether they lie within the current day by comparing the week numbers and year between them and current date and keep track of a running count for each site. If you have any query related to this report or a suggestion to improvise the report. Please Contact ruchika.singh@prithu.in'}
			/>
		</DefaultLayout>
	);
};
