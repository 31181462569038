import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Col, Menu, Row, Typography} from 'antd';
import {hasPermission} from 'app/helpers';
import {changePage} from './actions';
import {workOrderBillPathType} from './constants';
import {uiPaths} from 'app/constants';
import {ViewWorkOrderBillForm} from './ViewWorkOrderBillForm';
import {ViewAllWorkOrderBill} from './ViewAllWorkOrderBill';
import {ViewWorkOrderBillReport} from '../WorkOrderBillReport/ViewWorkOrderBillReport';
import {ViewBillWithoutWorkOrder} from '../WorkOrderBillReport/ViewBillWithoutWorkOrder';
import * as qs from 'qs';
import {ModuleName, ModulePermission} from 'app/models';
import {ViewReApprovedWorkOrderBill} from 'app/components/WorkOrderBill/ViewReApprovedWorkOrderBill';
import {useLocation} from 'react-router-dom';

// eslint-disable-next-line complexity
export const ViewWorkOrderBill: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const {pageType} = useSelector((state: Types.RootState) => state.workOrderBill);
	const {byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);

	const q: any = qs.parse(location.search, {ignoreQueryPrefix: true});
	const canWrite: boolean = permissions ? hasPermission(permissions[ModuleName.WORK_ORDER_BILL], ModulePermission.WRITE) : false;
	const canApprove: boolean = permissions ? hasPermission(permissions[ModuleName.WORK_ORDER_BILL], ModulePermission.APPROVE) : false;
	const canViewReport: boolean = permissions ? hasPermission(permissions[ModuleName.WORK_ORDER_BILL_REPORT], ModulePermission.READ) : false;

	const page = q.page as string;

	React.useEffect(() => {
		if (page) {
			dispatch(changePage(page));
		}
	}, [page]);

	const currentPageType = pageType ?? (canWrite ? workOrderBillPathType.new : workOrderBillPathType.all);
	return (
		<DefaultLayout currentPath={uiPaths.workOrderBill}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>{'Contractor\'s Bill'}</Typography.Title>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(changePage(e.key))}
						selectedKeys={[currentPageType]}
						mode='horizontal'
					>
						{canWrite ? (
							<Menu.Item key={workOrderBillPathType.new}>
								Upload New Bill
							</Menu.Item>
						) : []}
						<Menu.Item key={workOrderBillPathType.all}>
							All Bills
						</Menu.Item>
						{canApprove ? (
							<Menu.Item key={workOrderBillPathType.toApprove}>
								Bills to be Approved
							</Menu.Item>
						) : []}
						{canViewReport ? (
							<Menu.Item key={workOrderBillPathType.weeklyReport}>
								Weekly Report
							</Menu.Item>
						) : []}
						{canViewReport ? (
							<Menu.Item key={workOrderBillPathType.inTotalReport}>
								TTD Report
							</Menu.Item>
						) : []}
						{canViewReport ? (
							<Menu.Item key={workOrderBillPathType.billWithoutWorkOrder}>
								Bill Without Work Order
							</Menu.Item>
						) : []}
						{canViewReport ? (
							<Menu.Item key={workOrderBillPathType.reApprovedReport}>
								Re Approved Bill Report by Prithu
							</Menu.Item>
						) : []}
						{canViewReport ? (
							<Menu.Item key={workOrderBillPathType.reApprovedClientReport}>
								Re Approved Bill Report by Client
							</Menu.Item>
						) : []}
					</Menu>
				</Col>
			</Row>

			<br />
			<br />

			<Row>
				<Col span={24}>
					{canWrite && currentPageType === workOrderBillPathType.new ? (
						<ViewWorkOrderBillForm />
					) : []}
					{currentPageType === workOrderBillPathType.all ? (
						<ViewAllWorkOrderBill toBeApproved={false} />
					) : []}
					{canApprove && currentPageType === workOrderBillPathType.toApprove ? (
						<ViewAllWorkOrderBill toBeApproved={true} />
					) : []}
					{canViewReport && currentPageType === workOrderBillPathType.weeklyReport ? (
						<ViewWorkOrderBillReport reportType={'weekly'} />
					) : []}
					{canViewReport && currentPageType === workOrderBillPathType.inTotalReport ? (
						<ViewWorkOrderBillReport reportType={'ttd'} />
					) : []}
					{canViewReport && currentPageType === workOrderBillPathType.billWithoutWorkOrder ? (
						<ViewBillWithoutWorkOrder />
					) : []}
					{canViewReport && currentPageType === workOrderBillPathType.reApprovedReport ? (
						<ViewReApprovedWorkOrderBill />
					) : []}
					{canViewReport && currentPageType === workOrderBillPathType.reApprovedClientReport ? (
						<ViewReApprovedWorkOrderBill reportType='client' />
					) : []}
				</Col>
			</Row>
		</DefaultLayout>
	);
};
