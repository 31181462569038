import React, {useState} from 'react';
import {checkFileErr, parseNum} from 'app/helpers';
import {Button, Col, Form, Input, message, Row, Spin, Typography} from 'antd';
import type Types from 'MyTypes';
import {getById, terminate} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {type UploadFile} from 'app/models/upload-file';
import {AttachmentParentType, type WorkOrder} from 'app/models';
import {FileUpload} from '../Common/FileUpload';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useHistory, useParams} from 'react-router';

type ParamType = {
	orderId?: string;
};

export const ViewTerminateWorkOrderForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<ParamType>();

	const {loading, byIds, dataUpdated, errorMessage} = useSelector((state: Types.RootState) => state.workOrder);

	const orderId: number = parseNum(params?.orderId ?? '0');

	const [terminationAmount, setTerminationAmount] = useState<number>(0);

	const [terminationFileList, setTerminationFileList] = useState<UploadFile[]>([]);

	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});

	const order = byIds[orderId];

	React.useEffect(() => {
		if (orderId && !byIds[orderId]) {
			dispatch(getById(orderId, {}));
		}
	}, [orderId]);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Success! Terminated in progress.');
			setTimeout(() => {
				history.goBack();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		if (!order?.id) {
			void message.error('Something went wrong. Please refresh page and try again');
			return;
		}

		const data: WorkOrder = {...order, ...{terminationAmount}};

		if (!data.terminationAmount) {
			void message.error('Please Enter Termination Amount');
			return;
		}

		checkFileErr(terminationFileList);

		data.terminationSummaryAttachments = terminationFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_TERMINATION_SUMMARY,
		}));

		if (!data.terminationSummaryAttachments?.length) {
			void message.error('Please Upload a Termination Summary File');
			return;
		}

		dispatch(terminate(orderId, data));
	};

	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 20},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 20},
	};

	return (
		<DefaultLayout currentPath={uiPaths.createWorkOrderFromSchedule}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={2} style={{textAlign: 'center'}}>Work Order</Typography.Title>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Form
							{...layout}
							name='basic'
						>

							<Form.Item
								label='Payment Settlement Milestone Amount'
							>
								<Input
									type={'number'}
									onChange={(e: any) => {
										setTerminationAmount(parseNum(e.target.value as string));
									}}
								/>
							</Form.Item>

							<Form.Item label='Termination Summary Files'>
								<FileUpload
									prefix={'work-order'}
									fileList={terminationFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(terminationFileList, filePrefixKeys) => {
										setTerminationFileList(terminationFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item {...tailLayout}>
								<Button type='primary' id={'Submit'} onClick={onSubmitClicked}>
									Terminate
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
