import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@projectProcessSchedule/API_FILTER',
	getApi: '@projectProcessSchedule/GET_API',
	getSingleApi: '@projectProcessSchedule/GET_SINGLE_API',
	getChecklistApi: '@projectProcessSchedule/GET_CHECKLIST_API',
	createApi: '@projectProcessSchedule/CREATE_API',
	updateApi: '@projectProcessSchedule/UPDATE_API',
	updateProgressPhotosApi: '@projectProcessSchedule/UPDATE_PROGRESS_PHOTOS_API',
	updateWarrantyDocumentsApi: '@projectProcessSchedule/UPDATE_WARRANTY_DOCUMENTS_API',
	removeApi: '@attachments/REMOVE_API',
};

const url = lbBaseUrl + '/project-process-schedules';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const getById = (id: number) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const getChecklist = (id: number, checklistId: string) => ({
	type: actionTypes.getChecklistApi,
	payload: axios.get(`${url}/${id}/checklists/${checklistId}`, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const updateProgressPhotos = (id: number, data: any) => ({
	type: actionTypes.updateProgressPhotosApi,
	payload: axios.patch(`${url}/${id}/in-progress`, data, getHeaders(true)),
	meta: {process: true},
});

export const updateWarrantyDocuments = (id: number, data: any) => ({
	type: actionTypes.updateWarrantyDocumentsApi,
	payload: axios.patch(`${url}/${id}/warranty-documents`, data, getHeaders(true)),
	meta: {process: true},
});

export const start = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/start`, data, getHeaders(true)),
	meta: {process: true},
});

export const complete = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/complete`, data, getHeaders(true)),
	meta: {process: true},
});

export const forceComplete = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/force-complete`, data, getHeaders(true)),
	meta: {process: true},
});

export const forceUpdate = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/force-update`, data, getHeaders(true)),
	meta: {process: true},
});

export const updateDatesBySite = (siteId: number) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${siteId}/update-dates`, undefined, getHeaders(true)),
	meta: {process: true},
});

export const removeAttachment = (id: number) => ({
	type: actionTypes.removeApi,
	payload: axios.delete(`${lbBaseUrl}/attachments/${id}`, getHeaders(true)),
	meta: {process: true},
});
