import {Select, Spin} from 'antd';
import type {SelectProps} from 'antd/es/select';
import {type FilterOptionItem} from 'app/models/ui-filter';
import debounce from 'lodash/debounce';
import React, {useMemo, useRef, useState} from 'react';

export type DebounceSelectProps = {
	fetchOptions: (search: string) => Promise<FilterOptionItem[]>;
	singleSelect?: boolean;
	debounceTimeout?: number;
} & Omit<SelectProps<FilterOptionItem | FilterOptionItem[] | string>, 'options' | 'children'>;

export const DebounceSelect: React.FC<DebounceSelectProps> = ({
	fetchOptions,
	debounceTimeout = 800,
	singleSelect = false,
	...props
}) => {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState<FilterOptionItem[]>([]);
	const fetchRef = useRef(0);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const debounceFetcher = useMemo(() => {
		const loadOptions = (value: string) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);

			void fetchOptions(value).then(newOptions => {
				if (fetchId !== fetchRef.current) {
					// For fetch callback order
					return;
				}

				setOptions(newOptions);
				setFetching(false);
			});
		};

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	return singleSelect ? (
		<Select
			labelInValue
			showSearch={true}
			filterOption={false}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size='small' /> : null}
			{...props}
		>
			{options?.length ? options.map((opt: FilterOptionItem, ix: number) =>
				<Select.Option key={ix} value={opt.value}>{opt.label}</Select.Option>,
			) : []}
		</Select>
	) : (
		<Select
			labelInValue
			filterOption={false}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size='small' /> : null}
			{...props}
			options={options}
		/>
	);
};
