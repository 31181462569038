export type ProjectHandoverActivity = {
	id: string;
	sno: string;
	parentId?: string;
	siteId: number;
	activityName: string;
	timelineDate?: string;
	noOfDays?: number;
	remarks?: string;
	timeImpactStr?: string;
	children?: ProjectHandoverActivity[];
	timeImpacts?: TimeImpact[];
};

export type TimeImpact = {
	sno?: string;
	title: string;
	noOfDays: number;
	description?: string;
	referenceLink: string;
};

export enum Fields {
	CONTRACT_SIGNING_DATE = 'CONTRACT_SIGNING_DATE',
	PROJECT_START_DATE = 'PROJECT_START_DATE',
	FLOOR_PLAN_SIGNOFF_DATE = 'FLOOR_PLAN_SIGNOFF_DATE',
	PLOT_HANDOVER_DATE = 'PLOT_HANDOVER_DATE',
	PROJECT_COMPLETION_PERIOD = 'PROJECT_COMPLETION_PERIOD',
	EXTENSION_DELIVERY_COMMITMENTS = 'EXTENSION_DELIVERY_COMMITMENTS',
	FORCE_MAJEURE = 'FORCE_MAJEURE',
	STOP_WORK = 'STOP_WORK',
	PAYMENTS_DELAY = 'PAYMENTS_DELAY',
	DECISION_MAKING_DELAY = 'DECISION_MAKING_DELAY',
	SCOPE_OF_WORK_CHANGES = 'SCOPE_OF_WORK_CHANGES',
	WORK_RESTRICTIONS = 'WORK_RESTRICTIONS',
	PENALTY_PAYABLE_DATE = 'PENALTY_PAYABLE_DATE',
	PENALTY_PAYABLE = 'PENALTY_PAYABLE',
	INCENTIVE = 'INCENTIVE',
	TENTATIVE_PROJECT_HANDOVER_DATE = 'TENTATIVE_PROJECT_HANDOVER_DATE',
}

export const parentChildrenMap: Record<string, string[]> = {
	[Fields.CONTRACT_SIGNING_DATE]: [],
	[Fields.PROJECT_START_DATE]: [],
	[Fields.FLOOR_PLAN_SIGNOFF_DATE]: [],
	[Fields.PLOT_HANDOVER_DATE]: [],
	[Fields.PROJECT_COMPLETION_PERIOD]: [],
	[Fields.EXTENSION_DELIVERY_COMMITMENTS]: [
		Fields.FORCE_MAJEURE,
		Fields.STOP_WORK,
		Fields.PAYMENTS_DELAY,
		Fields.DECISION_MAKING_DELAY,
		Fields.SCOPE_OF_WORK_CHANGES,
		Fields.WORK_RESTRICTIONS,
	],
	[Fields.PENALTY_PAYABLE_DATE]: [],
	[Fields.PENALTY_PAYABLE]: [],
	[Fields.INCENTIVE]: [],
	[Fields.TENTATIVE_PROJECT_HANDOVER_DATE]: [],
};

export enum FieldsLabelName {
	CONTRACT_SIGNING_DATE = 'Contract Signing Date',
	PROJECT_START_DATE = 'Project Start Date as per contract is later of the dates below as per schedule C of the agreement',
	FLOOR_PLAN_SIGNOFF_DATE = 'Floor Plan Sign off Date',
	PLOT_HANDOVER_DATE = 'Date of Plot Handover',
	PROJECT_COMPLETION_PERIOD = 'Project Completion Period as per contract(in months)',
	EXTENSION_DELIVERY_COMMITMENTS = 'Extension in Delivery Commitments timeline as per schedule C of the agreement',
	FORCE_MAJEURE = 'Force Majeure reasons as mentioned in the agreement',
	STOP_WORK = 'Stop Work on the request of the owner',
	PAYMENTS_DELAY = 'Delay in making payments by the owner',
	DECISION_MAKING_DELAY = 'Delay in decision making from the owner',
	SCOPE_OF_WORK_CHANGES = 'Change in the scope of work by the owner due to reasons not attributable to the developer which require additional time for implementation',
	WORK_RESTRICTIONS = 'Work Restrictions imposed by the relevant RWA(e.g no work on sundays and restricted work timings)',
	PENALTY_PAYABLE_DATE = 'Date of Project Handover beyond which penalty is payable by Prithu',
	PENALTY_PAYABLE = 'Penalty payable as per agreement(per month)',
	INCENTIVE = 'Incentive as per agreement(per month)',
	TENTATIVE_PROJECT_HANDOVER_DATE = 'Tentative Project Handover Date as per current site progress',
}

export enum FieldsSerialNumbers {
	CONTRACT_SIGNING_DATE = 'A',
	PROJECT_START_DATE = 'B',
	FLOOR_PLAN_SIGNOFF_DATE = 'C',
	PLOT_HANDOVER_DATE = 'D',
	PROJECT_COMPLETION_PERIOD = 'E',
	EXTENSION_DELIVERY_COMMITMENTS = 'F',
	FORCE_MAJEURE = 'a',
	STOP_WORK = 'b',
	PAYMENTS_DELAY = 'c',
	DECISION_MAKING_DELAY = 'd',
	SCOPE_OF_WORK_CHANGES = 'e',
	WORK_RESTRICTIONS = 'f',
	PENALTY_PAYABLE_DATE = 'G',
	PENALTY_PAYABLE = 'H',
	INCENTIVE = 'I',
	TENTATIVE_PROJECT_HANDOVER_DATE = 'J',
}
