import React from 'react';
import {formatDateFunction, getCurrencyString} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {Col, Row, Space, Typography} from 'antd';
import {type ContractorForeman, OrderType, type WorkOrder, type WorkOrderDebit} from 'app/models';
import {ColBlock} from 'app/components/Common/ColBlock';
import {Link} from 'react-router-dom';
import {ColFileBlock} from 'app/components/Common/ColFileBlock';
import {ViewWorkOrderWorkOrderBill} from 'app/components/WorkOrder/ViewWorkOrderWorkOrderBill';

type Props = {
	workOrder: WorkOrder;
};

export const ViewWorkOrderSummary: React.FC<Props> = ({workOrder}) => (
	<Row style={{marginBottom: 20}}>
		<Col span={24}>
			<ColBlock label={'Type'} value={workOrder?.projectProcessMaster?.processName ?? ''}/>
			<ColBlock label={'Contractor Visit'} value={workOrder?.contractorVisit ?? ''}/>
			<ColBlock
				label={'Work Order Amount'}
				value={getCurrencyString(workOrder?.workOrderAmount, false) ?? 'N/A'}
			/>
			<ColBlock
				label={'Work Order Quantity'}
				value={workOrder?.workOrderQuantity ? `${workOrder?.workOrderQuantity ?? ''}` : 'N/A'
				}
			/>
			{workOrder?.orderType === OrderType.RATE && (
				<ColBlock label={'Rate Per Unit'} value={workOrder?.ratePerUnit ?? ''}/>
			)}
			{workOrder?.orderType === OrderType.RATE && (
				<ColBlock label={'Quantity Measure Rule'} value={workOrder?.quantityMeasureRule ?? ''}/>
			)}
			<ColBlock
				label={'Contractor'}
				value={workOrder?.contractor ? (
					<Link to={{pathname: `/${uiPaths.userDetail.replace(':userId', workOrder?.contractor?.id.toString())}`}}>
						{workOrder?.contractor?.name ?? ''}
					</Link>
				) : []}
			/>
			{workOrder?.billedToClient ? (
				<ColBlock label={'Billed to Client'} value={'Yes'} />
			) : []}
			<ColBlock
				label={'Foremen/Supervisors'}
				value={(
					<Space direction={'vertical'}>
						{workOrder?.contractorForemen?.map((foreman: ContractorForeman) => (
							<Space key={foreman.id}>{foreman.name} {foreman.phone}</Space>
						))}
					</Space>
				)}
			/>
			<ColBlock
				label={'Debits'}
				value={workOrder?.debits ? (
					<Space direction={'vertical'}>
						{workOrder?.debits.map((debit: WorkOrderDebit) => (
							<Typography.Text key={debit.id} type={debit.workOrderBillId ? 'success' : undefined}>
								{debit.workOrderBillId ? '(Recovered in Bill) ' : ''}
								{`${debit.description}: ${getCurrencyString(debit.amount, false)}`}
								{' '}
								{`(${formatDateFunction(debit.addedAt, false)})`}
							</Typography.Text>
						))}
						<b>
								Total Debited Amount:
							{' '}
							{getCurrencyString(workOrder?.debits
								.reduce((amt, debit) => {
									amt += debit.amount;
									return amt;
								}, 0), false)}
						</b>
					</Space>
				) : []}
			/>
			<ColFileBlock
				label={'Scope of Work'}
				textValue={workOrder?.scopeOfWork}
				attachments={workOrder?.scopeOfWorkAttachments}
			/>
			<ColFileBlock
				label={'Material Scope of contractor & Supplied by the Contractor'}
				textValue={workOrder?.materialScopeContractorSupContractor}
				attachments={workOrder?.materialScopeContractorSupContractorAttachments}
			/>
			<ColFileBlock
				label={'Material in Scope of contractor & provided by Prithu'}
				textValue={workOrder?.materialScopeContractorProvPrithu}
				attachments={workOrder?.materialScopeContractorProvPrithuAttachments}
			/>
			<ColFileBlock
				label={'Item rate list for extra work and rework'}
				textValue={workOrder?.itemRateListExtra}
				attachments={workOrder?.itemRateListExtraAttachments}
			/>
			<ColFileBlock
				label={'Quality Defect'}
				textValue={workOrder?.qualityCheck}
				attachments={workOrder?.qualityCheckAttachments}
			/>
			<ColFileBlock
				label={'Project Schedule and Delay Penalty calculation'}
				textValue={workOrder?.schedule}
				attachments={workOrder?.scheduleAttachments}
			/>
			<ColFileBlock
				label={'Contact Person'}
				textValue={workOrder?.contactPerson}
				attachments={workOrder?.contactPersonAttachments}
			/>
			<ColFileBlock
				label={'Term of agreement'}
				textValue={workOrder?.generalClauses}
				attachments={workOrder?.generalClausesAttachments}
			/>
			<ColFileBlock
				label={'Drawing files'}
				textValue={workOrder?.drawingQuantityCalc}
				attachments={workOrder?.drawingQuantityCalcAttachments}
			/>
			<ColFileBlock
				label={'Quantity calculation'}
				textValue={workOrder?.qualityAndPrice}
				attachments={workOrder?.qualityAndPriceAttachments}
			/>
			<ColFileBlock
				label={'Tender Comparison Sheet'}
				textValue={workOrder?.tenderComparison}
				attachments={workOrder?.tenderComparisonAttachments}
			/>
			<ColFileBlock
				label={'Other Files'}
				attachments={workOrder?.otherFilesAttachments}
			/>
			{workOrder ? <ViewWorkOrderWorkOrderBill workOrder={workOrder} /> : []}
		</Col>
	</Row>
);
