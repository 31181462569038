import React from 'react';
import {Col, Row} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {ViewAllProjectBudgetQuantities} from '../ProjectBudgetQuantities/ViewAllProjectBudgetQuantities';

export const ViewApprovalProjectBudgetQuantity: React.FC = () => (
	<DefaultLayout currentPath={uiPaths.allProjectBudgetQuantities}>
		<Row>
			<Col span={24}>
				<ViewAllProjectBudgetQuantities toBeApproved={true} />
			</Col>
		</Row>
	</DefaultLayout>
);
