import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type ProjectHandoverActivity} from 'app/models';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	initPage: '@projectHandoverActivities/INIT_PAGE',
	apiFilter: '@projectHandoverActivities/API_FILTER',
	createGetApi: '@projectHandoverActivities/CREATE_GET_API',
	updateApi: '@projectHandoverActivities/UPDATE_API',
	getApi: '@projectHandoverActivities/GET_API',
};

const url = lbBaseUrl + '/project-handover-activities';

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const createAndGet = (data: any) => ({
	type: actionTypes.createGetApi,
	payload: axios.post(url, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (data: ProjectHandoverActivity[]) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(url, data, getHeaders(true)),
	meta: {process: true},
});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});
