import React from 'react';
import {Button, Input} from 'antd';
import {type ContactDetail} from 'app/models';

type Prop = {
	currentValue?: ContactDetail;
	onValueChange: (value: ContactDetail) => void;
};

export const ContactDetailForm: React.FC<Prop> = ({currentValue, onValueChange}) => (
	<Input.Group compact style={{width: '100%'}}>
		<Button disabled={true}>Contact Name</Button>
		<Input
			style={{width: '30%'}}
			value={currentValue?.name ?? ''}
			onChange={(e: any) => {
				onValueChange({...currentValue, name: String(e.target.value)});
			}}
		/>
		<Button disabled={true}>Contact Number</Button>
		<Input
			style={{width: '30%'}}
			value={currentValue?.phone ?? ''}
			onChange={(e: any) => {
				onValueChange({...currentValue, phone: String(e.target.value)});
			}}
		/>
	</Input.Group>
);
