import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';

export const actionTypes = {
	resetState: '@prithuWallet/RESET_STATE',
	getApi: '@prithuWallet/GET_API',
	createApi: '@prithuWallet/CREATE_API',
	updateApi: '@prithuWallet/UPDATE_API',
	removeApi: '@prithuWallet/REMOVE_API',
};

const url = `${lbBaseUrl}/prithu-wallets`;

export const reset = () => ({
	type: actionTypes.resetState,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const save = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const addMoney = (data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/add-money`, data, getHeaders(true)),
	meta: {process: true},
});

export const remove = (id: number) => ({
	type: actionTypes.removeApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});
