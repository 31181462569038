import React from 'react';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useDispatch, useSelector} from 'react-redux';
import {type FilterDataType} from '../Common/Filter/FilterView';
import {getQueryDateRangeForDays, getQueryDateRange} from 'app/query_helpers';
import type Types from 'MyTypes';
import {FileUpload} from '../Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {
	Col,
	Row,
	Spin,
	Table,
	Typography,
	Button,
	Space,
	Alert,
	message,
	InputNumber,
	Divider,
	Form,
	Select,
} from 'antd';
import {isMobile, equalNum, parseNum, toTitleCase, checkFileErr} from 'app/helpers';
import {setFilterValue, get, tryGetApi} from './actions';
import {type ElectricityConsumption, ModuleName, ModulePermission, type UserType, type Attachment, MeterType, type AttachmentData} from 'app/models';
import {getPermissionSites, formatDateFunction} from 'app/helpers';
import {FilterView} from 'app/components/Common/Filter/FilterView';
import {AttachmentsPreviewBlock} from 'app/components/ProjectProcessSchedule/AttachmentsPreviewBlock';
import {useHistory, useParams} from 'react-router';
import {create, update} from './actions';

type MyParams = {
	siteId?: string;
};

export const ViewElectricityConsumptionForm: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<MyParams>();
	const siteId = parseNum(params?.siteId ?? '0');
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const site = allSites.find(s => s.id === siteId);
	const {loading, electricityConsumptionItem, dataUpdated, errorMessage, filterValue} = useSelector(
		(state: Types.RootState) => state.electricityConsumption,
	);
	const reportDate = new Date(new Date().setHours(0, 0, 0, 0));
	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 18},
	};

	const electricityConsumption = electricityConsumptionItem;

	const [meterType, setMeterType] = React.useState<string | undefined>(electricityConsumption?.meterType ?? undefined);
	const [meterCount, setMeterCount] = React.useState<number | undefined>(
		electricityConsumption?.meterCount ?? undefined,
	);
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<
	Record<string, string>
	>({});

	const tailLayout = {
		wrapperCol: {offset: 8, span: 18},
	};

	React.useEffect(() => {
		console.log('ViewElectricityConsumptionForm.componentDidMount');
		if (siteId) {
			const data = {
				siteId,
				reportDate,
			};
			dispatch(
				tryGetApi({
					where: data,
				}),
			);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Success');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		if (!meterCount || !meterType) {
			void message.error('Enter Meter Reading and Meter Type');
			return;
		}

		checkFileErr(fileList);

		const attachments: AttachmentData[] = fileList.map(
			(file: UploadFile) => {
				const x: AttachmentData = {
					name: file.name,
					key: `${filePrefixKeys[file.uid]}/${file.name}`,
				};
				return x;
			},
		);

		if (!attachments?.length && !electricityConsumption) {
			void message.error('Please Upload Photos');
			return;
		}

		const data: any = {
			siteId,
			meterCount,
			meterType,
			reportDate,
		};
		if (electricityConsumption?.id) {
			dispatch(update(electricityConsumption.id, data));
		} else {
			data.attachments = attachments;
			dispatch(create(data));
		}
	};

	return (
		<DefaultLayout currentPath={uiPaths.electricityConsumptionForm}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Button className='btn-primary' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Electricity Consumption Form
						</Typography.Title>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form {...layout} name='basic'>
							<Form.Item label={<b>Site</b>}>
								<b>{site?.name}</b>
							</Form.Item>
							<Form.Item label={<b>Date</b>}>
								<b>{formatDateFunction(reportDate.toString(), false)}</b>
							</Form.Item>
							<Form.Item label='Meter Type'>
								<Select
									style={{width: 250}}
									placeholder='Select a Meter Type'
									defaultValue={meterType}
									onChange={value => {
										setMeterType(value);
									}
									}
								>
									{Object.values(MeterType).map((type: string) => (
										<Select.Option key={type} value={type}>
											{toTitleCase(type)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label='Meter Reading'>
								<InputNumber
									style={{width: 250}}
									placeholder='Enter Meter Reading'
									value={meterCount}
									onChange={value => {
										setMeterCount(value ?? 0);
									}}
								/>
							</Form.Item>
							{electricityConsumption?.attachments?.length ? (
								<Form.Item label = 'Photos'>
									<AttachmentsPreviewBlock
										attachments={electricityConsumption.attachments}
									/>
								</Form.Item>
							) : (
								[]
							)}
							{electricityConsumption ? ([]) : (
								<Form.Item label='Attachments *'>
									<FileUpload
										prefix={'query'}
										fileList={fileList}
										filePrefixKeys={filePrefixKeys}
										onFileChange={(fileList, filePrefixKeys) => {
											setFileList(fileList);
											setFilePrefixKeys(filePrefixKeys);
										}}
									/>
									<br/>
									<Alert
										message='Uploading'
										description='Kindly ensure that you upload time stamped photographs'
										type='warning'
									/>
								</Form.Item>
							)}
							<Form.Item {...tailLayout}>
								<Button id={'submit'} type='primary' onClick={onSubmitClicked}>
									{electricityConsumption ? 'Update' : 'Submit'}
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};

