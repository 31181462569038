/* eslint-disable complexity */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	DatePicker,
	Form,
	message,
	Row,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {getById, create, update, search as searchActivities} from './actions';
import {search as searchChecklists} from '../ProcessChecklist/actions';
import {useHistory, useParams} from 'react-router';
import {addDays, getUniqueId, parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {type ProjectActivity} from './project-activity';
import {type ActivityItem, ActivityType} from '../ActivityTemplateItem/activity-template-item';
import dayjs from 'dayjs';
import {ActivityItemFormBlock} from '../ActivityTemplateItem/ActivityItemFormBlock';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type ProcessChecklist} from 'app/models/process-checklist.model';

type MyParams = {
	id?: string;
	siteId?: string;
	type?: string;
};

export const ViewProjectActivityForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();

	const {loading, byIds, dataUpdated, errorMessage}
		= useSelector((state: Types.RootState) => state.projectActivity);

	const id = parseNum(params?.id);
	const siteId = parseNum(params?.siteId);
	const type = params?.type ? (params.type as ActivityType) : undefined;
	const item = byIds[id]
		? (byIds[id])
		: undefined;

	const [activityItem, setActivityItem] = React.useState<ActivityItem>({
		activityType: item?.activityType ?? type ?? ActivityType.CONSTRUCTION,
		isRootActivity: item?.isRootActivity ?? false,
		name: item?.name ?? '',
		sequence: item?.sequence ?? 0,
		duration: item?.duration ?? 0,
		formula: item?.formula ?? undefined,
		operator: item?.operator ?? undefined,
		operandDays: item?.operandDays ?? 0,
		queryType: ActivityType.CONSTRUCTION,
		itemValues: item?.linkedActivities?.length
			? item.linkedActivities.map(a => ({
				label: a?.name ?? '',
				value: a?.uniqueId ?? '',
			})) : [],
		linkedFromType: item?.linkedFromType ?? undefined,
		checklistValues: item?.linkedChecklists?.length
			? item.linkedChecklists.map(a => ({
				label: a?.title ?? '',
				value: a?.id ?? '',
			})) : [],
	});
	const [baselineStartDate, setBaselineStartDate] = React.useState(
		item?.baselineStartDate ?? undefined,
	);
	const [startedAt, setStartedAt] = React.useState(item?.startedAt ?? undefined);
	const [completedAt, setCompletedAt] = React.useState(item?.completedAt ?? undefined);

	React.useEffect(() => {
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (id) {
				void message.success('Updated Successfully.');
				dispatch(getById(id));
			} else {
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	// React.useEffect(() => {
	// 	if (item?.id) {
	// 		window.location.reload();
	// 	}
	// }, [item]);

	const onSubmitClicked = () => {
		const data: Omit<ProjectActivity, 'id'> = {
			uniqueId: item?.uniqueId ?? getUniqueId(),
			siteId: item?.siteId ?? siteId,
			isRootActivity: activityItem.isRootActivity,
			activityType: activityItem.activityType,
			name: activityItem.name,
			sequence: activityItem.sequence ?? 0,
			formula: activityItem.formula,
			operator: activityItem.operator,
			operandDays: activityItem.operandDays,
			duration: activityItem.duration,
			linkedUniqueIds: activityItem.itemValues?.length
				? activityItem.itemValues.map(opt => opt.value).join(',')
				: undefined,
			linkedFromType: activityItem.linkedFromType,
			linkedChecklistIds: activityItem.checklistValues?.length
				? activityItem.checklistValues.map(opt => opt.value).join(',')
				: undefined,
			baselineStartDate: baselineStartDate ? dayjs(baselineStartDate).format('YYYY-MM-DDTHH:mm:ss.sssZ') : undefined,
			baselineEndDate: baselineStartDate ? dayjs(addDays(baselineStartDate, activityItem.duration ?? 0)).format('YYYY-MM-DDTHH:mm:ss.sssZ') : undefined,
			startedAt: startedAt ? dayjs(startedAt).format('YYYY-MM-DDTHH:mm:ss.sssZ') : undefined,
			completedAt: completedAt ? dayjs(completedAt).format('YYYY-MM-DDTHH:mm:ss.sssZ') : undefined,
		};

		if (!data.name) {
			void message.error('Please enter a name!');
			return;
		}

		if (!data.activityType) {
			void message.error('Activity Type Missing!');
			return;
		}

		if (data.isRootActivity) {
			// If this activity is root activity we don't need below fields
			delete data.formula;
			delete data.operator;
			delete data.operandDays;
			delete data.linkedUniqueIds;
			delete data.linkedChecklistIds;
			// And projected dates will be equal to baseline dates
			data.projectedStartDate = data.baselineStartDate;
			data.projectedEndDate = data.baselineEndDate;
		}

		if (item?.id) {
			dispatch(update(item.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<DefaultLayout currentPath={uiPaths.addProjectActivity}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Project Activity Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
						<Form {...layout} name='basic'>
							<ActivityItemFormBlock
								item={activityItem}
								onItemChange={item => {
									setActivityItem(item);
								}}
								onItemSearch={async (
									query: string,
								): Promise<FilterOptionItem[]> =>
									searchActivities(activityItem.queryType, query, item?.siteId ?? siteId).then(data =>
										data.data.filter((dataItem: ProjectActivity) =>
											dataItem.uniqueId !== item?.uniqueId,
										).map((dataItem: ProjectActivity) => ({
											label: dataItem?.name ?? '',
											value: dataItem?.uniqueId ?? '',
										})),
									)
								}
								onChecklistChange={item => {
									setActivityItem(item);
								}}
								onChecklistSearch={async (
									query: string,
								): Promise<FilterOptionItem[]> =>
									searchChecklists(query).then(data =>
										data.data.map((dataItem: ProcessChecklist) => ({
											label: dataItem?.title ?? '',
											value: dataItem?.id ?? '',
										})),
									)
								}
							/>

							{activityItem.isRootActivity ? (
								<Form.Item
									label='Baseline Start Date'
									help='Setting Baseline Start will clear formula, linked activities fields. Baseline End date will be calculate by duration with star date'
								>
									<DatePicker
										value={
											baselineStartDate ? dayjs(baselineStartDate) : undefined
										}
										onChange={(date, dateString) => {
											setBaselineStartDate(dateString);
										}}
									/>
								</Form.Item>
							) : []}

							<Form.Item label='Started At'>
								<DatePicker
									value={
										startedAt ? dayjs(startedAt) : undefined
									}
									onChange={(_, dateString) => {
										setStartedAt(dateString);
									}}
								/>
							</Form.Item>

							<Form.Item label='Completed At'>
								<DatePicker
									value={
										completedAt ? dayjs(completedAt) : undefined
									}
									onChange={(_, dateString) => {
										setCompletedAt(dateString);
									}}
								/>
							</Form.Item>

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
