import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type ProjectProcessMaster} from 'app/components/ProjectProcessMaster/project-process-master';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<ProjectProcessMaster['id'], ProjectProcessMaster>;
	allIds: Array<ProjectProcessMaster['id']>;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

const scheduleManualEntryReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('scheduleManualEntry', initialState),
	action: any,
) => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage: action.payload?.response?.data?.error?.message as string ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as ProjectProcessMaster[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default scheduleManualEntryReducer;
