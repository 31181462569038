import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
	Alert,
	Button,
	Card,
	Col,
	Divider,
	Form,
	message,
	Row,
	Select,
	Space,
	Spin,
	Typography,
} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {
	equalNum,
	getPermissionSites,
	parseNum,
	getOrdinalNumberWords,
	toTitleCase,
} from 'app/helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	type Drawing,
	DrawingScopeType,
	DrawingStatusType,
	ModuleName,
	ModulePermission,
} from 'app/models';
import {DrawingBlock} from './DrawingBlock';
import {
	initPage,
	createAndGet,
	getById,
	update,
	saveDrawings,
	removeDrawing,
} from './actions';
import {useHistory, useParams} from 'react-router';
import {DrawingForm} from 'app/components/DrawingSchedule/DrawingForm';
import {ViewParseLink} from 'app/components/Common/ViewParseLink';
import {
	type ProcessChecklist,
	ProcessChecklistOnActionType,
} from 'app/models/process-checklist.model';
import {ProcessChecklistResponseStatus} from 'app/models/process-checklist-response.model';
import {ProcessChecklistBlock} from 'app/components/ProjectProcessSchedule/ProcessChecklistBlock';
import {uiPaths} from 'app/constants';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';

type MyParams = {
	siteId?: string;
	tabId?: string;
};

// eslint-disable-next-line complexity
export const ViewDrawingScheduleForm: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<MyParams>();
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {loading, dataUpdated, errorMessage, ids, records} = useSelector(
		(state: Types.RootState) => state.drawingSchedule,
	);

	const siteId = parseNum(params?.siteId ?? '0');
	const projectProcessMasterId = parseNum(params?.tabId ?? '0');
	const writeSites = getPermissionSites(
		permissions,
		ModuleName.DRAWING_SCHEDULE,
		ModulePermission.WRITE,
		allSites,
	);
	const viewProcessDocSites = getPermissionSites(
		permissions,
		ModuleName.DRAWING_SCHEDULE_PROCESS_DOCUMENT,
		ModulePermission.READ,
		allSites,
	);
	const drawingSchedule = ids
		.map(id => records[id])
		.find(
			item =>
				item.projectProcessMasterId === projectProcessMasterId
				&& item.siteId === siteId,
		);

	const isRevision
		= drawingSchedule?.status === DrawingStatusType.COMPLETED
		|| drawingSchedule?.status === DrawingStatusType.REVISED;

	const [scope, setScope] = React.useState<DrawingScopeType | undefined>(
		drawingSchedule?.scope ?? undefined,
	);
	const [showUpload, setShowUpload] = React.useState<boolean>(false);
	const [drawings, setDrawings] = React.useState<Drawing[]>([]);
	const [revisionReason, setRevisionReason] = React.useState<string>('');
	React.useEffect(() => {
		console.log('ViewDrawingScheduleForm.componentDidMount');
		dispatch(initPage());
		const data = {
			siteId: parseNum(String(siteId)),
			projectProcessMasterId,
		};
		dispatch(createAndGet(data));
		// }
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			setShowUpload(false);
			setDrawings([]);
			void message.success('Updated successfully');
			if (drawingSchedule?.id) {
				dispatch(getById(drawingSchedule.id));
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const categorizeChecklist = () => {
		const afterCompletionFilledChecklists: ProcessChecklist[] = [];
		const beforeStartFilledChecklists: ProcessChecklist[] = [];
		const beforeStartUnfilledChecklists: ProcessChecklist[] = [];
		const inProgressFilledChecklists: ProcessChecklist[] = [];
		const inProgressUnfilledChecklists: ProcessChecklist[] = [];
		const afterCompletionUnfilledChecklists: ProcessChecklist[] = [];

		drawingSchedule?.projectProcessMaster?.processChecklists?.forEach(checklist => {
			if (
				checklist.onActionType === ProcessChecklistOnActionType.BEFORE_START
			) {
				const checklistResponse = drawingSchedule?.checklistResponses?.find(
					res => res.processChecklistId === checklist.id,
				);
				if (checklistResponse?.status) {
					if (
						checklistResponse?.status
						=== ProcessChecklistResponseStatus.APPROVED
						|| checklistResponse?.status
						=== ProcessChecklistResponseStatus.REJECTED
						|| checklistResponse?.status
						=== ProcessChecklistResponseStatus.SUBMITTED
					) {
						beforeStartFilledChecklists.push(checklist);
					} else {
						beforeStartUnfilledChecklists.push(checklist);
					}
				} else {
					beforeStartUnfilledChecklists.push(checklist);
				}
			}

			if (
				checklist.onActionType === ProcessChecklistOnActionType.IN_PROGRESS
			) {
				const checklistResponse = drawingSchedule?.checklistResponses?.find(
					res => res.processChecklistId === checklist.id,
				);
				if (checklistResponse?.status) {
					if (
						checklistResponse?.status
						=== ProcessChecklistResponseStatus.APPROVED
						|| checklistResponse?.status
						=== ProcessChecklistResponseStatus.REJECTED
						|| checklistResponse?.status
						=== ProcessChecklistResponseStatus.SUBMITTED
					) {
						inProgressFilledChecklists.push(checklist);
					} else {
						inProgressUnfilledChecklists.push(checklist);
					}
				} else {
					inProgressUnfilledChecklists.push(checklist);
				}
			}

			if (
				checklist.onActionType
				=== ProcessChecklistOnActionType.AFTER_COMPLETION
			) {
				const checklistResponse = drawingSchedule?.checklistResponses?.find(
					res => res.processChecklistId === checklist.id,
				);
				if (checklistResponse?.status) {
					if (
						checklistResponse?.status
						=== ProcessChecklistResponseStatus.APPROVED
						|| checklistResponse?.status
						=== ProcessChecklistResponseStatus.REJECTED
						|| checklistResponse?.status
						=== ProcessChecklistResponseStatus.SUBMITTED
					) {
						afterCompletionFilledChecklists.push(checklist);
					} else {
						afterCompletionUnfilledChecklists.push(checklist);
					}
				} else {
					afterCompletionUnfilledChecklists.push(checklist);
				}
			}
		},
		);
		return {
			beforeStartFilledChecklists,
			beforeStartUnfilledChecklists,
			inProgressFilledChecklists,
			inProgressUnfilledChecklists,
			afterCompletionFilledChecklists,
			afterCompletionUnfilledChecklists,
		};
	};

	const checklistCategories = categorizeChecklist();

	const updateScope = (value: DrawingScopeType) => {
		if (!drawingSchedule?.id) {
			void message.error('Something went wrong. Please refresh the page');
			return;
		}

		setScope(value);
		dispatch(update(drawingSchedule.id, {scope: value}));
	};

	const uploadDrawingFiles = (revise = false) => {
		if (isRevision && !revisionReason) {
			void message.error('Please Enter a reason to upload revision');
			return;
		}

		if (!drawingSchedule?.id) {
			void message.error('Something is not okay. Please refresh the page');
			return;
		}

		if (drawings.filter(drawing => !drawing.name || !drawing.key).length) {
			void message.error('Please enter name and select for each drawings');
			return;
		}

		dispatch(
			saveDrawings(drawingSchedule.id, {drawings, revise, revisionReason}),
		);
	};

	const removeDrawingFiles = (id: number) => {
		dispatch(
			removeDrawing(id),
		);
	};

	const handleUpload = () => {
		uploadDrawingFiles();
	};

	const onUploadCompleteDrawingClick = () => {
		if (drawingSchedule?.projectProcessMaster?.processChecklists?.length) {
			const noFilledChecklist = [
				...checklistCategories.beforeStartUnfilledChecklists,
				...checklistCategories.inProgressUnfilledChecklists,
				...checklistCategories.afterCompletionUnfilledChecklists,
			];
			if (noFilledChecklist.length) {
				void message.error(
					`There are ${noFilledChecklist.length} unfilled checklists.`,
				);
				return;
			}

			const notApprovedChecklists = [
				...checklistCategories.beforeStartFilledChecklists,
				...checklistCategories.inProgressFilledChecklists,
				...checklistCategories.afterCompletionFilledChecklists,
			].filter(checklist => {
				const checklistResponse = drawingSchedule?.checklistResponses?.find(
					res => res.processChecklistId === checklist.id,
				);
				return (
					!checklistResponse?.status
					|| checklistResponse?.status !== ProcessChecklistResponseStatus.APPROVED
				);
			});
			if (notApprovedChecklists?.length) {
				void message.error(
					`There are ${notApprovedChecklists?.length} not approved checklists.`,
				);
				return;
			}
		}

		setShowUpload(true);
	};

	const onUploadReviseDrawingClick = () => {
		setShowUpload(true);
	};

	const handleReviseUpload = () => {
		uploadDrawingFiles(true);
	};

	const onDrawingChange = (i: number, value: Drawing) => {
		const items = [...drawings];
		items[i] = value;
		setDrawings(items);
	};

	const onDrawingAdd = () => {
		const items = [...drawings];
		items.push({name: ''});
		setDrawings(items);
	};

	const onDrawingRemove = (i: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to remove')) {
			const items = [...drawings];
			items.splice(i, 1);
			setDrawings(items);
		}
	};

	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 18},
	};
	const canWrite = Boolean(writeSites.find(s =>
		equalNum(s.id, drawingSchedule?.siteId),
	));
	const canViewProcessDoc = Boolean(viewProcessDocSites.find(s =>
		equalNum(s.id, drawingSchedule?.siteId),
	));
	return (
		<DefaultLayout currentPath={uiPaths.drawingProcessScheduleForm}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Button className='btn-primary' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Drawing Schedule{' '}
							{drawingSchedule?.projectProcessMaster?.processName
								? `for ${drawingSchedule?.projectProcessMaster?.processName}`
								: ''}
						</Typography.Title>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form {...layout} name='basic'>
							{canWrite ? (
								<Form.Item label={<b>Scope</b>}>
									<Select
										size={'large'}
										onChange={value => {
											updateScope(value as DrawingScopeType);
										}}
										defaultValue={scope ?? ''}
										style={{width: 220}}
									>
										<Select.Option value=''>Select Scope</Select.Option>
										{Object.values(DrawingScopeType).map(
											(opt: string, ix: number) => (
												<Select.Option key={ix} value={opt}>
													{toTitleCase(opt, '_')}
												</Select.Option>
											),
										)}
									</Select>
								</Form.Item>
							) : (
								[]
							)}
							{scope === DrawingScopeType.IN_SCOPE ? (
								<Form.Item label={<b>Status</b>}>
									{toTitleCase(drawingSchedule?.status, '_') ?? ''}
									{drawingSchedule?.revisionCount
										&& `( ${getOrdinalNumberWords(
											drawingSchedule?.revisionCount,
										)} revision )`}
								</Form.Item>
							) : (
								<Form.Item label={<b>Scope</b>}>
									<b>{toTitleCase(scope, '_')}</b>
								</Form.Item>
							)}
							{scope === DrawingScopeType.IN_SCOPE
								&& drawingSchedule?.revisionReason ? (
									<Form.Item label={<b>Reason for revision</b>}>
										{toTitleCase(drawingSchedule.revisionReason)}
									</Form.Item>
								) : (
									[]
								)}
							{scope === DrawingScopeType.IN_SCOPE ? (
								<Form.Item label={<b>Drawings/Files</b>}>
									{drawingSchedule?.drawings?.length
										? drawingSchedule.drawings
											.filter(drawing => !drawing.inactive)
											.map((drawing: Drawing, ix: number) => (
												<DrawingBlock
													key={ix}
													drawing={drawing}
													canRemove={canWrite}
													onRemove={id => {
														removeDrawingFiles(id);
													}}
												/>
											))
										: []}
									{canWrite ? (
										<Space direction={'vertical'} style={{width: '100%'}}>
											{showUpload ? (
												<Space direction={'vertical'} style={{width: '100%'}}>
													{isRevision ? (
														<Select
															value={revisionReason ?? undefined}
															style={{width: '100%'}}
															placeholder='Select a Reason for uploading revision'
															onChange={value => {
																setRevisionReason(value);
															}}
														>
															<Select.Option value=''>
																Select a reason for revision
															</Select.Option>
															<Select.Option value='Changes done from the client side and billed to the client'>
																Changes done from the client side and billed to
																the client
															</Select.Option>
															<Select.Option value='Changes done from the client side but not billed to the client'>
																Changes done from the client side but not billed
																to the client
															</Select.Option>
															<Select.Option value='Changes in our internal guidelines'>
																Changes in our internal guidelines
															</Select.Option>
															<Select.Option value='Internal error in the earlier drawing'>
																Internal error in the earlier drawing
															</Select.Option>
														</Select>
													) : (
														[]
													)}
													{drawings.map((drawing, ix) => (
														<Card
															key={ix}
															size={'small'}
															actions={[
																<Button
																	key={ix}
																	type={'dashed'}
																	danger={true}
																	onClick={() => {
																		onDrawingRemove(ix);
																	}}
																>
																	Remove
																</Button>,
															]}
														>
															<DrawingForm
																drawing={drawing}
																onDrawingChange={(value: Drawing) => {
																	onDrawingChange(ix, value);
																}
																}
															/>
														</Card>
													))}
													<Button
														type='dashed'
														onClick={() => {
															onDrawingAdd();
														}}
														block
														icon={<PlusOutlined />}
													>
														Add
													</Button>
												</Space>
											) : (
												[]
											)}
											{isRevision ? (
												<Space direction={'vertical'} style={{width: '100%'}}>
													{showUpload ? (
														<Button type='primary' onClick={handleReviseUpload}>
															Upload Revised
														</Button>
													) : (
														<Button
															type='primary'
															onClick={onUploadReviseDrawingClick}
														>
															Upload{' '}
															{getOrdinalNumberWords(
																drawingSchedule?.revisionCount
																	? (drawingSchedule?.revisionCount ?? 0) + 1
																	: 1,
															)}{' '}
															revision
														</Button>
													)}
													<Alert
														message='Uploading Revision'
														description='Kindly ensure that you upload all drawings or files pertaining to this tab. Any previously uploaded drawings/files will be deleted.'
														type='warning'
													/>
												</Space>
											) : (
												<Space direction={'vertical'} style={{width: '100%'}}>
													{showUpload ? (
														<Button type='primary' onClick={handleUpload}>
															Upload
														</Button>
													) : (
														<Button
															type='primary'
															onClick={onUploadCompleteDrawingClick}
														>
															Upload Complete Drawings/Files
														</Button>
													)}
													<Alert
														message='Uploading Complete Drawings/Files'
														description="Kindly upload all the drawings or files that are related to this tab. It's important to note that you can't add more files after this upload. Nonetheless, you can submit a revised version with all the necessary files of this tab and a valid reason for the revision."
														type='warning'
													/>
												</Space>
											)}
										</Space>
									) : (
										[]
									)}
								</Form.Item>
							) : (
								[]
							)}
							{scope === DrawingScopeType.IN_SCOPE && drawingSchedule ? (
								<>
									{checklistCategories.beforeStartFilledChecklists.length
										|| checklistCategories.inProgressFilledChecklists.length
										|| checklistCategories.afterCompletionFilledChecklists.length ? (
											<Form.Item label={'Filled Checklists'}>
												{[
													...checklistCategories.beforeStartFilledChecklists,
													...checklistCategories.inProgressFilledChecklists,
													...checklistCategories.afterCompletionFilledChecklists,
												].map((checklist, ix) => (
													<ProcessChecklistBlock
														key={ix}
														checklist={checklist}
														checklistResponse={drawingSchedule?.checklistResponses?.find(
															res => res.processChecklistId === checklist.id,
														)}
														drawingScheduleId={drawingSchedule.id}
														siteId={drawingSchedule.siteId}
														projectProcessType={ProjectProcessType.DESIGN}
													/>
												))}
											</Form.Item>
										) : (
											[]
										)}
									{checklistCategories.beforeStartUnfilledChecklists.length
										|| checklistCategories.inProgressUnfilledChecklists.length
										|| checklistCategories.afterCompletionUnfilledChecklists
											.length ? (
											<Form.Item
												label={
													<>
														{'Checklist to be Filled Before'}
														<br />
														{' You Change the Status'}
													</>
												}
											>
												{[
													...checklistCategories.beforeStartUnfilledChecklists,
													...checklistCategories.inProgressUnfilledChecklists,
													...checklistCategories.afterCompletionUnfilledChecklists,
												].map((checklist, ix) => (
													<ProcessChecklistBlock
														key={ix}
														checklist={checklist}
														checklistResponse={drawingSchedule?.checklistResponses?.find(
															res => res.processChecklistId === checklist.id,
														)}
														drawingScheduleId={drawingSchedule.id}
														siteId={drawingSchedule.siteId}
														projectProcessType={ProjectProcessType.DESIGN}
													/>
												))}
											</Form.Item>
										) : (
											[]
										)}
								</>
							) : (
								[]
							)}
							<Form.Item label={<b>Notes</b>}>
								{drawingSchedule?.projectProcessMaster?.description ?? ''}
							</Form.Item>
							{canViewProcessDoc ? (
								<Form.Item label={<b>Reference Drawing Link</b>}>
									{drawingSchedule?.projectProcessMaster?.referenceDocuments ? (
										<ViewParseLink
											parseLink={
												drawingSchedule?.projectProcessMaster.referenceDocuments
											}
										/>
									) : (
										[]
									)}
								</Form.Item>
							) : (
								[]
							)}
							{canViewProcessDoc ? (
								<Form.Item label={<b>Process Document Link</b>}>
									{drawingSchedule?.projectProcessMaster?.processDocument ? (
										<ViewParseLink
											parseLink={
												drawingSchedule?.projectProcessMaster.processDocument
											}
										/>
									) : (
										[]
									)}
								</Form.Item>
							) : (
								[]
							)}
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
