import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Empty,
	message,
	Popconfirm,
	Row,
	Space,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
	removeAll,
} from './actions';
import {Link} from 'react-router-dom';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';
import {formatDateFunction, parseNum} from 'app/helpers';
import {type Site} from 'app/models';
import {get as getSites} from '../Site/actions';
import {type WarrantyPeriod} from './warranty-period';

export const ViewAllWarrantyPeriod: React.FC = () => {
	const dispatch = useDispatch();
	const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

	const {
		loading,
		byIds,
		allIds,
		filterValue,
		dataUpdated,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
	} = useSelector((state: Types.RootState) => state.warrantyPeriod);

	const {byIds: siteByIds, allIds: allSiteIds} = useSelector((state: Types.RootState) => state.site);

	React.useEffect(() => {
		dispatch(getSites({where: {isLive: true}}));
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleRemoveAll = () => {
		const ids = selectedRowKeys.map(key => parseNum(key));
		if (ids?.length) {
			dispatch(removeAll({id: {inq: ids}}));
		}
	};

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.name) {
			filter.where.name = f.name;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const dataSource: WarrantyPeriod[] = allIds?.length
		? allIds.map(id => ({
			...{key: String(id)},
			...byIds[id],
		}))
		: [];

	const siteOptions: FilterOptionItem[] = allSiteIds.map(id => siteByIds[id]).filter(
		(site: Site) =>
			filterValue?.siteStatus ? site.status === filterValue?.siteStatus : true,
	).map(site => ({label: site.name, value: String(site.id)}));

	const uiFilters = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter name of category',
			label: 'Category Name',
			defaultValue: filterValue?.name,
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.allWarrantyPeriods}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Warranty Period
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
					</Col>
					<Col span={12} className='mb-10'>
						{selectedRowKeys.length ? (
							<Space>
								<Popconfirm
									title={`Are you sure you want to delete ${selectedRowKeys.length} items?`}
									onConfirm={() => {
										handleRemoveAll();
									}}
								>
									<Button type={'primary'}>Remove All</Button>
								</Popconfirm>
							</Space>
						) : (
							[]
						)}
					</Col>
				</Row>

				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Link
							to={{
								pathname: `/${uiPaths.addWarrantyPeriod}`,
							}}
						>
							<Button type='primary'>Add</Button>
						</Link>
						<br />
						<br />
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
								rowSelection={{
									selectedRowKeys,
									onChange: onSelectChange,
								}}
							>
								<Table.Column
									title='Site'
									dataIndex='site'
									key='siteName'
									render={(site: Site | undefined) => site?.name ?? ''}
								/>
								<Table.Column
									title='Category Name'
									dataIndex='name'
									key='name'
								/>

								<Table.Column
									title='Warranty Start Date'
									dataIndex='startDate'
									key='startDate'
									render={(startDate: string) =>
										formatDateFunction(startDate, false)
									}
								/>
								<Table.Column
									title='Warranty Period'
									dataIndex='periodInYears'
									key='periodInYears'
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
