import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Avatar,
	Button,
	Card,
	Col,
	Empty,
	Form,
	Input,
	message,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Badge,
} from 'antd';
import {IssuesCloseOutlined, PaperClipOutlined, UserOutlined} from '@ant-design/icons';
import type Types from 'MyTypes';
import {
	type Attachment,
	CommentParentType,
	QueryType,
	QueryStatus,
	VisibilityType,
	type UserPermission,
	UserType,
	ModuleName,
} from 'app/models';
import {log} from 'app/services/logger-service';
import {
	checkFileErr,
	equalNum,
	formatDateFunction,
	getS3Url,
	parseNum,
	toTitleCase,
} from 'app/helpers';
import moment from 'moment';
import {queryEscalateAfterDays, uiPaths} from 'app/constants';
import {
	close,
	escalate,
	getById,
	reassign,
	reOpen,
} from './actions';
import {get as getComments, save as saveComment} from 'app/components/Common/comment-actions';
import {get as getUserPermissions} from 'app/components/UserPermission/actions';
import {useHistory, useParams} from 'react-router';
import {CommentBlock} from 'app/components/Common/CommentBlock';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {ViewPettyCashSummary} from 'app/components/ExpenseReport/ViewPettyCashSummary';
import {ViewWorkOrderSummary} from 'app/components/WorkOrder/ViewWorkOrderSummary';
import {ViewWorkOrderBillSummary} from 'app/components/WorkOrderBill/ViewWorkOrderBillSummary';
import {
	ViewProjectProcessScheduleSummary,
} from 'app/components/ProjectProcessSchedule/ViewProjectProcessScheduleSummary';
import {type UploadFile} from 'app/models/upload-file';
import {FileUpload} from 'app/components/Common/FileUpload';
import {CommentComponent} from '../Common/CommentComponent';

type ParamType = {
	queryId?: string;
};

// eslint-disable-next-line complexity
export const ViewQueryDetail: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<ParamType>();
	const queryId: number = parseNum(params?.queryId ?? '0');
	const before3Days: Date = new Date(new Date().setDate(new Date().getDate() - queryEscalateAfterDays));

	const {loading, byIds, dataUpdated} = useSelector((state: Types.RootState) => state.query);
	const {
		allSites: sites,
		allIds: userPermissionAllIds,
		byIds: userPermissionByIds,
	} = useSelector((state: Types.RootState) => state.userPermission);
	const {
		allIds: commentAllIds,
		byIds: commentByIds,
		dataUpdated: commentDataUpdated,
	} = useSelector((state: Types.RootState) => state.comment);
	const {user: authUser, userOptions} = useSelector((state: Types.RootState) => state.summary);

	const [showEscalateModal, setShowEscalateModal] = useState<boolean>(false);
	const [showReassignModal, setShowReassignModal] = useState<boolean>(false);
	const [showCloseModal, setShowCloseModal] = useState<boolean>(false);
	const [showReOpenModal, setShowReOpenModal] = useState<boolean>(false);
	const [reassignTo, setReassignTo] = useState<number>(0);
	const [escalatePoint, setEscalatePoint] = useState<string>('');
	const [reassignReason, setReassignReason] = useState<string>('');
	const [reOpenReason, setReOpenReason] = useState<string>('');
	const [closeReason, setCloseReason] = useState<string>('');
	const [closeFileList, setCloseFileList] = useState<UploadFile[]>([]);
	const [reOpenFileList, setReOpenFileList] = useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = useState<Record<string, string>>({});

	React.useEffect(() => {
		log('ViewQueryDetail.componentDidMount');
		dispatch(getById(queryId));
		dispatch(getComments({
			where: {
				or: [
					{queryId},
					{parentId: queryId, parentType: CommentParentType.QUERY},
				],
			},
		}));
		dispatch(getUserPermissions({
			where: {
				siteId: {inq: sites.map(({id}) => id)}, moduleName: ModuleName.PROJECT_QUERY_ASSIGNMENT,
			},
		}));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			setShowEscalateModal(false);
			setShowReassignModal(false);
			setShowCloseModal(false);
			setShowReOpenModal(false);
			setReassignTo(0);
			setEscalatePoint('');
			setReassignReason('');
			setReOpenReason('');
			setCloseReason('');
			setCloseFileList([]);
			setReOpenFileList([]);
			setFilePrefixKeys({});
			dispatch(getById(queryId));
			dispatch(getComments({
				where: {
					or: [
						{queryId},
						{parentId: queryId, parentType: CommentParentType.QUERY},
					],
				},
			}));
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (commentDataUpdated) {
			dispatch(getComments({
				where: {
					or: [
						{queryId},
						{parentId: queryId, parentType: CommentParentType.QUERY},
					],
				},
			}));
		}
	}, [commentDataUpdated]);

	const onReassignClick = () => {
		setShowReassignModal(true);
	};

	const handleReassignQueryOk = () => {
		if (!reassignReason) {
			void message.error('Please give a reason for reassigning this query.');
			return;
		}

		setReassignReason('');
		setReassignTo(0);
		setShowReassignModal(false);
		dispatch(reassign(queryId, {reassignTo: parseNum(reassignTo), content: reassignReason}));
	};

	const handleReassignQueryCancel = () => {
		setReassignReason('');
		setReassignTo(0);
		setShowReassignModal(false);
	};

	const onCloseQueryClick = () => {
		setShowCloseModal(true);
	};

	const handleCloseQueryOk = () => {
		if (!closeReason) {
			void message.error('Please enter a comment.');
			return;
		}

		checkFileErr(closeFileList);

		const attachments = closeFileList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));

		setCloseReason('');
		setShowCloseModal(false);
		setCloseFileList([]);
		setFilePrefixKeys({});
		const data = {
			content: closeReason,
			attachments,
		};
		dispatch(close(queryId, data));
	};

	const handleCloseQueryCancel = () => {
		setCloseReason('');
		setShowCloseModal(false);
	};

	const onReOpenQueryClick = () => {
		setShowReOpenModal(true);
	};

	const handleReOpenQueryOk = () => {
		if (!reOpenReason) {
			void message.error('Please enter a comment.');
			return;
		}

		checkFileErr(reOpenFileList);

		const attachments = reOpenFileList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));

		setReOpenReason('');
		setShowReOpenModal(false);
		setReOpenFileList(reOpenFileList);
		setFilePrefixKeys(filePrefixKeys);
		const data = {
			content: reOpenReason,
			attachments,
		};
		dispatch(reOpen(queryId, data));
	};

	const handleReOpenQueryCancel = () => {
		setReOpenReason('');
		setShowReOpenModal(false);
	};

	const onEscalateQueryClick = () => {
		setShowEscalateModal(true);
	};

	const handleEscalateQueryOk = (escalatedTo?: number) => {
		if (!escalatedTo) {
			return;
		}

		if (!escalatePoint) {
			void message.error('Please enter an escalate point.');
			return;
		}

		setEscalatePoint('');
		setShowEscalateModal(false);
		dispatch(escalate(queryId, {escalatedTo, content: escalatePoint}));
	};

	const handleEscalateQueryCancel = () => {
		setEscalatePoint('');
		setShowEscalateModal(false);
	};

	const query = byIds[queryId];
	const comments = commentAllIds?.map(id => commentByIds[id]);

	if (!query) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	const userType = authUser?.userType;
	const userPermissions = userPermissionAllIds.map(id => userPermissionByIds[id]);
	const assignOptions: FilterOptionItem[] = query.queryType === QueryType.PROJECT
		? userPermissions.filter(userPermission => !equalNum(userPermission.userId, authUser?.id)
			&& !equalNum(userPermission.userId, query.assignedTo)
			&& !equalNum(userPermission.userId, query.createdBy)
			&& equalNum(userPermission.siteId, query.siteId),
		).reduce((assignOptions: FilterOptionItem[], userPermission: UserPermission) => {
			const u = userPermission?.user;
			if (u?.id && u?.name && !assignOptions.find(opt => equalNum(opt.value, u?.id))) {
				assignOptions.push({label: `${u.name} (${toTitleCase(u.roles)})`, value: String(u.id)});
			}

			return assignOptions;
		}, []) : [...userOptions];

	const filteredComments = comments?.length ? comments.filter(comment =>
		comment.createdBy === parseNum(authUser?.id)
		|| !comment.visibility
		|| (comment.visibility && comment.visibility === VisibilityType.EVERYONE)
		|| (comment.visibility && comment.visibility === VisibilityType.INTERNAL
			&& userType !== UserType.CLIENT && userType !== UserType.VENDOR)
		|| (comment.visibility && comment.visibility === VisibilityType.CLIENT && userType !== UserType.VENDOR)
		|| (comment.visibility && comment.visibility === VisibilityType.VENDOR && userType !== UserType.CLIENT),
	).sort((a, b) => {
		if (a.createdAt && b.createdAt) {
			return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
		}

		return -1;
	}) : [];

	const statusColor = query.status === QueryStatus.OPEN ? 'green' : 'red';

	return (
		<DefaultLayout currentPath={uiPaths.queryDetail}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<h3 style={{textAlign: 'center'}}>{query.site?.name}</h3>
					</Col>
				</Row>
				<Badge.Ribbon style={{height: 25}} color={statusColor} text={toTitleCase(query.status, '_')}>
					<Card size='small'>
						<Row>
							<Col span={24}>
								{query.projectProcessSchedule ? (
									<ViewProjectProcessScheduleSummary projectProcessSchedule={query.projectProcessSchedule} />
								) : []}
								{query.expenseReport ? (
									<ViewPettyCashSummary expenseReport={query.expenseReport} />
								) : []}
								{query.workOrder ? (
									<ViewWorkOrderSummary workOrder={query.workOrder} />
								) : []}
								{query.workOrderBill ? (
									<ViewWorkOrderBillSummary workOrderBill={query.workOrderBill} />
								) : []}
								{query.version ? [] : (
									<CommentComponent
										author={(
											<span style={{color: '#222222', fontWeight: 'bold', fontSize: '14px'}}>
												{query.createdUser ? query.createdUser.name : 'User'}
											</span>
										)}
										avatar={(<Avatar icon={<UserOutlined />} />)}
										content={(
											<Row>
												<Col span={24}>
													<p>{query.content}</p>
												</Col>
												{query.attachments?.length ? query.attachments.map((attachment: Attachment, ix: number) => (
													<Col span={24} key={ix}>
														<PaperClipOutlined />
														{' '}
														<a href={getS3Url(attachment.key ?? '')} title={attachment.name} target='_blank'
															rel='noreferrer'>
															{attachment.name}
														</a>
													</Col>
												)) : []}
											</Row>
										)}
										datetime={(
											<span style={{color: '#444444', fontSize: '14px'}}>
												{moment(query.createdAt).fromNow()}
											</span>
										)}
									/>
								)}
							</Col>
						</Row>

						<CommentBlock
							parentId={queryId}
							parentType={CommentParentType.QUERY}
							authUser={authUser}
							comments={filteredComments}
							canComment={query.status === QueryStatus.OPEN}
							saveComment={data => dispatch(saveComment(data))}
							showVisibility={userType !== UserType.CLIENT && userType !== UserType.VENDOR}
						/>

						<Row>
							<Col span={24} className='mt-15' style={{textAlign: 'right'}}>
								<Space wrap={true}>
									{query.assignedUser?.name ? <span><b>Currently Assigned To:</b> {query.assignedUser.name ?? ''}</span> : []}
									{(equalNum(authUser?.id, query.createdBy) || equalNum(authUser?.id, query.assignedTo) || authUser?.roles === query.forWhom)
										&& userType === UserType.EMPLOYEE && query.status !== QueryStatus.CLOSED ? (
											<Button
												type='primary'
												onClick={onReassignClick}
											>
											Reassign this Query
											</Button>
										) : []}
									{(equalNum(authUser?.id, query.assignedTo) || equalNum(authUser?.id, query.createdBy)) ? (
										query.status === QueryStatus.OPEN ? (
											<Button
												type='primary'
												danger={true}
												onClick={onCloseQueryClick}
											>
												<IssuesCloseOutlined /> Mark this Query as Closed
											</Button>
										) : (
											<Button
												type='primary'
												onClick={onReOpenQueryClick}
											>
												Re-open this Query
											</Button>
										)
									) : []}
								</Space>
							</Col>
						</Row>
					</Card>
				</Badge.Ribbon>

				<Row>
					<Col span={24} className='mt-15'>
						<Card size='small' title={'Please Note'}>
							{`If you want to edit or delete the raised query or assigned it to a different person,
							mark the raised query as 'Closed' and raise a fresh query.`}
						</Card>
					</Col>
				</Row>

				{query.createdBy === authUser?.id ? (
					<Row>
						<Col span={24} className='mt-15'>
							<Card size='small' title={'Escalate this Query'}>
								Escalate to the seniors if, <br />
								{'- you don\'t get any reply or acknowledgment within 3 days of raising the query.'}<br />
								{'- you are not satisfied with the response.'}<br /><br />
								{new Date(query.assignedAt) > before3Days ? (<b>Query can not be escalated now.</b>)
									: (query.escalateToUser?.id ? (
										<Button type={'primary'} size={'small'} onClick={onEscalateQueryClick}>
											Escalate this Query to {query.escalateToUser?.name} ({toTitleCase(query.escalateToUser?.roles)})
										</Button>
									) : (
										<b>Query cannot be escalated any further.</b>
									))}
								{' '}
								{query.isEscalated ? (
									<span>
										Query last escalated to {query.assignedUser?.name} ({toTitleCase(query.assignedUser?.roles)})
										at {formatDateFunction(query.assignedAt, true)}.
									</span>
								) : []}
								<br /><br />
								{query.isEscalated ? (
									<p>
										<b>Please note that a query can be escalated again after {queryEscalateAfterDays} days of last escalation to more senior team member.</b>
									</p>
								) : []}
							</Card>
						</Col>
					</Row>
				) : []}

				<Modal
					title='Reassign this query'
					open={showReassignModal}
					onOk={handleReassignQueryOk}
					onCancel={handleReassignQueryCancel}
				>
					<Form layout='vertical'>
						<Form.Item label='For Whom to Assign'>
							<Select
								showSearch={true}
								style={{width: '100%'}}
								placeholder='Select For Whom to Assign'
								optionFilterProp='children'
								onChange={value => {
									setReassignTo(value as number);
								}}
								// C filterOption={(input, option) => String(option?.label).includes(input)}
							>
								{assignOptions.map((option: FilterOptionItem, ix: number) =>
									<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
								)}
							</Select>
						</Form.Item>
						<Form.Item label='Please give a reason for reassigning'>
							<Input.TextArea
								placeholder={'Enter Reason'}
								defaultValue={reassignReason ?? ''}
								value={reassignReason ?? ''}
								onChange={(e: any) => {
									setReassignReason(String(e.target.value));
								}}
							/>
						</Form.Item>
					</Form>
				</Modal>

				<Modal
					title='Close this query'
					open={showCloseModal}
					onOk={handleCloseQueryOk}
					onCancel={handleCloseQueryCancel}
				>
					<Form layout='vertical'>
						<Form.Item label='Please enter a comment.'>
							<Input.TextArea
								placeholder={'Enter comment'}
								defaultValue={closeReason ?? ''}
								value={closeReason ?? ''}
								onChange={(e: any) => {
									setCloseReason(String(e.target.value));
								}}
							/>
						</Form.Item>
						<Form.Item label='Upload attachments'>
							<FileUpload
								label={'Click to Upload'}
								prefix={'comment'}
								fileList={closeFileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={((closeFileList, filePrefixKeys) => {
									setCloseFileList(closeFileList);
									setFilePrefixKeys(filePrefixKeys);
								})}
							/>
						</Form.Item>
					</Form>
				</Modal>

				<Modal
					title='Re-open this query'
					open={showReOpenModal}
					onOk={handleReOpenQueryOk}
					onCancel={handleReOpenQueryCancel}
				>
					<Form layout='vertical'>
						<Form.Item label='Please enter a comment.'>
							<Input.TextArea
								placeholder={'Enter comment'}
								defaultValue={reOpenReason ?? ''}
								value={reOpenReason ?? ''}
								onChange={(e: any) => {
									setReOpenReason(String(e.target.value));
								}}
							/>
						</Form.Item>
						<Form.Item label='Upload attachments'>
							<FileUpload
								label={'Click to Upload'}
								prefix={'comment'}
								fileList={reOpenFileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={((reOpenFileList, filePrefixKeys) => {
									setReOpenFileList(reOpenFileList);
									setFilePrefixKeys(filePrefixKeys);
								})}
							/>
						</Form.Item>
					</Form>
				</Modal>

				<Modal
					title='Escalate this Query'
					open={showEscalateModal}
					onOk={() => {
						handleEscalateQueryOk(query.escalateToUser?.id);
					}}
					onCancel={handleEscalateQueryCancel}
				>
					<Form layout='vertical'>
						<Form.Item>
							Query is being Escalated to {query.escalateToUser?.name} ({toTitleCase(query.escalateToUser?.roles)})
						</Form.Item>
						<Form.Item label='Please Enter an Escalation Point'>
							<Input.TextArea
								placeholder={'Enter Escalation Point'}
								defaultValue={escalatePoint ?? ''}
								value={escalatePoint ?? ''}
								onChange={(e: any) => {
									setEscalatePoint(String(e.target.value));
								}}
							/>
						</Form.Item>
					</Form>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};
