import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	initPage: '@materialOrderDailyQuantities/INIT_PAGE',
	apiFilter: '@materialOrderDailyQuantities/API_FILTER',
	getApi: '@materialOrderDailyQuantities/GET_API',
	getSingleApi: '@materialOrderDailyQuantities/GET_SINGLE_API',
	updateApi: '@materialOrderDailyQuantities/UPDATE_API',
	createApi: '@materialOrderDailyQuantities/CREATE_API',
};

const url = lbBaseUrl + '/material-order-daily-quantities';

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const setFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: value,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(url, data, getHeaders(true)),
	meta: {process: true},
});

export const getById = (id: number) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});
