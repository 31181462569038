import {type User} from './user.model';
import {type Site} from './site.model';
import {type WorkOrder} from 'app/models/work-order.model';
import {type UserRole} from 'app/models/enums/user-role.enum';
import {type Attachment} from 'app/models/attachment.model';
import {type Category} from 'app/components/Category/category';

export type MaterialQuery = {
	id: number;
	siteId: number;
	description: string;
	attachmentStr?: string;
	deliveryChallanStr?: string;
	materialPhotosStr?: string;
	status?: MaterialQueryStatus;
	orderAmount?: number;
	debitedToContractor?: boolean;
	debitApproved?: boolean;
	contractorInformed?: boolean;
	contractorInformedFiles?: string;
	contractorNotInformedReason?: string;
	debitWorkOrderId?: number;
	escalatePoint?: string;
	escalatedTo?: number;
	escalatedAt?: string;
	approvedLevel?: number;
	nextApprovedBy?: number;
	cancelReason?: string;
	createdAt?: string;
	updatedAt?: string;
	approvedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	approvedBy?: number;
	receivedOptionDescription?: string;
	checkedDeliveredQuantity?: string;
	receivedDefectiveItems?: string;
	materialLength?: string;
	materialBreadth?: string;
	materialHeight?: string;
	categoryId?: number;
	subCategoryId?: number;
	attachments?: Attachment[];
	recieveAttachments?: Attachment[];
	receivedDefectiveItemsAttachments?: Attachment[];
	deliveryChallanAttachments?: Attachment[];
	materialPhotosAttachments?: Attachment[];
	materialLengthAttachments?: Attachment[];
	materialBreadthAttachments?: Attachment[];
	materialHeightAttachments?: Attachment[];
	notifyCount?: number;
	createdUser?: User;
	approvedUser?: User;
	nextApprovedUser?: User;
	escalatedUser?: User;
	site: Site;
	debitWorkOrder?: WorkOrder;
	approver?: UserRole;
	category?: Category;
	subCategory?: Category;
	debitNow?: boolean;
};

export enum MaterialQueryStatus {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	ORDERED = 'ORDERED',
	CANCELED = 'CANCELED',
	BUY_LOCALLY = 'BUY_LOCALLY',
	RECEIVED_A = 'RECEIVED_A',
	RECEIVED_B = 'RECEIVED_B',
	RECEIVED_C = 'RECEIVED_C',
	RECEIVED_D = 'RECEIVED_D',
}

export const materialQueryStatusLabels: Record<string, string> = {
	[MaterialQueryStatus.PENDING]: 'Pending for approval',
	[MaterialQueryStatus.APPROVED]: 'Approved',
	[MaterialQueryStatus.ORDERED]: 'Ordered',
	[MaterialQueryStatus.CANCELED]: 'Cancelled',
	[MaterialQueryStatus.BUY_LOCALLY]: 'Buy locally',
	[MaterialQueryStatus.RECEIVED_A]:
    'Material Received is less than the material ordered',
	[MaterialQueryStatus.RECEIVED_B]:
    'Material Received is same as the material ordered',
	[MaterialQueryStatus.RECEIVED_C]:
    'Material Received is more than the material ordered',
	[MaterialQueryStatus.RECEIVED_D]:
    'Some material is received less than the ordered and some material more than the ordered',
};
