
import React from 'react';
import {Spin, Col, Empty, Row, Table, Tag, Space} from 'antd';
import {QueryStatus, type Query, type User} from 'app/models';
import {useDispatch, useSelector} from 'react-redux';
import {count, get, onPaginationChange, setFilterValue} from './actions';
import {getQueryDateRange} from 'app/query_helpers';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {formatDateFunction, toSubstring, toTitleCase} from 'app/helpers';
import {useHistory} from 'react-router';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {type WorkOrderQueryStatus, workOrderQueryStatusLabel} from './work-order-query.model';

export const ViewRaisedWorkOrderQueries: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		byIds,
		allIds,
		loading,
		totalCount,
		currentPos,
		perPageSize,
		filterValue,
	} = useSelector((state: Types.RootState) => state.workOrderQuery);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);

	const today = new Date();

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, [filterValue]);

	const statusOptions: FilterOptionItem[] = Object.keys(workOrderQueryStatusLabel).map((status: string) => ({
		label: workOrderQueryStatusLabel[status],
		value: status,
	}));

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {
				createdBy: authUser?.id,
			},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
		};

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt');
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const queries
    = allIds?.length ? allIds.map((id: number) => byIds[id]) : [];

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: filterValue?.status,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
	];

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
						filterData(f);
					}} />
					<br />
				</Col>

				{totalCount ? (
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={currentPos ?? 1}
							perPageSize={perPageSize ?? 10}
							filterValue={filterValue}
							filterData={filterData}
							onPaginationChange={onPaginationChange}
						/>
						<Table
							size={'small'}
							pagination={false}
							dataSource={queries}
							scroll={{x: 1200, y: 500}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.workOrderQueryDetail.replace(
											':id',
											String(query.id),
										)}`,
									});
								},
							})}
						>
							<Table.Column
								title='Point Raised'
								width={400}
								dataIndex='content'
								key='content'
								render={(content: string) => toSubstring(content, 80, '...')}
							/>
							<Table.Column
								title='Raised At'
								width={100}
								dataIndex='createdAt'
								key='createdAt'
								render={(createdAt: string) => formatDateFunction(createdAt)}
							/>
							<Table.Column
								title='Requirement Date'
								dataIndex='targetDate'
								key='targetDate'
								render={(targetDate: string | undefined) => {
									if (!targetDate) {
										return '';
									}

									return (
										<Tag color={new Date(targetDate) < today ? 'red' : ''}>
											<Space>
												{formatDateFunction(targetDate, false)}
											</Space>
										</Tag>
									);
								}}
							/>
							<Table.Column
								title='Raised By'
								dataIndex='createdUser'
								key='createdUser'
								render={(createdUser: User) =>
									createdUser ? createdUser.name : ''
								}
							/>
							<Table.Column
								title='Status'
								width={100}
								dataIndex='status'
								key='status'
								render={(status: WorkOrderQueryStatus) => workOrderQueryStatusLabel[status]}
							/>
						</Table>
					</Col>
				) : (
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				)}
			</Row>
		</Spin>
	);
};
