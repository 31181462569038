import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from '../constants';
import {DefaultLayout} from './Layout/DefaultLayout';
import {Button, Col, Form, Input, message, Row, Spin, Typography} from 'antd';
import {initPage, updatePassword} from './Common/auth-actions';
import {useHistory} from 'react-router';
import type Types from 'MyTypes';

export const ChangePasswordView: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {loading, errorMessage, passwordChanged} = useSelector((state: Types.RootState) => state.auth);
	const [oldPassword, setOldPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');

	useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
			dispatch(initPage());
		}
	}, [errorMessage]);

	useEffect(() => {
		if (passwordChanged) {
			void message.success('Password Updated Successfully');
			setOldPassword('');
			setNewPassword('');
			setConfirmPassword('');
			dispatch(initPage());
		}
	}, [passwordChanged]);

	const onSubmitClicked = () => {
		if (!oldPassword) {
			void message.error('Please Enter Current Password');
			return;
		}

		if (!newPassword) {
			void message.error('Please Enter New Password');
			return;
		}

		if (!confirmPassword) {
			void message.error('Please Confirm New Password');
			return;
		}

		if (confirmPassword !== newPassword) {
			void message.error('Password Not Matched');
			return;
		}

		const data = {
			password: oldPassword,
			newPassword,
		};

		dispatch(updatePassword(data));
	};

	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<DefaultLayout currentPath={uiPaths.changePassword}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Change Password</Typography.Title>
						<Button onClick={e => {
							history.goBack();
						}}>Back</Button>
						<Form
							{...layout}
							name='basic'
						>
							<Form.Item label='Current Password'>
								<Input.Password
									type={'password'}
									defaultValue={oldPassword}
									value={oldPassword}
									onChange={(e: any) => {
										setOldPassword(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label='New Password'>
								<Input.Password
									type={'password'}
									defaultValue={newPassword}
									value={newPassword}
									onChange={(e: any) => {
										setNewPassword(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label='Confirm New Password'>
								<Input.Password
									type={'password'}
									defaultValue={confirmPassword}
									value={confirmPassword}
									onChange={(e: any) => {
										setConfirmPassword(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
				<br />
				<br />
			</Spin>
		</DefaultLayout>
	);
};
