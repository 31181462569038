import React from 'react';
import {Select} from 'antd';
import {type ProcessChecklist} from 'app/models/process-checklist.model';

type Pops = {
	checklists: ProcessChecklist[];
	defaultChecklist?: ProcessChecklist;
	onChecklistChange: (checklist?: ProcessChecklist) => void;
};

export const ProcessChecklistSelect: React.FC<Pops> = ({checklists, defaultChecklist, onChecklistChange}) => {
	const onChecklistSelectChange = (value: string) => {
		const checklist = checklists.find(c => c.id === value);
		onChecklistChange(checklist);
	};

	return (
		<Select
			showSearch={true}
			style={{width: '100%'}}
			placeholder='Select a Checklist'
			defaultValue={defaultChecklist?.title}
			optionFilterProp='children'
			onChange={(value: any) => {
				onChecklistSelectChange(String(value));
			}}
			// C filterOption={(input, option) => String(option?.label).includes(input)}
		>
			{checklists.map((checklist: ProcessChecklist, ix: number) =>
				<Select.Option key={ix} value={checklist.id}>{checklist.title}</Select.Option>,
			)}
		</Select>
	);
};

