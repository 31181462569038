import React from 'react';
import {parseNum, toTitleCase} from 'app/helpers';
import {ExpenseType, ModuleName, ModulePermission, extraModuleWisePermissions, type UserPermission} from 'app/models';
import {Button, Col, Form, Row, Select} from 'antd';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {svrProjectFileTypes} from '../Site/project-file';

type Props = {
	ix: number;
	userPermission: UserPermission;
	userOptions: FilterOptionItem[];
	onDataChange: (data: UserPermission) => void;
	onDataRemove: (ix: number) => void;
};

export const UserPermissionFormBlock: React.FC<Props> = ({ix, userPermission, userOptions, onDataChange, onDataRemove}) => {
	const conditionOptions = Object.values(ExpenseType).map(condition => ({
		value: condition,
		label: toTitleCase(condition) ?? '',
	}));

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};

	const extraPermissions = extraModuleWisePermissions[userPermission.moduleName] ?? [];

	return (
		<Row>
			<Col span={24}>
				<Form
					{...layout}
					layout='inline'
					name='basic'
				>
					<Form.Item label='Permissions*'>
						<Select
							style={{width: 200}}
							placeholder='Please select'
							defaultValue={userPermission?.permission ?? undefined}
							onChange={value => {
								onDataChange({...userPermission, ...{permission: String(value)}} as UserPermission);
							}}
						>
							<Select.Option key={ModulePermission.READ} value={ModulePermission.READ}>View</Select.Option>
							<Select.Option key={ModulePermission.WRITE} value={ModulePermission.WRITE}>Edit</Select.Option>
							<Select.Option key={ModulePermission.APPROVE} value={ModulePermission.APPROVE}>Approve</Select.Option>
							{extraPermissions.map((permission: string, ix: number) => (
								<Select.Option key={ix} value={permission}>
									{toTitleCase(permission)}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					{userPermission.permission === String(ModulePermission.APPROVE) ? (
						<Form.Item label='Level'>
							<Select
								style={{width: 60}}
								placeholder='Select a Level'
								onChange={value => {
									onDataChange({...userPermission, ...{level: parseNum(String(value))}} as UserPermission);
								}}
							>
								<Select.Option key={1} value={1}>1</Select.Option>
								<Select.Option key={2} value={2}>2</Select.Option>
								<Select.Option key={3} value={3}>3</Select.Option>
								<Select.Option key={4} value={4}>4</Select.Option>
							</Select>
						</Form.Item>
					) : []}

					{userPermission.moduleName === ModuleName.EXPENSE_REPORT && userPermission.permission === String(ModulePermission.APPROVE) ? (
						<Form.Item label='Condition'>
							<Select
								style={{width: 400}}
								placeholder='Select a Condition'
								defaultValue={userPermission?.condition ?? undefined}
								onChange={value => {
									onDataChange({...userPermission, ...{condition: String(value)}} as UserPermission);
								}}
							>
								{conditionOptions.map((option: FilterOptionItem, ix: number) =>
									<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
								)}
							</Select>
						</Form.Item>
					) : []}

					{userPermission.moduleName === ModuleName.PROJECT_DETAIL ? (
						<Form.Item label='Condition'>
							<Select
								style={{width: 400}}
								placeholder='Select a Condition'
								defaultValue={userPermission?.condition ?? undefined}
								onChange={value => {
									onDataChange({...userPermission, ...{condition: String(value)}} as UserPermission);
								}}
							>
								{svrProjectFileTypes.map((option: string, ix: number) =>
									<Select.Option key={ix} value={option}>{option}</Select.Option>,
								)}
							</Select>
						</Form.Item>
					) : []}

					<Form.Item label='User*'>
						<Select
							showSearch={true}
							style={{width: 200}}
							placeholder='Select a User'
							optionFilterProp='children'
							defaultValue={userPermission?.userId ?? undefined}
							onChange={value => {
								onDataChange({...userPermission, ...{userId: parseNum(String(value))}} as UserPermission);
							}}
							// C filterOption={(input, option) => String(option?.label).includes(input)}
						>
							{userOptions.map((option: FilterOptionItem, ix: number) =>
								<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
							)}
						</Select>
					</Form.Item>

					<Form.Item>
						<Button
							type='primary'
							danger={true}
							onClick={() => {
								onDataRemove(ix);
							}}
						>
							Remove
						</Button>
					</Form.Item>
				</Form>
			</Col>
		</Row>
	);
};
