import {loadState} from 'app/store/helpers';
import {type ActionType} from 'typesafe-actions';
import type * as actions from './actions';
import {logoutSuccessful} from '../Common/auth-actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {actionTypes} from './actions';
import {type WeeklyExpenseReport} from 'app/models';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = ActionType<typeof actions>;

export type MyState = Readonly<{
	records: Record<number, WeeklyExpenseReport>;
	recordSiteStatus: Record<string, WeeklyExpenseReport[]>;
	ids: number[];
	filterValue: FilterDataType;
	reportDate?: string;
	loading: boolean;
	dataUpdated: boolean;
}>;

const initialState: MyState = {
	records: {},
	recordSiteStatus: {},
	ids: [],
	filterValue: {},
	reportDate: undefined,
	loading: false,
	dataUpdated: false,
};

export default function weeklyExpenseReport(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('weeklyExpenseReport', initialState),
	action: MyAction,
) {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case getRequestActionType(actionTypes.getApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
				},
			};
		case getFailureActionType(actionTypes.getApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as WeeklyExpenseReport[];
			const records: Record<number, WeeklyExpenseReport> = {};
			const recordSiteStatus: Record<string, WeeklyExpenseReport[]> = {};
			const ids: number[] = [];
			let reportDate: string | undefined;
			for (const item of items) {
				if (!reportDate || new Date(reportDate) < new Date(item.createdAt)) {
					reportDate = item.createdAt;
				}

				if (item.site?.status) {
					if (!recordSiteStatus[item.site.status]) {
						recordSiteStatus[item.site.status] = [];
					}

					recordSiteStatus[item.site.status].push(item);
				}

				records[item.id] = item;
				ids.push(item.id);
			}

			return {
				...state,
				...{
					ids,
					records,
					recordSiteStatus,
					reportDate,
					dataUpdated: false,
					loading: false,
				},
			};
		}

		default:
			return state;
	}
}
