import {loadState} from '../../store/helpers';
import {logoutSuccessful} from '../Common/auth-actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from '../../store/action-type.util';
import {actionTypes} from './actions';
import {type WeeklyPendingAction} from '../../models/weekly-pending-action.model';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyState = Readonly<{
	byIds: Record<number, WeeklyPendingAction>;
	allIds: number[];
	filterValue: FilterDataType;
	reportDate?: string;
	loading: boolean;
	dataUpdated: boolean;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	filterValue: {},
	reportDate: undefined,
	loading: false,
	dataUpdated: false,
};

export default function weeklyPendingAction(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('weeklyPendingAction', initialState),
	action: any,
) {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case getRequestActionType(actionTypes.getApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					reportDate: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
					reportDate: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as WeeklyPendingAction[];
			return {
				...state,
				...{
					allIds: items.map(item => item.id),
					byIds: items.reduce(
						(record: Record<number, WeeklyPendingAction>, item) => {
							record[item.id] = item;
							return record;
						},
						{},
					),
					reportDate: items.reduce(
						(date: string | undefined, item) =>
							!date || new Date(date ?? '') < new Date(item.createdAt)
								? item.createdAt
								: date,
						undefined,
					),
					dataUpdated: false,
					loading: false,
				},
			};
		}

		default:
			return state;
	}
}
