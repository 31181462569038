import React from 'react';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from './Layout/DefaultLayout';
import {Col, Empty, Row, Spin} from 'antd';
import {useParams} from 'react-router';
import Retool from './Common/Retool';

type ParamType = {
	id?: string;
};

export const ViewRetoolComponent: React.FC = () => {
	const params = useParams<ParamType>();
	const id = params?.id ?? '';

	const [loading, setLoading] = React.useState<boolean>(true);

	return (
		<DefaultLayout currentPath={uiPaths.retool.replace(':id', id)}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						{id ? (
							<Retool
								url={`https://prithu.retool.com/embedded/public/${id}/#token=${localStorage.getItem('appToken')}`}
								data={{}}
								height={600}
								onLoad={() => {
									setLoading(false);
								}}
							/>
						) : (
							<Empty />
						)}
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
