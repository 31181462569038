
import React from 'react';
import {Spin, Col, Empty, Row, Table, Alert, Space, Tag} from 'antd';
import {ModuleName, ModulePermission, type Site, type User} from 'app/models';
import {useDispatch, useSelector} from 'react-redux';
import {count, get, onPaginationChange, setFilterValue} from './actions';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {getQueryDateRange} from 'app/query_helpers';
import {useHistory} from 'react-router';
import {uiPaths} from 'app/constants';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {formatDateFunction, getPermissionSites, parseNum, toSubstring, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';
import {WorkOrderQueryDrawingAvailability, type WorkOrderQueryStatus, workOrderQueryStatusLabel, type WorkOrderQuery, type WorkOrderQueryOrderType, workOrderQueryOrderTypeLabel} from './work-order-query.model';
import {type Category} from '../Category/category';

type Props = {
	toBeApproved?: boolean;
};

export const ViewAllWorkOrderQueries: React.FC<Props> = ({
	toBeApproved,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		byIds,
		allIds,
		loading,
		totalCount,
		currentPos,
		perPageSize,
		filterValue,
	} = useSelector((state: Types.RootState) => state.workOrderQuery);
	const {
		user: authUser,
		userOptions,
	} = useSelector((state: Types.RootState) => state.summary);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);

	const {byIds: categoryByIds, allIds: allCategoryIds} = useSelector((state: Types.RootState) => state.category);
	const categories = allCategoryIds.map(id => categoryByIds[id]).filter(category => !category.isDeactivated);

	const subCategoryOptions: FilterOptionItem[] = categories
		.filter(category => Boolean(category.parentId))
		.map(item => ({value: item.id.toString(), label: item.name}));

	const today = new Date();

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, [filterValue]);

	const sites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER_QUERY,
		ModulePermission.READ,
		allSites,
	);
	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const statusOptions: FilterOptionItem[] = Object.keys(workOrderQueryStatusLabel).map((status: string) => ({
		label: workOrderQueryStatusLabel[status],
		value: status,
	}));

	const drawingAvailabilityOptions: FilterOptionItem[] = Object.keys(WorkOrderQueryDrawingAvailability).map((key: string) => ({
		label: toTitleCase(key) ?? '',
		value: key,
	}));

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
		};

		const orQuery: any[] = [];

		if (toBeApproved) {
			filter.where.nextApprovedBy = authUser?.id;
		} else {
			orQuery.push({
				siteId: {inq: sites.map(({id}) => id)},
			});
			orQuery.push({
				createdBy: {
					inq: userOptions
						.filter(({value}: FilterOptionItem) => value)
						.map(({value}: FilterOptionItem) => parseNum(value)),
				},
			});
		}

		if (orQuery.length) {
			filter.where.and = [{or: orQuery}];
		} else {
			filter.where.and = [];
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.userId) {
			filter.where.createdBy = f.userId;
		}

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt');
		}

		if (f.targetDate1 && f.targetDate2) {
			filter.where.and = getQueryDateRange(f.targetDate1 as string, f.targetDate2 as string, 'targetDate');
		}

		if (f.leadTargetDate1 && f.leadTargetDate2) {
			filter.where.and = getQueryDateRange(f.leadTargetDate1 as string, f.leadTargetDate2 as string, 'leadTargetDate');
		}

		if (f.drawingAvailability) {
			filter.where.drawingAvailability = f.drawingAvailability;
		}

		if (f.subCategoryId) {
			filter.where.subCategoryId = f.subCategoryId;
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const queries
    = allIds?.length ? allIds.map((id: number) => byIds[id]) : [];

	const raisedByUserOptions: FilterOptionItem[] = [...userOptions];

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'userId',
			items: raisedByUserOptions,
			formType: FilterFormType.SELECT,
			formWidth: 160,
			placeholder: 'Select User',
			label: 'Raised By',
			defaultValue: filterValue?.userId,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
		{
			filterKey: 'targetDate',
			formType: FilterFormType.DATE_RANGE,
			label: 'Target Date (As per site)',
			defaultValue: [filterValue?.targetDate1 as string, filterValue?.targetDate2 as string],
		},
		{
			filterKey: 'leadTargetDate',
			formType: FilterFormType.DATE_RANGE,
			label: 'Target Date (As per lead time)',
			defaultValue: [filterValue?.leadTargetDate1 as string, filterValue?.leadTargetDate2 as string],
		},
		{
			filterKey: 'drawingAvailability',
			items: drawingAvailabilityOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select an Option',
			label: 'Drawing Availability',
			defaultValue: filterValue?.drawingAvailability,
		},
		{
			filterKey: 'subCategoryId',
			items: subCategoryOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Category',
			label: 'Category',
			defaultValue: filterValue?.subCategoryId,
		},
	];

	if (siteOptions.length > 1) {
		uiFilters.push({
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			formWidth: 240,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		});
	}

	if (!toBeApproved) {
		uiFilters.push({
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: filterValue?.status,
		});
	}

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
						filterData(f);
					}} />
					<br />
				</Col>

				{totalCount ? (
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={currentPos ?? 1}
							perPageSize={perPageSize ?? 10}
							filterValue={filterValue}
							filterData={filterData}
							onPaginationChange={onPaginationChange}
						/>
						<Table
							size={'small'}
							pagination={false}
							dataSource={queries}
							scroll={{x: 1200, y: 500}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.workOrderQueryDetail.replace(
											':id',
											String(query.id),
										)}`,
									});
								},
							})}
						>
							<Table.Column
								title='Site Name'
								width={300}
								dataIndex='site'
								key='site'
								render={(site: Site) => site?.name ?? ''}
							/>
							<Table.Column
								title='Work Order Type'
								dataIndex='workOrderType'
								key='workOrderType'
								render={(workOrderType: WorkOrderQueryOrderType) =>
									workOrderQueryOrderTypeLabel[workOrderType]
								}
							/>
							<Table.Column
								title='Category'
								dataIndex='subCategory'
								key='subCategory'
								render={(subCategory: Category | undefined) =>
									subCategory?.name ?? ''
								}
							/>
							<Table.Column
								title='Drawing Availability'
								dataIndex='drawingAvailability'
								key='drawingAvailability'
								render={(drawingAvailability: WorkOrderQueryDrawingAvailability) =>
									toTitleCase(drawingAvailability)
								}
							/>
							<Table.Column
								title='Target Date (As per lead time)'
								dataIndex='leadTargetDate'
								key='leadTargetDate'
								render={(leadTargetDate: string | undefined) => {
									if (!leadTargetDate) {
										return '';
									}

									return (
										<Tag color={new Date(leadTargetDate) < today ? 'red' : ''}>
											<Space>
												{formatDateFunction(leadTargetDate, false)}
											</Space>
										</Tag>
									);
								}}
							/>
							<Table.Column
								title='Target Date (As per site)'
								dataIndex='targetDate'
								key='targetDate'
								render={(targetDate: string | undefined) => {
									if (!targetDate) {
										return '';
									}

									return (
										<Tag color={new Date(targetDate) < today ? 'red' : ''}>
											<Space>
												{formatDateFunction(targetDate, false)}
											</Space>
										</Tag>
									);
								}}
							/>
							<Table.Column
								title='Raised At'
								width={100}
								dataIndex='createdAt'
								key='createdAt'
								render={(createdAt: string) => formatDateFunction(createdAt)}
							/>
							<Table.Column
								title='Approved At'
								width={100}
								dataIndex='approvedAt'
								key='approvedAt'
								render={(approvedAt: string) => formatDateFunction(approvedAt)}
							/>
							<Table.Column
								title='Status'
								width={100}
								dataIndex='status'
								key='status'
								render={(status: WorkOrderQueryStatus, query: WorkOrderQuery) => (
									<Space direction='vertical'>
										{workOrderQueryStatusLabel[status]}
										{query.cancelReason ? (<small>{query.cancelReason}</small>) : []}
										{query.cancelReasonType ? (<small>{toTitleCase(query.cancelReasonType)}</small>) : []}
									</Space>
								)}
							/>
						</Table>
					</Col>
				) : (
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				)}
			</Row>
		</Spin>
	);
};
