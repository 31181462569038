import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	initPage: '@materialQuery/INIT_PAGE',
	changePage: '@materialQuery/CHANGE_PAGE',
	changeAllPage: '@materialQuery/CHANGE_ALL_PAGE',
	paginationChange: '@materialQuery/PAGINATION_CHANGE',
	raisedPaginationChange: '@materialQuery/RAISE_PAGINATION_CHANGE',
	pendingForApprovalPaginationChange:
    '@materialQuery/PENDING_FOR_APPROVAL_PAGINATION_CHANGE',
	apiFilter: '@materialQuery/API_FILTER',
	getApi: '@materialQuery/GET_API',
	getCountApi: '@materialQuery/GET_COUNT_API',
	getSingleApi: '@materialQuery/GET_SINGLE_API',
	createApi: '@materialQuery/CREATE_API',
	updateApi: '@materialQuery/UPDATE_API',
	updateCommentApi: '@materialQuery/UPDATE_COMMENT_API',
	getCommentApi: '@materialQuery/GET_COMMENT_API',
};

const url = lbBaseUrl + '/material-queries';

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const changePage = (pageType: string) => ({
	type: actionTypes.changePage,
	payload: pageType,
	meta: {},
});

export const changeAllPage = (pageType: string) => ({
	type: actionTypes.changeAllPage,
	payload: pageType,
	meta: {},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const onRaisedPaginationChange = (
	raisedCurrentPosPrefix: number,
	raisedPerPageSizePrefix: number,
) => ({
	type: actionTypes.raisedPaginationChange,
	payload: {raisedCurrentPosPrefix, raisedPerPageSizePrefix},
	meta: {},
});

export const onAssignedPaginationChange = (
	pendingForApprovalCurrentPosPrefix: number,
	pendingForApprovalPerPageSizePrefix: number,
) => ({
	type: actionTypes.pendingForApprovalPaginationChange,
	payload: {
		pendingForApprovalCurrentPosPrefix,
		pendingForApprovalPerPageSizePrefix,
	},
	meta: {},
});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getById = (id: number, filter: any = {}) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(
		`${url}/${id}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const approve = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/approve`, data, getHeaders(true)),
	meta: {process: true},
});

// To approve receive changes
export const approveChanges = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/approve-change`, data, getHeaders(true)),
	meta: {process: true},
});

export const cancel = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/cancel`, data, getHeaders(true)),
	meta: {process: true},
});

// To cancel receive changes
export const cancelChanges = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/cancel-change`, data, getHeaders(true)),
	meta: {process: true},
});

export const receive = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/receive`, data, getHeaders(true)),
	meta: {process: true},
});

export const escalate = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/escalate`, data, getHeaders(true)),
	meta: {process: true},
});

export const saveComment = (queryId: number, data: any): any => ({
	type: actionTypes.updateCommentApi,
	payload: axios.post(`${url}/${queryId}/comments`, data, getHeaders(true)),
	meta: {process: true},
});

export const updateComment = (queryId: number, data: any, where: any): any => ({
	type: actionTypes.updateCommentApi,
	payload: axios.patch(
		`${url}/${queryId}/comments?where=${JSON.stringify(where)}`,
		data,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getComments = (queryId: number): any => ({
	type: actionTypes.getCommentApi,
	payload: axios.get(`${url}/${queryId}/comments`, getHeaders(true)),
	meta: {process: true},
});
