import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	type ProjectProcessMaster,
	ProjectProcessType,
} from 'app/components/ProjectProcessMaster/project-process-master';
import {Link} from 'react-router-dom';
import {
	type ProjectProcessSchedule,
} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {
	Col,
	message,
	Row,
	Spin,
	Table,
	Typography,
} from 'antd';
import {ModuleName, ModulePermission} from 'app/models';
import {idNotExist, uiPaths} from 'app/constants';
import {getPermissionSites} from 'app/helpers';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {get, setFilterValue} from '../ProjectProcessSchedule/actions';

export const ViewWarrantyDocument: React.FC = () => {
	const dispatch = useDispatch();
	const {
		allIds,
		byIds,
		loading,
		filterValue,
		dataUpdated,
		errorMessage,
	} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const sites = getPermissionSites(permissions, ModuleName.WARRANTY_DOCUMENTS, ModulePermission.READ, allSites);
	const rawItems = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const children = rawItems.filter(item => item.projectProcessMaster?.parentId);
	const parents = rawItems.filter(item => !item.projectProcessMaster?.parentId && item.projectProcessType === ProjectProcessType.WARRANTY);

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {where: {}, limit: 1000};
		filter.where.projectProcessType = ProjectProcessType.WARRANTY;
		if (f.siteId) {
			filter.where.siteId = f.siteId;
		} else {
			filter.where.siteId = idNotExist;
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	React.useEffect(() => {
		console.log('ViewWarrantyDocument component');
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully.');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
	];

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const dataSource: ProjectProcessSchedule[] = [];
	parents.forEach(parent => {
		dataSource.push(parent);
		children.filter(child => parent.projectProcessMaster && child.projectProcessMaster?.parentId === parent.projectProcessMaster.id)
			.forEach(entry => dataSource.push(entry));
	});

	return (
		<DefaultLayout currentPath={uiPaths.warrantyDocuments}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
						Warranty Documents
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
							filterData(f);
						}} />
						<br />
					</Col>
				</Row>
				{filterValue.siteId ? (
					<Table
						size={'small'}
						bordered={true}
						dataSource={dataSource}
						pagination={false}
					>
						<Table.Column
							title='Work Activity'
							dataIndex='projectProcessMaster'
							key='projectProcessMaster'
							render={(
								projectProcessMaster: ProjectProcessMaster,
								schedule: ProjectProcessSchedule,
							) => (
								projectProcessMaster.parentId
									? <Link
										to={{
											pathname: `/${uiPaths.editWarrantyDocument.replace(
												':id',
												String(schedule.id),
											)}`,
										}}
									>
										{projectProcessMaster?.processName ?? 'N/A'}
									</Link>

									: <b>{projectProcessMaster?.processName ?? 'N/A'}</b>

							)}
						/>
					</Table>
				) : []}

			</Spin>
		</DefaultLayout>
	);
};
