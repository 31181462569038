import {loadState} from 'app/store/helpers';
import {type ActionType} from 'typesafe-actions';
import {type PaidExpense} from 'app/models';
import type * as actions from './actions';
import {logoutSuccessful} from '../Common/auth-actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {actionTypes} from './actions';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = ActionType<typeof actions>;

export type MyState = Readonly<{
	records: Record<string, PaidExpense>;
	ids: string[];
	filterValue: FilterDataType;
	reportDate?: string;
	loading: boolean;
	dataUpdated: boolean;
}>;

const initialState: MyState = {
	records: {},
	ids: [],
	filterValue: {},
	reportDate: undefined,
	loading: false,
	dataUpdated: false,
};

export default function paidExpense(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('paidExpense', initialState),
	action: MyAction,
) {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as PaidExpense[];
			const records: Record<string, PaidExpense> = {};
			const ids: string[] = [];
			let reportDate: string | undefined;
			for (const item of items) {
				if (!reportDate || new Date(reportDate) < new Date(item.expenseTo)) {
					reportDate = item.expenseTo;
				}

				records[item.id] = item;
				ids.push(item.id);
			}

			return {
				...state,
				...{
					ids,
					records,
					reportDate,
					dataUpdated: false,
					loading: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					dataUpdated: true,
					loading: false,
				},
			};
		default:
			return state;
	}
}
