
import {Button, Card, Input, Space} from 'antd';
import React, {useState} from 'react';

type Props = {
	canEdit: boolean;
	currentValue?: string;
	onSubmit: (value: string) => void;
};

export const EditInputTextForm: React.FC<Props> = ({canEdit, currentValue, onSubmit}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [value, setValue] = useState(currentValue ?? '');

	const handleSubmit = () => {
		onSubmit(value);
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Card>
				<Space>
					<Input
						width={300}
						value={value}
						defaultValue={value}
						onChange={(e: any) => {
							setValue(String(e.target.value));
						}}
					/>
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Space>
			</Card>
		) : (
			<Space>
				<label>{currentValue}</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
