import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';
import {type ActivityTemplateItem} from './activity-template-item';

export const actionTypes = {
	initPage: '@expenseReport/INIT_PAGE',
	apiFilter: '@activityTemplateItem/API_FILTER',
	getApi: '@activityTemplateItem/GET_API',
	getSingleApi: '@activityTemplateItem/GET_SINGLE_API',
	createApi: '@activityTemplateItem/CREATE_API',
	updateApi: '@activityTemplateItem/UPDATE_API',
	paginationChange: '@activityTemplateItem/PAGINATION_CHANGE',
	getCountApi: '@activityTemplateItem/GET_COUNT_API',
};

const url = lbBaseUrl + '/activity-template-items';

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getById = (id: number) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const remove = (id: number) => ({
	type: actionTypes.updateApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const search = async (type: string, query: string, templateId: number) =>
	axios.get<ActivityTemplateItem[]>(
		`${url}/search?type=${type}&query=${query}&templateId=${templateId}`,
		getHeaders(true),
	);
