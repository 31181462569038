import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';
import {type ProcessChecklist} from 'app/models/process-checklist.model';

export const actionTypes = {
	paginationChange: '@processChecklists/PAGINATION_CHANGE',
	apiFilter: '@processChecklists/API_FILTER',
	getCountApi: '@processChecklists/GET_COUNT_API',
	getApi: '@processChecklists/GET_API',
	createApi: '@processChecklists/CREATE_API',
	updateApi: '@processChecklists/UPDATE_API',
};

const url = lbBaseUrl + '/process-checklists';

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: string, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const remove = (id: string) => ({
	type: actionTypes.updateApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const search = async (query: string) =>
	axios.get<ProcessChecklist[]>(
		`${url}/search?query=${query}`,
		getHeaders(true),
	);
