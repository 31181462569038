import {type User} from './user.model';

export type WeeklyPendingAction = {
	id: number;
	userId: number;
	type: PendingActionType;
	pendingCount: number;
	pendingAmount: number;
	createdAt: string;

	user?: User;
};

export enum PendingActionType {
	EXPENSE_REPORT = 'EXPENSE_REPORT',
	WORK_ORDER_BILL = 'WORK_ORDER_BILL',
	WORK_ORDER = 'WORK_ORDER',
}
