export enum UserRole {
	TECHINICAL_HEAD = 'TECHINICAL_HEAD',
	APP_DEVELOPMENT_TEAM = 'APP_DEVELOPMENT_TEAM',
	ARCHITECT_HEAD = 'ARCHITECT_HEAD',
	AREA_PROJECT_MANAGER = 'AREA_PROJECT_MANAGER',
	AREA_PROJECT_MANAGER_AND_SITE_INCHARGE = 'AREA_PROJECT_MANAGER_AND_SITE_INCHARGE',
	ASSISTANT_ARCHITECT = 'ASSISTANT_ARCHITECT',
	MD_CEO = '_MD_N_CEO',
	COO = '_COO',
	BUSINESS_HEAD = 'BUSINESS_HEAD',
	CONSTRUCTION_HEAD = 'CONSTRUCTION_HEAD',
	CONSTRUCTION_IN_CHARGE = 'CONSTRUCTION_IN_CHARGE',
	SERVICES_HEAD = 'SERVICES_HEAD',
	CONTRACT_DEVELOPMENT_AND_RELATIONSHIP_MANAGER = 'CONTRACT_DEVELOPMENT_AND_RELATIONSHIP_MANAGER',
	EXTENDED_WARRANTY_CLIENT = 'EXTENDED_WARRANTY_CLIENT',
	ELECTRICAL_EXPERT = 'ELECTRICAL_EXPERT',
	FABRICATION_EXPERT = 'FABRICATION_EXPERT',
	FINISHING_EXPERT = 'FINISHING_EXPERT',
	PLUMBING_EXPERT = 'PLUMBING_EXPERT',
	FINANCE_ACCOUNTS_AND_ADMIN = 'FINANCE_ACCOUNTS_AND_ADMIN',
	FINANCE_ACCOUNTS_HEAD = 'FINANCE_ACCOUNTS_HEAD',
	GOVERNMENT_APPROVAL_HEAD = 'GOVERNMENT_APPROVAL_HEAD',
	GOVERNMENT_APPROVAL_IN_CHARGE = 'GOVERNMENT_APPROVAL_IN_CHARGE',
	PROCUREMENT_HEAD = 'PROCUREMENT_HEAD',
	PRINCIPAL_ARCHITECT = 'PRINCIPAL_ARCHITECT',
	PROCUREMENT_INCHARGE = 'PROCUREMENT_INCHARGE',
	PROJECT_ARCHITECT = 'PROJECT_ARCHITECT',
	SALES_AND_MARKETING = 'SALES_AND_MARKETING',
	SALES_HEAD = 'SALES_HEAD',
	SITE_INCHARGE = 'SITE_INCHARGE',
	SITE_SECURITY_GUARD = 'SITE_SECURITY_GUARD',
	TRAINING_MANAGER = 'TRAINING_MANAGER',
	DESIGN_TRAINING_MANAGER = 'DESIGN_TRAINING_MANAGER',
	WARRANTY_TEAM = 'WARRANTY_TEAM',
	CLIENT = 'CLIENT',
	VENDOR = 'VENDOR',
	CONTRACTOR = 'CONTRACTOR',
	IN_HOUSE_LABOUR = 'IN_HOUSE_LABOUR',
	_3D_VISUALIZER = '3D_VISUALIZER',
	CLIENT_RELATIONSHIP_MANAGER = 'CLIENT_RELATIONSHIP_MANAGER',
	QUANTITY_CALCULATION = 'QUANTITY_CALCULATION',
	CONTRACTOR_APPOINTMENT_BILLING_AND_TERMINATION = 'CONTRACTOR_APPOINTMENT_BILLING_AND_TERMINATION',
	ENGAGEMENT_MANAGER = 'ENGAGEMENT_MANAGER',
	NONE = '',
}
