
import {lbBaseUrl} from '../../constants';
import axios from 'axios';
import {getHeaders} from '../../services/common-service';
import {type Attachment} from 'app/models';

export const actionTypes = {
	getApi: '@aws/GET_API',
	deleteApi: '@aws/DELETE_API',
};

const url = `${lbBaseUrl}/aws-s3`;

export const getSignedUrl = (s3Key: string, operation = 'putObject') => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}/signed-url?key=${s3Key}&operation=${operation}`, getHeaders(true)),
	meta: {process: true},
});

export const deleteByKey = (s3Key: string) => ({
	type: actionTypes.deleteApi,
	payload: axios.delete(`${url}/remove-upload?key=${s3Key}`, getHeaders(true)),
	meta: {process: true},
});

export const deleteAll = (attachments?: Attachment[]) => ({
	type: actionTypes.deleteApi,
	payload: axios.delete(`${url}/remove-all-uploads?keys=${JSON.stringify(attachments?.length ? attachments.filter(({id, key}) => !id && key).map(({key}) => key ?? '') : [])}`, getHeaders(true)),
	meta: {process: true},
});
