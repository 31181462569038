import axios from 'axios';
import {loadState} from 'app/store/helpers';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type ClientQueryOverdueReport} from 'app/models';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	getApi: '@clientQueryOverdueReport/GET_API',
};

const url = `${lbBaseUrl}/client-query-overdue-reports`;

export const getClientQueryOverdueReport = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export type MyState = Readonly<{
	byIds: Record<number, ClientQueryOverdueReport>;
	allIds: number[];
	loading: boolean;
	filterValue: FilterDataType;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	loading: false,
	filterValue: {},
	errorMessage: undefined,
};

const clientQueryOverdueReportReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('clientQueryOverdueReport', initialState),
	action: any,
): MyState => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case getRequestActionType(actionTypes.getApi):
			return {
				...state,
				loading: true,
			};
		case getFailureActionType(actionTypes.getApi):
			return {
				...state,
				loading: false,
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload?.data
				? action.payload?.data as ClientQueryOverdueReport[]
				: [];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
				},
			};
		}

		default:
			return state;
	}
};

export default clientQueryOverdueReportReducer;
