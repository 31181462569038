export const expensePathType = {
	all: 'expenses',
	new: 'create',
	pending: 'pending',
	rejected: 'rejected',
	approved: 'approved',
	approvedReport: 'approved-report',
	toApprove: 'to-approve',
	weaklyPaid: 'weakly-paid',
	weaklySum: 'weakly-sum',
	inTotalSum: 'in-total-sum',
	pendingAction: 'pending-action',
	wallet: 'wallet',
};

export type ExpenseAmountType = {
	amountReceived: number;
	foodBeverageExpense: number;
	siteLoadingUnloadingExpense: number;
	siteMaterialExpense: number;
	siteStationeryExpense: number;
	conveyance: number;
	labourExpense: number;
	waterAndElectricityBill: number;
	laisioningExpense: number;
	malwaRemoval: number;
	scrapAmount: number;
	solarAndWaterAutomation: number;
	otherExpense: number;
};
