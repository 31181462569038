import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';

export const actionTypes = {
	getContractor: '@summary/GET_CONTRACTOR',
	getClient: '@summary/GET_CLIENT',
};

export const userLoaded = '@summary/USER_LOADED'; // Separate definition as this is getting used other places

const userUrl = `${lbBaseUrl}/users`;

export const loadUser = () => ({
	type: userLoaded,
	payload: axios.get(`${userUrl}/me`, getHeaders(true)),
	meta: {process: true},
});

export const getContractors = () => ({
	type: actionTypes.getContractor,
	payload: axios.get(`${userUrl}/contractors`, getHeaders(true)),
	meta: {process: true},
});

export const getClients = () => ({
	type: actionTypes.getClient,
	payload: axios.get(`${userUrl}/clients`, getHeaders(true)),
	meta: {process: true},
});

