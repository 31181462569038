import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {getCurrencyString, getFormattedDateMonth, getS3Url, getStartEndDate} from '../../helpers';
import type Types from 'MyTypes';
import {Button, Col, DatePicker, Row, Space, Spin, Table} from 'antd';
import {log} from '../../services/logger-service';
import {getQueryDateRangeForDays} from '../../query_helpers';
import {get, setFilterValue} from './actions';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {type Site, type User} from 'app/models';
import {type PendingActionType, type WeeklyPendingAction} from 'app/models/weekly-pending-action.model';
import {pdfReportFile} from 'app/constants';

type OwnProps = {
	type: PendingActionType;
	sites: Site[];
};

const mapStateToProps = ({
	weeklyPendingAction,
}: Types.RootState, ownProps: OwnProps) => ({
	...weeklyPendingAction,
	...ownProps,
});

const mapDispatchToProps = {
	get,
	setFilterValue,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

class ViewWeeklyPendingAction extends React.Component<Props> {
	componentDidMount() {
		log('WeeklyPendingAction.componentDidMount');
		this.filterData();
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			this.filterData();
		}
	}

	filterData = (date: string | undefined = undefined) => {
		if (!date) {
			date = new Date().toString();
		}

		const filter = {
			where: {
				type: this.props.type,
				and: getQueryDateRangeForDays(date, -2, 4, 'createdAt'),
			},
			limit: 500,
			skip: 0,
		};

		this.props.setFilterValue({date});

		this.props.get(filter);
	};

	onWeakChange = (weakString: string) => {
		const splitWeak = weakString.split('-');
		const year = parseInt(splitWeak[0], 10);
		const weak = parseInt(splitWeak[1], 10);
		const date = moment().day('Wednesday').year(year).week(weak).toDate().toString();
		this.filterData(date);
	};

	render() {
		const {byIds = {}, allIds = [], filterValue, reportDate, loading} = this.props;
		const [firstDay] = getStartEndDate(reportDate ?? filterValue?.date as string)
			.map((date: string) => date ? getFormattedDateMonth(date) : '');

		const inTotal = `Till ${firstDay}`;

		const dataSource: WeeklyPendingAction[] = allIds.map((id: number) => byIds[id]);

		const csvData: any[] = [
			[
				'User',
				'Employee No',
				`Pending Count ${inTotal}`,
				`Pending Amount ${inTotal}`,
			],
		];
		dataSource.forEach((record: WeeklyPendingAction) => {
			csvData.push([
				record.user ? record.user.name : '',
				record.user ? record.user.empNo : '',
				String(record.pendingCount),
				getCurrencyString(record.pendingAmount, false),
			]);
		});

		return (
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<Space>
							<DatePicker
								picker='week'
								format={'YYYY-w'}
								onChange={(date, dateString) => {
									this.onWeakChange(dateString);
								}}
							/>
							<CSVLink
								data={csvData}
								filename={`Weekly_pending_${this.props.type.toLowerCase()}.csv`}
							>
								<Button type={'primary'}>
									Download
								</Button>
							</CSVLink>
							{/* <a href={getS3Url(pdfReportFile.pendingExpenseAction)} target='_blank' rel='noreferrer'>
								<Button type={'primary'}>
									Open PDF Report
								</Button>
							</a> */}
						</Space>
						<br />
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Table
							bordered={true}
							size={'small'}
							pagination={false}
							dataSource={dataSource}
							scroll={{x: 1200, y: 500}}
						>
							<Table.Column
								title='Employee No'
								dataIndex='user'
								key='user'
								render={(user: User | undefined) => user?.empNo ?? ''}
							/>
							<Table.Column
								title='User'
								dataIndex='user'
								key='user'
								render={(user: User | undefined) => user?.name ?? ''}
							/>
							<Table.Column
								title={`Pending Count ${inTotal}`}
								dataIndex='pendingCount'
								key='pendingCount'
							/>
							<Table.Column
								title={`Pending Amount ${inTotal}`}
								dataIndex='pendingAmount'
								key='pendingAmount'
								render={(pendingAmount: number) => getCurrencyString(pendingAmount, false)}
							/>
						</Table>
					</Col>
				</Row>
			</Spin>
		);
	}
}

export default connector(ViewWeeklyPendingAction);
