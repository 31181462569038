import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Col,
	Empty,
	Row,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
} from './actions';
import type Types from 'MyTypes';
import {formatDateFunction, getPermissionSites} from 'app/helpers';
import {ModuleName, ModulePermission} from 'app/models';
import {type WarrantyPeriod} from './warranty-period';
import dayjs from 'dayjs';

export const ViewClientWarrantyPeriod: React.FC = () => {
	const dispatch = useDispatch();

	const {
		loading,
		byIds,
		allIds,
	} = useSelector((state: Types.RootState) => state.warrantyPeriod);

	const {byModule: permissions, allSites} = useSelector((state: Types.RootState) => state.userPermission);
	const sites = getPermissionSites(permissions, ModuleName.WARRANTY_PERIODS, ModulePermission.READ, allSites);

	React.useEffect(() => {
		dispatch(get({
			where: {
				siteId: sites[0].id,
			},
		}));
	}, []);

	const dataSource: WarrantyPeriod[] = allIds?.length
		? allIds.map(id => ({
			...{key: String(id)},
			...byIds[id],
		})).filter(item => Boolean(item.periodInYears))
		: [];

	return (
		<DefaultLayout currentPath={uiPaths.allWarrantyPeriods}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<br/>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Warranty Period
						</Typography.Title>
						<br /><br />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Table
							size={'small'}
							bordered={true}
							dataSource={dataSource}
							pagination={false}
						>
							<Table.Column
								title='Category Name'
								dataIndex='name'
								key='name'
							/>
							<Table.Column
								title='Warranty Start Date'
								dataIndex='startDate'
								key='startDate'
								render={(startDate: string) =>
									formatDateFunction(startDate, false)
								}
							/>
							<Table.Column
								title='Warranty Period (In Years)'
								dataIndex='periodInYears'
								key='periodInYears'
							/>
							<Table.Column
								title='Warranty End Date'
								dataIndex='startDate'
								key='endDate'
								render={(startDate: string, item: WarrantyPeriod) => {
									const endDate = dayjs(startDate).add(item.periodInYears ?? 0, 'year').toDate().toString();
									return formatDateFunction(endDate, false);
								}}
							/>
						</Table>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
