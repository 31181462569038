import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';
import {
	SiteStatus,
	ModuleName,
	ModulePermission,
	type ScheduleDelayReport,
} from 'app/models';
import {type User} from 'app/models';
import {get as getUserPermission} from '../UserPermission/actions';
import type Types from 'MyTypes';
import {get as getSites} from '../Site/actions';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {getKey, mutateTree} from 'app/services/report-service';
import {getScheduleDelayReport, setFilterValue} from './schedule-delay-report-reducer';
import {formatDateFunction, parseNum} from 'app/helpers';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {getQueryDateRangeForDays, getQueryDateRange} from 'app/query_helpers';
import {
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {type DataSourceType, createUserTree, emptyNode, type ReportColumnType, populateDataSourceTree, ViewHierarchyReport} from './ViewHierarchyReport';

export const ViewDailyScheduleDelayReport: React.FC = () => {
	const dispatch = useDispatch();
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {loading, byIds, allIds, filterValue} = useSelector(
		(state: Types.RootState) => state.scheduleDelayReport,
	);
	const today = new Date();

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, Record<number, ScheduleDelayReport>>>(
		(record, item) => {
			const date = formatDateFunction(item.reportDate, false);
			if (!record[date]) {
				record[date] = {};
			}

			record[date][item.siteId] = item;
			return record;
		},
		{},
	);

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.map(d => ({
			title: d,
			key: getKey(d),
		}));

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {
			where: {},
			order: ['reportDate DESC'],
		};

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'reportDate');
		}

		if (!f.date1 || !f.date2) {
			filter.where.and = getQueryDateRangeForDays(
				today.toString(),
				0,
				6,
				'reportDate',
			);
			f.date1 = new Date(new Date().setDate(new Date().getDate() - 6))
				.toISOString()
				.split('T')[0];
			f.date2 = new Date().toISOString().split('T')[0];
		}

		dispatch(setFilterValue(f));
		dispatch(getScheduleDelayReport(filter));
	};

	// Fetching all sites, user permissions of READ for construction schedule module,and the reports of past 5 weeks from the current date
	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewDailyScheduleDelayReport.componentDidMount');
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}

		dispatch(
			getSites({
				where: {
					status: SiteStatus.UNDER_CONSTRUCTION,
				},
			}),
		);
		dispatch(
			getUserPermission({
				where: {
					moduleName: ModuleName.CONSTRUCTION_SCHEDULE,
					permission: ModulePermission.READ,
				},
			}),
		);
	}, []);

	const createDataSourceTree = (node: DataSourceType) => {
		const nodeKeys = node.key.split('-');
		if (nodeKeys[0] === 'user') {
			const userId = parseNum(nodeKeys[1]);
			// The user is site Incharge
			const siteChildren = allSites.filter(site => site.siteInchargeId === userId).map(site => {
				const x: DataSourceType = {
					key: 'site-' + String(site.id),
					name: site?.name ?? '',
					records: Object.keys(mapOfRecords).reduce<Record<string, number>>((record, d) => {
						record[getKey(d)] = mapOfRecords[d]?.[site.id]?.delayed ?? 0;
						return record;
					}, {}),
				};
				return x;
			});

			if (siteChildren?.length) {
				if (node.children?.length) {
					node.children.push(...siteChildren);
				} else {
					node.children = siteChildren;
				}
			}

			if (node.children?.length) {
				node.children.forEach(childNode =>
					createDataSourceTree(childNode),
				);
			}
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites.map(({siteInchargeId}) => siteInchargeId ?? 0),
	);

	const userTree = roots.map(user =>
		createUserTree({...emptyNode}, user),
	);

	const initDataSource = userTree.map(userNode =>
		createDataSourceTree(userNode),
	);

	const dataSource = initDataSource.map(node =>
		populateDataSourceTree(node, true),
	);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
			disabledDateDays: 7,
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.dailyProjectScheduleDelayReport}>
			<Row>
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						onSubmit={f => {
							filterData(f);
						}}
					/>
					<br />
				</Col>
			</Row>
			<ViewHierarchyReport
				title='Daily Construction Delay Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={`This report presents by how many days
				each site is delayed. Data is captured daily at
				06:00 PM in the night. If there is an increase in the delay
				compared to the previous week, then it means additional delay has
				been added to the project in the last 7 days.`}
				reportLogic={`Based upon the delay for each site, average
				delay is calculated for Construction In Charge and APMs reporting
				under him and beyond. To read the report, you can expand the “+”
				sign in front of the Reporting Manager name to see the list of
				employees reporting to him. If you have any query related to this
				report or a suggestion to improvise the report. Please Contact
				ruchika.singh@prithu.in`}
			/>
		</DefaultLayout>
	);
};
