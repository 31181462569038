import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {get as getUserPermission} from '../UserPermission/actions';
import {get as getSites} from '../Site/actions';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
	createUserTree,
	populateDataSourceTree,
} from './ViewHierarchyReport';
import {
	SiteStatus,
	ModuleName,
	ModulePermission,
	type User,
	type ConstructionSchedulePhotoReport,
} from 'app/models';
import {formatDateFunction, parseNum} from 'app/helpers';
import {
	mutateTree,
	getKey,
} from 'app/services/report-service';
import {getConstructionSchedulePhotoReport} from './construction-schedule-photo-report-reducer';

export const ViewConstructionSchedulePhotoReport: React.FC = () => {
	const dispatch = useDispatch();
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.constructionSchedulePhotoReport,
	);
	const today = new Date();
	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		records: {},
		children: [],
	};

	// Fetching all sites under construction
	React.useEffect(() => {
		dispatch(
			getSites({
				where: {
					status: SiteStatus.UNDER_CONSTRUCTION,
				},
			}),
		);
	}, []);

	React.useEffect(() => {
		if (allSites.length > 0) {
			dispatch(
				getConstructionSchedulePhotoReport({
					where: {
						and: getQueryDateRangeForDays(
							today.toDateString(),
							0,
							7,
							'reportDate',
						),
					},
					order: ['reportDate DESC'],
				}),
			);
			dispatch(
				getUserPermission({
					where: {
						moduleName: ModuleName.CONSTRUCTION_SCHEDULE,
						permission: ModulePermission.READ,
						siteId: {inq: allSites.map(({id}) => id ?? 0)},
					},
				}),
			);
		}
	}, [allSites]);

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];

	const mapOfRecords = reports.reduce<
	Record<string, ConstructionSchedulePhotoReport[]>
	>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.slice(0, 5)
		.map(d => ({
			title: d,
			key: getKey(d),
			width: 50,
		}));

	const createDataSourceTree = (node: DataSourceType) => {
		const nodeKeys = node.key.split('-');
		if (nodeKeys[0] === 'user') {
			const userId = parseNum(nodeKeys[1]);
			// The user is site Incharge
			const siteChildren = allSites.filter(site => site.siteInchargeId === userId).map(site => {
				const dataRecords: Record<string, number> = {};
				Object.entries(mapOfRecords).forEach(([date, records]) => {
					dataRecords[getKey(date)]
              = records.find(entry => entry.siteId === site.id)?.photoCount ?? 0;
				});
				const x: DataSourceType = {
					key: 'site-' + String(site.id),
					name: site?.name ?? '',
					records: dataRecords,
				};
				return x;
			});

			if (siteChildren?.length) {
				if (node.children?.length) {
					node.children.push(...siteChildren);
				} else {
					node.children = siteChildren;
				}
			}

			if (node.children?.length) {
				node.children.forEach(childNode =>
					createDataSourceTree(childNode),
				);
			}
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites.map(({siteInchargeId}) => siteInchargeId ?? 0),
	);

	const userTree = roots.map(user =>
		createUserTree({...emptyNode}, user),
	);

	const initDataSource = userTree.map(userNode =>
		createDataSourceTree(userNode),
	);

	const dataSource = initDataSource.map(node =>
		populateDataSourceTree(node),
	);

	return (
		<DefaultLayout
			currentPath={uiPaths.constructionSchedulePhotoReport}
		>
			<ViewHierarchyReport
				title='Daily Report on number of photos added per site'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={''}
				reportLogic={''}
			/>
		</DefaultLayout>
	);
};
