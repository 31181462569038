import {Button, Form, Select, Space} from 'antd';
import React, {useState} from 'react';
import {equalNum, parseNum} from 'app/helpers';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {useDispatch, useSelector} from 'react-redux';
import {type Site} from 'app/models';
import {update} from '../actions';
import type Types from 'MyTypes';

type Props = {
	canEdit: boolean;
	siteId: number;
	siteInchargeId?: number;
	projectArchitectId?: number;
	approvalsInchargeId?: number;
	procurementInchargeId?: number;
	clientInvoicingInchargeId?: number;
	clientInterfaceDesignId?: number;
	clientInterfaceConstructionId?: number;
	clientInterfaceApprovalsId?: number;
	clientInterfaceAccountsId?: number;
	contractorAppointmentBillingAndTerminationId?: number;
	quantityCalculationId?: number;
};

// eslint-disable-next-line complexity
export const EditStakeholderForm: React.FC<Props> = ({
	canEdit,
	siteId,
	siteInchargeId,
	projectArchitectId,
	approvalsInchargeId,
	procurementInchargeId,
	clientInvoicingInchargeId,
	clientInterfaceDesignId,
	clientInterfaceConstructionId,
	clientInterfaceApprovalsId,
	clientInterfaceAccountsId,
	contractorAppointmentBillingAndTerminationId,
	quantityCalculationId,
}) => {
	const dispatch = useDispatch();
	const {userOptions} = useSelector((state: Types.RootState) => state.summary);

	const siteInchargeItem = userOptions?.length && userOptions.find(item => equalNum(item.value, siteInchargeId));
	const projectArchitectItem = userOptions?.length && userOptions.find(item => equalNum(item.value, projectArchitectId));
	const approvalsInchargeItem = userOptions?.length && userOptions.find(item => equalNum(item.value, approvalsInchargeId));
	const procurementInchargeItem = userOptions?.length && userOptions.find(item => equalNum(item.value, procurementInchargeId));
	const clientInvoicingInchargeItem = userOptions?.length && userOptions.find(item => equalNum(item.value, clientInvoicingInchargeId));
	const clientInterfaceDesignItem = userOptions?.length && userOptions.find(item => equalNum(item.value, clientInterfaceDesignId));
	const clientInterfaceConstructionItem = userOptions?.length && userOptions.find(item => equalNum(item.value, clientInterfaceConstructionId));
	const clientInterfaceApprovalsItem = userOptions?.length && userOptions.find(item => equalNum(item.value, clientInterfaceApprovalsId));
	const clientInterfaceAccountsItem = userOptions?.length && userOptions.find(item => equalNum(item.value, clientInterfaceAccountsId));
	const contractorAppointmentBillingAndTerminationItem = userOptions?.length && userOptions.find(item => equalNum(item.value, contractorAppointmentBillingAndTerminationId));

	const quantityCalculationItem
  = userOptions?.length
  && userOptions.find(item => equalNum(item.value, quantityCalculationId));

	const siteInchargeValue = siteInchargeItem ? siteInchargeItem.label : undefined;
	const projectArchitectValue = projectArchitectItem ? projectArchitectItem.label : undefined;
	const approvalsInchargeValue = approvalsInchargeItem ? approvalsInchargeItem.label : undefined;
	const procurementInchargeValue = procurementInchargeItem ? procurementInchargeItem.label : undefined;
	const clientInvoicingInchargeValue = clientInvoicingInchargeItem ? clientInvoicingInchargeItem.label : undefined;
	const clientInterfaceDesignValue = clientInterfaceDesignItem ? clientInterfaceDesignItem.label : undefined;
	const clientInterfaceConstructionValue = clientInterfaceConstructionItem ? clientInterfaceConstructionItem.label : undefined;
	const clientInterfaceApprovalsValue = clientInterfaceApprovalsItem ? clientInterfaceApprovalsItem.label : undefined;
	const clientInterfaceAccountsValue = clientInterfaceAccountsItem ? clientInterfaceAccountsItem.label : undefined;
	const contractorAppointmentBillingAndTerminationValue = contractorAppointmentBillingAndTerminationItem ? contractorAppointmentBillingAndTerminationItem.label : undefined;
	const quantityCalculationValue = quantityCalculationItem ? quantityCalculationItem.label : undefined;

	const [isEditing, setIsEditing] = useState(false);
	const [newSiteInchargeId, setNewSiteInchargeId] = useState(siteInchargeId ?? 0);
	const [newProjectArchitectId, setNewProjectArchitectId] = useState(projectArchitectId ?? 0);
	const [newApprovalsInchargeId, setNewApprovalsInchargeId] = useState(approvalsInchargeId ?? 0);
	const [newProcurementInchargeId, setNewProcurementInchargeId] = useState(procurementInchargeId ?? 0);
	const [newClientInvoicingInchargeId, setNewClientInvoicingInchargeId] = useState(clientInvoicingInchargeId ?? 0);
	const [newClientInterfaceDesignId, setNewClientInterfaceDesignId] = useState(clientInterfaceDesignId ?? 0);
	const [newClientInterfaceConstructionId, setNewClientInterfaceConstructionId] = useState(clientInterfaceConstructionId ?? 0);
	const [newClientInterfaceApprovalsId, setNewClientInterfaceApprovalsId] = useState(clientInterfaceApprovalsId ?? 0);
	const [newClientInterfaceAccountsId, setNewClientInterfaceAccountsId] = useState(clientInterfaceAccountsId ?? 0);
	const [
		newContractorAppointmentBillingAndTerminationId,
		setNewContractorAppointmentBillingAndTerminationId,
	] = useState(contractorAppointmentBillingAndTerminationId ?? 0);
	const [newQuantityCalculationId, setNewQuantityCalculationId] = useState(
		quantityCalculationId ?? 0,
	);

	const handleSubmit = () => {
		const data: Partial<Site> = {
			siteInchargeId: newSiteInchargeId,
			projectArchitectId: newProjectArchitectId,
			approvalsInchargeId: newApprovalsInchargeId,
			procurementInchargeId: newProcurementInchargeId,
			clientInvoicingInchargeId: newClientInvoicingInchargeId,
			clientInterfaceDesignId: newClientInterfaceDesignId,
			clientInterfaceConstructionId: newClientInterfaceConstructionId,
			clientInterfaceApprovalsId: newClientInterfaceApprovalsId,
			clientInterfaceAccountsId: newClientInterfaceAccountsId,
			contractorAppointmentBillingAndTerminationId:
        newContractorAppointmentBillingAndTerminationId,
			quantityCalculationId: newQuantityCalculationId,
		};
		dispatch(update(siteId, data));
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Form>
				<Form.Item label={'Site Incharge'}>
					<Select
						disabled={!isEditing}
						id={'SelectSiteIncharge'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Site Incharge'
						optionFilterProp='children'
						onChange={value => {
							setNewSiteInchargeId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Project Architect'}>
					<Select
						disabled={!isEditing}
						id={'SelectProjectArchitect'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Project Architect'
						optionFilterProp='children'
						onChange={value => {
							setNewProjectArchitectId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Approvals Incharge'}>
					<Select
						disabled={!isEditing}
						id={'SelectApprovalsIncharge'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Approvals Incharge'
						optionFilterProp='children'
						onChange={value => {
							setNewApprovalsInchargeId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Procurement Incharge'}>
					<Select
						disabled={!isEditing}
						id={'SelectProcurementIncharge'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Procurement Incharge'
						optionFilterProp='children'
						onChange={value => {
							setNewProcurementInchargeId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Client invoicing Incharge'}>
					<Select
						disabled={!isEditing}
						id={'SelectClientinvoicingIncharge'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Client invoicing Incharge'
						optionFilterProp='children'
						onChange={value => {
							setNewClientInvoicingInchargeId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Client Interface for Design'}>
					<Select
						disabled={!isEditing}
						id={'SelectClientInterfaceforDesign'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Client Interface for Design'
						optionFilterProp='children'
						onChange={value => {
							setNewClientInterfaceDesignId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Client Interface for Construction'}>
					<Select
						disabled={!isEditing}
						id={'SelectClientInterfaceforConstruction'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Client Interface for Construction'
						optionFilterProp='children'
						onChange={value => {
							setNewClientInterfaceConstructionId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Client Interface for Approvals'}>
					<Select
						disabled={!isEditing}
						id={'SelectClientInterfaceforApprovals'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Client Interface for Approvals'
						optionFilterProp='children'
						onChange={value => {
							setNewClientInterfaceApprovalsId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Client Interface for Accounts'}>
					<Select
						disabled={!isEditing}
						id={'SelectClientInterfaceforAccounts'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Client Interface for Accounts'
						optionFilterProp='children'
						onChange={value => {
							setNewClientInterfaceAccountsId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{userOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
				</Form.Item>

				<Form.Item label={'Contractor Appointment Billing and Termination'}>
					<Select
						disabled={!isEditing}
						id={'SelectContractorAppointmentBillingTermination'}
						showSearch={true}
						style={{width: 250}}
						placeholder='Select Contractor Appointment Billing and Termination'
						optionFilterProp='children'
						defaultValue={contractorAppointmentBillingAndTerminationItem}
						onChange={value => {
							setNewContractorAppointmentBillingAndTerminationId(
								parseNum(String(value)),
							);
						}}
						// C filterOption={(input, option) =>
						// 	String(option?.label).includes(input)
						// }
					>
						{userOptions.map((option: FilterOptionItem, ix: number) => (
							<Select.Option key={ix} value={option.value}>
								{option.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label={'Quality Calculation'}>
					<Select
						disabled={!isEditing}
						id={'SelectQualityCalculation'}
						showSearch={true}
						style={{width: 250}}
						defaultValue={quantityCalculationItem}
						placeholder='Select Quality Calculation'
						optionFilterProp='children'
						onChange={value => {
							setNewQuantityCalculationId(parseNum(String(value)));
						}}
						// C filterOption={(input, option) =>
						// 	String(option?.label).includes(input)
						// }
					>
						{userOptions.map((option: FilterOptionItem, ix: number) => (
							<Select.Option key={ix} value={option.value}>
								{option.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item>
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					{' '}
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Form.Item>
			</Form>
		) : (
			<Space direction='vertical'>
				<Form>
					<Form.Item label={'Site Incharge'}>{siteInchargeValue}</Form.Item>
					<Form.Item label={'Project Architect'}>{projectArchitectValue}</Form.Item>
					<Form.Item label={'Approvals Incharge'}>{approvalsInchargeValue}</Form.Item>
					<Form.Item label={'Procurement Incharge'}>{procurementInchargeValue}</Form.Item>
					<Form.Item label={'Client invoicing Incharge'}>{clientInvoicingInchargeValue}</Form.Item>
					<Form.Item label={'Client Interface for Design'}>{clientInterfaceDesignValue}</Form.Item>
					<Form.Item label={'Client Interface for Construction'}>{clientInterfaceConstructionValue}</Form.Item>
					<Form.Item label={'Client Interface for Approvals'}>{clientInterfaceApprovalsValue}</Form.Item>
					<Form.Item label={'Client Interface for Accounts'}>{clientInterfaceAccountsValue}</Form.Item>
					<Form.Item label={'Contractor Appointment Billing Termination'}>
						{contractorAppointmentBillingAndTerminationValue}
					</Form.Item>
					<Form.Item label={'Quality Calculation'}>
						{quantityCalculationValue}
					</Form.Item>
				</Form>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
