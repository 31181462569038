import React, {useState} from 'react';
import {Space, Card, Button, Form, Select, message, Popconfirm} from 'antd';
import {equalNum, parseNum} from 'app/helpers';
import {update} from '../actions';
import {useDispatch, useSelector} from 'react-redux';
import {get as getTemplates} from '../../ActivityTemplate/actions';
import type Types from 'MyTypes';
import {type FilterOptionItem} from 'app/models/ui-filter';

type Pops = {
	siteId: number;
	currentValue: number | undefined;
	canEdit: boolean;
};

export const ProjectActivityTemplateForm: React.FC<Pops> = ({currentValue, siteId, canEdit}) => {
	const dispatch = useDispatch();
	const {
		byIds: templateByIds,
		allIds: allTemplateIds,
	} = useSelector((state: Types.RootState) => state.activityTemplate);
	const [isEditing, setIsEditing] = useState(false);
	const [activityTemplateId, setActivityTemplateId] = React.useState(currentValue);
	const templateOptions: FilterOptionItem[] = allTemplateIds.map(id => ({
		label: templateByIds[id]?.name,
		value: String(templateByIds[id]?.id),
	}));

	React.useEffect(() => {
		getTemplates({});
	}, []);

	const handleSubmit = () => {
		if (!activityTemplateId) {
			void message.error('Please select a template');
			return;
		}

		dispatch(update(siteId, {activityTemplateId}));
		setIsEditing(false);
	};

	return (
		<Space direction='vertical'>
			{isEditing ? (
				<Card>
					<Form.Item>
						<Space style={{width: '100%'}}>
							<Select
								style={{width: 300}}
								showSearch={true}
								optionFilterProp='children'
								placeholder={'Please Select a Template'}
								defaultValue={currentValue ? templateOptions.find(opt => equalNum(opt.value, currentValue))?.label : ''}
								onChange={value => {
									setActivityTemplateId(parseNum(String(value)));
								}}
							>
								<Select.Option key={-1} value={''}>{'Select'}</Select.Option>,
								{templateOptions?.length && templateOptions.map((option: FilterOptionItem, ix: number) =>
									<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
								)}
							</Select>
						</Space>
					</Form.Item>
					<Popconfirm
						title={'All previous activities (if any) will be removed for this site. Are you sure you want to update this new template?'}
						onConfirm={handleSubmit}
						okText='Yes'
						cancelText='No'
					>
						<Button type='primary'>
							Save
						</Button>
					</Popconfirm>
					<Button type='primary' style={{marginLeft: '10px'}} onClick={() => {
						setIsEditing(false);
					}}>
						Cancel
					</Button>
				</Card>
			) : (
				<Space direction='vertical'>
					{currentValue ? templateOptions.find(opt => equalNum(opt.value, currentValue))?.label : ''}
					{canEdit ? (
						<Button type='primary' onClick={() => {
							setIsEditing(true);
						}}>Edit</Button>
					) : []}
				</Space>
			)}
		</Space>
	);
};
