import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Button, Col, Form, Input, message, Row, Select, Spin, Typography} from 'antd';
import {save, update} from './actions';
import {type match as Match} from 'react-router';
import {type History} from 'history';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {parseNum} from 'app/helpers';

const {Option} = Select;

type MyParams = {
	walletId?: string;
};

type OwnProps = {
	match: Match<MyParams>;
	history: History;
};

const mapStateToProps = ({
	prithuWallet: {wallets, dataUpdated, errorMessage, loading},
	summary: {userOptions},
}: Types.RootState, ownProps: OwnProps) => ({
	wallets,
	dataUpdated,
	errorMessage,
	loading,
	userOptions,
	...ownProps,
});

const mapDispatchToProps = {
	save,
	update,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

type State = {
	userId?: number;
	limit?: number;
};

class CreateWallet extends React.Component<Props, State> {
	public walletId?: number;

	constructor(props: Props) {
		super(props);
		this.walletId = parseInt(props.match?.params?.walletId ?? '0', 10);

		const {wallets} = this.props;
		const wallet = this.walletId && wallets?.length ? wallets.find(o => o.id === this.walletId) : undefined;

		this.state = {
			userId: undefined,
			limit: wallet?.limit ?? undefined,
		};
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			if (this.walletId) {
				void message.success('Wallet Updated Successfully');
			} else {
				void message.success('New Wallet Created Successfully');
			}

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}

		if (nextProps.errorMessage) {
			void message.error(nextProps.errorMessage);
		}
	}

	handleSubmit = () => {
		if (this.walletId) {
			const data = {
				limit: this.state.limit,
			};

			if (!data.limit) {
				void message.error('Please select a limit.');
				return;
			}

			this.props.update(this.walletId, data);
		} else {
			const data = {
				balance: 0,
				nextSno: 1,
				userId: this.state.userId,
				limit: this.state.limit,
			};

			if (!data.userId) {
				void message.error('Please select a user.');
				return;
			}

			if (!data.limit) {
				void message.error('Please select a limit.');
				return;
			}

			this.props.save(data);
		}
	};

	render() {
		const {loading, userOptions} = this.props;

		const layout = {
			labelCol: {span: 8},
			wrapperCol: {span: 16},
		};
		const tailLayout = {
			wrapperCol: {offset: 8, span: 16},
		};

		return (
			<DefaultLayout>
				<Spin
					size='large'
					spinning={loading}
					tip={'Loading...'}
				>
					<Row>
						<Col span={24}>
							<Typography.Title level={3} style={{textAlign: 'center'}}>Add New Wallet</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Button onClick={e => {
								this.props.history.goBack();
							}}>Back</Button>
							<br /><br />
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<Form {...layout} name='basic'>
								<Form.Item label='Limit*'>
									<Input
										addonBefore={'₹'}
										type={'number'}
										defaultValue={this.state.limit ?? ''}
										value={this.state.limit ?? ''}
										onChange={(e: any) => {
											this.setState({limit: parseNum(String(e.target.value))});
										}}
									/>
								</Form.Item>

								{!this.walletId && (
									<Form.Item label='User*'>
										<Select
											showSearch={true}
											style={{width: 250}}
											placeholder='Select a User'
											optionFilterProp='children'
											onChange={value => {
												this.setState({userId: parseInt(String(value), 10)});
											}}
											// C filterOption={(input, option) => String(option?.label).includes(input)}
										>
											{userOptions.map((option: FilterOptionItem, ix: number) =>
												<Option key={ix} value={option.value}>{option.label}</Option>,
											)}
										</Select>
									</Form.Item>
								)}

								<Form.Item {...tailLayout}>
									<Button type='primary' onClick={this.handleSubmit}>Submit</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>

					<br />
					<br />
				</Spin>
			</DefaultLayout>
		);
	}
}

export default connector(CreateWallet);
