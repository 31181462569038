import {Collapse, Form, message} from 'antd';
import React, {useEffect} from 'react';
import {moduleLabelName, type UserPermission} from 'app/models';
import {useDispatch, useSelector} from 'react-redux';
import {get, reset, createAll} from 'app/components/UserPermission/actions';
import type Types from 'MyTypes';
import {ProjectUserPermissions} from './ProjectUserPermissions';
import {ProjectUserPermissionsForm} from './ProjectUserPermissionsForm';
import {getClients, getContractors} from 'app/components/Common/summary-actions';

type Props = {
	siteId: number;
};

export const EditUserPermissionForm: React.FC<Props> = ({siteId}) => {
	const dispatch = useDispatch();

	const {userOptions, clientOptions, contractorOptions} = useSelector((state: Types.RootState) => state.summary);
	const {dataUpdated, allIds, byIds} = useSelector((state: Types.RootState) => state.userPermission);
	const userPermissions = allIds.map(id => byIds[id]);

	useEffect(() => {
		if (siteId) {
			dispatch(reset());
			dispatch(get({where: {siteId}}));
			dispatch(getClients());
			dispatch(getContractors());
		}
	}, [siteId]);

	useEffect(() => {
		if (dataUpdated && siteId) {
			dispatch(get({where: {siteId}}));
			void message.success('Permissions updated successfully');
		}
	}, [dataUpdated, siteId]);

	const getViewPermissionsUsers = moduleName => userPermissions?.filter(userPermission => userPermission.moduleName === moduleName && userPermission.permission === 'READ').length;

	const getEditPermissionsUsers = moduleName => userPermissions?.filter(userPermission => userPermission.moduleName === moduleName && userPermission.permission === 'WRITE').length;

	const getApprovePermissionsUsers = moduleName => userPermissions?.filter(userPermission => userPermission.moduleName === moduleName && userPermission.permission === 'APPROVE').length;

	const saveNewUserPermissions = userPerms => {
		dispatch(createAll(userPerms));
	};

	return (
		<Collapse>
			{Object.keys(moduleLabelName).map((module, ix) => (
				<Collapse.Panel
					header={(
						<span>
							<span style={{float: 'left', marginRight: '10px'}}>
								<label>{moduleLabelName[module]}</label>
							</span>
							<span style={{float: 'right'}}>
								<label>View: {<strong>{getViewPermissionsUsers(module)}</strong>} . Edit: {<strong>{getEditPermissionsUsers(module)}</strong>} . Approvals: {<strong>{getApprovePermissionsUsers(module)}</strong>}</label>
							</span>
						</span>
					)}
					key={ix}
				>
					<Form.Item>
						<ProjectUserPermissions
							userPermissions={userPermissions?.filter(userPermission => String(userPermission.moduleName) === module)}
							moduleName={module}
							siteId={siteId}
						/>
					</Form.Item>
					{module ? (
						<Form.Item>
							<ProjectUserPermissionsForm
								siteId={siteId}
								userOptions={[...userOptions ?? [], ...clientOptions ?? [], ...contractorOptions ?? []]}
								moduleName={module}
								onSave={(editedUserPermissions: UserPermission[]) => {
									saveNewUserPermissions(editedUserPermissions);
								}}
							/>
						</Form.Item>
					) : []}
				</Collapse.Panel>
			))}
		</Collapse>
	);
};
