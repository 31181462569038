import React from 'react';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useDispatch, useSelector} from 'react-redux';
import {parseNum, formatDateFunction} from 'app/helpers';
import type Types from 'MyTypes';
import {useParams} from 'react-router';
import {
	Col,
	Row,
	Spin,
	Typography,
	Button,
	Form,
	message,
	InputNumber,
	Empty,
} from 'antd';
import {get, initPage, create} from './actions';
import {getById as getTabById} from '../ProjectBudgetQuantities/actions';

type MyParams = {
	masterId?: string;
	siteId?: string;
};

export const ViewMaterialOrderDailyQuantityForm: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams<MyParams>();
	const masterId = parseNum(params?.masterId ?? '0');
	const siteId = parseNum(params?.siteId ?? '0');
	const {loading, byIds, allIds, dataUpdated, errorMessage}
    = useSelector((state: Types.RootState) => state.materialOrderDailyQuantity);

	const {
		byIds: tabByIds,
	} = useSelector((state: Types.RootState) => state.materialOrderBudget);

	const item = allIds
		.map(id => byIds[id])
		.find(item => item.materialOrderBudgetId === masterId);

	const master = tabByIds[masterId]
		? (tabByIds[masterId])
		: undefined;

	const [orderQuantity, setOrderQuantity] = React.useState<number | undefined>(
		undefined,
	);

	React.useEffect(() => {
		console.log('ViewProjectHandoverActivityForm.componentDidMount');
		dispatch(initPage());
		if (masterId && siteId) {
			const data = {
				siteId,
				materialOrderBudgetId: masterId,
			};
			dispatch(
				get({
					where: {
						siteId,
						materialOrderBudgetId: masterId,
						orderDate: new Date().toISOString().split('T')[0],
					},
				}),
			);
			dispatch(getTabById(masterId));
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated successfully');
			if (masterId && siteId) {
				dispatch(
					get({
						where: {
							siteId,
							materialOrderBudgetId: masterId,
							orderDate: new Date().toISOString().split('T')[0],
						},
					}),
				);
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	if (!master) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};

	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};

	const onSubmitClicked = () => {
		if (!orderQuantity) {
			void message.error('Please input work done for current date!');
			return;
		}

		if (
			// eslint-disable-next-line no-alert
			window.confirm(
				`Are u sure u want to save ${orderQuantity} as work done for ${formatDateFunction(
					new Date().toISOString().split('T')[0],
					false,
				)} ?`,
			)
		) {
			dispatch(
				create({
					siteId,
					materialOrderBudgetId: masterId,
					orderQuantity,
				}),
			);
		}
	};

	return (
		<DefaultLayout currentPath={uiPaths.materialOrderDailyQuantityForm}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3}>
              Daily tracking of works involving usage of cement,dust,sand and
              aggregate
						</Typography.Title>
					</Col>
				</Row>
				<Form {...layout} name='basic'>
					<Form.Item label={<b>Work Type*</b>}>
						<b>{master?.title}</b>
					</Form.Item>
					<Form.Item label={<b>Measuring Unit*</b>}>
						<b>{master?.measuringUnit}</b>
					</Form.Item>
					<Form.Item label={<b>Project Quantity*</b>}>
						<b>{master?.budgetQuantity}</b>
					</Form.Item>
					<Form.Item
						label={
							<b>
                Work done on
								<br />
								{formatDateFunction(
									new Date().toISOString().split('T')[0],
									false,
								)}
							</b>
						}
					>
						<b>
							{item?.orderQuantity ? (
								item.orderQuantity
							) : (
								<>
									<InputNumber
										value={orderQuantity}
										onChange={value => {
											setOrderQuantity(value ?? 0);
										}}
									/>
									<Form.Item {...tailLayout}>
										<Button type='primary' onClick={onSubmitClicked}>
                      Save
										</Button>
									</Form.Item>
								</>
							)}
						</b>
					</Form.Item>
				</Form>
			</Spin>
		</DefaultLayout>
	);
};
