import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Row,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {getById, create, update} from './actions';
import {useHistory, useParams} from 'react-router';
import {parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {type ActivityTemplate} from './activity-template';

type MyParams = {
	id?: string;
};

export const ViewActivityTemplateForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();

	const {loading, byIds, allIds, dataUpdated, errorMessage}
		= useSelector((state: Types.RootState) => state.activityTemplate);

	const id = parseNum(params?.id);
	const template = byIds[id]
		? (byIds[id])
		: undefined;

	const [name, setName] = React.useState(
		template?.name ?? '',
	);

	React.useEffect(() => {
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (id) {
				void message.success('Updated Successfully.');
				dispatch(getById(id));
			} else {
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		const data: Omit<ActivityTemplate, 'id'> = {
			name,
		};

		if (!data.name) {
			void message.error('Please enter a name!');
			return;
		}

		if (template?.id) {
			dispatch(update(template.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<DefaultLayout currentPath={uiPaths.addActivityTemplate}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Activity Template Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
						<Form {...layout} name='basic'>
							<Form.Item
								label='Name'
								rules={[{required: true, message: 'Please Enter Name'}]}
							>
								<Input
									defaultValue={name}
									value={name}
									onChange={(e: any) => {
										setName(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
