import React from 'react';
import {
	message,
	Spin,
	Row,
	Col,
	Card,
	Form,
	Select,
	Input,
	Button,
} from 'antd';
import {type QueryData, type AttachmentData} from 'app/models';
import {FileUpload} from '../Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import {useDispatch, useSelector} from 'react-redux';
import {create, initPage} from './actions';
import {checkFileErr, parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {type FilterOptionItem} from 'app/models/ui-filter';

export const ViewCorporateQueryForm: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, dataUpdated} = useSelector(
		(state: Types.RootState) => state.corporateQuery,
	);
	const {userOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const [content, setContent] = React.useState<string>('');
	const [assignedTo, setAssignedTo] = React.useState<number | undefined>(
		undefined,
	);
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<
	Record<string, string>
	>({});

	React.useEffect(() => {
		dispatch(initPage());
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Created successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	const onSubmitClicked = () => {
		if (!assignedTo) {
			void message.error('Please Select Whom to Assign this query');
			return;
		}

		if (!content) {
			void message.error('Please Enter Query Point');
			return;
		}

		checkFileErr(fileList);

		const attachments = fileList.map((file: UploadFile) => {
			const x: AttachmentData = {
				name: file.name,
				key: `${filePrefixKeys[file.uid]}/${file.name}`,
			};
			return x;
		});

		const data: QueryData = {
			content,
			assignedTo,
			attachments,
		};

		dispatch(create(data));
	};

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};

	const filterOption = (input, option) =>
		(option?.label as string ?? '').toLowerCase().includes((input as string).toLowerCase());

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row className='mb-15'>
				<Col span={24}>
					<Form {...layout} name='basic'>
						<Form.Item label='Assign this Query to*'>
							<Select
								id={'assignedTo'}
								showSearch={true}
								style={{width: '100%'}}
								placeholder='Select For Whom'
								optionFilterProp='children'
								onChange={value => {
									setAssignedTo(parseNum(String(value)));
								}}
							>
								{[...userOptions].map((option: FilterOptionItem, ix: number) => (
									<Select.Option key={ix} value={option.value}>
										{option.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label='Query Point*'>
							<Input.TextArea
								id={'content'}
								rows={3}
								placeholder={'Enter Your Query'}
								value={content ?? ''}
								onChange={(e: any) => {
									setContent(String(e.target.value));
								}}
							/>
						</Form.Item>
						<Form.Item label='Attachments (if any)'>
							<FileUpload
								prefix={'query'}
								fileList={fileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={(fileList, filePrefixKeys) => {
									setFileList(fileList);
									setFilePrefixKeys(filePrefixKeys);
								}}
							/>
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button id={'submit'} type='primary' onClick={onSubmitClicked}>
                Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>

				<Col span={24}>
					<Card size='small' title={'Important note'}>
						<ul>
							<li>
                -{' '}
								<a
									href={
										'https://docs.google.com/document/d/1OH8_tzF7D1ixRC28L45_y3N2tQSYIRgID6gjqL1gyEQ/edit?usp=sharing'
									}
									target='_blank'
									rel='noreferrer'
								>
                  Points to know about queries.
								</a>
							</li>
							<li>
								{`- If you want to edit or delete the raised query or assigned it
								to a different person, mark the raised query as 'Closed' and
								raise a fresh query.`}
							</li>
						</ul>
					</Card>
				</Col>
			</Row>
		</Spin>
	);
};
