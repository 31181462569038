import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get as getUserPermissions} from '../UserPermission/actions';
import {ModuleName, type User, UserType, type ClientQueryReport} from 'app/models';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	calculateClientQueryReportData,
	getKey,
	mutateTree,
} from 'app/services/report-service';
import {getClientQueryReport} from './client-query-report-reducer';
import {formatDateFunction} from 'app/helpers';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
} from './ViewHierarchyReport';

export const ViewInternalClientQueryReport: React.FC = () => {
	const dispatch = useDispatch();
	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
    = useSelector((state: Types.RootState) => state.userPermission);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.clientQueryReport,
	);
	const today = new Date();
	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const userList: User[] = userPermissions.reduce<User[]>((list, item) => {
		if (
			item.user
      && !list.find(u => u.id === item.user?.id)
      && item.user.userType === UserType.EMPLOYEE
		) {
			list.push(item.user);
		}

		return list;
	}, []);
	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		records: {},
		children: [],
	};

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, ClientQueryReport[]>>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	console.log({mapOfRecords});

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.slice(0, 5)
		.map(d => ({
			title: d,
			key: getKey(d),
			width: 240,
			children: [
				{title: 'Open queries', key: getKey(d, '-open'), width: 120},
				{
					title: 'Subordinate queries',
					key: getKey(d, '-subordinatesOpen'),
					width: 120,
				},
			],
		}));

	console.log({reportColumns});

	React.useEffect(() => {
		dispatch(
			getClientQueryReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						36,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(
			getUserPermissions({
				where: {
					moduleName: ModuleName.CLIENT_QUERY,
				},
			}),
		);
	}, []);

	// Creating User Record of all users
	const userRecord: Record<number, User> = {};
	userPermissions.forEach(item => {
		if (item.user?.id) {
			userRecord[item.user.id] = item.user;
		}
	});

	// Traversing the tree to assign delays to all nodes and to create sites as child nodes of site incharges(leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.name = user.name;
		node.key = String(user.id);
		node.records = calculateClientQueryReportData(user, mapOfRecords);
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		userList.map(({id}) => id ?? 0),
	);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);

	console.log({dataSource});
	return (
		<DefaultLayout currentPath={uiPaths.oragnisationalClientQueryReport}>
			<ViewHierarchyReport
				title='Client Query Report : Open Queries(From the client to Internal Users)'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={`
          This report presents how many client queries are open which
          are assigned to the individual user and his team members by the client.Data is
          captured every week on Friday at 12:00 AM in the night.If there is any increase in the
          number of client queries which are in open status as compared to the previous week,
          it means more queries are assigned to the user by the client in the last 7 days. If
          there is any decrease in the number as compared to the previous week, it means that
          client queries have been marked as closed either by the client or by the internal user
          to whom the client query was assigned.
        `}
				reportLogic={`
          Based upon the number of client queries assigned to our internal
          team by the client which are in open status are captured in this client Query Report.
          To read the report, you can expand the “+” sign in front of the Reporting Manager
          name to see the list of employees reporting to him.
          If you have any query related to this report or a suggestion to improvise the report.
          Please Contact ruchika.singh@prithu.in
        `}
			/>
		</DefaultLayout>
	);
};
