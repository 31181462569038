import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Form, Input, message, Row, Space, Spin, Typography} from 'antd';
import {SimpleLayout} from 'app/components/Layout/SimpleLayout';
import {useHistory} from 'react-router';
import {initPage, resetPassword} from './Common/auth-actions';
import type Types from 'MyTypes';

export const ResetPasswordView: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {loading, errorMessage, passwordReset} = useSelector((state: Types.RootState) => state.auth);
	const [email, setEmail] = useState<string>('');

	useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
			dispatch(initPage());
		}
	}, [errorMessage]);

	useEffect(() => {
		if (passwordReset) {
			void message.success('Password Reset Successfully. Please check your email for new password.');
			setEmail('');
			dispatch(initPage());
		}
	}, [passwordReset]);

	const onSubmitClicked = () => {
		if (!email) {
			void message.error('Please Enter Email');
			return;
		}

		dispatch(resetPassword({email}));
	};

	return (
		<SimpleLayout>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row justify={'center'}>
					<Col style={{maxWidth: '600px'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Reset Password</Typography.Title>
						<Form style={{textAlign: 'center'}}>
							<Form.Item label='Enter your email address' extra={'New password will be sent to your email id.'}>
								<Input
									onChange={(e: any) => {
										setEmail(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item>
								<Space>
									<Button type='primary' onClick={onSubmitClicked}>Submit</Button>
									<Button onClick={() => {
										history.goBack();
									}}>Exit</Button>
								</Space>
							</Form.Item>
						</Form>
					</Col>
				</Row>
				<br/>
				<br/>
			</Spin>
		</SimpleLayout>
	);
};
