export const getAuthToken = () => localStorage.getItem('appToken');

export const getHeaders = (all = false) => {
	const token = localStorage.getItem('appToken');
	const headers = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		Authorization: `Bearer ${token}`,
	};
	return {headers};
};

export const getMultipartHeaders = (all = false) => {
	const token = localStorage.getItem('appToken');
	const headers = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		Authorization: `Bearer ${token}`,
		'Content-type': 'multipart/form-data;',
	};
	return {headers};
};
