import {DefaultLayout} from '../Layout/DefaultLayout';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row, Spin, Table, Typography} from 'antd';
import type Types from 'MyTypes';
import {
	SiteStatus,
	ModuleName,
	ModulePermission,
	type ChecklistResponseReport,
	type User,
	type ElectricityConsumptionReport,
} from 'app/models';
import {uiPaths} from 'app/constants';
import {getElectricityConsumptionReport} from './electricity-consumption-report-reducer';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {get as getSites} from '../Site/actions';
import {formatDateFunction} from 'app/helpers';

export const ViewElectricityConsumptionReport: React.FC = () => {
	const dispatch = useDispatch();
	const {
		allIds,
		byIds,
		loading,
	} = useSelector((state: Types.RootState) => state.electricityConsumptionReport);
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const today = new Date();

	React.useEffect(() => {
		console.log('ViewElectricityConsumptionReport component');
		dispatch(
			getElectricityConsumptionReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						34,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(
			getSites({
				where: {
					status: {
						inq: [SiteStatus.UNDER_CONSTRUCTION, SiteStatus.RECENTLY_COMPLETED],
					},
					isLive: true,
				},
			}),
		);
	}, []);

	const items = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = items.reduce<
	Record<string, ElectricityConsumptionReport[]>
	>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	const dataSource = allSites.map(site => {
		const data: Record<string, number | string | undefined> = {};
		Object.entries(mapOfRecords).forEach(([date, reports]) => {
			const item = reports.find(report => report.siteId === site.id);
			data[date] = item?.consumption === 0 ? 'Report not available' : item?.consumption;
		});
		return {
			key: String(site.id),
			name: site.name,
			records: data,
		};
	});

	console.log(dataSource, 'I m datasource');

	return (
		<DefaultLayout currentPath={uiPaths.electricityConsumptionReport}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Electricity Consumption Report
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='mb-10'>
						<Table
							dataSource={dataSource}
							bordered={true}
							pagination={false}
							scroll={{x: 2000, y: 1000}}
						>
							<Table.Column
								title='Name'
								dataIndex='name'
								key='name'
								fixed='left'
								width={240}
							/>
							{[0, 1, 2, 3, 4].map(i => (
								<Table.Column
									title={Object.keys(mapOfRecords)[i]}
									dataIndex= 'records'
									key='records'
									render={(records: Record<string, number | string | undefined>) =>
										records[Object.keys(mapOfRecords)[i]]
									}
								/>
							))}
						</Table>
					</Col>
				</Row>

			</Spin>
		</DefaultLayout>
	);
};
