/**
 * Appends REQUEST async action type
 */

type GetActionType = (type: string) => string;
export const getRequestActionType: GetActionType = (actionType: string) => `${actionType}_PENDING`;

/**
 * Appends SUCCESS async action type
 */

export const getSuccessActionType: GetActionType = (actionType: string) => `${actionType}_FULFILLED`;

/**
 * Appends FAILURE async action type
 */

export const getFailureActionType: GetActionType = (actionType: string) => `${actionType}_REJECTED`;
