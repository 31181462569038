import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type ElectricityConsumption} from 'app/models';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<ElectricityConsumption['id'], ElectricityConsumption>;
	allIds: Array<ElectricityConsumption['id']>;
	electricityConsumptionItem: ElectricityConsumption | undefined;
	totalCount: number;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	electricityConsumptionItem: undefined,
	totalCount: 0,
	filterValue: {
		date1: new Date(new Date().setDate(new Date().getDate() - 6))
			.toISOString()
			.split('T')[0],
		date2: new Date().toISOString().split('T')[0],
	},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
const electricityConsumption = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('electricityConsumption', initialState),
	action: any,
): MyState => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getCountApi):
		case getRequestActionType(actionTypes.getSingleApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
		case getRequestActionType(actionTypes.tryGetApi):
			return {
				...state,
				loading: true,
				dataUpdated: false,
				errorMessage: undefined,
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getCountApi):
		case getFailureActionType(actionTypes.getSingleApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
		case getFailureActionType(actionTypes.tryGetApi):
			return {
				...state,
				errorMessage: action.payload?.response?.data?.error?.message as string ?? undefined,
				loading: false,
				dataUpdated: false,
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload?.data ? action.payload?.data as ElectricityConsumption[] : [];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.tryGetApi): {
			const items = action.payload?.data ? action.payload?.data as ElectricityConsumption[] : [];
			return {
				...state,
				...{
					electricityConsumptionItem: items[0],
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: action.payload.data.count as number,
					byIds: {},
					allIds: [],
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getSingleApi): {
			const newItem = action.payload.data as ElectricityConsumption;
			const newAllIds = [...state.allIds];
			if (!newAllIds.includes(newItem.id)) {
				newAllIds.push(newItem.id);
			}

			const newByIds = {...state.byIds};
			newByIds[newItem.id] = newItem;
			return {
				...state,
				...{
					byIds: newByIds,
					allIds: newAllIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default electricityConsumption;

