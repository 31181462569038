import React from 'react';
import ReactDOM from 'react-dom';
// Import 'antd/dist/antd.less';
import './index.less';
import './pure-tables.less';
import * as Sentry from '@sentry/react';
import {App} from './App';
import {Button, ConfigProvider, Result} from 'antd';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-LL824VMQEL');

Sentry.init({
	dsn: 'https://d087751140b84589ba9eb62353c8321c@o386325.ingest.sentry.io/5220448',
});

function onHomeClick() {
	window.location.href = '/';
}

ReactDOM.render(
	<React.StrictMode>
		<Sentry.ErrorBoundary
			fallback={
				<Result
					status='500'
					title='500'
					subTitle='Sorry, something went wrong.'
					extra={
						<Button type='primary' onClick={onHomeClick}>
              Back Home
						</Button>
					}
				/>
			}
		>
			<ConfigProvider
				theme={{
					token: {
					// Seed Token
						colorPrimary: '#BC982F',
						borderRadius: 10,
					},
				}}
			>
				<App />
			</ConfigProvider>
		</Sentry.ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
