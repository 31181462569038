import {type ProjectHandoverActivity, type TimeImpact} from 'app/models';
import React from 'react';
import {Button, Form, message, Modal, Input, Card, InputNumber} from 'antd';
import {FieldsLabelName, Fields} from 'app/models';
import {TimeImpactsTable} from './TimeImpactsTable';

type Props = {
	child: ProjectHandoverActivity;
	onChange: (child: ProjectHandoverActivity) => void;
};

export const ChildActivityForm: React.FC<Props> = ({child, onChange}) => {
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [editTimeImpactIndex, setEditTimeImpactIndex]
    = React.useState<number>(-1);
	const [isEdit, setIsEdit] = React.useState<boolean>(false);
	const [timeImpact, setTimeImpact] = React.useState<TimeImpact>({
		sno: '',
		title: '',
		noOfDays: 0,
		description: '',
		referenceLink: '',
	});
	// To show immediate changes in time Impacts
	const [childActivity, setChildActivity]
    = React.useState<ProjectHandoverActivity>(child);

	const onAddTimeImpactClicked = () => {
		setShowModal(true);
	};

	const handleModelSubmit = () => {
		if (!timeImpact.title) {
			void message.error('Please enter a title !!');
			return;
		}

		if (!timeImpact.noOfDays) {
			void message.error('Please input the timeImpact(in days) !!');
			return;
		}

		if (!timeImpact.referenceLink) {
			void message.error('Please enter the referenceLink !!');
			return;
		}

		const items = child.timeImpacts ? [...child.timeImpacts] : [];
		if (isEdit) {
			items[editTimeImpactIndex] = timeImpact;
			setEditTimeImpactIndex(-1);
		} else {
			items.push(timeImpact);
		}

		setShowModal(false);
		setIsEdit(false);
		setTimeImpact({
			sno: '',
			title: '',
			noOfDays: 0,
			description: '',
			referenceLink: '',
		});
		onChange({
			...child,
			...{timeImpactStr: JSON.stringify(items), timeImpacts: items},
		} as ProjectHandoverActivity);
		setChildActivity({
			...childActivity,
			...{timeImpactStr: JSON.stringify(items), timeImpacts: items},
		});
	};

	const onTimeImpactDeletion = (index: number) => {
		const items = child.timeImpacts ? [...child.timeImpacts] : [];
		items.splice(index, 1);
		onChange({
			...child,
			...{timeImpactStr: JSON.stringify(items), timeImpacts: items},
		});
		setChildActivity({
			...childActivity,
			...{timeImpactStr: JSON.stringify(items), timeImpacts: items},
		});
	};

	const onTimeImpactEdit = (index: number) => {
		setEditTimeImpactIndex(index);
		const items = child.timeImpacts ? [...child.timeImpacts] : [];
		const timeImpact = items[index];
		setTimeImpact(timeImpact);
		setIsEdit(true);
		setShowModal(true);
	};

	return (
		<>
			<Card
				title={<b>{FieldsLabelName[Fields[child.activityName]]}</b>}
				style={{marginBottom: 10}}
				actions={[
					<Button key={1} type={'dashed'} onClick={() => {
						onAddTimeImpactClicked();
					}}>
            + Add Time Impact
					</Button>,
				]}
			>
				<Form.Item name='days' label='Days'>
					{childActivity.noOfDays}
				</Form.Item>
				<Form.Item name={childActivity.id} label='Remarks'>
					<Input.TextArea
						placeholder={'Enter Remarks'}
						defaultValue={childActivity.remarks ?? ''}
						value={childActivity.remarks ?? ''}
						onChange={(e: any) => {
							onChange({
								...child,
								...{remarks: String(e.target.value)},
							} as ProjectHandoverActivity);
							setChildActivity({
								...childActivity,
								...{remarks: String(e.target.value)},
							} as ProjectHandoverActivity);
						}}
					/>
				</Form.Item>
				{childActivity.timeImpacts?.length ? (
					<TimeImpactsTable
						timeImpacts={childActivity.timeImpacts}
						onChange={(index: number) => {
							onTimeImpactDeletion(index);
						}}
						onEdit={(index: number) => {
							onTimeImpactEdit(index);
						}}
					/>
				) : (
					[]
				)}
			</Card>
			<Modal
				title='Time Impact'
				width={700}
				open={showModal}
				onOk={handleModelSubmit}
				onCancel={e => {
					setShowModal(false);
					setTimeImpact({
						sno: '',
						title: '',
						noOfDays: 0,
						description: '',
						referenceLink: '',
					});
				}}
			>
				<Form.Item label='Serial Number'>
					<Input
						value={timeImpact?.sno}
						onChange={(e: any) => {
							setTimeImpact({...timeImpact, sno: String(e.target.value)});
						}
						}
					/>
				</Form.Item>
				<Form.Item label='Activity Name'>
					<Input
						value={timeImpact?.title}
						onChange={(e: any) => {
							setTimeImpact({...timeImpact, title: String(e.target.value)});
						}
						}
					/>
				</Form.Item>
				<Form.Item label='Time Impact (in days)'>
					<InputNumber
						value={timeImpact?.noOfDays}
						onChange={value => {
							setTimeImpact({...timeImpact, noOfDays: value ?? 0});
						}
						}
					/>
				</Form.Item>
				<Form.Item label='Description (optional)'>
					<Input.TextArea
						placeholder={'Enter Description'}
						value={timeImpact?.description}
						onChange={(e: any) => {
							setTimeImpact({...timeImpact, description: String(e.target.value)});
						}
						}
					/>
				</Form.Item>
				<Form.Item label='Reference Link'>
					<Input
						value={timeImpact?.referenceLink}
						onChange={(e: any) => {
							setTimeImpact({...timeImpact, referenceLink: String(e.target.value)});
						}
						}
					/>
				</Form.Item>
			</Modal>
		</>
	);
};
