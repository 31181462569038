import {lbBaseUrl} from '../../constants';
import axios from 'axios';
import {getHeaders} from '../../services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@weeklyPendingAction/API_FILTER',
	getApi: '@weeklyPendingAction/GET_API',
};

const url = lbBaseUrl + '/weekly-pending-actions';

export const setFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: value,
	meta: {},
});

export const get = (filter: any): any => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});
