import React, {useState} from 'react';
import {Button, Col, Form, Input, message, Row, Spin} from 'antd';
import type Types from 'MyTypes';
import {create, update, get} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {type PaymentDetail} from 'app/models';

type ParamType = {
	userId?: string;
};

export const ViewPaymentDetailForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<ParamType>();
	const userId = parseInt(params?.userId ?? '0', 10);

	const {loading, byIds, allIds, dataUpdated} = useSelector((state: Types.RootState) => state.paymentDetail);

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [paymentDetails, setPaymentDetails] = useState<PaymentDetail>(userId && byIds?.[userId] ? {...byIds[userId]} : {id: 0, userId: 0, bankACNumber: '', bankACHolderName: '', bankName: '', ifscCode: '', gstNumber: ''});

	React.useEffect(() => {
		if (userId) {
			dispatch(get({where: {userId}}));
		}
	}, [userId]);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Saved successfully');
		}
	}, [dataUpdated]);

	const onSubmitClicked = () => {
		const data = {
			userId: paymentDetails.userId,
			gstNumber: paymentDetails.gstNumber,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			bankACNumber: paymentDetails.bankACNumber,
			bankName: paymentDetails.bankName,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			bankACHolderName: paymentDetails.bankACHolderName,
			ifscCode: paymentDetails.ifscCode,
		};

		if (!userId) {
			void message.error('Invalid request. No user found');
			return;
		}

		if (!data.bankACNumber) {
			void message.error('Please enter bank account number');
			return;
		}

		if (!data.bankACHolderName) {
			void message.error('Please enter bank account holder name');
			return;
		}

		if (!data.bankName) {
			void message.error('Please enter bank name');
			return;
		}

		if (!data.ifscCode) {
			void message.error('Please enter IFSC code');
			return;
		}

		data.userId = userId;

		const paymentDetail = allIds
			.map(id => byIds[id])
			.find(detail => detail.userId === userId);

		if (paymentDetail?.id) {
			dispatch(update(paymentDetail.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<DefaultLayout currentPath={uiPaths.enterPaymentDetail}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<h3 style={{textAlign: 'center'}}>Payment Detail</h3>
						<hr />
						<Button onClick={e => {
							history.goBack();
						}}>Back</Button>
						<Form
							{...layout}
							name='basic'
						>
							<Form.Item label='Bank Account Number'>
								<Input
									defaultValue={paymentDetails.bankACNumber}
									value={paymentDetails.bankACNumber}
									onChange={(e: any) => {
										// eslint-disable-next-line @typescript-eslint/naming-convention
										setPaymentDetails({...paymentDetails, bankACNumber: String(e.target.value)});
									}}
								/>
							</Form.Item>
							<Form.Item label='Bank Account Holder Name'>
								<Input
									defaultValue={paymentDetails.bankACHolderName}
									value={paymentDetails.bankACHolderName}
									onChange={(e: any) => {
										// eslint-disable-next-line @typescript-eslint/naming-convention
										setPaymentDetails({...paymentDetails, bankACHolderName: String(e.target.value)});
									}}
								/>
							</Form.Item>
							<Form.Item label='Bank Name'>
								<Input
									defaultValue={paymentDetails.bankName}
									value={paymentDetails.bankName}
									onChange={(e: any) => {
										setPaymentDetails({...paymentDetails, bankName: String(e.target.value)});
									}}
								/>
							</Form.Item>
							<Form.Item label='IFSC Code'>
								<Input
									defaultValue={paymentDetails.ifscCode}
									value={paymentDetails.ifscCode}
									onChange={(e: any) => {
										setPaymentDetails({...paymentDetails, ifscCode: String(e.target.value)});
									}}
								/>
							</Form.Item>
							<Form.Item label='GST Number'>
								<Input
									defaultValue={paymentDetails.gstNumber}
									value={paymentDetails.gstNumber}
									onChange={(e: any) => {
										setPaymentDetails({...paymentDetails, gstNumber: String(e.target.value)});
									}}
								/>
							</Form.Item>
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
