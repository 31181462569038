import React from 'react';
import {
	Col,
	Empty,
	Row,
	Spin,
	Button,
	Card,
	Badge,
	Space,
	Modal,
	Form,
	Select,
	Input,
	message,
} from 'antd';
import {
	CommentParentType,
	ModuleName,
	ModulePermission,
	QueryStatus,
	moduleCommentType,
	UserType,
	VisibilityType,
	type User,
	type UserPermission,
	type Query,
} from 'app/models';
import {IssuesCloseOutlined} from '@ant-design/icons';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {queryEscalateAfterDays} from 'app/constants';
import {CommentBlock} from '../Common/CommentBlock';
import {FileUpload} from '../Common/FileUpload';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useParams} from 'react-router';
import {
	parseNum,
	toTitleCase,
	equalNum,
	formatDateFunction,
	checkFileErr,
} from 'app/helpers';
import {type UploadFile} from 'app/models/upload-file';
import {getById, reassign, close, reOpen, escalate} from './actions';
import {get as getUserPermissions} from 'app/components/UserPermission/actions';
import {
	get as getComments,
	save as saveComment,
} from 'app/components/Common/comment-actions';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {ViewProjectProcessScheduleSummary} from '../ProjectProcessSchedule/ViewProjectProcessScheduleSummary';
import {ViewPettyCashSummary} from '../ExpenseReport/ViewPettyCashSummary';
import {ViewWorkOrderSummary} from '../WorkOrder/ViewWorkOrderSummary';
import {ViewWorkOrderBillSummary} from '../WorkOrderBill/ViewWorkOrderBillSummary';
import type Types from 'MyTypes';

type ParamType = {
	id?: string;
};

export const ViewClientQueryDetail: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams<ParamType>();
	const history = useHistory();
	const queryId = parseNum(params?.id ?? '0');
	const {loading, byIds, dataUpdated} = useSelector(
		(state: Types.RootState) => state.clientQuery,
	);
	const {
		allSites: sites,
		allIds: userPermissionAllIds,
		byIds: userPermissionByIds,
	} = useSelector((state: Types.RootState) => state.userPermission);
	const {
		allIds: commentAllIds,
		byIds: commentByIds,
		dataUpdated: commentDataUpdated,
	} = useSelector((state: Types.RootState) => state.comment);
	const userPermissions = userPermissionAllIds.map(
		id => userPermissionByIds[id],
	);
	const comments = commentAllIds
		? commentAllIds.map(id => commentByIds[id])
		: [];
	const query = byIds[queryId];
	const statusColor = query?.status === QueryStatus.OPEN ? 'green' : 'red';
	const before3Days = new Date(
		new Date().setDate(new Date().getDate() - queryEscalateAfterDays),
	);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const userType = authUser?.userType;

	const [showReassignModal, setShowReassignModal] = React.useState<boolean>();
	const [reassignReason, setReassignReason] = React.useState<
	string | undefined
	>(undefined);
	const [reassignTo, setReassignTo] = React.useState<number | undefined>(
		undefined,
	);
	const [showCloseModal, setShowCloseModal] = React.useState<boolean>();
	const [showReOpenModal, setShowReOpenModal] = React.useState<boolean>();
	const [showEscalateModal, setShowEscalateModal]
    = React.useState<boolean>(false);
	const [closeReason, setCloseReason] = React.useState<string | undefined>(
		undefined,
	);
	const [closeFileList, setCloseFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<
	Record<string, string>
	>({});
	const [reOpenReason, setReOpenReason] = React.useState<string | undefined>(
		undefined,
	);
	const [reOpenFileList, setReOpenFileList] = React.useState<UploadFile[]>([]);
	const [escalatePoint, setEscalatePoint] = React.useState<string | undefined>(
		undefined,
	);

	const filteredComments = comments?.length
		? comments
			.filter(
				comment =>
					comment.createdBy === parseNum(authUser?.id)
            || !comment.visibility
            || (comment.visibility
              && comment.visibility === VisibilityType.EVERYONE)
            || (comment.visibility
              && comment.visibility === VisibilityType.INTERNAL
              && userType !== UserType.CLIENT
              && userType !== UserType.VENDOR)
            || (comment.visibility
              && comment.visibility === VisibilityType.CLIENT
              && userType !== UserType.VENDOR)
            || (comment.visibility
              && comment.visibility === VisibilityType.VENDOR
              && userType !== UserType.CLIENT),
			)
			.sort((a, b) => {
				if (a.createdAt && b.createdAt) {
					return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
				}

				return -1;
			})
		: [];

	React.useEffect(() => {
		if (queryId) {
			dispatch(getById(queryId));
			dispatch(
				getComments({
					where: {
						parentId: queryId,
						parentType: CommentParentType.CLIENT_QUERY,
					},
				}),
			);
			dispatch(
				getUserPermissions({
					where: {
						siteId: {inq: sites.map(({id}) => id)},
						permission: ModulePermission.WRITE,
						moduleName: ModuleName.CLIENT_QUERY,
					},
				}),
			);
		}
	}, [queryId]);

	React.useEffect(() => {
		if (queryId && dataUpdated) {
			dispatch(getById(queryId));
			dispatch(
				getComments({
					where: {
						parentId: queryId,
						parentType: CommentParentType.CLIENT_QUERY,
					},
				}),
			);
		}
	}, [queryId, dataUpdated]);

	React.useEffect(() => {
		if (queryId && commentDataUpdated) {
			dispatch(
				getComments({
					where: {
						parentId: queryId,
						parentType: CommentParentType.CLIENT_QUERY,
					},
				}),
			);
		}
	}, [queryId, commentDataUpdated]);

	const assignOptions = userPermissions
		?.filter(
			userPermission =>
				!equalNum(userPermission.userId, authUser?.id)
        && equalNum(userPermission.siteId, query?.siteId),
		)
		.reduce((opts: FilterOptionItem[], p: UserPermission) => {
			let u: User | undefined;
			if (p?.user?.userType === userType) {
				u = p?.user;
			}

			if (u?.id && u?.name && !opts.find(opt => equalNum(opt.value, u?.id))) {
				opts.push({
					label: `${u.name} (${toTitleCase(u.roles)})`,
					value: String(u.id),
				});
			}

			return opts;
		}, []);
	const onSaveComment = data => dispatch(saveComment(data));

	const onReassignClick = () => {
		setShowReassignModal(true);
	};

	const onCloseQueryClick = () => {
		setShowCloseModal(true);
	};

	const onReOpenQueryClick = () => {
		setShowReOpenModal(true);
	};

	const onEscalateQueryClick = () => {
		setShowEscalateModal(true);
	};

	const handleReassignQueryOk = () => {
		if (!reassignTo) {
			void message.error('Please select a user to reassign this query to.');
			return;
		}

		if (!reassignReason) {
			void message.error('Please give a reason for reassigning this query.');
			return;
		}

		const data = {
			content: reassignReason,
			reassignTo,
		};
		dispatch(reassign(query.id, data));
		setReassignReason(undefined);
		setReassignTo(undefined);
		setShowReassignModal(false);
	};

	const handleReassignQueryCancel = () => {
		setReassignReason(undefined);
		setReassignTo(undefined);
		setShowReassignModal(false);
	};

	const handleCloseQueryOk = () => {
		if (!closeReason) {
			void message.error('Please enter a comment.');
			return;
		}

		checkFileErr(closeFileList);

		const attachments = closeFileList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));

		const data = {
			content: closeReason,
			attachments,
		};

		dispatch(close(query.id, data));
		setCloseReason(undefined);
		setShowCloseModal(false);
		setCloseFileList([]);
		setFilePrefixKeys({});
	};

	const handleCloseQueryCancel = () => {
		setCloseReason(undefined);
		setShowCloseModal(false);
	};

	const handleReOpenQueryOk = () => {
		if (!reOpenReason) {
			void message.error('Please enter a comment.');
			return;
		}

		checkFileErr(reOpenFileList);

		const attachments = reOpenFileList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));

		const data = {
			content: reOpenReason,
			attachments,
		};

		dispatch(reOpen(query.id, data));
		setReOpenReason(undefined);
		setShowReOpenModal(false);
		setReOpenFileList([]);
		setFilePrefixKeys({});
	};

	const handleReOpenQueryCancel = () => {
		setReOpenReason(undefined);
		setShowReOpenModal(false);
	};

	const handleEscalateQueryOk = (escalatedTo?: number) => {
		if (!escalatedTo) {
			return;
		}

		if (!escalatePoint) {
			void message.error('Please enter an escalate point.');
			return;
		}

		const data = {
			content: escalatePoint,
			escalatedTo,
		};

		dispatch(escalate(query.id, data));
		setEscalatePoint(undefined);
		setShowEscalateModal(false);
	};

	const handleEscalateQueryCancel = () => {
		setEscalatePoint(undefined);
		setShowEscalateModal(false);
	};

	// If query is not found, return empty component
	if (!query) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	const authIsCreatorOrAssignedUser = equalNum(authUser?.id, query.assignedTo) || equalNum(authUser?.id, query.createdBy);

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<DefaultLayout currentPath={uiPaths.clientQueryDetail}>
				<Row>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<h3 style={{textAlign: 'center'}}>{query.site?.name}</h3>
					</Col>

					<Col span={24} className='mt-15'>
						<Badge.Ribbon
							style={{height: 25}}
							color={statusColor}
							text={toTitleCase(query.status, '_')}
						>
							<Card size='small'>
								<DetailSummaryBlock query={query} />
								<CommentBlock
									parentId={query.id}
									parentType={moduleCommentType.CLIENT_QUERY}
									authUser={authUser}
									comments={filteredComments}
									canComment={query.status === QueryStatus.OPEN}
									saveComment={data => onSaveComment(data)}
									showVisibility={userType !== UserType.CLIENT && userType !== UserType.VENDOR}
								/>
								<DetailActionBlock
									query={query}
									authIsCreatorOrAssignedUser={authIsCreatorOrAssignedUser}
									userType={userType ?? UserType.EMPLOYEE}
									onReassignClick={onReassignClick}
									onCloseQueryClick={onCloseQueryClick}
									onReOpenQueryClick={onReOpenQueryClick}
								/>
							</Card>
						</Badge.Ribbon>
					</Col>
					<Col span={24} className='mt-15'>
						<Card size='small' title={'Please Note'}>
							If you want to edit or delete the raised query or assigned it to
							a different person, mark the raised query as &apos;Closed&apos; and raise
							a fresh query.
						</Card>
					</Col>
					{query.createdBy === authUser?.id ? (
						<DetailBottomBlock
							query={query}
							before3Days={before3Days}
							onEscalateQueryClick={onEscalateQueryClick}
						/>
					) : []}
					<Modal
						title='Reassign this query'
						open={showReassignModal}
						onOk={handleReassignQueryOk}
						onCancel={handleReassignQueryCancel}
					>
						<Form layout='vertical'>
							<Form.Item label='For Whom to Assign'>
								<Select
									showSearch={true}
									style={{width: '100%'}}
									placeholder='Select For Whom to Assign'
									optionFilterProp='children'
									onChange={value => {
										setReassignTo(value as number);
									}}
									// C filterOption={(input, option) => String(option?.label).includes(input)}
								>
									{assignOptions.map((option: FilterOptionItem, ix: number) => (
										<Select.Option key={ix} value={option.value}>
											{option.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label='Please give a reason for reassigning'>
								<Input.TextArea
									placeholder={'Enter Reason'}
									defaultValue={reassignReason ?? ''}
									value={reassignReason ?? ''}
									onChange={(e: any) => {
										setReassignReason(String(e.target.value));
									}}
								/>
							</Form.Item>
						</Form>
					</Modal>
					<Modal
						title='Close this query'
						open={showCloseModal}
						onOk={handleCloseQueryOk}
						onCancel={handleCloseQueryCancel}
					>
						<Form layout='vertical'>
							<Form.Item label='Please enter a comment.'>
								<Input.TextArea
									placeholder={'Enter comment'}
									defaultValue={closeReason ?? ''}
									value={closeReason ?? ''}
									onChange={(e: any) => {
										setCloseReason(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label='Upload attachments'>
								<FileUpload
									label={'Click to Upload'}
									prefix={'comment'}
									fileList={closeFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(closeFileList, filePrefixKeys) => {
										setCloseFileList(closeFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>
						</Form>
					</Modal>

					<Modal
						title='Re-open this query'
						open={showReOpenModal}
						onOk={handleReOpenQueryOk}
						onCancel={handleReOpenQueryCancel}
					>
						<Form layout='vertical'>
							<Form.Item label='Please enter a comment.'>
								<Input.TextArea
									placeholder={'Enter comment'}
									defaultValue={reOpenReason ?? ''}
									value={reOpenReason ?? ''}
									onChange={(e: any) => {
										setReOpenReason(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label='Upload attachments'>
								<FileUpload
									label={'Click to Upload'}
									prefix={'comment'}
									fileList={reOpenFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(reOpenFileList, filePrefixKeys) => {
										setReOpenFileList(reOpenFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>
						</Form>
					</Modal>
					<Modal
						title='Escalate this Query'
						open={showEscalateModal}
						onOk={() => {
							handleEscalateQueryOk(query.escalateToUser?.id);
						}}
						onCancel={handleEscalateQueryCancel}
					>
						<Form layout='vertical'>
							<Form.Item>
                  Query is being Escalated to {query.escalateToUser?.name} (
								{toTitleCase(query.escalateToUser?.roles)})
							</Form.Item>
							<Form.Item label='Please Enter an Escalation Point'>
								<Input.TextArea
									placeholder={'Enter Escalation Point'}
									defaultValue={escalatePoint ?? ''}
									value={escalatePoint ?? ''}
									onChange={(e: any) => {
										setEscalatePoint(String(e.target.value));
									}}
								/>
							</Form.Item>
						</Form>
					</Modal>
				</Row>
			</DefaultLayout>
		</Spin>
	);
};

type DetailSummaryBlockProp = {
	query: Query;
};

const DetailSummaryBlock: React.FC<DetailSummaryBlockProp> = ({query}) => (
	<Row>
		<Col span={24}>
			{query.projectProcessSchedule ? (
				<ViewProjectProcessScheduleSummary
					projectProcessSchedule={query.projectProcessSchedule}
				/>
			) : (
				[]
			)}
			{query.expenseReport ? (
				<ViewPettyCashSummary
					expenseReport={query.expenseReport}
				/>
			) : (
				[]
			)}
			{query.workOrder ? (
				<ViewWorkOrderSummary workOrder={query.workOrder} />
			) : (
				[]
			)}
			{query.workOrderBill ? (
				<ViewWorkOrderBillSummary
					workOrderBill={query.workOrderBill}
				/>
			) : (
				[]
			)}
		</Col>
	</Row>
);

type DetailActionBlockProp = {
	query: Query;
	authIsCreatorOrAssignedUser: boolean;
	userType: UserType;
	onReassignClick: () => void;
	onCloseQueryClick: () => void;
	onReOpenQueryClick: () => void;
};

const DetailActionBlock: React.FC<DetailActionBlockProp> = ({query, authIsCreatorOrAssignedUser, userType, onReassignClick, onCloseQueryClick, onReOpenQueryClick}) => (
	<Row>
		<Col
			span={24}
			className='mt-15'
			style={{textAlign: 'right'}}
		>
			<Space wrap={true}>
				{query.assignedUser?.name ? (
					<span>
						<b>Currently Assigned To:</b>{' '}
						{query.assignedUser.name ?? ''}
					</span>
				) : (
					[]
				)}
				{authIsCreatorOrAssignedUser
												&& userType === UserType.EMPLOYEE
												&& query.status !== QueryStatus.CLOSED ? (
						<Button type='primary' onClick={onReassignClick}>
                                                        Reassign this Query
						</Button>
					) : (
						[]
					)}
				{authIsCreatorOrAssignedUser && query.status === QueryStatus.OPEN ? (
					<Button
						type='primary'
						danger={true}
						onClick={onCloseQueryClick}
					>
						<IssuesCloseOutlined /> Mark this Query as Closed
					</Button>
				) : []}
				{authIsCreatorOrAssignedUser && query.status === QueryStatus.CLOSED ? (
					<Button type='primary' onClick={onReOpenQueryClick}>
                                                        Re-open this Query
					</Button>
				) : []}
			</Space>
		</Col>
	</Row>
);

type DetailBottomBlockProp = {
	query: Query;
	before3Days: Date;
	onEscalateQueryClick: () => void;
};

const DetailBottomBlock: React.FC<DetailBottomBlockProp> = ({query, before3Days, onEscalateQueryClick}) => (
	<Col span={24} className='mt-15'>
		<Card size='small' title={'Escalate this Query'}>
                  Escalate to the seniors if, <br />
                  - you don&apos;t get any reply or acknowledgment within 3 days of
                  raising the query.
			<br />
                  - you are not satisfied with the response.
			<br />
			<br />
			{new Date(query.assignedAt) > before3Days ? (
				<b>Query can not be escalated now.</b>
			) : query.escalateToUser?.id ? (
				<Button
					type={'primary'}
					size={'small'}
					onClick={onEscalateQueryClick}
				>
                      Escalate this Query to {query.escalateToUser?.name} (
					{toTitleCase(query.escalateToUser?.roles)})
				</Button>
			) : (
				<b>Query cannot be escalated any further.</b>
			)}
			{<br />}
			{query.isEscalated ? (
				<span>
                      Query last escalated to {query.assignedUser?.name} (
					{toTitleCase(query.assignedUser?.roles)}) at{' '}
					{formatDateFunction(query.assignedAt, true)}.
				</span>
			) : (
				[]
			)}
			<br />
			<br />
			{query.isEscalated ? (
				<p>
					<b>
                        Please note that a query can be escalated again after{' '}
						{queryEscalateAfterDays} days of last escalation to
                        more senior team member.
					</b>
				</p>
			) : (
				[]
			)}
		</Card>
	</Col>
);
