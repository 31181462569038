import type * as actions from './actions';
import {actionTypes} from './actions';
import {type GoogleScheduleSheet} from 'app/models';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type SheetDataType} from './sheet-data-type';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<GoogleScheduleSheet['id'], GoogleScheduleSheet>;
	allIds: Array<GoogleScheduleSheet['id']>;
	sheetData?: Record<number, Record<number, SheetDataType>>;
	sheetDates?: FilterOptionItem[];
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	sheetData: undefined,
	sheetDates: undefined,
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
const googleScheduleSheetReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('scheduleSheet', initialState),
	action: any,
) => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getSheetDataApi):
		case getRequestActionType(actionTypes.getClientSheetDataApi):
		case getRequestActionType(actionTypes.getSheetDatesApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					sheetData: undefined,
					sheetDates: undefined,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getSheetDataApi):
		case getFailureActionType(actionTypes.getClientSheetDataApi):
		case getFailureActionType(actionTypes.getSheetDatesApi):
		case getFailureActionType(actionTypes.updateSheetApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					sheetData: undefined,
					sheetDates: undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as GoogleScheduleSheet[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getSheetDataApi):
		case getSuccessActionType(actionTypes.getClientSheetDataApi):
			return {
				...state,
				...{
					sheetData: action.payload.data as Record<
					number,
					Record<string, SheetDataType>
					>,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getSheetDatesApi):
			return {
				...state,
				...{
					sheetDates: action.payload.data as FilterOptionItem[],
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.updateSheetApi): {
			const sheetItem = action.payload.data as SheetDataType;
			const newSheetData: Record<number, Record<number, SheetDataType>> = {
				...state.sheetData,
			};
			newSheetData[sheetItem.rowIndex][sheetItem.columnIndex] = sheetItem;
			return {
				...state,
				...{
					sheetData: newSheetData,
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default googleScheduleSheetReducer;
