import {Button, Card, Input, message, Select} from 'antd';
import {type Site, ProjectType, ProjectCity} from 'app/models';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {update} from '../actions';

type Props = {
	canEdit: boolean;
	siteId: number;
	projectNumber?: number;
	projectType?: ProjectType;
	projectCity?: string;
	address?: string;
};

export const EditNameForm: React.FC<Props> = ({canEdit, siteId, projectNumber, projectCity, projectType, address}) => {
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [newProjectType, setNewProjectType] = useState(projectType ?? undefined);
	const [newProjectCity, setNewProjectCity] = useState(projectCity ?? '');
	const [newAddress, setNewAddress] = useState(address ?? '');

	const handleSubmit = () => {
		if (!newProjectType || !newProjectCity || !newAddress) {
			void message.error('Please enter mandatory fields.');
			return;
		}

		const data: Partial<Site> = {
			projectType: newProjectType,
			projectCity: newProjectCity,
			address: newAddress,
		};
		dispatch(update(siteId, data));
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Card>
				<Input.Group compact>
					<Button disabled={true}>{projectNumber}</Button>
					<Button disabled={true}>/</Button>
					<Select
						disabled={!isEditing}
						style={{width: '20%'}}
						defaultValue={newProjectType ?? ''}
						value={newProjectType}
						onChange={value => {
							setNewProjectType(value as ProjectType);
						}}
					>
						<Select.Option value=''>Select Project Type</Select.Option>
						{Object.values(ProjectType).map((value, ix) => (
							<Select.Option key={ix} value={value}>{value}</Select.Option>
						))}
					</Select>
					<Button disabled={true}>/</Button>
					<Select
						disabled={!isEditing}
						style={{width: '20%'}}
						defaultValue={newProjectCity}
						value={newProjectCity}
						onChange={value => {
							setNewProjectCity(value);
						}}
					>
						<Select.Option value=''>Select Project City</Select.Option>
						{Object.values(ProjectCity).map((value, ix) => (
							<Select.Option key={ix} value={value}>{value}</Select.Option>
						))}
					</Select>
					<Button disabled={true}>/</Button>
					<Input
						style={{width: '30%'}}
						placeholder={'Enter Project Address'}
						value={newAddress}
						defaultValue={newAddress}
						required={true}
						onChange={(e: any) => {
							setNewAddress(String(e.target.value));
						}}
					/>
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Input.Group>
			</Card>
		) : (
			<Input.Group>
				<label>{projectNumber}/{projectType}/{projectCity}/{address}</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Input.Group>
		)
	);
};
