import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {FileUpload} from 'app/components/Common/FileUpload';
import {AttachmentsPreviewBlock} from 'app/components/ProjectProcessSchedule/AttachmentsPreviewBlock';
import {
	Button,
	Col,
	message,
	Row,
	Spin,
	Form,
	Typography,
} from 'antd';
import {ModuleName, ModulePermission} from 'app/models';
import {uiPaths} from 'app/constants';
import {getPermissionSites, parseNum, checkFileErr} from 'app/helpers';
import {getById, updateWarrantyDocuments, removeAttachment} from '../ProjectProcessSchedule/actions';
import {useHistory, useParams} from 'react-router';
import {type UploadFile} from 'app/models/upload-file';

type MyParams = {
	id?: string;
};

export const ViewWarrantyDocumentForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();
	const id = parseNum(params?.id ?? '0');
	const {
		byIds,
		loading,
		filterValue,
		dataUpdated,
		errorMessage,
	} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const writeSites = getPermissionSites(permissions, ModuleName.WARRANTY_DOCUMENTS, ModulePermission.WRITE, allSites);
	const schedule = id ? byIds[id] : undefined;
	const canWrite = filterValue.siteId ? Boolean(writeSites.find(s =>
		String(s.id) === filterValue.siteId,
	)) : false;
	const [warrantyDocumentsList, setWarrantyDocumentsList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});
	console.log(canWrite, filterValue.siteId);

	React.useEffect(() => {
		console.log('ViewWarrantyDocumentForm.componentDidMount');
		dispatch(getById(id));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully.');
			if (id) {
				dispatch(getById(id));
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};

	const onSubmitClicked = () => {
		if (warrantyDocumentsList.length === 0) {
			void message.error('Please upload Warranty Documents');
			return;
		}

		checkFileErr(warrantyDocumentsList);
		const warrantyDocuments = warrantyDocumentsList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));
		dispatch(updateWarrantyDocuments(id, {warrantyDocuments}));
		setWarrantyDocumentsList([]);
		setFilePrefixKeys({});
	};

	const handleImageDelete: (id: number) => void = (id => dispatch(removeAttachment(id)));

	return (
		<DefaultLayout currentPath={uiPaths.editWarrantyDocument}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							{schedule?.projectProcessMaster?.processName ?? ''}
						</Typography.Title>
						<Typography.Title level={5} style={{textAlign: 'center'}}>
							{schedule?.site?.name}
						</Typography.Title>
						<br/>
						<br/>
						{canWrite ? (
							<Form {...layout} name='basic'>
								<Form.Item label='Warranty Documents'>
									{schedule?.warrantyDocuments?.length ? (
										<AttachmentsPreviewBlock
											attachments={schedule.warrantyDocuments}
											canDelete={canWrite}
											onDelete={id => {
												handleImageDelete(id);
											}}
										/>
									) : (
										[]
									)}
									<br/>
									<br/>
									<FileUpload
										label={'Click to Upload'}
										prefix={'project-schedule'}
										fileList={warrantyDocumentsList}
										filePrefixKeys={filePrefixKeys}
										onFileChange={(
											warrantyDocumentsList,
											filePrefixKeys,
										) => {
											setWarrantyDocumentsList(warrantyDocumentsList);
											setFilePrefixKeys(filePrefixKeys);
										}}
									/>
								</Form.Item>
								<Form.Item {...tailLayout}>
									<Button
										type='primary'
										onClick={onSubmitClicked}
									>
										Submit
									</Button>
								</Form.Item>

							</Form>
						) : (
							<Form {...layout} name='basic'>
								<Form.Item label='Warranty Documents'>
									{schedule?.warrantyDocuments?.length ? (
										<AttachmentsPreviewBlock
											attachments={schedule.warrantyDocuments}
											canDelete={false}
											showLabel={true}
										/>
									) : (
										[]
									)}
								</Form.Item>
							</Form>
						)}
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
