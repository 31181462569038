import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import type Types from 'MyTypes';
import {Col, Row, Spin, Table, Typography} from 'antd';
import {uiPaths} from 'app/constants';
import {get, setFilterValue} from './actions';
import {
	FilterFormType,
	type UiFilter,
	type FilterOptionItem,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {Link} from 'react-router-dom';
import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {
	type ProcessChecklistResponse,
	ProcessChecklistResponseStatus,
} from 'app/models/process-checklist-response.model';
import {getPermissionSites, toTitleCase} from 'app/helpers';
import {ModuleName, ModulePermission} from 'app/models';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';

type Props = {
	title: string;
	projectProcessType: ProjectProcessType;
};

export const ViewProcessChecklistResponse: React.FC<Props> = ({
	title,
	projectProcessType,
}) => {
	const dispatch = useDispatch();
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {allIds, byIds, loading, filterValue, dataUpdated} = useSelector(
		(state: Types.RootState) => state.processChecklistResponse,
	);
	const approveSites = getPermissionSites(
		permissions,
		ModuleName.CONSTRUCTION_SCHEDULE,
		ModulePermission.APPROVE,
		allSites,
	);

	React.useEffect(() => {
		console.log('ViewProcessChecklistResponse.componentDidMount');
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			if (filterValue) {
				filterData(filterValue);
			} else {
				filterData();
			}
		}
	}, [dataUpdated]);

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {where: {}, limit: 1000};
		if (projectProcessType) {
			filter.where.projectProcessType = projectProcessType;
		}

		if (f.siteId) {
			filter.where.siteId = f.siteId;
		} else {
			filter.where.siteId = {inq: approveSites.map(({id}) => id)};
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	const siteOptions: FilterOptionItem[] = approveSites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));
	const dataSource
    = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const statusOptions: FilterOptionItem[] = Object.values(
		ProcessChecklistResponseStatus,
	).map(status => ({value: status, label: toTitleCase(status) ?? ''}));
	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'status',
			formType: FilterFormType.SELECT,
			items: statusOptions,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: filterValue.status,
		},
	];
	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>
						{title}
					</Typography.Title>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={f => {
						filterData(f);
					}} />
					<br />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Table
						size={'small'}
						bordered={true}
						dataSource={dataSource}
						pagination={false}
					>
						<Table.Column
							title='Site'
							dataIndex='site'
							key='site'
							render={(text: string, response: ProcessChecklistResponse) =>
								response?.drawingSchedule
									? response?.drawingSchedule?.site?.name
									: response?.projectProcessSchedule?.site?.name
							}
						/>
						<Table.Column
							title='Checklist'
							dataIndex='processChecklist'
							key='processChecklist'
							render={(
								processChecklist: ProcessChecklist,
								response: ProcessChecklistResponse,
							) => (
								<Link
									to={{
										pathname: `/${uiPaths.viewProcessChecklistResponseDetail.replace(
											':scheduleId',
											String(response.projectProcessScheduleId ?? '0'),
										)
											.replace(
												':drawingScheduleId',
												String(response.drawingScheduleId ?? '0'),
											)
											.replace(':checklistId', processChecklist?.id ?? '0')
											.replace(
												':siteId',
												String(response.projectProcessSchedule?.siteId ?? '0'),
											)
											.replace(
												':processType',
												String(
													response.drawingScheduleId
														? ProjectProcessType.DESIGN
														: response.projectProcessSchedule
															?.projectProcessType,
												),
											)}`,
									}}
								>
									{processChecklist?.title ?? 'N/A'}
								</Link>
							)}
						/>
						<Table.Column
							title='Work Activity'
							dataIndex='workActivity'
							key='workActivity'
							render={(text: string, response: ProcessChecklistResponse) =>
								response?.drawingScheduleId
									? response?.drawingSchedule?.projectProcessMaster
										?.processName ?? 'N/A'
									: response?.projectProcessSchedule?.projectProcessMaster
										?.processName ?? 'N/A'
							}
						/>
						<Table.Column
							title='Checklist Status'
							dataIndex='status'
							key='status'
							render={(status: string) => toTitleCase(status)}
						/>
					</Table>
				</Col>
			</Row>
		</Spin>
	);
};
