import React from 'react';
import {formatDateFunction, parseNum, toSubstring, toTitleCase} from 'app/helpers';
import {idNotExist, uiPaths} from 'app/constants';
import {Col, Empty, Row, Spin, Table} from 'antd';
import type Types from 'MyTypes';
import {count, get, onAllPaginationChange, setAllFilterValue} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {UserRole} from 'app/models/enums/user-role.enum';
import {getQueryDateRange} from 'app/query_helpers';
import {FilterFormType, type FilterOptionItem, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {
	type ModuleName,
	PriorityType,
	type Query,
	type QueryParentType,
	type Site,
	QueryStatus,
	type User,
} from 'app/models';
import {useHistory} from 'react-router';

type Props = {
	module?: ModuleName;
	siteId?: number;
	workStatusReportId?: number;
	parentId?: string;
	parentType?: QueryParentType;
};

export const ViewAllQuery: React.FC<Props> = ({module, siteId, workStatusReportId, parentId, parentType}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {allFilterValue, loading, totalCount, allCurrentPos, allPerPageSize, byIds, allIds} = useSelector((state: Types.RootState) => state.query);
	const {user: authUser, subordinateOptions, userOptions} = useSelector((state: Types.RootState) => state.summary);
	const {allSites: sites} = useSelector((state: Types.RootState) => state.userPermission);

	const userStake: UserRole | undefined = authUser?.roles;
	const statusOptions: FilterOptionItem[] = Object.values(QueryStatus)
		.map(role => ({value: String(role), label: toTitleCase(role as string, '_') ?? ''}));

	React.useEffect(() => {
		if (allFilterValue) {
			filterData(allFilterValue);
		} else {
			filterData();
		}
	}, [allFilterValue]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: (currentPos ? perPageSize * (currentPos - 1) : 0),
			order: [
				'createdAt DESC',
			],
		};

		const orQuery: any[] = [];

		if (siteId) {
			filter.where.siteId = siteId;
		} else {
			orQuery.push({
				siteId: {inq: sites.map(({id}) => id)},
			});
		}

		if (parentId && parentType) {
			filter.where.parentId = parentId;
			filter.where.parentType = parentType;
		} else {
			orQuery.push({
				assignedTo: {
					inq: subordinateOptions.filter(({value}: FilterOptionItem) => value)
						.map(({value}: FilterOptionItem) => parseInt(value, 10)),
				},
			});
			orQuery.push({
				createdBy: {
					inq: subordinateOptions.filter(({value}: FilterOptionItem) => value)
						.map(({value}: FilterOptionItem) => parseInt(value, 10)),
				},
			});
		}

		filter.where.and = [{or: orQuery}];

		if (workStatusReportId) {
			filter.where.workStatusReportId = workStatusReportId;
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.assignedTo) {
			filter.where.assignedTo = f.assignedTo;
		}

		if (f.userId) {
			filter.where.createdBy = f.userId;
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		if (f.isHoldPoint) {
			filter.where.priority = PriorityType.HOLD_POINT;
		}

		if (f.date1 && f.date2) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(...getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt'));
		}

		if (f.closedDate1 && f.closedDate2) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(...getQueryDateRange(f.closedDate1 as string, f.closedDate2 as string, 'closedAt'));
		}

		dispatch(setAllFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const siteOptions: FilterOptionItem[] = sites.map(site => ({label: site.name, value: String(site.id)}));

	const raisedByUserOptions: FilterOptionItem[] = [
		...userOptions,
	];

	const assignedToUserOptions: FilterOptionItem[] = [
		...userOptions,
		{value: String(idNotExist), label: 'Not Yet Assigned'},
	];

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'userId',
			items: raisedByUserOptions,
			formType: FilterFormType.SELECT,
			formWidth: 160,
			placeholder: 'Select User',
			label: 'Raised By',
			defaultValue: allFilterValue?.userId,
		},
		{
			filterKey: 'assignedTo',
			items: assignedToUserOptions,
			formType: FilterFormType.SELECT,
			formWidth: 240,
			placeholder: 'Select Assigned To',
			label: 'Assigned To',
			defaultValue: allFilterValue?.assignedTo,
		},
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: allFilterValue?.status,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [allFilterValue?.date1 as string, allFilterValue?.date2 as string],
		},
		{
			filterKey: 'closedDate',
			formType: FilterFormType.DATE_RANGE,
			label: 'Closed Date',
			defaultValue: [allFilterValue?.closedDate1 as string, allFilterValue?.closedDate2 as string],
		},
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			formWidth: 240,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: allFilterValue?.siteId,
		},
	];

	if (userStake === UserRole.ARCHITECT_HEAD
		|| userStake === UserRole.CONSTRUCTION_HEAD
		|| userStake === UserRole.GOVERNMENT_APPROVAL_HEAD
		|| userStake === UserRole.PROCUREMENT_HEAD
		|| userStake === UserRole.COO
	) {
		uiFilters.push(
			{
				filterKey: 'isHoldPoint',
				formType: FilterFormType.BOOLEAN,
				label: 'Hold Point Queries',
				defaultValue: allFilterValue.isHoldPoint,
			},
		);
	}

	const columns = [
		{
			title: 'Point Raised',
			width: 400,
			dataIndex: 'content',
			render: (content: string) => toSubstring(content, 80, '...'),
			sorter: {
				compare: (a: Query, b: Query) =>
					a.content && b.content && a.content > b.content ? 1 : -1,
				multiple: 1,
			},
		},
		{
			title: 'Site',
			dataIndex: 'site',
			render: (site: Site) => site ? site.name : '',
			sorter: {
				compare: (a: Query, b: Query) =>
					a.site?.name && b.site?.name && a.site.name > b.site.name ? 1 : -1,
				multiple: 2,
			},
		},
		{
			title: 'Raised At',
			width: 100,
			dataIndex: 'createdAt',
			render: (createdAt: string) => formatDateFunction(createdAt),
			sorter: {
				compare: (a: Query, b: Query) =>
					a.createdAt && b.createdAt && new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1,
				multiple: 3,
			},
		},
		{
			title: 'Raised By',
			dataIndex: 'createdUser',
			render: (createdUser: User) => createdUser ? createdUser.name : '',
			sorter: {
				compare: (a: Query, b: Query) =>
					a.createdUser?.name && b.createdUser?.name && a.createdUser?.name > b.createdUser?.name ? 1 : -1,
				multiple: 4,
			},
		},
		{
			title: 'Assigned At',
			width: 100,
			dataIndex: 'assignedAt',
			render: (assignedAt: string) => formatDateFunction(assignedAt),
			sorter: {
				compare: (a: Query, b: Query) =>
					a.assignedAt && b.assignedAt && new Date(a.assignedAt) > new Date(b.assignedAt) ? 1 : -1,
				multiple: 5,
			},
		},
		{
			title: 'Assigned To',
			dataIndex: 'assignedUser',
			render: (assignedUser: User) => assignedUser ? assignedUser.name : 'Not Yet Assigned',
			sorter: {
				compare: (a: Query, b: Query) =>
					a.assignedUser?.name && b.assignedUser?.name && a.assignedUser?.name > b.assignedUser?.name ? 1 : -1,
				multiple: 5,
			},
		},
		{
			title: 'Status',
			width: 100,
			dataIndex: 'status',
			render: (status: QueryStatus) => toTitleCase(status),
			sorter: {
				compare: (a: Query, b: Query) =>
					a.status && b.status && a.status > b.status ? 1 : -1,
				multiple: 6,
			},
		},
		{
			title: 'Closed At',
			width: 100,
			dataIndex: 'closedAt',
			render: (closedAt: string) => formatDateFunction(closedAt),
			sorter: {
				compare: (a: Query, b: Query) =>
					a.closedAt && b.closedAt && new Date(a.closedAt) > new Date(b.closedAt) ? 1 : -1,
				multiple: 8,
			},
		},
	];

	const queries = allIds?.length ? allIds.map((id: number) => byIds[id]) : [];

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row>
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						onSubmit={f => {
							filterData(f);
						}}
					/>
					<br />
				</Col>
			</Row>

			{totalCount ? (
				<Row>
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={allCurrentPos ?? 1}
							perPageSize={allPerPageSize ?? 10}
							filterValue={allFilterValue}
							filterData={filterData}
							onPaginationChange={onAllPaginationChange}
						/>
						<Table
							size={'small'}
							pagination={false}
							dataSource={queries}
							columns={columns}
							scroll={{x: 1200, y: 500}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.queryDetail.replace(':queryId', String(query.id))}`,
									});
								},
							})}
						/>
					</Col>
				</Row>
			) : (
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			)}
		</Spin>
	);
};
