import {loadState} from 'app/store/helpers';
import {type ActionType} from 'typesafe-actions';
import type * as actions from './actions';
import {type Notification} from 'app/models';
import {logoutSuccessful} from '../Common/auth-actions';
import {actionTypes} from './actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';

export type MyAction = ActionType<typeof actions>;

export type MyState = Readonly<{
	itemMap: Record<Notification['id'], Notification>;
	items: Notification[];
	newCount: number;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
	loading: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	items: [],
	itemMap: {},
	newCount: 0,
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
	loading: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
export default function notification(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('notification', initialState),
	action: any,
): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: action.payload.currentPos as number,
					perPageSize: action.payload.perPageSize as number,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getCountApi):
		case getRequestActionType(actionTypes.getNewCountApi):
		case getRequestActionType(actionTypes.updateAllApi):
		case getRequestActionType(actionTypes.deleteApi):
			return {
				...state,
				...{
					loading: true,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getCountApi):
		case getFailureActionType(actionTypes.getNewCountApi):
		case getFailureActionType(actionTypes.updateAllApi):
		case getFailureActionType(actionTypes.deleteApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as Notification[];
			const itemMap: Record<Notification['id'], Notification> = {};
			let itemNewCount = 0;
			for (const item of items) {
				itemMap[item.id] = item;
				if (!item.isViewed) {
					itemNewCount++;
				}
			}

			return {
				...state,
				...{
					items,
					itemMap,
					newCount: itemNewCount,
					errorMessage: '',
					loading: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: (action?.payload?.data?.count as number) ?? 0,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getNewCountApi):
			return {
				...state,
				...{
					newCount: (action?.payload?.data?.count as number) ?? 0,
					errorMessage: '',
					loading: false,
				},
			};
		case getSuccessActionType(actionTypes.deleteApi): {
			const newItems: Notification[] = [];
			const newItemMap: Record<Notification['id'], Notification> = {};
			for (const item of state.items) {
				if (item.id !== action.payload.data) {
					newItemMap[item.id] = item;
					newItems.push(item);
				}
			}

			return {
				...state,
				...{
					items: newItems,
					itemMap: newItemMap,
					errorMessage: '',
					loading: false,
				},
			};
		}

		default:
			return state;
	}
}
