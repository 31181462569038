import type * as actions from './actions';
import {actionTypes} from './actions';
import {type PaymentDetail} from '../../models';
import {loadState} from '../../store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from '../../store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<PaymentDetail['id'], PaymentDetail>;
	allIds: Array<PaymentDetail['id']>;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

const paymentDetailReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('paymentDetail', initialState),
	action: any,
) => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as PaymentDetail[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default paymentDetailReducer;
