import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {getPermissionSites, hasPermission} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Col, Menu, Row, Typography} from 'antd';
import {log} from 'app/services/logger-service';
import {expensePathType} from './constants';
import ViewAllExpenseReport from './ViewAllExpenseReport';
import ExpenseReportForm from './ExpenseReportForm';
import {changePage} from './actions';
import ViewApprovedExpenseReport from './ViewApprovedExpenseReport';
import ViewPaidExpense from '../PaidExpense/ViewPaidExpense';
import ViewAllWallet from '../Wallet/ViewAllWallet';
import ViewWeeklyExpenseReport from '../WeeklyExpenseReport/ViewWeeklyExpenseReport';
import ViewWeeklyPendingAction from '../WeeklyPendingAction/ViewWeeklyPendingAction';
import {PendingActionType} from 'app/models/weekly-pending-action.model';
import * as qs from 'qs';
import {type History} from 'history';
import {ExpenseStatus, ModuleName, ModulePermission, type Site} from 'app/models';

type OwnProps = {
	location: Location;
	history: History;
};

const mapStateToProps = ({
	expenseReport: {pageType},
	summary: {user: authUser},
	userPermission: {byModule: permissions, allSites},
}: Types.RootState, ownProps: OwnProps) => ({
	pageType,
	authUser,
	permissions,
	allSites,
	...ownProps,
});

const mapDispatchToProps = {
	changePage,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

class ViewPettyCash extends React.Component<Props> {
	q: any;
	public canWrite: boolean;
	public readSites: Site[];
	public writeSites: Site[];
	public canViewApproved: boolean;
	public canViewPaid: boolean;
	public viewApprovedSites: Site[];
	public canApprove: boolean;
	public approveSites: Site[];
	public paidSites: Site[];
	public canViewWallet: boolean;
	public viewWalletSites: Site[];

	constructor(props: Props) {
		super(props);
		this.q = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
		if (this.q.page) {
			this.props.changePage(String(this.q.page));
		}

		const {permissions, allSites} = this.props;
		this.readSites = getPermissionSites(permissions, ModuleName.EXPENSE_REPORT, ModulePermission.READ, allSites);
		this.canWrite = permissions ? hasPermission(permissions[ModuleName.EXPENSE_REPORT], ModulePermission.WRITE) : false;
		this.writeSites = getPermissionSites(permissions, ModuleName.EXPENSE_REPORT, ModulePermission.WRITE, allSites);
		this.canViewApproved = permissions ? hasPermission(permissions[ModuleName.APPROVED_EXPENSE_REPORT], ModulePermission.READ) : false;
		this.canViewPaid = permissions ? hasPermission(permissions[ModuleName.EXPENSE_REPORT_PAY], ModulePermission.READ) : false;
		this.viewApprovedSites = getPermissionSites(permissions, ModuleName.APPROVED_EXPENSE_REPORT, ModulePermission.READ, allSites);
		this.canApprove = permissions ? hasPermission(permissions[ModuleName.EXPENSE_REPORT], ModulePermission.APPROVE) : false;
		this.approveSites = getPermissionSites(permissions, ModuleName.EXPENSE_REPORT, ModulePermission.APPROVE, allSites);
		this.paidSites = getPermissionSites(permissions, ModuleName.EXPENSE_REPORT_PAY, ModulePermission.READ, allSites);
		this.canViewWallet = permissions ? hasPermission(permissions[ModuleName.WALLET], ModulePermission.READ) : false;
		this.viewWalletSites = getPermissionSites(permissions, ModuleName.WALLET, ModulePermission.READ, allSites);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		log('ViewPettyCash.componentDidMount');
	}

	// eslint-disable-next-line complexity
	render() {
		const {pageType, changePage: onPageChange, history} = this.props;
		const currentPageType = pageType ?? (this.canWrite ? expensePathType.new : expensePathType.all);

		return (
			<DefaultLayout currentPath={uiPaths.pettyCash}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Petty Cash</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Menu
							onClick={e => onPageChange(e.key)}
							selectedKeys={[currentPageType]}
							mode='horizontal'
						>
							{this.canWrite ? (
								<Menu.Item key={expensePathType.new}>
									Add New Petty Cash
								</Menu.Item>
							) : []}
							{this.canWrite ? (
								<Menu.Item key={expensePathType.pending}>
									Pending Petty Cash
								</Menu.Item>
							) : []}
							{this.canWrite ? (
								<Menu.Item key={expensePathType.rejected}>
									Rejected Petty Cash
								</Menu.Item>
							) : []}
							{this.canWrite ? (
								<Menu.Item key={expensePathType.approved}>
									Approved Petty Cash
								</Menu.Item>
							) : []}
							{this.canApprove ? (
								<Menu.Item key={expensePathType.toApprove}>
									Petty Cash to be Approved
								</Menu.Item>
							) : []}
							{this.canViewPaid ? (
								<Menu.Item key={expensePathType.weaklyPaid}>
									Petty Cash to be Paid
								</Menu.Item>
							) : []}
							{this.canViewApproved ? (
								<Menu.SubMenu key='weeklyReport' title='Reports'>
									<Menu.Item key={expensePathType.approvedReport}>
										{'Report - Approved Petty Cash'}
									</Menu.Item>
									<Menu.Item key={expensePathType.weaklySum}>
										{'Weekly Report - Petty Cash Sum'}
									</Menu.Item>
									<Menu.Item key={expensePathType.inTotalSum}>
										{'Weekly Report - TTD Petty Cash Sum'}
									</Menu.Item>
									<Menu.Item key={expensePathType.pendingAction}>
										{'Weekly Report - Users\' Pending Petty Cash for Approvals'}
									</Menu.Item>
								</Menu.SubMenu>
							) : []}
							{this.canViewWallet ? (
								<Menu.Item key={expensePathType.wallet}>
									Wallets
								</Menu.Item>
							) : []}
							<Menu.Item key={expensePathType.all}>
								All Petty Cash
							</Menu.Item>
						</Menu>
					</Col>
				</Row>

				<Row>
					<Col span={24}><br /></Col>
				</Row>

				<Row>
					<Col span={24}>
						{this.canWrite && currentPageType === expensePathType.new ? (
							<ExpenseReportForm
								sites={this.writeSites}
							/>
						) : []}
						{currentPageType === expensePathType.pending ? (
							<ViewAllExpenseReport
								pageType={currentPageType}
								reportStatus={ExpenseStatus.PENDING}
								history={history}
								sites={this.writeSites}
							/>
						) : []}
						{currentPageType === expensePathType.rejected ? (
							<ViewAllExpenseReport
								pageType={currentPageType}
								reportStatus={ExpenseStatus.REJECTED}
								history={history}
								sites={this.writeSites}
							/>
						) : []}
						{currentPageType === expensePathType.approved ? (
							<ViewAllExpenseReport
								pageType={currentPageType}
								reportStatus={ExpenseStatus.APPROVED}
								history={history}
								sites={this.writeSites}
							/>
						) : []}
						{this.canApprove && currentPageType === expensePathType.toApprove ? (
							<ViewAllExpenseReport
								pageType={currentPageType}
								toBeApproved={true}
								history={history}
								sites={this.approveSites}
							/>
						) : []}
						{this.canViewPaid && currentPageType === expensePathType.weaklyPaid ? (
							<ViewPaidExpense
								sites={this.paidSites}
							/>
						) : []}
						{this.canViewApproved && currentPageType === expensePathType.approvedReport ? (
							<ViewApprovedExpenseReport
							/>
						) : []}
						{this.canViewApproved && currentPageType === expensePathType.weaklySum ? (
							<ViewWeeklyExpenseReport
								sites={this.approveSites}
								reportType={'weekly'}
							/>
						) : []}
						{this.canViewApproved && currentPageType === expensePathType.inTotalSum ? (
							<ViewWeeklyExpenseReport
								sites={this.approveSites}
								reportType={'ttd'}
							/>
						) : []}
						{this.canViewApproved && currentPageType === expensePathType.pendingAction ? (
							<ViewWeeklyPendingAction
								sites={this.approveSites}
								type={PendingActionType.EXPENSE_REPORT}
							/>
						) : []}
						{this.canViewWallet && currentPageType === expensePathType.wallet ? (
							<ViewAllWallet
								sites={this.viewWalletSites}
							/>
						) : []}
						{currentPageType === expensePathType.all && (
							<ViewAllExpenseReport
								pageType={currentPageType}
								history={history}
								canViewPaid={this.canViewPaid}
								sites={this.readSites}
							/>
						)}
					</Col>
				</Row>

				<br />
				<br />
			</DefaultLayout>
		);
	}
}

export default connector(ViewPettyCash);

