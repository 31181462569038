import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Form,
	message,
	Row,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {getById, create, update, initPage, search as searchActivities} from './actions';
import {search as searchChecklists} from '../ProcessChecklist/actions';
import {useHistory, useParams} from 'react-router';
import {getUniqueId, parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {type ActivityTemplateItem, ActivityType, type ActivityItem} from './activity-template-item';
import {ActivityItemFormBlock} from './ActivityItemFormBlock';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type ProcessChecklist} from 'app/models/process-checklist.model';

type MyParams = {
	id?: string;
	templateId?: string;
	type?: string;
};

export const ViewActivityTemplateItemForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();

	const {loading, byIds, dataUpdated, errorMessage}
		= useSelector((state: Types.RootState) => state.activityTemplateItem);

	const id = parseNum(params?.id);
	const templateId = parseNum(params?.templateId);
	const type = params?.type ? (params.type as ActivityType) : undefined;
	const item = byIds[id]
		? (byIds[id])
		: undefined;

	const [activityItem, setActivityItem] = React.useState<ActivityItem>({
		activityType: item?.activityType ?? type ?? ActivityType.CONSTRUCTION,
		isRootActivity: item?.isRootActivity ?? false,
		name: item?.name ?? '',
		sequence: item?.sequence ?? 0,
		duration: item?.duration ?? 0,
		formula: item?.formula ?? undefined,
		operator: item?.operator ?? undefined,
		operandDays: item?.operandDays ?? 0,
		queryType: ActivityType.CONSTRUCTION,
		itemValues: item?.linkedActivities?.length
			? item.linkedActivities.map(a => ({
				label: a?.name ?? '',
				value: a?.uniqueId ?? '',
			})) : [],
		checklistValues: item?.linkedChecklists?.length
			? item.linkedChecklists.map(a => ({
				label: a?.title ?? '',
				value: a?.id ?? '',
			})) : [],
	});

	React.useEffect(() => {
		dispatch(initPage());
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (id) {
				void message.success('Updated Successfully.');
				dispatch(getById(id));
			} else {
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		const data: Omit<ActivityTemplateItem, 'id'> = {
			uniqueId: item?.uniqueId ?? getUniqueId(),
			templateId: item?.templateId ?? templateId,
			isRootActivity: activityItem.isRootActivity,
			activityType: activityItem.activityType,
			name: activityItem.name,
			sequence: activityItem.sequence ?? 0,
			formula: activityItem.formula,
			operator: activityItem.operator,
			operandDays: activityItem.operandDays,
			duration: activityItem.duration,
			linkedUniqueIds: activityItem.itemValues?.length
				? activityItem.itemValues.map(opt => opt.value).join(',')
				: undefined,
			linkedFromType: activityItem.linkedFromType,
			linkedChecklistIds: activityItem.checklistValues?.length
				? activityItem.checklistValues.map(opt => opt.value).join(',')
				: undefined,
		};

		if (!data.isRootActivity && !data.linkedUniqueIds) {
			void message.error('Please add linked activities or make this activity as root activity');
			return;
		}

		if (!data.name) {
			void message.error('Please enter a name!');
			return;
		}

		if (!data.activityType) {
			void message.error('Please select activity type!');
			return;
		}

		if (data.isRootActivity) {
			delete data.formula;
			delete data.operator;
			delete data.operandDays;
			delete data.linkedUniqueIds;
			delete data.linkedChecklistIds;
		}

		if (item?.id) {
			dispatch(update(item.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<DefaultLayout currentPath={uiPaths.addActivityTemplateItem}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Activity Template Item Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
						<Form {...layout} name='basic'>
							<ActivityItemFormBlock
								item={activityItem}
								onItemChange={item => {
									setActivityItem(item);
								}}
								onItemSearch={async (
									query: string,
								): Promise<FilterOptionItem[]> =>
									searchActivities(activityItem.queryType, query, item?.templateId ?? templateId).then(data =>
										data.data.filter((dataItem: ActivityTemplateItem) =>
											dataItem.uniqueId !== item?.uniqueId,
										).map((dataItem: ActivityTemplateItem) => ({
											label: dataItem?.name ?? '',
											value: dataItem?.uniqueId ?? '',
										})),
									)
								}
								onChecklistChange={item => {
									setActivityItem(item);
								}}
								onChecklistSearch={async (
									query: string,
								): Promise<FilterOptionItem[]> =>
									searchChecklists(query).then(data =>
										data.data.map((dataItem: ProcessChecklist) => ({
											label: dataItem?.title ?? '',
											value: dataItem?.id ?? '',
										})),
									)
								}
							/>
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
