import type * as actions from './aws-actions';
import {actionTypes} from './aws-actions';
import {loadState} from '../../store/helpers';
import {getSuccessActionType} from '../../store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	signedUrl?: string;
}>;

const initialState: MyState = {
	signedUrl: undefined,
};

const awsReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('aws', initialState),
	action: any,
) => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case getSuccessActionType(actionTypes.getApi): {
			const signedUrl = String(action.payload.data);
			return {
				...state,
				signedUrl,
			};
		}

		default:
			return state;
	}
};

export default awsReducer;
