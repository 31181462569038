import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@googleScheduleSheet/API_FILTER',
	getApi: '@googleScheduleSheet/GET_API',
	getSheetDataApi: '@googleScheduleSheet/GET_SHEET_DATA_API',
	getClientSheetDataApi: '@googleScheduleSheet/GET_CLIENT_SHEET_DATA_API',
	getSheetDatesApi: '@googleScheduleSheet/GET_SHEET_DATES_API',
	createApi: '@googleScheduleSheet/CREATE_API',
	updateApi: '@googleScheduleSheet/UPDATE_API',
	updateSheetApi: '@googleScheduleSheet/UPDATE_SHEET_API',
};

const url = lbBaseUrl + '/schedule-sheets';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const getSheet = (id: number) => ({
	type: actionTypes.getSheetDataApi,
	payload: axios.get(`${url}/${id}/get-sheet`, getHeaders(true)),
	meta: {process: true},
});

export const getClientSheet = (id: number) => ({
	type: actionTypes.getClientSheetDataApi,
	payload: axios.get(`${url}/${id}/get-client-sheet`, getHeaders(true)),
	meta: {process: true},
});

export const getSheetDates = (id: number, labels: string) => ({
	type: actionTypes.getSheetDatesApi,
	payload: axios.get(`${url}/${id}/get-sheet-dates?labels=${labels}`, getHeaders(true)),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const updateSheet = (id: number,
	rowIndex: number,
	colIndex: number,
	data: any,
) => ({
	type: actionTypes.updateSheetApi,
	payload: axios.patch(
		`${url}/${id}/update-sheet?row=${rowIndex}&col=${colIndex}`,
		data,
		getHeaders(true),
	),
	meta: {process: true},
});

export const remove = (id: number) => ({
	type: actionTypes.updateApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const updateScheduleDates = (siteId: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/sites/${siteId}/update-schedule-dates-directly`, data, getHeaders(true)),
	meta: {process: true},
});
