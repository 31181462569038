import React from 'react';
import {Button, Input} from 'antd';
import {type UserAddress} from 'app/models';

type Prop = {
	currentValue?: UserAddress;
	onValueChange: (value: UserAddress) => void;
};

export const UserAddressForm: React.FC<Prop> = ({currentValue, onValueChange}) => (
	<Input.Group compact>
		<Button disabled={true}>Address Line</Button>
		<Input
			style={{width: '30%'}}
			value={currentValue?.addressLine ?? ''}
			onChange={(e: any) => {
				onValueChange({...currentValue, addressLine: String(e.target.value)});
			}}
		/>
		<Button disabled={true}>Google Pin</Button>
		<Input
			style={{width: '30%'}}
			value={currentValue?.googlePin ?? ''}
			onChange={(e: any) => {
				onValueChange({...currentValue, googlePin: String(e.target.value)});
			}}
		/>
	</Input.Group>
);
