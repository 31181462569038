import {Button, Card, Select, Space} from 'antd';
import {type Site, SiteStatus} from 'app/models';
import React, {useState} from 'react';
import {toTitleCase} from 'app/helpers';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {useDispatch} from 'react-redux';
import {update} from '../actions';

type Props = {
	canEdit: boolean;
	siteId: number;
	currentValue?: SiteStatus;
};

export const EditStatusForm: React.FC<Props> = ({canEdit, siteId, currentValue}) => {
	const statusOptions = Object.values(SiteStatus).map(status => ({
		value: status,
		label: toTitleCase(status as string, '_') ?? '',
	}));

	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [status, setStatus] = useState(currentValue ?? undefined);

	const handleSubmit = () => {
		const data: Partial<Site> = {
			status,
		};
		dispatch(update(siteId, data));
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Card>
				<Space>
					<Select
						showSearch={true}
						id={'SelectStatus'}
						style={{width: 250}}
						placeholder='Select a Status'
						optionFilterProp='children'
						value={status}
						defaultValue={status}
						onChange={value => {
							setStatus(value);
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{statusOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Space>
			</Card>
		) : (
			<Space>
				<label>{toTitleCase(currentValue as string, '_') ?? ''}</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
