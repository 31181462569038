import React from 'react';
import {type UserPermission} from 'app/models';
import {Button, Space} from 'antd';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {PlusOutlined} from '@ant-design/icons';
import {UserPermissionFormBlock} from 'app/components/UserPermission/UserPermissionFormBlock';

type Props = {
	siteId: number;
	moduleName: string;
	userOptions: FilterOptionItem[];
	onSave: (editedUserPermissions: UserPermission[]) => void;
};

export const ProjectUserPermissionsForm: React.FC<Props> = ({siteId, userOptions, moduleName, onSave}) => {
	const [editedUserPermissions, setEditedUserPermissions] = React.useState([] as UserPermission[]);

	const onUserPermissionAdd = () => {
		const items = [...editedUserPermissions];
		items.push({moduleName, siteId} as UserPermission);
		setEditedUserPermissions(items);
		// OnChange(items);
	};

	const onUserPermissionRemove = (ix: number) => {
		const items = [...editedUserPermissions];
		items.splice(ix, 1);
		setEditedUserPermissions(items);
		// OnChange(items);
	};

	const onUserPermissionChange = (ix: number, data: UserPermission) => {
		const items = [...editedUserPermissions];
		items[ix] = data;
		setEditedUserPermissions(items);
		// OnChange(items);
	};

	return (
		<Space direction='vertical'>
			{editedUserPermissions?.map((userPermission, ix) => (
				<UserPermissionFormBlock
					key={ix}
					ix={ix}
					userOptions={userOptions}
					userPermission={userPermission}
					onDataRemove={() => {
						onUserPermissionRemove(ix);
					}}
					onDataChange={(data: UserPermission) => {
						onUserPermissionChange(ix, data);
					}}
				/>
			))}
			<Button
				type='primary'
				onClick={() => {
					onUserPermissionAdd();
				}}
				block
				icon={<PlusOutlined />}
			>
        Add Permission
			</Button>
			<Button
				type='primary'
				onClick={() => {
					onSave(editedUserPermissions);
				}}
				block
			>
        Save
			</Button>
		</Space>
	);
};
