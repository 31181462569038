import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type MaterialOrderDailyQuantity} from 'app/models/material-order-daily-quantity.model';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<MaterialOrderDailyQuantity['id'], MaterialOrderDailyQuantity>;
	allIds: Array<MaterialOrderDailyQuantity['id']>;
	totalCount: number;
	pageType?: string;
	currentPos: number;
	perPageSize: number;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	totalCount: 0,
	pageType: undefined,
	currentPos: 1,
	perPageSize: 10,
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

const materialOrderDailyQuantity = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('materialOrderDailyQuantity', initialState),
	action: any,
): MyState => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getSingleApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				loading: true,
				dataUpdated: false,
				errorMessage: undefined,
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getSingleApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				errorMessage:
          (action.payload?.response?.data?.error?.message as string)
          ?? undefined,
				loading: false,
				dataUpdated: false,
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload?.data
				? (action.payload?.data as MaterialOrderDailyQuantity[])
				: [];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.getSingleApi): {
			const newItem = action.payload.data as MaterialOrderDailyQuantity;
			const newAllIds = [...state.allIds];
			if (!newAllIds.includes(newItem.id)) {
				newAllIds.push(newItem.id);
			}

			const newByIds = {...state.byIds};
			newByIds[newItem.id] = newItem;
			return {
				...state,
				...{
					byIds: newByIds,
					allIds: newAllIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default materialOrderDailyQuantity;
