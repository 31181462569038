import {Button, Card, Input, Space} from 'antd';
import {parseNum} from 'app/helpers';
import React, {useState} from 'react';

type Props = {
	canEdit: boolean;
	currentValue?: number;
	absoluteValue: number;
	onSubmit: (value: number) => void;
};

export const EditInputPercentForm: React.FC<Props> = ({canEdit, currentValue, absoluteValue, onSubmit}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [value, setValue] = useState(currentValue ?? 0);

	const handleSubmit = () => {
		onSubmit(value);
		setIsEditing(false);
	};

	const calPercent = (percentageValue: number) => {
		if (percentageValue && absoluteValue) {
			setValue(parseNum((percentageValue / 100) * absoluteValue));
		} else {
			setValue(0);
		}
	};

	return (
		isEditing ? (
			<Card>
				<Space>
					<Input
						width={300}
						type='number'
						min={0}
						value={value}
						defaultValue={value}
						onChange={(e: any) => {
							setValue(parseFloat(String(e.target.value)));
						}}
					/>
					<label><b>{'Enter Percentage: '}</b></label>
					<Input
						width={300}
						type='number'
						min={0}
						onChange={(e: any) => {
							calPercent(parseFloat(String(e.target.value)));
						}}
					/>
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Space>
			</Card>
		) : (
			<Space>
				<label>{currentValue}</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
