import React from 'react';
import {Col, Row, Spin, Table, Typography} from 'antd';
import {isNumeric, parseNum} from 'app/helpers';
import {type User} from 'app/models';

export type DelayType = {
	date: string;
	delay: number;
};

export type DataSourceType = {
	key: string;
	name: string;
	delays?: DelayType[];
	records: Record<string, number | string>;
	children?: DataSourceType[];
	ignoreChildren?: boolean;
};

export type ReportColumnType = {
	title: string;
	key: string;
	children?: ReportColumnType[];
	width?: number;
};

export type ReportCalcType = {
	sum: number;
	count: number;
};

type Props = {
	title: string;
	loading?: boolean;
	columns: ReportColumnType[];
	dataSource: DataSourceType[];
	reportDescription?: string;
	reportLogic?: string;
};

export const findLastChildNodes = (root: DataSourceType) => {
	if (!root) {
		return [];
	}

	const queue: DataSourceType[] = [root];
	const lastChildNodes: DataSourceType[] = [];

	while (queue.length > 0) {
		const node = queue.shift();
		if (node) {
			lastChildNodes.push(node);
		}

		if (node?.children?.length && !node.ignoreChildren) {
			queue.push(...node.children);
		}
	}

	return lastChildNodes;
};

export const emptyNode: DataSourceType = {
	key: '',
	name: '',
	records: {},
	children: [],
};

export const createUserTree = (node: DataSourceType, user?: User) => {
	if (!user) {
		return node;
	}

	node.key = 'user-' + String(user.id);
	node.name = user.name;
	if (user.subordinates?.length) {
		node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
			createUserTree({...emptyNode} as DataSourceType, subordinateUser),
		);
	}

	return node;
};

// Traversing the tree to assign delays to all nodes and to create sites as child nodes of site in charges(leaf nodes)
export const populateDataSourceTree = (node: DataSourceType, isAverage = false) => {
	const lastChildNodes = findLastChildNodes(node);

	const records = lastChildNodes.reduce<Record<string, ReportCalcType>>((record, item) => {
		if (item.records) {
			Object.keys(item.records).forEach(key => {
				if (!record[key]) {
					record[key] = {sum: 0, count: 0};
				}

				if (isNumeric(item.records[key])) {
					record[key].sum += parseNum(item.records[key]);
					record[key].count += 1;
				}
			});
		}

		return record;
	}, {});

	node.records = {};
	// Calculate the average for each key in the 'records' object if isAverage is true
	Object.keys(records).forEach(key => {
		node.records[key] = isAverage ? Math.round((records[key].sum / records[key].count) * 100) / 100 : records[key].sum;
	});

	if (node.children?.length) {
		node.children = node.children.map<DataSourceType>(childNode =>
			populateDataSourceTree(childNode, isAverage),
		);
	}

	return node;
};

export const ViewHierarchyReport: React.FC<Props> = props => (
	<Spin size='large' spinning={props.loading} tip={'Loading...'}>
		<Row>
			<Col span={24} style={{textAlign: 'center'}}>
				<Typography.Title level={3} style={{textAlign: 'center'}}>
					{props.title}
				</Typography.Title>
			</Col>
		</Row>
		<Row>
			<Col span={24} className='mb-10'>
				<Table
					dataSource={props.dataSource}
					bordered={true}
					pagination={false}
					scroll={{x: 1000, y: 1000}}
				>
					<Table.Column
						title='Name'
						dataIndex='name'
						key='name'
						fixed='left'
						width={240}
					/>
					{props.columns.map(column =>
						column.children?.length ? (
							<Table.ColumnGroup key={column.key} title={column.title}>
								{column.children.map(childColumn => (
									<Table.Column
										title={childColumn.title}
										dataIndex='records'
										key={childColumn.key}
										width={childColumn.width}
										render={(records: Record<string, number>) => records[childColumn.key] ?? ''}
									/>
								))}
							</Table.ColumnGroup>
						) : (
							<Table.Column
								title={column.title}
								dataIndex='records'
								key={column.key}
								width={column.width}
								render={(records: Record<string, number>) => records[column.key] ?? ''}
							/>
						),
					)}
				</Table>
			</Col>
		</Row>
		{props.reportDescription ? (
			<Row className='margin'>
				<Col span={24}>
					<Typography.Text>
						<b>Report Description:</b> {props.reportDescription}{' '}
					</Typography.Text>
				</Col>
			</Row>
		) : (
			[]
		)}
		{props.reportLogic ? (
			<Row>
				<Col span={24}>
					<Typography.Text>
						<b>Report Logic:</b> {props.reportLogic}{' '}
					</Typography.Text>
				</Col>
			</Row>
		) : (
			[]
		)}
	</Spin>
);
