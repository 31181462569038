import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCurrencyString, getDayOfWeek, getFormattedDateMonth, getPermissionSites, parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {Button, Card, Col, Row, Space, Spin, Table} from 'antd';
import {log} from 'app/services/logger-service';
import {getQueryDateRange, getQueryDateRangeForDays} from 'app/query_helpers';
import {get, setExtraFilterValue} from './actions';
import {CSVLink} from 'react-csv';
import {type ReportCounts, type WorkOrderBillReport, SiteStatus, type Site, ModuleName, ModulePermission} from 'app/models';
import {FilterFormType, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';

export const ViewBillWithoutWorkOrder: React.FC = () => {
	const dispatch = useDispatch();
	const {
		loading,
		extraFilterValue,
		reportDates,
		extraBySiteIds,
		dataUpdated,
		filterValue,
	} = useSelector((state: Types.RootState) => state.workOrderBillReport);
	const {byModule: permissions, allSites} = useSelector((state: Types.RootState) => state.userPermission);

	const {Column, ColumnGroup} = Table;

	const sites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER_BILL, ModulePermission.READ, allSites);

	React.useEffect(() => {
		log('ViewBillWithoutWorkOrder.componentDidMount');
		filterData();
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			if (filterValue) {
				filterData(filterValue);
			}
		}
	}, [dataUpdated]);

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {
			where: {},
			limit: 50000,
			skip: 0,
		};

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt');
		} else {
			filter.where.and = getQueryDateRangeForDays(new Date().toDateString(), 0, 34, 'createdAt');
		}

		dispatch(setExtraFilterValue(f));
		dispatch(get(filter));
	};

	const sitesByStatus = sites.reduce((byStatus: Record<string, Site[]>, site: Site) => {
		if (!site.status) {
			return byStatus;
		}

		if (!byStatus[site.status]) {
			byStatus[site.status] = [];
		}

		byStatus[site.status].push(site);
		return byStatus;
	}, {});

	const reduceTotalFn = (totalSum, report) => {
		if (!totalSum.site) {
			totalSum.site = {id: 0, projectNumber: 0, name: 'Total', address: ''};
		}

		if (reportDates && extraBySiteIds) {
			reportDates.forEach((reportDate: string) => {
				const item = extraBySiteIds[report.site.id]?.[reportDate]
					? extraBySiteIds[report.site.id][reportDate] : {total: 0, week: 0};
				if (totalSum[reportDate]) {
					// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
					totalSum[reportDate].total += item.total;
					// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
					totalSum[reportDate].week += item.week;
				} else {
					totalSum[reportDate] = {total: 0, week: 0};
				}
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return totalSum;
	};

	const mapItemFn = (site: Site) => {
		const item = {site};
		if (reportDates && extraBySiteIds) {
			reportDates.forEach((reportDate: string) => {
				item[reportDate] = extraBySiteIds[site.id]?.[reportDate] ? extraBySiteIds[site.id][reportDate] : {};
			});
		}

		return item;
	};

	const underConstructionLabel: any = {site: {id: 0, name: 'Under Construction', address: ''}};
	const underConstructionSites: Site[] = sitesByStatus[SiteStatus.UNDER_CONSTRUCTION] ?? [];
	const underConstruction = underConstructionSites.map(mapItemFn);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const underConstructionTotal = underConstruction.reduce(reduceTotalFn, {});

	const underWarrantyLabel = {site: {id: 0, name: 'Under Warranty', address: ''}};
	const underWarrantySites: Site[] = sitesByStatus[SiteStatus.UNDER_WARRANTY] ?? [];
	const underWarranty = underWarrantySites.map(mapItemFn);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const underWarrantyTotal = underWarranty.reduce(reduceTotalFn, {});

	const underDesignLabel = {site: {id: 0, name: 'Under Design', address: ''}};
	const underDesignSites: Site[] = sitesByStatus[SiteStatus.UNDER_DESIGN] ?? [];
	const underDesign = underDesignSites.map(mapItemFn);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const underDesignTotal = underDesign.reduce(reduceTotalFn, {});

	const dataSource: any[] = [
		underConstructionLabel,
		...underConstruction,
		underConstructionTotal,
		{},
		underWarrantyLabel,
		...underWarranty,
		underWarrantyTotal,
		{},
		underDesignLabel,
		...underDesign,
		underDesignTotal,
	];

	const csvData: any[] = [];
	const csvDataHeader = ['Site'];
	for (const reportDate of reportDates) {
		csvDataHeader.push(`${getDayOfWeek(reportDate)} ${getFormattedDateMonth(reportDate)} In Week`);
		csvDataHeader.push(`${getDayOfWeek(reportDate)} ${getFormattedDateMonth(reportDate)} Till Now`);
	}

	csvData.push(csvDataHeader);
	dataSource.forEach((record: WorkOrderBillReport) => {
		const csvDataEntry = [record.site ? record.site.name : ''];
		for (const reportDate of reportDates) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const report = record[reportDate];
			csvDataEntry.push(report?.week ? getCurrencyString(parseNum(report.week as string), false) : '');
			csvDataEntry.push(report?.total ? getCurrencyString(parseNum(report.total as string), false) : '');
		}

		csvData.push(csvDataEntry);
	});

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [extraFilterValue?.date1 as string, extraFilterValue?.date2 as string],
		},
	];

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row>
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						onSubmit={f => {
							filterData(f);
						}}
					/>
				</Col>
				<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
					<Space>
						<CSVLink
							data={csvData}
							filename={'Work_Order_Bill_Report.csv'}
						>
							<Button type={'primary'}>
								Download
							</Button>
						</CSVLink>
					</Space>
					<br />
				</Col>
			</Row>

			<Row>
				<Col span={24} className='mb-10'>
					<Table
						bordered={true}
						size={'small'}
						dataSource={dataSource}
						scroll={{x: 1200, y: 500}}
						pagination={false}
					>
						<Column
							title='Site'
							dataIndex='site'
							key='site'
							width={200}
							render={(site: Site | undefined) => site ? (site.id ? site.name : <b>{site.name}</b>) : ' - '}
						/>
						{reportDates?.map((reportDate: string, index: number) => (
							<ColumnGroup
								width={200}
								title={`${getDayOfWeek(reportDate)} ${getFormattedDateMonth(reportDate)}`}
								key={reportDate}
							>
								<Column
									title='In Week'
									width={100}
									dataIndex={reportDate}
									key={`${reportDate}InWeek`}
									render={(report: ReportCounts | undefined) =>
										report?.week ? getCurrencyString(report.week, false) : ''}
								/>
								<Column
									title='Till Now'
									width={100}
									dataIndex={reportDate}
									key={`${reportDate}TillNow`}
									render={(report: ReportCounts | undefined) =>
										report?.total ? getCurrencyString(report.total, false) : ''}
								/>
							</ColumnGroup>
						))}
					</Table>
				</Col>
			</Row>

			<Row>
				<Col span={24} className='mb-10'>
					<Card size='small' title={'Important note'}>
						<p><b>*Pending Bills: </b> Bills those are not yet re approved</p>
						<p><b>^Approved Bills: </b> Bill those are re approved or paid</p>
					</Card>
					<br />
				</Col>
			</Row>
		</Spin>
	);
};

