import {Button, Card, DatePicker, Space} from 'antd';
import React, {useState} from 'react';
import moment from 'moment';
import {formatDateFunction} from 'app/helpers';

type Props = {
	canEdit: boolean;
	currentValue?: string;
	onSubmit: (value: string) => void;
};

export const EditInputDateForm: React.FC<Props> = ({canEdit, currentValue, onSubmit}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [value, setValue] = useState(currentValue ?? '');

	const handleSubmit = () => {
		onSubmit(value ? moment(value).format('YYYY-MM-DDTHH:mm:ss.sssZ').toString() : '');
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Card>
				<Space>
					<DatePicker onChange={(_, dateString) => {
						setValue(dateString);
					}} />
					<Button onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Close</Button>
				</Space>
			</Card>
		) : (
			<Space>
				<label>{currentValue ? formatDateFunction(currentValue, false) : ''}</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
