import React from 'react';
import {type Notification} from 'app/models';
import {Card, Col} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import moment from 'moment';

type Props = {
	notification: Notification;
	canRemove: boolean;
	handleRemoveClick: (id: number) => void;
};

export const NotificationBlock: React.FC<Props> = (props: Props) => {
	const {notification, canRemove, handleRemoveClick} = props;

	const onRemoveClick = () => {
		handleRemoveClick(notification.id);
	};

	const actions: any[] = [];
	if (canRemove) {
		actions.push(<DeleteOutlined onClick={onRemoveClick} key={notification.id}/>);
	}

	return (
		<Col span={24} className={'mb-10'}>
			<Card size='small' title={notification.title ?? ''} actions={actions}>
				<div dangerouslySetInnerHTML={{__html: notification.content}}></div>
				<p>{moment(notification.createdAt).fromNow()}</p>
			</Card>
		</Col>
	);
};
