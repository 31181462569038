import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {Col, Empty, Input, Modal, Row, Space, Spin, Typography} from 'antd';
import {uiPaths} from 'app/constants';
import {approve, count, get, onPaginationChange, reject, setFilterValue} from './actions';
import {toTitleCase} from 'app/helpers';
import {type ChangeLog, ChangeLogStatus} from 'app/components/ChangeLog/change-log';
import {FilterFormType, type FilterOptionItem, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import {ChangeLogTable} from 'app/components/ChangeLog/ChangeLogTable';
import type Types from 'MyTypes';
import {type Where, type Filter} from 'app/types/query';

export const ViewChangeLog: React.FC = () => {
	const dispatch = useDispatch();
	const {allIds, byIds, loading, filterValue, dataUpdated, totalCount, currentPos, perPageSize} = useSelector((state: Types.RootState) => state.changeLog);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const [cancelReason, setCancelReason] = useState<string>('');
	const [cancelId, setCancelId] = useState<number>(0);

	const statusOptions: FilterOptionItem[] = Object.values(ChangeLogStatus).map((status: string) => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	React.useEffect(() => {
		window.scrollTo(0, 0);
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, [filterValue]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (filterValue) {
				filterData(filterValue, currentPos, perPageSize);
			} else {
				filterData();
			}
		}
	}, [dataUpdated]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const where: Where<ChangeLog> = {};

		if (f.status) {
			where.status = f.status as ChangeLogStatus;
		} else {
			where.status = ChangeLogStatus.REQUESTED;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			const filter: Filter<ChangeLog> = {
				where,
				limit: perPageSize,
				skip: (currentPos ? perPageSize * (currentPos - 1) : 0),
				order: [
					'createdAt DESC',
				],
			};
			dispatch(get(filter));
		} else {
			dispatch(count(where));
		}
	};

	const handleApproveClick = (id: number) => {
		dispatch(approve(id, {}));
	};

	const handleRejectClick = (id: number) => {
		setCancelId(id);
		setCancelReason('');
	};

	const handleRejectOk = () => {
		if (cancelId) {
			dispatch(reject(cancelId, {cancelReason}));
		}

		setCancelId(0);
		setCancelReason('');
	};

	const handleRejectCancel = () => {
		setCancelId(0);
		setCancelReason('');
	};

	const userStake = authUser?.roles;

	const dataSource = allIds.map(id => byIds[id]);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 120,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: filterValue?.status,
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.changeLog}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Change Log</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
					</Col>
				</Row>
				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos ?? 1}
								perPageSize={perPageSize ?? 10}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<ChangeLogTable
								dataSource={dataSource}
								userStake={userStake}
								handleApprove={handleApproveClick}
								handleReject={handleRejectClick}
							/>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
				<Modal
					title='Cancel this Change'
					open={Boolean(cancelId)}
					onOk={handleRejectOk}
					onCancel={handleRejectCancel}
				>
					<Space direction={'vertical'}>
						<label>Please provide a reason to cancel this change</label>
						<Input.TextArea
							placeholder={'Enter Cancel Reason'}
							defaultValue={cancelReason ?? ''}
							value={cancelReason ?? ''}
							onChange={(e: any) => {
								setCancelReason(String(e.target.value));
							}}
						/>
					</Space>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};
