import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseNum} from 'app/helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useParams, useHistory} from 'react-router';
import {update, createAndGet, initPage} from './actions';
import {Button, Col, Form, message, Row, Spin, Typography} from 'antd';
import {ActivityForm} from './ActivityForm';
import {
	type ProjectHandoverActivity,
} from 'app/models';
import type Types from 'MyTypes';

type MyParams = {
	siteId?: string;
};

export const ViewProjectHandoverActivityForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {loading, byIds, allIds, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.projectHandoverActivity,
	);
	const params = useParams<MyParams>();
	const siteId = parseNum(params?.siteId ?? '0');
	const projectHandoverActivities
    = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const [activities, setActivities] = React.useState<ProjectHandoverActivity[]>(
		projectHandoverActivities,
	);

	React.useEffect(() => {
		console.log('ViewProjectHandoverActivityForm.componentDidMount');
		dispatch(initPage());
		const data = {
			siteId,
		};
		dispatch(createAndGet(data));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated successfully');
			history.push({
				pathname: `/${uiPaths.viewProjectHandoverSchedule}`,
			});
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};

	const onActivityChange = (id: string, value: ProjectHandoverActivity) => {
		const items = [...projectHandoverActivities];
		const ix = items.findIndex(i => i.id === id);
		items[ix] = value;
		setActivities(items);
	};

	const onSubmitClicked = () => {
		dispatch(update(activities));
	};

	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};

	return (
		<DefaultLayout currentPath={uiPaths.editProjectHandoverSchedule}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Button onClick={() => dispatch(update(activities))}>
              Update Data
						</Button>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Project Handover Schedule Form
						</Typography.Title>

						<Form {...layout} name='basic'>
							{siteId && projectHandoverActivities.length
								? projectHandoverActivities.map((activity, ix) => (
									<ActivityForm
										key={ix}
										activity={activity}
										onChange={(value: ProjectHandoverActivity) => {
											onActivityChange(activity.id, value);
										}
										}
									/>
								))
								: []}
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
                  Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
