import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {uiPaths} from '../constants';
import {DefaultLayout} from './Layout/DefaultLayout';
import {Avatar, Col, Empty, Menu, Row, Spin, Typography} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {toTitleCase} from '../helpers';
import {ViewAllProfileSite} from './Site/ViewAllProfileSite';
import {UserRole} from '../models/enums/user-role.enum';
import {type User} from '../models';
import {ViewAllProfileUser} from './User/ViewAllProfileUser';
import {ViewUserInfo} from './User/ViewUserInfo';
import type Types from 'MyTypes';

export const profilePathType: Record<string, string> = {
	info: 'INFO',
	sites: 'SITES',
	users: 'USERS',
};

export const Profile: React.FC = () => {
	const {user: authUser, users} = useSelector((state: Types.RootState) => state.summary);
	const {allSites: userSites} = useSelector((state: Types.RootState) => state.userPermission);
	const [pageType, setPageType] = useState<string>(profilePathType.info);

	if (!authUser) {
		return (
			<DefaultLayout currentPath={uiPaths.profile}>
				<Empty />
			</DefaultLayout>
		);
	}

	return (
		<DefaultLayout currentPath={uiPaths.profile}>
			<Spin
				size='large'
				spinning={false}
				tip={'Loading...'}
			>
				<Row><Col><br /><br /></Col></Row>

				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Avatar size={128} icon={<UserOutlined />} />
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>{authUser?.name ?? ''}</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Menu
							onClick={e => {
								setPageType(e.key);
							}}
							selectedKeys={[pageType]}
							mode='horizontal'
						>
							<Menu.Item key={profilePathType.info}>
								{toTitleCase(profilePathType.info, '_')}
							</Menu.Item>
							{authUser?.roles !== UserRole.CLIENT && authUser?.roles !== UserRole.VENDOR && (
								<Menu.Item key={profilePathType.sites}>
									{toTitleCase(profilePathType.sites, '_')}
								</Menu.Item>
							)}
							{authUser?.roles !== UserRole.CLIENT && authUser?.roles !== UserRole.VENDOR && (
								<Menu.Item key={profilePathType.users}>
									{toTitleCase(profilePathType.users, '_')}
								</Menu.Item>
							)}
						</Menu>
					</Col>
				</Row>

				<br />
				<br />

				{pageType === profilePathType.info && (
					<ViewUserInfo
						user={authUser}
						canEdit={true}
					/>
				)}
				{pageType === profilePathType.sites && (
					<ViewAllProfileSite
						sites={userSites}
					/>
				)}
				{pageType === profilePathType.users && (
					<ViewAllProfileUser
						users={users.filter((u: User) => u.subordinate)}
						source={'profile'}
					/>
				)}
				<br />
				<br />
			</Spin>
		</DefaultLayout>
	);
};
