import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type ProjectProcessSchedule} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {reduceUniqueIds} from 'app/helpers';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<ProjectProcessSchedule['id'], ProjectProcessSchedule>;
	allIds: Array<ProjectProcessSchedule['id']>;
	processChecklist?: ProcessChecklist;
	floorOptions?: FilterOptionItem[];
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	processChecklist: undefined,
	floorOptions: [],
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
const projectProcessScheduleReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('projectProcessSchedule', initialState),
	action: any,
) => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.updateProgressPhotosApi):
		case getRequestActionType(actionTypes.updateWarrantyDocumentsApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.removeApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateProgressPhotosApi):
		case getFailureActionType(actionTypes.updateWarrantyDocumentsApi):
		case getFailureActionType(actionTypes.removeApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as ProjectProcessSchedule[];
			const floors = items.reduce<string[]>(
				(ids, {projectProcessMaster: master}) =>
					reduceUniqueIds<string>(ids, master?.workActivityMaster?.floorName),
				[],
			);
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					floorOptions: floors.map<FilterOptionItem>(floor => ({
						label: floor,
						value: floor,
					})),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getSingleApi): {
			const item = action.payload.data as ProjectProcessSchedule;
			const newAllIds = state.allIds?.length ? [...state.allIds] : [];
			if (!newAllIds.includes(item.id)) {
				newAllIds.push(item.id);
			}

			const newByIds = state.byIds ? {...state.byIds} : {};
			newByIds[item.id] = item;
			return {
				...state,
				...{
					allIds: newAllIds,
					byIds: newByIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getChecklistApi): {
			const processChecklist = action.payload.data as ProcessChecklist;
			return {
				...state,
				...{
					processChecklist,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateProgressPhotosApi):
		case getSuccessActionType(actionTypes.updateWarrantyDocumentsApi):
		case getSuccessActionType(actionTypes.updateApi):
		case getSuccessActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default projectProcessScheduleReducer;
