import {loadState} from 'app/store/helpers';
import {type ActionType} from 'typesafe-actions';
import type * as actions from './actions';
import {logoutSuccessful} from '../Common/auth-actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {actionTypes} from './actions';
import {type ReportCounts, type WorkOrderBillReport} from 'app/models';
import {getOnlyDate} from 'app/helpers';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = ActionType<typeof actions>;

export type MyState = Readonly<{
	byIds: Record<number, WorkOrderBillReport>;
	recordSiteStatus: Record<string, WorkOrderBillReport[]>;
	extraBySiteIds: Record<number, Record<string, ReportCounts>>;
	reportDates: string[];
	allIds: number[];
	filterValue: FilterDataType;
	extraFilterValue: FilterDataType;
	reportDate?: string;
	loading: boolean;
	dataUpdated: boolean;
}>;

const initialState: MyState = {
	byIds: {},
	recordSiteStatus: {},
	extraBySiteIds: {},
	reportDates: [],
	allIds: [],
	filterValue: {},
	extraFilterValue: {},
	reportDate: undefined,
	loading: false,
	dataUpdated: false,
};

export default function workOrderBillReport(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('workOrderBillReport', initialState),
	action: any,
): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.apiExtraFilter:
			return {...state, extraFilterValue: action.payload as FilterDataType};
		case getRequestActionType(actionTypes.getApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
				},
			};
		case getFailureActionType(actionTypes.getApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as WorkOrderBillReport[];
			items.sort((d1, d2) =>
				d1.site?.createdAt
        && d2.site?.createdAt
        && new Date(d2.site?.createdAt) >= new Date(d1.site?.createdAt)
					? 0
					: -1,
			);
			return {
				...state,
				...{
					byIds: items.reduce(
						(byIds: Record<number, WorkOrderBillReport>, item) => {
							byIds[item.id] = item;
							return byIds;
						},
						{},
					),
					recordSiteStatus: items.reduce(
						(bySieStatus: Record<string, WorkOrderBillReport[]>, item) => {
							if (item.site?.status) {
								if (!bySieStatus[item.site.status]) {
									bySieStatus[item.site.status] = [];
								}

								bySieStatus[item.site.status].push(item);
							}

							return bySieStatus;
						},
						{},
					),
					extraBySiteIds: items.reduce<MyState['extraBySiteIds']>(
						(extraBySiteIds, report) => {
							const dateKey = getOnlyDate(report.createdAt);
							if (!extraBySiteIds[report.siteId]) {
								extraBySiteIds[report.siteId] = {};
							}

							extraBySiteIds[report.siteId][dateKey] = report.extraAmounts ?? {
								total: 0,
								week: 0,
							};
							return extraBySiteIds;
						},
						{},
					),
					reportDates: items
						.reduce<MyState['reportDates']>((reportDates, report) => {
						const dateKey = getOnlyDate(report.createdAt);
						if (!reportDates.includes(dateKey)) {
							reportDates.push(dateKey);
						}

						return reportDates;
					}, [])
						.sort((d1, d2) => (new Date(d2) >= new Date(d1) ? 0 : -1)),
					allIds: items.map(item => item.id),
					reportDate: items.length ? items[0].createdAt : undefined,
					dataUpdated: false,
					loading: false,
				},
			};
		}

		default:
			return state;
	}
}
