import React from 'react';
import type Types from 'MyTypes';
import {ViewQueryForm} from './ViewQueryForm';
import {ViewAllQuery} from './ViewAllQuery';
import {ViewMyRaisedQuery} from './ViewMyRaisedQuery';
import {ViewAssignedToMeQuery} from './ViewAssignedToMeQuery';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Menu, Row} from 'antd';
import {log} from 'app/services/logger-service';
import {hasPermission, isMobile} from 'app/helpers';
import {queryPathType} from './constants';
import {changePage} from './actions';
import {ModuleName, ModulePermission, type QueryParentType} from 'app/models';

type Props = {
	defaultPage?: string;
	parentId?: string;
	parentType?: QueryParentType;
	module?: ModuleName;
	siteId?: number;
};

export const ViewChildQuery: React.FC<Props> = ({defaultPage, parentId, parentType, module, siteId}) => {
	const dispatch = useDispatch();

	const {modulePermissionMap: permMap} = useSelector((state: Types.RootState) => state.userPermission);
	const {pageType} = useSelector((state: Types.RootState) => state.query);

	const canWrite: boolean = hasPermission(permMap[ModuleName.CLIENT_QUERY], ModulePermission.WRITE)
		|| hasPermission(permMap[ModuleName.WARRANTY_QUERY], ModulePermission.WRITE)
		|| hasPermission(permMap[ModuleName.QUERY], ModulePermission.WRITE);
	const canWriteWarrantyQuery: boolean = hasPermission(permMap[ModuleName.WARRANTY_QUERY], ModulePermission.WRITE);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		log('ViewQuery.componentDidMount');
	}, []);

	const selectedPage = pageType ?? defaultPage;
	return (
		<>
			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(changePage(e.key))}
						selectedKeys={[selectedPage ?? '']}
						mode='horizontal'
					>
						<Menu.Item id={queryPathType.new} key={queryPathType.new}>
							Raise New Query
						</Menu.Item>
						{isMobile ? (
							<Menu.SubMenu key='ShowQuery' title='Show Queries'>
								<Menu.Item id={queryPathType.raised} key={queryPathType.raised}>
									Raised by Me
								</Menu.Item>
								<Menu.Item id={queryPathType.assigned} key={queryPathType.assigned}>
									Assigned to Me
								</Menu.Item>
								<Menu.Item id={queryPathType.all} key={queryPathType.all}>
									All Queries
								</Menu.Item>
							</Menu.SubMenu>
						) : []}
						{isMobile ? [] : (
							<Menu.Item id={queryPathType.raised} key={queryPathType.raised}>
								Raised by Me
							</Menu.Item>
						)}
						{isMobile ? [] : (
							<Menu.Item id={queryPathType.assigned} key={queryPathType.assigned}>
								Assigned to Me
							</Menu.Item>
						)}
						{isMobile ? [] : (
							<Menu.Item id={queryPathType.all} key={queryPathType.all}>
								All Queries
							</Menu.Item>
						)}
					</Menu>
				</Col>
			</Row>

			<Row>
				<Col span={24}><br/></Col>
			</Row>

			<Row>
				<Col span={24}>
					{selectedPage === queryPathType.new && (
						<ViewQueryForm
							module={canWriteWarrantyQuery ? ModuleName.WARRANTY_QUERY : ModuleName.QUERY}
							parentId={parentId}
							parentType={parentType}
							siteId={siteId}
						/>
					)}
					{selectedPage === queryPathType.raised && (
						<ViewMyRaisedQuery
							// History={history}
							module={ModuleName.QUERY}
							parentId={parentId}
							parentType={parentType}
							siteId={siteId}
						/>
					)}
					{selectedPage === queryPathType.assigned && (
						<ViewAssignedToMeQuery
							// History={history}
							module={ModuleName.QUERY}
							parentId={parentId}
							parentType={parentType}
							siteId={siteId}
						/>
					)}
					{selectedPage === queryPathType.all && (
						// <ViewAllTicket
						<ViewAllQuery
							// History={history}
							module={ModuleName.QUERY}
							parentId={parentId}
							parentType={parentType}
							siteId={siteId}
						/>
					)}
				</Col>
			</Row>
		</>
	);
};
