import {loadState} from 'app/store/helpers';
import {actionTypes} from './actions';
import {logoutSuccessful} from '../Common/auth-actions';
import {type Query, type Comment} from 'app/models';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyState = Readonly<{
	byIds: Record<Query['id'], Query>;
	allIds: Array<Query['id']>;
	comments: Comment[];
	totalCount: number;
	currentPos: number;
	perPageSize: number;
	allCurrentPos: number;
	allPerPageSize: number;
	raisedCurrentPos: number;
	raisedPerPageSize: number;
	assignedCurrentPos: number;
	assignedPerPageSize: number;
	pageType?: string;
	filterValue: FilterDataType;
	allFilterValue: FilterDataType;
	raisedFilterValue: FilterDataType;
	assignedFilterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	comments: [],
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
	allCurrentPos: 1,
	allPerPageSize: 10,
	raisedCurrentPos: 1,
	raisedPerPageSize: 10,
	assignedCurrentPos: 1,
	assignedPerPageSize: 10,
	pageType: undefined,
	filterValue: {},
	allFilterValue: {},
	raisedFilterValue: {},
	assignedFilterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
const clientQueryReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('clientQuery', initialState),
	action: any,
): MyState => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.changePage:
			return {...state, pageType: String(action.payload)};
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.apiAllFilter:
			return {...state, allFilterValue: action.payload as FilterDataType};
		case actionTypes.apiRaisedFilter:
			return {...state, raisedFilterValue: action.payload as FilterDataType};
		case actionTypes.apiAssignedFilter:
			return {
				...state,
				assignedFilterValue: action.payload as FilterDataType,
			};
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: action.payload.currentPos as number,
					perPageSize: action.payload.perPageSize as number,
				},
			};
		case actionTypes.allPaginationChange:
			return {
				...state,
				...{
					allCurrentPos: action.payload.allCurrentPos as number,
					allPerPageSize: action.payload.allPerPageSize as number,
				},
			};
		case actionTypes.raisedPaginationChange:
			return {
				...state,
				...{
					raisedCurrentPos: action.payload.raisedCurrentPos as number,
					raisedPerPageSize: action.payload.raisedPerPageSize as number,
				},
			};
		case actionTypes.assignedPaginationChange:
			return {
				...state,
				...{
					assignedCurrentPos: action.payload.assignedCurrentPos as number,
					assignedPerPageSize: action.payload.assignedPerPageSize as number,
				},
			};
		case actionTypes.initPage:
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getCountApi):
		case getRequestActionType(actionTypes.getSingleApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					commentUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getCountApi):
		case getFailureActionType(actionTypes.getSingleApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage: action.payload?.response?.data?.error?.message as string,
					loading: false,
					dataUpdated: false,
					commentUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as Query[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: action.payload.data.count as number,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getSingleApi): {
			const newItem = action.payload.data as Query;
			const newAllIds = [...state.allIds];
			if (!newAllIds.includes(newItem.id)) {
				newAllIds.push(newItem.id);
			}

			const newByIds = {...state.byIds};
			newByIds[newItem.id] = newItem;
			return {
				...state,
				...{
					byIds: newByIds,
					allIds: newAllIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default clientQueryReducer;
