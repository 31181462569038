import React, {useState} from 'react';
import {lbBaseUrl, uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {Button, Col, Form, Input, Row, Spin} from 'antd';
import axios from 'axios';

const {TextArea} = Input;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DUMMI: React.FC = () => {
	const [show, setShow] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [dummiUrl, setDummiUrl] = useState<string>('');
	const [dummiResponse, setDummiResponse] = useState<string>('');

	const onSubmitClicked = () => {
		setLoading(true);
		const token = localStorage.getItem('appToken');
		axios({
			method: 'get',
			url: `${lbBaseUrl}/dummi/${dummiUrl}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(response => {
				setLoading(false);
				setDummiResponse(JSON.stringify(response));
			})
			.catch(error => {
				setLoading(false);
				setDummiResponse('');
			});
	};

	return (
		<DefaultLayout currentPath={uiPaths.dummi}>
			{show > 5 ? (
				<Spin
					size='large'
					spinning={loading}
					tip={'Loading...'}
				>
					<h1>Welcome to DUMMI - Data U Migrate or Manage Internally</h1>
					<Row className='mb-15'>
						<Col span={24}>
							<Form>
								<Form.Item>
									<TextArea
										rows={3}
										placeholder={'Enter Your DUMMI url'}
										value={dummiUrl ?? ''}
										onChange={(e: any) => {
											setDummiUrl(String(e.target.value));
										}}
									/>
								</Form.Item>
								<Form.Item>
									<Button type='primary' onClick={onSubmitClicked}>Submit</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
					<Row className='mb-15'>
						<Col span={24}>
							<pre>
								{dummiResponse}
							</pre>
						</Col>
					</Row>
				</Spin>
			) : (
				<span onClick={() => {
					setShow(show + 1);
				}}>&nbsp;</span>
			)}
		</DefaultLayout>
	);
};
