import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {changePage} from './actions';
import {Col, Menu, Row, Typography} from 'antd';
import type Types from 'MyTypes';
import {ViewRaisedWorkOrderQueries} from './ViewRaisedWorkOrderQueries';
import {ViewAllWorkOrderQueries} from './ViewAllWorkOrderQueries';
import {ViewWorkOrderQueryForm} from './ViewWorkOrderQueryForm';

const pathType = {
	new: 'new',
	raised: 'raised',
	all: 'all',
};

export const ViewWorkOrderQuery: React.FC = () => {
	const dispatch = useDispatch();
	const {pageType} = useSelector((state: Types.RootState) => state.workOrderQuery);
	const selectedPage = pageType ?? pathType.new;

	return (
		<DefaultLayout currentPath={uiPaths.workOrderQuery}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>Work Order Queries</Typography.Title>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(changePage(e.key))}
						selectedKeys={[selectedPage]}
						mode='horizontal'
					>
						<Menu.Item id={pathType.new} key={pathType.new}>
							Raise New Query
						</Menu.Item>
						<Menu.Item id={pathType.raised} key={pathType.raised}>
							Raised by Me
						</Menu.Item>
						<Menu.Item id={pathType.all} key={pathType.all}>
							All Queries
						</Menu.Item>
					</Menu>
				</Col>
			</Row>

			<Row>
				<Col span={24}><br /></Col>
			</Row>

			<Row>
				<Col span={24}>
					{selectedPage === pathType.new ? (
						<ViewWorkOrderQueryForm />
					) : []}
					{selectedPage === pathType.raised ? (
						<ViewRaisedWorkOrderQueries />
					) : []}
					{selectedPage === pathType.all ? (
						<ViewAllWorkOrderQueries />
					) : []}
				</Col>
			</Row>

			<br />
			<br />
			<br />
		</DefaultLayout>
	);
};
