import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useHistory} from 'react-router';
import {ViewAllMaterialQuery} from '../MaterialQuery/ViewAllMaterialQuery';

export const ViewApprovalMaterialQuery: React.FC = () => {
	const history = useHistory();

	return (
		<DefaultLayout currentPath={uiPaths.allMaterialQuery}>
			<Row>
				<Col span={24}>
					<ViewAllMaterialQuery toBeApproved={true} />
				</Col>
			</Row>
		</DefaultLayout>
	);
};
