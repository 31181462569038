import React from 'react';
import {Button, Popconfirm, Space, Table, Tooltip} from 'antd';
import {formatDateFunction, getHrefLink, toTitleCase} from 'app/helpers';
import {moduleLabelName, type User} from 'app/models';
import {
	ChangeIdType,
	ChangeInputType,
	type ChangeLog,
	ChangeLogStatus,
	type ChangeValue,
} from 'app/components/ChangeLog/change-log';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {type UserRole} from 'app/models/enums/user-role.enum';

type Prop = {
	dataSource?: ChangeLog[];
	userStake?: UserRole;
	handleApprove?: (id: number) => void;
	handleReject?: (id: number) => void;
};

export const ChangeLogTable: React.FC<Prop> = ({dataSource, userStake, handleApprove, handleReject}) => {
	const handleApproveClick = (id: number) => {
		if (handleApprove) {
			handleApprove(id);
		}
	};

	const handleRejectClick = (id: number) => {
		if (handleReject) {
			handleReject(id);
		}
	};

	return (
		<Table
			size={'small'}
			bordered={true}
			dataSource={dataSource}
			pagination={false}
			scroll={{x: 800, y: 500}}
		>
			<Table.Column
				title='Module Name'
				dataIndex='moduleName'
				key='moduleName'
				render={(moduleName: string, log: ChangeLog) => (
					<>
						{moduleName === 'USER' ? (
							<a target='_blank' href={getHrefLink('profile', log.changeModuleId)} rel='noreferrer'>
								{moduleLabelName[moduleName]}
							</a>
						) : []}
					</>
				)}
			/>
			<Table.Column
				title='Detail'
				dataIndex='changeModuleId'
				key='changeModuleId'
				render={(changeModuleId: number, log: ChangeLog) => {
					if (log.moduleName === 'USER' && log.changeModuleUser) {
						return log.changeModuleUser?.name ?? '';
					}

					return '';
				}}
			/>
			<Table.Column
				title='Label'
				dataIndex='changeValue'
				key='changeValue.label'
				render={(changeValue: ChangeValue) => changeValue.label}
			/>
			<Table.Column
				title='Value'
				dataIndex='changeValue'
				key='changeValue.value'
				render={(changeValue: ChangeValue, log: ChangeLog) => {
					if (log.changeValue?.inputType === ChangeInputType.ID) {
						if (log.changeValue?.idType === ChangeIdType.USER) {
							return log.user?.name ?? '';
						}
					}

					return changeValue.value;
				}}
			/>
			<Table.Column
				title='Raise By'
				dataIndex='createdUser'
				key='createdUser'
				render={(createdUser: User | undefined) => createdUser?.name ?? ''}
			/>
			<Table.Column
				title='Raise At'
				dataIndex='createdAt'
				key='createdAt'
				render={(createdAt: string | undefined) => formatDateFunction(createdAt ?? '')}
			/>
			<Table.Column
				title='Action'
				dataIndex='action'
				key='action'
				render={(action: string, changeLog: ChangeLog) => (
					<Space direction='vertical'>
						{toTitleCase(changeLog.status)}
						{changeLog.status === ChangeLogStatus.CANCELED && changeLog.cancelReason && (
							<small><b>Cancel Reason:</b>{' '}{changeLog.cancelReason}</small>
						)}
						{userStake && changeLog.approver === userStake
							&& (!changeLog.status || changeLog.status === ChangeLogStatus.REQUESTED) && (
							<Space>
								<Tooltip placement='topLeft' title={'Approve'}>
									<Popconfirm
										title={'Are you sure you want to approve this?'}
										onConfirm={() => {
											handleApproveClick(changeLog.id);
										}}
										okText='Yes'
										cancelText='No'
									>
										<Button type={'primary'} size={'small'}>
											<CheckOutlined />
										</Button>
									</Popconfirm>
								</Tooltip>
								<Tooltip placement='topLeft' title={'Reject'}>
									<Button type={'primary'} danger={true} size={'small'} onClick={() => {
										handleRejectClick(changeLog.id);
									}}>
										<CloseOutlined />
									</Button>
								</Tooltip>
							</Space>
						)}
					</Space>
				)}
			/>
		</Table>
	);
};
