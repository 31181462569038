import {type ProjectHandoverActivity} from 'app/models';
import React from 'react';
import {Form, Input, Card} from 'antd';
import {FieldsLabelName, Fields} from 'app/models';
import {ChildActivityForm} from './ChildActivityForm';
import {formatDateFunction} from 'app/helpers';

type Props = {
	activity: ProjectHandoverActivity;
	onChange: (activity: ProjectHandoverActivity) => void;
};

export const ActivityForm: React.FC<Props> = ({activity, onChange}) => {
	const onChildActivityChange = (
		id: string,
		value: ProjectHandoverActivity,
	) => {
		const children = activity.children ? [...activity.children] : [];
		const ix = children.findIndex(i => i.id === id);
		children[ix] = value;
		onChange({...activity, ...{children}});
	};

	const dateLabel = (FieldsLabelName[Fields[activity.activityName] as string] as string)
		.toLowerCase()
		.includes('date');

	return (
		<>
			<Card
				title={<b>{FieldsLabelName[Fields[activity.activityName]]}</b>}
				style={{marginBottom: 10}}
			>
				<Form.Item
					name={dateLabel ? 'date' : 'value'}
					label={dateLabel ? 'Date' : 'Value'}
				>
					{dateLabel
						? activity.timelineDate
              && formatDateFunction(activity.timelineDate, false)
						: activity.noOfDays && activity.noOfDays}
				</Form.Item>
				<Form.Item name={activity.id} label='Remarks'>
					<Input.TextArea
						placeholder={'Enter Remarks'}
						value={activity.remarks ?? ''}
						defaultValue={activity.remarks ?? ''}
						onChange={(e: any) => {
							onChange({
								...activity,
								...{remarks: String(e.target.value)},
							} as ProjectHandoverActivity);
						}
						}
					/>
				</Form.Item>
				{activity.children?.length
					? activity.children.map((child, ix) => (
						<ChildActivityForm
							key={ix}
							child={child}
							onChange={(value: ProjectHandoverActivity) => {
								onChildActivityChange(child.id, value);
							}
							}
						/>
					))
					: []}
			</Card>
		</>
	);
};
