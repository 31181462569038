import React from 'react';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {useDispatch, useSelector} from 'react-redux';
import {ModuleName, ModulePermission} from 'app/models';
import {type MaterialOrderBudget} from 'app/models/material-order-budget.model';
import {getPermissionSites, equalNum, formatDateFunction} from 'app/helpers';
import {get as getTabs} from '../ProjectBudgetQuantities/actions';
import {get, setFilterValue} from './actions';
import {
	Col,
	Row,
	Spin,
	Table,
	Typography,
	message,
} from 'antd';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {Link} from 'react-router-dom';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {getQueryDateRange, getQueryDateRangeForDays} from 'app/query_helpers';
import {type MaterialOrderDailyQuantity} from 'app/models/material-order-daily-quantity.model';

type DataSourceType = {
	id: number;
	title: string;
	budgetQuantity: number | undefined;
	measuringUnit: string;
};

export const ViewMaterialOrderDailyQuantities: React.FC = () => {
	const dispatch = useDispatch();
	const {
		byIds: tabByIds,
		allIds: tabAllIds,
	} = useSelector((state: Types.RootState) => state.materialOrderBudget);
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {loading, byIds, allIds, dataUpdated, errorMessage, filterValue}
    = useSelector((state: Types.RootState) => state.materialOrderDailyQuantity);
	const readSites = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_ORDER_DAILY_QUANTITY,
		ModulePermission.READ,
		allSites,
	);
	const writeSites = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_ORDER_DAILY_QUANTITY,
		ModulePermission.WRITE,
		allSites,
	);
	const siteOptions: FilterOptionItem[] = readSites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));
	const today = new Date();
	const canWrite = Boolean(writeSites.find(s =>
		equalNum(s.id, String(filterValue?.siteId)),
	));
	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewMaterialOrderDailyQuantities.componentDidMount');
		dispatch(getTabs({}));
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			dispatch(get({}));
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}) => {
		if (!f.siteId) {
			return;
		}

		const filter: any = {
			where: {},
			order: ['createdAt DESC'],
		};

		filter.where = {
			siteId: f.siteId,
		};
		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'orderDate');
		} else {
			filter.where.and = getQueryDateRangeForDays(
				today.toISOString().split('T')[0],
				-1,
				7,
				'orderDate',
			);
		}

		dispatch(setFilterValue(f));
		// Console.log(filter);
		dispatch(get(filter));
	};

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
	];

	const masterEntries: MaterialOrderBudget[] = tabAllIds
		.map(id => tabByIds[id])
		.filter(item => item.parentId);

	const items: MaterialOrderDailyQuantity[] = allIds.map(id => byIds[id]);

	const dataSource: DataSourceType[] = [];

	const itemsMap = {};

	items.forEach(entry => {
		if (!itemsMap[entry.materialOrderBudgetId]) {
			itemsMap[entry.materialOrderBudgetId] = {};
		}

		itemsMap[entry.materialOrderBudgetId][
			formatDateFunction(entry.orderDate, false)
		] = entry.orderQuantity;
	});

	masterEntries.forEach(entry => {
		dataSource.push({
			id: entry.id,
			title: entry.title,
			budgetQuantity: entry.budgetQuantity ?? undefined,
			measuringUnit: entry.measuringUnit ?? '',
		});
	});

	console.log(' i m dataSource', dataSource);

	const dates: string[] = [];
	if (filterValue.date1 && filterValue.date2) {
		const startDate = new Date(filterValue.date1 as string);
		const endDate = new Date(filterValue.date2 as string);
		console.log(startDate, endDate);
		// eslint-disable-next-line no-unmodified-loop-condition
		for (let day = endDate; day >= startDate; day.setDate(day.getDate() - 1)) {
			dates.push(formatDateFunction(new Date(day).toDateString(), false));
		}
	} else {
		const currentDate = new Date();
		const startDate = new Date(); // Today
		const endDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
		// eslint-disable-next-line no-unmodified-loop-condition
		for (let day = startDate; day >= endDate; day.setDate(day.getDate() - 1)) {
			dates.push(formatDateFunction(new Date(day).toDateString(), false));
		}
	}

	return (
		<DefaultLayout currentPath={uiPaths.materialOrderDailyQuantity}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3}>
              Daily Site Work Tracking
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							submitOnChange={true}
							onSubmit={f => {
								filterData(f);
							}}
						/>
						<br />
					</Col>
				</Row>
				{filterValue.siteId ? (
					<Row>
						<Col span={24}>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
								scroll={{x: 2000, y: 1000}}
							>
								<Table.Column
									title='Work Type'
									fixed='left'
									dataIndex='title'
									key='title'
									width='10%'
									render={(title: string, materialOrderDailyQuantity: DataSourceType) =>
										canWrite ? (
											<Link
												to={{
													pathname: `/${uiPaths.materialOrderDailyQuantityForm.replace(
														':siteId',
														String(filterValue.siteId),
													).replace(
														':masterId',
														String(materialOrderDailyQuantity.id),
													)}`,
												}}
											>
												{title}
											</Link>
										) : (
											{title}
										)
									}
								/>
								<Table.Column
									title='Measuring Unit'
									dataIndex='measuringUnit'
									key='measuringUnit'
									fixed='left'
									width='5%'
								/>
								<Table.Column
									title='Project Quantity'
									dataIndex='budgetQuantity'
									key='budgetQuantity'
									fixed='left'
									width='5%'
								/>
								{dates.map(entry => (
									<Table.Column
										title={entry}
										dataIndex='id'
										key='id'
										render={id =>
											// eslint-disable-next-line @typescript-eslint/no-unsafe-return
											itemsMap[id]?.[entry]
												? itemsMap[id][entry]
												: 'No data'
										}
										width={120}
									/>
								))}
							</Table>
						</Col>
					</Row>
				) : (
					[]
				)}
			</Spin>
		</DefaultLayout>
	);
};
