import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {Link} from 'react-router-dom';
import {getCurrencyString, hasPermission, parseNum} from 'app/helpers';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {Button, Col, message, Popconfirm, Row, Space, Spin, Table} from 'antd';
import {ModuleName, ModulePermission, type PrithuWallet, type Site, type User} from 'app/models';
import {get, remove} from './actions';
import {FilterFormType, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';

type OwnProps = {
	sites: Site[];
};

const mapStateToProps = ({
	prithuWallet: {wallets, dataUpdated, errorMessage, loading},
	summary: {userOptions},
	userPermission: {modulePermissionMap: permissions},
}: Types.RootState, ownProps: OwnProps) => ({
	wallets,
	dataUpdated,
	errorMessage,
	loading,
	userOptions,
	permissions,
	...ownProps,
});

const mapDispatchToProps = {
	remove,
	get,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

type State = {
	filterValue: FilterDataType;
};

class ViewAllWallet extends React.Component<Props, State> {
	public canWrite: boolean;

	constructor(props: Props) {
		super(props);

		const {permissions} = this.props;
		this.canWrite = hasPermission(permissions[ModuleName.EXPENSE_REPORT], ModulePermission.WRITE);

		this.state = {
			filterValue: {},
		};
	}

	componentDidMount() {
		this.filterData();
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			this.filterData();
		}

		if (nextProps.errorMessage) {
			void message.error(nextProps.errorMessage);
		}
	}

	filterData = () => {
		this.props.get({});
	};

	handleRemoveClick = (id: number) => {
		this.props.remove(id);
	};

	getWalletName = (wallet: PrithuWallet) => wallet.user?.name ?? 'Unknown';

	getWalletOwnerName = (wallet: PrithuWallet) => wallet.user?.name ?? '';

	render() {
		const {filterValue} = this.state;
		const {loading, wallets} = this.props;

		const uiFilters: UiFilter[] = [
			{
				filterKey: 'userId',
				items: this.props.userOptions,
				formType: FilterFormType.SELECT,
				formWidth: 240,
				placeholder: 'Select User',
				label: 'User',
				defaultValue: filterValue?.userId,
			},
		];

		const dataSource = wallets && wallets.length > 0 ? wallets.filter((wallet: PrithuWallet) =>
			!filterValue.userId || parseNum(filterValue.userId as string) === wallet.userId,
		) : [];

		return (
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							submitOnChange={true}
							onSubmit={(filter: FilterDataType) => {
								this.setState({filterValue: filter});
							}}
						/>
						<br />
					</Col>
				</Row>

				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						{this.canWrite && (
							<Link to={{pathname: `/${uiPaths.createWallet}`}}>
								<Button type={'primary'}>Add New Wallet</Button>
							</Link>
						)}
						<br />
						<br />
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Table
							size={'small'}
							bordered={true}
							dataSource={dataSource}
							pagination={false}
						>
							<Table.Column
								title='User'
								dataIndex='user'
								key='user'
								render={(user: User | undefined) => user?.name ?? 'n/a'}
							/>
							<Table.Column
								title='Limit'
								dataIndex='limit'
								key='limit'
								render={(limit: number) => getCurrencyString(limit)}
							/>
							<Table.Column
								title='Current Balance'
								dataIndex='balance'
								key='balance'
								render={(balance: number) => getCurrencyString(balance)}
							/>
							<Table.Column
								title='Action'
								dataIndex='action'
								key='action'
								render={(text: string, wallet: PrithuWallet) => (
									<Space>
										{this.canWrite ? (
											<Link
												to={{
													pathname: `/${uiPaths.addWalletMoney.replace(':walletId', String(wallet.id))}`,
													state: {
														walletName: this.getWalletName(wallet),
														walletLimit: wallet.limit,
													},
												}}
											>
												<Button size={'small'} type='primary'>
													Add Money
												</Button>
											</Link>
										) : []}
										{this.canWrite ? (
											<Link
												to={{
													pathname: `/${uiPaths.editWallet.replace(':walletId', String(wallet.id))}`,
													state: {
														walletLimit: wallet.limit,
													},
												}}
											>
												<Button size={'small'} type='primary'>
													Edit Wallet
												</Button>
											</Link>
										) : []}
										{this.canWrite ? (
											<Popconfirm title={'Are you sure you want to remove this wallet!'} onConfirm={e => {
												this.handleRemoveClick(wallet.id);
											}}>
												<Button size={'small'} type='primary' danger={true}>
													Delete Wallet
												</Button>
											</Popconfirm>
										) : []}
									</Space>
								)}
							/>
						</Table>
					</Col>
				</Row>
			</Spin>
		);
	}
}

export default connector(ViewAllWallet);
