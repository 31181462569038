import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type ChangeLog} from 'app/components/ChangeLog/change-log';
import {type AnyAction} from 'redux';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<ChangeLog['id'], ChangeLog>;
	allIds: Array<ChangeLog['id']>;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
export default function changeLog(state: MyState = loadState('changeLog', initialState), action: AnyAction): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as Record<string, string>,
			};
		case actionTypes.paginationChange:
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return {...state, ...action.payload};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getCountApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				loading: true,
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getCountApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				loading: false,
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload?.data ? action.payload?.data as ChangeLog[] : [];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: Number(action.payload.data.count),
					byIds: {},
					allIds: [],
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
}
