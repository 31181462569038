import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	initPage: '@drawingSchedule/INIT_PAGE',
	apiFilter: '@drawingSchedule/API_FILTER',
	getApi: '@drawingSchedule/GET_API',
	getSingleApi: '@drawingSchedule/GET_SINGLE_API',
	createGetApi: '@drawingSchedule/CREATE_GET_API',
	updateApi: '@drawingSchedule/UPDATE_API',
	uploadDrawingApi: '@drawingSchedule/UPLOAD_DRAWING_API',
	removeDrawingApi: '@drawingSchedule/REMOVE_DRAWING_API',
};

const url = lbBaseUrl + '/drawing-schedules';
const drawingsUrl = lbBaseUrl + '/drawings';

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const setFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: value,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const createAndGet = (data: any) => ({
	type: actionTypes.createGetApi,
	payload: axios.post(url, data, getHeaders(true)),
	meta: {process: true},
});

export const getById = (id: number) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const saveDrawings = (id: number, data: any): any => ({
	type: actionTypes.uploadDrawingApi,
	payload: axios.post(`${url}/${id}/save-drawings`, data, getHeaders(true)),
	meta: {process: true},
});

export const removeDrawing = (id: number): any => ({
	type: actionTypes.removeDrawingApi,
	payload: axios.delete(`${drawingsUrl}/${id}`, getHeaders(true)),
	meta: {process: true},
});
