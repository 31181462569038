import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	message,
	Row,
	Select,
	Spin,
	Switch,
	Tag,
	Typography,
} from 'antd';
import {parentTab, uiPaths} from 'app/constants';
import {get, getById, create, search, update, getAllWorkActivity} from './actions';
import {get as getAllChecklist} from 'app/components/ProcessChecklist/actions';
import {useHistory, useParams} from 'react-router';
import {
	DateFetchType,
	DateType,
	FormulaType,
	OperatorType,
	type ProjectProcessMaster,
	ProjectProcessType,
} from 'app/components/ProjectProcessMaster/project-process-master';
import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {PlusOutlined} from '@ant-design/icons';
import {ProcessChecklistSelect} from 'app/components/ProjectProcessMaster/ProcessChecklistSelect';
import {ViewParseLink} from 'app/components/Common/ViewParseLink';
import {parseNum, toTitleCase} from 'app/helpers';
import {ModuleName} from 'app/models';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {DebounceSelect} from '../Common/DebounceSelect';
import type Types from 'MyTypes';

type MyParams = {
	id?: string;
	type?: string;
};

const getProcessMasterOption = (master: ProjectProcessMaster) => ({
	label: master?.processName ?? '',
	value: String(master?.id ?? ''),
});

// eslint-disable-next-line complexity
export const ViewProjectProcessMasterForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();

	const {loading, byIds, allIds, activityMasters, dataUpdated, errorMessage}
		= useSelector((state: Types.RootState) => state.projectProcessMaster);
	const {
		byIds: checklistByIds,
		allIds: allChecklistIds,
		loading: checklistLoading,
	} = useSelector((state: Types.RootState) => state.processChecklist);

	const id = parseNum(params?.id);
	const type = params?.type ? (params.type as ProjectProcessType) : undefined;
	const projectProcessMaster = byIds[id]
		? (byIds[id])
		: undefined;
	const projectProcessType = projectProcessMaster?.projectProcessType ?? type;

	const [processName, setProcessName] = React.useState(
		projectProcessMaster?.processName ?? '',
	);
	const [activityName, setActivityName] = React.useState(
		projectProcessMaster?.activityName ?? '',
	);
	const [floorName, setFloorName] = React.useState(
		projectProcessMaster?.floorName ?? '',
	);
	const [projectProcessMasterIds, setProjectProcessMasterIds] = React.useState(
		projectProcessMaster?.projectProcessMasterIds ?? undefined,
	);
	const [parentId, setParentId] = React.useState(
		projectProcessMaster?.parentId ?? undefined,
	);
	const [workActivityMasterId, setWorkActivityMasterId] = React.useState(
		projectProcessMaster?.workActivityMasterId ?? undefined,
	);
	const [secondaryWorkActivityMasterIds, setSecondaryWorkActivityMasterIds]
		= React.useState(
			projectProcessMaster?.secondaryWorkActivityMasterIds ?? undefined,
		);
	const [budgetWorkActivityMasterIds, setBudgetWorkActivityMasterIds]
		= React.useState(
			projectProcessMaster?.budgetWorkActivityMasterIds ?? undefined,
		);
	const [budgetFieldName, setBudgetFieldName] = React.useState(
		projectProcessMaster?.budgetFieldName ?? undefined,
	);
	const [sequence, setSequence] = React.useState(
		projectProcessMaster?.sequence ?? undefined,
	);
	const [description, setDescription] = React.useState(
		projectProcessMaster?.description ?? undefined,
	);
	const [beforeDays, setBeforeDays] = React.useState(
		projectProcessMaster?.beforeDays ?? undefined,
	);
	const [afterDays, setAfterDays] = React.useState(
		projectProcessMaster?.afterDays ?? undefined,
	);
	const [processType, setProcessType] = React.useState(
		projectProcessMaster?.processType ?? '',
	);
	const [processDocument, setProcessDocument] = React.useState(
		projectProcessMaster?.processDocument ?? '',
	);
	const [referenceDocuments, setReferenceDocuments] = React.useState(
		projectProcessMaster?.referenceDocuments ?? '',
	);
	const [milestoneNames, setMilestoneNames] = React.useState(
		projectProcessMaster?.milestoneNames ?? undefined,
	);
	const [showMilestoneNames, setShowMilestoneNames] = React.useState(false);
	const [processChecklists, setProcessChecklists] = React.useState(
		projectProcessMaster?.processChecklists?.length
			? [...projectProcessMaster.processChecklists]
			: [],
	);
	const [durationDays, setDurationDays] = React.useState(
		projectProcessMaster?.durationDays ?? 0,
	);
	const [dateType, setDateType] = React.useState(
		projectProcessMaster?.dateType ?? '',
	);
	const [dateFetchType, setDateFetchType] = React.useState(
		projectProcessMaster?.dateFetchType ?? '',
	);
	const [formulaType, setFormulaType] = React.useState(
		projectProcessMaster?.formulaType ?? '',
	);
	const [operatorType, setOperatorType] = React.useState(
		projectProcessMaster?.operatorType ?? '',
	);
	const [noOfDays, setNoOfDays] = React.useState(
		projectProcessMaster?.noOfDays ?? 0,
	);
	const [queryType, setQueryType] = React.useState<string>(
		ProjectProcessType.CONSTRUCTION,
	);

	const [isInternal, setIsInternal] = React.useState(
		projectProcessMaster?.isInternal ?? false,
	);

	const [processMasterValues, setProcessMasterValues] = React.useState<FilterOptionItem[]>(
		projectProcessMaster?.relationProcessMasters?.length
			? projectProcessMaster.relationProcessMasters.map(a =>
				getProcessMasterOption(a),
			)
			: [],
	);

	React.useEffect(() => {
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		// Getting the checklist for showing it in the option for adding new checklist
		let checklistFilter;
		if (projectProcessType === ProjectProcessType.CONSTRUCTION) {
			checklistFilter = {
				where: {moduleName: ModuleName.CONSTRUCTION_SCHEDULE},
			};
		} else if (projectProcessType === ProjectProcessType.DESIGN) {
			checklistFilter = {where: {moduleName: ModuleName.DRAWING_SCHEDULE}};
		} else if (projectProcessType === ProjectProcessType.APPROVALS) {
			checklistFilter = {where: {moduleName: ModuleName.APPROVAL_SCHEDULE}};
		}

		if (checklistFilter) {
			dispatch(getAllChecklist(checklistFilter));
		}

		// Getting the Google sheet activities for showing it in the option for mapping the Google work activity master.
		dispatch(getAllWorkActivity({}));
		// Getting all the master for parent option.
		// We don't need to get all the activities
		// we need to call a separate api and store in separate
		// redux as this conflict with get by id redux store.
		dispatch(get({where: {projectProcessType: type}}));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			if (id) {
				void message.success('Updated Successfully.');
				dispatch(getById(id));
			} else {
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onChecklistChange = (i: number, value?: ProcessChecklist) => {
		if (!value) {
			return;
		}

		const items = [...processChecklists];
		items[i] = value;
		setProcessChecklists(items);
	};

	const onChecklistAdd = () => {
		const items = [...processChecklists];
		items.push({} as ProcessChecklist);
		setProcessChecklists(items);
	};

	const onChecklistRemove = (i: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to remove')) {
			const items = [...processChecklists];
			items.splice(i, 1);
			setProcessChecklists(items);
		}
	};

	const onDateFetchTypeChange = (value: DateFetchType) => {
		setDateFetchType(value);
		if (value === DateFetchType.ACTUAL) {
			setFormulaType(FormulaType.MAX);
		} else {
			setFormulaType('');
		}
	};

	const onSubmitClicked = () => {
		const data: any = {
			projectProcessType,
			processName,
			activityName,
			floorName,
			projectProcessMasterIds,
			parentId,
			workActivityMasterId,
			secondaryWorkActivityMasterIds,
			budgetWorkActivityMasterIds,
			budgetFieldName,
			sequence,
			description,
			beforeDays,
			afterDays,
			processType,
			processDocument,
			referenceDocuments,
			milestoneNames,
			showMilestoneNames,
			processChecklists,
			dateType,
			formulaType,
			operatorType,
			noOfDays,
			durationDays,
			dateFetchType,
			isInternal,
			relatedIds: processMasterValues?.length
				? processMasterValues.map(opt => parseNum(opt.value))
				: [],
		};

		if (!data.processName) {
			void message.error('Please enter a title!');
			return;
		}

		if (!data.projectProcessType) {
			void message.error('Project Process Type Missing!');
			return;
		}

		if (!data.afterDays) {
			delete data.afterDays;
		}

		if (!data.beforeDays) {
			delete data.beforeDays;
		}
		// C data.afterDays = data.afterDays ?? undefined;
		// data.beforeDays = data.beforeDays ?? undefined;

		delete data.showMilestoneNames;

		if (projectProcessMaster?.id) {
			dispatch(update(projectProcessMaster.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const allChecklist = allChecklistIds.map(id => checklistByIds[id]);
	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	const parentTabOptions = projectProcessType === ProjectProcessType.DESIGN ? allIds
		.filter(
			id =>
				byIds[id].projectProcessType === ProjectProcessType.DESIGN
				&& (byIds[id].processName === parentTab.drawings || byIds[id].processName === parentTab.quantity || byIds[id].processName === parentTab.designProgressTracker)
				&& !byIds[id].parentId,
		)
		.map((id: number) => ({
			value: String(id),
			label: byIds[id].processName,
		})) : allIds
		.filter(
			id =>
				byIds[id].projectProcessType === ProjectProcessType.WARRANTY
				&& !byIds[id].parentId,
		)
		.map((id: number) => ({
			value: String(id),
			label: byIds[id].processName,
		}));
	parentTabOptions.push({value: '', label: 'None'});

	const constructionMasterOptions = allIds
		.filter(
			id => byIds[id].projectProcessType === ProjectProcessType.CONSTRUCTION,
		)
		.map((id: number) => ({
			value: String(id),
			label: byIds[id].processName,
		}));
	constructionMasterOptions.push({value: '', label: 'None'});

	const activityMasterOptions = activityMasters
		? activityMasters
			.filter(item => !item.hasBudget)
			.map(item => ({value: String(item.id), label: item.title}))
		: [];
	activityMasterOptions.push({value: '', label: 'None'});
	const budgetActivityMasterOptions = activityMasters
		? activityMasters
			.filter(item => item.hasBudget)
			.map(item => ({value: String(item.id), label: item.title}))
		: [];
	budgetActivityMasterOptions.push({value: '', label: 'None'});

	const warrantyOptions = Array.from({length: 16}, (_, index) => ({
		value: index * 365,
		label: `${index} year${index === 1 ? '' : 's'}`,
	}));

	return (
		<DefaultLayout currentPath={uiPaths.createProjectProcessMaster}>
			<Spin
				size='large'
				spinning={checklistLoading ?? loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Project Process Master Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
						<Form {...layout} name='basic'>
							<Form.Item label='Project Process Type'>
								{toTitleCase(projectProcessType, '_')}
							</Form.Item>
							{projectProcessType === ProjectProcessType.DESIGN || projectProcessType === ProjectProcessType.WARRANTY ? (
								<Form.Item label='Parent Tab (If Any)' name='parentId'>
									<Select
										showSearch={true}
										optionFilterProp='children'
										placeholder='Select a Parent Tab'
										defaultValue={
											parentId
												? parentTabOptions.find(
													opt => parseNum(opt.value) === parentId,
												)?.label
												: ''
										}
										onChange={value => {
											setParentId(parseNum(value));
										}}
										// C filterOption={(input, option) => String(option?.label).includes(input)}
									>
										{parentTabOptions.map((item: FilterOptionItem, ix: number) => (
											<Select.Option key={ix} value={item.value}>
												{item.label}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							) : (
								[]
							)}
							<Form.Item name='sequence' label='Sequence'>
								<Input
									defaultValue={sequence}
									onChange={e => {
										setSequence(parseFloat(String(e.target.value)));
									}}
								/>
							</Form.Item>
							<Form.Item
								label='Process Name'
								rules={[{required: true, message: 'Please Enter Name'}]}
							>
								<Input
									defaultValue={processName}
									value={processName}
									onChange={(e: any) => {
										setProcessName(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item name='description' label='Description'>
								<Input.TextArea
									value={description ?? ''}
									onChange={e => {
										setDescription(String(e.target.value));
									}}
								/>
							</Form.Item>

							{projectProcessType === ProjectProcessType.WARRANTY_PERIODS ? (
								<Form.Item name='durationDays' label='Warranty Period'>
									<Select
										defaultValue={durationDays}
										onChange={value => {
											setDurationDays(value);
										}}
										options={warrantyOptions}
									/>
								</Form.Item>
							) : []}

							{projectProcessType === ProjectProcessType.DESIGN ? (
								<Form.Item
									label={'Internal only'}
									help={'Making this internal only will make it visible to employees only.'}
								>
									<Switch
										checked={isInternal}
										onChange={checked => {
											setIsInternal(checked);
										}}
									/>
								</Form.Item>
							) : []}
							{projectProcessType === ProjectProcessType.WARRANTY ? [] : (
								<>
									<Form.Item
										label='Google Sheet Activity Name'
									>
										<Input
											defaultValue={activityName}
											value={activityName}
											onChange={(e: any) => {
												setActivityName(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item
										label='Google Sheet Floor Name'
									>
										<Input
											defaultValue={floorName}
											value={floorName}
											onChange={(e: any) => {
												setFloorName(String(e.target.value));
											}}
										/>
									</Form.Item>
									<Form.Item
										label='Work Activity Master'
										name='workActivityMasterId'
									>
										<Select
											showSearch={true}
											optionFilterProp='children'
											placeholder='Select a Work Activity Master'
											defaultValue={
												workActivityMasterId
													? activityMasterOptions.find(
														opt => parseNum(opt.value) === workActivityMasterId,
													)?.label
													: ''
											}
											onChange={value => {
												setWorkActivityMasterId(parseNum(value));
											}}
										>
											{activityMasterOptions.map((item: FilterOptionItem, ix: number) => (
												<Select.Option key={ix} value={item.value}>
													{item.label}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										label='Work Activity Master (Secondary)'
										name='secondaryWorkActivityMasterIds'
										extra={
											'If date is not found for Work Activity Master then it will pick from on of these Work Activity Master'
										}
									>
										<Select
											showSearch={true}
											mode={'multiple'}
											optionFilterProp='children'
											placeholder='Select a Work Activity Master'
											defaultValue={
												secondaryWorkActivityMasterIds
													? secondaryWorkActivityMasterIds.split(',')
													: []
											}
											onChange={value => {
												setSecondaryWorkActivityMasterIds(
													value ? value.toString() : undefined,
												);
											}
											}
										>
											{activityMasterOptions.map((item: FilterOptionItem, ix: number) => (
												<Select.Option key={ix} value={item.value}>
													{item.label}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									{projectProcessType === ProjectProcessType.ORDER ? (
										<>
											<Form.Item
												label='Budget Activity Master'
												name='budgetWorkActivityMasterIds'
												extra={
													projectProcessMaster?.budgetFieldName
														? `Previously budget activity name : ${projectProcessMaster?.budgetFieldName}`
														: undefined
												}
											>
												<Select
													showSearch={true}
													mode={'multiple'}
													optionFilterProp='children'
													placeholder='Select a Budget Activity Master'
													defaultValue={
														budgetWorkActivityMasterIds
															? budgetWorkActivityMasterIds.split(',')
															: []
													}
													onChange={value => {
														setBudgetWorkActivityMasterIds(
															value ? value.toString() : undefined,
														);
													}
													}
												>
													{budgetActivityMasterOptions.map(
														(item: FilterOptionItem, ix: number) => (
															<Select.Option key={ix} value={item.value}>
																{item.label}
															</Select.Option>
														),
													)}
												</Select>
											</Form.Item>
											<Form.Item label='Milestone Names (comma separated eg. a, b, c)'>
												<Input.TextArea
													defaultValue={milestoneNames}
													value={milestoneNames}
													onChange={e => {
														setMilestoneNames(String(e.target.value));
													}}
												/>
												<Button
													className={'mt-10'}
													size={'small'}
													type={'primary'}
													onClick={() => {
														setShowMilestoneNames(!showMilestoneNames);
													}}
												>
													{showMilestoneNames ? 'Hide' : 'Show'} Preview
												</Button>
											</Form.Item>
											{showMilestoneNames ? (
												<Form.Item>
													{milestoneNames?.split(',').map((name, ix) => <Tag key={ix}>{name.trim()}</Tag>)}
												</Form.Item>
											) : (
												[]
											)}
										</>
									) : (
										[]
									)}
									<Form.Item
										name='beforeDays'
										label='Calculate Schedule Date Before Days'
										extra={
											'Schedule start and end date will be calculated by subtracting this no days.'
										}
									>
										<Input
											type={'number'}
											defaultValue={beforeDays}
											onChange={(e: any) => {
												setBeforeDays(parseNum(e.target.value as string));
											}}
										/>
									</Form.Item>
									<Form.Item
										name='afterDays'
										label='Calculate Schedule Date After Days'
										extra={
											'Schedule start and end date will be calculated by adding this no days.'
										}
									>
										<Input
											type={'number'}
											defaultValue={afterDays}
											onChange={(e: any) => {
												setAfterDays(parseNum(e.target.value as string));
											}}
										/>
									</Form.Item>
									<Form.Item label='Process Document Link'>
										<Input
											defaultValue={processDocument}
											value={processDocument}
											onChange={(e: any) => {
												setProcessDocument(String(e.target.value));
											}}
										/>
										{processDocument ? (
											<ViewParseLink parseLink={processDocument} />
										) : (
											[]
										)}
									</Form.Item>
									<Form.Item label='Reference Document Link'>
										<Input
											defaultValue={referenceDocuments}
											value={referenceDocuments}
											onChange={(e: any) => {
												setReferenceDocuments(String(e.target.value));
											}}
										/>
										{referenceDocuments ? (
											<ViewParseLink parseLink={referenceDocuments} />
										) : (
											[]
										)}
									</Form.Item>
									<Form.Item label={<b>{'Checklist'}</b>}>
										{processChecklists.map((checklist, ix) => (
											<Card
												key={ix}
												size={'small'}
												actions={[
													<Button
														key={ix}
														type={'dashed'}
														danger={true}
														onClick={() => {
															onChecklistRemove(ix);
														}}
													>
													Remove Checklist
													</Button>,
												]}
											>
												<ProcessChecklistSelect
													checklists={allChecklist}
													defaultChecklist={checklist}
													onChecklistChange={(value?: ProcessChecklist) => {
														onChecklistChange(ix, value);
													}
													}
												/>
											</Card>
										))}
										<Form.Item>
											<Button
												type='dashed'
												onClick={onChecklistAdd}
												block
												icon={<PlusOutlined />}
											>
											Add Checklist
											</Button>
										</Form.Item>
									</Form.Item>

									<Form.Item name='durationDays' label='Duration of this activity'>
										<Input
											type={'number'}
											defaultValue={durationDays}
											onChange={(e: any) => {
												setDurationDays(parseNum(e.target.value as string));
											}
											}
										/>
									</Form.Item>
									<Form.Item name='dateType' label='Fetch Start/End Date'>
										<Select
											style={{width: 100}}
											defaultValue={dateType ?? ''}
											onChange={value => {
												setDateType(value as DateType);
											}}
										>
											<Select.Option key={-1} value=''>
											Select Date Type
											</Select.Option>
											{Object.values(DateType).map((value, ix) => (
												<Select.Option key={ix} value={value}>
													{toTitleCase(value)}
												</Select.Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item
										name='dateFetchType'
										label='Based on Actual/Planed Date'
									>
										<Select
											style={{width: 100}}
											defaultValue={dateFetchType ?? ''}
											onChange={value => {
												onDateFetchTypeChange(value as DateFetchType);
											}}
										>
											<Select.Option key={-1} value=''>
											Select Date Fetched From
											</Select.Option>
											{Object.values(DateFetchType).map((value, ix) => (
												<Select.Option key={ix} value={value}>
													{toTitleCase(value)}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									{dateFetchType === String(DateFetchType.PLANED) ? (
										<Form.Item
											name='formula'
											label='Min/Max of the Below Activities'
										>
											<Select
												style={{width: 100}}
												defaultValue={formulaType ?? ''}
												onChange={value => {
													setFormulaType(value as FormulaType);
												}}
											>
												<Select.Option key={-1} value=''>
												Select Formula
												</Select.Option>
												{Object.values(FormulaType).map((value, ix) => (
													<Select.Option key={ix} value={value}>
														{toTitleCase(value)}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									) : (
										[]
									)}
									{formulaType ? (
										<Form.Item label='Activities' name='projectProcessMasterIds'>
											<Select
												defaultValue={queryType ?? ''}
												onChange={value => {
													setQueryType(value);
												}}
												style={{width: '20%'}}
											>
												<Select.Option key={-1} value=''>
												Select Process Type
												</Select.Option>
												{Object.values(ProjectProcessType).map((value, ix) => (
													<Select.Option key={ix} value={value}>
														{toTitleCase(value)}
													</Select.Option>
												))}
											</Select>
											{dateFetchType === String(DateFetchType.PLANED) ? (
												<DebounceSelect
													mode={'multiple'}
													value={processMasterValues}
													placeholder='Search Activities'
													fetchOptions={async (
														query: string,
													): Promise<FilterOptionItem[]> =>
														search(queryType, query).then(data =>
															data.data.map((item: ProjectProcessMaster) =>
																getProcessMasterOption(item),
															),
														)
													}
													onChange={newValue => {
														setProcessMasterValues(newValue as FilterOptionItem[]);
													}}
													style={{width: '70%'}}
												/>
											) : (
												<DebounceSelect
													singleSelect={true}
													value={
														processMasterValues?.length
															? processMasterValues[0]
															: undefined
													}
													placeholder='Select Activity'
													fetchOptions={async (
														query: string,
													): Promise<FilterOptionItem[]> =>
														search(queryType, query).then(data =>
															data.data.map((item: ProjectProcessMaster) =>
																getProcessMasterOption(item),
															),
														)
													}
													onChange={newValue => {
														setProcessMasterValues([newValue as FilterOptionItem]);
													}}
													style={{width: '70%'}}
												/>
											)}
										</Form.Item>
									) : (
										[]
									)}

									<Form.Item name='operatorType' label='Plus/Minus from Above Date'>
										<Input.Group compact>
											<Select
												style={{width: 100}}
												defaultValue={operatorType ?? ''}
												onChange={value => {
													setOperatorType(value as OperatorType);
												}}
											>
												<Select.Option key={-1} value=''>
												Select Operator
												</Select.Option>
												{Object.values(OperatorType).map((value, ix) => (
													<Select.Option key={ix} value={value}>
														{toTitleCase(value)}
													</Select.Option>
												))}
											</Select>
											<Button>By Number of</Button>
											<Input
												type={'number'}
												style={{width: 100}}
												defaultValue={noOfDays}
												onChange={(e: any) => {
													setNoOfDays(parseNum(String(e.target.value)));
												}}
											/>
											<Button>Days</Button>
										</Input.Group>
									</Form.Item>
								</>
							)}
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
