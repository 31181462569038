import React from 'react';
import {
	Button,
	Form,
	Input,
	Select,
	Switch,
} from 'antd';
import {parseNum, toTitleCase} from 'app/helpers';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {DebounceSelect} from '../Common/DebounceSelect';
import {type ActivityTemplateItem, ActivityType, type ActivityItem} from './activity-template-item';

type MyProps = {
	item: ActivityItem;
	onItemChange: (item: ActivityItem) => void;
	onItemSearch: (search: string) => Promise<FilterOptionItem[]>;
	onChecklistChange: (item: ActivityItem) => void;
	onChecklistSearch: (search: string) => Promise<FilterOptionItem[]>;
};

export const ActivityItemFormBlock: React.FC<MyProps> = ({item, onItemChange, onItemSearch, onChecklistChange, onChecklistSearch}) => (
	<>
		<Form.Item label='Activity Type'>
			<Select
				style={{width: '100%'}}
				defaultValue={item.activityType}
				onChange={value => {
					onItemChange({...item, ...{activityType: value as ActivityType}} as ActivityItem);
				}}
			>
				<Select.Option key={-1} value=''>
					Select Type
				</Select.Option>
				{Object.values(ActivityType).map((value, ix) => (
					<Select.Option key={ix} value={value}>
						{toTitleCase(value)}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
		<Form.Item
			label={'Is this activity the root activity'}
			help={'Making this activity will require to enter baseline date and there will be no linked activity or formula'}
		>
			<Switch
				checked={item.isRootActivity}
				onChange={checked => {
					onItemChange({...item, ...{isRootActivity: checked}} as ActivityItem);
				}}
			/>
		</Form.Item>
		<Form.Item
			name='sequence'
			label='Sequence'
			rules={[{required: true, message: 'Please Enter Sequence'}]}
		>
			<Input
				type={'number'}
				defaultValue={item.sequence}
				onChange={e => {
					onItemChange({...item, ...{sequence: parseFloat(String(e.target.value))}} as ActivityItem);
				}}
			/>
		</Form.Item>
		<Form.Item
			label='Name'
			rules={[{required: true, message: 'Please Enter Name'}]}
		>
			<Input
				defaultValue={item.name}
				value={item.name}
				onChange={(e: any) => {
					onItemChange({...item, ...{name: String(e.target.value)}} as ActivityItem);
				}}
			/>
		</Form.Item>
		<Form.Item name='duration' label='Duration of this activity'>
			<Input
				type={'number'}
				defaultValue={item.duration}
				onChange={(e: any) => {
					onItemChange({...item, ...{duration: parseNum(e.target.value as string)}} as ActivityItem);
				}
				}
			/>
		</Form.Item>
		{item.isRootActivity ? [] : (
			<>
				<Form.Item
					name='formula'
					label='Min/Max of the Below Activities'
				>
					<Select
						style={{width: 100}}
						defaultValue={item.formula}
						onChange={value => {
							onItemChange({...item, ...{formula: value as 'min' | 'max' | undefined}} as ActivityItem);
						}}
					>
						<Select.Option key={-1} value=''>
							Select Formula
						</Select.Option>
						<Select.Option key={1} value={'min'}>Min</Select.Option>
						<Select.Option key={2} value={'max'}>Max</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label='Linked Activities' name='linkedUniqueIds'>
					<Select
						defaultValue={item.queryType}
						onChange={value => {
							onItemChange({...item, ...{queryType: value as ActivityType}} as ActivityItem);
						}}
						style={{width: '20%'}}
					>
						<Select.Option key={-1} value=''>
							Select Process Type
						</Select.Option>
						{Object.values(ActivityType).map((value, ix) => (
							<Select.Option key={ix} value={value}>
								{toTitleCase(value)}
							</Select.Option>
						))}
					</Select>
					<DebounceSelect
						mode={'multiple'}
						value={item.itemValues}
						defaultValue={item.itemValues}
						placeholder='Search Activities'
						fetchOptions={onItemSearch}
						onChange={newValue => {
							onItemChange({...item, ...{itemValues: newValue as FilterOptionItem[]}} as ActivityItem);
						}}
						style={{width: '70%'}}
					/>
				</Form.Item>
				<Form.Item
					name='linkedFromType'
					label='Dates should be linked with?'
				>
					<Select
						style={{width: 200}}
						defaultValue={item.linkedFromType}
						onChange={value => {
							onItemChange({...item, ...{linkedFromType: value as 'start' | 'end' | undefined}} as ActivityItem);
						}}
					>
						<Select.Option key={-1} value=''>
							Select a Type
						</Select.Option>
						<Select.Option key={1} value={'start'}>Start Date</Select.Option>
						<Select.Option key={2} value={'end'}>End Date</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item name='operator' label='+/- from Calculated Date'>
					<Input.Group compact>
						<Select
							style={{width: 100}}
							defaultValue={item.operator ?? ''}
							onChange={value => {
								onItemChange({...item, ...{operator: value as '+' | '-' | undefined}} as ActivityItem);
							}}
						>
							<Select.Option key={-1} value=''>
								Select Operator
							</Select.Option>
							<Select.Option key={1} value={'+'}>+</Select.Option>
							<Select.Option key={1} value={'-'}>-</Select.Option>
						</Select>
						<Button>By Number of</Button>
						<Input
							type={'number'}
							style={{width: 100}}
							defaultValue={item.operandDays}
							onChange={(e: any) => {
								onItemChange({...item, ...{operandDays: parseNum(String(e.target.value))}} as ActivityItem);
							}}
						/>
						<Button>Days</Button>
					</Input.Group>
				</Form.Item>
				<Form.Item label='Linked Checklists' name='linkedChecklistIds'>
					<DebounceSelect
						mode={'multiple'}
						value={item.checklistValues}
						defaultValue={item.checklistValues}
						placeholder='Search Checklist'
						fetchOptions={onChecklistSearch}
						onChange={newValue => {
							onChecklistChange({...item, ...{checklistValues: newValue as FilterOptionItem[]}} as ActivityItem);
						}}
					/>
				</Form.Item>
			</>
		)}
	</>
);
