
import React, {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';

import {ComponentToPrint} from './ComponentToPrint';
import {Button, Empty} from 'antd';
import {useHistory, useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {getById} from './actions';

type ParamType = {
	id?: string;
};

export const PrintWorkOrderBill = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {loading, byIds} = useSelector((state: Types.RootState) => state.workOrderBill);
	const params = useParams<ParamType>();

	const billId: number = parseInt(params?.id ?? '0', 10);
	const filter: any = {
		include: [
			{relation: 'penalties'},
			{relation: 'debits'},
		],
	};

	React.useEffect(() => {
		if (billId) {
			dispatch(getById(billId, filter));
		}
	}, [billId]);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const bill = byIds[billId];

	if (!bill) {
		return (
			<Empty />
		);
	}

	return (
		<div>
			<div style={{margin: '40px', textAlign: 'right'}}>
				<Button type={'primary'} onClick={handlePrint}>Print this out!</Button>
				{' '}
				<Button onClick={history.goBack}>Back</Button>
			</div>
			<ComponentToPrint ref={componentRef} data={bill} test='test' />
		</div>
	);
};
