import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from '../constants';
import {DefaultLayout} from './Layout/DefaultLayout';
import {Spin, Typography, Card, List, Badge, Table} from 'antd';
import {loadUser} from './Common/summary-actions';
import type Types from 'MyTypes';
import {SiteStatus, ProjectType, ProjectCity, UserType} from 'app/models';
import ReactGA from 'react-ga4';

type DataType = {
	title: string;
	name: string;
	count: number;
	percent: string;
};

export const Home: React.FC = () => {
	const dispatch = useDispatch();
	const {user: authUser, loading} = useSelector((state: Types.RootState) => state.summary);
	const {allSites} = useSelector((state: Types.RootState) => state.userPermission);

	useEffect(() => {
		ReactGA.send({hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Home'});

		dispatch(loadUser());
	}, []);

	// Not considering the sites with projectCity and projectType as empty string and Testing Sites
	const validSites = allSites ? allSites.filter(item => item.projectCity && item.projectType && item.projectNumber) : [];

	// To calculate percent
	const calculatePercent = count => {
		const total = validSites.length;
		const a = (count / total) * 100;
		return `${Number(a.toFixed(0))}%`;
	};

	// To count Projects on basis of Cities
	const countCity = city => validSites.filter(item => item.projectCity === city).length;

	//  To count Projects on basis of Status
	const countStatus = status => validSites.filter(item => item.status === status).length;

	//  To count Projects on basis of Types
	const countType = type => validSites.filter(item => item.projectType === type).length;

	// Creating array of objects of Status Table
	const StatusObj: DataType[] = Object.values(SiteStatus).map(item => (
		{
			title: 'Projects By Status',
			name: item.charAt(0) + item.substring(1).toLowerCase().replace(/_/g, ' '),
			count: countStatus(item),
			percent: calculatePercent(countStatus(item)),
		}
	));

	// Creating array of objects of Project Types Table
	const ProjectTypesObj: DataType[] = Object.values(ProjectType).map(item => (
		{
			title: 'Projects By Types',
			name: item,
			count: countType(item),
			percent: calculatePercent(countType(item)),
		}
	));

	// Creating array of objects of Project Cities Table
	const ProjectCitiesObj: DataType[] = Object.values(ProjectCity).map(item => (
		{
			title: 'Projects By Cities',
			name: item,
			count: countCity(item),
			percent: calculatePercent(countCity(item)),
		}
	));

	// Data source to pass in the List Component
	const data = [ProjectTypesObj, ProjectCitiesObj, StatusObj];

	return (
		<DefaultLayout currentPath={uiPaths.home}>
			<Spin size='large' spinning={loading} tip={''}>
				{authUser?.userType === UserType.EMPLOYEE && validSites.length ? (
					<List
						grid={{
							gutter: 16,
							xs: 1,
							sm: 1,
							md: 2,
							lg: 2,
							xl: 3,
							xxl: 3,
						}}
						dataSource={data}
						renderItem={item => (
							<List.Item>
								<Badge.Ribbon text={`${validSites.length} Sites`}>
									<Card title={<Typography.Title level={5} style={{color: '#BC982F'}}>{item[0].title}</Typography.Title>}>
										<Table
											size={'small'}
											bordered={true}
											dataSource={item}
											pagination={false}
											showHeader={false}
										>
											<Table.Column
												dataIndex='name'
												key='name'
											/>
											<Table.Column
												align='center'
												dataIndex='percent'
												key='percent'
											/>
											<Table.Column
												align='center'
												dataIndex='count'
												key='count'
											/>
										</Table>
									</Card>
								</Badge.Ribbon>
							</List.Item>
						)}
					/>
				) : []}
			</Spin>
		</DefaultLayout>
	);
};
