import {type User} from './user.model';
import {type Site} from './site.model';

export type LabourAttendance = {
	id: number;
	actualCount?: number;
	requiredCount?: number;
	reportDate?: string;
	notes?: string;
	siteId: number;
	labourType: LabourType;
	createdBy?: number;
	updatedBy?: number;
	createdAt?: string;
	updatedAt?: string;
	createdUser?: User;
	site?: Site;
};

export enum LabourType {
	HANDOVER = 'HANDOVER',
	SITE_SURVEY = 'SITE_SURVEY',
	INITIAL_APPROVALS = 'INITIAL_APPROVALS',
	DEMOLITION = 'DEMOLITION',
	SITE_PREPARATION = 'SITE_PREPARATION',
	EXCAVATION = 'EXCAVATION',
	CASTING = 'CASTING',
	MINOR_CASTING = 'MINOR_CASTING',
	BRICKWORK = 'BRICKWORK',
	MINOR_BRICKWORK = 'MINOR_BRICKWORK',
	SHUTTERING = 'SHUTTERING',
	STEEL_WORK = 'STEEL_WORK',
	INTERNAL_PLASTER = 'INTERNAL_PLASTER',
	EXTERNAL_PLASTER = 'EXTERNAL_PLASTER',
	ELECTRICAL = 'ELECTRICAL',
	PLUMBING = 'PLUMBING',
	CORE_CUT = 'CORE_CUT',
	WATERPROOFING = 'WATERPROOFING',
	FABRICATION = 'FABRICATION',
	AC = 'AC',
	POP = 'POP',
	FALSE_CEILING = 'FALSE_CEILING',
	WINDOWS = 'WINDOWS',
	DOORS = 'DOORS',
	WARDROBES = 'WARDROBES',
	KITCHEN = 'KITCHEN',
	BATHROOM_TILE = 'BATHROOM_TILE',
	KITCHEN_TILE = 'KITCHEN_TILE',
	ROOM_TILE = 'ROOM_TILE',
	BALCONY_TILE = 'BALCONY_TILE',
	STONE_LAYING = 'STONE_LAYING',
	STONE_POLISHING_INITIAL_CUT = 'STONE_POLISHING_INITIAL_CUT',
	STONE_POLISHING_MAIN_POLISH = 'STONE_POLISHING_MAIN_POLISH',
	STONE_POLISHING_POWDER = 'STONE_POLISHING_POWDER',
	STONE_POLISHING_BUFFING = 'STONE_POLISHING_BUFFING',
	LIFT = 'LIFT',
	PAINT = 'PAINT',
	VANITY = 'VANITY',
	CORNICE = 'CORNICE',
	GROUTING = 'GROUTING',
	WOODWORK = 'WOODWORK',
	GLASS_WORK = 'GLASS_WORK',
	ELEVATION = 'ELEVATION',
	CLOSING_APPROVALS = 'CLOSING_APPROVALS',
	ANY_OTHER_WORK = 'ANY_OTHER_WORK',
}
