import React from 'react';

import {uiPaths} from '../../constants';
import {Alert, Col, Row} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';

export const ViewHelpAndFeedback: React.FC = () => (
	<DefaultLayout currentPath={uiPaths.viewHelpAndFeedback}>
		<Row><Col><br /><br /></Col></Row>
		<Row>
			<Col span={24} className='text-center'>
				<Alert
					message='If you have any feedback or suggestion regarding the online system,
									please do send an email to nitin.bansal@prithu.in. Your feedback or suggestions
										are a crucial part of the development of our online system.'
					type='success'
					closable={false}
				/>
			</Col>
		</Row>
		<Row><Col span={24}><hr /></Col></Row>
	</DefaultLayout>
);
