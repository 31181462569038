import React from 'react';
import {
	materialQueryStatusLabels,
	type MaterialQuery,
	ModuleName,
	ModulePermission,
	type Site,
	type User,
} from 'app/models';
import {
	formatDateFunction,
	getPermissionSites,
	parseNum,
	toSubstring,
} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {Col, Row, Spin, Tooltip, Space, Empty} from 'antd';
import {get, setFilterValue, onPaginationChange, count} from './actions';
import {getQueryDateRange} from 'app/query_helpers';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {CheckCircleOutlined} from '@ant-design/icons';
import {Table} from 'antd';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import type Types from 'MyTypes';
import {type Category} from '../Category/category';

export const ViewMaterialQueryPendingForApproval: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {
		allIds,
		byIds,
		loading,
		totalCount,
		pendingForApprovalCurrentPosPrefix,
		pendingForApprovalPerPageSizePrefix,
		filterValue,
	} = useSelector((state: Types.RootState) => state.materialQuery);

	const sites = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_QUERY,
		ModulePermission.READ,
		allSites,
	);
	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {
				createdBy: authUser?.id,
			},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
		};

		// eslint-disable-next-line no-constant-binary-expression
		filter.where.nextApprovedBy = {neq: undefined ?? null};

		if (f.id) {
			filter.where.id = parseNum(String(f.id));
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(String(f.siteId));
		} else {
			filter.where.siteId = {inq: sites.map((site: Site) => site.id)};
		}

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt');
		}

		dispatch(setFilterValue(f));
		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	React.useEffect(() => {
		if (filterValue?.where) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	const queries: MaterialQuery[] = allIds.map((id: number) => byIds[id]);
	const uiFilters: UiFilter[] = [
		{
			filterKey: 'id',
			formType: FilterFormType.TEXT,
			label: 'Id',
			defaultValue: filterValue?.id,
		},
		{
			filterKey: 'date',
			items: [],
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
		},
	];

	if (siteOptions.length > 1) {
		uiFilters.push({
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			formWidth: 240,
			placeholder: 'Select Site',
			label: 'Site',
		});
	}

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={f => {
						filterData(f);
					}} />
					<br />
				</Col>
			</Row>
			{totalCount ? (
				<Row>
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={pendingForApprovalCurrentPosPrefix ?? 1}
							perPageSize={pendingForApprovalPerPageSizePrefix ?? 10}
							filterValue={filterValue}
							filterData={filterData}
							onPaginationChange={onPaginationChange}
						/>
						<Table
							size={'small'}
							dataSource={queries}
							pagination={false}
							scroll={{x: 500, y: 600}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.materialQueryDetail.replace(
											':queryId',
											String(query.id),
										)}`,
									});
								},
							})}
						>
							<Table.Column
								title='Id'
								dataIndex='id'
								key='id'
							/>
							<Table.Column
								title='Raised At'
								dataIndex='createdAt'
								key='createdAt'
								render={(createdAt: string | undefined) =>
									formatDateFunction(createdAt ?? '')
								}
							/>
							<Table.Column
								title='Approved At'
								dataIndex='approvedAt'
								key='approvedAt'
								render={(approvedAt: string | undefined) =>
									formatDateFunction(approvedAt ?? '')
								}
							/>
							<Table.Column
								title='Site'
								dataIndex='site'
								key='site'
								render={(site: Site | undefined) => site?.name ?? ''}
							/>
							<Table.Column
								title='Query Point'
								dataIndex='description'
								key='description'
								render={(description: string) => toSubstring(description, 80, '...')}
							/>
							<Table.Column
								title='Debited to Contractor'
								dataIndex='debitedToContractor'
								key='debitedToContractor'
								render={(val: boolean, query: MaterialQuery) =>
									val ? (
										<Space>
                      Yes
											{query.debitApproved ? (
												<Tooltip title='This debit is approved.'>
													<CheckCircleOutlined />
												</Tooltip>
											) : (
												[]
											)}
										</Space>
									) : (
										'No'
									)
								}
							/>
							<Table.Column
								title='Status'
								dataIndex='status'
								key='status'
								render={(status: string) =>
									status ? materialQueryStatusLabels[status] : ''
								}
							/>
							<Table.Column
								title='Category'
								dataIndex='category'
								key='category'
								render={(category: Category | undefined) =>
									category?.name ?? ''
								}
							/>
							<Table.Column
								title='Sub Category'
								dataIndex='subCategory'
								key='subCategory'
								render={(subCategory: Category | undefined) =>
									subCategory?.name ?? ''
								}
							/>
							<Table.Column
								title='Raise By'
								dataIndex='createdUser'
								key='createdUser'
								render={(createdUser: User | undefined) =>
									createdUser?.name ?? ''
								}
							/>
							<Table.Column
								title='Approved By'
								dataIndex='approvedUser'
								key='approvedUser'
								render={(approvedUser: User | undefined) =>
									approvedUser?.name ?? ''
								}
							/>
						</Table>
					</Col>
				</Row>
			) : (
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			)}
		</Spin>
	);
};
