import {Button, Card, Select, Space} from 'antd';
import {getWarrantyPeriodCoverageLabel, type Site, WarrantyPeriodCoverageType} from 'app/models';
import React, {useState} from 'react';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {useDispatch} from 'react-redux';
import {update} from '../actions';

type Props = {
	canEdit: boolean;
	siteId: number;
	currentValue?: string;
};

export const EditWarrantyPeriodCoverageForm: React.FC<Props> = ({canEdit, siteId, currentValue}) => {
	const warrantyOptions = Object.values(WarrantyPeriodCoverageType).reduce<FilterOptionItem[]>((options, warranty) => {
		for (let i = 1; i <= 10; i++) {
			const value = warranty + '-' + i;
			options.push({
				value,
				label: getWarrantyPeriodCoverageLabel(value),
			});
		}

		return options;
	}, []);

	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [warrantyPeriodCoverage, setWarrantyPeriodCoverage] = useState<string[]>(currentValue ? currentValue.split(', ') : []);

	const handleSubmit = () => {
		const data: Partial<Site> = {
			warrantyPeriodCoverage: warrantyPeriodCoverage.join(', '),
		};
		dispatch(update(siteId, data));
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Card>
				<Space wrap={true}>
					<Select
						id = {'SelectWarrantyPeriodCoverage'}
						mode={'multiple'}
						style={{width: 250}}
						placeholder='Select Warranty Period Coverage'
						optionFilterProp='children'
						value={warrantyPeriodCoverage}
						defaultValue={warrantyPeriodCoverage}
						onChange={value => {
							setWarrantyPeriodCoverage(value);
						}}
						// C filterOption={(input, option) => String(option?.label).includes(input)}
					>
						{warrantyOptions.map((option: FilterOptionItem, ix: number) =>
							<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
						)}
					</Select>
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Space>
			</Card>
		) : (
			<Space>
				<label>
					{currentValue ? currentValue.split(', ').map(value => getWarrantyPeriodCoverageLabel(value)).join(', ') : []}
				</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
