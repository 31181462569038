import React from 'react';
import {DatePicker, Form, Input, Select, Switch} from 'antd';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import dayjs, {type Dayjs} from 'dayjs';
import {SiteSelect} from '../SiteSelect';
import {type RangeValue} from 'rc-picker/lib/interface';
import type {Moment} from 'moment';

const {Option} = Select;
const {RangePicker} = DatePicker;

type Props = {
	filterKey: string;
	items?: FilterOptionItem[];
	formType?: FilterFormType;
	formWidth?: number;
	placeholder?: string;
	label?: string;
	value?: string | boolean | string[];
	onChange?: (value: string | boolean, key: string) => void;
	onMultiChange?: (values: string[], key: string) => void;
	onRangeChange?: (value1: string, value2: string, key: string) => void;
	disabledDateDays?: number;
};

export const FilterForm: React.FC<Props> = ({
	filterKey,
	formType,
	formWidth,
	placeholder,
	items,
	label,
	value,
	onChange,
	onMultiChange,
	onRangeChange,
	disabledDateDays,
}) => {
	if (formType === FilterFormType.SELECT) {
		return (
			<FilterSelectForm
				filterKey={filterKey}
				placeholder={placeholder}
				items={items}
				label={label}
				value={value}
				onChange={onChange}
			/>
		);
	}

	if (formType === FilterFormType.MULTI_SELECT) {
		return (
			<FilterMultiSelectForm
				filterKey={filterKey}
				items={items}
				label={label}
				value={value}
				onMultiChange={onMultiChange}
			/>
		);
	}

	if (formType === FilterFormType.SITE_SELECT) {
		return (
			<FilterSiteSelectForm
				filterKey={filterKey}
				placeholder={placeholder}
				items={items}
				label={label}
				value={value}
				onChange={onChange}
			/>
		);
	}

	if (formType === FilterFormType.TEXT) {
		return (
			<FilterTextForm
				filterKey={filterKey}
				placeholder={placeholder}
				formWidth={formWidth}
				label={label}
				value={value}
				onChange={onChange}
			/>
		);
	}

	if (formType === FilterFormType.DATE) {
		const defaultValue = value ? dayjs(String(value)) : undefined;
		return (
			<Form.Item label={label}>
				<DatePicker
					defaultValue={defaultValue}
					onChange={(date, dateString) => {
						if (onChange) {
							onChange(dateString, filterKey);
						}
					}}
				/>
			</Form.Item>
		);
	}

	if (formType === FilterFormType.MONTH) {
		return (
			<Form.Item label={label}>
				<DatePicker
					// DefaultValue={defaultValue}
					onChange={(date, dateString) => {
						if (onChange) {
							onChange(dateString, filterKey);
						}
					}}
					picker='month'
				/>
			</Form.Item>
		);
	}

	if (formType === FilterFormType.WEEK) {
		return (
			<Form.Item label={label}>
				<DatePicker
					// DefaultValue={defaultValue}
					onChange={(date, dateString) => {
						if (onChange) {
							onChange(dateString, filterKey);
						}
					}}
					picker='week'
				/>
			</Form.Item>
		);
	}

	if (formType === FilterFormType.DATE_RANGE) {
		return (
			<FilterDateRangeForm
				filterKey = {filterKey}
				label= {label}
				value = {value}
				onRangeChange = {onRangeChange}
				disabledDateDays = {disabledDateDays}
			/>
		);
	}

	if (formType === FilterFormType.BOOLEAN) {
		const defaultValue = value ? Boolean(value) : undefined;
		return (
			<Form.Item label={label}>
				<Switch
					defaultChecked={defaultValue}
					onChange={checked => {
						if (onChange) {
							onChange(checked, filterKey);
						}
					}}
				/>
			</Form.Item>
		);
	}

	const defaultValue = value ? String(value) : '';
	return (
		<Form.Item label={label}>
			<Input
				allowClear={true}
				defaultValue={defaultValue}
				placeholder={placeholder ?? 'Please Enter'}
				onChange={(e: any) => {
					if (onChange) {
						onChange(String(e.target.value), filterKey);
					}
				}}
			/>
		</Form.Item>
	);
};

export const FilterSelectForm: React.FC<Props> = ({
	filterKey,
	placeholder,
	items,
	label,
	value,
	onChange,
}) => {
	const defaultItem = items?.length && items.find((item: any) =>
		String(item.value) === String(value),
	);
	const defaultValue = defaultItem ? defaultItem.label : undefined;
	return (
		<Form.Item label={label}>
			<Select
				showSearch={true}
				optionFilterProp='children'
				placeholder={placeholder ?? 'Please Select'}
				defaultValue={defaultValue}
				onChange={searchValue => {
					if (onChange) {
						onChange(searchValue.toString(), filterKey);
					}
				}}
				// C filterOption={(input, option) => String(option?.label).includes(input)}
			>
				<Option key={-1} value={''}>{'All'}</Option>,
				{items?.length && items.map((item: FilterOptionItem, ix: number) =>
					<Option key={ix} value={item.value}>{item.label}</Option>,
				)}
			</Select>
		</Form.Item>
	);
};

export const FilterMultiSelectForm: React.FC<Props> = ({
	filterKey,
	items,
	label,
	value,
	onMultiChange,
}) => {
	const defaultValues = value ? value as string[] : [];
	return (
		<Form.Item label={label}>
			<Select
				mode={'multiple'}
				maxTagCount={1}
				defaultValue={defaultValues}
				onChange={(values: string[]) => {
					if (onMultiChange) {
						onMultiChange(values, filterKey);
					}
				}}
			>
				{items?.length && items.map((item: FilterOptionItem, ix: number) =>
					<Option key={ix} value={item.value}>{item.label}</Option>,
				)}
			</Select>
		</Form.Item>
	);
};

export const FilterTextForm: React.FC<Props> = ({
	filterKey,
	placeholder,
	formWidth,
	label,
	value,
	onChange,
}) => {
	const defaultValue = value ? String(value) : '';
	return (
		<Form.Item label={label}>
			<Input
				allowClear={true}
				placeholder={placeholder ?? 'Please Enter'}
				style={formWidth ? {width: formWidth} : {}}
				defaultValue={defaultValue}
				onChange={(e: any) => {
					if (onChange) {
						onChange(String(e.target.value), filterKey);
					}
				}}
			/>
		</Form.Item>
	);
};

export const FilterSiteSelectForm: React.FC<Props> = ({
	filterKey,
	placeholder,
	items,
	label,
	value,
	onChange,
}) => {
	const defaultItem = items?.length && items.find((item: any) =>
		String(item.value) === String(value),
	);
	const defaultValue = defaultItem ? defaultItem.label : undefined;
	return (
		<Form.Item label={label}>
			<SiteSelect
				siteOptions={items ?? []}
				allOption={true}
				placeholder={placeholder ?? 'Please Select'}
				defaultValue={defaultValue}
				onChange={searchValue => {
					if (onChange) {
						onChange(String(searchValue), filterKey);
					}
				}}
			/>
		</Form.Item>
	);
};

export const FilterDateRangeForm: React.FC<Props> = ({
	filterKey,
	label,
	value,
	onRangeChange,
	disabledDateDays,
}) => {
	const defaultValues = value as string[];
	const [dates, setDates] = React.useState<RangeValue<dayjs.Dayjs>>();
	const defaultValue: RangeValue<Dayjs> | undefined = (defaultValues && defaultValues.length === 2 && defaultValues[0] && defaultValues[1]) ? [dayjs(defaultValues[0]), dayjs(defaultValues[1])] : undefined;
	const disabledDate = (current: Dayjs) => {
		if (!dates || !disabledDateDays) {
			return false;
		}

		const tooLate = dates[0] && current.diff(dates[0], 'days') >= disabledDateDays;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') >= disabledDateDays;
		return Boolean(tooEarly) || Boolean(tooLate);
	};

	const onOpenChange = (open: boolean) => {
		if (open) {
			setDates([null, null]);
		} else {
			setDates(null);
		}
	};

	return (
		<Form.Item label={label}>
			<RangePicker
				defaultValue={defaultValue}
				onChange={(_date, dateString) => {
					setDates(_date);
					if (onRangeChange) {
						onRangeChange(dateString[0], dateString[1], filterKey);
					}
				}}
				disabledDate={disabledDateDays ? disabledDate : undefined}
				onOpenChange={onOpenChange}
				onCalendarChange={val => {
					setDates(val);
				}}
				changeOnBlur
			/>
		</Form.Item>
	);
};
