import React from 'react';
import {Input, Space, Form, Button, Select, Switch} from 'antd';
import {type ProcessChecklistQuestion} from 'app/models/process-checklist-question.model';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {ViewParseLink} from 'app/components/Common/ViewParseLink';

type Pops = {
	question: ProcessChecklistQuestion;
	onChange: (question: ProcessChecklistQuestion) => void;
};

export const ChecklistQuestionForm: React.FC<Pops> = ({question, onChange}) => {
	const onResponseOptionChange = (i: number, value: string) => {
		const items = question.responseOptions ? [...question.responseOptions] : [];
		items[i] = value;
		onChange({...question, ...{responseOptions: items, requiredResponse: undefined, correctiveMeasures: undefined}} as ProcessChecklistQuestion);
	};

	const onResponseOptionAdd = () => {
		const items = question.responseOptions ? [...question.responseOptions] : [];
		items.push('');
		onChange({...question, ...{responseOptions: items, requiredResponse: undefined, correctiveMeasures: undefined}} as ProcessChecklistQuestion);
	};

	const onResponseOptionRemove = (i: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to remove this option')) {
			const items = question.responseOptions ? [...question.responseOptions] : [];
			items.splice(i, 1);
			onChange({...question, ...{responseOptions: items, requiredResponse: undefined, correctiveMeasures: undefined}} as ProcessChecklistQuestion);
		}
	};

	const onCorrectiveMeasureChange = (key: string, value: string) => {
		const items = question.correctiveMeasures ? {...question.correctiveMeasures} : {};
		items[key] = value;
		onChange({...question, ...{correctiveMeasures: items}} as ProcessChecklistQuestion);
	};

	// Adding textarea
	const {TextArea} = Input;

	return (
		<Space direction={'vertical'} style={{width: '100%'}}>
			<Form.Item
				label='Question'
				rules={[{required: true, message: 'Please input your question!'}]}
			>
				<Input
					value={question.content}
					onChange={(e: any) => {
						onChange({...question, ...{content: String(e.target.value)}} as ProcessChecklistQuestion);
					}}
				/>
			</Form.Item>
			<Form.Item label='Sequence/Order Number'>
				<Input
					value={question.sno}
					onChange={(e: any) => {
						onChange({...question, ...{sno: String(e.target.value)}} as ProcessChecklistQuestion);
					}}
				/>
			</Form.Item>
			<Form.Item label={'Do We Requires Supporting Photos'}>
				<Switch
					checked={question.isPhotoRequired}
					onChange={checked => {
						onChange({...question, ...{isPhotoRequired: checked}} as ProcessChecklistQuestion);
					}}
				/>
			</Form.Item>
			<Form.Item label={'Answer Choices'}>
				<Space direction={'vertical'}>
					{question.responseOptions?.map((option, ix) => (
						<Space key={ix}>
							<Input
								value={option}
								onChange={e => {
									onResponseOptionChange(ix, e.target.value);
								}}
							/>
							<Button type={'dashed'} danger={true} onClick={() => {
								onResponseOptionRemove(ix);
							}}><DeleteOutlined /></Button>
						</Space>
					))}
					<Button
						type='dashed'
						onClick={() => {
							onResponseOptionAdd();
						}}
						block
						icon={<PlusOutlined />}
					>
						Add Answer Choices
					</Button>
				</Space>
			</Form.Item>
			<Form.Item label={'Right Answer'}>
				<Select
					style={{width: '100%'}}
					onChange={value => {
						onChange({...question, ...{requiredResponse: String(value)}} as ProcessChecklistQuestion);
					}}
					defaultValue={question.requiredResponse ?? ''}
					value={question.requiredResponse ?? ''}
				>
					{question.responseOptions?.map((opt: string, ix: number) =>
						<Select.Option key={ix} value={opt}>{opt}</Select.Option>,
					)}
					<Select.Option key={-1} value={''}>{'None'}</Select.Option>
				</Select>
			</Form.Item>
			{/* form label updated */}
			{/* <Form.Item label="Corrective Steps/Measure"> */}
			<Form.Item label='Corrective Steps to be taken if wrong answer is selected'>
				<Space direction={'vertical'}>
					{question.requiredResponse ? question.responseOptions?.filter((opt: string) => opt !== question.requiredResponse)
						.map((opt: string, ix: number) => (
							<Space key={ix}>
								{opt} :
								<TextArea rows={2}
									style={{width: 900}}
									value={question.correctiveMeasures ? question.correctiveMeasures[opt] : ''}
									onChange={e => {
										onCorrectiveMeasureChange(opt, e.target.value);
									}}
								/>
							</Space>
						)) : []}
				</Space>
			</Form.Item>
			<Form.Item label='Reference Documents Links'>
				<Input
					value={question.referenceDocuments}
					onChange={(e: any) => {
						onChange({...question, ...{referenceDocuments: String(e.target.value)}} as ProcessChecklistQuestion);
					}}
				/>
				{question.referenceDocuments ? <ViewParseLink parseLink={question.referenceDocuments}/> : []}
			</Form.Item>
			{/* form item added */}
			<Form.Item label='Explanation Content'>
				<Input
					value={question.referenceDocumentStr}
					onChange={(e: any) => {
						onChange({...question, ...{referenceDocumentStr: String(e.target.value)}} as ProcessChecklistQuestion);
					}}
				/>
			</Form.Item>
		</Space>
	);
};

