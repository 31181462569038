export const loadState = (target: string, initialState: any) => {
	try {
		const serializedState = localStorage.getItem('appState');
		if (target === undefined && serializedState !== undefined && serializedState !== null) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return JSON.parse(serializedState);
		}

		if (serializedState === undefined || serializedState === null) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return initialState; // Reducer will return Redux state, as local storage is undefined.
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return JSON.parse(serializedState)[target] ?? initialState;
	} catch (err) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return initialState;
	}
};
