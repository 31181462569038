import React, {type ReactNode} from 'react';
import {Col, Row} from 'antd';

export const ColBlock: React.FC<{label: string; value: ReactNode}> = ({label, value}) => {
	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	return (
		<Row style={{marginBottom: 15}}>
			<Col span={layout.labelCol.span}><b>{label}</b></Col>
			<Col span={layout.wrapperCol.span}>{value}</Col>
		</Row>
	);
};
