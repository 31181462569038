import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type Approval} from './approval';
import {type AnyAction} from 'redux';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<Approval['id'], Approval>;
	allIds: Array<Approval['id']>;
	pageType?: string;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	pageType: undefined,
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

export default function approval(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('approval', initialState),
	action: AnyAction,
): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.changePage:
			return {...state, pageType: String(action.payload)};
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: Number(action.payload.currentPos),
					perPageSize: Number(action.payload.perPageSize),
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getCountApi):
			return {
				...state,
				loading: true,
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getCountApi):
			return {
				...state,
				loading: false,
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload?.data
				? (action.payload?.data as Approval[])
				: [];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: Number(action.payload.data.count),
					byIds: {},
					allIds: [],
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
}
