import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SearchOutlined} from '@ant-design/icons';
import {Col, Row, Spin, Table, Typography, Space, Button, Input, Empty} from 'antd';
import type {ColumnType} from 'antd/es/table';
import type {FilterConfirmProps} from 'antd/es/table/interface';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {getQueryDateRange} from 'app/query_helpers';
import {count, get, onPaginationChange, setFilterValue} from './checklist-approval-report-reducer';
import {get as getUsers} from '../User/actions';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {UserRole} from 'app/models/enums/user-role.enum';
import {ModuleName} from 'app/models';
import {type ApprovalLog} from 'app/models/approval-log.model';
import {toTitleCase} from 'app/helpers';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {Link} from 'react-router-dom';
import type Types from 'MyTypes';

type DataType = {
	scheduleId: number;
	drawingScheduleId: number;
	siteId: number;
	projectProcessType: string;
	checklistId: string;
	checklistName: string;
	status: string;
	submittedBy: string;
	level1: string;
	level2: string;
};
type DataIndex = keyof DataType;
export const ViewChecklistApprovalReport: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds, filterValue, totalCount, currentPos, perPageSize} = useSelector(
		(state: Types.RootState) => state.checklistApprovalLog,
	);
	const searchInput = useRef<any>(null);
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');

	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewChecklistApprovalReport.componentDidMount');
		dispatch(
			getUsers({
				where: {
					roles: {
						inq: [UserRole.CONSTRUCTION_HEAD, UserRole.CONSTRUCTION_IN_CHARGE],
					},
				},
			}),
		);
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {
				moduleName: ModuleName.CONSTRUCTION_SCHEDULE,
			},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f.userId) {
			filter.where.createdBy = f.userId;
		}

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt');
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const handleSearch = (
		selectedKeys: string[],
		confirm: (param?: FilterConfirmProps) => void,
		dataIndex: DataIndex,
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (
		dataIndex: DataIndex,
	): ColumnType<DataType> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			// UI of search interface
			<div style={{padding: 8}} onKeyDown={e => {
				e.stopPropagation();
			}}>
				<Input
					ref={searchInput}
					placeholder={`Search ${toTitleCase(dataIndex)}`}
					value={selectedKeys[0]}
					onChange={e => {
						setSelectedKeys(e.target.value ? [e.target.value] : []);
					}
					}
					onPressEnter={() => {
						handleSearch(selectedKeys as string[], confirm, dataIndex);
					}
					}
					style={{marginBottom: 8, display: 'block'}}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => {
							handleSearch(selectedKeys as string[], confirm, dataIndex);
						}
						}
						icon={<SearchOutlined />}
						size='small'
						style={{width: 90}}
					>
            Search
					</Button>
					<Button
						onClick={() => {
							if (clearFilters) {
								handleReset(clearFilters);
							}
						}}
						size='small'
						style={{width: 90}}
					>
            Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
		),
		// Filters the dataSource
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase()),
		onFilterDropdownVisibleChange(visible) {
			if (visible) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	const approvalLogs: ApprovalLog[] = allIds.map(id => byIds[id]);

	const dataSource: DataType[] = approvalLogs.map(log => ({
		scheduleId: log.processChecklistResponse?.projectProcessScheduleId ?? 0,
		drawingScheduleId: log.processChecklistResponse?.drawingScheduleId ?? 0,
		siteId: log.processChecklistResponse?.siteId ?? 0,
		projectProcessType: log.processChecklistResponse?.projectProcessType ?? '',
		checklistId:
      log.processChecklistResponse?.processChecklist?.id ?? '',
		checklistName:
      log.processChecklistResponse?.processChecklist?.title ?? 'N/A',
		status: log.processChecklistResponse?.status ?? 'N/A',
		submittedBy: log.processChecklistResponse?.updatedByUser?.name ?? 'N/A',
		level1: log.relatedApprovalLogs?.find(item => item.level === 1)?.createdUser
			?.name ?? 'N/A',
		level2: log.relatedApprovalLogs?.find(item => item.level === 2)?.createdUser
			?.name ?? 'N/A',
	}),
	);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.checklistApprovalReport}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Checklist Approval Report
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={f1 => {
								filterData(f1);
							}}
						/>
					</Col>
				</Row>
				<br />
				{totalCount ? (
					<>
						<Row>
							<Col span={24} className='mb-10'>
								<PaginationView
									isFunctional={true}
									total={totalCount}
									currentPos={currentPos}
									perPageSize={perPageSize}
									filterValue={filterValue}
									filterData={filterData}
									onPaginationChange={onPaginationChange}
								/>
								<Table
									size='small'
									dataSource={dataSource}
									bordered={true}
									pagination={false}
								>
									<Table.Column
										title='Checklist'
										dataIndex='checklistName'
										key='checklistName'
										fixed='left'
										render={(name: string, data: DataType) => (
											<Link
												to={{
													pathname: `/${uiPaths.viewProcessChecklistResponseDetail
														.replace(':scheduleId', String(data.scheduleId))
														.replace(':drawingScheduleId', String(data.drawingScheduleId))
														.replace(':checklistId', data.checklistId)
														.replace(':siteId', String(data.siteId))
														.replace(':processType', data.projectProcessType)}`,
												}}
											>
												{name}
											</Link>
										)}
										{...getColumnSearchProps('checklistName')}
									/>
									<Table.Column
										title='Status'
										dataIndex='status'
										key='status'
										render={(status: string) =>
											status === 'N/A' ? '' : toTitleCase(status)
										}
										filters={[
											{
												text: 'Approved',
												value: 'Approved',
											},
											{
												text: 'Rejected',
												value: 'Rejected',
											},
											{
												text: 'Submitted',
												value: 'Submitted',
											},
										]}
										onFilter={(value, record: DataType) =>
											value === toTitleCase(record.status)
										}
									/>
									<Table.Column
										title='Submitted'
										dataIndex='submittedBy'
										key='submittedBy'
										{...getColumnSearchProps('submittedBy')}
									/>
									<Table.Column
										title='Approval Level 1'
										dataIndex='level1'
										key='level1'
										{...getColumnSearchProps('level1')}
									/>
									<Table.Column
										title='Approval Level 2'
										dataIndex='level2'
										key='level2'
										{...getColumnSearchProps('level2')}
									/>
								</Table>
							</Col>
						</Row>
						<br/>
						<Row>
							<Col span={24}>
								<Typography.Text><b>Report Description:</b>This report presents the list of construction schedule checklists which are in the Submitted,Approved and Cancelled State. The report shows the Approval Level 1 and Level 2 user names also along with the name of the user who submitted the checklist. Data can be seen for any particular time duration by selecting the Start Date and End Date in the Date Filter.</Typography.Text>
							</Col>
						</Row>
						<br/>
						<Row>
							<Col span={24}>
								<Typography.Text><b>Report Logic:</b> The data is shown on the basis of construction schedule checklists that got Submitted, Approved or Cancelled. If you have any query related to this report or a suggestion to improvise the report. Please contact ruchika.singh@prithu.in</Typography.Text>
							</Col>
						</Row>
					</>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
