import axios from 'axios';
import {getHeaders} from '../../services/common-service';
import {lbBaseUrl} from '../../constants';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@workOrderBillReports/API_FILTER',
	apiExtraFilter: '@workOrderBillReports/API_EXTRA_FILTER',
	getApi: '@workOrderBillReports/GET_API',
};

const url = lbBaseUrl + '/work-order-bill-reports';

export const setFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: value,
	meta: {},
});

export const setExtraFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiExtraFilter,
	payload: value,
	meta: {},
});

export const get = (filter: any): any => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});
