import {lbBaseUrl} from '../../constants';
import axios from 'axios';
import {getHeaders} from '../../services/common-service';

export const actionTypes = {
	getApi: '@paymentDetail/GET_API',
	createApi: '@paymentDetail/CREATE_API',
	updateApi: '@paymentDetail/UPDATE_API',
};

const url = lbBaseUrl + '/payment-details';

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});
