import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
	equalNum,
	formatDateFunction,
	getPermissionSites,
	toTitleCase,
	getStartDate,
	parseNum,
} from 'app/helpers';
import {drawingStatusColorMap} from './drawing-status-color-map';
import {parentTab, uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Card,
	Col,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import {Link} from 'react-router-dom';
import {BgColorsOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import type Types from 'MyTypes';
import {get as getTabs, getParents} from '../ProjectProcessMaster/actions';
import {get, setFilterValue} from './actions';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {
	DrawingScopeType,
	type DrawingSchedule,
	DrawingStatusType,
	DrawingType,
	ModuleName,
	ModulePermission,
	SiteStatus,
	type Site,
} from 'app/models';
import {UserRole} from 'app/models/enums/user-role.enum';
import {type ProjectProcessMaster, ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {getQueryDateRange} from 'app/query_helpers';

export const ViewDrawingSchedule: React.FC = () => {
	const dispatch = useDispatch();
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {
		byIds: tabByIds,
		allIds: tabAllIds,
		parents,
	} = useSelector((state: Types.RootState) => state.projectProcessMaster);
	const {ids, records, loading, filterValue} = useSelector(
		(state: Types.RootState) => state.drawingSchedule,
	);
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const sites = getPermissionSites(
		permissions,
		ModuleName.DRAWING_SCHEDULE,
		ModulePermission.READ,
		allSites,
	);
	const approveSites = getPermissionSites(
		permissions,
		ModuleName.DRAWING_SCHEDULE,
		ModulePermission.APPROVE,
		allSites,
	);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewDrawingSchedule.componentDidMount');
		dispatch(
			getParents({
				where: {
					projectProcessType: ProjectProcessType.DESIGN,
					processName: {
						inq: [parentTab.drawings, parentTab.quantity, parentTab.designProgressTracker],
					},
				},
			}),
		);
		dispatch(
			getTabs({where: {projectProcessType: ProjectProcessType.DESIGN}}),
		);
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	const filterData = (f: FilterDataType = {}) => {
		if (!f.siteId) {
			return;
		}

		const filter: any = {
			where: {},
			offset: 0,
			limit: 2500,
			order: ['createdAt ASC'],
		};

		filter.where = {
			siteId: f.siteId,
			drawingType: DrawingType.DRAWING_SCHEDULE,
		};

		if (f.completedAt1 && f.completedAt2) {
			if (!filter.where.and) {
				filter.where.and = [];
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(
				...getQueryDateRange(f.completedAt1 as string, f.completedAt2 as string, 'completedAt'),
			);
		}

		if (f.revisedAt1 && f.revisedAt2) {
			if (!filter.where.and) {
				filter.where.and = [];
			}

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(
				...getQueryDateRange(f.revisedAt1 as string, f.revisedAt2 as string, 'revisedAt'),
			);
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	// Created to calculate maXDelay and maxCompletionDate for a set of Drawing Schedule activities for a particular Site
	const getMaxDelayAndMaxCompletionDate = (dataSource: ProjectProcessMaster[]) => {
		const today = new Date();
		let maxPlannedCompletionDate = '';
		let maxDelay = 0;
		dataSource.forEach((item: any) => {
			let diffTime;
			let diffDays = 0;
			if (
				item.designSchedule.completedAt
        && item.designSchedule.scheduleDate
        && new Date(item.designSchedule.completedAt as string)
          > new Date(item.designSchedule.scheduleDate as string)
			) {
				diffTime = Math.abs(
					getStartDate(item.designSchedule.completedAt as string).valueOf()
            - getStartDate(item.designSchedule.scheduleDate as string).valueOf(),
				);
				diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			} else if (
				!item.designSchedule.completedAt
        && item.designSchedule.scheduleDate
        && new Date(today) > new Date(item.designSchedule.scheduleDate as string)
			) {
				diffTime = Math.abs(
					getStartDate(today.toDateString()).valueOf()
            - getStartDate(item.designSchedule.scheduleDate as string).valueOf(),
				);
				diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			}

			item.maxDelay = diffDays;
			maxDelay = Math.max(maxDelay, Math.max(diffDays, diffDays));
			if (item.designSchedule.scheduleDate) {
				maxPlannedCompletionDate
          = new Date(maxPlannedCompletionDate)
          > new Date(item.designSchedule.scheduleDate as string) ? maxPlannedCompletionDate : item.designSchedule.scheduleDate as string;
			}
		});
		return {maxDelay, maxPlannedCompletionDate};
	};

	const userStake = authUser?.roles;
	const items = ids.map(id => records[id]);
	let tabItems = tabAllIds.map(id => tabByIds[id]);

	// Creating Options to pass in workActivityParentName filter
	const workActivityNameOptions: FilterOptionItem[] = tabItems.map(item => ({
		value: String(item.id),
		label: item.processName,
	}));
	const today = new Date();
	const monthAhead = new Date(
		new Date(new Date().setDate(new Date().getDate() + 30)),
	);

	tabItems = tabItems.map(tabItem => {
		tabItem.designSchedule = items.find(
			item => item.projectProcessMasterId === tabItem.id,
		);
		return tabItem;
	});

	const parentOptions: FilterOptionItem[] = parents?.length
		? parents.map(parent => ({
			value: String(parent?.id),
			label: parent.processName ?? '',
		}))
		: [];

	const parentTabItems = tabItems.filter(tabItem => !tabItem?.parentId);
	if (filterValue.isMissing) {
		tabItems = tabItems.filter(item => !item.designSchedule?.scheduleDate);
	}

	// Filter for parent id
	if (filterValue.parentId) {
		tabItems = tabItems.filter(
			item => item?.parentId === parseNum(filterValue.parentId as string),
		);
	}

	// Filter for Work Activity Name
	if (filterValue.processNameId) {
		tabItems = tabItems.filter(item => item.id === parseNum(filterValue.processNameId as string));
	}

	// Filter for completed/revised activities
	if (filterValue.isCompleted) {
		tabItems = tabItems.filter(
			item =>
				item.designSchedule?.status === DrawingStatusType.COMPLETED
        || item.designSchedule?.status === DrawingStatusType.REVISED,
		).filter(item => item.designSchedule?.scope !== DrawingScopeType.NOT_IN_SCOPE);
	}

	if (filterValue.isNotInScopeWithDate) {
		tabItems = tabItems.filter(
			item =>
				item.designSchedule?.scheduleDate
        && item.designSchedule?.scope === DrawingScopeType.NOT_IN_SCOPE,
		);
	}

	if (filterValue.isDelayed) {
		tabItems = tabItems.filter(
			item =>
				item.designSchedule?.scheduleDate
        && new Date(item.designSchedule?.scheduleDate) < today
        && item.designSchedule?.scope !== DrawingScopeType.NOT_IN_SCOPE
        && item.designSchedule?.scope !== DrawingScopeType.SCOPE_NOT_DECIDED
        && item.designSchedule?.status !== DrawingStatusType.REVISED
        && item.designSchedule?.status !== DrawingStatusType.COMPLETED,
		);
	}

	tabItems = tabItems.filter(
		item =>
			item.designSchedule?.status?.toUpperCase()
      !== (DrawingStatusType.STOPPED).toUpperCase(),
	);

	const dataSource: ProjectProcessMaster[] = tabItems
		.filter(item => item.designSchedule?.scheduleDate)
		.sort((a, b) => {
			if (a.designSchedule?.scheduleDate && b.designSchedule?.scheduleDate) {
				if (
					new Date(a.designSchedule?.scheduleDate) > new Date(b.designSchedule?.scheduleDate)
				) {
					return 1;
				}

				if (
					new Date(a.designSchedule?.scheduleDate) < new Date(b.designSchedule.scheduleDate)
				) {
					return -1;
				}

				return (a.sequence ?? 0) > (b.sequence ?? 0) ? 1 : -1;
			}

			return -1;
		});

	const {maxDelay} = getMaxDelayAndMaxCompletionDate(dataSource);
	const siteOptions = sites
		.filter(
			(site: Site) =>
				filterValue?.siteStatus ? site.status === filterValue?.siteStatus : true,
		)
		.map((site: Site) => ({value: site.id.toString(), label: site.name}));
	const siteStatusOptions: FilterOptionItem[] = [
		{
			value: SiteStatus.UNDER_DESIGN,
			label: toTitleCase(SiteStatus.UNDER_DESIGN) ?? '',
		},
		{
			value: SiteStatus.UNDER_CONSTRUCTION,
			label: toTitleCase(SiteStatus.UNDER_CONSTRUCTION) ?? '',
		},
		{
			value: SiteStatus.RECENTLY_COMPLETED,
			label: toTitleCase(SiteStatus.RECENTLY_COMPLETED) ?? '',
		},
	];
	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteStatus',
			items: siteStatusOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status',
			label: 'Site Status',
			defaultValue: filterValue?.siteStatus,
		},
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'parentId',
			items: parentOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Parent',
			label: 'Parent',
			defaultValue: filterValue?.parentId,
		},
		{
			filterKey: 'isDelayed',
			formType: FilterFormType.BOOLEAN,
			label: 'Delayed Drawing Schedule',
			defaultValue: filterValue.isDelayed,
		},
		{
			filterKey: 'processNameId',
			items: workActivityNameOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Work Activity Name',
			label: 'Work Activity Name',
			defaultValue: filterValue?.processNameId,
		},
		{
			filterKey: 'isCompleted',
			formType: FilterFormType.BOOLEAN,
			label: 'Show Completed/Revised Schedules',
			defaultValue: filterValue.isCompleted,
		},
		{
			filterKey: 'completedAt',
			items: [],
			formType: FilterFormType.DATE_RANGE,
			label: 'Completed At',
			defaultValue: [filterValue?.completedAt1 as string, filterValue?.completedAt2 as string],
		},
		{
			filterKey: 'revisedAt',
			items: [],
			formType: FilterFormType.DATE_RANGE,
			label: 'Revised At',
			defaultValue: [filterValue?.revisedAt1 as string, filterValue?.revisedAt2 as string],
		},
	];

	if (userStake === UserRole.TRAINING_MANAGER) {
		uiFilters.push(
			{
				filterKey: 'isMissing',
				formType: FilterFormType.BOOLEAN,
				label: 'Missing Schedule Date',
				defaultValue: filterValue.isMissing,
			},
			{
				filterKey: 'isNotInScopeWithDate',
				formType: FilterFormType.BOOLEAN,
				label: 'Not in Scope with Schedule Date',
				defaultValue: filterValue.isNotInScopeWithDate,
			},
		);
	}

	const canApprove
    = filterValue.siteId
    && approveSites.find(({id}) => equalNum(String(filterValue.siteId), id));
	return (
		<DefaultLayout currentPath={uiPaths.drawingProcessSchedule}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3}>Drawing Schedule</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							submitOnChange={false}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
						<br />
					</Col>
				</Row>
				{canApprove ? (
					<Row>
						<Col span={24} style={{textAlign: 'right', marginBottom: 10}}>
							<Link
								to={{pathname: `/${uiPaths.viewDesignChecklistResponse}`}}
							>
								<Button type='primary'>Approve Checklist for this Site</Button>
							</Link>
						</Col>
					</Row>
				) : (
					[]
				)}
				<Row>
					<Col span={24}>
						{Object.keys(drawingStatusColorMap).map((opt, ix: number) => (
							<Space key={ix}>
								<BgColorsOutlined
									className={'legend-' + drawingStatusColorMap[opt]}
								/>
								{toTitleCase(opt, '_')}
								<span>&nbsp;</span>
								<span>&nbsp;</span>
							</Space>
						))}
					</Col>
				</Row>
				{filterValue?.siteId && (
					<Row>
						<Col span={24}>
							<br />
							<Button>{`${dataSource.length} Results`}</Button>
							<br />
							<br />
							{/* {(maxPlannedCompletionDate && dataSource.length > 0) && <Tag color={'#BC982F'}>{`Expected Date of Completion: ${formatDateFunction(addDays(maxPlannedCompletionDate, maxDelay).toDateString(), false)}`}</Tag>} */}
							{dataSource.length > 0 && (
								<Tag color={'#BC982F'}>{`Delayed By: ${maxDelay} days`}</Tag>
							)}
							<br />
							<br />
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
								scroll={{x: 800, y: 500}}
							>
								<Table.Column
									title='Work Activity'
									dataIndex='processName'
									key='processName'
									render={(
										title: string | undefined,
										record: DrawingSchedule,
									) => (
										<Link
											to={{
												pathname: `/${uiPaths.drawingProcessScheduleForm.replace(
													':siteId',
													filterValue.siteId as string,
												).replace(':tabId', String(record.id))}`,
											}}
										>
											{title}
										</Link>
									)}
								/>
								<Table.Column
									title={<b>Parent Tab</b>}
									dataIndex='parentId'
									key='parentId'
									render={parentId =>
										parentId
											? parentTabItems.filter(item => item.id === parentId)
												.length > 0
												? parentTabItems.filter(
													item => item.id === parentId,
												)[0].processName
												: 'N/A'
											: 'N/A'
									}
									sorter={{
										compare(a: DrawingSchedule, b: DrawingSchedule) {
											const aStr = a?.projectProcessMaster?.parentId
												? parentTabItems.filter(
													item =>
														item.id === a?.projectProcessMaster?.parentId,
												).length > 0
													? parentTabItems.filter(
														item =>
															item.id === a?.projectProcessMaster?.parentId,
													)[0].processName
													: 'N/A'
												: 'N/A';
											const bStr = b?.projectProcessMaster?.parentId
												? parentTabItems.filter(
													item =>
														item.id === b?.projectProcessMaster?.parentId,
												).length > 0
													? parentTabItems.filter(
														item =>
															item.id === b?.projectProcessMaster?.parentId,
													)[0].processName
													: 'N/A'
												: 'N/A';
											return aStr > bStr ? 1 : -1;
										},
										multiple: 1,
									}}
								/>
								<Table.Column
									title='Schedule Date'
									dataIndex='designSchedule'
									key='designSchedule'
									render={(item: DrawingSchedule | undefined) => {
										if (!item?.scheduleDate) {
											return '';
										}

										let color = '';
										if (
											item.status !== DrawingStatusType.COMPLETED
                      && item.status !== DrawingStatusType.REVISED
                      && new Date(item.scheduleDate) < monthAhead
                      && new Date(item.scheduleDate) > today
										) {
											color = 'orange';
										}

										if (
											item.status !== DrawingStatusType.COMPLETED
                      && item.status !== DrawingStatusType.REVISED
                      && new Date(item.scheduleDate) < today
										) {
											color = 'red';
										}

										return (
											<Tag color={color}>
												<Space>
													{formatDateFunction(item.scheduleDate, false)}
													{color === 'red' ? (
														<Tooltip title="Drawings hasn't been uploaded before schedule date.">
															<QuestionCircleOutlined />
														</Tooltip>
													) : (
														[]
													)}
												</Space>
											</Tag>
										);
									}}
								/>
								<Table.Column
									title='Completed At'
									dataIndex='designSchedule'
									key='designSchedule.completedAt'
									render={(item: DrawingSchedule | undefined) =>
										formatDateFunction(item?.completedAt, false)
									}
								/>
								<Table.Column
									title='Revised At'
									dataIndex='designSchedule'
									key='designSchedule.revisedAt'
									render={(item: DrawingSchedule | undefined) =>
										formatDateFunction(item?.revisedAt, false)
									}
								/>
								<Table.Column
									title='Delay'
									dataIndex='maxDelay'
									key='maxDelay'
									render={maxDelay => maxDelay as string}
								/>

								<Table.Column
									title='Status'
									dataIndex='designSchedule'
									key='designSchedule.status'
									render={(item: DrawingSchedule | undefined) =>
										item?.scope === DrawingScopeType.IN_SCOPE
											? toTitleCase(item?.status)
											: toTitleCase(item?.scope)
									}
									sorter={{
										compare: (a: DrawingSchedule, b: DrawingSchedule) =>
											a?.projectProcessMaster?.designSchedule?.status
                      && b?.projectProcessMaster?.designSchedule?.status
                      && a?.projectProcessMaster?.designSchedule?.status
                        > b?.projectProcessMaster?.designSchedule?.status
												? 1
												: -1,
										multiple: 1,
									}}
								/>
							</Table>
						</Col>
					</Row>
				)}
				<br />
				<br />
				<Row>
					<Col span={24} className='mb-10'>
						<Card size='small' title={'Notes'}>
							<p>
								{`If you don't find the tab you are looking for, please do send an
								email to nitin.bansal@prithu.in, poorva.khandelwal@prithu.in and
								mohammad.quadri@prithu.in`}
							</p>
						</Card>
						<br />
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
