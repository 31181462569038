import React from 'react';
import {Col, Layout, Row} from 'antd';
import './DefaultLayout.less';
import logo from '../../assets/img/logo.png';

type Props = {
	children?: React.ReactNode;
};

export const SimpleLayout: React.FC<Props> = ({children}) => (
	<Layout id={'components-layout-demo-custom-trigger'}>
		<Layout className='site-layout'>
			<Layout.Header className='site-layout-background' style={{padding: '0px 8px'}}>
				<Row>
					<Col flex='100px'>
						<img style={{height: 40}} src={logo}/>
					</Col>
					<Col flex='auto'>
						<Row justify='end'>
							<Col>
								{' '}
							</Col>
						</Row>
					</Col>
				</Row>
			</Layout.Header>
			<Layout.Content
				className='site-layout-background'
				style={{
					margin: '8px',
					padding: 12,
					minHeight: 280,
				}}
			>
				{children}
			</Layout.Content>
			<Layout.Footer style={{textAlign: 'center'}}>
					Copyright © 2022 Prithu Homes. All Rights Reserved.
			</Layout.Footer>
		</Layout>
	</Layout>
);
