/* eslint-disable complexity */
import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {getCurrencyString, getFormattedDateMonth, getS3Url, getStartEndDate} from 'app/helpers';
import type Types from 'MyTypes';
import {Button, Col, DatePicker, Row, Space, Spin, Table} from 'antd';
import {log} from 'app/services/logger-service';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {get, setFilterValue} from './actions';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {type ReportCounts, type WeeklyExpenseReport} from 'app/models';
import {SiteStatus, type Site, type User} from '../../models';
import {pdfReportFile} from 'app/constants';

type OwnProps = {
	reportType: string;
	sites: Site[];
};

const mapStateToProps = ({
	weeklyExpenseReport,
}: Types.RootState, ownProps: OwnProps) => ({
	...weeklyExpenseReport,
	...ownProps,
});

const mapDispatchToProps = {
	get,
	setFilterValue,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

class ViewWeeklyExpenseReport extends React.Component<Props> {
	componentDidMount() {
		log('ViewWeeklyExpenseReport.componentDidMount');
		this.filterData();
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			this.filterData();
		}
	}

	filterData = (date: string | undefined = undefined) => {
		if (!date) {
			date = new Date().toString();
		}

		const filter = {
			where: {
				and: getQueryDateRangeForDays(date, -2, 4, 'createdAt'),
			},
			limit: 500,
			skip: 0,
		};

		this.props.setFilterValue({date});

		this.props.get(filter);
	};

	onWeakChange = (weakString: string) => {
		const splitWeak = weakString.split('-');
		const year = parseInt(splitWeak[0], 10);
		const weak = parseInt(splitWeak[1], 10);
		const date = moment().day('Wednesday').year(year).week(weak).toDate().toString();
		console.log({year, weak, date});

		this.filterData(date);
	};

	render() {
		const {recordSiteStatus = {}, loading, filterValue, reportDate, reportType} = this.props;
		const [firstDay, lastDay] = getStartEndDate(reportDate ?? filterValue?.date as string)
			.map((date: string) => date ? getFormattedDateMonth(date) : '');

		const inWeek = `${lastDay} to ${firstDay}`;
		const inTotal = `Till ${firstDay}`;

		const initialReport: WeeklyExpenseReport = {id: 0, siteId: 0, typeAmounts: '', createdAt: ''};

		const sumReportCounts = (fist?: ReportCounts, second?: ReportCounts): ReportCounts => ({
			week: (fist?.week ?? 0) + (second?.week ?? 0),
			total: (fist?.total ?? 0) + (second?.total ?? 0),
		});

		const reduceFn = (total: WeeklyExpenseReport, report: WeeklyExpenseReport) => {
			if (!total.site) {
				total.site = {id: 0, name: 'Total', address: '', projectNumber: 0};
			}

			total.foodBeverageExpense = sumReportCounts(total.foodBeverageExpense, report.foodBeverageExpense);
			total.siteLoadingUnloadingExpense = sumReportCounts(total.siteLoadingUnloadingExpense, report.siteLoadingUnloadingExpense);
			total.siteMaterialExpense = sumReportCounts(total.siteMaterialExpense, report.siteMaterialExpense);
			total.siteStationeryExpense = sumReportCounts(total.siteStationeryExpense, report.siteStationeryExpense);
			total.conveyance = sumReportCounts(total.conveyance, report.conveyance);
			total.labourExpense = sumReportCounts(total.labourExpense, report.labourExpense);
			total.waterAndElectricityBill = sumReportCounts(total.waterAndElectricityBill, report.waterAndElectricityBill);
			total.laisioningExpense = sumReportCounts(total.laisioningExpense, report.laisioningExpense);
			total.malwaRemoval = sumReportCounts(total.malwaRemoval, report.malwaRemoval);
			total.scrapAmount = sumReportCounts(total.scrapAmount, report.scrapAmount);
			total.solarAndWaterAutomation = sumReportCounts(total.solarAndWaterAutomation, report.solarAndWaterAutomation);
			total.otherExpense = sumReportCounts(total.otherExpense, report.otherExpense);
			total.totalExpense = sumReportCounts(total.totalExpense, report.totalExpense);
			total.labourExpenseWithoutDebit = sumReportCounts(total.labourExpenseWithoutDebit, report.labourExpenseWithoutDebit);
			return total;
		};

		const underConstructionLabel = {...initialReport};
		underConstructionLabel.site = {id: 0, name: 'Under Construction', address: '', projectNumber: 0};
		const underConstruction: WeeklyExpenseReport[] = recordSiteStatus[SiteStatus.UNDER_CONSTRUCTION] ?? [];
		const underConstructionTotal: WeeklyExpenseReport = underConstruction
			.reduce(reduceFn, {id: 0, siteId: 0, typeAmounts: '', createdAt: ''});

		const underWarrantyLabel = {...initialReport};
		underWarrantyLabel.site = {id: 0, name: 'Under Warranty', address: '', projectNumber: 0};
		const underWarranty: WeeklyExpenseReport[] = recordSiteStatus[SiteStatus.UNDER_WARRANTY] ?? [];
		const underWarrantyTotal: WeeklyExpenseReport = underWarranty
			.reduce(reduceFn, {id: 0, siteId: 0, typeAmounts: '', createdAt: ''});

		const underDesignLabel = {...initialReport};
		underDesignLabel.site = {id: 0, name: 'Under Design', address: '', projectNumber: 0};
		const underDesign: WeeklyExpenseReport[] = recordSiteStatus[SiteStatus.UNDER_DESIGN] ?? [];
		const underDesignTotal: WeeklyExpenseReport = underDesign
			.reduce(reduceFn, {id: 0, siteId: 0, typeAmounts: '', createdAt: ''});

		const recentlyCompletedLabel = {...initialReport};
		recentlyCompletedLabel.site = {id: 0, name: 'Recently Completed', address: '', projectNumber: 0};
		const recentlyCompleted: WeeklyExpenseReport[] = recordSiteStatus[SiteStatus.RECENTLY_COMPLETED] ?? [];
		const recentlyCompletedTotal: WeeklyExpenseReport = recentlyCompleted
			.reduce(reduceFn, {id: 0, siteId: 0, typeAmounts: '', createdAt: ''});

		const dataSource: WeeklyExpenseReport[] = [
			underConstructionLabel,
			...underConstruction,
			underConstructionTotal,
			initialReport,
			underWarrantyLabel,
			...underWarranty,
			underWarrantyTotal,
			initialReport,
			underDesignLabel,
			...underDesign,
			underDesignTotal,
			initialReport,
			recentlyCompletedLabel,
			...recentlyCompleted,
			recentlyCompletedTotal,
		];

		const csvData: any[] = [
			reportType === 'ttd' ? [
				'Site',
				'Employee Number',
				'Site In Charge',
				`Food Beverage Expenses ${inTotal}`,
				`Site Loading Unloading Expenses ${inTotal}`,
				`Site Material Expenses ${inTotal}`,
				`Site Stationery Expenses ${inTotal}`,
				`Conveyance Expenses ${inTotal}`,
				`Labour Expenses ${inTotal}`,
				`Water And Electricity Bill ${inTotal}`,
				`Laisioning Expenses ${inTotal}`,
				`Malwa Removal ${inTotal}`,
				`Scrap Amount ${inTotal}`,
				`Solar and Water Automation ${inTotal}`,
				`Other Expenses ${inTotal}`,
				`Total Expenses ${inTotal}`,
				`Labour Expenses Not Debited ${inTotal}`,
			] : [
				'Site',
				'Employee Number',
				'Site In Charge',
				`Food Beverage Expenses ${inWeek}`,
				`Site Loading Unloading Expenses ${inWeek}`,
				`Site Material Expenses ${inWeek}`,
				`Site Stationery Expenses ${inWeek}`,
				`Conveyance Expenses ${inWeek}`,
				`Labour Expenses ${inWeek}`,
				`Water And Electricity Bill ${inWeek}`,
				`Laisioning Expenses ${inWeek}`,
				`Malwa Removal ${inWeek}`,
				`Scrap Amount ${inWeek}`,
				`Solar and Water Automation ${inWeek}`,
				`Other Expenses ${inWeek}`,
				`Total Expenses ${inWeek}`,
				`Labour Expenses Not Debited ${inWeek}`,
			],
		];

		dataSource.forEach((record: WeeklyExpenseReport) => {
			csvData.push(reportType === 'ttd' ? [
				record.site ? record.site.name : '',
				record.siteInCharge ? record.siteInCharge.empNo : '',
				record.siteInCharge ? record.siteInCharge.name : '',
				String(record.foodBeverageExpense?.total ?? ''),
				String(record.siteLoadingUnloadingExpense?.total ?? ''),
				String(record.siteMaterialExpense?.total ?? ''),
				String(record.siteStationeryExpense?.total ?? ''),
				String(record.conveyance?.total ?? ''),
				String(record.labourExpense?.total ?? ''),
				String(record.waterAndElectricityBill?.total ?? ''),
				String(record.laisioningExpense?.total ?? ''),
				String(record.malwaRemoval?.total ?? ''),
				String(record.scrapAmount?.total ?? ''),
				String(record.solarAndWaterAutomation?.total ?? ''),
				String(record.otherExpense?.total ?? ''),
				String(record.totalExpense?.total ?? ''),
				String(record.labourExpenseWithoutDebit?.total ?? ''),
			] : [
				record.site ? record.site.name : '',
				record.siteInCharge ? record.siteInCharge.empNo : '',
				record.siteInCharge ? record.siteInCharge.name : '',
				String(record.foodBeverageExpense?.week ?? ''),
				String(record.siteLoadingUnloadingExpense?.week ?? ''),
				String(record.siteMaterialExpense?.week ?? ''),
				String(record.siteStationeryExpense?.week ?? ''),
				String(record.conveyance?.week ?? ''),
				String(record.labourExpense?.week ?? ''),
				String(record.waterAndElectricityBill?.week ?? ''),
				String(record.laisioningExpense?.week ?? ''),
				String(record.malwaRemoval?.week ?? ''),
				String(record.scrapAmount?.week ?? ''),
				String(record.solarAndWaterAutomation?.week ?? ''),
				String(record.otherExpense?.week ?? ''),
				String(record.totalExpense?.week ?? ''),
				String(record.labourExpenseWithoutDebit?.week ?? ''),
			]);
		});

		return (
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<Space>
							<DatePicker
								picker='week'
								format={'YYYY-w'}
								onChange={(date, dateString) => {
									this.onWeakChange(dateString);
								}}
							/>
							<CSVLink
								data={csvData}
								filename={'Weekly_Expense_Report.csv'}
							>
								<Button type={'primary'}>
									Download
								</Button>
							</CSVLink>
							<a
								href={getS3Url(reportType === 'ttd'
									? pdfReportFile.totalExpenseSum : pdfReportFile.weeklyExpenseSum)}
								target='_blank' rel='noreferrer'
							>
								<Button type={'primary'}>
									Open PDF Report
								</Button>
							</a>
						</Space>
						<br />
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Table
							size={'small'}
							pagination={false}
							dataSource={dataSource}
							scroll={{x: 1800, y: 500}}
						>
							<Table.Column
								title='Site'
								dataIndex='site'
								width={120}
								key='site'
								render={(site: Site | undefined) => site ? (site.id ? site.name : <b>{site.name}</b>) : ' - '}
							/>
							<Table.Column
								title='Employee No'
								dataIndex='siteInCharge'
								key='siteInCharge'
								render={(siteInCharge: User | undefined) => siteInCharge?.empNo ?? ''}
							/>
							<Table.Column
								title='Site In Charge'
								dataIndex='siteInCharge'
								key='siteInCharge'
								render={(siteInCharge: User | undefined) => siteInCharge?.name ?? ''}
							/>
							<Table.ColumnGroup
								title='Food Beverage Expenses'
								key='foodBeverageExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='foodBeverageExpense'
										key='foodBeverageExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='foodBeverageExpense'
										key='foodBeverageExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Site Loading Unloading Expenses'
								key='siteLoadingUnloadingExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='siteLoadingUnloadingExpense'
										key='siteLoadingUnloadingExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='siteLoadingUnloadingExpense'
										key='siteLoadingUnloadingExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Site Material Expenses'
								key='siteMaterialExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='siteMaterialExpense'
										key='siteMaterialExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='siteMaterialExpense'
										key='siteMaterialExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Site Stationery Expenses'
								key='siteStationeryExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='siteStationeryExpense'
										key='siteStationeryExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='siteStationeryExpense'
										key='siteStationeryExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Conveyance Expenses'
								key='conveyance'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='conveyance'
										key='conveyanceTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='conveyance'
										key='conveyanceWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Labour Expenses'
								key='labourExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='labourExpense'
										key='labourExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='labourExpense'
										key='labourExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Water And Electricity Bill'
								key='waterAndElectricityBill'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='waterAndElectricityBill'
										key='waterAndElectricityBillTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='waterAndElectricityBill'
										key='waterAndElectricityBillWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Laisioning Expense'
								key='laisioningExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='laisioningExpense'
										key='laisioningExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='laisioningExpense'
										key='laisioningExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Malwa Removal'
								key='malwaRemoval'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='malwaRemoval'
										key='malwaRemovalTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='malwaRemoval'
										key='malwaRemovalWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Scrap Amount'
								key='scrapAmount'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='scrapAmount'
										key='scrapAmountTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='scrapAmount'
										key='scrapAmountWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Solar and Water Automation'
								key='solarAndWaterAutomation'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='solarAndWaterAutomation'
										key='solarAndWaterAutomationTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='solarAndWaterAutomation'
										key='solarAndWaterAutomationWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Other Expenses'
								key='otherExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='otherExpense'
										key='otherExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='otherExpense'
										key='otherExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Total Expenses'
								key='totalExpense'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='totalExpense'
										key='totalExpenseTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='totalExpense'
										key='totalExpenseWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
							<Table.ColumnGroup
								title='Labour Expenses Not Debited'
								key='labourExpenseWithoutDebit'
							>
								{reportType === 'ttd' ? (
									<Table.Column
										title={inTotal}
										dataIndex='labourExpenseWithoutDebit'
										key='labourExpenseWithoutDebitTotal'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.total, false) ?? ''}
									/>
								) : (
									<Table.Column
										title={inWeek}
										dataIndex='labourExpenseWithoutDebit'
										key='labourExpenseWithoutDebitWeek'
										render={(amt: ReportCounts | undefined) => getCurrencyString(amt?.week, false) ?? ''}
									/>
								)}
							</Table.ColumnGroup>
						</Table>
					</Col>
				</Row>
			</Spin>
		);
	}
}

export default connector(ViewWeeklyExpenseReport);

