import {type ContractorForeman} from './contractor-foreman.model';
import {type PaymentMilestone} from './payment-milestone.model';
import {type Site} from './site.model';
import {type WorkOrderDebit} from './work-order-debit.model';
import {type WorkOrderSchedule} from 'app/models/work-order-schedule.model';
import {type ProjectProcessMaster} from 'app/components/ProjectProcessMaster/project-process-master';
import {type Attachment} from './attachment.model';
import {type User} from './user.model';
import {type WorkOrderBill} from './work-order-bill.model';
import {type Comment} from './comment.model';

export type WorkOrder = {
	id: number;
	title: string;
	siteId: number;
	orderType: OrderType;
	status?: WorkOrderStatus;
	cancelReason?: string;
	// WorkOrderTypeId: number;
	projectProcessMasterId?: number;
	workOrderScheduleId?: number;
	contractorId: number;
	contractorVisit?: string;
	workOrderAmount?: number;
	workOrderQuantity?: number;
	scopeOfWork?: string;
	scopeOfWorkFiles?: string;
	scopeOfWorkAttachments?: Attachment[];
	materialScopeContractorSupContractor?: string;
	materialScopeContractorSupContractorAttachments?: Attachment[];
	materialScopeContractorProvPrithu?: string;
	materialScopeContractorProvPrithuAttachments?: Attachment[];
	itemRateListExtra?: string;
	itemRateListExtraAttachments?: Attachment[];
	projectSchedule?: string;
	qualityDefect?: string;
	qualityDefectAttachments?: Attachment[];
	qualityCheck?: string;
	qualityCheckFiles?: string;
	qualityCheckAttachments?: Attachment[];
	paymentScheduleDelayPenalty?: string;
	paymentScheduleDelayPenaltyAttachments?: Attachment[];
	contactPerson?: string;
	contactPersonAttachments?: Attachment[];
	termOfAgreement?: string;
	termOfAgreementAttachments?: Attachment[];
	drawingFiles?: string;
	drawingFilesAttachments?: Attachment[];
	quantityCalc?: string;
	quantityCalcAttachments?: Attachment[];
	qualityAndPrice?: string;
	qualityAndPriceFiles?: string;
	qualityAndPriceAttachments?: Attachment[];
	tenderComparison?: string;
	tenderComparisonFiles?: string;
	tenderComparisonAttachments?: Attachment[];
	terminationClauses?: string;
	terminationClausesFiles?: string;
	generalClauses?: string;
	generalClausesFiles?: string;
	generalClausesAttachments?: Attachment[];
	schedule?: string;
	scheduleFiles?: string;
	scheduleAttachments?: Attachment[];
	drawingQuantityCalc?: string;
	drawingQuantityCalcFiles?: string;
	drawingQuantityCalcAttachments?: Attachment[];
	otherFiles?: string;
	otherFilesAttachments?: Attachment[];
	contractorForemen: ContractorForeman[];
	paymentMilestones: PaymentMilestone[];
	debits: WorkOrderDebit[];
	quantityMeasureRule?: string;
	ratePerUnit?: string;
	approvedLevel?: number;
	nextApprovedBy?: number;
	billedToClient?: boolean;
	terminationAmount?: number;
	createdAt?: string;
	updatedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	site?: Site;
	contractor?: User;
	// WorkOrderType?: WorkOrderType;
	projectProcessMaster?: ProjectProcessMaster;
	createdUser?: User;
	workOrderSchedule?: WorkOrderSchedule;
	paidBills?: WorkOrderBill[];
	terminationSummaryAttachments?: Attachment[];
	approveComments?: Comment[];

	key?: string;
};

export enum WorkOrderStatus {
	CREATED = 'CREATED',
	// PRE_APPROVED = 'PRE_APPROVED',
	APPROVED = 'APPROVED',
	// These are the statuses which are in use on ui part and logics in app
	PENDING = 'PENDING',
	PUBLISHED = 'PUBLISHED',
	CANCELLED = 'CANCELLED',
	TERMINATED = 'TERMINATED',
	TERMINATION_IN_PROGRESS = 'TERMINATION_IN_PROGRESS',
	COMPLETED = 'COMPLETED',
}

export enum OrderType {
	WORK = 'WORK',
	RATE = 'RATE',
}
