export const workOrderBillPathType = {
	new: 'new',
	all: 'all',
	toApprove: 'TO_APPROVE',
	reApprovedReport: 're-approved-report',
	reApprovedClientReport: 're-approved-client-report',
	weeklyReport: 'weekly-report',
	inTotalReport: 'in-total-report',
	billWithoutWorkOrder: 'bill-without-work-order',
	pendingBillAction: 'pending-bill-action',
	pendingOrderAction: 'pending-order-action',
};
