import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {changePage} from './actions';
import {Col, Menu, Row, Typography} from 'antd';
import {queryPathType} from './constants';
import {ViewCorporateQueryForm} from './ViewCorporateQueryForm';
import {ViewCorporateQueryRaised} from './ViewCorporateQueryRaised';
import {ViewCorporateQueryAssigned} from './ViewCorporateQueryAssigned';
import {ViewCorporateQueryAll} from './ViewCorporateQueryAll';
import type Types from 'MyTypes';

export const ViewCorporateQuery: React.FC = () => {
	const dispatch = useDispatch();
	const {pageType} = useSelector((state: Types.RootState) => state.corporateQuery);
	const selectedPage = pageType ?? queryPathType.new;

	return (
		<DefaultLayout currentPath={uiPaths.corporateQuery}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>Corporate Queries</Typography.Title>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(changePage(e.key))}
						selectedKeys={[selectedPage]}
						mode='horizontal'
					>
						<Menu.Item id={queryPathType.new} key={queryPathType.new}>
							Raise New Query
						</Menu.Item>
						<Menu.Item id={queryPathType.raised} key={queryPathType.raised}>
							Raised by Me
						</Menu.Item>
						<Menu.Item id={queryPathType.assigned} key={queryPathType.assigned}>
							Assigned to Me
						</Menu.Item>
						<Menu.Item id={queryPathType.all} key={queryPathType.all}>
							All Queries
						</Menu.Item>
					</Menu>
				</Col>
			</Row>

			<Row>
				<Col span={24}><br /></Col>
			</Row>

			<Row>
				<Col span={24}>
					{selectedPage === queryPathType.new ? (
						<ViewCorporateQueryForm />
					) : []}
					{selectedPage === queryPathType.raised ? (
						<ViewCorporateQueryRaised />
					) : []}
					{selectedPage === queryPathType.assigned ? (
						<ViewCorporateQueryAssigned />
					) : []}
					{selectedPage === queryPathType.all ? (
						<ViewCorporateQueryAll />
					) : []}
				</Col>
			</Row>

			<br />
			<br />
			<br />
		</DefaultLayout>
	);
};
