import {s3Url, uiPaths} from './constants';
import {v4 as uuidv4} from 'uuid';
import {message} from 'antd';
import {type UploadFile} from './models/upload-file';
import {appUrl} from './ui-env-const';
import {getFilterDateForDays} from './query_helpers';
import {type Site} from './models';

export const isMobile = window.innerWidth < 1024;

export const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const special = [
	'zeroth',
	'first',
	'second',
	'third',
	'fourth',
	'fifth',
	'sixth',
	'seventh',
	'eighth',
	'ninth',
	'tenth',
	'eleventh',
	'twelfth',
	'thirteenth',
	'fourteenth',
	'fifteenth',
	'sixteenth',
	'seventeenth',
	'eighteenth',
	'nineteenth',
];
export const deca = [
	'twent',
	'thirt',
	'fort',
	'fift',
	'sixt',
	'sevent',
	'eight',
	'ninet',
];
export const dayOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const getOrdinalNumberWords = (n?: number) => {
	if (!n || (n && n < 1)) {
		return '';
	}

	if (n && n < 20) {
		return special[n];
	}

	if (n && n % 10 === 0) {
		return `${deca[Math.floor(n / 10) - 2]}ieth`;
	}

	return n ? `${deca[Math.floor(n / 10) - 2]}y-${special[n % 10]}` : '';
};

export const getDayOfWeek = (date: string) => {
	const dayOfWeek = new Date(date).getDay();
	return isNaN(dayOfWeek) ? '' : dayOfWeekNames[dayOfWeek];
};

export const getMonthName = (month: number) => monthNames[Number(month) - 1];

export const isObjEqual = (o1: any, o2: any): boolean =>
	JSON.stringify(o1) === JSON.stringify(o2);

export const getFormattedDate = (date?: string) => {
	const d = date ? new Date(date) : new Date();
	return `${d.getDate().toString().padStart(2, '0')}/${getMonthName(
		d.getMonth() + 1,
	)}/${d.getFullYear()}`;
};

export const getFormattedDateMonth = (date?: string) => {
	const d = date ? new Date(date) : new Date();
	return `${d.getDate().toString().padStart(2, '0')} ${getMonthName(
		d.getMonth() + 1,
	)}`;
};

export const getOnlyDate = (dateStr: string) => {
	const date = new Date(dateStr);
	return `${date.getFullYear()}/${getMonthName(date.getMonth() + 1)}/${date
		.getDate()
		.toString()
		.padStart(2, '0')}`;
};

export const addDays = (date: string, days: number) => {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};

export const subtractDays = (date: string, days: number) => {
	const result = new Date(date);
	result.setDate(result.getDate() - days);
	return result;
};

export const formatDateFunction = (dateTimeStr?: string, showTime = true) => {
	if (!dateTimeStr) {
		return '';
	}

	try {
		const dateTime = new Date(dateTimeStr).toLocaleString('en-IN');
		const [date, time] = dateTime.split(', ');
		const [d, m, y]: string[] = date.split('/');
		if (showTime && time) {
			return `${d.padStart(2, '0')} ${getMonthName(parseNum(m))} ${y} ${time.trim().split('.')[0]}`;
		}

		return `${d.padStart(2, '0')} ${getMonthName(parseNum(m))} ${y}`;
	} catch (e) {
		return '';
	}
};

export const formatMonthYearFunction = (dateTimeStr?: string, showTime = true) => {
	if (!dateTimeStr) {
		return '';
	}

	try {
		const dateTime = new Date(dateTimeStr).toLocaleString('en-IN');
		const [date, time] = dateTime.split(', ');
		const [d, m, y]: string[] = date.split('/');
		return `${getMonthName(parseNum(m))} ${y} ${
			showTime && time ? time.trim().split('.')[0] : ''
		}`;
	} catch (e) {
		return '';
	}
};

export const numberWithCommas = (x?: number) =>
	x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';

export const getCurrencyString = (amount?: number, signed = true): string => {
	if (!amount) {
		return '';
	}

	if (amount > 0) {
		return `${signed ? '+' : ''}₹${numberWithCommas(amount)}`;
	}

	if (amount < 0) {
		return `${signed ? '-' : ''}₹${numberWithCommas(Math.abs(amount))}`;
	}

	return `₹${numberWithCommas(amount)}`;
};

export const toTitleCase = (str?: string, separator = '_') => {
	if (str && str.startsWith('_')) {
		return str.replace('_N_', ' & ').replace('_', '');
	}

	return str
		? str
			.split(separator)
			.map(w =>
				isNaN(parseInt(w[0], 10))
					? w[0].toUpperCase() + w.substr(1).toLowerCase()
					: w,
			)
			.join(' ')
		: str;
};

export const toSubstring = (str?: string, length = 0, endsWith = '') =>
	str && str.length >= length ? str.slice(0, length - 1) + endsWith : str;

export const hasPermission = (permissionMap: any, permission: any) =>
	Boolean((permissionMap?.[permission])
    || (Array.isArray(permissionMap) && permissionMap.includes(permission)));

export const isNumeric = (str: string | number | undefined) => !isNaN(parseNum(str));

export const getPermissionSites = (
	record: Record<string, Record<string, number[]>>,
	moduleName: string,
	permission: string,
	allSites: Site[],
) => {
	const siteIds: number[]
    = record?.[moduleName]?.[permission] ? record[moduleName][permission] : [];
	return allSites.filter(site => siteIds.includes(site.id));
};

export const getUniqueId = () => uuidv4();

export const getS3Url = (key: string) =>
	s3Url + key.split('+').join('%2B').split(' ').join('+');

export const checkFileErr = (fileList: UploadFile[]) => {
	const errFileCount = fileList.filter(
		file => file.status === 'error',
	).length;
	if (errFileCount) {
		void message.error(`${errFileCount} files haven't been uploaded successfully.
			 Please remove these files and upload again.`);
		return true;
	}

	const uploadingFileCount = fileList.filter(
		file => file.status === 'uploading',
	).length;
	if (uploadingFileCount) {
		void message.error(`${uploadingFileCount} files haven't been uploaded completely.
			 Please wait for these files to be uploaded successfully.`);
		return true;
	}

	return false;
};

export const getHrefLink = (path: string, id?: number) => {
	if (path === uiPaths.workOrderDetail) {
		return `${appUrl}/#/email-redirect?token=${localStorage.getItem(
			'appToken',
		)}&open=work-order&id=${id ?? ''}`;
	}

	return `${appUrl}/#/email-redirect?token=${localStorage.getItem(
		'appToken',
	)}&open=${path}&id=${id ?? ''}`;
};

export const getStartEndDate = (date?: string, noOfDays = 7) => {
	if (!date) {
		return ['', ''];
	}

	const firstDay = new Date(date).toString();
	const lastDay = getFilterDateForDays(date, noOfDays).toString();
	return [firstDay, lastDay];
};

export const parseNum = (num: number | string | undefined) =>
	num ? parseInt(String(num), 10) : 0;

export const equalNum = (num1?: number | string | undefined, num2?: number | string | undefined) =>
	parseNum(num1) === parseNum(num2);

export const getFileExtension = (fileName?: string) =>
	fileName ? fileName.split('.').pop() : undefined;

export function reduceUniqueIds<T>(ids: T[], id?: T) {
	if (id && !ids.includes(id)) {
		ids.push(id);
	}

	return ids;
}

export function reduceToRecord<T>(
	record: Record<number | string, T>,
	index: string | number,
	item?: T,
) {
	if (!item || !index) {
		return record;
	}

	if (index && item) {
		record[index] = item;
	}

	return record;
}

export const getStartDate = (date: string) =>
	new Date(new Date(date).setHours(0, 0, 0, 0));

export const checkForDisallowedCharacters = (input: string) => {
	// List of characters to check for
	const disallowedChars = ['/', '\\', ':', '*', '?', '"', '<', '>', '|', '&', ';', '&&', '||', '\n', '\r'];

	// Find which of the disallowed characters are present in the input
	const foundChars = disallowedChars.filter(char => input.includes(char));

	// If any disallowed characters are found, return an error message
	if (foundChars.length > 0) {
		return {
			error: true,
			message: `The following characters are not allowed: ${foundChars.join(', ')}. Please remove them from your input.`,
		};
	}

	// If no disallowed characters are found, return a success response
	return {error: false, message: 'Input is valid.'};
};
