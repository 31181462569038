import React, {type ReactNode} from 'react';
import {Card, Space} from 'antd';

type Props = {
	author?: ReactNode;
	avatar?: ReactNode;
	content?: ReactNode;
	datetime?: ReactNode;
};

export const CommentComponent: React.FC<Props> = ({
	author,
	avatar,
	content,
	datetime,
}) => (
	<Card>
		<Card.Meta
			avatar={avatar}
			title={author}
			description={(
				<Space direction='vertical'>
					{content}
					{datetime}
				</Space>
			)}
		/>
	</Card>
);
