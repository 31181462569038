import {type VisibilityType} from './comment.model';
import {type AttachmentData, type Attachment} from './attachment.model';
import {type PrithuPostPolicy} from './policies/prithu-post.policy';
import {type User} from './user.model';
import {UserRole} from './enums/user-role.enum';
import {type Site} from './site.model';
import {type ExpenseReport} from 'app/models/expense-report.model';
import {type WorkOrder} from 'app/models/work-order.model';
import {type WorkOrderBill} from 'app/models/work-order-bill.model';
import {type ProjectProcessSchedule} from 'app/components/ProjectProcessSchedule/project-process-schedule';

export type Query = {
	id: number;
	siteId?: number;
	content?: string;
	queryType?: QueryType;
	priority?: PriorityType;
	forWhom?: UserRole;
	forWhomStatus?: QueryStatus;
	createdByStatus?: QueryStatus;
	status?: QueryStatus;
	assignedAt: string;
	assignedTo?: number;
	workStatusReportId?: number;
	clientDecisionId?: number;
	escalatedTo?: number;
	escalatedAt?: string;
	visibility?: VisibilityType;
	parentId?: string;
	parentType?: QueryParentType;
	createdAt?: string;
	updatedAt?: string;
	closedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	version?: number;
	attachments: Attachment[];
	can?: PrithuPostPolicy;
	site?: Site;
	createdUser?: User;
	assignedUser?: User;
	escalatedUser?: User;
	escalateToUser?: User;
	deviceType?: DeviceType;
	isEscalated?: boolean;
	expenseReport?: ExpenseReport;
	workOrder?: WorkOrder;
	workOrderBill?: WorkOrderBill;
	projectProcessSchedule?: ProjectProcessSchedule;
	notifyCount?: number;
};

export type QueryData = {
	siteId?: number;
	content?: string;
	queryType?: QueryType;
	priority?: PriorityType;
	forWhom?: UserRole;
	forWhomStatus?: QueryStatus;
	createdByStatus?: QueryStatus;
	status?: QueryStatus;
	assignedTo?: number;
	workStatusReportId?: number;
	clientDecisionId?: number;
	escalatedTo?: number;
	escalatedAt?: string;
	visibility?: VisibilityType;
	parentId?: string;
	parentType?: QueryParentType;
	attachments: AttachmentData[];
	deviceType?: DeviceType;
	notifyCount?: number;
};

export enum QueryType {
	GENERAL = 'GENERAL',
	PROJECT = 'PROJECT',
}

export enum PriorityType {
	NORMAL = 'NORMAL',
	HOLD_POINT = 'HOLD_POINT',
}

export enum DeviceType {
	ANDROID = 'ANDROID',
	IOS = 'IOS',
	WEB = 'WEB',
}

export enum QueryStatus {
	CLOSED = 'CLOSED',
	OPEN = 'OPEN',
}

export const forClientAssignRoles = [
	UserRole.PROJECT_ARCHITECT,
	UserRole.AREA_PROJECT_MANAGER,
	UserRole.GOVERNMENT_APPROVAL_IN_CHARGE,
	UserRole.PROCUREMENT_INCHARGE,
	UserRole.CLIENT_RELATIONSHIP_MANAGER,
];

export enum QueryParentType {
	QUERY = 'QUERY',
	MATERIAL_QUERY = 'MATERIAL_QUERY',
	PETTY_CASH = 'PETTY_CASH',
	WORK_ORDER = 'WORK_ORDER',
	WORK_ORDER_BILL = 'WORK_ORDER_BILL',
	PROJECT_SCHEDULE = 'PROJECT_SCHEDULE',
}

export const queryParentTypeLabels = {
	[QueryParentType.QUERY]: 'Query',
	[QueryParentType.MATERIAL_QUERY]: 'Material query',
	[QueryParentType.PETTY_CASH]: 'Petty cash',
	[QueryParentType.WORK_ORDER]: 'Work order',
	[QueryParentType.WORK_ORDER_BILL]: 'Contractors bill',
	[QueryParentType.PROJECT_SCHEDULE]: 'Project schedule',
};
