import React from 'react';
import type Types from 'MyTypes';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {Col, Row, Typography} from 'antd';
import {log} from 'app/services/logger-service';
import {queryPathType} from './constants';
import {changePage} from './actions';
import * as qs from 'qs';
import {UserType} from 'app/models';
import {ViewChildQuery} from 'app/components/Query/ViewChildQuery';
import {Redirect, useLocation} from 'react-router-dom';

export const ViewQuery: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const {pageType} = useSelector((state: Types.RootState) => state.query);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);

	const q: any = qs.parse(location.search, {ignoreQueryPrefix: true});

	const page = q.page as string;

	React.useEffect(() => {
		if (page) {
			dispatch(changePage(page));
		}
	}, [page]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		log('ViewQuery.componentDidMount');
	}, []);

	if (authUser?.userType === UserType.CLIENT) {
		return <Redirect to={'/' + uiPaths.clientQuery} />;
	}

	const selectedPage: string = pageType ?? queryPathType.assigned;
	return (
		<DefaultLayout currentPath={uiPaths.query}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>Queries</Typography.Title>
				</Col>
			</Row>

			<ViewChildQuery
				defaultPage={selectedPage}
			/>
			<br />
			<br />
			<br />
		</DefaultLayout>
	);
};
