import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import axios from 'axios';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	getApi: '@electricityConsumption/GET_API',
	apiFilter: '@electricityConsumption/API_FILTER',
	createApi: '@electricityConsumption/CREATE_API',
	updateApi: '@electricityConsumption/UPDATE_API',
	getCountApi: '@electricityConsumption/GET_COUNT_API',
	getSingleApi: '@electricityConsumption/GET_SINGLE_API',
	tryGetApi: '@electricityConsumption/TRY_GET_API',
};

const url = lbBaseUrl + '/electricity-consumption';

export const setFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: value,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const tryGetApi = (filter: any) => ({
	type: actionTypes.tryGetApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const getById = (id: number, filter: any = {}) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(`${url}/count?where=${JSON.stringify(where)}`, getHeaders(true)),
	meta: {process: true},
});
