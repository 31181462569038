import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Empty,
	message,
	Popconfirm,
	Popover,
	Row,
	Space,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
	remove,
} from './actions';
import {Link} from 'react-router-dom';
import {formatDateFunction, getPermissionSites, isMobile, toTitleCase} from 'app/helpers';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';
import {CheckCircleOutlined} from '@ant-design/icons';
import {ActivityType} from '../ActivityTemplateItem/activity-template-item';
import {type ProjectActivity} from './project-activity';
import {ModuleName, ModulePermission} from 'app/models';

export const ViewProjectActivity: React.FC = () => {
	const dispatch = useDispatch();
	const {
		loading,
		byIds,
		allIds,
		filterValue,
		dataUpdated,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
	} = useSelector((state: Types.RootState) => state.projectActivity);
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const sites = getPermissionSites(permissions, ModuleName.PROJECT_ACTIVITY, ModulePermission.READ, allSites);
	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		if (!f.siteId) {
			return;
		}

		const filter: any = {
			where: {siteId: f.siteId},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f?.activityType) {
			filter.where.activityType = f.activityType;
		}

		if (f.name) {
			filter.where.name = {like: `%25${f.name as string}%25`};
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const typeOptions = Object.values(ActivityType).map((status: string) => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	const dataSource: ProjectActivity[] = allIds?.length
		? allIds.map(id => byIds[id]).sort((a, b) => {
			if (a.baselineStartDate && b.baselineStartDate) {
				return new Date(a.baselineStartDate) > new Date(b.baselineStartDate) ? 1 : -1;
			}

			if (a.sequence && b.sequence) {
				return a.sequence > b.sequence ? 1 : -1;
			}

			return -1;
		})
		: [];

	const nameRecord: Record<string, string> = dataSource.reduce((record, item) => {
		record[item.uniqueId] = item.name;
		return record;
	}, {});

	const uiFilters = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'activityType',
			items: typeOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Type',
			label: 'Activity Type',
			defaultValue: filterValue ? filterValue.activityType : '',
		},
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Name',
			label: 'Name',
			defaultValue: filterValue ? filterValue.name : '',
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.projectActivities}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Project Activity
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f1: FilterDataType) => {
								filterData(f1);
							}}
						/>
					</Col>
				</Row>
				<br />

				{filterValue.siteId ? (
					<Row>
						<Col span={24} style={{textAlign: 'right'}}>
							<Link
								to={{
									pathname: `/${uiPaths.addProjectActivity.replace(
										':siteId',
										String(filterValue.siteId),
									)}`,
								}}
							>
								<Button type='primary'>
									Add new Activity
								</Button>
							</Link>
							<br />
							<br />
						</Col>
					</Row>
				) : []}

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								pageSizeOptions={['10', '50', '100', '500', '1000', '5000']}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
							>
								<Table.Column
									title='Name'
									dataIndex='name'
									key='name'
								/>
								<Table.Column
									title='Logic'
									dataIndex='linkedUniqueIds'
									key='linkedUniqueIds'
									render={(linkedUniqueIds: string | undefined, item: ProjectActivity) =>
										linkedUniqueIds
											? `${item.linkedFromType === 'end' ? 'End date' : 'Start Date'} of ${item.formula === 'min' ? 'Min' : 'Max'}(${item.linkedUniqueIds?.split(',').map(uid => nameRecord[uid]).join(',')}) ${item.operator} ${item.operandDays}`
											: []
									}
								/>
								<Table.Column
									title='Checklists'
									dataIndex='linkedChecklistIds'
									key='linkedChecklistIds'
									render={(linkedChecklistIds: string | undefined) =>
										linkedChecklistIds ? (
											<Space>
												<CheckCircleOutlined />
											</Space>
										) : []
									}
								/>
								<Table.Column
									title='Duration'
									dataIndex='duration'
									key='duration'
								/>
								<Table.Column
									title='Baseline Start Date'
									dataIndex='baselineStartDate'
									key='baselineStartDate'
									render={(baselineStartDate: string) =>
										formatDateFunction(baselineStartDate, false)
									}
								/>
								<Table.Column
									title='Baseline End Date'
									dataIndex='baselineEndDate'
									key='baselineEndDate'
									render={(baselineEndDate: string) =>
										formatDateFunction(baselineEndDate, false)
									}
								/>
								<Table.Column
									title='Projected Start Date'
									dataIndex='projectedStartDate'
									key='projectedStartDate'
									render={(projectedStartDate: string) =>
										formatDateFunction(projectedStartDate, false)
									}
								/>
								<Table.Column
									title='Projected End Date'
									dataIndex='projectedEndDate'
									key='projectedEndDate'
									render={(projectedEndDate: string) =>
										formatDateFunction(projectedEndDate, false)
									}
								/>
								<Table.Column
									title='Started At'
									dataIndex='startedAt'
									key='startedAt'
									render={(startedAt: string) =>
										formatDateFunction(startedAt, false)
									}
								/>
								<Table.Column
									title='Completed At'
									dataIndex='completedAt'
									key='completedAt'
									render={(completedAt: string) =>
										formatDateFunction(completedAt, false)
									}
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(action: string, item: ProjectActivity) => (
										<Space direction={isMobile ? 'vertical' : 'horizontal'}>
											<Link
												to={{
													pathname: `/${uiPaths.editProjectActivity.replace(
														':id',
														String(item.id),
													)}`,
												}}
											>
												<Button size={'small'} type='primary'>
													Edit
												</Button>
											</Link>
											{item.startedAt ? (
												<>
													<Button size={'small'} type='primary'>
														Stop
													</Button>
												</>
											) : (
												<Popconfirm
													title={'Are you sure you want to remove this? '}
													onConfirm={() => {
														dispatch(remove(item.id));
													}}
													okText='Yes'
													cancelText='No'
												>
													<Button type={'primary'} size={'small'} danger={true}>
														Delete
													</Button>
												</Popconfirm>
											)}
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
