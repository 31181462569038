import {type Site} from 'app/models';

export type GoogleScheduleSheet = {
	id: number;
	sheetURL: string;
	budgetSheetURL?: string;
	siteId: number;
	connected?: boolean;
	dateUpdatedAt?: string;
	site: Site;
};

export enum ScheduleSheetStatus {
	COMPLETED = 'COMPLETED',
	IN_PROGRESS = 'IN_PROGRESS',
	DELAYED = 'DELAYED',
	ADD_NOTE = 'ADD_NOTE',
}
