import React from 'react';
import {formatDateFunction} from 'app/helpers';
import {Col, Form, Row, Space, Typography} from 'antd';
import {
	type ProjectProcessSchedule,
	ProjectScheduleStatus,
} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {ViewParseLink} from 'app/components/Common/ViewParseLink';
import {AttachmentsPreviewBlock} from 'app/components/ProjectProcessSchedule/AttachmentsPreviewBlock';

type Props = {
	projectProcessSchedule: ProjectProcessSchedule;
};

export const ViewProjectProcessScheduleSummary: React.FC<Props> = ({projectProcessSchedule: schedule}) => {
	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	return (
		<Row className='mb-15'>
			<Col span={24}>
				<Typography.Title level={3} style={{textAlign: 'center'}}>{schedule?.projectProcessMaster?.processName ?? ''}</Typography.Title>
				<Typography.Title level={5} style={{textAlign: 'center'}}>{schedule?.site?.name}</Typography.Title>
				<Form {...layout} name='basic'>
					<Form.Item label={<>Planned Start Date</>}><b>{formatDateFunction(schedule?.startDate, false)}</b></Form.Item>
					{schedule?.startedAt ? (
						<Form.Item label={<>Actual Start Date</>}>
							<p><b>{formatDateFunction(schedule?.startedAt, false)}</b></p>
							{schedule?.startedPhotos ? (
								<AttachmentsPreviewBlock attachments={schedule.startedPhotos} />
							) : []}
						</Form.Item>
					) : []}
					<Form.Item label={<>Planned Completion Date</>}><b>{formatDateFunction(schedule?.endDate, false)}</b></Form.Item>
					{schedule?.completedAt ? (
						<Form.Item label={<>Actual Completion Date</>}>
							<p><b>{formatDateFunction(schedule?.completedAt, false)}</b></p>
							{schedule?.completedPhotos ? (
								<AttachmentsPreviewBlock attachments={schedule.completedPhotos} />
							) : []}
						</Form.Item>
					) : []}
					<Form.Item label={<>Current Status</>}>
						{schedule?.status ? (
							<>
								{schedule.status === String(ProjectScheduleStatus.STARTED) ? (
									<Space>
										<b>In Progress</b>
									</Space>
								) : []}
								{schedule.status === String(ProjectScheduleStatus.COMPLETED) ? (
									<b>Completed</b>
								) : []}
							</>
						) : (
							<Space>
								<b>Yet to Start</b>
							</Space>
						)}
					</Form.Item>
					{schedule?.status ? (
						<Form.Item label='In Progress Photographs'>
							{schedule?.inProgressPhotos ? (
								<AttachmentsPreviewBlock attachments={schedule.inProgressPhotos} />
							) : []}
						</Form.Item>
					) : []}
					<Form.Item label={<>{'Process Documents'}</>}>
						{schedule?.projectProcessMaster?.processDocument ? (
							<ViewParseLink parseLink={schedule.projectProcessMaster.processDocument} />
						) : []}
					</Form.Item>
					<Form.Item label={<>{'Reference Documents'}</>}>
						{schedule?.projectProcessMaster?.referenceDocuments ? (
							<ViewParseLink parseLink={schedule.projectProcessMaster.referenceDocuments} />
						) : []}
					</Form.Item>
				</Form>
			</Col>
		</Row>
	);
};
