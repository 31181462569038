import React from 'react';
import {Input, Space} from 'antd';
import {type User} from 'app/models';

type Pops = {
	canAddClient: boolean;
	member: User;
	onChange: (member: User) => void;
};

export const ProjectClientMemberForm: React.FC<Pops> = ({member, canAddClient, onChange}) => (
	<Space direction={'vertical'} style={{width: '100%'}}>
		<Input
			addonBefore={'Name*'}
			disabled={!canAddClient}
			defaultValue={member.name}
			value={member.name}
			style={{width: '100%'}}
			onChange={(e: any) => {
				onChange({...member, ...{name: String(e.target.value)}} as User);
			}}
		/>
		<Input
			addonBefore={'Email*'}
			disabled={!canAddClient}
			value={member.email}
			onChange={(e: any) => {
				onChange({...member, ...{email: String(e.target.value)}} as User);
			}}
		/>
		<Input
			addonBefore={'PAN*'}
			disabled={!canAddClient}
			value={member.panNumber}
			onChange={(e: any) => {
				onChange({...member, ...{panNumber: String(e.target.value)}} as User);
			}}
		/>
		<Input
			addonBefore='Phone Number(s)'
			disabled={!canAddClient}
			value={member.mobileNumbers}
			onChange={(e: any) => {
				onChange({...member, ...{mobileNumbers: String(e.target.value)}} as User);
			}}
		/>
		<Input
			addonBefore='Current Address'
			disabled={!canAddClient}
			value={member.clientCurrentAddress}
			onChange={(e: any) => {
				onChange({...member, ...{clientCurrentAddress: String(e.target.value)}} as User);
			}}
		/>
	</Space>
);

