import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {get} from '../Site/actions';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Col,
	message,
	Row,
	Spin,
	Table,
	Typography,
} from 'antd';
import {SiteStatus} from 'app/models';

type DataSourceType = {
	key: number;
	name: string;
	pendingRoleAssignment: number;
};

export const ViewPendingRoleAssignmentReport: React.FC = () => {
	const dispatch = useDispatch();
	const {byIds, allIds, loading, errorMessage, dataUpdated} = useSelector(
		(state: Types.RootState) => state.site,
	);

	React.useEffect(() => {
		dispatch(
			get({
				where: {
					isLive: true,
					/* Status: {
            inq: [SiteStatus.UNDER_DESIGN, SiteStatus.UNDER_CONSTRUCTION],
          }, */
				},
			}),
		);
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			dispatch(
				get({
					where: {
						isLive: true,
						/* Status: {
              inq: [SiteStatus.UNDER_DESIGN, SiteStatus.UNDER_CONSTRUCTION],
            }, */
					},
				}),
			);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const sites = allIds && byIds ? allIds.map(id => byIds[id]) : [];

	const dataSource: DataSourceType[] = sites.map(site => {
		let count = 0;
		if (!site.approvalsInchargeId) {
			count++;
		}

		if (!site.clientInterfaceAccountsId) {
			count++;
		}

		if (!site.clientInterfaceApprovalsId) {
			count++;
		}

		if (!site.clientInterfaceConstructionId) {
			count++;
		}

		if (!site.clientInterfaceDesignId) {
			count++;
		}

		if (!site.clientInvoicingInchargeId) {
			count++;
		}

		if (!site.procurementInchargeId) {
			count++;
		}

		if (!site.projectArchitectId) {
			count++;
		}

		if (!site.siteInchargeId) {
			count++;
		}

		if (!site.quantityCalculationId) {
			count++;
		}

		if (!site.contractorAppointmentBillingAndTerminationId) {
			count++;
		}

		return {
			key: site.id,
			name: site.name,
			pendingRoleAssignment: count,
		};
	});
	console.log(dataSource);
	return (
		<DefaultLayout currentPath={uiPaths.pendingRoleAssignmentReport}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Pending Role Assignment Report
						</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='mb-10'>
						<Table
							bordered={true}
							size={'small'}
							dataSource={dataSource}
							scroll={{x: 2000, y: 1000}}
							pagination={false}
						>
							<Table.Column title='Name' dataIndex='name' key='name' />
							<Table.Column
								title='Pending Roles To Be Assigned'
								dataIndex='pendingRoleAssignment'
								key='pendingRoleAssignment'
							/>
						</Table>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
