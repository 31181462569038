import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Button, Col, Descriptions, Empty, Input, message, Modal, Row, Space, Spin, Tag, Typography} from 'antd';
import {uiPaths} from 'app/constants';
import {approve, get, initPage, reject} from './actions';
import {type History} from 'history';
import {type match as Match} from 'react-router';
import {
	formatDateFunction,
	getCurrencyString,
	getHrefLink,
	getS3Url,
	hasPermission,

} from 'app/helpers';
import {PaperClipOutlined} from '@ant-design/icons';
import {type UploadFile} from 'app/models/upload-file';
import {log} from 'app/services/logger-service';
import {type Attachment, ExpenseStatus, ModuleName, ModulePermission, QueryParentType} from 'app/models';
import {ViewChildQuery} from 'app/components/Query/ViewChildQuery';

type MyParams = {
	id?: string;
};

type OwnProps = {
	match: Match<MyParams>;
	history: History;
};

const mapStateToProps = ({
	expenseReport,
	summary: {user: authUser, users},
	userPermission: {modulePermissionMap: permissions},
}: Types.RootState, ownProps: OwnProps) => ({
	...expenseReport,
	permissions,
	users,
	authUser,
	...ownProps,
});

const mapDispatchToProps = {
	initPage,
	get,
	approve,
	reject,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

type State = {
	fileList: UploadFile[];
	filePrefixKeys: Record<string, string>;
	assignRequestId?: number;
	assignedLabourId?: number;
	cancelReason: string;
	cancelRequestId?: number;
};

const initialState = {
	fileList: [],
	filePrefixKeys: {},
	assignRequestId: undefined,
	assignedLabourId: undefined,
	cancelReason: '',
	cancelRequestId: undefined,
};

class ViewPettyCashDetail extends React.Component<Props, State> {
	recordId: number;
	canApprove: boolean;
	canWrite: boolean;

	constructor(props: Props) {
		super(props);
		this.recordId = parseInt(props.match?.params?.id ?? '0', 10);

		this.state = initialState;

		const {permissions} = this.props;
		this.canApprove = hasPermission(permissions[ModuleName.EXPENSE_REPORT], ModulePermission.APPROVE);
		this.canWrite = hasPermission(permissions[ModuleName.EXPENSE_REPORT], ModulePermission.WRITE);
	}

	componentDidMount() {
		log('ViewPettyCashDetail.componentDidMount');
		this.props.initPage();
		const {byIds} = this.props;
		if (!byIds?.[this.recordId]) {
			this.props.get({where: {id: this.recordId}});
		}
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			this.setState(initialState);
			this.props.get({
				where: {id: this.recordId},
				include: [
					{relation: 'attachments'},
				],
			});
		}

		if (nextProps.errorMessage) {
			void message.error(nextProps.errorMessage);
		}
	}

	handleCancelRequest = () => {
		const {cancelReason, cancelRequestId: id} = this.state;
		if (!id) {
			return;
		}

		this.props.reject(id, {cancelReason});
		this.setState({cancelRequestId: undefined, cancelReason: ''});
	};

	// eslint-disable-next-line complexity
	render() {
		const {history, loading, byIds} = this.props;

		const record = byIds[this.recordId];

		let color: string;
		if (record?.status === ExpenseStatus.APPROVED) {
			color = 'green';
		} else if (record?.status === ExpenseStatus.REJECTED) {
			color = 'red';
		} else if (record?.status === ExpenseStatus.REFUND) {
			color = 'blue';
		} else {
			color = 'orange';
		}

		if (!record) {
			return (
				<DefaultLayout currentPath={uiPaths.pettyCashDetail}>
					<Empty />
				</DefaultLayout>
			);
		}

		return (
			<DefaultLayout currentPath={uiPaths.pettyCashDetail}>
				<Spin
					size='large'
					spinning={loading}
					tip={'Loading...'}
				>
					<Row className='mb-15'>
						<Col span={24}>
							<Typography.Title level={2} style={{textAlign: 'center'}}>Petty Cash Detail</Typography.Title>
							<Button onClick={e => {
								history.goBack();
							}}>Back</Button>
						</Col>
					</Row>

					<Row className='mb-15'>
						<Col span={24}>
							<Descriptions bordered={true} column={1}>
								<Descriptions.Item label='Serial No.'>{record.sno ?? 'N/A'}</Descriptions.Item>
								<Descriptions.Item label='Status'>
									<Tag color={color}>
										{record.status ?? ExpenseStatus.PENDING}
									</Tag>
								</Descriptions.Item>
								<Descriptions.Item label='Date'>{formatDateFunction(record.createdAt)}</Descriptions.Item>
								<Descriptions.Item label='Site'>{record.site?.name ?? ''}</Descriptions.Item>
								<Descriptions.Item label='Created By'>{record.createdUser?.name ?? ''}</Descriptions.Item>
								<Descriptions.Item label='Description'>{record.expenseDescription}</Descriptions.Item>
								<Descriptions.Item label='Attachments'>
									<Space>
										{record.attachments?.length
											? record.attachments.map((attachment: Attachment, ixx: number) => (
												<a key={ixx} href={getS3Url(attachment.key ?? '')} title={attachment.name} target='_blank' rel='noreferrer'>
													<PaperClipOutlined />
												</a>
											))
											: []}
									</Space>
								</Descriptions.Item>
								<Descriptions.Item label='Expense Amount'>
									<span>
										{record.foodBeverageExpense ? `Food and Beverage: ${getCurrencyString(record.foodBeverageExpense * -1)}` : ''}
										{record.siteLoadingUnloadingExpense ? `Site Loading-unloading: ${getCurrencyString(record.siteLoadingUnloadingExpense * -1)}` : ''}
										{record.siteMaterialExpense ? `Site Material: ${getCurrencyString(record.siteMaterialExpense * -1)}` : ''}
										{record.siteStationeryExpense ? `Site Stationery: ${getCurrencyString(record.siteStationeryExpense * -1)}` : ''}
										{record.conveyance ? `Conveyance: ${getCurrencyString(record.conveyance * -1)}` : ''}
										{record.labourExpense ? `Labour: ${getCurrencyString(record.labourExpense * -1)}` : ''}
										{record.waterAndElectricityBill ? `Water and Electricity Bill: ${getCurrencyString(record.waterAndElectricityBill * -1)}` : ''}
										{record.laisioningExpense ? `Laisioning Expense: ${getCurrencyString(record.laisioningExpense * -1)}` : ''}
										{record.malwaRemoval ? `Malwa Removal: ${getCurrencyString(record.malwaRemoval * -1)}` : ''}
										{record.scrapAmount ? `Scrap Amount: ${getCurrencyString(record.scrapAmount * -1)}` : ''}
										{record.solarAndWaterAutomation ? `Solar and Water Automation: ${getCurrencyString(record.solarAndWaterAutomation * -1)}` : ''}
										{record.otherExpense ? `Other: ${getCurrencyString(record.otherExpense * -1)}` : ''}
										{record.amountReceived ? `Amount received: ${getCurrencyString(Number(record.amountReceived))}` : ''}
									</span>
								</Descriptions.Item>
								{record.labourExpense ? (
									<>
										<Descriptions.Item label='Debited to Contractor'>
											{record.debitedToContractor ? 'Yes' : 'No'}
										</Descriptions.Item>
										{record.debitedToContractor ? (
											<>
												<Descriptions.Item label='Debited Work Order'>
													{record.debitWorkOrder ? (
														<a target='_blank' href={getHrefLink(uiPaths.workOrderDetail, record.debitWorkOrder.id)} rel='noreferrer'>
															{record.debitWorkOrder.title}
														</a>
													) : []}
												</Descriptions.Item>
												<Descriptions.Item label='Contractor has been Informed'>
													{record.contractorInformed ? 'Yes' : 'No'}
												</Descriptions.Item>
												{record.contractorInformed ? (
													<Descriptions.Item label='Contractor Communication Attachments'>
														<Space>
															{record.contractorInformedFiles?.split(',').length
																? record.contractorInformedFiles.split(',').map((fileKey: string, ixx: number) => (
																	<a key={ixx} href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
																		<PaperClipOutlined />
																	</a>
																)) : []}
														</Space>
													</Descriptions.Item>
												) : (
													<Descriptions.Item label='Contractor not Informed Reason'>
														{record?.contractorNotInformedReason ?? ''}
													</Descriptions.Item>
												)}
											</>
										) : (
											<Descriptions.Item label='Not Debiting to Contractor Reason'>
												{record?.notDebitedToContractorReason ?? ''}
											</Descriptions.Item>
										)}
									</>
								) : []}
							</Descriptions>
						</Col>
					</Row>

					{record?.siteId ? (
						<ViewChildQuery
							parentType={QueryParentType.PETTY_CASH}
							parentId={String(this.recordId)}
							siteId={record.siteId}
							module={ModuleName.EXPENSE_REPORT}
						/>
					) : []}

					<Modal
						title='Cancel Request'
						open={Boolean(this.state.cancelRequestId)}
						onOk={this.handleCancelRequest}
						onCancel={() => {
							this.setState({cancelRequestId: undefined, cancelReason: ''});
						}}
					>
						<Space direction={'vertical'}>
							<label>Please provide a reason to cancel this request</label>
							<Input.TextArea
								placeholder={'Enter Cancel Reason'}
								defaultValue={this.state.cancelReason ?? ''}
								value={this.state.cancelReason ?? ''}
								onChange={(e: any) => {
									this.setState({cancelReason: String(e.target.value)});
								}}
							/>
						</Space>
					</Modal>
				</Spin>
			</DefaultLayout>
		);
	}
}

export default connector(ViewPettyCashDetail);
