import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Empty,
	message,
	Popconfirm,
	Row,
	Space,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
	removeAll,
} from './actions';
import {Link} from 'react-router-dom';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';
import {parseNum, toTitleCase} from 'app/helpers';
import {ModulePermission, moduleLabelName, type UserPermission, type Site, type User, permissionLabels, ExpenseType, SiteStatus} from 'app/models';
import {get as getSites} from '../Site/actions';

export const ViewAllUserPermission: React.FC = () => {
	const dispatch = useDispatch();
	const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

	const {
		loading,
		byIds,
		allIds,
		filterValue,
		dataUpdated,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
	} = useSelector((state: Types.RootState) => state.userPermission);

	const {userOptions} = useSelector((state: Types.RootState) => state.summary);
	const {byIds: siteByIds, allIds: allSiteIds} = useSelector((state: Types.RootState) => state.site);

	React.useEffect(() => {
		dispatch(getSites({where: {isLive: true}}));
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleRemoveAll = () => {
		const ids = selectedRowKeys.map(key => parseNum(key));
		if (ids?.length) {
			dispatch(removeAll({id: {inq: ids}}));
		}
	};

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f.siteStatus) {
			filter.where.siteId = {inq: allSiteIds.filter(id =>
				siteByIds[id].status === f.siteStatus,
			)};
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.userId) {
			filter.where.userId = parseNum(f.userId as string);
		}

		if (f.module) {
			filter.where.moduleName = f.module;
		}

		if (f.permission) {
			filter.where.permission = f.permission;
		}

		if (f.level) {
			filter.where.level = f.level;
		}

		if (f.condition) {
			filter.where.condition = f.condition;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const dataSource: UserPermission[] = allIds?.length
		? allIds.map(id => ({
			...{key: String(id)},
			...byIds[id],
		}))
		: [];

	const siteOptions: FilterOptionItem[] = allSiteIds.map(id => siteByIds[id]).filter(
		(site: Site) =>
			filterValue?.siteStatus ? site.status === filterValue?.siteStatus : true,
	).map(site => ({label: site.name, value: String(site.id)}));

	const permissionOptions: FilterOptionItem[] = [
		{value: ModulePermission.READ, label: 'View'},
		{value: ModulePermission.WRITE, label: 'Edit'},
		{value: ModulePermission.APPROVE, label: 'Approve'},
		{value: 'UPDATE_STATUS', label: 'Update Status'},
		{value: 'CANCEL_ONLY', label: 'Cancel Only'},
	];
	const levelOptions = [
		{value: '1', label: '1'},
		{value: '2', label: '2'},
		{value: '3', label: '3'},
		{value: '4', label: '4'},
	];
	const moduleOptions = Object.keys(moduleLabelName).map((module: string) => (
		{value: module, label: moduleLabelName[module]}
	));

	const conditionOptions = Object.values(ExpenseType).map(condition => ({
		value: condition,
		label: toTitleCase(condition) ?? '',
	}));

	const siteStatusOptions = Object.values(SiteStatus).map(status => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	const uiFilters = [
		{
			filterKey: 'siteStatus',
			items: siteStatusOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status',
			label: 'Site Status',
			defaultValue: filterValue?.siteStatus,
		},
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'userId',
			items: userOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select User',
			label: 'User',
			defaultValue: filterValue?.userId,
		},
		{
			filterKey: 'module',
			items: moduleOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Module',
			label: 'Module',
			defaultValue: filterValue?.module,
		},
		{
			filterKey: 'permission',
			items: permissionOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Permission',
			label: 'Permission',
			defaultValue: filterValue?.permission,
		},
		{
			filterKey: 'level',
			items: levelOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Level',
			label: 'Level',
			defaultValue: filterValue?.level,
		},
		{
			filterKey: 'condition',
			items: conditionOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Condition',
			label: 'Condition',
			defaultValue: filterValue?.condition,
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.allPermissions}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							User Permissions
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
					</Col>
					<Col span={12} className='mb-10'>
						{selectedRowKeys.length ? (
							<Space>
								<Popconfirm
									title={`Are you sure you want to delete ${selectedRowKeys.length} permissions?`}
									onConfirm={() => {
										handleRemoveAll();
									}}
								>
									<Button type={'primary'}>Remove All</Button>
								</Popconfirm>
							</Space>
						) : (
							[]
						)}
					</Col>
				</Row>

				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Link
							to={{
								pathname: `/${uiPaths.addPermissions}`,
							}}
						>
							<Button type='primary'>Add Permissions</Button>
						</Link>
						<br />
						<br />
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
								rowSelection={{
									selectedRowKeys,
									onChange: onSelectChange,
								}}
							>
								<Table.Column
									title='Site'
									dataIndex='site'
									key='siteName'
									render={(site: Site | undefined) => site?.name ?? ''}
								/>
								<Table.Column
									title='User'
									dataIndex='user'
									key='userName'
									render={(user: User | undefined) => user?.name ?? ''}
								/>
								<Table.Column
									title='Module Name'
									dataIndex='moduleName'
									key='moduleName'
									render={(moduleName: string) =>
										moduleLabelName[moduleName] ?? toTitleCase(moduleName)
									}
								/>
								<Table.Column
									title='Permission'
									dataIndex='permission'
									key='permission'
									render={(permission: string) =>
										permissionLabels[permission] ?? toTitleCase(permission)
									}
								/>
								<Table.Column
									title='Level'
									dataIndex='level'
									key='level'
								/>
								<Table.Column
									title='Condition'
									dataIndex='condition'
									key='condition'
									render={(condition: string) => toTitleCase(condition)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
