import React from 'react';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useSelector, useDispatch} from 'react-redux';
import {type match as Match} from 'react-router';
import type Types from 'MyTypes';
import {getById} from './actions';
import {formatDateFunction} from 'app/helpers';
import {Spin, Row, Col, Button, Typography, Form, Space, Image} from 'antd';
import {type History} from 'history';
import {AttachmentsPreviewBlock} from 'app/components/ProjectProcessSchedule/AttachmentsPreviewBlock';
import {ProjectScheduleStatus} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {getS3Url} from 'app/helpers';
import {type ProcessChecklist, ProcessChecklistOnActionType} from 'app/models/process-checklist.model';
import {ProcessChecklistResponseStatus} from 'app/models/process-checklist-response.model';
import {ProcessChecklistBlock} from 'app/components/ProjectProcessSchedule/ProcessChecklistBlock';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';
import {type Attachment} from 'app/models';

type MyParams = {
	id?: string;
};

type Props = {
	match: Match<MyParams>;
	history: History;
};

export const ViewClientProjectProcessScheduleDetail: React.FC<Props> = ({match, history}) => {
	const dispatch = useDispatch();
	const {byIds, loading} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	const id = parseInt(match?.params?.id ?? '0', 10);
	const schedule = id ? byIds[id] : undefined;

	React.useEffect(() => {
		console.log('ViewProjectProcessScheduleForm.componentDidMount');
		dispatch(getById(id));
	}, []);

	const categorizeChecklist = () => {
		const afterCompletionFilledChecklists: ProcessChecklist[] = [];
		const beforeStartFilledChecklists: ProcessChecklist[] = [];
		const beforeStartUnfilledChecklists: ProcessChecklist[] = [];
		const inProgressFilledChecklists: ProcessChecklist[] = [];
		const inProgressUnfilledChecklists: ProcessChecklist[] = [];
		const afterCompletionUnfilledChecklists: ProcessChecklist[] = [];

		schedule?.projectProcessMaster?.processChecklists?.forEach(checklist => {
			if (checklist.onActionType === ProcessChecklistOnActionType.BEFORE_START) {
				const checklistResponse = schedule?.checklistResponses?.find(res => res.processChecklistId === checklist.id);
				if (checklistResponse?.status) {
					if (checklistResponse?.status === ProcessChecklistResponseStatus.APPROVED
							|| checklistResponse?.status === ProcessChecklistResponseStatus.REJECTED
							|| checklistResponse?.status === ProcessChecklistResponseStatus.SUBMITTED) {
						beforeStartFilledChecklists.push(checklist);
					} else {
						beforeStartUnfilledChecklists.push(checklist);
					}
				} else {
					beforeStartUnfilledChecklists.push(checklist);
				}
			}

			if (checklist.onActionType === ProcessChecklistOnActionType.IN_PROGRESS) {
				const checklistResponse = schedule?.checklistResponses?.find(res => res.processChecklistId === checklist.id);
				if (checklistResponse?.status) {
					if (checklistResponse?.status === ProcessChecklistResponseStatus.APPROVED
							|| checklistResponse?.status === ProcessChecklistResponseStatus.REJECTED
							|| checklistResponse?.status === ProcessChecklistResponseStatus.SUBMITTED) {
						inProgressFilledChecklists.push(checklist);
					} else {
						inProgressUnfilledChecklists.push(checklist);
					}
				} else {
					inProgressUnfilledChecklists.push(checklist);
				}
			}

			if (checklist.onActionType === ProcessChecklistOnActionType.AFTER_COMPLETION) {
				const checklistResponse = schedule?.checklistResponses?.find(res => res.processChecklistId === checklist.id);
				if (checklistResponse?.status) {
					if (checklistResponse?.status === ProcessChecklistResponseStatus.APPROVED
							|| checklistResponse?.status === ProcessChecklistResponseStatus.REJECTED
							|| checklistResponse?.status === ProcessChecklistResponseStatus.SUBMITTED) {
						afterCompletionFilledChecklists.push(checklist);
					} else {
						afterCompletionUnfilledChecklists.push(checklist);
					}
				} else {
					afterCompletionUnfilledChecklists.push(checklist);
				}
			}
		});
		return {
			beforeStartFilledChecklists,
			beforeStartUnfilledChecklists,
			inProgressFilledChecklists,
			inProgressUnfilledChecklists,
			afterCompletionFilledChecklists,
			afterCompletionUnfilledChecklists,
		};
	};

	const checklistCategories = categorizeChecklist();

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const mapOfPhotos: Record<string, Attachment[]> = {};
	schedule?.inProgressPhotos?.reverse().forEach(item => {
		if (mapOfPhotos[formatDateFunction(item.createdAt, false)] === undefined) {
			mapOfPhotos[formatDateFunction(item.createdAt, false)] = [];
		}

		mapOfPhotos[formatDateFunction(item.createdAt, false)].push(item);
	});

	return (
		<DefaultLayout currentPath={uiPaths.clientProjectProcessScheduleDetail}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							{schedule?.projectProcessMaster?.processName ?? ''}
						</Typography.Title>
						<Form {...layout} name='basic'>
							{schedule?.startedPhotos?.length ? (
								<Form.Item label={<>Start Photographs</>}>
									<AttachmentsPreviewBlock
										attachments={schedule.startedPhotos}
									/>
								</Form.Item>
							) : (
								[]
							)}
							{schedule?.completedPhotos?.length ? (
								<Form.Item label={<>Completion Photographs</>}>
									<AttachmentsPreviewBlock
										attachments={schedule.completedPhotos}
									/>
								</Form.Item>
							) : (
								[]
							)}
							<Form.Item label={<>Current Status</>}>
								{schedule?.status ? (
									<>
										{schedule.status === String(ProjectScheduleStatus.STARTED) ? (
											<b>In Progress</b>
										) : (
											[]
										)}
										{schedule.status === String(ProjectScheduleStatus.COMPLETED) ? (
											<b>Completed</b>
										) : (
											[]
										)}
									</>
								) : (
									<b>Yet to Start</b>
								)}
							</Form.Item>
							{schedule?.inProgressPhotos?.length ? (
								<Form.Item label='In Progress Photographs'>
									{Object.entries(mapOfPhotos).map(([k, v]) => (
										<>
											<br />
											<b>{k}</b>
											<br />
											<br />
											<Space>
												{v.map((photo, ix) => (
													<Image
														key={ix}
														style={{marginLeft: '10px'}}
														width={80}
														src={getS3Url(photo.key ?? '')}
													/>
												))}
											</Space>
										</>
									))}
								</Form.Item>
							) : (
								[]
							)}
							{schedule?.status && schedule.projectProcessMaster?.projectProcessType === ProjectProcessType.CONSTRUCTION ? (
								<>
									{checklistCategories.beforeStartFilledChecklists.length
										?? checklistCategories.inProgressFilledChecklists.length
										?? checklistCategories.afterCompletionFilledChecklists.length ? (
											<Form.Item label={'Filled Checklists'}>
												{[...checklistCategories.beforeStartFilledChecklists, ...checklistCategories.inProgressFilledChecklists, ...checklistCategories.afterCompletionFilledChecklists].map(
													(checklist, ix) => (
														<ProcessChecklistBlock
															key={ix}
															checklist={checklist}
															checklistResponse={schedule?.checklistResponses?.find(
																res =>
																	res.processChecklistId === checklist.id,
															)}
															scheduleId={schedule.id}
															siteId={schedule.siteId}
															projectProcessType={schedule.projectProcessType}
														/>
													),
												)}
											</Form.Item>) : []
									}
								</>
							) : []}
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
