import {type User} from './user.model';
import {type ExpenseAmountType} from '../components/ExpenseReport/constants';

export type PaidExpense = {
	id: string;
	userId: number;
	expenseFrom: string;
	expenseTo: string;
	amount: number;
	pendingAmount?: number;
	balanceAmount?: number;
	limitAmount?: number;
	typeAmounts?: string;
	expenseTypeAmounts?: ExpenseAmountType;
	status: PaidExpenseStatus;
	user?: User;

	key?: string;
};

export enum PaidExpenseStatus {
	CREATED = 'CREATED',
	PAID = 'PAID',
}
