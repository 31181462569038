import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	initPage: '@expenseReport/INIT_PAGE',
	changePage: '@expenseReport/CHANGE_PAGE',
	changeAllPage: '@expenseReport/CHANGE_ALL_PAGE',
	apiFilter: '@expenseReport/API_FILTER',
	approvedApiFilter: '@expenseReport/APPROVED_API_FILTER',
	paginationChange: '@expenseReport/PAGINATION_CHANGE',
	approvedPaginationChange: '@expenseReport/APPROVED_PAGINATION_CHANGE',
	getApi: '@expenseReport/GET_API',
	getCountApi: '@expenseReport/GET_COUNT_API',
	getSingleApi: '@expenseReport/GET_SINGLE_API',
	getUserWalletApi: '@expenseReport/GET_USER_WALLET_API',
	createApi: '@expenseReport/CREATE_API',
	updateApi: '@expenseReport/UPDATE_API',
};

const url = lbBaseUrl + '/expense-reports';
const walletUrl = lbBaseUrl + '/prithu-wallets';

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const changePage = (pageType: string) => ({
	type: actionTypes.changePage,
	payload: pageType,
	meta: {},
});

export const changeAllPage = (pageType: string) => ({
	type: actionTypes.changeAllPage,
	payload: pageType,
	meta: {},
});

export const onPaginationChange = (currentPos: number, perPageSize: number) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const onApprovedPaginationChange = (approvedCurrentPos: number, approvedPerPageSize: number) => ({
	type: actionTypes.approvedPaginationChange,
	payload: {approvedCurrentPos, approvedPerPageSize},
	meta: {},
});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const setApprovedFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.approvedApiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(`${url}/count?where=${JSON.stringify(where)}`, getHeaders(true)),
	meta: {process: true},
});

export const getById = (id: number, filter: any = {}) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const getUserWallet = (userId: number) => ({
	type: actionTypes.getUserWalletApi,
	payload: axios.get(`${walletUrl}/users?user_id=${userId}`, getHeaders(true)),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const approve = (id: number) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/approve`, undefined, getHeaders(true)),
	meta: {process: true},
});

export const reject = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/reject`, data, getHeaders(true)),
	meta: {process: true},
});
