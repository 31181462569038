import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import axios from 'axios';

export const actionTypes = {
	getApi: '@emailTemplate/GET_API',
	createApi: '@emailTemplate/CREATE_API',
	updateApi: '@emailTemplate/UPDATE_API',
	changePage: '@emailTemplate/CHANGE_PAGE',
	paginationChange: '@emailTemplate/PAGINATION_CHANGE',
	getCountApi: '@emailTemplate/GET_COUNT_API',
	getSingleApi: '@emailTemplate/GET_SINGLE_API',
	testApi: '@emailTemplate/TEST_API',
};

const url = lbBaseUrl + '/email-templates';

export const changePage = (pageType: string) => ({type: actionTypes.changePage, payload: pageType, meta: {}});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const getById = (id: number, filter: any = {}) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const onPaginationChange = (currentPos: number, perPageSize: number) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(`${url}/count?where=${JSON.stringify(where)}`, getHeaders(true)),
	meta: {process: true},
});

export const remove = (id: number) => ({
	type: actionTypes.updateApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});
