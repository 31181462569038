// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/DefaultLayout.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,sBAAA;AACJ;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,YAAA;EACA,oCAAA;EACA,YAAA;AADJ;AAIA;EACI,gBAAA;AAFJ","sourcesContent":["#components-layout-demo-custom-trigger .trigger {\n    font-size: 18px;\n    line-height: 64px;\n    padding: 0 24px;\n    cursor: pointer;\n    transition: color 0.3s;\n}\n\n#components-layout-demo-custom-trigger .trigger:hover {\n    color: #1890ff;\n}\n\n#components-layout-demo-custom-trigger .logo {\n    height: 32px;\n    background: rgba(255, 255, 255, 0.2);\n    margin: 16px;\n}\n\n.site-layout .site-layout-background {\n    background: #fff;\n}\n\n@primary-color: #BC982F;@border-radius-base: 10px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
