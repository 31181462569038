import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	SiteStatus,
	ModuleName,
	ModulePermission,
	type ScheduleDelayReport,
} from 'app/models';
import {type User} from 'app/models';
import {get as getUserPermission} from '../UserPermission/actions';
import type Types from 'MyTypes';
import {get as getSites} from '../Site/actions';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {
	mutateTree,
	getKey,
} from 'app/services/report-service';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
	createUserTree,
	emptyNode,
	populateDataSourceTree,
} from './ViewHierarchyReport';
import {getScheduleDelayReport} from './schedule-delay-report-reducer';
import {formatDateFunction, parseNum} from 'app/helpers';

export const ViewScheduleActivityDelayReport: React.FC = () => {
	const dispatch = useDispatch();
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.scheduleDelayReport,
	);
	const today = new Date();

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, Record<number, ScheduleDelayReport>>>(
		(record, item) => {
			const date = formatDateFunction(item.reportDate, false);
			if (!record[date]) {
				record[date] = {};
			}

			record[date][item.siteId] = item;
			return record;
		},
		{},
	);

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.map(d => ({
			title: d,
			key: getKey(d),
			width: 50,
		}));

	// Fetching all sites, user permissions of READ for construction schedule module,and the reports of past 5 weeks from the current date
	React.useEffect(() => {
		dispatch(
			getScheduleDelayReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						34,
						'reportDate',
					),
					isWeekly: true,
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(
			getSites({
				where: {
					status: {
						inq: [SiteStatus.UNDER_CONSTRUCTION, SiteStatus.UNDER_DESIGN],
					},
				},
			}),
		);
		dispatch(
			getUserPermission({
				where: {
					moduleName: ModuleName.CONSTRUCTION_SCHEDULE,
					permission: ModulePermission.READ,
				},
			}),
		);
	}, []);

	const createDataSourceTree = (node: DataSourceType) => {
		const nodeKeys = node.key.split('-');
		if (nodeKeys[0] === 'user') {
			const userId = parseNum(nodeKeys[1]);
			// The user is site Incharge
			const siteChildren = allSites.filter(site => site.siteInchargeId === userId).map(site => {
				const x: DataSourceType = {
					key: 'site-' + String(site.id),
					name: site?.name ?? '',
					records: Object.keys(mapOfRecords).reduce<Record<string, number>>((record, d) => {
						record[getKey(d)] = mapOfRecords[d]?.[site.id]?.delayedActivities ?? 0;
						return record;
					}, {}),
				};
				return x;
			});

			if (siteChildren?.length) {
				if (node.children?.length) {
					node.children.push(...siteChildren);
				} else {
					node.children = siteChildren;
				}
			}

			if (node.children?.length) {
				node.children.forEach(childNode =>
					createDataSourceTree(childNode),
				);
			}
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites.map(({siteInchargeId}) => siteInchargeId ?? 0),
	);

	const userTree = roots.map(user =>
		createUserTree({...emptyNode}, user),
	);

	const initDataSource = userTree.map(userNode =>
		createDataSourceTree(userNode),
	);

	const dataSource = initDataSource.map(node =>
		populateDataSourceTree(node),
	);

	return (
		<DefaultLayout
			currentPath={uiPaths.projectScheduleActivityDelayReport}
		>
			<ViewHierarchyReport
				title='Started, But not completed on time Activity Delay Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={`
        This report presents how many activities are there on site
        which are in the “Started” stage but did not get completed in its ideal duration.Data is
        captured every week on Tuesday at 12:00 AM in the night. If there is any increase
        in the number of activities which are in the “started” stage but not completed on time
        as compared to the previous week, it means that in the last 7 days, more activities
        have come under the category that started but not completed in the ideal duration.
        Similarly, If there is any decrease in the number as compared to the previous week, it
        means that in the last 7 days some of the activities have been marked as
        “Completed”.
      `}
				reportLogic={`
        Based upon the delayed completion of the Started activities for each
        site, total number of the activities “which are started but not completed in ideal
        duration + 2 days” is calculated for Construction In Charge and APMs reporting under
        him and beyond. To read the report, you can expand the “+” sign in front of the
        Reporting Manager name to see the list of employees reporting to him.
        If you have any query related to this report or a suggestion to improvise the report.
        Please Contact ruchika.singh@prithu.in `}
			/>
		</DefaultLayout>
	);
};
