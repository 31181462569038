import React from 'react';
import {formatDateFunction, getCurrencyString, getHrefLink, getS3Url} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {Col, Descriptions, Row, Space, Tag} from 'antd';
import {type ExpenseReport, ExpenseStatus, type Attachment} from 'app/models';
import {PaperClipOutlined} from '@ant-design/icons';

type Props = {
	expenseReport: ExpenseReport;
};

// eslint-disable-next-line complexity
export const ViewPettyCashSummary: React.FC<Props> = ({expenseReport: record}) => {
	let totalExpense = 0;
	totalExpense += record.amountReceived ?? 0;
	totalExpense -= record.foodBeverageExpense ?? 0;
	totalExpense -= record.siteLoadingUnloadingExpense ?? 0;
	totalExpense -= record.siteMaterialExpense ?? 0;
	totalExpense -= record.siteStationeryExpense ?? 0;
	totalExpense -= record.conveyance ?? 0;
	totalExpense -= record.labourExpense ?? 0;
	totalExpense -= record.waterAndElectricityBill ?? 0;
	totalExpense -= record.otherExpense ?? 0;
	let color: string;
	if (record.status === ExpenseStatus.APPROVED) {
		color = 'green';
	} else if (record.status === ExpenseStatus.REJECTED) {
		color = 'red';
	} else if (record.status === ExpenseStatus.REFUND) {
		color = 'blue';
	} else {
		color = 'orange';
	}

	return (
		<Row className='mb-15'>
			<Col span={24}>
				<Descriptions bordered={true} column={1}>
					<Descriptions.Item label='Serial No.'>{record.sno ?? 'N/A'}</Descriptions.Item>
					<Descriptions.Item label='Status'>
						<Tag color={color}>
							{record.status ?? ExpenseStatus.PENDING}
						</Tag>
					</Descriptions.Item>
					<Descriptions.Item label='Date'>{formatDateFunction(record.createdAt)}</Descriptions.Item>
					<Descriptions.Item label='Site'>{record.site?.name ?? ''}</Descriptions.Item>
					<Descriptions.Item label='Created By'>{record.createdUser?.name ?? ''}</Descriptions.Item>
					<Descriptions.Item label='Description'>{record.expenseDescription}</Descriptions.Item>
					<Descriptions.Item label='Attachments'>
						<Space>
							{record.attachments?.length
								? record.attachments.map((attachment: Attachment, ixx: number) => (
									<a key={ixx} href={getS3Url(attachment.key ?? '')} title={attachment.name} target='_blank' rel='noreferrer'>
										<PaperClipOutlined/>
									</a>
								))
								: []}
						</Space>
					</Descriptions.Item>
					<Descriptions.Item label='Expense Amount'>
						<span>
							{record.foodBeverageExpense ? `Food and Beverage: ${getCurrencyString(record.foodBeverageExpense * -1)}` : ''}
							{record.siteLoadingUnloadingExpense ? `Site Loading-unloading: ${getCurrencyString(record.siteLoadingUnloadingExpense * -1)}` : ''}
							{record.siteMaterialExpense ? `Site Material: ${getCurrencyString(record.siteMaterialExpense * -1)}` : ''}
							{record.siteStationeryExpense ? `Site Stationery: ${getCurrencyString(record.siteStationeryExpense * -1)}` : ''}
							{record.conveyance ? `Conveyance: ${getCurrencyString(record.conveyance * -1)}` : ''}
							{record.labourExpense ? `Labour: ${getCurrencyString(record.labourExpense * -1)}` : ''}
							{record.waterAndElectricityBill ? `Water and Electricity Bill: ${getCurrencyString(record.waterAndElectricityBill * -1)}` : ''}
							{record.otherExpense ? `Other: ${getCurrencyString(record.otherExpense * -1)}` : ''}
							{record.amountReceived ? `Amount received: ${getCurrencyString(record.amountReceived)}` : ''}
						</span>
					</Descriptions.Item>
					{record.labourExpense ? (
						<>
							<Descriptions.Item label='Debited to Contractor'>
								{record.debitedToContractor ? 'Yes' : 'No'}
							</Descriptions.Item>
							{record.debitedToContractor ? (
								<>
									<Descriptions.Item label='Debited Work Order'>
										{record.debitWorkOrder ? (
											<a target='_blank' href={getHrefLink(uiPaths.workOrderDetail, record.debitWorkOrder.id)} rel='noreferrer'>
												{record.debitWorkOrder.title}
											</a>
										) : []}
									</Descriptions.Item>
									<Descriptions.Item label='Contractor has been Informed'>
										{record.contractorInformed ? 'Yes' : 'No'}
									</Descriptions.Item>
									{record.contractorInformed ? (
										<Descriptions.Item label='Contractor Communication Attachments'>
											<Space>
												{record.contractorInformedFiles?.split(',').length
													? record.contractorInformedFiles.split(',').map((fileKey: string, ixx: number) => (
														<a key={ixx} href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
															<PaperClipOutlined />
														</a>
													)) : []}
											</Space>
										</Descriptions.Item>
									) : (
										<Descriptions.Item label='Contractor not Informed Reason'>
											{record?.contractorNotInformedReason ?? ''}
										</Descriptions.Item>
									)}
								</>
							) : (
								<Descriptions.Item label='Not Debiting to Contractor Reason'>
									{record?.notDebitedToContractorReason ?? ''}
								</Descriptions.Item>
							)}
						</>
					) : []}
				</Descriptions>
			</Col>
		</Row>
	);
};
