import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get as getUserPermission} from '../UserPermission/actions';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {
	SiteStatus,
	ModuleName,
	ModulePermission,
	type ChecklistResponseReport,
	type User,
} from 'app/models';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {get as getSites} from '../Site/actions';
import {
	calculateChecklistResponseReportData,
	mutateTree,
	getKey,
} from 'app/services/report-service';
import type Types from 'MyTypes';
import {getChecklistResponseReport} from './checklist-response-report-reducer';
import {formatDateFunction} from 'app/helpers';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
} from './ViewHierarchyReport';

export const ViewChecklistResponseReport: React.FC = () => {
	const dispatch = useDispatch();
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.checklistResponseReport,
	);

	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
		= useSelector((state: Types.RootState) => state.userPermission);

	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];

	const today = new Date();
	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		records: {},
		children: [],
	};

	// Fetching all sites, user permissions of READ for construction schedule module,and the reports of past 5 weeks from the current date
	React.useEffect(() => {
		dispatch(
			getChecklistResponseReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						34,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(
			getSites({
				where: {
					status: {
						inq: [SiteStatus.UNDER_CONSTRUCTION, SiteStatus.UNDER_DESIGN],
					},
				},
			}),
		);
		dispatch(
			getUserPermission({
				where: {
					moduleName: ModuleName.CONSTRUCTION_SCHEDULE,
					permission: ModulePermission.READ,
				},
			}),
		);
	}, []);

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<
	Record<string, ChecklistResponseReport[]>
	>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.slice(0, 5)
		.map(d => ({
			title: d,
			key: getKey(d),
			width: 360,
			children: [
				{title: 'Approved', key: getKey(d, '-approved'), width: 120},
				{title: 'Cancelled', key: getKey(d, '-cancelled'), width: 120},
				{title: 'Approve %', key: getKey(d, '-approvepercent'), width: 120},
			],
		}));

	// Creating a permissions record to uniquely identify users and the sites associated with them
	const permissionMapOfRecord = userPermissions.reduce<
	Record<number, number[]>
	>((record, item) => {
		if (!record[item.userId]) {
			record[item.userId] = [];
		}

		if (allSites.find(({id}) => id === item.siteId)) {
			record[item.userId].push(item.siteId);
		}

		return record;
	}, {});

	// Traversing the tree to assign delays to all nodes and to create sites as child nodes of site in charges (leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.key = String(user.id);
		node.records = calculateChecklistResponseReportData(
			user,
			mapOfRecords,
			permissionMapOfRecord,
		);
		node.name = user.name;
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else if (permissionMapOfRecord[user.id]?.length) {
			node.children = permissionMapOfRecord[user.id].map(siteId => {
				const dataRecords: Record<string, number> = {};
				Object.entries(mapOfRecords).forEach(([key, records]) => {
					const weekEntry = records.find(entry => entry.siteId === siteId);
					let total = 0;
					if (weekEntry) {
						total = (weekEntry?.approved ?? 0) + (weekEntry?.cancelled ?? 0);
					}

					dataRecords[getKey(key, '-approved')] = weekEntry?.approved ?? 0;
					dataRecords[getKey(key, '-cancelled')] = weekEntry?.cancelled ?? 0;
					dataRecords[getKey(key, '-approvepercent')]
						= weekEntry?.approved && total
							? Math.floor(((weekEntry?.approved ?? 0) * 100) / total)
							: 0;
				});
				const x: DataSourceType = {
					key: String(siteId),
					records: dataRecords,
					name: allSites.find(site => site.id === siteId)?.name ?? '',
				};
				return x;
			});
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites
			.filter(({siteInchargeId}) => Boolean(siteInchargeId))
			.map(({siteInchargeId}) => siteInchargeId ?? 0),
	);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);

	return (
		<DefaultLayout currentPath={uiPaths.checklistResponseReport}>
			<ViewHierarchyReport
				title='Checklist Response Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={`
        This report presents how many construction schedule checklists are in the Approved and Cancelled State.
        The report shows the Approved Percentage also. Data is captured every week on Tuesday at 12:00 AM in the night.
        If there is any increase in the number of Approved or Cancelled Checklists compared to the previous week, it means
        that in the last 7 days, more checklists have come under the category of Approved or Cancelled. Similarly,
        If there is any decrease in the number compared to the previous week, it means that in the last 7 days,
        fewer checklists have come under the category of Approved or Cancelled.
        `}
				reportLogic={`
        The Average percentage is calculated based on the number of checklists that got Approved or Cancelled.
        The data is calculated for Construction Incharges and their team members reporting under him.
        The site name is also mentioned under the particular site in charge.
        To read the report, you can expand the “+” sign in front of the Reporting Manager’s name to see the list of employees
        reporting to him. If you have any query related to this report or a suggestion to improvise the report.
        Please contact ruchika.singh@prithu.in
        `}
			/>
		</DefaultLayout>
	);
};
