import React from 'react';
import {message} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import axios from 'axios';
import type Types from 'MyTypes';
import {getHeaders} from 'app/services/common-service';
import {parseNum} from 'app/helpers';

export const ViewAttendance: React.FC = () => {
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);

	const fetchToken = async () => {
		const requestBody = {
			key: 'onsite_live_3193d3a9-73dc-411d-b7f3-7fe7cff84119',
			secret: '0a958a99-4c1f-4a2c-9590-88287aa80ce1',
			mobile: authUser?.mobileNumbers
				? parseNum(authUser?.mobileNumbers)
				: null,
		};
		await axios
			.post(
				'https://api.onsiteteams.in/apis/v3/generate/login-token',
				requestBody,
				getHeaders(true),
			)
			.then((response: any) => {
				if (response && response.status === 200 && response.data.token) {
					window.location.href = `https://prithu.onsiteteams.com/login?token=${response.data.token}`;
				} else {
					void message.error('Invalid Token');
				}
			})
			.catch(err => message.error('Something went wrong'));
	};

	React.useEffect(() => {
		if (authUser?.mobileNumbers && parseNum(authUser?.mobileNumbers)) {
			void fetchToken();
		} else {
			void message.error('Invalid Phone number!');
		}
	}, []);
	return (
		<DefaultLayout currentPath={uiPaths.attendance}>
			{/* <h1>{token}</h1> */}
		</DefaultLayout>
	);
};
