import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Card,
	Col,
	Empty,
	message,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Typography,
} from 'antd';
import {parentTab, uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
	getParents,
} from './actions';
import {Link} from 'react-router-dom';
import {isMobile, toTitleCase} from 'app/helpers';
import {
	type ProjectProcessMaster,
	ProjectProcessType,
} from 'app/components/ProjectProcessMaster/project-process-master';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';

export const ViewProjectProcessMaster: React.FC = () => {
	const dispatch = useDispatch();
	const {
		loading,
		byIds,
		allIds,
		filterValue,
		dataUpdated,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
		parents,
	} = useSelector((state: Types.RootState) => state.projectProcessMaster);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f?.projectProcessType) {
			filter.where.projectProcessType = f.projectProcessType;
			const parentsFilter: any = {
				where: {},
			};
			if (f.projectProcessType === ProjectProcessType.DESIGN) {
				parentsFilter.where.projectProcessType = ProjectProcessType.DESIGN;
				parentsFilter.where.processName = {inq: [parentTab.drawings, parentTab.quantity, parentTab.designProgressTracker]};
			} else if (f.projectProcessType === ProjectProcessType.WARRANTY) {
				parentsFilter.where.projectProcessType = ProjectProcessType.WARRANTY;
				parentsFilter.where.parentId = {eq: null};
			}

			dispatch(getParents(parentsFilter));
		}

		if (f.processName) {
			filter.where.processName = {like: `%25${f.processName as string}%25`};
		}

		if (f.parentId) {
			filter.where.parentId = f.parentId;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const typeOptions = Object.values(ProjectProcessType).map((status: string) => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	const parentOptions: FilterOptionItem[] = parents?.length
		? parents.map(parent => ({
			value: String(parent?.id),
			label: parent.processName ?? '',
		}))
		: [];

	const dataSource: ProjectProcessMaster[] = allIds?.length
		? allIds.map(id => byIds[id])
		: [];

	const uiFilters = [
		{
			filterKey: 'projectProcessType',
			items: typeOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status Tab Type',
			label: 'Status Tab Type',
			defaultValue: filterValue ? filterValue.projectProcessType : '',
		},
		{
			filterKey: 'processName',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Process Name',
			label: 'Process Name',
			defaultValue: filterValue ? filterValue.processName : '',
		},
		{
			filterKey: 'parentId',
			items: parentOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Parent',
			label: 'Parent',
			defaultValue: filterValue ? filterValue.parentId : '',
		},
	];

	const formatDuration = (days?: number): string => {
		if (!days) {
			return '';
		}

		const daysInYear = 365;
		const daysInMonth = 30;

		const formatValue = (value: number) => value % 1 === 0 ? value.toFixed(0) : value.toFixed(1);

		if (days >= daysInYear) {
			const years = days / daysInYear;
			return `${formatValue(years)} year${years > 1 ? 's' : ''}`;
		}

		if (days >= daysInMonth) {
			const months = days / daysInMonth;
			return `${formatValue(months)} month${months > 1 ? 's' : ''}`;
		}

		return `${formatValue(days)} day${days > 1 ? 's' : ''}`;
	};

	return (
		<DefaultLayout currentPath={uiPaths.projectProcessMaster}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Project Schedule Tab Masters
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f1: FilterDataType) => {
								filterData(f1);
							}}
						/>
					</Col>
				</Row>
				<br />
				<Row
					className='mb-15'
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Col span={24}>
						<Card
							title={
								<b>Please Note While Creating or Editing any Master Tabs</b>
							}
							style={{width: '100%'}}
						>
							<Space direction='vertical'>
								{
									'- While creating any new tab, don not update any old or unused tab. Please create a new tab.'
								}
								{
									'- Please do not remove any tab. Instead archive it by appending (Archive) to it.'
								}
								{
									'- Do not swap any names. If you requires to change the position please update the sequence number.'
								}
							</Space>
						</Card>
					</Col>
				</Row>

				{filterValue?.projectProcessType ? (
					<Row>
						<Col span={24} style={{textAlign: 'right'}}>
							<Link
								to={{
									pathname: `/${uiPaths.createProjectProcessMaster.replace(
										':type',
										filterValue.projectProcessType as string,
									)}`,
								}}
							>
								<Button type='primary'>{`Add New Project ${toTitleCase(
									filterValue.projectProcessType as string,
								)} Schedule Tab Masters`}</Button>
							</Link>
							<br />
							<br />
						</Col>
					</Row>
				) : (
					[]
				)}

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								pageSizeOptions={['10', '50', '100', '500', '1000', '5000', '10000']}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
							>
								<Table.Column
									title='Id'
									dataIndex='id'
									key='id'
								/>
								<Table.Column
									width={500}
									title='Process Name'
									dataIndex='processName'
									key='processName'
								/>
								<Table.Column
									title='Parent Tab'
									dataIndex='parentProcessMaster'
									key='parentProcessMaster'
									render={(
										parentProcessMaster: ProjectProcessMaster | undefined,
									) => parentProcessMaster?.processName ?? ''}
								/>
								<Table.Column
									title='Duration / Period'
									dataIndex='durationDays'
									key='durationDays'
									render={(durationDays: number | undefined) =>
										formatDuration(durationDays)
									}
								/>
								<Table.Column
									title='Based on Actual/Planed Date'
									dataIndex='dateFetchType'
									key='dateFetchType'
									render={(dateFetchType: string | undefined) =>
										toTitleCase(dateFetchType)
									}
								/>
								<Table.Column
									title='Linked Activities'
									dataIndex='relationProcessMasters'
									key='relationProcessMasters'
									render={(relationProcessMasters: ProjectProcessMaster[]) =>
										relationProcessMasters?.map((master, ix) => (
											<Tag key={ix}>{master?.processName ?? ''}</Tag>
										))
									}
								/>
								<Table.Column
									title='Google Sheet Activity'
									dataIndex='activityName'
									key='activityName'
								/>
								<Table.Column
									title='Google Sheet Floor Name'
									dataIndex='floorName'
									key='floorName'
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(action: string, item: ProjectProcessMaster) => (
										<Space direction={isMobile ? 'vertical' : 'horizontal'}>
											{item.processName !== parentTab.drawings
                      && item.processName !== parentTab.quantity && item.processName !== parentTab.designProgressTracker ? (
													<Link
														to={{
															pathname: `/${uiPaths.editProjectProcessMaster.replace(
																':id',
																String(item.id),
															)}`,
														}}
													>
														<Button size={'small'} type='primary'>
                            Edit
														</Button>
													</Link>
												) : (
													[]
												)}
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
