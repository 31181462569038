import {type TimeImpact} from 'app/models';
import React from 'react';
import {Button, Col, Row, Space, Table} from 'antd';
import {isMobile} from 'app/helpers';
import {Link} from 'react-router-dom';

type Props = {
	timeImpacts: TimeImpact[];
	onChange: (id: number) => void;
	onEdit: (id: number) => void;
};

export const TimeImpactsTable: React.FC<Props> = ({
	timeImpacts,
	onChange,
	onEdit,
}) => (
	<Row>
		<Col span={24}>
			<Table
				size={'small'}
				bordered={true}
				dataSource={timeImpacts}
				pagination={false}
				scroll={{x: 800, y: 500}}
			>
				<Table.Column title='S.no' dataIndex='sno' key='sno' width='5%' />

				<Table.Column
					title='Activity Name'
					dataIndex='title'
					key='title'
				/>

				<Table.Column
					title='Time Impact (in days)'
					dataIndex='noOfDays'
					key='noOfDays'
				/>

				<Table.Column
					title='Description'
					dataIndex='description'
					key='description'
					render={(description: string) => description ?? '-'}
				/>

				<Table.Column
					title='Reference Link'
					dataIndex='referenceLink'
					key='referenceLink'
					render={(referenceLink: string) => (
						<Link to={referenceLink ?? ''}>{referenceLink ?? ''}</Link>
					)}
				/>
				<Table.Column
					title='Action'
					dataIndex='action'
					key='action'
					render={(action: string, timeImpact: TimeImpact, index: number) => (
						<Space direction={isMobile ? 'vertical' : 'horizontal'}>
							<Button
								size={'small'}
								type='primary'
								onClick={() => {
									onEdit(index);
								}}
							>
                  Edit
							</Button>
							<Button
								size={'small'}
								type='primary'
								danger={true}
								onClick={() => {
									onChange(index);
								}}
							>
                  Delete
							</Button>
						</Space>
					)}
				/>
			</Table>
		</Col>
	</Row>
);
