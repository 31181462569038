import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get as getUserPermissions} from '../UserPermission/actions';
import {ModuleName, type User, UserType, type ClientQueryOverdueReport} from 'app/models';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {getClientQueryOverdueReport} from './client-query-overdue-report-reducer';
import {formatDateFunction} from 'app/helpers';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
} from './ViewHierarchyReport';
import {
	calculateClientQueryOverdueReportData,
	getKey,
	mutateTree,
} from 'app/services/report-service';

export const ViewClientQueryOverdueReport: React.FC = () => {
	const dispatch = useDispatch();
	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
    = useSelector((state: Types.RootState) => state.userPermission);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.clientQueryOverdueReport,
	);
	const today = new Date();
	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const userList: User[] = userPermissions.reduce<User[]>((list, item) => {
		if (
			item.user
      && !list.find(u => u.id === item.user?.id)
      && item.user.userType === UserType.EMPLOYEE
		) {
			list.push(item.user);
		}

		return list;
	}, []);

	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		records: {},
		children: [],
	};

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, ClientQueryOverdueReport[]>>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.slice(0, 5)
		.map(d => ({
			title: d,
			key: getKey(d),
			width: 100,
			children: [
				{title: 'Overdue queries', key: getKey(d, '-overdue'), width: 50},
				{
					title: 'Subordinate Overdue queries',
					key: getKey(d, '-subordinatesOverdue'),
					width: 50,
				},
			],
		}));

	React.useEffect(() => {
		dispatch(
			getClientQueryOverdueReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						36,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(
			getUserPermissions({
				where: {
					moduleName: ModuleName.CLIENT_QUERY,
				},
			}),
		);
	}, []);

	// Creating User Record of all users
	const userRecord: Record<number, User> = {};
	userPermissions.forEach(item => {
		if (item.user?.id) {
			userRecord[item.user.id] = item.user;
		}
	});

	// Traversing the tree to assign delays to all nodes and to create sites as child nodes of (leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.name = user.name;
		node.key = String(user.id);
		node.records = calculateClientQueryOverdueReportData(user, mapOfRecords);
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		userList.map(({id}) => id ?? 0),
	);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);

	return (
		<DefaultLayout currentPath={uiPaths.clientQueryOverdueReport}>
			<ViewHierarchyReport
				title='Client Query Overdue Report : Overdue Queries(From the client to Internal Users)'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
				reportDescription={`
        To see or keep track of all the open queries raised by clients that are not resolved within three days so that
        the reporting manager can fix them.
        `}
				reportLogic={`
        Based upon the number of client queries assigned to our internal
        team by the client which are overdue (pending for more than 3 days).
        To read the report, you can expand the “+” sign in front of the Reporting Manager
        name to see the list of employees reporting to him.
        If you have any query related to this report or a suggestion to improvise the report.
        Please Contact ruchika.singh@prithu.in
      `}
			/>
		</DefaultLayout>
	);
};
