import React, {useEffect} from 'react';
import {Button, Col, Form, message, Row, Spin, Typography} from 'antd';
import {parseNum} from 'app/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {getById, reset, update} from './actions';
import {getForProjectDetail} from '../UserPermission/actions';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useHistory, useParams} from 'react-router';
import {EditNameForm} from './EditComponents/EditNameForm';
import {EditStatusForm} from './EditComponents/EditStatusForm';
import {EditStageForm} from './EditComponents/EditStageForm';
import {ProjectFileType} from './project-file';
import {ProjectFileForm} from './EditComponents/ProjectFileForm';
import {EditStakeholderForm} from './EditComponents/EditStakeholderForm';
import {EditClientForm} from './EditComponents/EditClientForm';
import {EditUserPermissionForm} from './EditComponents/EditUserPermissionForm';
import {EditWarrantyPeriodCoverageForm} from './EditComponents/EditWarrantyPeriodCoverageForm';
import {EditDemolitionRequiredForm} from './EditComponents/EditDemolitionRequiredForm';
import {ModulePermission, type Site} from 'app/models';
import {EditInputDateForm} from '../Common/EditInputDateForm';
import {EditInputNumberForm} from '../Common/EditInputNumberForm';
import {EditInputTextForm} from '../Common/EditInputTextForm';
import {EditInputPercentForm} from './EditComponents/EditInputPercentForm';
import type Types from 'MyTypes';
import {ProjectActivityTemplateForm} from './EditComponents/ProjectActivityTemplateForm';
import {siteResourceDirectorySheetLink} from 'app/constants';

type MyParams = {
	siteId?: string;
};

export const ViewProjectDetail: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams<MyParams>();
	const siteId = parseNum(params?.siteId ?? '0');
	const history = useHistory();

	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {projectDetailPermissions: projectPermissions} = useSelector((state: Types.RootState) => state.userPermission);
	const {loading, dataUpdated, byIds, errorMessage} = useSelector((state: Types.RootState) => state.site);
	const site: Site = byIds[siteId];
	const canEdit = Boolean(authUser?.isAdmin);

	const getProjectFileValue = (fileType: ProjectFileType) =>
		site?.agreementDocuments?.find(d => d.fileType === fileType) ?? {fileType, siteId};
	const getProjectFilePerm = (fileType: ProjectFileType, perm: ModulePermission) =>
		Boolean(projectPermissions?.find(p => p.condition === String(fileType) && p.permission === perm));

	useEffect(() => {
		dispatch(reset());
	}, []);

	useEffect(() => {
		if (siteId) {
			dispatch(getById(siteId));
			dispatch(getForProjectDetail(siteId));
		}
	}, [siteId]);

	useEffect(() => {
		if (dataUpdated && canEdit) {
			dispatch(getById(siteId));
			void message.success('Data updated successfully');
		}
	}, [dataUpdated, canEdit]);

	useEffect(() => {
		if (errorMessage && canEdit) {
			void message.error(errorMessage);
		}
	}, [errorMessage, canEdit]);

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};

	return (
		<DefaultLayout>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24} className='text-center'>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Project Detail
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Button onClick={() => {
						history.goBack();
					}}>Back</Button>
					<Col span={24}>
						<Form
							{...layout}
							name='basic'
						>
							<Form.Item label={<b>{'Name'}</b>}>
								<EditNameForm
									canEdit={canEdit}
									siteId={siteId}
									projectCity={site?.projectCity}
									projectType={site?.projectType}
									address={site?.address}
									projectNumber={site?.projectNumber}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Status'}</b>}>
								<EditStatusForm
									canEdit={canEdit}
									currentValue={site?.status}
									siteId={siteId}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Stage'}</b>}>
								<EditStageForm
									canEdit={canEdit}
									currentValue={site?.stage}
									siteId={siteId}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Site Resources Directory'}</b>}>
								<a
									href={siteResourceDirectorySheetLink}
									target='_blank'
									rel='noreferrer'
								>
									{siteResourceDirectorySheetLink}
								</a>
							</Form.Item>

							<Form.Item label={<b>{'Project Duration (In Months)'}</b>}>
								<EditInputNumberForm
									canEdit={canEdit}
									currentValue={site?.projectDuration}
									onSubmit={projectDuration => dispatch(update(siteId, {projectDuration}))}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Base Steel Cost'}</b>}>
								<EditInputNumberForm
									canEdit={canEdit}
									currentValue={site?.baseSteelCost}
									onSubmit={baseSteelCost => dispatch(update(siteId, {baseSteelCost}))}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Google Pin'}</b>}>
								<EditInputTextForm
									canEdit={canEdit}
									currentValue={site?.location}
									onSubmit={location => dispatch(update(siteId, {location}))}
								/>
							</Form.Item>

							{canEdit ? (
								<Form.Item label={<b>{'Google Sheet Id for Project Schedule'}</b>}>
									<EditInputTextForm
										canEdit={canEdit}
										currentValue={site?.sheetURL}
										// eslint-disable-next-line @typescript-eslint/naming-convention
										onSubmit={sheetURL => dispatch(update(siteId, {sheetURL}))}
									/>
								</Form.Item>
							) : []}

							{canEdit ? (
								<Form.Item label={<b>{'Google Sheet Id for Budget'}</b>}>
									<EditInputTextForm
										canEdit={canEdit}
										currentValue={site?.budgetSheetURL}
										// eslint-disable-next-line @typescript-eslint/naming-convention
										onSubmit={budgetSheetURL => dispatch(update(siteId, {budgetSheetURL}))}
									/>
								</Form.Item>
							) : []}

							<Form.Item label={<b>{'Base Cement Price(Per bag)'}</b>}>
								<EditInputNumberForm
									canEdit={canEdit}
									currentValue={site?.baseCementPrice}
									onSubmit={baseCementPrice => dispatch(update(siteId, {baseCementPrice}))}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Warranty Period as per contract (In Years)'}</b>}>
								<EditInputNumberForm
									canEdit={canEdit}
									currentValue={site?.warrantyPeriod}
									onSubmit={warrantyPeriod => dispatch(update(siteId, {warrantyPeriod}))}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Warranty Period Coverage'}</b>}>
								<EditWarrantyPeriodCoverageForm
									canEdit={canEdit}
									currentValue={site?.warrantyPeriodCoverage}
									siteId={siteId}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Demolition Required'}</b>}>
								<EditDemolitionRequiredForm
									canEdit={canEdit}
									currentValue={site?.demolitionRequired}
									siteId={siteId}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Contract Value'}</b>}>
								<EditInputNumberForm
									canEdit={canEdit}
									currentValue={site?.contractValue}
									onSubmit={contractValue => dispatch(update(siteId, {contractValue}))}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Penalty Payable as per'}<br />{'Agreement(Per Month) in Rupees'}</b>}>
								<EditInputPercentForm
									canEdit={canEdit}
									currentValue={site?.agreementPenalty}
									absoluteValue={site?.contractValue ?? 0}
									onSubmit={agreementPenalty => dispatch(update(siteId, {agreementPenalty}))}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Incentive as per'}<br />{'Agreement(Per Month) in Rupees'}</b>}>
								<EditInputPercentForm
									canEdit={canEdit}
									currentValue={site?.agreementIncentive}
									absoluteValue={site?.contractValue ?? 0}
									onSubmit={agreementIncentive => dispatch(update(siteId, {agreementIncentive}))}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Contract Signing Date'}</b>}>
								<EditInputDateForm
									canEdit={canEdit}
									currentValue={site?.contractSigningDate}
									onSubmit={contractSigningDate => dispatch(update(siteId, {contractSigningDate}))}
								/>
							</Form.Item>

							<Form.Item label={<b>Scope Of Work</b>}>
								<ProjectFileForm
									siteId={siteId}
									currentValue={getProjectFileValue(
										ProjectFileType.SCOPE_OF_WORK,
									)}
									canEdit={canEdit}
								/>
							</Form.Item>

							<Form.Item label={<b>Construction Timeline</b>}>
								<ProjectFileForm
									siteId={siteId}
									currentValue={getProjectFileValue(
										ProjectFileType.CONSTRUCTION_TIMELINE,
									)}
									canEdit={canEdit}
								/>
							</Form.Item>

							<Form.Item label={<b>Area Statement Without Pricing</b>}>
								<ProjectFileForm
									siteId={siteId}
									currentValue={getProjectFileValue(
										ProjectFileType.AREA_STATEMENT_WITHOUT_PRICING,
									)}
									canEdit={canEdit}
								/>
							</Form.Item>

							{/* {getProjectFilePerm(ProjectFileType.AGREEMENT, ModulePermission.READ) ?? canEdit ? ( */}
							<Form.Item label={<b>Agreement</b>}>
								<ProjectFileForm
									siteId={siteId}
									currentValue={getProjectFileValue(ProjectFileType.AGREEMENT)}
									canEdit={getProjectFilePerm(ProjectFileType.AGREEMENT, ModulePermission.WRITE)}
								/>
							</Form.Item>
							{/* ) : []} */}

							{getProjectFilePerm(ProjectFileType.TITLE_DOCUMENTS, ModulePermission.READ) || canEdit ? (
								<Form.Item label={<b>Title Documents</b>}>
									<ProjectFileForm
										siteId={siteId}
										currentValue={getProjectFileValue(ProjectFileType.TITLE_DOCUMENTS)}
										canEdit={getProjectFilePerm(ProjectFileType.TITLE_DOCUMENTS, ModulePermission.WRITE)}
									/>
								</Form.Item>
							) : []}

							{getProjectFilePerm(ProjectFileType.AREA_STATEMENT_WITH_PRICING, ModulePermission.READ) || canEdit ? (
								<Form.Item label={<b>Area Statement With Pricing</b>}>
									<ProjectFileForm
										siteId={siteId}
										currentValue={getProjectFileValue(ProjectFileType.AREA_STATEMENT_WITH_PRICING)}
										canEdit={getProjectFilePerm(ProjectFileType.AREA_STATEMENT_WITH_PRICING, ModulePermission.WRITE)}
									/>
								</Form.Item>
							) : []}

							{getProjectFilePerm(ProjectFileType.PAYMENT_PLAN, ModulePermission.READ) || canEdit ? (
								<Form.Item label={<b>Payment Plan</b>}>
									<ProjectFileForm
										siteId={siteId}
										currentValue={getProjectFileValue(ProjectFileType.PAYMENT_PLAN)}
										canEdit={getProjectFilePerm(ProjectFileType.PAYMENT_PLAN, ModulePermission.WRITE)}
									/>
								</Form.Item>
							) : []}

							{getProjectFilePerm(ProjectFileType.WARRANTY_DOCUMENTS, ModulePermission.READ) || canEdit ? (
								<Form.Item label={<b>Warranty Documents</b>}>
									<ProjectFileForm
										siteId={siteId}
										currentValue={getProjectFileValue(ProjectFileType.WARRANTY_DOCUMENTS)}
										canEdit={getProjectFilePerm(ProjectFileType.WARRANTY_DOCUMENTS, ModulePermission.WRITE)}
									/>
								</Form.Item>
							) : []}

							{getProjectFilePerm(ProjectFileType.ANY_OTHER_DOCUMENT, ModulePermission.READ) || canEdit ? (
								<Form.Item label={<b>Other Document</b>}>
									<ProjectFileForm
										siteId={siteId}
										currentValue={getProjectFileValue(ProjectFileType.ANY_OTHER_DOCUMENT)}
										canEdit={getProjectFilePerm(ProjectFileType.ANY_OTHER_DOCUMENT, ModulePermission.WRITE)}
									/>
								</Form.Item>
							) : []}

							<Form.Item label={<b>Activities Template</b>}>
								<ProjectActivityTemplateForm
									siteId={siteId}
									currentValue={site?.activityTemplateId}
									canEdit={canEdit}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Stakeholders'}</b>}>
								<EditStakeholderForm
									canEdit={canEdit}
									siteInchargeId={site?.siteInchargeId}
									projectArchitectId={site?.projectArchitectId}
									approvalsInchargeId={site?.approvalsInchargeId}
									procurementInchargeId={site?.procurementInchargeId}
									clientInvoicingInchargeId={site?.clientInvoicingInchargeId}
									clientInterfaceDesignId={site?.clientInterfaceDesignId}
									clientInterfaceConstructionId={
										site?.clientInterfaceConstructionId
									}
									clientInterfaceApprovalsId={site?.clientInterfaceApprovalsId}
									clientInterfaceAccountsId={site?.clientInterfaceAccountsId}
									contractorAppointmentBillingAndTerminationId ={site?.contractorAppointmentBillingAndTerminationId}
									quantityCalculationId={site?.quantityCalculationId}
									siteId={siteId}
								/>
							</Form.Item>

							<Form.Item label={<b>{'Clients'}</b>}>
								<EditClientForm
									canEdit={canEdit}
									currentValue={site?.clients}
									siteId={siteId}
								/>
							</Form.Item>

							{canEdit ? (
								<Form.Item label={<b>{'Module Permissions'}</b>}>
									<EditUserPermissionForm siteId={siteId} />
								</Form.Item>
							) : []}
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
