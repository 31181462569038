import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
	Button,
	Col,
	Empty,
	message,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import type Types from 'MyTypes';
import {CSVLink} from 'react-csv';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from 'app/components/Layout/DefaultLayout';
import {type User, UserType} from 'app/models';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {parseNum, toTitleCase} from 'app/helpers';
import {UserRole} from 'app/models/enums/user-role.enum';
import {
	get,
	resetPassword,
	remove,
	setFilterValue,
	count,
	onPaginationChange,
} from './actions';
import {
	EditOutlined,
	CreditCardOutlined,
	StopOutlined,
	LockOutlined,
} from '@ant-design/icons';

export const ViewAllUser: React.FC = () => {
	const dispatch = useDispatch();
	const {userOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {
		byIds,
		allIds,
		loading,
		errorMessage,
		dataUpdated,
		totalCount,
		currentPos,
		perPageSize,
		filterValue,
	} = useSelector((state: Types.RootState) => state.user);

	const roleOptions: FilterOptionItem[] = Object.values(UserRole).map(
		role => ({value: role, label: toTitleCase(role as string) ?? ''}),
	);
	const userTypeOptions: FilterOptionItem[] = Object.values(UserType).map(
		userType => ({
			value: userType,
			label: toTitleCase(userType as string) ?? '',
		}),
	);

	React.useEffect(() => {
		filterData(filterValue);
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const handleDeactivateClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to deactivate this user')) {
			dispatch(remove(id));
		}
	};

	const handleResetPasswordClick = (id: number) => {
		if (
			// eslint-disable-next-line no-alert
			window.confirm('Are you sure you want to reset password of this user')
		) {
			dispatch(resetPassword(id));
		}
	};

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['name ASC'],
		};

		if (f.isDeactivated) {
			filter.where.isActive = false;
		} else {
			filter.where.isActive = true;
		}

		if (f.name) {
			filter.where.name = {like: `%25${f.name as string}%25`};
		}

		if (f.roles) {
			filter.where.roles = f.roles;
		}

		if (f.userType) {
			filter.where.userType = f.userType;
		}

		if (f.reportingUserId) {
			filter.where.reportingUserId = parseNum(f.reportingUserId as string);
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Name',
			label: 'Name',
			defaultValue: filterValue?.name,
		},
		{
			filterKey: 'roles',
			items: roleOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Role',
			label: 'Role',
			defaultValue: filterValue?.roles,
		},
		{
			filterKey: 'userType',
			items: userTypeOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select a Type',
			label: 'User Type',
			defaultValue: filterValue?.userType,
		},
		{
			filterKey: 'reportingUserId',
			items: userOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select a User',
			label: 'Reporting User',
			defaultValue: filterValue?.reportingUserId,
		},
		{
			filterKey: 'isDeactivated',
			formType: FilterFormType.BOOLEAN,
			label: 'Deactivated',
			defaultValue: filterValue?.isDeactivated,
		},
	];

	const getCsvData = (data: User[]) => [
		[
			'Name',
			'Username',
			'Mobile Numbers',
			'User Type',
			'Email',
			'Pan Card',
			'Role',
			'Reporting User',
		],
		...data.map(d => [
			d.name,
			d.username,
			d.mobileNumbers,
			d.userType,
			d.email,
			d.panNumber,
			d.roles,
			d?.reportingUser?.name ?? '',
		]),
	];

	const dataSource = allIds && byIds ? allIds.map(id => byIds[id]) : [];

	const csvData: any[] = getCsvData(dataSource);

	return (
		<DefaultLayout currentPath={uiPaths.user}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Users
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView uiFilters={uiFilters} onSubmit={f => {
							filterData(f);
						}} />
					</Col>
				</Row>

				<Row>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<Space>
							<Link to={{pathname: `/${uiPaths.createNewUser}`}}>
								<Button type='primary'>Create New User</Button>
							</Link>
							<CSVLink
								data={csvData}
								filename={'Users.csv'}
								className='btn btn-primary'
							>
								<Button type='primary'>Download</Button>
							</CSVLink>
						</Space>
					</Col>
				</Row>
				<br />

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								pageSizeOptions={['10', '50', '100', '500', '1000', '5000', '10000']}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
							>
								<Table.Column
									title='User Name'
									dataIndex='username'
									key='username'
								/>
								<Table.Column
									title='Name'
									dataIndex='name'
									key='name'
									render={(name: string, u: User) => (
										<Link
											to={{
												pathname: `/${uiPaths.userDetail.replace(
													':userId',
													u.id?.toString() ?? '',
												)}`,
											}}
										>
											{u.name}
										</Link>
									)}
								/>
								<Table.Column
									title='Role'
									dataIndex='roles'
									key='roles'
									render={(roles: UserRole) => toTitleCase(roles, '_')}
								/>
								<Table.Column
									title='Email Address'
									dataIndex='email'
									key='email'
								/>
								<Table.Column
									title='Pan Card'
									dataIndex='panNumber'
									key='panNumber'
								/>
								<Table.Column
									title='Reporting User'
									dataIndex='reportingUser'
									key='reportingUser'
									render={(reportingUser: User | undefined) => (
										<Link
											to={{
												pathname: `/${uiPaths.userDetail.replace(
													':userId',
													reportingUser?.id?.toString() ?? '',
												)}`,
											}}
										>
											{reportingUser?.name}
										</Link>
									)}
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(text: string, u: User) => (
										<Space>
											{u.isActive ? [] : <Tag>Inactive</Tag>}
											<Link
												to={{
													pathname: `/${uiPaths.editUser.replace(
														':userId',
														String(u.id),
													)}`,
												}}
											>
												<Tooltip title={'Edit'}>
													<Button type='primary'>
														<EditOutlined />
													</Button>
												</Tooltip>
											</Link>
											{u.isActive ? (
												<Link
													to={{
														pathname: `/${uiPaths.enterPaymentDetail.replace(
															':userId',
															String(u.id),
														)}`,
													}}
												>
													<Tooltip title={'Edit Payment Details'}>
														<Button type='primary'>
															<CreditCardOutlined />
														</Button>
													</Tooltip>
												</Link>
											) : (
												[]
											)}
											{u.isActive ? (
												<Tooltip title={'Deactivate User'}>
													<Button
														type='primary'
														danger={true}
														onClick={() => {
															handleDeactivateClick(u.id ?? 0);
														}}
													>
														<StopOutlined />
													</Button>
												</Tooltip>
											) : (
												[]
											)}
											{u.isActive ? (
												<Tooltip title={'Reset Password'}>
													<Button
														type='primary'
														onClick={() => {
															handleResetPasswordClick(u.id ?? 0);
														}}
													>
														<LockOutlined />
													</Button>
												</Tooltip>
											) : (
												[]
											)}
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
