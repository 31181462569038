import {type User} from './user.model';

export type MaterialOrderBudget = {
	id: number;
	parentId?: number;
	title: string;
	measuringUnit?: string;
	budgetQuantity?: number;
	refDrawingLink?: string;
	remarks?: string;
	cancelReason?: string;
	revisionReason?: string;
	status?: MaterialOrderBudgetStatus;
	approvedLevel?: number;
	nextApprovedBy?: number;
	updatedAt?: string;
	approvedAt?: string;
	updatedBy?: number;
	approvedBy?: number;
	approvalData?: string;
	approvedUser?: User;
	nextApprovedUser?: User;
	parent?: MaterialOrderBudget;
};

export enum MaterialOrderBudgetStatus {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	CANCELLED = 'CANCELLED',
}
