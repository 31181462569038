import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type FilterDataType} from '../Common/Filter/FilterView';
import {type Category as MyModelType} from './category';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<number, MyModelType>;
	allIds: Array<MyModelType['id']>;
	parents: MyModelType[];
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	parents: [],
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
};

// eslint-disable-next-line complexity
const category = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('category', initialState),
	action: any,
) => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: action.payload.currentPos as number,
					perPageSize: action.payload.perPageSize as number,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getParentApi):
		case getRequestActionType(actionTypes.getSingleApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getParentApi):
		case getFailureActionType(actionTypes.getSingleApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as MyModelType[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getParentApi): {
			const items = action.payload.data?.length ? action.payload.data as MyModelType[] : [];
			return {
				...state,
				...{
					parents: items,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: action.payload.data.count as number,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getSingleApi): {
			const item = action.payload.data as MyModelType;
			const newAllIds = state.allIds?.length ? [...state.allIds] : [];
			if (!newAllIds.includes(item.id)) {
				newAllIds.push(item.id);
			}

			const newByIds = state.byIds ? {...state.byIds} : {};
			newByIds[item.id] = item;
			return {
				...state,
				...{
					allIds: newAllIds,
					byIds: newByIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default category;
