import React from 'react';
import {type ModuleName, type Query, type QueryParentType, QueryStatus, type User} from 'app/models';
import {formatDateFunction, parseNum, toSubstring, toTitleCase} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {Col, Empty, Row, Spin, Table} from 'antd';
import type Types from 'MyTypes';
import {count, get, onRaisedPaginationChange, setRaisedFilterValue} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {getQueryDateRange} from 'app/query_helpers';
import {FilterFormType, type FilterOptionItem, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {useHistory} from 'react-router';

type Props = {
	module?: ModuleName;
	siteId?: number;
	workStatusReportId?: number;
	parentId?: string;
	parentType?: QueryParentType;
};

export const ViewMyRaisedQuery: React.FC<Props> = ({module, siteId, workStatusReportId, parentId, parentType}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {raisedFilterValue, loading, totalCount, raisedCurrentPos, raisedPerPageSize, byIds, allIds} = useSelector((state: Types.RootState) => state.query);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);

	const statusOptions: FilterOptionItem[] = Object.values(QueryStatus)
		.map(role => ({value: String(role), label: toTitleCase(role as string, '_') ?? ''}));

	React.useEffect(() => {
		if (raisedFilterValue) {
			filterData(raisedFilterValue);
		} else {
			filterData();
		}
	}, [raisedFilterValue]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {
				createdBy: authUser?.id,
			},
			limit: perPageSize,
			skip: (currentPos ? perPageSize * (currentPos - 1) : 0),
			order: [
				'createdAt DESC',
			],
		};

		if (workStatusReportId) {
			filter.where.workStatusReportId = workStatusReportId;
		}

		if (parentId) {
			filter.where.parentId = parentId;
		}

		if (parentType) {
			filter.where.parentType = parentType;
		}

		if (siteId) {
			filter.where.siteId = parseNum(siteId);
		} else if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt');
		}

		if (f.assignedTo) {
			filter.where.forWhom = f.assignedTo;
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		dispatch(setRaisedFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const queries = allIds?.length ? allIds.map((id: number) => byIds[id]) : [];

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: raisedFilterValue?.status,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [raisedFilterValue?.date1 as string, raisedFilterValue?.date2 as string],
		},
	];

	const columns = [
		{
			title: 'Point Raised',
			width: 300,
			dataIndex: 'content',
			render: (content: string) => toSubstring(content, 80, '...'),
		},
		{
			title: 'Raised At',
			width: 120,
			dataIndex: 'createdAt',
			render: (createdAt: string) => formatDateFunction(createdAt),
		},
		{
			title: 'Raised By',
			width: 100,
			dataIndex: 'createdUser',
			render: (createdUser: User) => createdUser ? createdUser.name : '',
		},
		{
			title: 'Assigned At',
			width: 100,
			dataIndex: 'assignedAt',
			render: (assignedAt: string) => formatDateFunction(assignedAt),
			sorter: {
				compare: (a: Query, b: Query) =>
					a.assignedAt && b.assignedAt && new Date(a.assignedAt) > new Date(b.assignedAt) ? 1 : -1,
				multiple: 5,
			},
		},
		{
			title: 'Assigned To',
			width: 100,
			dataIndex: 'assignedUser',
			render: (assignedUser: User) => assignedUser ? assignedUser.name : 'Not Yet Assigned',
		},
		{
			title: 'Status',
			width: 100,
			dataIndex: 'status',
			render: (status: string) => toTitleCase(status, '_'),
		},
	];

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row>
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						onSubmit={(f: FilterDataType) => {
							filterData(f);
						}}
					/>
					<br />
				</Col>
			</Row>

			{totalCount ? (
				<Row>
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={raisedCurrentPos ?? 1}
							perPageSize={raisedPerPageSize ?? 10}
							filterValue={raisedFilterValue}
							filterData={filterData}
							onPaginationChange={onRaisedPaginationChange}
						/>
						<Table
							size={'small'}
							pagination={false}
							dataSource={queries}
							columns={columns}
							scroll={{x: 1000, y: 500}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.queryDetail.replace(':queryId', String(query.id))}`,
									});
								},
							})}
						/>
					</Col>
				</Row>
			) : (
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			)}
		</Spin>
	);
};
