import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {getPermissionSites, toTitleCase, parseNum} from 'app/helpers';
import {
	type LabourAttendance,
	LabourType,
	ModuleName,
	ModulePermission,
} from 'app/models';
import {hasPermission} from 'app/helpers';
import {
	Col,
	Row,
	Spin,
	Table,
	Typography,
	Button,
	message,
} from 'antd';
import {get, setFilterValue} from './actions';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {Link} from 'react-router-dom';

type DataType = {
	key: React.Key;
	labourType?: string;
	requiredCount?: number;
	actualCount?: number;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const ViewLabourAttendance: React.FC = () => {
	const dispatch = useDispatch();
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	let totalRequiredCount = 0;
	let totalActualCount = 0;
	const sites = getPermissionSites(
		permissions,
		ModuleName.LABOUR_ATTENDANCE,
		ModulePermission.READ,
		allSites,
	);
	const {byIds, allIds, loading, filterValue, errorMessage, dataUpdated}
    = useSelector((state: Types.RootState) => state.labourAttendance);

	const rawData: LabourAttendance[] = allIds.map(id => byIds[id]);
	const data: DataType[] = Object.values(LabourType).map((item, index) => {
		const labourAttendance = rawData.find(d => d.labourType === item);
		totalActualCount += labourAttendance?.actualCount ?? 0;
		totalRequiredCount += labourAttendance?.requiredCount ?? 0;
		return {
			key: index,
			siteId: parseNum(filterValue.siteId as string),
			reportDate: new Date(new Date().setHours(0, 0, 0, 0)),
			labourType: item,
			requiredCount: labourAttendance?.requiredCount ?? 0,
			actualCount: labourAttendance?.actualCount ?? 0,
		};
	});

	const canWrite = hasPermission(
		permissions[ModuleName.LABOUR_ATTENDANCE],
		ModulePermission.WRITE,
	);

	const filterData = (f: FilterDataType = {}) => {
		if (!f.siteId) {
			return;
		}

		const filter: any = {
			where: {},
			offset: 0,
			limit: 2500,
			order: ['createdAt ASC'],
		};
		filter.where = {
			siteId: parseNum(f.siteId as string),
		};

		if (f.date) {
			filter.where.reportDate = new Date(new Date(f.date as string).setHours(0, 0, 0, 0));
		} else {
			f.date = new Date().toISOString().split('T')[0];

			filter.where.reportDate = new Date(new Date().setHours(0, 0, 0, 0));
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewLabourAttendance.componentDidMount');
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated && filterValue) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE,
			placeholder: 'Select Date',
			label: 'Date',
			defaultValue: filterValue?.date,
		},
	];

	const defaultColumns: Array<ColumnTypes[number] & {
		editable?: boolean;
		dataIndex: string;
	}> = [
		{
			title: 'Labour Type',
			dataIndex: 'labourType',
			width: '30%',
			render: labourType => toTitleCase(labourType as string),
		},
		{
			title: `Required Count (${totalRequiredCount})`,
			dataIndex: 'requiredCount',
		},
		{
			title: `Actual Count (${totalActualCount})`,
			dataIndex: 'actualCount',
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.labourAttendance}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3}>Labour Attendance</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							submitOnChange={true}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
						<br />
					</Col>
				</Row>

				{filterValue.siteId && canWrite && filterValue.date === new Date().toISOString().substring(0, 10) ? (
					<Row>
						<Col span={24} style={{textAlign: 'right', marginBottom: '10px'}}>
							<Link
								to={{
									pathname: '/' + uiPaths.labourAttendanceForm,
									state: data,
								}}
							>
								<Button type='primary' style={{marginBottom: 16}}>
                  Edit Labour Attendance
								</Button>
							</Link>
						</Col>
					</Row>
				) : []}

				{filterValue.siteId ? (
					<Row>
						<Col span={24}>
							<Table
								rowClassName={() => 'editable-row'}
								bordered
								dataSource={data}
								columns={defaultColumns as ColumnTypes}
								pagination={false}
							/>
						</Col>
					</Row>
				) : []}
			</Spin>
		</DefaultLayout>
	);
};
