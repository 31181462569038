import React from 'react';
import {Form, Input} from 'antd';
import {type UserDetail} from 'app/models';
import {UserAddressForm} from './UserAddressForm';
import {ContactDetailForm} from './ContactDetailForm';

type Prop = {
	currentValue?: UserDetail;
	onValueChange: (value: UserDetail) => void;
};

export const UserDetailForm: React.FC<Prop> = ({currentValue, onValueChange}) => (
	<>
		<Form.Item label='Blood Group'>
			<Input
				placeholder={'Enter Blood Group'}
				value={currentValue?.bloodGroup ?? ''}
				onChange={(e: any) => {
					onValueChange({...currentValue, bloodGroup: String(e.target.value)});
				}}
			/>
		</Form.Item>

		<Form.Item label='Local Address'>
			<UserAddressForm
				currentValue={currentValue?.localAddress}
				onValueChange={value => {
					onValueChange({...currentValue, localAddress: value});
				}}
			/>
		</Form.Item>

		<Form.Item label='Permanent Address'>
			<UserAddressForm
				currentValue={currentValue?.permanentAddress}
				onValueChange={value => {
					onValueChange({...currentValue, permanentAddress: value});
				}}
			/>
		</Form.Item>

		<Form.Item label='Emergency Contact'>
			<ContactDetailForm
				currentValue={currentValue?.emergencyContact}
				onValueChange={value => {
					onValueChange({...currentValue, emergencyContact: value});
				}}
			/>
		</Form.Item>
	</>
);
