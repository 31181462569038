// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table .ant-table {
  border-collapse: collapse !important;
}
.custom-table .ant-table .ant-table-container,
.custom-table .ant-table .ant-table-content {
  border-radius: 0 !important;
}
.custom-table .ant-table .ant-table-thead > tr > th,
.custom-table .ant-table .ant-table-tbody > tr > td,
.custom-table .ant-table .ant-table-summary > tr > td {
  border: 1px solid #ddd;
  border-radius: 0 !important;
  padding: 4px;
}
.custom-table .ant-table .ant-table-thead > tr > th {
  background-color: white;
  color: black;
  border-radius: 0;
}
.custom-table .ant-table .ant-table-tbody > tr > td {
  background-color: white;
}
.custom-table .ant-table .ant-table-summary > tr > td {
  background-color: white;
  border-top: 1px solid #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/components/WorkOrderBill/PrintPreview.less"],"names":[],"mappings":"AAAA;EAEI,oCAAA;AAAJ;AAFA;;EAMM,2BAAA;AAAN;AANA;;;EAYM,sBAAA;EACA,2BAAA;EACA,YAAA;AADN;AAbA;EAkBM,uBAAA;EACA,YAAA;EACA,gBAAA;AAFN;AAlBA;EAwBM,uBAAA;AAHN;AArBA;EA4BM,uBAAA;EACA,0BAAA;AAJN","sourcesContent":[".custom-table {\n  .ant-table {\n    border-collapse: collapse !important; // Ensuring borders are collapsed\n\n    .ant-table-container,\n    .ant-table-content {\n      border-radius: 0 !important;\n    }\n\n    .ant-table-thead > tr > th,\n    .ant-table-tbody > tr > td,\n    .ant-table-summary > tr > td {\n      border: 1px solid #ddd; // Adjusted for a lighter border color\n      border-radius: 0 !important;\n      padding: 4px; // Reduced padding for all cells\n    }\n\n    .ant-table-thead > tr > th {\n      background-color: white;\n      color: black;\n      border-radius: 0;\n    }\n\n    .ant-table-tbody > tr > td {\n      background-color: white;\n    }\n\n    .ant-table-summary > tr > td {\n      background-color: white;\n      border-top: 1px solid #ddd;\n    }\n  }\n}\n\n@primary-color: #BC982F;@border-radius-base: 10px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
