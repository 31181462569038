import {type Site} from './site.model';
import {type User} from './user.model';
import {type WorkOrder} from './work-order.model';
import {type ProjectProcessMaster} from 'app/components/ProjectProcessMaster/project-process-master';

export type WorkOrderSchedule = {
	id: number;
	siteId: number;
	// WorkOrderTypeId?: number;
	workOrderId?: number;
	projectProcessMasterId?: number;
	scheduleDate?: string;
	budgetUnit?: string;
	budgetQuantity?: number;
	budgetAmount?: number;
	totalQuantity?: number;
	totalAmount?: number;
	status?: WorkOrderScheduleStatus;
	createdAt?: string;
	updatedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	workOrders: WorkOrder[];
	site?: Site;
	// WorkOrderType?: WorkOrderType;
	projectProcessMaster?: ProjectProcessMaster;
	createdUser?: User;

	// Client Defined fields
	workOrderQuantity?: any;
	workOrderAmount?: any;
};

export enum WorkOrderScheduleStatus {
	// TENDER_FLOATED = 'TENDER_FLOATED',
	// TO_BE_APPOINTED_WITH_TENDER = 'TO_BE_APPOINTED_WITH_TENDER',
	// APPOINTED_WITH_TENDER = 'APPOINTED_WITH_TENDER',
	// TO_BE_APPOINTED_WITHOUT_TENDER = 'TO_BE_APPOINTED_WITHOUT_TENDER',
	// APPOINTED_WITHOUT_TENDER = 'APPOINTED_WITHOUT_TENDER',

	// TO_BE_APPOINTED = 'TO_BE_APPOINTED',
	// APPOINTED = 'APPOINTED',

	ISSUED = 'ISSUED',
	TO_BE_ISSUED = 'TO_BE_ISSUED',
	DELAYED = 'DELAYED',
	STOPPED = 'STOPPED',
}

// Export interface NotifyLogic {
//   notifyRoles: string;
//   beforeDays: number;
// }
