import React from 'react';
import {get, remove} from './actions';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useDispatch, useSelector} from 'react-redux';
import {
	type EmailTemplate,
	triggerPointMap,
	triggerToWhomMap,
} from 'app/models/email-template.model';
import {
	Col,
	Row,
	Spin,
	Table,
	Typography,
	Button,
	Space,
	message,
} from 'antd';
import {isMobile} from 'app/helpers';
import {Link} from 'react-router-dom';
import {moduleLabelName} from 'app/models';
import type Types from 'MyTypes';

export const ViewEmailTemplates: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.emailTemplate,
	);
	// Test Comment
	const dataSource: EmailTemplate[] = allIds.map(id => byIds[id]);
	const handleRemoveClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to delete this item.')) {
			dispatch(remove(id));
		}
	};

	React.useEffect(() => {
		dispatch(get({}));
	}, []);
	React.useEffect(() => {
		if (dataUpdated) {
			dispatch(get({}));
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	return (
		<DefaultLayout currentPath={uiPaths.viewEmailTemplates}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3}>Email Templates</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Link
							to={{
								pathname: uiPaths.createEmailTemplate,
							}}
						>
							<Button type='primary'>Add New Email Template</Button>
						</Link>
						<br />
						<br />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Table
							size={'small'}
							bordered={true}
							dataSource={dataSource}
							pagination={false}
							scroll={{x: 800, y: 500}}
						>
							<Table.Column
								title='Module Name'
								dataIndex='moduleName'
								key='moduleName'
								render={moduleName => moduleLabelName[moduleName]}
							/>

							<Table.Column
								title='Trigger Point'
								dataIndex='triggerPoint'
								key='triggerPoint'
								render={(triggerPoint, template: EmailTemplate) =>
									triggerPointMap[template?.moduleName]
										? triggerPointMap[template?.moduleName][triggerPoint]
										: ''
								}
							/>

							<Table.Column
								title='Trigger To Whom'
								dataIndex='triggerToWhom'
								key='triggerToWhom'
								render={(triggerToWhom, template: EmailTemplate) =>
									triggerToWhomMap[template?.moduleName]
										? triggerToWhomMap[template?.moduleName][triggerToWhom]
										: ''
								}
							/>
							<Table.Column
								title='Action'
								dataIndex='action'
								key='action'
								render={(action: string, template: EmailTemplate) => (
									<Space direction={isMobile ? 'vertical' : 'horizontal'}>
										<Link
											to={{
												pathname: `/${uiPaths.editEmailTemplate.replace(
													':id',
													String(template.id ?? '0'),
												)}`,
											}}
										>
											<Button size={'small'} type='primary'>
                        Edit
											</Button>
										</Link>
										<Button
											size={'small'}
											type='primary'
											danger={true}
											onClick={() => {
												handleRemoveClick(template.id);
											}}
										>
                      Delete
										</Button>
									</Space>
								)}
							/>
						</Table>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
