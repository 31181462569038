import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	Button,
	Col,
	Row,
	Spin,
	Typography,
	Space,
	Table,
	Empty,
} from 'antd';
import {setFilterValue, get} from './actions';
import {Link} from 'react-router-dom';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {
	ModuleName,
	ModulePermission,
	type Site,
	type ProjectHandoverActivity,
} from 'app/models';
import type Types from 'MyTypes';
import {
	getPermissionSites,
	formatDateFunction,
	parseNum,
} from 'app/helpers';
import {FieldsLabelName, Fields} from 'app/models';

type DataSourceType = {
	key: string;
	sno: string;
	name: string;
	value: string;
	remarks: string;
	referenceLink?: string;
	children?: DataSourceType[];
};

export const ViewProjectHandoverActivity: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds, filterValue} = useSelector(
		(state: Types.RootState) => state.projectHandoverActivity,
	);
	console.log(filterValue);
	const projectHandoverActivities
		= allIds?.length ? allIds.map(id => byIds[id]) : [];
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const sitesWithReadPermission: Site[] = getPermissionSites(
		permissions,
		ModuleName.PROJECT_HANDOVER_SCHEDULE,
		ModulePermission.READ,
		allSites,
	);
	const sitesWithWritePermission: Site[] = getPermissionSites(
		permissions,
		ModuleName.PROJECT_HANDOVER_SCHEDULE,
		ModulePermission.WRITE,
		allSites,
	);

	const siteOptions: FilterOptionItem[] = sitesWithReadPermission.map(
		site => ({label: site.name, value: String(site.id)}),
	);

	React.useEffect(() => {
		console.log('ViewProjectHandoverActivity component');
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	const filterData = (f: FilterDataType = {}) => {
		// Means the user is client
		if (siteOptions.length === 1) {
			dispatch(
				get({
					where: {
						siteId: siteOptions[0].value,
					},
				}),
			);
		}

		if (!f.siteId) {
			return;
		}

		const filter: any = {where: {}, limit: 1000, order: ['sno ASC']};
		if (f.siteId) {
			filter.where.siteId = f.siteId;
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	const uiFilters: UiFilter[]
		= siteOptions.length > 1 ? [
			{
				filterKey: 'siteId',
				items: siteOptions,
				formType: FilterFormType.SITE_SELECT,
				placeholder: 'Select Site',
				label: 'Site',
				defaultValue: filterValue?.siteId,
			},
		]
			: [];
	const dataSource: DataSourceType[] = [];

	projectHandoverActivities.forEach((item: ProjectHandoverActivity) => {
		const value: string = item.timelineDate
			? formatDateFunction(item.timelineDate, false)
			: String(item.noOfDays);
		const children: DataSourceType[] = [];
		if (item.children?.length) {
			item.children.forEach(child => {
				const childValue = child.timelineDate
					? formatDateFunction(child.timelineDate, false)
					: child.noOfDays;
				const timeImpacts: DataSourceType[] = [];
				if (child.timeImpacts?.length) {
					child.timeImpacts.forEach(timeImpact => {
						timeImpacts.push({
							key: timeImpact.sno ?? '',
							sno: timeImpact.sno ?? '',
							name: timeImpact.title,
							value: String(timeImpact.noOfDays),
							remarks: timeImpact.description ?? '',
							referenceLink: timeImpact.referenceLink,
						});
					});
				}

				children.push({
					key: child.sno,
					sno: child.sno,
					name: FieldsLabelName[Fields[child.activityName]] as string,
					value: childValue as string,
					remarks: child.remarks ?? '',
					children: timeImpacts.length ? timeImpacts : undefined,
				});
			});
		}

		dataSource.push({
			key: item.sno,
			sno: item.sno,
			name: FieldsLabelName[Fields[item.activityName]] as string,
			value,
			remarks: item.remarks ?? '',
			children: children.length ? children : undefined,
		});
	});

	return (
		<DefaultLayout currentPath={uiPaths.viewProjectHandoverSchedule}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Project Handover Schedule
						</Typography.Title>
					</Col>
				</Row>
				{uiFilters.length ? (
					<Row>
						<Col span={24}>
							<FilterView
								uiFilters={uiFilters}
								onSubmit={(f: FilterDataType) => {
									filterData(f);
								}}
							/>
							<br />
						</Col>
					</Row>
				) : (
					[]
				)}
				{filterValue.siteId
					&& sitesWithWritePermission.filter(item => item.id === parseNum(filterValue.siteId as string))
						.length > 0 ? (
						<Row>
							<Col span={24} style={{textAlign: 'right', marginBottom: 10}}>
								<Space>
									<Link
										to={{
											pathname: `${uiPaths.editProjectHandoverSchedule.replace(
												':siteId',
												`${filterValue.siteId as string}`,
											)}`,
										}}
									>
										<Button type={'primary'}>Edit</Button>
									</Link>
								</Space>
							</Col>
						</Row>
					) : (
						[]
					)}

				{dataSource.length ? (
					<Row>
						<Col span={24} className='mb-10'>
							<Table
								dataSource={dataSource}
								bordered={true}
								pagination={false}
								scroll={{x: 2000, y: 1000}}
							>
								<Table.Column
									title='S.no'
									dataIndex='sno'
									key='sno'
									width='5%'
								/>

								<Table.Column
									title='Activity Name'
									dataIndex='name'
									key='name'
									width='20%'
									render={(name: string) => (name ? name : '')}
								/>

								<Table.Column
									title='Value'
									dataIndex='value'
									key='value'
									width='5%'
									render={(value: string) => (value ? value : '')}
								/>
								<Table.Column
									title='Reference Link'
									dataIndex='referenceLink'
									key='referenceLink'
									width='15%'
									render={(referenceLink: string) =>
										referenceLink ? referenceLink : ''
									}
								/>
								<Table.Column
									title='Remarks'
									dataIndex='remarks'
									width='15%'
									key='remarks'
									render={(remarks: string) => (remarks ? remarks : '')}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
