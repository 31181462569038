import React from 'react';
import {Button, Col, Row} from 'antd';
import {PaperClipOutlined} from '@ant-design/icons';
import {getS3Url} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {Link} from 'react-router-dom';
import {type Attachment} from 'app/models';

type Props = {
	label: React.ReactNode;
	help?: string;
	siteId?: number;
	canEdit?: boolean;
	textValue?: string | undefined;
	fileValue?: string | undefined;
	urlValue?: string | undefined;
	attachments?: Attachment[];
};

export const ColFileBlock: React.FC<Props>
	= ({label, help, siteId, canEdit, textValue, fileValue, urlValue, attachments}) => {
		const layout = {
			labelCol: {span: 6},
			wrapperCol: {span: 18},
		};
		return (
			<Row style={{marginBottom: 15}}>
				<Col span={layout.labelCol.span}>
					<b>{label}</b><br />
					{canEdit && siteId ? (
						<Link to={{pathname: `/${uiPaths.editSite.replace(':siteId', String(siteId))}`}}>
							<Button type='primary' size='small'>
								Update
							</Button>
						</Link>
					) : (
						<small>{help}</small>
					)}
				</Col>
				<Col span={layout.wrapperCol.span}>
					{textValue ? <p>{textValue}</p> : []}
					{urlValue ? <p><a href={urlValue} target={'_blank'} rel='noreferrer'>{urlValue}</a></p> : []}
					{fileValue && fileValue.split(',').length > 0
						? fileValue.split(',').map((fileKey: string, ix: number) => (
							<p key={ix}>
								<PaperClipOutlined />
								{' '}
								<a href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
									{fileKey.split('/')[fileKey.split('/').length - 1]}
								</a>
							</p>
						)) : []}
					{attachments?.length ? attachments.map((attachment: Attachment, ix: number) => (
						<p key={ix}>
							<PaperClipOutlined />
							{' '}
							<a href={getS3Url(attachment.key ?? '')} title={attachment.name} target='_blank'
								rel='noreferrer'>
								{attachment.name}
							</a>
						</p>
					)) : []}
				</Col>
			</Row>
		);
	};
