import React from 'react';
import {Button, Card, Form, Input, Switch} from 'antd';
import {type User} from 'app/models';
import {ProjectClientMemberForm} from './ProjectClientMemberForm';
import {PlusOutlined} from '@ant-design/icons';

type Pops = {
	canAddClient: boolean;
	client: User;
	onChange: (client: User) => void;
};

export const ProjectClientForm: React.FC<Pops> = ({client, canAddClient, onChange}) => {
	const onProjectClientMemberChange = (i: number, value: User) => {
		const items = client.members ? [...client.members] : [];
		items[i] = value;
		onChange({...client, ...{members: items}});
	};

	const onProjectClientMemberAdd = () => {
		const items = client.members ? [...client.members] : [];
		items.push({name: '', email: '', username: '', panNumber: ''} as User);
		onChange({...client, ...{members: items}});
	};

	const onProjectClientMemberRemove = (i: number) => {
		const items = client.members ? [...client.members] : [];
		items.splice(i, 1);
		onChange({...client, ...{members: items}});
	};

	return (
		<>
			<Form.Item
				label='Name*'
				extra={'Key Decision Maker'}>
				<Input
					value={client.name}
					defaultValue={client.name}
					onChange={(e: any) => {
						onChange({...client, ...{name: String(e.target.value)}} as User);
					}}
				/>
			</Form.Item>
			<Form.Item label='Email*'>
				<Input
					disabled={!canAddClient}
					value={client.email}
					defaultValue={client.email}
					onChange={(e: any) => {
						onChange({...client, ...{email: String(e.target.value)}} as User);
					}}
				/>
			</Form.Item>
			<Form.Item label='PAN Number*'>
				<Input
					disabled={!canAddClient}
					value={client.panNumber}
					defaultValue={client.panNumber}
					onChange={(e: any) => {
						onChange({...client, ...{panNumber: String(e.target.value)}} as User);
					}}
				/>
			</Form.Item>
			<Form.Item
				label='Mobile Number(s)'
				extra={'Enter Multiple Numbers Separated by Comma'}
			>
				<Input
					disabled={!canAddClient}
					defaultValue={client.mobileNumbers}
					value={client.mobileNumbers}
					onChange={(e: any) => {
						onChange({...client, ...{mobileNumbers: String(e.target.value)}} as User);
					}}
				/>
			</Form.Item>
			<Form.Item label='Current Address'>
				<Input
					disabled={!canAddClient}
					defaultValue={client.clientCurrentAddress}
					value={client.clientCurrentAddress}
					onChange={(e: any) => {
						onChange({...client, ...{clientCurrentAddress: String(e.target.value)}} as User);
					}}
				/>
			</Form.Item>
			<Form.Item label='Family Members'>
				{client.members?.map((member, ix) => (
					<Card
						key={ix}
						size={'small'}
						actions={client.id ? [] : [
							<Button key={ix} type={'dashed'} danger={true} onClick={() => {
								onProjectClientMemberRemove(ix);
							}}>Remove Family Member</Button>,
						]}
					>
						<ProjectClientMemberForm
							canAddClient={canAddClient}
							member={member }
							onChange={(value: User) => {
								onProjectClientMemberChange(ix, value);
							}}
						/>
					</Card>
				))}
				<Button
					disabled={!canAddClient}
					type='dashed'
					onClick={() => {
						onProjectClientMemberAdd();
					}}
					block
					icon={<PlusOutlined />}
				>
					Add Family Member
				</Button>
			</Form.Item>
			<Form.Item
				label='Share Credentials with Client'
				extra={'This will generate the new password and send it to the email of the client. Credentials will be shared to the main email address.'}
			>
				<Switch onChange={checked => {
					onChange({...client, ...{shareCredential: checked}} as User);
				}} />
			</Form.Item>
		</>
	);
};

