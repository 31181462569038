import React from 'react';
import {Col, Row, Typography} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {ViewAllProjectBudgetQuantities} from '../ProjectBudgetQuantities/ViewAllProjectBudgetQuantities';

export const ViewProjectBudgetQuantities: React.FC = () => (
	<DefaultLayout currentPath={uiPaths.projectBudgetQuantities}>
		<Row>
			<Col span={24} style={{textAlign: 'center'}}>
				<Typography.Title level={3}>
            Project Budget Quantities
				</Typography.Title>
			</Col>
		</Row>
		<ViewAllProjectBudgetQuantities />
	</DefaultLayout>
);
