import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row, Spin, Table, Typography} from 'antd';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {type CorporateQueryReport, type User} from 'app/models';
import {getCorporateQueryReport} from './corporate-query-report.reducer';
import {formatDateFunction} from 'app/helpers';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	calculateQueryReportData,
	mutateTree,
} from 'app/services/report-service';

type DataSourceType = {
	key: string;
	name: string;
	weeklyData?: WeeklyDataType[];
	children?: DataSourceType[];
};

export type WeeklyDataType = {
	date: string;
	open: number;
	subordinatesOpen?: number;
};

export const ViewCorporateQueryReport: React.FC = () => {
	const dispatch = useDispatch();
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.corporateQueryReport,
	);
	const today = new Date();
	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		weeklyData: [],
		children: [],
	};

	// Creating a record for all the reports fetched from the backend
	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, CorporateQueryReport[]>>((record, item) => {
		const date = formatDateFunction(item.reportDate, false);
		if (record[date] === undefined) {
			record[date] = [];
		}

		record[date].push(item);
		return record;
	}, {});

	React.useEffect(() => {
		dispatch(
			getCorporateQueryReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						34,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
	}, []);

	// Traversing the tree to assign delays to all nodes and to create sites as child nodes of site incharges(leaf nodes)
	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.name = user.name;
		node.key = String(user.id);
		node.weeklyData = calculateQueryReportData(user, mapOfRecords);
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(users);
	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);

	return (
		<DefaultLayout currentPath={uiPaths.corporateQueryReport}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Corporate Query Report : Open Queries</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='mb-10'>
						<Table
							bordered={true}
							size={'small'}
							dataSource={dataSource}
							scroll={{x: 2000, y: 1000}}
							pagination={false}
						>
							<Table.Column
								title='Name'
								dataIndex='name'
								key='name'
								fixed='left'
								width={240}
							/>
							{[0, 1, 2, 3, 4].map(i => (
								<Table.ColumnGroup key={i} title={Object.keys(mapOfRecords)[i]}>
									<Table.Column
										title='Your open queries'
										dataIndex='weeklyData'
										key='weeklyDataOpen'
										render={weeklyData =>
											// eslint-disable-next-line @typescript-eslint/no-unsafe-return
											weeklyData?.length ? weeklyData[i]?.open : []
										}
										width={120}
									/>
									<Table.Column
										title='Subordinates open queries'
										dataIndex='weeklyData'
										key='weeklyDataSubordinatesOpen'
										render={weeklyData =>
											// eslint-disable-next-line @typescript-eslint/no-unsafe-return
											weeklyData?.length ? weeklyData[i]?.subordinatesOpen : []
										}
										width={120}
									/>
								</Table.ColumnGroup>
							))}
						</Table>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col span={24}>
						<Typography.Text><b>Report Description:</b> This report presents how many corporate queries are open for
              the individual user and his team members.Data is captured every week on Wednesday
              at 12:00 AM in the night.If there is any increase in the number of corporate queries
              which are in open status as compared to the previous week, it means more queries
              are assigned to the user in the last 7 days. If there is any decrease in the number of
              as compared to the previous week, it means that corporate queries have been marked
              as closed either by the assignee or by the user to whom the corporate query was
              assigned.</Typography.Text>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col span={24}>
						<Typography.Text><b>Report Logic:</b> Based upon the number of corporate queries which are in open status
              are captured in the Corporate Query Report. To read the report, you can expand the “+”
              sign in front of the Reporting Manager name to see the list of employees reporting to
              him.
              If you have any query related to this report or a suggestion to improvise the report.
              Please Contact ruchika.singh@prithu.in</Typography.Text>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
