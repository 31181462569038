import {type ProjectFile} from 'app/components/Site/project-file';
import {type GoogleScheduleSheet, type User} from '.';
import {UserRole} from './enums/user-role.enum';

export type Site = {
	id: number;
	name: string;
	projectNumber: number;
	projectType?: ProjectType;
	projectCity?: string;
	address: string;
	status?: SiteStatus;
	stage?: SiteStage;
	location?: string;
	siteInchargeId?: number;
	projectArchitectId?: number;
	approvalsInchargeId?: number;
	procurementInchargeId?: number;
	contractorAppointmentBillingAndTerminationId?: number;
	quantityCalculationId?: number;
	clientInvoicingInchargeId?: number;
	clientInterfaceDesignId?: number;
	clientInterfaceConstructionId?: number;
	clientInterfaceApprovalsId?: number;
	clientInterfaceAccountsId?: number;
	createdBy?: number;
	updatedBy?: number;
	createdAt?: string;
	updatedAt?: string;
	isLive?: boolean;
	agreementSignedAt?: string;
	projectDuration?: number;
	baseSteelCost?: number;
	baseCementPrice?: number;
	warrantyPeriod?: number;
	warrantyPeriodCoverage?: string;
	coveragePeriod?: number;
	demolitionRequired?: string;
	contractSigningDate?: string;
	contractValue?: number;
	agreementPenalty?: number;
	agreementIncentive?: number;
	activityTemplateId?: number;
	siteIncharge?: User;
	projectArchitect?: User;
	approvalsIncharge?: User;
	procurementIncharge?: User;
	workOrdersIncharge?: User;
	clientInvoicingIncharge?: User;
	clientInterfaceDesign?: User;
	clientInterfaceConstruction?: User;
	contractorAppointmentBillingAndTermination?: User;
	quantityCalculation?: User;
	clientInterfaceApprovals?: User;
	clientInterfaceAccounts?: User;
	agreementDocuments?: ProjectFile[];
	clients?: User[];
	sheetURL?: string;
	budgetSheetURL?: string;
	scheduleSheet?: GoogleScheduleSheet;
	designBriefDate?: string;
	floorPlanDate?: string;
	plotHandoverDate?: string;
	barricadingCompletionDate?: string;
	rfCompletionDate?: string;
	mumtyCastingDate?: string;
	windowsDeliveryDate?: string;
	kitchenInstallationDate?: string;
	ccCompletionDate?: string;
	cqDesign?: string;
	cqFinance?: string;
	cqApprovals?: string;
	cqConstruction?: string;
	pqDesign?: string;
	pqFinance?: string;
	pqApprovals?: string;
	pqConstruction?: string;
	pqProcurement?: string;
	pqQualityCalculation?: string;
	pqContractorAppointmentBT?: string;
};

export enum ClientNumbers {
	ONE = 'ONE',
	MANY = 'MANY',
}

export const siteSurveyReportFilledBy = [UserRole.PROJECT_ARCHITECT, UserRole.AREA_PROJECT_MANAGER, UserRole.GOVERNMENT_APPROVAL_IN_CHARGE];

export const getWarrantyPeriodCoverageLabel = (value: string) => {
	const [type, duration] = value.split('-');
	return `${type} (${duration} Years)`;
};

export enum SiteStatus {
	UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
	RECENTLY_COMPLETED = 'RECENTLY_COMPLETED',
	UNDER_WARRANTY = 'UNDER_WARRANTY',
	UNDER_DESIGN = 'UNDER_DESIGN',
	OUT_OF_WARRANTY = 'OUT_OF_WARRANTY',
	ON_HOLD = 'ON_HOLD',
	CANCELLED = 'CANCELLED',
}

export enum SiteStage {
	FLOOR_PLANNING = 'FLOOR_PLANNING',
	PRE_CONSTRUCTION = 'PRE_CONSTRUCTION',
	BASEMENT = 'BASEMENT',
	STRUCTURE = 'STRUCTURE',
	PRE_FINISHING = 'PRE_FINISHING',
	FINISHING = 'FINISHING',
	PRE_DESIGN_BRIEF = 'PRE_DESIGN_BRIEF',
	DEMOLITION = 'DEMOLITION',
	FOUNDATION = 'FOUNDATION',
	HANDOVER_PREPARATION = 'HANDOVER_PREPARATION',
}

export enum ProjectType {
	TURNKEY = 'Turnkey',
	COLLABORATION = 'Collaboration',
	SALE = 'Sale',
	TRIAL = 'Trial',
	RENOVATION = 'Renovation',
	FEE_BASED = 'Fee Based',
	BOQ_BASED = 'BOQ Based',
}
export enum ProjectCity {
	DELHI = 'Delhi',
	NOIDA = 'Noida',
	GURUGRAM = 'Gurugram',
	FARIDABAD = 'Faridabad',
	GREATER_NOIDA = 'Greater Noida',
	GHAZIABAD = 'Ghaziabad',
	CHANDIGARH = 'Chandigarh',
}

export enum WarrantyPeriodCoverageType {
	STRUCTURE = 'Structure',
	SEEPAGE = 'Seepage',
	GENERAL = 'General',
}
