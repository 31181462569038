import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	initPage: '@corporateQuery/INIT_PAGE',
	changePage: '@corporateQuery/CHANGE_PAGE',
	paginationChange: '@corporateQuery/PAGINATION_CHANGE',
	allPaginationChange: '@corporateQuery/ALL_PAGINATION_CHANGE',
	raisedPaginationChange: '@corporateQuery/RAISE_PAGINATION_CHANGE',
	assignedPaginationChange: '@corporateQuery/ASSIGNED_PAGINATION_CHANGE',
	apiFilter: '@corporateQuery/API_FILTER',
	apiAllFilter: '@corporateQuery/API_ALL_FILTER',
	apiRaisedFilter: '@corporateQuery/API_RAISED_FILTER',
	apiAssignedFilter: '@corporateQuery/API_ASSIGNED_FILTER',
	getApi: '@corporateQuery/GET_API',
	getCountApi: '@corporateQuery/GET_COUNT_API',
	getSingleApi: '@corporateQuery/GET_SINGLE_API',
	getUserSiteApi: '@corporateQuery/GET_USER_SITE_API',
	createApi: '@corporateQuery/CREATE_API',
	updateApi: '@corporateQuery/UPDATE_API',
};

const url = `${lbBaseUrl}/corporate-queries`;

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const changePage = (pageType: string) => ({
	type: actionTypes.changePage,
	payload: pageType,
	meta: {},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const onAllPaginationChange = (
	allCurrentPos: number,
	allPerPageSize: number,
) => ({
	type: actionTypes.allPaginationChange,
	payload: {allCurrentPos, allPerPageSize},
	meta: {},
});

export const onRaisedPaginationChange = (
	raisedCurrentPos: number,
	raisedPerPageSize: number,
) => ({
	type: actionTypes.raisedPaginationChange,
	payload: {raisedCurrentPos, raisedPerPageSize},
	meta: {},
});

export const onAssignedPaginationChange = (
	assignedCurrentPos: number,
	assignedPerPageSize: number,
) => ({
	type: actionTypes.assignedPaginationChange,
	payload: {assignedCurrentPos, assignedPerPageSize},
	meta: {},
});

export const setFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: value,
	meta: {},
});

export const setAllFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiAllFilter,
	payload: value,
	meta: {},
});

export const setRaisedFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiRaisedFilter,
	payload: value,
	meta: {},
});

export const setAssignedFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiAssignedFilter,
	payload: value,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getById = (id: number, filter: any = {}) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(
		`${url}/${id}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

// Export const update = (id: number, data: any) => ({
//   type: actionTypes.updateApi,
//   payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
//   meta: { process: true },
// });

export const close = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/close`, data, getHeaders(true)),
	meta: {process: true},
});

export const reOpen = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/re-open`, data, getHeaders(true)),
	meta: {process: true},
});

export const escalate = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/escalate`, data, getHeaders(true)),
	meta: {process: true},
});

export const reassign = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/reassign`, data, getHeaders(true)),
	meta: {process: true},
});
