import {loadState} from 'app/store/helpers';
import type * as actions from './actions';
import {type ActionType} from 'typesafe-actions';
import {type ExpenseReport, type PrithuWallet} from 'app/models';
import {logoutSuccessful} from '../Common/auth-actions';
import {actionTypes} from './actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = ActionType<typeof actions>;

export type MyState = Readonly<{
	byIds: Record<ExpenseReport['id'], ExpenseReport>;
	allIds: Array<ExpenseReport['id']>;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
	filterValue: FilterDataType;
	approvedCurrentPos: number;
	approvedPerPageSize: number;
	approvedFilterValue: FilterDataType;
	allPageType?: string;
	pageType?: string;
	userWallet?: PrithuWallet;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
	filterValue: {},
	approvedCurrentPos: 1,
	approvedPerPageSize: 10,
	approvedFilterValue: {},
	allPageType: undefined,
	pageType: undefined,
	userWallet: undefined,
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

// eslint-disable-next-line complexity
export default function expenseReport(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('expenseReport', initialState),
	action: any,
): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.changePage:
			return {
				...state,
				...{
					pageType: action.payload as string,
					filterValue: {},
				},
			};
		case actionTypes.changeAllPage:
			return {
				...state,
				...{
					allPageType: action.payload as string,
					byIds: {},
					allIds: [],
					totalCount: 0,
					currentPos: 1,
					perPageSize: 10,
					filterValue: {},
				},
			};
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.approvedApiFilter:
			return {
				...state,
				approvedFilterValue: action.payload as FilterDataType,
			};
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: Number(action.payload.currentPos),
					perPageSize: Number(action.payload.perPageSize),
				},
			};
		case actionTypes.approvedPaginationChange:
			return {
				...state,
				...{
					approvedCurrentPos: Number(action.payload.approvedCurrentPos),
					approvedPerPageSize: Number(action.payload.approvedPerPageSize),
				},
			};
		case actionTypes.initPage:
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getCountApi):
		case getRequestActionType(actionTypes.getSingleApi):
		case getRequestActionType(actionTypes.getUserWalletApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getCountApi):
		case getFailureActionType(actionTypes.getSingleApi):
		case getFailureActionType(actionTypes.getUserWalletApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as ExpenseReport[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: action.payload.data.count as number,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getSuccessActionType(actionTypes.getSingleApi): {
			const newItem = action.payload.data as ExpenseReport;
			const newAllIds = [...state.allIds];
			if (!newAllIds.includes(newItem.id)) {
				newAllIds.push(newItem.id);
			}

			const newByIds = {...state.byIds};
			newByIds[newItem.id] = newItem;
			return {
				...state,
				...{
					byIds: newByIds,
					allIds: newAllIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getUserWalletApi): {
			const userWallet = action.payload.data as PrithuWallet;
			return {
				...state,
				...{
					userWallet,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
}
