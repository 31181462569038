import {Button, Card, Select, Space} from 'antd';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {type Site} from 'app/models';
import {update} from '../actions';

type Props = {
	canEdit: boolean;
	siteId: number;
	currentValue?: string;
};

export const EditDemolitionRequiredForm: React.FC<Props> = ({canEdit, siteId, currentValue}) => {
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [demolitionRequired, setDemolitionRequired] = useState(currentValue);

	const handleSubmit = () => {
		const data: Partial<Site> = {
			demolitionRequired,
		};
		dispatch(update(siteId, data));
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Card>
				<Space>
					<Select
						style={{width: 250}}
						value={demolitionRequired}
						defaultValue={demolitionRequired}
						onChange={value => {
							setDemolitionRequired(value);
						}}
					>
						<Select.Option value={'Yes'}>Yes</Select.Option>
						<Select.Option value={'No'}>No</Select.Option>
					</Select>
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Space>
			</Card>
		) : (
			<Space>
				<label>{currentValue ?? ''}</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
