import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Form, Input, Row, Spin, Typography, message} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import * as qs from 'qs';
import {SimpleLayout} from './Layout/SimpleLayout';
import {uiPaths} from '../constants';
import {Link, Redirect, useHistory, useLocation} from 'react-router-dom';
import {initPage, login} from './Common/auth-actions';
import {loadUser} from './Common/summary-actions';
import type Types from 'MyTypes';

export const EmailRedirect: React.FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const parsedQuery = qs.parse(location.search, {ignoreQueryPrefix: true});
	const history = useHistory();
	const {loading: authLoading, errorMessage, isAuthenticated} = useSelector((state: Types.RootState) => state.auth);
	const {user: authUser, loading: userLoading} = useSelector((state: Types.RootState) => state.summary);
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	useEffect(() => {
		if (authLoading) {
			dispatch(initPage());
		}
	}, []);

	useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
			dispatch(initPage());
		}
	}, [errorMessage]);

	useEffect(() => {
		if (isAuthenticated) {
			const signedIn = localStorage.getItem('appToken')?.length;
			if (signedIn) {
				dispatch(loadUser());
			}
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (authUser) {
			history.push({
				pathname: getRedirectTo(parsedQuery),
			});
		}
	}, [authUser]);

	const onSubmit = () => {
		if (!username || !password) {
			void message.error('Enter Username and Password');
			return;
		}

		const data = {
			email: username,
			password,
		};
		dispatch(login(data));
	};

	// eslint-disable-next-line complexity
	const getRedirectTo = parsedQuery => {
		if (!parsedQuery?.open) {
			return '/';
		}

		if (parsedQuery.open === 'profile' && parsedQuery.id) {
			return `/${uiPaths.userDetail.replace(
				':userId',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'queries' && parsedQuery.id) {
			return `/${uiPaths.queryDetail.replace(
				':queryId',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'corporate-queries' && parsedQuery.id) {
			return `/${uiPaths.corporateQueryDetail.replace(
				':id',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'client-queries' && parsedQuery.id) {
			return `/${uiPaths.clientQueryDetail.replace(
				':id',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'labour-attendance') {
			return `/${uiPaths.labourAttendance}`;
		}

		if (parsedQuery.open === 'material-queries' && parsedQuery.id) {
			return `/${uiPaths.materialQueryDetail.replace(
				':queryId',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'work-order' && parsedQuery.id) {
			return `/${uiPaths.workOrderDetail.replace(
				':orderId',
				String(parsedQuery.id),
			)}`;
		}

		if (
			parsedQuery.open === 'edit-project-process-schedule'
      && parsedQuery.id
		) {
			return `/${uiPaths.editProjectProcessSchedule.replace(
				':id',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'work-order-bill' && parsedQuery.id) {
			return `/${uiPaths.workOrderBillDetail.replace(
				':id',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'expense-report' && parsedQuery.id) {
			return `/${uiPaths.pettyCashDetail.replace(
				':id',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'edit-site' && parsedQuery.id) {
			return `/${uiPaths.editSite.replace(
				':siteId',
				String(parsedQuery.id),
			)}`;
		}

		if (parsedQuery.open === 'site' && parsedQuery.id) {
			return `/${uiPaths.siteDetail.replace(
				':siteId',
				String(parsedQuery.id),
			)}`;
		}

		if (
			parsedQuery.open === 'drawing-schedule-detail'
      && parsedQuery.id
      && parsedQuery.sid
		) {
			return `/${uiPaths.drawingProcessScheduleForm.replace(
				':siteId',
				String(parsedQuery.sid),
			).replace(':tabId', String(parsedQuery.id))}`;
		}

		if (parsedQuery.open && parsedQuery.page) {
			return `/${parsedQuery.open}?page=${parsedQuery.page}`;
		}

		if (parsedQuery.open) {
			return `/${parsedQuery.open}`;
		}

		return '/';
	};

	const signedIn
    = localStorage.getItem('appToken')?.length;
	if (signedIn) {
		return <Redirect to={getRedirectTo(parsedQuery)} />;
	}

	return (
		<SimpleLayout>
			<Spin size='large' spinning={userLoading} tip={''}>
				<Row justify={'center'}>
					<Col style={{maxWidth: '300px'}}>
						<Typography.Title style={{textAlign: 'center'}}>
							<span>Please login to continue</span>
						</Typography.Title>
						<Form style={{textAlign: 'center'}}>
							<Form.Item>
								<Input
									prefix={<UserOutlined className='site-form-item-icon'/>}
									placeholder={'Enter Email'}
									required={true}
									id='username'
									size={'large'}
									onChange={(e: any) => {
										setUsername(String(e.target.value).toLowerCase().replace(' ', '.').trim());
									}}
								/>
							</Form.Item>
							<Form.Item>
								<Input.Password
									prefix={<LockOutlined className='site-form-item-icon'/>}
									placeholder={'Enter Password'}
									required={true}
									id='password'
									size={'large'}
									onChange={(e: any) => {
										setPassword(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item>
								<Button
									id={'login'}
									type='primary'
									size={'large'}
									onClick={onSubmit}
									loading={authLoading}
									style={{width: '100%'}}
								>
									{'Login'}
								</Button>
							</Form.Item>
							<Form.Item>
								<Link to={{pathname: `/${uiPaths.forgotPassword}`}}>
                  Forgot Password?
								</Link>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</SimpleLayout>
	);
};
