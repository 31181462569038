import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Form, Input, message, Row, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Link, Redirect, useHistory} from 'react-router-dom';
import {SimpleLayout} from './Layout/SimpleLayout';
import {initPage, login} from './Common/auth-actions';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';

export const Login: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {loading, errorMessage, isAuthenticated} = useSelector((state: Types.RootState) => state.auth);
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	useEffect(() => {
		if (loading) {
			dispatch(initPage());
		}
	}, []);

	useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
			dispatch(initPage());
		}
	}, [errorMessage]);

	useEffect(() => {
		if (isAuthenticated) {
			const signedIn = localStorage.getItem('appToken')?.length;
			if (signedIn) {
				history.push({
					pathname: '/',
				});
			}
		}
	}, [isAuthenticated]);

	const onSubmit = () => {
		if (!username || !password) {
			void message.error('Enter Username and Password');
			return;
		}

		const data = {
			email: username,
			password,
		};
		dispatch(login(data));
	};

	const signedIn = localStorage.getItem('appToken')?.length;
	if (signedIn) {
		return <Redirect to='/'/>;
	}

	return (
		<SimpleLayout>
			<Row justify={'center'}>
				<Col style={{maxWidth: '300px'}}>
					<Typography.Title style={{textAlign: 'center'}}>
						<span>Welcome to<br/> Prithu Homes</span>
					</Typography.Title>
					<Form style={{textAlign: 'center'}}>
						<Form.Item>
							<Input
								prefix={<UserOutlined className='site-form-item-icon'/>}
								placeholder={'Enter Email'}
								required={true}
								id='username'
								size={'large'}
								onChange={(e: any) => {
									setUsername(String(e.target.value).toLowerCase().replace(' ', '.').trim());
								}}
							/>
						</Form.Item>
						<Form.Item>
							<Input.Password
								prefix={<LockOutlined className='site-form-item-icon'/>}
								placeholder={'Enter Password'}
								required={true}
								id='password'
								size={'large'}
								onChange={(e: any) => {
									setPassword(String(e.target.value));
								}}
							/>
						</Form.Item>
						<Form.Item>
							<Button
								id={'login'}
								type='primary'
								size={'large'}
								onClick={onSubmit}
								loading={loading}
								style={{width: '100%'}}
							>
								{'Login'}
							</Button>
						</Form.Item>
						<Form.Item>
							<Link to={{pathname: `/${uiPaths.forgotPassword}`}}>
								Forgot Password?
							</Link>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</SimpleLayout>
	);
};
