import React from 'react';
import {Button, Card, Col, Row, Table} from 'antd';
import {type User, UserType} from '../../models';
import {UserRole} from '../../models/enums/user-role.enum';
import {toTitleCase} from '../../helpers';
import {FilterFormType, type FilterOptionItem} from '../../models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {Link} from 'react-router-dom';
import {uiPaths} from '../../constants';

type Props = {
	users: User[];
	source?: string;
};

export const ViewAllProfileUser: React.FC<Props> = ({users, source}) => {
	const [filterValue, setFilterValue] = React.useState<FilterDataType>({role: ''});

	const internals = users.filter((u: User) => u.userType === UserType.EMPLOYEE);
	const clients = users.filter((u: User) => u.userType === UserType.CLIENT);
	const vendors = users.filter((u: User) => u.userType === UserType.VENDOR);
	const contractors = users.filter((u: User) => u.userType === UserType.CONTRACTOR);

	const filterFn = (u: User) => filterValue.role ? u.roles && String(u.roles) === filterValue.role : true;
	const compareFn = (a: User, b: User) => a.name > b.name ? 1 : -1;

	const dataSource: User[] = [
		{id: 0, username: '', name: 'Team', email: '', panNumber: ''},
		...internals.filter(filterFn).sort(compareFn),
		{id: 0, username: '', name: 'Clients', email: '', panNumber: ''},
		...clients.filter(filterFn).sort(compareFn),
		{id: 0, username: '', name: 'Vendors', email: '', panNumber: ''},
		...vendors.filter(filterFn).sort(compareFn),
		{id: 0, username: '', name: 'Contractors', email: '', panNumber: ''},
		...contractors.filter(filterFn).sort(compareFn),
	];

	const roleOptions: FilterOptionItem[] = Object.values(UserRole)
		.map(role => ({value: role, label: toTitleCase(role as string) ?? ''}));

	const uiFilters = [
		{
			filterKey: 'role',
			items: roleOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Role',
			label: 'Role',
		},
	];

	return (
		<Row>
			{source && source === 'profile' ? (
				<Col span={24} className={'mb-10'}>
					<Card size='small' title={'Important note'}>
						Following are your team members. Please contact <b>Training Manager</b> for any correction.
					</Card>
				</Col>
			) : []}

			{source && source === 'profile' ? (
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						submitOnChange={true}
						onSubmit={filter => {
							setFilterValue(filter);
						}}
					/>
				</Col>
			) : []}

			<Col span={24} className='mb-10'>
				{source && source === 'profile' ? <Button className={'mb-10'}>{`${dataSource.length - 4} Results`}</Button> : []}
				<Table
					size={'small'}
					bordered={true}
					dataSource={dataSource}
					pagination={false}
					scroll={{x: 400, y: 500}}
				>
					<Table.Column
						title='Name'
						dataIndex='name'
						key='name'
						render={(name: string, u: User) => u.id ? (
							<Link to={{pathname: `/${uiPaths.userDetail.replace(':userId', u.id.toString())}`}}>
								{name}
							</Link>
						) : (
							<b>{name}</b>
						)}
					/>
					<Table.Column
						title='Role'
						dataIndex='roles'
						key='roles'
						render={(roles: UserRole | undefined) => roles ? toTitleCase(roles) : ''}
					/>
					<Table.Column
						title='Contact Details'
						key='contact'
						render={(contact: string, u: User) => `${u.email ?? ''} ${u.mobileNumbers ?? ''}`}
					/>
					<Table.Column
						title='Pan Card'
						dataIndex='panNumber'
						key='panNumber'
					/>
				</Table>
			</Col>
		</Row>
	);
};
