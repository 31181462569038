import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Form,
	message,
	Row,
	Select,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {createAll} from './actions';
import {get as getSites} from '../Site/actions';
import {useHistory} from 'react-router';
import {numberWithCommas, parseNum, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';
import {ModulePermission, moduleLabelName, type UserPermission, ModuleName, ExpenseType, extraModuleWisePermissions, SiteStatus} from 'app/models';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {svrProjectFileTypes} from '../Site/project-file';

export const ViewUserPermissionForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {loading, dataUpdated, errorMessage}
		= useSelector((state: Types.RootState) => state.userPermission);
	const {byIds: siteByIds, allIds: allSiteIds} = useSelector((state: Types.RootState) => state.site);

	const {userOptions} = useSelector((state: Types.RootState) => state.summary);

	const [userIds, setUserIds] = React.useState<string[]>([]);
	const [siteIds, setSiteIds] = React.useState<string[]>([]);
	const [permission, setPermission] = React.useState<string>('');
	const [moduleName, setModuleName] = React.useState<string>('');
	const [condition, setCondition] = React.useState<string>('');
	const [siteStatus, setSiteStatus] = React.useState<string>('');
	const [level, setLevel] = React.useState<number>(0);

	const allSites = allSiteIds
		.map(id => siteByIds[id]);
		// .map(site => ({label: site.name, value: String(site.id)}));

	const conditionOptions = Object.values(ExpenseType).map(condition => ({
		value: condition,
		label: toTitleCase(condition) ?? '',
	}));

	const siteStatusOptions = Object.values(SiteStatus).map(status => ({
		value: status,
		label: toTitleCase(status) ?? '',
	}));

	React.useEffect(() => {
		dispatch(getSites({where: {isLive: true}}));
		// TBU dispatch(getUsers({where: {}}));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated');
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const handleSiteIdsChange = (values: string[]) => {
		// If "selectAll" is in the array, toggle select all options
		const filteredSites = allSites.filter(site =>
			siteStatus ? String(site.status) === siteStatus : true,
		);
		if (values.includes('selectAll')) {
			if (siteIds.length < filteredSites.length) {
				setSiteIds(filteredSites.map(site => String(site.id)));
			} else {
				setSiteIds([]);
			}
		} else {
			setSiteIds(values);
		}
	};

	const handleUserIdsChange = (values: string[]) => {
		// If "selectAll" is in the array, toggle select all options
		if (values.includes('selectAll')) {
			if (userIds.length < userOptions.length) {
				setUserIds(userOptions.map(opt => opt.value));
			} else {
				setUserIds([]);
			}
		} else {
			setUserIds(values);
		}
	};

	const onSubmitClicked = () => {
		const data: Array<Omit<UserPermission, 'id'>> = [];

		siteIds.forEach(siteId => {
			userIds.forEach(userId => {
				data.push({
					moduleName: moduleName as ModuleName,
					level,
					permission,
					condition,
					userId: parseNum(userId),
					siteId: parseNum(siteId),
				});
			});
		});

		console.log({data});

		if (!data?.length) {
			void message.error('Please select permissions!');
		}

		// eslint-disable-next-line no-alert
		if (data?.length > 10 && !confirm(`Are you sure you want to add ${numberWithCommas(data.length)} permissions. This might take some time to process. Also no email notification will be send.`)) {
			return;
		}

		dispatch(createAll(data));
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	const extraPermissions = extraModuleWisePermissions[moduleName] ?? [];

	return (
		<DefaultLayout currentPath={uiPaths.addPermissions}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							User Permission Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
							Back
						</Button>
						<Form {...layout} name='basic'>
							<Form.Item label='Module*'>
								<Select
									showSearch={true}
									style={{width: '100%'}}
									placeholder='Select a Module'
									defaultValue={moduleLabelName[moduleName]}
									onChange={value => {
										setModuleName(value);
										setPermission('');
										setLevel(0);
										setCondition('');
										setSiteIds([]);
										setUserIds([]);
									}}
								>
									{Object.keys(moduleLabelName).map((module: string, ix: number) => (
										<Select.Option key={ix} value={module}>
											{moduleLabelName[module]}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label='Permissions*'>
								<Select
									style={{width: '100%'}}
									placeholder='Select a Permission'
									onChange={value => {
										setPermission(String(value));
										setCondition('');
										setLevel(0);
									}}
								>
									<Select.Option key={ModulePermission.READ} value={ModulePermission.READ}>View</Select.Option>
									<Select.Option key={ModulePermission.WRITE} value={ModulePermission.WRITE}>Edit</Select.Option>
									<Select.Option key={ModulePermission.APPROVE} value={ModulePermission.APPROVE}>Approve</Select.Option>
									{extraPermissions.map((permission: string, ix: number) => (
										<Select.Option key={ix} value={permission}>
											{toTitleCase(permission)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							{permission === String(ModulePermission.APPROVE) ? (
								<Form.Item label='Level'>
									<Select
										style={{width: '100%'}}
										placeholder='Select a Level'
										onChange={value => {
											setLevel(parseNum(String(value)));
										}}
									>
										<Select.Option key={1} value={1}>1</Select.Option>
										<Select.Option key={2} value={2}>2</Select.Option>
										<Select.Option key={3} value={3}>3</Select.Option>
										<Select.Option key={4} value={4}>4</Select.Option>
									</Select>
								</Form.Item>
							) : []}

							{moduleName === String(ModuleName.EXPENSE_REPORT) && permission === String(ModulePermission.APPROVE) ? (
								<Form.Item label='Condition'>
									<Select
										style={{width: 400}}
										placeholder='Select a Condition'
										onChange={value => {
											setCondition(String(value));
										}}
									>
										{conditionOptions.map((option: FilterOptionItem, ix: number) =>
											<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
										)}
									</Select>
								</Form.Item>
							) : []}

							{moduleName === String(ModuleName.PROJECT_DETAIL) ? (
								<Form.Item label='Condition'>
									<Select
										style={{width: 400}}
										placeholder='Select a Condition'
										onChange={value => {
											setCondition(String(value));
										}}
									>
										{svrProjectFileTypes.map((option: string, ix: number) =>
											<Select.Option key={ix} value={option}>{option}</Select.Option>,
										)}
									</Select>
								</Form.Item>
							) : []}

							<Form.Item label='Site Status'>
								<Select
									style={{width: 400}}
									placeholder='Select a Status'
									onChange={value => {
										setSiteStatus(String(value));
										setSiteIds([]);
									}}
								>
									<Select.Option key='-1' value=''>
										Select All
									</Select.Option>
									{siteStatusOptions.map((option: FilterOptionItem, ix: number) =>
										<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
									)}
								</Select>
							</Form.Item>

							<Form.Item label='Site*'>
								<Select
									mode='multiple'
									style={{width: '100%'}}
									placeholder='Select Sites'
									optionFilterProp='children'
									value={siteIds}
									onChange={handleSiteIdsChange}
								>
									<Select.Option key='selectAll' value='selectAll'>
										Select All
									</Select.Option>
									{allSites.filter(site =>
										siteStatus ? String(site.status) === siteStatus : true,
									).map(site => (
										<Select.Option key={String(site.id)} value={String(site.id)}>
											{site.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item label='User*'>
								<Select
									mode='multiple'
									style={{width: '100%'}}
									placeholder='Select Users'
									optionFilterProp='children'
									value={userIds}
									onChange={handleUserIdsChange}
								>
									<Select.Option key='selectAll' value='selectAll'>
										Select All
									</Select.Option>
									{userOptions.map(option => (
										<Select.Option key={option.value} value={option.value}>
											{option.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
