import React from 'react';
import {Space} from 'antd';

export const ViewParseLink: React.FC<{parseLink: string}> = ({parseLink}) => {
	const parseLinks = parseLink.split(',').map(ln => {
		const splitLn = ln.trim().split(']');
		if (splitLn.length === 1) {
			const url = splitLn[0];
			return {name: url, url};
		}

		if (splitLn.length > 1) {
			const name = splitLn[0].trim().replace('[', '');
			const url = splitLn[1].trim();
			return {name, url};
		}

		return {};
	});
	return (
		<Space direction={'vertical'}>
			{parseLinks.map((parseLink, ix) => (
				<a key={ix} href={parseLink.url} target={'_blank'} rel='noreferrer'>{parseLink.name}</a>
			))}
		</Space>
	);
};
