import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import axios from 'axios';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	getApi: '@labourAttendance/GET_API',
	createApi: '@labourAttendance/CREATE_API',
	updateApi: '@labourAttendance/UPDATE_API',
	getCountApi: '@labourAttendance/GET_COUNT_API',
	saveAllApi: '@labourAttendance/SAVE_ALL_API',
	getSingleApi: '@labourAttendance/GET_SINGLE_API',
	initPage: '@labourAttendance/INIT_PAGE',
	apiFilter: '@labourAttendance/API_FILTER',
	dailyLabourReportApiFilter:
    '@labourAttendance/DAILY_LABOUR_REPORT_API_FILTER',
};

const url = lbBaseUrl + '/labour-attendances';

export const initPage = () => ({type: actionTypes.initPage, meta: {}});

export const setFilterValue = (value: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: value,
	meta: {},
});

export const setDailyLabourReportFilterValue = (value: FilterDataType) => ({
	type: actionTypes.dailyLabourReportApiFilter,
	payload: value,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const saveAll = (data: any, siteId: number) => ({
	type: actionTypes.saveAllApi,
	payload: axios.post(`${url}/${siteId}/save-all`, data, getHeaders(true)),
	meta: {process: true},
});

export const getById = (id: number, filter: any = {}) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(
		`${url}/${id}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});
