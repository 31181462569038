import {type Site, type UserPermission} from '.';
import {type UserRole} from './enums/user-role.enum';
import {type PaymentDetail} from './payment-detail.model';

export type User = {
	id: number;
	username: string;
	empNo?: string;
	profileImage?: string;
	salutations?: string;
	name: string;
	lastName?: string;
	email: string;
	password?: string;
	mobileNumbers?: string;
	detail?: string;
	roles?: UserRole;
	isAdmin?: boolean;
	userType?: UserType;
	lastLogin?: string;
	subordinate?: boolean;
	paymentDetail?: PaymentDetail;
	address?: string;
	reportingUserId?: number;
	onSiteToken?: string;
	fcmToken?: string;
	panNumber?: string;
	userDetail?: UserDetail;
	shareCredential?: boolean;
	clientCurrentAddress?: string;
	members?: User[];
	reportingUser?: User;
	userPermissions?: UserPermission[];
	allSites?: Site[];
	subordinates?: User[];
	internalUsers?: User[];
	subordinateUsers?: User[];
	newNotificationCount?: number;
	dateJoined?: string;
	isActive?: boolean;
};

export type UserDetail = {
	bloodGroup?: string;
	localAddress?: UserAddress;
	permanentAddress?: UserAddress;
	emergencyContact?: ContactDetail;
	members?: UserMember[];
};

export type UserAddress = {
	addressLine?: string;
	googlePin?: string;
};

export type ContactDetail = {
	name?: string;
	phone?: string;
};

export type UserMember = {
	name: string;
	email?: string;
	phone?: string;
	currentAddress?: string;
	remarks?: string;
	salutations?: string;
};

export enum UserType {
	EMPLOYEE = 'EMPLOYEE',
	CLIENT = 'CLIENT',
	VENDOR = 'VENDOR',
	CONTRACTOR = 'CONTRACTOR',
}
