import {loadState} from 'app/store/helpers';
import {actionTypes, userLoaded} from './summary-actions';
import {type User} from '../../models';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {logoutSuccessful} from './auth-actions';
import {getFailureActionType, getRequestActionType, getSuccessActionType} from 'app/store/action-type.util';

export type MyState = Readonly<{
	user?: User;
	users: User[];
	contractorUsers: User[];
	clientUsers: User[];
	userOptions: FilterOptionItem[];
	contractorOptions: FilterOptionItem[];
	clientOptions: FilterOptionItem[];
	subordinateOptions: FilterOptionItem[];
	userOptionsByRole: Record<string, FilterOptionItem[]>;
	loading?: boolean;
	errorMessage?: string;
	lastUpdated?: string;
}>;

const initialState: MyState = {
	user: undefined,
	users: [],
	contractorUsers: [],
	clientUsers: [],
	userOptions: [],
	contractorOptions: [],
	clientOptions: [],
	subordinateOptions: [],
	userOptionsByRole: {},
	loading: false,
	errorMessage: undefined,
	lastUpdated: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
export default function summary(state: MyState = loadState('summary', initialState), action: any): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case getRequestActionType(userLoaded):
		case getRequestActionType(actionTypes.getContractor):
		case getRequestActionType(actionTypes.getClient):
			return {
				...state,
				...{
					loading: true,
					lastUpdated: undefined,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(userLoaded):
		case getFailureActionType(actionTypes.getContractor):
		case getFailureActionType(actionTypes.getClient):
			return {
				...state,
				...{
					errorMessage: action.payload?.response?.data?.error?.message as string ?? undefined,
					loading: false,
					lastUpdated: undefined,
				},
			};
		case getSuccessActionType(userLoaded): {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const user = action.payload.data ? {...action.payload.data} as User : {} as User;
			const {internalUsers = [], subordinateUsers = []} = user;
			delete user.internalUsers;
			delete user.subordinateUsers;
			delete user.userPermissions;
			delete user.allSites;
			console.log({internalUsers});
			return {
				...state,
				...{
					user,
					users: [...internalUsers],
					userOptions: internalUsers.map(({id, name}: User) => ({value: id.toString(), label: name})),
					subordinateOptions: subordinateUsers.map(({id, name}: User) => ({value: id.toString(), label: name})),
					userOptionsByRole: {}, // To be removed and its usage
					loading: false,
					lastUpdated: new Date().toString(),
				},
			};
		}

		case getSuccessActionType(actionTypes.getContractor): {
			const contractors = action.payload.data as User[];
			return {
				...state,
				...{
					contractorUsers: contractors,
					contractorOptions: contractors ? contractors.map(({id, name}: User) => ({value: id.toString(), label: name})) : [],
					loading: false,
					lastUpdated: new Date().toString(),
				},
			};
		}

		case getSuccessActionType(actionTypes.getClient): {
			const clients = action.payload.data as User[];
			return {
				...state,
				...{
					clientUsers: clients,
					clientOptions: clients ? clients.map(({id, name}: User) => ({value: id.toString(), label: name})) : [],
					loading: false,
					lastUpdated: new Date().toString(),
				},
			};
		}

		default:
			return state;
	}
}
