import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row, Spin, Table, Typography} from 'antd';
import {
	SiteStatus,
	ModuleName,
	ModulePermission,
	type MonthlyPettyCashReport,
	ProjectType,
} from 'app/models';
import {type User} from 'app/models';
import {get as getUserPermission} from '../UserPermission/actions';
import type Types from 'MyTypes';
import {get as getSites} from '../Site/actions';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {calculateLaisioningExpense, mutateTree} from 'app/services/report-service';
import {formatMonthYearFunction, numberWithCommas} from 'app/helpers';
import {getMonthlyPettyCashReport} from './monthly-petty-cash-report-reducer';

type DataSourceType = {
	key: string;
	expenses: ExpenseType[];
	name: string;
	children?: DataSourceType[];
};

export type ExpenseType = {
	date: string;
	expense: number;
};

export const ViewMonthlyPettyCashReport: React.FC = () => {
	const dispatch = useDispatch();
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {allIds: allUserPermissionsId, byIds: byUserPermissionIds}
    = useSelector((state: Types.RootState) => state.userPermission);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.monthlyPettyCashReport,
	);
	const userPermissions = allUserPermissionsId?.length
		? allUserPermissionsId.map(id => byUserPermissionIds[id])
		: [];
	const today = new Date();

	const emptyNode: DataSourceType = {
		key: '',
		name: '',
		expenses: [],
		children: [],
	};

	React.useEffect(() => {
		dispatch(
			getMonthlyPettyCashReport({
				where: {
					and: getQueryDateRangeForDays(
						today.toDateString(),
						0,
						155,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
		dispatch(
			getSites({
				where: {
					projectType: {
						inq: [ProjectType.TURNKEY, ProjectType.COLLABORATION],
					},
					status: {
						inq: [SiteStatus.UNDER_DESIGN, SiteStatus.UNDER_CONSTRUCTION],
					},
				},
			}),
		);
		dispatch(
			getUserPermission({
				where: {
					moduleName: ModuleName.EXPENSE_REPORT,
					permission: ModulePermission.APPROVE,
				},
			}),
		);
	}, []);

	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const mapOfRecords = reports.reduce<Record<string, MonthlyPettyCashReport[]>>(
		(record, item) => {
			const date = formatMonthYearFunction(item.reportDate, false);
			if (record[date] === undefined) {
				record[date] = [];
			}

			record[date].push(item);
			return record;
		},
		{},
	);

	const permissionMapOfRecord = userPermissions.reduce<
	Record<number, number[]>
	>((record, item) => {
		if (!record[item.userId]) {
			record[item.userId] = [];
		}

		if (item.siteId && allSites.find(s => s.id === item.siteId)) {
			record[item.userId].push(item.siteId);
		}

		return record;
	}, {});

	const populateDataSourceTree = (node: DataSourceType, user?: User) => {
		if (!user) {
			return node;
		}

		node.key = String(user.id);
		node.expenses = calculateLaisioningExpense(user, mapOfRecords, permissionMapOfRecord);
		node.name = user.name;
		if (user.subordinates?.length) {
			node.children = user.subordinates.map<DataSourceType>(subordinateUser =>
				populateDataSourceTree({...emptyNode}, subordinateUser),
			);
		} else if (permissionMapOfRecord[user.id]?.length) {
			node.children = permissionMapOfRecord[user.id].map(siteId => {
				const expenses: ExpenseType[] = Object.entries(mapOfRecords).map(
					([key, records]) => ({
						date: key,
						expense: records.find(entry => entry.siteId === siteId)?.laisioningExpense ?? 0,
					}),
				);
				const x: DataSourceType = {
					key: String(siteId),
					expenses,
					name: allSites.find(site => site.id === siteId)?.name ?? '',
				};
				return x;
			});
		} else {
			node.children = undefined;
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites
			.filter(({approvalsInchargeId}) => Boolean(approvalsInchargeId))
			.map(({approvalsInchargeId}) => approvalsInchargeId ?? 0),
	);

	const dataSource = roots.map(user =>
		populateDataSourceTree({...emptyNode}, user),
	);
	console.log(dataSource);

	return (
		<DefaultLayout currentPath={uiPaths.monthlyPettyCashReport}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Laisioning Expense Monthly Report</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='mb-10'>
						<Table
							dataSource={dataSource}
							bordered={true}
							pagination={false}
							scroll={{x: 2000, y: 1000}}
						>
							<Table.Column
								title='Name'
								dataIndex='name'
								key='name'
								fixed='left'
								width={240}
							/>
							{[0, 1, 2, 3, 4].map(i => (
								<Table.Column
									title={Object.keys(mapOfRecords)[i]}
									dataIndex='expenses'
									key='expenses'
									render={expenses =>
										// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
										expenses?.length > i ? numberWithCommas(expenses[i].expense) : []
									}
								/>
							))}
						</Table>
					</Col>
				</Row>
				<Row className='margin'>
					<Col span={24}>
						<Typography.Text>
							<b>Report Description:</b> This report presents the total expense approved under the Liasoning Expenses through the approval incharges.
							Data is captured on the last day of every month at 12:00 AM in the night. If there is an increase in the expense amount compared to the previous month, then it
							means additional expense has been added to the liasoning expense in the current month as compared to the last month.
						</Typography.Text>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Typography.Text>
							<b>Report Logic:</b> Based upon the liasoning expense approved by the approval managers in the particular month, total expense amount is calculated for the sites under them.
							To read the report, you can expand the “+” sign in front of the Reporting Manager name to see the list of employees reporting to him and then the site wise liasoning expense approved by them.
                            If you have any query related to this report or a suggestion to improvise the report. Please Contact ruchika.singh@prithu.in.
						</Typography.Text>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
