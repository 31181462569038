import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {Col, Row, Typography} from 'antd';
import {log} from 'app/services/logger-service';
import ViewApprovedExpenseReport from './ViewApprovedExpenseReport';
import {type match as Match} from 'react-router';
import {type History} from 'history';

type MyParams = {
	paidExpenseId?: string;
};

type OwnProps = {
	match: Match<MyParams>;
	history: History;
};

const mapStateToProps = (_: Types.RootState, ownProps: OwnProps) => ({
	...ownProps,
});

const mapDispatchToProps = {
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

class ViewApprovedPaidExpense extends React.Component<Props> {
	public paidExpenseId?: string;

	constructor(props: Props) {
		super(props);
		this.paidExpenseId = props.match?.params?.paidExpenseId;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		log('ViewApprovedPaidExpense.componentDidMount');
	}

	render() {
		return (
			<DefaultLayout currentPath={uiPaths.approvedPaidExpense}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Approve Paid Expense</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<ViewApprovedExpenseReport
							paidExpenseId={this.paidExpenseId}
						/>
					</Col>
				</Row>

				<br />
				<br />
			</DefaultLayout>
		);
	}
}

export default connector(ViewApprovedPaidExpense);

