import {loadState} from 'app/store/helpers';
import {logoutSuccessful} from 'app/components/Common/auth-actions';
import {
	type ModuleName,
	ModulePermission,
	type Site,
	type UserPermission,
	type UserPermissionType,
	type User,
} from 'app/models';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {actionTypes} from './actions';
import {userLoaded} from '../Common/summary-actions';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyState = Readonly<{
	byIds: Record<UserPermission['id'], UserPermission>;
	allIds: Array<UserPermission['id']>;
	byModule: Record<string, Record<string, number[]>>;
	allSites: Site[];
	canApprove: boolean;
	permissions: UserPermissionType[];
	projectDetailPermissions: UserPermissionType[];
	modulesHasPermissions: ModuleName[];
	modulePermissionMap: Record<string, string[]>;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	dataFetched: boolean;
	errorMessage?: string;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	byModule: {},
	allSites: [],
	canApprove: false,
	permissions: [],
	projectDetailPermissions: [],
	modulesHasPermissions: [],
	modulePermissionMap: {},
	filterValue: {},
	loading: false,
	dataUpdated: false,
	dataFetched: false,
	errorMessage: undefined,
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
};

// eslint-disable-next-line complexity
export default function userPermission(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('userPermission', initialState),
	action: any,
): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.resetState:
			return {
				...state,
				...{
					loading: false,
					dataFetched: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: action.payload.currentPos as number,
					perPageSize: action.payload.perPageSize as number,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getUserApi):
		case getRequestActionType(actionTypes.getProjectDetailApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.createAllApi):
		case getRequestActionType(actionTypes.updateApi):
		case getRequestActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: true,
					dataFetched: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getUserApi):
		case getFailureActionType(actionTypes.getProjectDetailApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.createAllApi):
		case getFailureActionType(actionTypes.updateApi):
		case getFailureActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataFetched: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getProjectDetailApi): {
			const projectDetailItems = action.payload
				? (action.payload.data as UserPermission[])
				: (action.data as UserPermission[]);
			const projectDetailPermissions = projectDetailItems.reduce<
			MyState['projectDetailPermissions']
			>((permissions, item) => {
				item.permission?.split(',').forEach(p => {
					permissions.push({
						moduleName: item.moduleName,
						siteId: item.siteId,
						permission: p as ModulePermission,
						condition: item.condition,
						userId: item.userId,
					} as UserPermissionType);
				});
				return permissions;
			}, []);
			return {
				...state,
				projectDetailPermissions,
			};
		}

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: action.payload.data.count as number,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};

		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as UserPermission[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, item) => {
						byIds[item.id] = item;
						return byIds;
					}, {}),
					loading: false,
					dataFetched: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(userLoaded): {
			const user = action.payload.data as User;
			const {userPermissions: userItems = [], allSites = []} = user;
			return {
				...state,
				...{
					modulePermissionMap: userItems.reduce<MyState['modulePermissionMap']>(
						(record, item) => {
							if (!record[item.moduleName]) {
								record[item.moduleName] = [];
							}

							if (
								item.permission
                && !record[item.moduleName].includes(item.permission)
							) {
								record[item.moduleName].push(item.permission);
							}

							return record;
						},
						{},
					),
					byModule: userItems.reduce<MyState['byModule']>((record, item) => {
						if (item.moduleName && item.siteId && item.permission) {
							if (!record[item.moduleName]) {
								record[item.moduleName] = {};
							}

							if (!record[item.moduleName][item.permission]) {
								record[item.moduleName][item.permission] = [];
							}

							if (
								item.siteId
                && !record[item.moduleName][item.permission].find(siteId => siteId === item.siteId)
							) {
								record[item.moduleName][item.permission].push(item.siteId);
							}
						}

						return record;
					}, {}),
					modulesHasPermissions: userItems.reduce<
					MyState['modulesHasPermissions']
					>((list, item) => {
						if (item.moduleName) {
							const {moduleName} = item;
							if (!list.includes(moduleName)) {
								list.push(moduleName);
							}
						}

						return list;
					}, []),
					allSites,
					canApprove: Boolean(
						userItems.find(
							item => item.permission === (ModulePermission.APPROVE as string),
						),
					),
					loading: false,
					dataFetched: true,
					dataUpdated: !state.dataUpdated,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.createAllApi):
		case getSuccessActionType(actionTypes.updateApi):
		case getSuccessActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: false,
					dataFetched: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
}
