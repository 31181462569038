import React, {useState, useContext, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useHistory} from 'react-router';
import type Types from 'MyTypes';
import {parseNum, toTitleCase} from 'app/helpers';
import {useLocation} from 'react-router';
import {
	ModuleName,
	ModulePermission,
} from 'app/models';
import {hasPermission} from 'app/helpers';
import {
	Col,
	Row,
	Spin,
	Table,
	Typography,
	Button,
	message,
	Form,
	InputNumber,
	Modal,
} from 'antd';
import {saveAll} from './actions';
import type {FormInstance} from 'antd/es/form';

type Item = {
	key: string;
	labourType: string;
	requiredCount?: number;
	actualCount?: number;
};

type EditableRowProps = {
	index: number;
};

type EditableCellProps = {
	title: React.ReactNode;
	editable: boolean;
	children: React.ReactNode;
	dataIndex: keyof Item;
	record: Item;
	handleSave: (record: Item) => void;
};

type EditableTableProps = Parameters<typeof Table>[0];

type DataType = {
	key: React.Key;
	labourType?: string;
	requiredCount?: number;
	actualCount?: number;
};

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const EditableContext = React.createContext<FormInstance | undefined>(undefined);

const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const EditableCell: React.FC<EditableCellProps> = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef<any>(null);
	const form = useContext(EditableContext)!;

	useEffect(() => {
		if (editing) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			inputRef.current.focus();
		}
	}, [editing]);

	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({[dataIndex]: record[dataIndex]});
	};

	const save = async () => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const values = await form.validateFields();

			toggleEdit();
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			handleSave({...record, ...values});
		} catch (errInfo) {
			console.log('Save failed:', errInfo);
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item style={{margin: 0}} name={dataIndex} rules={[{required: true, message: 'Field is mandatory'}]}>
				<InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
			</Form.Item>
		) : (
			<div
				className='editable-cell-value-wrap'
				style={{paddingRight: 24}}
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};

export const ViewLabourAttendanceForm: React.FC = () => {
	const location = useLocation();
	const propsData: any = location.state;
	const history = useHistory();

	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	const [dataSource, setDataSource] = useState<DataType[]>(propsData ?? []);
	const [showModal, setShowModal] = React.useState<boolean>(false);

	const dispatch = useDispatch();
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);

	const {loading, filterValue, errorMessage, dataUpdated} = useSelector(
		(state: Types.RootState) => state.labourAttendance,
	);

	const canWrite = hasPermission(
		permissions[ModuleName.LABOUR_ATTENDANCE],
		ModulePermission.WRITE,
	);

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated successfully');
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const defaultColumns: Array<ColumnTypes[number] & {
		editable?: boolean;
		dataIndex: string;
	}> = [
		{
			title: 'Labour Type',
			dataIndex: 'labourType',
			width: '30%',
			render: labourType => toTitleCase(labourType as string),
		},
		{
			title: 'Required Count',
			dataIndex: 'requiredCount',
			editable:
        canWrite
        && filterValue.date === new Date().toISOString().substring(0, 10),
		},
		{
			title: 'Actual Count',
			dataIndex: 'actualCount',
			editable:
        canWrite
        && filterValue.date === new Date().toISOString().substring(0, 10),
		},
	];

	const handleSaveAll = () => {
		const item = dataSource.find(entry => entry.actualCount && entry?.actualCount > 0 && entry?.requiredCount === 0);
		if (item) {
			setShowModal(true);
			return;
		}

		dispatch(saveAll(dataSource, parseNum(filterValue.siteId as string)));
	};

	const handleSave = (row: DataType) => {
		const newData = [...dataSource];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setDataSource(newData);
	};

	const columns = defaultColumns.map(col => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record: DataType) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	return (
		<DefaultLayout currentPath={uiPaths.labourAttendanceForm}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3}>Labour Attendance</Typography.Title>
					</Col>
				</Row>
				{dataSource.length > 0 && (
					<div>
						{canWrite && (
							<Row>
								<Col span={12}>
									<Button onClick={() => {
										history.goBack();
									}}>Back</Button>
								</Col>
								<Col
									span={12}
									style={{textAlign: 'right', marginBottom: '10px'}}
								>
									<Button
										onClick={handleSaveAll}
										type='primary'
										style={{marginBottom: 16}}
									>
                    Save All
									</Button>
								</Col>
							</Row>
						)}
						<Table
							components={components}
							rowClassName={() => 'editable-row'}
							bordered
							dataSource={dataSource}
							columns={columns as ColumnTypes}
							pagination={false}
						/>
					</div>
				)}
				<Modal
					title='You have entered the required labour count of 0 for a few activities. Are you sure the required count is 0 and would like to save it?

					आपने कुछ गतिविधियों के लिए आवश्यक श्रम गणना 0 दर्ज की है। क्या आप निश्चित हैं कि आवश्यक गिनती 0 है और आप इसे सहेजना चाहेंगे?'
					open={showModal}
					okText={'Save all'}
					onOk={() => {
						dispatch(saveAll(dataSource, parseNum(filterValue.siteId as string)));
						setShowModal(false);
					}}
					onCancel={() => {
						setShowModal(false);
					}}
				></Modal>
			</Spin>
		</DefaultLayout>
	);
};
