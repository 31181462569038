import type * as actions from './actions';
import {actionTypes} from './actions';
import {type WorkOrderSchedule} from 'app/models';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {logoutSuccessful} from '../Common/auth-actions';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<WorkOrderSchedule['id'], WorkOrderSchedule>;
	allIds: Array<WorkOrderSchedule['id']>;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

const workOrderScheduleReducer = (
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('workOrderSchedule', initialState),
	action: any = {},
) => {
	switch (String(action.type)) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {
				...state,
				filterValue: action.payload as FilterDataType,
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.getSingleApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					sheetData: undefined,
					sheetDates: undefined,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.getSingleApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					sheetData: undefined,
					sheetDates: undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as WorkOrderSchedule[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.getSingleApi): {
			const newItem = action.payload.data as WorkOrderSchedule;
			const newAllIds = state.allIds?.length ? [...state.allIds] : [];
			if (!newAllIds.includes(newItem.id)) {
				newAllIds.push(newItem.id);
			}

			const newByIds = state.byIds ? {...state.byIds} : {};
			newByIds[newItem.id] = newItem;
			return {
				...state,
				...{
					byIds: newByIds,
					allIds: newAllIds,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default workOrderScheduleReducer;
