import React from 'react';
import {connect, type ConnectedProps} from 'react-redux';
import {formatDateFunction, getCurrencyString, getS3Url, parseNum, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';
import {Card, Col, Empty, Row, Space, Spin, Table} from 'antd';
import {type ExpenseReport, ExpenseStatus, type Site, type Attachment, type User} from 'app/models';
import {FilterFormType, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {log} from 'app/services/logger-service';
import {PaperClipOutlined} from '@ant-design/icons';
import {getQueryDateRange} from 'app/query_helpers';
import {CSVLink} from 'react-csv';
import {UserRole} from 'app/models/enums/user-role.enum';
import {count, get, onApprovedPaginationChange, setApprovedFilterValue} from './actions';
import {PaginationView} from '../Common/Pagination/PaginationView';
import moment from 'moment';

type OwnProps = {
	paidExpenseId?: string;
};

const mapStateToProps = ({
	expenseReport,
	summary: {userOptions},
	userPermission: {allSites: userSites},
}: Types.RootState, ownProps: OwnProps) => ({
	...expenseReport,
	userSites,
	userOptions,
	...ownProps,
});

const mapDispatchToProps = {
	get,
	count,
	onApprovedPaginationChange,
	setApprovedFilterValue,
};

const connector = connect(
	mapStateToProps,
	mapDispatchToProps,
);

type Props = ConnectedProps<typeof connector>;

type State = {
	csvData: any[];
};

class ViewApprovedExpenseReport extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			csvData: [],
		};
	}

	componentDidMount() {
		log('ViewApprovedExpenseReport.componentDidMount');
		if (this.props.approvedFilterValue && !this.props.paidExpenseId) {
			this.filterData(this.props.approvedFilterValue);
		} else {
			this.filterData();
		}
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Props, nextContext: any) {
		if (nextProps.dataUpdated) {
			if (this.props.approvedFilterValue && !this.props.paidExpenseId) {
				this.filterData(this.props.approvedFilterValue, this.props.approvedCurrentPos, this.props.approvedPerPageSize);
			} else {
				this.filterData();
			}
		}
	}

	getCsvData = (reports: ExpenseReport[]) => ([
		['Site Name', 'N/A'],
		['User Name', 'N/A'],
		[
			'Date',
			'Time',
			'S No',
			'Employee Number',
			'Amount received',
			'Description',
			'Food and Beverage',
			'Site Loading-unloading',
			'Site Material',
			'Site Stationery',
			'Conveyance',
			'Labour',
			'Water and Electricity Bill',
			'Laisioning Expense',
			'Malwa Removal',
			'Scrap Amount',
			'Solar and Water Automation',
			'Other',
			'User',
			'Site',
		],
		...reports.map(report => ([
			formatDateFunction(report.createdAt, false),
			new Date(report.createdAt).toLocaleTimeString(),
			report.sno ? report.sno : 'N/A',
			report.createdUser ? report.createdUser.empNo : '',
			report.amountReceived,
			report.expenseDescription,
			report.foodBeverageExpense,
			report.siteLoadingUnloadingExpense,
			report.siteMaterialExpense,
			report.siteStationeryExpense,
			report.conveyance,
			report.labourExpense,
			report.waterAndElectricityBill,
			report.laisioningExpense,
			report.malwaRemoval,
			report.scrapAmount,
			report.solarAndWaterAutomation,
			report.otherExpense,
			report.createdUser ? report.createdUser.name : 'N/A',
			report.site ? report.site.name : 'N/A',
		])),
	]);

	filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {
				status: ExpenseStatus.APPROVED,
			},
			limit: perPageSize,
			skip: (currentPos ? perPageSize * (currentPos - 1) : 0),
			order: [
				'updatedAt DESC',
			],
			include: [
				{relation: 'attachments'},
			],
		};

		if (this.props.paidExpenseId) {
			filter.where.paidExpenseId = this.props.paidExpenseId;
		} else {
			filter.where.status = ExpenseStatus.APPROVED;

			if (f.siteId) {
				filter.where.siteId = parseNum(String(f.siteId));
			} else {
				filter.where.siteId = {inq: this.props.userSites.map((site: Site) => site.id)};
			}

			if (f.userId) {
				filter.where.createdBy = f.userId;
			}

			if (f.date1 && f.date2) {
				if (!filter.where.and) {
					filter.where.and = [];
				}

				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				filter.where.and.push(...getQueryDateRange(f.date1 as string, f.date2 as string, 'updatedAt'));
			}
		}

		this.props.setApprovedFilterValue(f);

		if (perPageSize && currentPos) {
			this.props.get(filter);
		} else {
			this.props.count(filter.where);
		}
	};

	render() {
		const {
			approvedFilterValue,
			userOptions,
			userSites,
			loading,
			totalCount,
			approvedCurrentPos,
			approvedPerPageSize,
			byIds,
			allIds,
		} = this.props;

		const siteOptions = userSites.map((site: Site) => ({value: site.id.toString(), label: site.name}));

		// Populating approver as construction head if approver is not defined
		const reports = allIds?.length ? allIds.map((id: number) => {
			const report = byIds[id];
			report.approver = report.approver ?? UserRole.CONSTRUCTION_HEAD;
			return report;
		}) : [];

		const uiFilters: UiFilter[] = [
			{
				filterKey: 'siteId',
				items: siteOptions,
				formType: FilterFormType.SITE_SELECT,
				formWidth: 240,
				placeholder: 'Select Site',
				label: 'Site',
				defaultValue: approvedFilterValue?.siteId,
			},
			{
				filterKey: 'userId',
				items: userOptions,
				formType: FilterFormType.SELECT,
				formWidth: 240,
				placeholder: 'Select User',
				label: 'User',
				defaultValue: approvedFilterValue?.userId,
			},
			{
				filterKey: 'date',
				items: [],
				formType: FilterFormType.DATE_RANGE,
				label: 'Approved Date',
				defaultValue: [approvedFilterValue?.date1 as string, approvedFilterValue?.date2 as string],
			},
		];

		const columns = [
			{
				title: 'Serial No.', dataIndex: 'serialNo', key: 'serialNo', render: (sno: string, record: ExpenseReport) => (
					<Space>
						{sno}
						{record.paidExpense?.status ? toTitleCase(record.paidExpense?.status) : '*'}
						{/* <Input value={JSON.stringify(record)}/> */}
					</Space>
				),
			},
			{title: 'Employee No', dataIndex: 'createdUser', key: 'createdUser', render: (createdUser: User, record: ExpenseReport) => (
				<p>{ createdUser.empNo ? createdUser.empNo : ''} </p>
			)},
			{title: 'Approved at', dataIndex: 'approvedDate', key: 'approvedDate'},
			{title: 'Expense', dataIndex: 'expenseDescription', key: 'expenseDescription'},
			{
				title: 'Attachments', dataIndex: 'attachments', key: 'attachments', render: (attachments: Attachment[]) => (
					<Space>
						{attachments?.length
							? attachments.map((attachment: Attachment, ixx: number) => (
								<a key={ixx} href={getS3Url(attachment.key ?? '')} title={attachment.name} target='_blank' rel='noreferrer'>
									<PaperClipOutlined />
								</a>
							))
							: undefined}
					</Space>
				),
			},
			{
				title: 'Expense Amount', width: 220, dataIndex: 'amount', key: 'amount', render: (text: string, record: any) => (
					<p>
						{record.foodBeverageExpense ? `Food and Beverage: ${getCurrencyString(record.foodBeverageExpense * -1)}` : ''}
						{record.siteLoadingUnloadingExpense ? `Site Loading-unloading: ${getCurrencyString(record.siteLoadingUnloadingExpense * -1)}` : ''}
						{record.siteMaterialExpense ? `Site Material: ${getCurrencyString(record.siteMaterialExpense * -1)}` : ''}
						{record.siteStationeryExpense ? `Site Stationery: ${getCurrencyString(record.siteStationeryExpense * -1)}` : ''}
						{record.conveyance ? `Conveyance: ${getCurrencyString(record.conveyance * -1)}` : ''}
						{record.labourExpense ? `Labour: ${getCurrencyString(record.labourExpense * -1)}` : ''}
						{record.waterAndElectricityBill ? `Water and Electricity Bill: ${getCurrencyString(record.waterAndElectricityBill * -1)}` : ''}
						{record.laisioningExpense ? `Laisioning Expense: ${getCurrencyString(record.laisioningExpense * -1)}` : ''}
						{record.malwaRemoval ? `Malwa Removal: ${getCurrencyString(record.malwaRemoval * -1)}` : ''}
						{record.scrapAmount ? `Scrap Amount: ${getCurrencyString(record.scrapAmount * -1)}` : ''}
						{record.solarAndWaterAutomation ? `Solar and Water Automation: ${getCurrencyString(record.solarAndWaterAutomation * -1)}` : ''}
						{record.otherExpense ? `Other: ${getCurrencyString(record.otherExpense * -1)}` : ''}
						{record.amountReceived ? `Amount received: ${getCurrencyString(Number(record.amountReceived))}` : ''}
					</p>
				),
			},
			{title: 'Raised At', dataIndex: 'date', key: 'date'},
			{title: 'Created by', dataIndex: 'createdByName', key: 'createdByName'},
			{title: 'Approved by', dataIndex: 'approvedByName', key: 'approvedByName'},
			{title: 'Site', dataIndex: 'siteName', key: 'siteName'},
		];

		const dataSource: ExpenseReport[] = reports ? reports.map((report: ExpenseReport) => ({
			...report,
			date: moment(report.createdAt).format('D MMMM YYYY, h:mm:ss a'),
			serialNo: report.sno ? report.sno : 'N/A',
			createdByName: report.createdUser ? report.createdUser.name : 'N/A',
			approvedByName: report.updatedUser ? report.updatedUser.name : 'N/A',
			approvedDate: moment(report.updatedAt).format('D MMMM YYYY, h:mm:ss a'),
			siteName: report.site ? report.site.name : 'N/A',
		})) : [];

		const csvData: any[] = this.getCsvData(reports);

		return (
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				{this.props.paidExpenseId ? [] : (
					<Row>
						<Col span={24}>
							<FilterView
								uiFilters={uiFilters}
								onSubmit={f => {
									this.filterData(f);
								}}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<br />
						<Space>
							<CSVLink
								data={csvData}
								filename={'Approve_Expense_Report.csv'}
								className='btn btn-primary'
							>
								Download
							</CSVLink>
						</Space>
					</Col>
				</Row>
				<br />

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								total={totalCount}
								pageSizeOptions={['10', '50', '100', '500', '1000', '5000']}
								currentPos={approvedCurrentPos ?? 1}
								perPageSize={approvedPerPageSize ?? 10}
								filterValue={approvedFilterValue}
								filterData={this.filterData}
								onPaginationChange={this.props.onApprovedPaginationChange}
							/>
							<Table
								size={'small'}
								pagination={false}
								dataSource={dataSource}
								columns={columns}
								scroll={{x: 1300, y: 500}}
								footer={pageData => (
									<b>
										Total: {getCurrencyString(pageData.reduce((val, record) => {
											val += (record.foodBeverageExpense ?? 0)
											+ (record.siteLoadingUnloadingExpense ?? 0)
											+ (record.siteMaterialExpense ?? 0)
											+ (record.siteStationeryExpense ?? 0)
											+ (record.conveyance ?? 0)
											+ (record.labourExpense ?? 0)
											+ (record.waterAndElectricityBill ?? 0)
											+ (record.laisioningExpense ?? 0)
											+ (record.malwaRemoval ?? 0)
											+ (record.scrapAmount ?? 0)
											+ (record.solarAndWaterAutomation ?? 0)
											+ (record.otherExpense ?? 0)
											+ (record.amountReceived ?? 0);
											return val;
										}, 0), true)}
									</b>
								)}
							/>
						</Col>
						<br /><br />
						<Col span={24}>
							<Card size={'small'}>
								<p>* Expense is not added in Paid Expense</p>
							</Card>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		);
	}
}

export default connector(ViewApprovedExpenseReport);

