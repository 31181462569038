import React from 'react';
import {Card, Space, Tag} from 'antd';
import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {Link} from 'react-router-dom';
import {uiPaths} from 'app/constants';
import {type ProcessChecklistResponse, ProcessChecklistResponseStatus} from 'app/models/process-checklist-response.model';
import {type ProjectProcessType} from '../ProjectProcessMaster/project-process-master';
import {useSelector} from 'react-redux';
import {UserType} from 'app/models';
import {toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';

type Pops = {
	checklist: ProcessChecklist;
	checklistResponse?: ProcessChecklistResponse;
	scheduleId?: number;
	drawingScheduleId?: number;
	siteId: number;
	projectProcessType: ProjectProcessType;
};

export const ProcessChecklistBlock: React.FC<Pops> = ({checklist, checklistResponse, scheduleId, drawingScheduleId, siteId, projectProcessType}) => {
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);

	let statusLabel = 'Not Yet Filled';
	if (checklistResponse?.status === ProcessChecklistResponseStatus.IN_PROGRESS) {
		statusLabel = 'In Progress';
	} else if (checklistResponse?.status === ProcessChecklistResponseStatus.SUBMITTED) {
		statusLabel = 'Submitted For Inspection';
	} else if (checklistResponse?.status === ProcessChecklistResponseStatus.APPROVED) {
		statusLabel = 'Inspection Approved';
	} else if (checklistResponse?.status === ProcessChecklistResponseStatus.REJECTED) {
		statusLabel = 'Inspection Rejected';
	}

	return (
		<Card
			size='small'
			title={(
				<Link
					to={{
						pathname: `/${uiPaths.viewProcessChecklistResponseDetail
							.replace(':scheduleId', String(scheduleId ?? '0'))
							.replace(':drawingScheduleId', String(drawingScheduleId ?? '0'))
							.replace(':checklistId', checklist.id ?? '0')
							.replace(':siteId', String(siteId ?? '0'))
							.replace(':processType', String(projectProcessType ?? ''))}`,
					}}
				>
					{checklist.title}
				</Link>
			)}
			style={{width: '100%', marginBottom: 8}}
		>
			<Space direction={'vertical'}>
				{authUser?.userType === UserType.CLIENT ? [] : (
					<>
						<Tag>{statusLabel}</Tag>
						<Tag>{toTitleCase(checklist.onActionType)}</Tag>
					</>
				)}
				{checklist.description ? <span>{checklist.description}</span> : []}
			</Space>
		</Card>
	);
};
