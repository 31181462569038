import axios, {
	type AxiosResponse,
	type InternalAxiosRequestConfig,
} from 'axios';
import {parseNum} from './helpers';

axios.defaults.timeout = 5 * 60 * 1000;

const setupAxiosInterceptors = (onUnauthenticated: () => void) => {
	const onRequestSuccess = (config: InternalAxiosRequestConfig) => config;
	const onResponseSuccess = (response: AxiosResponse) => {
		const version = response.headers['x-prithu-v'] as string;
		const versionName = response.headers['x-prithu-vn'] as string;
		const versionChange = response.headers['x-prithu-vct'] as string;
		const prevVersion = localStorage.getItem('appVersionNumber') ?? undefined;
		localStorage.setItem('appVersionNumber', version);
		localStorage.setItem('appVersionName', versionName);
		if (!prevVersion || parseNum(prevVersion) < parseInt(version, 10)) {
			if (versionChange === 'major') {
				localStorage.removeItem('appToken');
				localStorage.removeItem('appState');
			}

			window.location.reload();
		}

		return response;
	};

	const onResponseError = async (err: any) => {
		let status = 0;
		if (err.status) {
			status = parseNum(err.status as string);
		} else if (err.response?.status) {
			status = parseNum(err.response.status as string);
		}

		if (status === 403 || status === 401) {
			onUnauthenticated();
			localStorage.removeItem('appToken');
			localStorage.removeItem('appState');
			window.location.reload();
		}

		return Promise.reject(err);
	};

	axios.interceptors.request.use(onRequestSuccess);
	axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
