import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {Avatar, Button, Col, Row, Spin, Typography} from 'antd';
import type Types from 'MyTypes';
import {UserOutlined} from '@ant-design/icons';
import {equalNum, parseNum} from 'app/helpers';
import {getById} from './actions';
import {useHistory, useParams} from 'react-router';
import {ViewUserInfo} from './ViewUserInfo';

type ParamType = {
	userId?: string;
};

export const ViewUserDetail: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<ParamType>();
	const userId = parseNum(params?.userId ?? '0');

	const {byIds, loading} = useSelector((state: Types.RootState) => state.user);
	const user = userId ? byIds[userId] : undefined;

	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);

	React.useEffect(() => {
		if (userId) {
			dispatch(getById(userId));
		} else {
			dispatch(getById(authUser?.id ?? 0));
		}
	}, [userId, authUser]);

	return (
		<DefaultLayout currentPath={uiPaths.userDetail}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				{userId ? <Button onClick={e => {
					history.goBack();
				}}>Back</Button> : []}
				<Row><Col><br /><br /></Col></Row>

				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Avatar size={128} icon={<UserOutlined />} />
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							{user?.name ?? ''}
						</Typography.Title>
					</Col>
				</Row>

				<br />
				<br />

				<ViewUserInfo
					user={user}
					canEdit={equalNum(userId, authUser?.id)}
				/>
				<br /><br />
			</Spin>
		</DefaultLayout>
	);
};
