import * as React from 'react';
import {Button, message, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {type UploadFile} from '../../models/upload-file';
import {getUniqueId} from '../../helpers';
import {lbBaseUrl} from '../../constants';
import {getAuthToken} from '../../services/common-service';

type FileUploadProps = {
	label?: string;
	single?: boolean;
	prefix: string;
	fileList?: UploadFile[];
	filePrefixKeys?: Record<string, string>;
	onFileChange: (fileList: UploadFile[], filePrefixKeys: Record<string, string>) => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
	label,
	single,
	prefix,
	fileList,
	filePrefixKeys,
	onFileChange,
}) => {
	const [uniqueKey, setUniqueKey] = React.useState<string>(getUniqueId());

	return (
		<Upload
			name={'file'}
			action={`${lbBaseUrl}/aws-s3/pre-upload?key=${prefix}/${uniqueKey}`}
			headers={{Authorization: `Bearer ${getAuthToken()}`}}
			onChange={(info: any) => {
				if (!filePrefixKeys) {
					filePrefixKeys = {};
				}

				if (!filePrefixKeys[info.file.uid]) {
					filePrefixKeys[info.file.uid] = `${prefix}/${uniqueKey}`;
					setUniqueKey(getUniqueId());
				}

				onFileChange([...info.fileList as UploadFile[]], filePrefixKeys);
				if (info.file.status === 'done') {
					void message.success(`${info.file.name} file uploaded successfully`);
				} else if (info.file.status === 'error') {
					void message.error(`${info.file.name} file upload failed.`);
				}
			}}
			multiple={!single}
			fileList={fileList}
		>
			<Button icon={<UploadOutlined />}>{label ?? 'Click to Upload'}</Button>
		</Upload>
	);
};
