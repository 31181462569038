import React from 'react';
import {uiPaths} from 'app/constants';
import {get} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {type MaterialOrderBudget} from 'app/models/material-order-budget.model';
import {ModuleName, ModulePermission, type Site} from 'app/models';
import {Col, Row, Spin, Table, Button, Space} from 'antd';
import type Types from 'MyTypes';
import {Link} from 'react-router-dom';
import {isMobile, getPermissionSites} from 'app/helpers';

type Props = {
	toBeApproved?: boolean;
};

export const ViewAllProjectBudgetQuantities: React.FC<Props> = ({
	toBeApproved,
}) => {
	const dispatch = useDispatch();
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.materialOrderBudget,
	);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const dataSource: MaterialOrderBudget[] = allIds
		.map(id => byIds[id])
		.filter(item => item.parentId);

	const sitesWithWritePermission: Site[] = getPermissionSites(
		permissions,
		ModuleName.PROJECT_HANDOVER_SCHEDULE,
		ModulePermission.WRITE,
		allSites,
	);

	React.useEffect(() => {
		if (toBeApproved) {
			dispatch(
				get({
					where: {
						nextApprovedBy: authUser?.id,
					},
				}),
			);
		} else {
			dispatch(get({}));
		}
	}, []);

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<Table
						size={'small'}
						bordered={true}
						dataSource={dataSource}
						pagination={false}
						scroll={{x: 800, y: 500}}
					>
						<Table.Column
							title='Work Type'
							dataIndex='title'
							key='title'
							render={(title: string, item: MaterialOrderBudget) =>
								toBeApproved ? (
									<Link
										to={{
											pathname: `/${uiPaths.projectBudgetQuantityForm.replace(
												':id',
												String(item.id),
											)}`,
										}}
									>
										{title}
									</Link>
								) : (
									title
								)
							}
						/>

						<Table.Column
							title='Measuring Unit'
							dataIndex='measuringUnit'
							key='measuringUnit'
						/>

						<Table.Column
							title='Project Quantity'
							dataIndex='budgetQuantity'
							key='budgetQuantity'
						/>

						<Table.Column
							title='Reference Drawing Link'
							dataIndex='refDrawingLink'
							key='refDrawingLink'
							render={(refDrawingLink: string) => (
								<Link to={refDrawingLink ?? ''}>{refDrawingLink ?? ''}</Link>
							)}
						/>

						<Table.Column
							title='Remarks'
							dataIndex='remarks'
							key='remarks'
						/>

						{sitesWithWritePermission.length && !toBeApproved ? (
							<Table.Column
								title='Action'
								dataIndex='action'
								key='action'
								render={(action: string, item: MaterialOrderBudget) => (
									<Space direction={isMobile ? 'vertical' : 'horizontal'}>
										<Link
											to={{
												pathname: `/${uiPaths.projectBudgetQuantityForm.replace(
													':id',
													String(item.id),
												)}`,
											}}
										>
											<Button size={'small'} type='primary'>
                        Edit
											</Button>
										</Link>
									</Space>
								)}
							/>
						) : (
							[]
						)}
					</Table>
				</Col>
			</Row>
		</Spin>
	);
};
