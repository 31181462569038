import React from 'react';
import {Col, Pagination, Row} from 'antd';
import {numberWithCommas} from 'app/helpers';
import {useDispatch} from 'react-redux';
import {type FilterDataType} from '../Filter/FilterView';

type Props = {
	total: number;
	currentPos: number;
	perPageSize: number;
	pageSizeOptions?: string[];
	filterValue: FilterDataType;
	isFunctional?: boolean;
	filterData: (filterValue: any, currentPos: number, perPageSize: number) => void;
	onPaginationChange: (currentPos: number, perPageSize: number) => any;
};

export const PaginationView: React.FC<Props> = ({
	total,
	currentPos,
	perPageSize,
	pageSizeOptions,
	filterValue,
	isFunctional,
	filterData,
	onPaginationChange,
}) => {
	const dispatch = useDispatch();
	React.useEffect(() => {
		filterData(filterValue, currentPos, perPageSize);
	}, [filterValue, currentPos, perPageSize]);

	const onShowSizeChange = (current: number, pageSize: number) => {
		if (isFunctional) {
			dispatch(onPaginationChange(current, pageSize));
		} else {
			onPaginationChange(current, pageSize);
		}
	};

	const onChange = (pageNumber: number, pageSize?: number) => {
		if (isFunctional) {
			dispatch(onPaginationChange(pageNumber, pageSize ?? perPageSize));
		//	Dispatch(filterData(filterValue, pageNumber, pageSize ?? perPageSize));
		} else {
			onPaginationChange(pageNumber, pageSize ?? perPageSize);
		}

		filterData(filterValue, pageNumber, pageSize ?? perPageSize);
	};

	return (
		<Row>
			<Col span={24} style={{textAlign: 'right'}}>
				<Pagination
					showSizeChanger={true}
					showQuickJumper={true}
					onShowSizeChange={onShowSizeChange}
					onChange={onChange}
					defaultCurrent={currentPos}
					defaultPageSize={perPageSize}
					pageSizeOptions={pageSizeOptions}
					total={total}
					showTotal={(totalCount, range) =>
						`${numberWithCommas(range[0])}-${numberWithCommas(range[1])} of ${numberWithCommas(totalCount)} items`}
				/>
				<br/>
			</Col>
		</Row>
	);
};
