import {loadState} from 'app/store/helpers';
import {logoutSuccessful} from 'app/components/Common/auth-actions';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {actionTypes} from './actions';
import {type FilterDataType} from '../Common/Filter/FilterView';
import {type WarrantyPeriod} from './warranty-period';

export type MyState = Readonly<{
	byIds: Record<WarrantyPeriod['id'], WarrantyPeriod>;
	allIds: Array<WarrantyPeriod['id']>;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	dataFetched: boolean;
	errorMessage?: string;
	totalCount: number;
	currentPos: number;
	perPageSize: number;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	filterValue: {},
	loading: false,
	dataUpdated: false,
	dataFetched: false,
	errorMessage: undefined,
	totalCount: 0,
	currentPos: 1,
	perPageSize: 10,
};

// eslint-disable-next-line complexity
export default function warrantyPeriod(
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('warrantyPeriod', initialState),
	action: any,
): MyState {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.resetState:
			return {
				...state,
				...{
					loading: false,
					dataFetched: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.paginationChange:
			return {
				...state,
				...{
					currentPos: action.payload.currentPos as number,
					perPageSize: action.payload.perPageSize as number,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.createApi):
		case getRequestActionType(actionTypes.createAllApi):
		case getRequestActionType(actionTypes.updateApi):
		case getRequestActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: true,
					dataFetched: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createApi):
		case getFailureActionType(actionTypes.createAllApi):
		case getFailureActionType(actionTypes.updateApi):
		case getFailureActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataFetched: false,
					dataUpdated: false,
				},
			};

		case getSuccessActionType(actionTypes.getCountApi):
			return {
				...state,
				...{
					totalCount: action.payload.data.count as number,
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};

		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as WarrantyPeriod[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, item) => {
						byIds[item.id] = item;
						return byIds;
					}, {}),
					loading: false,
					dataFetched: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		}

		case getSuccessActionType(actionTypes.createApi):
		case getSuccessActionType(actionTypes.createAllApi):
		case getSuccessActionType(actionTypes.updateApi):
		case getSuccessActionType(actionTypes.removeApi):
			return {
				...state,
				...{
					loading: false,
					dataFetched: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
}
