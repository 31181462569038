import React from 'react';
import './App.less';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {HomeRoute} from './routes/HomeRoute';
import store, {history} from './store';
import setupAxiosInterceptors from './axios-interceptor';

setupAxiosInterceptors(() => {
	console.log('login.error.unauthorized');
});

export const App: React.FC = () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<HomeRoute />
		</ConnectedRouter>
	</Provider>
);
