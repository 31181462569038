// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*!
Pure v2.0.5
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/pure-css/pure/blob/master/LICENSE
*/
.pure-table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
}
.pure-table caption {
  color: #000;
  font: italic 85%/1 arial, sans-serif;
  padding: 1em 0;
  text-align: center;
}
.pure-table td,
.pure-table th {
  border-left: 1px solid #cbcbcb;
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible;
  padding: 0.5em 1em;
}
.pure-table thead {
  background-color: #e0e0e0;
  color: #000;
  text-align: left;
  vertical-align: bottom;
}
.pure-table td {
  background-color: transparent;
}
.pure-table-odd td {
  background-color: #f2f2f2;
}
.pure-table-striped tr:nth-child(2n-1) td {
  background-color: #f2f2f2;
}
.pure-table-bordered td {
  border-bottom: 1px solid #cbcbcb;
}
.pure-table-bordered tbody > tr:last-child > td {
  border-bottom-width: 0;
}
.pure-table-horizontal td,
.pure-table-horizontal th {
  border-width: 0 0 1px 0;
  border-bottom: 1px solid #cbcbcb;
}
.pure-table-horizontal tbody > tr:last-child > td {
  border-bottom-width: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pure-tables.less"],"names":[],"mappings":"AAAA;;;;;CAKC;AACD;EAAY,yBAAA;EAAyB,iBAAA;EAAiB,iBAAA;EAAiB,yBAAA;AAKvE;AALgG;EAAoB,WAAA;EAAW,oCAAA;EAAmC,cAAA;EAAc,kBAAA;AAWhL;AAXkM;;EAA8B,8BAAA;EAA8B,uBAAA;EAAuB,kBAAA;EAAkB,SAAA;EAAS,iBAAA;EAAiB,kBAAA;AAoBjU;AApBkV;EAAkB,yBAAA;EAAyB,WAAA;EAAW,gBAAA;EAAgB,sBAAA;AA0BxZ;AA1B8a;EAAe,6BAAA;AA6B7b;AA7B0d;EAAmB,yBAAA;AAgC7e;AAhCsgB;EAA0C,yBAAA;AAmChjB;AAnCykB;EAAwB,gCAAA;AAsCjmB;AAtCioB;EAA4C,sBAAA;AAyC7qB;AAzCmsB;;EAAoD,uBAAA;EAAuB,gCAAA;AA8C9wB;AA9C8yB;EAA8C,sBAAA;AAiD51B","sourcesContent":["/*!\nPure v2.0.5\nCopyright 2013 Yahoo!\nLicensed under the BSD License.\nhttps://github.com/pure-css/pure/blob/master/LICENSE\n*/\n.pure-table{border-collapse:collapse;border-spacing:0;empty-cells:show;border:1px solid #cbcbcb}.pure-table caption{color:#000;font:italic 85%/1 arial,sans-serif;padding:1em 0;text-align:center}.pure-table td,.pure-table th{border-left:1px solid #cbcbcb;border-width:0 0 0 1px;font-size:inherit;margin:0;overflow:visible;padding:.5em 1em}.pure-table thead{background-color:#e0e0e0;color:#000;text-align:left;vertical-align:bottom}.pure-table td{background-color:transparent}.pure-table-odd td{background-color:#f2f2f2}.pure-table-striped tr:nth-child(2n-1) td{background-color:#f2f2f2}.pure-table-bordered td{border-bottom:1px solid #cbcbcb}.pure-table-bordered tbody>tr:last-child>td{border-bottom-width:0}.pure-table-horizontal td,.pure-table-horizontal th{border-width:0 0 1px 0;border-bottom:1px solid #cbcbcb}.pure-table-horizontal tbody>tr:last-child>td{border-bottom-width:0}\n@primary-color: #BC982F;@border-radius-base: 10px;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
