
import React from 'react';
import {Spin, Col, Empty, Row, Table, Alert, Space} from 'antd';
import {QueryStatus, type Query, type User} from 'app/models';
import {useDispatch, useSelector} from 'react-redux';
import {count, get, onPaginationChange, setFilterValue} from './actions';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {getQueryDateRange} from 'app/query_helpers';
import {useHistory} from 'react-router';
import {idNotExist, uiPaths} from 'app/constants';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {formatDateFunction, toSubstring, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';

export const ViewCorporateQueryAll: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		byIds,
		allIds,
		loading,
		totalCount,
		currentPos,
		perPageSize,
		filterValue,
	} = useSelector((state: Types.RootState) => state.corporateQuery);
	const {
		user: authUser,
		subordinateOptions,
		userOptions,
	} = useSelector((state: Types.RootState) => state.summary);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, [filterValue]);

	const statusOptions: FilterOptionItem[] = [
		{value: QueryStatus.OPEN, label: 'Open'},
		{value: QueryStatus.CLOSED, label: 'Closed'},
	];

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {
				and: [
					{
						or: [
							{
								createdBy: {
									inq: [
										...subordinateOptions
											.filter(({value}: FilterOptionItem) => value)
											.map(({value}: FilterOptionItem) =>
												parseInt(value, 10),
											),
										(authUser?.id ?? 0),
									],
								},
							},
							{
								assignedTo: {
									inq: [
										...subordinateOptions
											.filter(({value}: FilterOptionItem) => value)
											.map(({value}: FilterOptionItem) =>
												parseInt(value, 10),
											),
										(authUser?.id ?? 0),
									],
								},
							},
						],
					},
				],
			},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
		};

		if (f.assignedTo) {
			filter.where.assignedTo = f.assignedTo;
		}

		if (f.userId) {
			filter.where.createdBy = f.userId;
		}

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt');
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const queries
    = allIds?.length ? allIds.map((id: number) => byIds[id]) : [];

	const raisedByUserOptions: FilterOptionItem[] = [...userOptions];

	const assignedToUserOptions: FilterOptionItem[] = [
		...userOptions,
		{value: String(idNotExist), label: 'Not Yet Assigned'},
	];

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'userId',
			items: raisedByUserOptions,
			formType: FilterFormType.SELECT,
			formWidth: 160,
			placeholder: 'Select User',
			label: 'Raised By',
			defaultValue: filterValue?.userId,
		},
		{
			filterKey: 'assignedTo',
			items: assignedToUserOptions,
			formType: FilterFormType.SELECT,
			formWidth: 240,
			placeholder: 'Select Assigned To',
			label: 'Assigned To',
			defaultValue: filterValue?.assignedTo,
		},
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: filterValue?.status,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
	];

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
						filterData(f);
					}} />
					<br />
				</Col>

				{totalCount ? (
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={currentPos ?? 1}
							perPageSize={perPageSize ?? 10}
							filterValue={filterValue}
							filterData={filterData}
							onPaginationChange={onPaginationChange}
						/>
						<Table
							size={'small'}
							pagination={false}
							dataSource={queries}
							scroll={{x: 1200, y: 500}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.corporateQueryDetail.replace(
											':id',
											String(query.id),
										)}`,
									});
								},
							})}
						>
							<Table.Column
								title='Point Raised'
								width={400}
								dataIndex='content'
								key='content'
								render={(content: string) => toSubstring(content, 80, '...')}
								sorter={{
									compare: (a: Query, b: Query) =>
										a.content && b.content && a.content > b.content ? 1 : -1,
									multiple: 1,
								}}
							/>
							<Table.Column
								title='Raised At'
								width={100}
								dataIndex='createdAt'
								key='site'
								render={(createdAt: string) => formatDateFunction(createdAt)}
								sorter={{
									compare: (a: Query, b: Query) =>
										a.createdAt
                    && b.createdAt
                    && new Date(a.createdAt) > new Date(b.createdAt)
											? 1
											: -1,
									multiple: 3,
								}}
							/>
							<Table.Column
								title='Raised By'
								dataIndex='createdUser'
								key='createdUser'
								render={(createdUser: User) =>
									createdUser ? createdUser.name : ''
								}
								sorter={{
									compare: (a: Query, b: Query) =>
										a.createdUser?.name
                    && b.createdUser?.name
                    && a.createdUser?.name > b.createdUser?.name
											? 1
											: -1,
									multiple: 4,
								}}
							/>
							<Table.Column
								title='Assigned At'
								width={100}
								dataIndex='assignedAt'
								key='assignedAt'
								render={(assignedAt: string) => formatDateFunction(assignedAt)}
								sorter={{
									compare: (a: Query, b: Query) =>
										a.assignedAt
                    && b.assignedAt
                    && new Date(a.assignedAt) > new Date(b.assignedAt)
											? 1
											: -1,
									multiple: 5,
								}}
							/>
							<Table.Column
								title='Assigned To'
								dataIndex='assignedUser'
								key='assignedUser'
								render={(assignedUser: User) =>
									assignedUser ? assignedUser.name : 'Not Yet Assigned'
								}
								sorter={{
									compare: (a: Query, b: Query) =>
										a.assignedUser?.name
                    && b.assignedUser?.name
                    && a.assignedUser?.name > b.assignedUser?.name
											? 1
											: -1,
									multiple: 5,
								}}
							/>
							<Table.Column
								title='Status'
								width={100}
								dataIndex='status'
								key='status'
								render={(status: QueryStatus) => toTitleCase(status)}
								sorter={{
									compare: (a: Query, b: Query) =>
										a.status && b.status && a.status > b.status ? 1 : -1,
									multiple: 6,
								}}
							/>
							<Table.Column
								title='Closed At'
								width={100}
								dataIndex='closedAt'
								key='closedAt'
								render={(closedAt: string) => formatDateFunction(closedAt)}
								sorter={{
									compare: (a: Query, b: Query) =>
										a.closedAt
                    && b.closedAt
                    && new Date(a.closedAt) > new Date(b.closedAt)
											? 1
											: -1,
									multiple: 8,
								}}
							/>
						</Table>
					</Col>
				) : (
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				)}
				<Col span={24}>
					<br />
					<Alert
						message='The All Queries section displays all the queries you and your team have raised or those assigned to you or your team.'
						type='info'
					/>
				</Col>
			</Row>
		</Spin>
	);
};
