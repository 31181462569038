import React from 'react';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {ViewClientProjectProcessSchedule} from './ViewClientProjectProcessSchedule';

export const ViewClientApprovalSchedule: React.FC = () => (
	<DefaultLayout currentPath={uiPaths.clientApprovalSchedule}>
		<ViewClientProjectProcessSchedule
			title={'Approval Update'}
			projectProcessType={ProjectProcessType.APPROVALS}
		/>
	</DefaultLayout>
);
