import React from 'react';
import {ModulePermission, type UserPermission} from 'app/models';
import {Space, Table, Tag} from 'antd';
import {remove} from '../../UserPermission/actions';
import {useDispatch} from 'react-redux';

type Props = {
	moduleName: string;
	siteId: number;
	userPermissions: UserPermission[];
};

export const ProjectUserPermissions: React.FC<Props> = ({userPermissions}) => {
	const dispatch = useDispatch();

	const dataSource = [
		userPermissions?.filter(p => p.permission === String(ModulePermission.READ)).length ? {
			title: 'View',
			users: userPermissions?.filter(p => p.permission === String(ModulePermission.READ)),
		} : {},
		userPermissions?.filter(p => p.permission === String(ModulePermission.WRITE)) ? {
			title: 'Edit',
			users: userPermissions?.filter(p => p.permission === String(ModulePermission.WRITE)),
		} : {},
		userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 1) ? {
			title: 'Approve Level 1',
			users: userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 1),
		} : {},
		userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 2) ? {
			title: 'Approve Level 2',
			users: userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 2),
		} : {},
		userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 3) ? {
			title: 'Approve Level 3',
			users: userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 3),
		} : {},
		userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 4) ? {
			title: 'Approve Level 4',
			users: userPermissions?.filter(p => p.permission === String(ModulePermission.APPROVE) && p.level === 4),
		} : {},
	];

	const onUserPermissionRemove = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to remove')) {
			dispatch(remove(id));
		}
	};

	return (
		<Table
			size={'small'}
			showHeader={false}
			bordered={true}
			dataSource={dataSource.filter(data => data?.users?.length)}
			pagination={false}
			scroll={{x: 900, y: 260}}
		>
			<Table.Column
				key='title'
				dataIndex='title'
				render={(title: string) => <strong>{title ?? ''}</strong>}
				width={140}
				rowSpan={50}
				fixed='left'
			/>
			<Table.Column
				key='users'
				dataIndex='users'
				render={(users: UserPermission[]) => (
					<Space wrap={true} direction='horizontal'>
						{users.map((p, ix) =>
							(<Tag key={ix} closable={true} onClose={() => {
								onUserPermissionRemove(p.id);
							}}>{p.user?.name ? p.condition ? `${p.user?.name} ? ${p.condition}` : p.user?.name : 'N/A'}</Tag>),
						)}
					</Space>
				)}
			/>
		</Table>
	);
};
