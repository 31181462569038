import React from 'react';
import {Button, Card, Col, Form, Popconfirm, Row, Space} from 'antd';
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import {FilterFormType, type UiFilter} from 'app/models/ui-filter';
import {FilterForm} from './FilterForm';
import {isMobile} from 'app/helpers';

export type FilterDataType = Record<string, string[] | string | boolean>;

type Props = {
	uiFilters: UiFilter[];
	title?: string;
	submitOnChange?: boolean;
	onSubmit: (value: FilterDataType) => void;
	onReset?: () => void;
};

export const FilterView: React.FC<Props> = ({uiFilters, submitOnChange, onSubmit, onReset}) => {
	const [filter, setFilter] = React.useState(uiFilters
		.filter(uiFilter => uiFilter.defaultValue)
		.reduce((allFilter: FilterDataType, uiFilter: UiFilter) => {
			if (uiFilter.formType === FilterFormType.DATE_RANGE) {
				const defaultValues = uiFilter.defaultValue as string[];
				allFilter[`${uiFilter.filterKey}1`] = defaultValues[0];
				allFilter[`${uiFilter.filterKey}2`] = defaultValues[1];
			} else if (uiFilter.formType === FilterFormType.BOOLEAN) {
				allFilter[uiFilter.filterKey] = Boolean(uiFilter.defaultValue);
			} else {
				allFilter[uiFilter.filterKey] = String(uiFilter.defaultValue);
			}

			return allFilter;
		}, {}));

	const [showFilter, setShowFilter] = React.useState(!isMobile);

	const handleChange = (value: string | boolean, key: string) => {
		const f = {...filter};
		f[key] = value;
		setFilter(f);
		if (submitOnChange) {
			onSubmit(f);
		}
	};

	const handleRangeChange = (value1: string, value2: string, key: string) => {
		const f = {...filter};
		f[`${key}1`] = value1;
		f[`${key}2`] = value2;
		setFilter(f);
		if (submitOnChange) {
			onSubmit(f);
		}
	};

	const handleMultiChange = (values: string[], key: string) => {
		const f = {...filter};
		f[key] = values;
		setFilter(f);
		if (submitOnChange) {
			onSubmit(f);
		}
	};

	const handleSubmit = () => {
		onSubmit(filter);
		if (isMobile) {
			setShowFilter(false);
		}
	};

	const toggleFilter = () => {
		setShowFilter(!showFilter);
	};

	const extra = (
		showFilter ? (
			<Button icon={<CloseOutlined/>} onClick={toggleFilter}>
				Close
			</Button>
		) : (
			<Button type='primary' icon={<SearchOutlined/>} onClick={toggleFilter}>
				Search
			</Button>
		)
	);

	return (
		<Card
			size='small'
			title={'Filters'}
			extra={extra}
			className={'mb-10'}
		>
			{showFilter && (
				<Form>
					<Row gutter={24}>
						{uiFilters.map((uiFilter: UiFilter, ix: number) => (
							<Col span={isMobile ? 24 : 8} key={ix}>
								<FilterForm
									key={ix}
									filterKey={uiFilter.filterKey}
									formType={uiFilter.formType}
									items={uiFilter.items}
									formWidth={uiFilter.formWidth}
									label={uiFilter.label}
									value={uiFilter.defaultValue}
									placeholder={uiFilter.placeholder}
									disabledDateDays={uiFilter.disabledDateDays}
									onChange={handleChange}
									onMultiChange={handleMultiChange}
									onRangeChange={handleRangeChange}
								/>
							</Col>
						))}
					</Row>

					<Row>
						<Col span={24} style={{textAlign: 'right'}}>
							<Space>
								{onReset ? (
									<Popconfirm title={'Are you sure you want to reset!'} onConfirm={onReset}>
										<Button type='primary'>Reset</Button>
									</Popconfirm>
								) : []}
								<Button onClick={handleSubmit} type='primary' icon={<SearchOutlined/>}>
									Search
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			)}
		</Card>
	);
};
