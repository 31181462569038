import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	resetState: '@warrantyPeriod/RESET_STATE',
	apiFilter: '@warrantyPeriod/API_FILTER',
	getApi: '@warrantyPeriod/GET_API',
	createApi: '@warrantyPeriod/CREATE_API',
	createAllApi: '@warrantyPeriod/CREATE_ALL_API',
	updateApi: '@warrantyPeriod/UPDATE_API',
	removeApi: '@warrantyPeriod/REMOVE_API',
	paginationChange: '@warrantyPeriod/PAGINATION_CHANGE',
	getCountApi: '@warrantyPeriod/GET_COUNT_API',
};

const url = lbBaseUrl + '/warranty-periods';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const reset = () => ({
	type: actionTypes.resetState,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const createAll = (data: any) => ({
	type: actionTypes.createAllApi,
	payload: axios.post(`${url}/all`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const remove = (id: number) => ({
	type: actionTypes.removeApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const removeAll = (where: any) => ({
	type: actionTypes.removeApi,
	payload: axios.delete(`${url}?where=${JSON.stringify(where)}`, getHeaders(true)),
	meta: {process: true},
});
