import React from 'react';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {useDispatch, useSelector} from 'react-redux';
import {type FilterDataType} from '../Common/Filter/FilterView';
import {getQueryDateRangeForDays, getQueryDateRange} from 'app/query_helpers';
import {CheckOutlined, CloseOutlined, PaperClipOutlined} from '@ant-design/icons';
import type Types from 'MyTypes';
import {
	type FilterOptionItem,
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {
	Col,
	Row,
	Spin,
	Table,
	Typography,
	Button,
	Space,
	message,
} from 'antd';
import {isMobile, equalNum, parseNum} from 'app/helpers';
import {setFilterValue, get} from './actions';
import {type ElectricityConsumption, ModuleName, ModulePermission, UserType, type Attachment} from 'app/models';
import {getPermissionSites, formatDateFunction, getS3Url} from 'app/helpers';
import {FilterView} from 'app/components/Common/Filter/FilterView';
import {AttachmentsPreviewBlock} from 'app/components/ProjectProcessSchedule/AttachmentsPreviewBlock';
import {Link} from 'react-router-dom';

export const ViewElectricityConsumption: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds, dataUpdated, errorMessage, filterValue} = useSelector(
		(state: Types.RootState) => state.electricityConsumption,
	);
	const today = new Date(new Date().setHours(0, 0, 0, 0));
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const readPermissionSites = getPermissionSites(permissions, ModuleName.ELECTRICITY_CONSUMPTION, ModulePermission.READ, allSites);
	const writePermissionSites = getPermissionSites(permissions, ModuleName.ELECTRICITY_CONSUMPTION, ModulePermission.WRITE, allSites);
	const data: ElectricityConsumption[] = allIds.map(id => byIds[id]);
	const canWrite = filterValue.siteId ? Boolean(writePermissionSites.find(s =>
		String(s.id) === filterValue.siteId,
	)) : false;

	const siteOptions: FilterOptionItem[] = readPermissionSites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const filterData = (f: FilterDataType = {}) => {
		if (!f.siteId) {
			return;
		}

		const filter: any = {
			where: {},
			offset: 0,
			limit: 300000,
			order: ['createdAt ASC'],
		};

		filter.where.siteId = f.siteId;

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'reportDate');
		}

		if (!f.date1 || !f.date2) {
			filter.where.and = getQueryDateRangeForDays(
				today.toString(),
				0,
				6,
				'reportDate',
			);
			f.date1 = new Date(new Date().setDate(new Date().getDate() - 6))
				.toISOString()
				.split('T')[0];
			f.date2 = new Date().toISOString().split('T')[0];
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	React.useEffect(() => {
		console.log('ViewElectricityConsumption component');
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
			disabledDateDays: 7,
		},
	];

	const dataSource = [...Array.from({length: 7})].map((_, i) => {
		let d: Date;
		if (typeof filterValue.date2 === 'string' || typeof filterValue.date2 === 'number') {
			d = new Date(filterValue.date2);
		} else {
			d = new Date();
		}

		d.setDate(d.getDate() - i);
		// Check whether a entry of this date exists or no
		const entry = data.find(item => new Date(item.reportDate).getDate() === new Date(d.setHours(0, 0, 0, 0)).getDate());
		if (!entry) {
			return {
				createdAt: new Date(d.setHours(0, 0, 0, 0)).toString(),
				meterCount: 0,
				meterType: null,
			};
		}

		return entry;
	});

	return (
		<DefaultLayout currentPath={uiPaths.electricityConsumption}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3}>Electricity Consumption</Typography.Title>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={f => {
								filterData(f);
							}}
						/>
						<br />
					</Col>
				</Row>
				{filterValue.siteId && canWrite ? (
					<Row>
						<Col span={24} style={{textAlign: 'right', marginBottom: '10px'}}>
							<Link
								to={{
									pathname: `/${uiPaths.electricityConsumptionForm.replace(
										':siteId',
										filterValue.siteId as string,
									)}`,
								}}
							>
								<Button type='primary' style={{marginBottom: 16}}>
                  Add Electricity Consumption
								</Button>
							</Link>

						</Col>
					</Row>
				) : []}
				{filterValue.siteId ? (
					<Row>
						<Col span={24}>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
								scroll={{x: 800, y: 500}}
							>
								<Table.Column
									title='Creation Details'
									dataIndex='createdAt'
									key='createdAt'
									render={ (createdAt: string) => formatDateFunction(createdAt)}
								/>
								<Table.Column
									title='Meter Type'
									dataIndex='meterType'
									key='meterType'
								/>
								<Table.Column
									title='Meter Reading'
									dataIndex='meterCount'
									key='meterCount'
								/>
								<Table.Column
									title='Attachments'
									dataIndex='attachments'
									key='attachments'
									render = {(attachments: Attachment[]) => attachments?.length
										? attachments.map((attachment, ixx: number) => (
											<a key={ixx} href={getS3Url(attachment.key ?? '')} title={attachment.name} target='_blank' rel='noreferrer'>
												<PaperClipOutlined />
											</a>
										))
										: []}
								/>
							</Table>
						</Col>
					</Row>
				) : []}
			</Spin>
		</DefaultLayout>
	);
};
