import React from 'react';
import {useEffect, useRef, useState} from 'react';

type Props = {
	data: any;
	url: string;
	height?: number;
	width?: number;
	onLoad?: () => void;
	onData?: (data: any) => void;
};

const Retool: React.FC<Props> = ({data, url, height, width, onLoad, onData}) => {
	const embeddedIframe = useRef<any>(null);
	const [elementWatchers, setElementWatchers] = useState({});

	/* Retool passes up the list of elements to watch on page load  */
	useEffect(() => {
		for (const key in elementWatchers) {
			if (key) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const watcher = elementWatchers[key];
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				watcher.iframe?.contentWindow.postMessage(
					{
						type: 'PARENT_WINDOW_RESULT',
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						result: data[watcher.selector],
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						id: watcher.queryId,
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						pageName: watcher.pageName,
					},
					'*',
				);
			}
		}
	}, [data, elementWatchers]);

	/* On page load, add event listener to listen for events from Retool */
	useEffect(() => {
		/* Handle events - if PWQ then create/replace watchers -> return result */
		const handler = event => {
			if (!embeddedIframe?.current?.contentWindow) {
				return;
			}

			if (
				event.origin === new URL(url).origin
        && event.data?.type !== 'PARENT_WINDOW_QUERY'
        && event.data?.type !== 'intercom-snippet__ready'
			) {
				onData?.(event.data);
			}

			if (event.data.type === 'PARENT_WINDOW_QUERY') {
				createOrReplaceWatcher(
					event.data.selector,
					event.data.pageName,
					event.data.id,
				);
				postMessageForSelector('PARENT_WINDOW_RESULT', event.data);
			}
		};

		window.addEventListener('message', handler);

		return () => {
			window.removeEventListener('message', handler);
		};
	}, []);

	/* Creates or updates the list of values for us to watch for changes */
	const createOrReplaceWatcher = (selector, pageName, queryId) => {
		// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
		const watcherId = pageName + '-' + queryId;
		const updatedState = elementWatchers;

		updatedState[watcherId] = {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			iframe: embeddedIframe.current,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			selector,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			pageName,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			queryId,
		};

		setElementWatchers(updatedState);
	};

	/* Checks for selectors for data and posts message for Retool to read */
	const postMessageForSelector = (messageType, eventData) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const maybeData = data[eventData.selector];

		if (maybeData) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			embeddedIframe.current.contentWindow.postMessage(
				{
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					type: messageType,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					result: maybeData,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					id: eventData.id,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					pageName: eventData.pageName,
				},
				'*',
			);
		} else {
			console.log(
				`Not sending data back to Retool, nothing found for selector: ${eventData.selector}`,
			);
		}
	};

	return (
		<iframe
			height={height ?? '100%'}
			width={width ?? '100%'}
			frameBorder='none'
			src={url}
			ref={embeddedIframe}
			title='retool'
			onLoad={onLoad}
		/>
	);
};

export default Retool;
