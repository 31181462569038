import {type Site} from './site.model';
import {type Attachment} from './attachment.model';

export type ElectricityConsumption = {
	id: number;
	siteId: number;
	meterType: MeterType;
	meterCount: number;
	reportDate: string;
	createdAt?: string;
	createdBy: number;
	updatedAt: string;
	updatedBy: number;
	attachments: Attachment[];
	site: Site;
};

export enum MeterType {
	PRE_PAID = 'Pre-paid',
	POST_PAID = 'Post-paid',
}

