import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {Col, Menu, Row, Typography} from 'antd';
import {hasPermission} from 'app/helpers';
import {changePage as onPageChange} from './actions';
import {materialQueryPathType} from './constants';
import {ViewMaterialQueryForm} from './ViewMaterialQueryForm';
import {ViewAllMaterialQuery} from './ViewAllMaterialQuery';
import {uiPaths} from 'app/constants';
import {ModuleName, ModulePermission} from 'app/models';
import {ViewMaterialQueryRaised} from './ViewMaterialQueryRaised';
import {ViewMaterialQueryPendingForApproval} from './ViewMaterialQueryPendingForApproval';
import type Types from 'MyTypes';

export const ViewMaterialQuery: React.FC = () => {
	const dispatch = useDispatch();
	const {byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const {pageType} = useSelector((state: Types.RootState) => state.materialQuery);

	const canWrite: boolean = permissions ? hasPermission(permissions[ModuleName.MATERIAL_QUERY], ModulePermission.WRITE) : false;
	const currentPageType: string = pageType ?? (canWrite ? materialQueryPathType.new : materialQueryPathType.all);

	return (
		<DefaultLayout currentPath={uiPaths.materialQuery}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>Material Queries</Typography.Title>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(onPageChange(e.key))}
						selectedKeys={[currentPageType]}
						mode='horizontal'
					>
						{canWrite ? (
							<Menu.Item key={materialQueryPathType.new}>
								Raise New Query
							</Menu.Item>
						) : []}
						{canWrite ? (
							<Menu.Item key={materialQueryPathType.byMe}>
								Raise By Me
							</Menu.Item>
						) : []}
						{canWrite ? (
							<Menu.Item key={materialQueryPathType.pendingForApproval}>
								Pending for Approval
							</Menu.Item>
						) : []}
						<Menu.Item key={materialQueryPathType.all}>
							All Queries
						</Menu.Item>
					</Menu>
				</Col>
			</Row>

			<br />
			<br />

			{canWrite && currentPageType === materialQueryPathType.new ? (
				<ViewMaterialQueryForm />
			) : []}
			{currentPageType === materialQueryPathType.all ? (
				<ViewAllMaterialQuery
					pageType={currentPageType}
				/>
			) : []}
			{currentPageType === materialQueryPathType.byMe ? (
				<ViewMaterialQueryRaised />
			) : []}
			{currentPageType === materialQueryPathType.pendingForApproval ? (
				<ViewMaterialQueryPendingForApproval />
			) : []}
		</DefaultLayout>
	);
};
