import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
	Button,
	Col,
	Empty,
	Form,
	message,
	Row,
	Space,
	Spin,
	Typography,
} from 'antd';
import {ModuleName, ModulePermission, UserType} from 'app/models';
import {notApplicable, uiPaths} from 'app/constants';
import {equalNum, getPermissionSites, parseNum} from 'app/helpers';
import {DefaultLayout} from 'app/components/Layout/DefaultLayout';
import type Types from 'MyTypes';
import {useHistory, useParams} from 'react-router';
import {update, create, submit, approve, reject} from './actions';
import {ChecklistQuestionResponseForm} from 'app/components/ProcessChecklistResponse/ChecklistQuestionResponseForm';
import {type ProcessChecklistQuestionResponse} from 'app/models/process-checklist-question-response.model';
import {ProcessChecklistResponseStatus} from 'app/models/process-checklist-response.model';
import {type ProjectProcessType} from '../ProjectProcessMaster/project-process-master';
import {getById} from '../ProjectProcessSchedule/actions';
import {ProcessChecklistOnActionType} from 'app/models/process-checklist.model';

type MyParams = {
	scheduleId?: string;
	drawingScheduleId?: string;
	checklistId?: string;
	siteId?: string;
	processType: string;
};

// eslint-disable-next-line complexity
export const ViewProcessChecklistResponseForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {checklistResponse, loading, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.processChecklistResponse,
	);
	const {byIds} = useSelector(
		(state: Types.RootState) => state.projectProcessSchedule,
	);
	const scheduleId = parseNum(params?.scheduleId ?? '0');
	const drawingScheduleId = parseNum(params?.drawingScheduleId ?? '0');
	const checklistId = String(params?.checklistId ?? '');
	const siteId = parseNum(params?.siteId ?? '0');
	const schedule = scheduleId ? byIds[scheduleId] : undefined;
	const projectProcessType = params?.processType as ProjectProcessType;
	const [questionResponses, setQuestionResponses] = React.useState<
	ProcessChecklistQuestionResponse[]
	>([]);

	React.useEffect(() => {
		console.log('ViewProcessChecklistResponseForm.componentDidMount');
		if (drawingScheduleId) {
			dispatch(
				create({
					drawingScheduleId,
					processChecklistId: checklistId,
					siteId,
					projectProcessType,
				}),
			);
		} else {
			dispatch(
				create({
					projectProcessScheduleId: scheduleId,
					processChecklistId: checklistId,
					siteId,
					projectProcessType,
				}),
			);
		}

		// Getting the project process schedule
		dispatch(getById(scheduleId));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onChecklistResponseChange = (
		response: ProcessChecklistQuestionResponse,
	) => {
		const items = [...questionResponses];
		const ix = items.findIndex(
			i =>
				i.processChecklistQuestionId === response.processChecklistQuestionId,
		);
		if (ix === -1) {
			items.push(response);
		} else {
			items[ix] = response;
		}

		setQuestionResponses(items);
	};

	const onSaveClicked = () => {
		if (checklistResponse?.id) {
			dispatch(update(checklistResponse?.id, {questionResponses}));
		}
	};

	const onSubmitClicked = () => {
		const incompleteAfterCompletionChecklists
			= schedule?.projectProcessMaster?.processChecklists
				?.filter(
					checklist =>
						checklist.onActionType
						=== ProcessChecklistOnActionType.AFTER_COMPLETION,
				)
				.filter(checklist => {
					const checklistResponse = schedule?.checklistResponses?.find(
						res => res.processChecklistId === checklist.id,
					);
					if (
						!checklistResponse
						|| checklistResponse?.status !== ProcessChecklistResponseStatus.APPROVED
					) {
						return true;
					}

					return false;
				});
		console.log(incompleteAfterCompletionChecklists);
		if (
			checklistResponse?.processChecklist?.onActionType
			=== ProcessChecklistOnActionType.AFTER_COMPLETION
			&& incompleteAfterCompletionChecklists
			&& incompleteAfterCompletionChecklists.length === 1
		) {
			console.log('I m inside');
			if (schedule?.completedPhotos && schedule?.completedPhotos.length === 0) {
				void message.error('Please upload Completion photos by going back!');
				return;
			}
		}

		if (
			// eslint-disable-next-line no-alert
			!window.confirm(
				'Are you sure you want to submit this checklist for inspection.'
				+ ' Please make sure all checklists are filled',
			)
		) {
			return;
		}

		const questionResponses = checklistResponse?.questionResponses;
		const questions
			= checklistResponse?.processChecklist?.processChecklistQuestions;
		if (!questions?.length) {
			void message.error('No checklist to submit.');
			return;
		}

		if (!questionResponses?.length) {
			void message.error('Please save all responses.');
			return;
		}

		let submitChecklist = true;
		questions.forEach(question => {
			const questionResponse = questionResponses.find(
				res => res.processChecklistQuestionId === question.id,
			);
			if (!questionResponse?.response) {
				submitChecklist = false;
				void message.error('Please save all responses.');
				return;
			}

			if (
				questionResponse.response === notApplicable
				&& !questionResponse.remarks
			) {
				submitChecklist = false;
				void message.error('Please enter remarks for not applicable checklist.');
				return;
			}

			if (
				questionResponse.response !== notApplicable
				&& question.isPhotoRequired
				&& !questionResponse.supportingPhotos?.length
			) {
				submitChecklist = false;
				void message.error('Please upload required supporting photos.');
			}
		});

		if (checklistResponse?.id && submitChecklist) {
			dispatch(submit(checklistResponse?.id, {}));
		}
	};

	const onApproveClicked = () => {
		// eslint-disable-next-line no-alert
		if (!window.confirm('Are you sure you want to approve this checklist.')) {
			return;
		}

		if (checklistResponse?.id) {
			dispatch(approve(checklistResponse?.id, {}));
		}
	};

	const onRejectClicked = () => {
		// eslint-disable-next-line no-alert
		const cancelReason = window.prompt('Please Give a Cancellation Reason');
		if (!cancelReason) {
			void message.error('Please Enter Cancellation Reason');
			return;
		}

		if (checklistResponse?.id) {
			dispatch(reject(checklistResponse?.id, {cancelReason}));
		}
	};

	const processChecklist = checklistResponse?.processChecklist;
	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};
	const moduleName
		= processChecklist?.moduleName ?? ModuleName.CONSTRUCTION_SCHEDULE;
	const writeSites = getPermissionSites(
		permissions,
		moduleName,
		ModulePermission.WRITE,
		allSites,
	);
	const canWrite
		= writeSites.find(({id}) => equalNum(siteId, id))
		&& checklistResponse?.status !== ProcessChecklistResponseStatus.SUBMITTED
		&& checklistResponse?.status !== ProcessChecklistResponseStatus.APPROVED;
	const canApprove
		= checklistResponse?.nextApprovedUsers?.find(({id}) => equalNum(id, authUser?.id),
		) && checklistResponse?.status === ProcessChecklistResponseStatus.SUBMITTED;
	const questions
		= processChecklist?.processChecklistQuestions
			&& checklistResponse?.status === ProcessChecklistResponseStatus.APPROVED ? processChecklist?.processChecklistQuestions.filter(item =>
				checklistResponse?.questionResponses?.find(
					r => r.processChecklistQuestionId === item.id,
				),
			)
			: processChecklist?.processChecklistQuestions;

	if (!checklistResponse) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	return (
		<DefaultLayout
			currentPath={uiPaths.viewProcessChecklistResponseDetail}
		>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							{processChecklist?.title}
						</Typography.Title>
						<Typography.Title level={5} style={{textAlign: 'center'}}>
							{processChecklist?.description}
						</Typography.Title>
						<br />
						<br />
						{checklistResponse?.id ? (
							<Form {...layout} name='basic'>
								{checklistResponse?.id
									? questions
										?.sort((a: any, b: any) => a.sno - b.sno)
										.map((question, ix) => (
											<ChecklistQuestionResponseForm
												key={ix}
												canWrite={canWrite}
												authUser={authUser}
												sno={ix + 1}
												checklistResponseId={checklistResponse.id}
												question={question}
												questionResponse={
													questionResponses.find(
														r =>
															r.processChecklistQuestionId === question.id,
													)
													?? checklistResponse?.questionResponses?.find(
														r =>
															r.processChecklistQuestionId === question.id,
													)
												}
												onResponseChange={(
													value: ProcessChecklistQuestionResponse,
												) => {
													onChecklistResponseChange(value);
												}}
											/>
										))
									: []}
								{canWrite ? (
									<Form.Item {...tailLayout}>
										<Space>
											<Button
												size={'large'}
												type='primary'
												onClick={onSaveClicked}
											>
												Save Checklist
											</Button>
											<Button
												size={'large'}
												type='primary'
												danger={true}
												onClick={onSubmitClicked}
											>
												Submit Checklist for Inspection
											</Button>
										</Space>
									</Form.Item>
								) : (
									[]
								)}
								{authUser?.userType === UserType.CLIENT ? [] : (
									<Form.Item style={{textAlign: 'right'}}>
										{canApprove ? (
											<Space>
												<Button
													size={'large'}
													type='primary'
													onClick={onApproveClicked}
												>
													Approve
												</Button>
												<Button
													size={'large'}
													type='primary'
													danger={true}
													onClick={onRejectClicked}
												>
													Reject
												</Button>
											</Space>
										) : (
											<Space direction={'vertical'}>
												{checklistResponse?.status
													=== ProcessChecklistResponseStatus.SUBMITTED ? (
														<Typography.Title level={3}>
															{'Checklist has been submitted for Inspection.'}
														</Typography.Title>
													) : (
														[]
													)}
												{checklistResponse?.status
													=== ProcessChecklistResponseStatus.APPROVED ? (
														<Typography.Title level={3}>
															{'Checklist has been approved.'}
														</Typography.Title>
													) : (
														[]
													)}
												{checklistResponse?.status
													=== ProcessChecklistResponseStatus.REJECTED ? (
														<Space direction={'vertical'}>
															<Typography.Title level={3}>
																{'Checklist has been reject'}
															</Typography.Title>
															<p>
																<b>Reason:</b> {checklistResponse.cancelReason}
															</p>
														</Space>
													) : (
														[]
													)}

												{checklistResponse?.nextApprovedUsers?.length ? (
													<h4>
														Pending for Approval from{' '}
														{checklistResponse?.nextApprovedUsers
															?.map(({name}) => name)
															.join(',')}
													</h4>
												) : (
													[]
												)}
											</Space>
										)}
									</Form.Item>
								)}
							</Form>
						) : (
							[]
						)}
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
