import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Empty,
	message,
	Row,
	Space,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
} from './actions';
import {Link} from 'react-router-dom';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';
import {type CategoryType, categoryTypeLabels, type Category} from './category';
import {isMobile} from 'app/helpers';
import {moduleLabelName} from 'app/models';

export const ViewAllCategory: React.FC = () => {
	const dispatch = useDispatch();
	const {
		loading,
		byIds,
		allIds,
		filterValue,
		dataUpdated,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
	} = useSelector((state: Types.RootState) => state.category);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f.name) {
			filter.where.name = {like: `%25${f.name as string}%25`};
		}

		if (f.categoryType) {
			filter.where.categoryType = f.categoryType;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const dataSource: Category[] = allIds?.length
		? allIds.map(id => byIds[id])
		: [];

	const categoryTypeOptions: FilterOptionItem[] = Object.keys(categoryTypeLabels).map((type: string) => ({
		label: moduleLabelName[type],
		value: type,
	}));

	const uiFilters = [
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Name',
			label: 'Name',
			defaultValue: filterValue ? filterValue.name : '',
		},
		{
			filterKey: 'categoryType',
			items: categoryTypeOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Type',
			label: 'Type',
			defaultValue: filterValue?.categoryType,
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.allCategories}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Category
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col span={24} style={{textAlign: 'right'}}>
						<Link
							to={{
								pathname: `/${uiPaths.addCategory}`,
							}}
						>
							<Button type='primary'>Add New Category</Button>
						</Link>
						<br />
						<br />
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
							>
								<Table.Column
									title='Type'
									dataIndex='categoryType'
									key='categoryType'
									render={(categoryType: CategoryType) => categoryType ? categoryTypeLabels[categoryType] : ''}
								/>
								<Table.Column
									title='Parent'
									dataIndex='parent'
									key='parent'
									render={(parent: Category) => parent?.name ?? ''}
								/>
								<Table.Column
									width={500}
									title='Name'
									dataIndex='name'
									key='name'
								/>
								<Table.Column
									title='Measurable'
									dataIndex='isMeasurable'
									key='isMeasurable'
									render={(isMeasurable: boolean) => isMeasurable ? 'Yes' : 'No'}
								/>
								<Table.Column
									title='Debitable'
									dataIndex='isDebitable'
									key='isDebitable'
									render={(isDebitable: boolean) => isDebitable ? 'Yes' : 'No'}
								/>
								<Table.Column
									title='Lead Time'
									dataIndex='leadTime'
									key='leadTime'
								/>
								<Table.Column
									title='Deactivated'
									dataIndex='isDeactivated'
									key='isDeactivated'
									render={(isDeactivated: boolean) => isDeactivated ? 'Deactivated' : ''}
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(action: string, item: Category) => (
										<Space direction={isMobile ? 'vertical' : 'horizontal'}>
											<Link
												to={{
													pathname: `/${uiPaths.editCategory.replace(
														':id',
														String(item.id),
													)}`,
												}}
											>
												<Button size={'small'} type='primary'>
													Edit
												</Button>
											</Link>
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
