import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	message,
	Row,
	Select,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {create, update} from './actions';
import {useHistory, useParams} from 'react-router';
import {getUniqueId, toTitleCase, parseNum} from 'app/helpers';
import {ProcessChecklistOnActionType} from 'app/models/process-checklist.model';
import {PlusOutlined} from '@ant-design/icons';
import {type ProcessChecklistQuestion} from 'app/models/process-checklist-question.model';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {ChecklistQuestionForm} from 'app/components/ProcessChecklist/ChecklistQuestionForm';
import {moduleLabelName, ModuleName} from 'app/models';

type MyParams = {
	id?: string;
};

export const ViewProcessChecklistForm: React.FC = () => {
	const history = useHistory();
	const {byIds, loading, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.processChecklist,
	);
	const dispatch = useDispatch();
	const params = useParams<MyParams>();
	const id = params?.id ?? '';
	const processChecklist = byIds[id];
	console.log(processChecklist);
	const onActionTypeOptions = Object.values(ProcessChecklistOnActionType).map(
		(status: string) => ({value: status, label: toTitleCase(status) ?? ''}),
	);
	const [processChecklistId, setProcessChecklistId] = React.useState<string>(
		processChecklist?.id ?? getUniqueId(),
	);
	const [moduleName, setModuleName] = React.useState<ModuleName | undefined>(
		processChecklist?.moduleName ?? undefined,
	);
	const [title, setTitle] = React.useState<string>(
		processChecklist?.title ?? '',
	);
	const [description, setDescription] = React.useState<string>(
		processChecklist?.description ?? '',
	);
	const [onActionType, setOnActionType] = React.useState<
	ProcessChecklistOnActionType | undefined
	>(processChecklist?.onActionType ?? undefined);
	const [processChecklistQuestions, setProcessChecklistQuestions]
		= React.useState<ProcessChecklistQuestion[]>(
			processChecklist?.processChecklistQuestions
				? [
					...processChecklist.processChecklistQuestions.sort(
						(a: ProcessChecklistQuestion, b: ProcessChecklistQuestion) =>
							a.sno && b.sno ? parseNum(a.sno) - parseNum(b.sno) : 0,
					),
				]
				: [],
		);
	React.useEffect(() => {
		if (dataUpdated) {
			history.push({
				pathname: `/${uiPaths.processChecklist}`,
			});
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onChecklistQuestionChange = (
		i: number,
		value: ProcessChecklistQuestion,
	) => {
		const items = [...processChecklistQuestions];
		items[i] = value;
		setProcessChecklistQuestions(items);
	};

	const onChecklistQuestionAdd = () => {
		const items = [...processChecklistQuestions];
		items.push({
			id: getUniqueId(),
			processChecklistId,
			sno: '',
			content: '',
		});
		setProcessChecklistQuestions(items);
	};

	const onChecklistQuestionRemove = (i: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to remove')) {
			const items = [...processChecklistQuestions];
			items.splice(i, 1);
			setProcessChecklistQuestions(items);
		}
	};

	const onSubmitClicked = () => {
		if (!moduleName) {
			void message.error('Please select a module!');
			return;
		}

		if (!title) {
			void message.error('Please enter a title!');
			return;
		}

		if (!onActionType) {
			void message.error('Please select when should the checklist be filled!');
			return;
		}

		if (processChecklist?.id) {
			dispatch(
				update(processChecklistId, {
					moduleName,
					title,
					onActionType,
					description,
					processChecklistQuestions,
				}),
			);
		} else {
			dispatch(
				create({
					id: processChecklistId,
					moduleName,
					title,
					onActionType,
					description,
					processChecklistQuestions,
				}),
			);
		}
	};

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};
	return (
		<DefaultLayout currentPath={uiPaths.createProcessChecklist}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							Process Checklist Form
						</Typography.Title>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Form {...layout} name='basic'>
							<Form.Item label='Module Name*'>
								<Select
									style={{width: '100%'}}
									placeholder='Select a Module'
									defaultValue={moduleName}
									onChange={value => {
										setModuleName(value as ModuleName);
									}}
								>
									<Select.Option value={ModuleName.CONSTRUCTION_SCHEDULE}>
										{moduleLabelName[ModuleName.CONSTRUCTION_SCHEDULE]}
									</Select.Option>
									<Select.Option value={ModuleName.DRAWING_SCHEDULE}>
										{moduleLabelName[ModuleName.DRAWING_SCHEDULE]}
									</Select.Option>
									<Select.Option value={ModuleName.APPROVAL_SCHEDULE}>
										{moduleLabelName[ModuleName.APPROVAL_SCHEDULE]}
									</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item label='Checklist Title*'>
								<Input
									defaultValue={title}
									value={title}
									onChange={(e: any) => {
										setTitle(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label='Checklist Description'>
								<Input
									defaultValue={description}
									value={description}
									onChange={(e: any) => {
										setDescription(String(e.target.value));
									}}
								/>
							</Form.Item>
							<Form.Item label='When Should the Checklist be Filled*'>
								<Select
									style={{width: '100%'}}
									onChange={value => {
										setOnActionType(value as ProcessChecklistOnActionType);
									}
									}
									defaultValue={onActionType ?? ''}
								>
									{onActionTypeOptions.map(
										(opt: FilterOptionItem, ix: number) => (
											<Select.Option key={ix} value={opt.value}>
												{opt.label}
											</Select.Option>
										),
									)}
								</Select>
							</Form.Item>
							<Form.Item label={<b>{'Checklist Questions'}</b>}>
								{processChecklistQuestions.map((question, ix) => (
									<Card
										key={ix}
										size={'small'}
										style={{marginBottom: 10}}
										actions={[
											<Button
												key={ix}
												type={'dashed'}
												danger={true}
												onClick={() => {
													onChecklistQuestionRemove(ix);
												}}
											>
												Remove Checklist Question
											</Button>,
										]}
									>
										<ChecklistQuestionForm
											question={question}
											onChange={(value: ProcessChecklistQuestion) => {
												onChecklistQuestionChange(ix, value);
											}
											}
										/>
									</Card>
								))}
								<Form.Item>
									<Button
										type='dashed'
										onClick={() => {
											onChecklistQuestionAdd();
										}}
										block
										icon={<PlusOutlined />}
									>
										Add Checklist Question
									</Button>
								</Form.Item>
							</Form.Item>
							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
