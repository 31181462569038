import React from 'react';
import {Typography, Divider, Table, Row, Col} from 'antd';
const {Title, Text} = Typography;
import {type ColumnType} from 'antd/es/table';
import './PrintPreview.less';
import {type UserDetail, type WorkOrderBill} from 'app/models';
import {getCurrencyString} from 'app/helpers';
import {nameChangeDate} from 'app/constants';

type Item = {
	key: string;
	sno: string;
	particulars: string;
	amount: string;
};

// eslint-disable-next-line complexity
export const ComponentToPrint = React.forwardRef<HTMLDivElement, any>((props, ref) => {
	const bill = props.data as WorkOrderBill;
	const contractor = bill.workOrder?.contractor ?? bill.contractor;
	if (contractor?.detail) {
		contractor.userDetail = JSON.parse(contractor.detail) as UserDetail;
	}

	const dataSource: Item[] = [];
	let dataSourceSno = 1;

	if (bill.paymentMilestones?.length) {
		bill.paymentMilestones.forEach(milestone => {
			dataSource.push({
				key: String(dataSourceSno),
				sno: String(dataSourceSno),
				particulars: milestone.name,
				amount: getCurrencyString(milestone.amount, false),
			});
			dataSourceSno += 1;
		});
	}

	if (bill.penalties?.length) {
		bill.penalties.forEach(penalty => {
			dataSource.push({
				key: String(dataSourceSno),
				sno: String(dataSourceSno),
				particulars: penalty.description ?? '',
				amount: getCurrencyString(-1 * penalty.amount, true),
			});
			dataSourceSno += 1;
		});
	}

	if (bill.debits?.length) {
		bill.debits.forEach(debit => {
			dataSource.push({
				key: String(dataSourceSno),
				sno: String(dataSourceSno),
				particulars: debit.description ?? '',
				amount: getCurrencyString(-1 * debit.amount, true),
			});
			dataSourceSno += 1;
		});
	}

	if (!dataSource?.length) {
		dataSource.push({
			key: String(dataSourceSno),
			sno: String(dataSourceSno),
			particulars: 'Bill as per attachment',
			amount: getCurrencyString(bill.billAmount, true),
		});
	}

	const columns: Array<ColumnType<Item>> = [
		{
			title: 'S.No',
			dataIndex: 'sno',
			key: 'sno',
			align: 'center',
			width: '10%',
		},
		{
			title: 'Particulars',
			dataIndex: 'particulars',
			key: 'particulars',
			align: 'center',
			render: text => <div style={{textAlign: 'left'}}>{text}</div>,
			// Width is auto-adjusted to take the remaining space
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center',
			width: '20%',
		},
	];
	const penaltyAmount = bill.penalties?.length
		? bill.penalties.reduce((amt, penalty) => amt + penalty.amount, 0)
		: 0;
	const debitAmount = bill.debits?.length
		? bill.debits.reduce((amt, debit) => amt + debit.amount, 0)
		: 0;
	const totalAmount = bill.billAmount - penaltyAmount - debitAmount;
	const companyName = new Date(bill.createdAt ?? '') > new Date(nameChangeDate) ? 'Prithu Builders Pvt Ltd' : 'Vinimay Developers Pvt Ltd';
	const companyAddressLine1 = new Date(bill.createdAt ?? '') > new Date(nameChangeDate) ? '6A, 6th Floor Atma Ram House' : '15D, Atma Ram House';
	return (
		<div ref={ref} className='print-preview' style={{padding: 80}}>
			<div style={{textAlign: 'center', marginBottom: '10px'}}>
				<Title level={2}>{contractor?.name ?? ''}</Title>
				<Text>{contractor?.userDetail?.localAddress?.addressLine ?? ''}</Text>
			</div>
			<Row justify='space-between'>
				<Col><Text strong>PAN No: {contractor?.panNumber ?? ''}</Text></Col>
				<Col><Text strong>Mobile No: {contractor?.mobileNumbers ?? ''}</Text></Col>
			</Row>
			<Divider />
			<Row justify='space-between'>
				<Col><Text strong>Invoice No: ..................</Text></Col>
				<Col><Text strong>Date: {new Date(bill.createdAt ?? '').toLocaleDateString('en-GB')}</Text></Col>
			</Row>
			<div style={{marginTop: '50px'}}>
				<Text>To</Text>
				<br />
				<Text style={{marginLeft: 20}}>{companyName}</Text>
				<br />
				<Text style={{marginLeft: 20}}>{companyAddressLine1}</Text>
				<br />
				<Text style={{marginLeft: 20}}>Tolstoy Marg, Delhi-110001.</Text>
			</div>
			<div style={{marginTop: '50px', marginBottom: '30px'}}>
				<Text>Site Address: {bill.site?.name ?? ''}</Text>
				<br />
				<Text>Work: {bill.workOrder ? bill.workOrder.projectProcessMaster?.processName : bill.projectProcessMaster?.processName}</Text>
			</div>
			<div style={{textAlign: 'center', margin: '10px'}}>
				<Text strong>INVOICE</Text>
			</div>
			{dataSource?.length ? (
				<Table className='custom-table' dataSource={dataSource} columns={columns} pagination={false} summary={() => (
					<Table.Summary.Row>
						<Table.Summary.Cell index={0}>&nbsp;</Table.Summary.Cell>
						<Table.Summary.Cell index={1} align='right'><b>Total</b></Table.Summary.Cell>
						<Table.Summary.Cell index={2} align='center'><b>{getCurrencyString(totalAmount, false)}</b></Table.Summary.Cell>
					</Table.Summary.Row>
				)} />
			) : []}
			<div style={{textAlign: 'right', marginTop: '50px'}}>
				<Text strong>Signature</Text>
				<br/>
				<Text strong>({contractor?.name ?? ''})</Text>
			</div>
		</div>
	);
});

ComponentToPrint.displayName = 'ComponentToPrint';
