import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {changePage} from './actions';
import {Col, Menu, Row, Typography} from 'antd';
import {queryPathType} from './constants';
import {ViewClientQueryForm} from './ViewClientQueryForm';
import {ViewClientQueryRaised} from './ViewClientQueryRaised';
import {UserType} from 'app/models';
import type Types from 'MyTypes';
import {ViewAllClientQuery} from './ViewAllClientQuery';

export const ViewClientQuery: React.FC = () => {
	const dispatch = useDispatch();
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {pageType} = useSelector((state: Types.RootState) => state.clientQuery);
	const selectedPage = pageType ?? queryPathType.new;

	return (
		<DefaultLayout currentPath={uiPaths.clientQuery}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>Queries</Typography.Title>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(changePage(String(e.key)))}
						selectedKeys={[selectedPage]}
						mode='horizontal'
					>
						<Menu.Item key={queryPathType.new}>
							Raise New Query
						</Menu.Item>
						<Menu.Item key={queryPathType.raised}>
							All Queries History
						</Menu.Item>
					</Menu>
				</Col>
			</Row>

			<Row>
				<Col span={24}><br /></Col>
			</Row>

			<Row>
				<Col span={24}>
					{selectedPage === queryPathType.new ? (
						<ViewClientQueryForm />
					) : []}
					{selectedPage === queryPathType.raised ? (
						authUser?.userType === UserType.CLIENT ? <ViewClientQueryRaised /> : <ViewAllClientQuery />
					)
						: []}
				</Col>
			</Row>

			<br />
			<br />
			<br />
		</DefaultLayout>
	);
};
