import {DefaultLayout} from '../Layout/DefaultLayout';
import React, {useState, useRef} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import type {ColumnType} from 'antd/es/table';
import type {FilterConfirmProps} from 'antd/es/table/interface';
import {
	SiteStatus,
	type Site,
	ModuleName,
} from 'app/models';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {
	Col,
	Row,
	Spin,
	Typography,
	Table,
	Space,
	Button,
	Input,
} from 'antd';
import {uiPaths} from 'app/constants';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';
import {get} from '../ProjectProcessSchedule/actions';
import {type ProjectProcessSchedule} from '../ProjectProcessSchedule/project-process-schedule';
import {subtractDays, addDays, formatDateFunction, toTitleCase} from 'app/helpers';

type DataType = {
	title: string;
	'Contract Signing': string;
	'Approval Submission': string;
	'Start of Construction': string;
	'Handover of Plot': string;
	'Start of Slab - Basement': string;
	'Start of Slab - Stilt': string;
	'Start of Slab - GF': string;
	'Start of Slab - FF': string;
	'Start of Slab - SF': string;
	'Start of Slab - TF': string;
	'Start of Brickwork': string;
	'Start of Services(AC, electrical or plumbing)': string;
	'Start of Flooring': string;
	'Start of Woodwork (Doors, windows etc)': string;
	'Start of Finishing': string;
	'Start of Fittings and Fixtures': string;
	'Handover and Completion Certificate': string;
};

type DataIndex = keyof DataType;

const paymentMilestoneReportSpecifications = {
	'Contract Signing': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Contract Signing',
		referenceMasterIds: [517],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Approval Submission': {
		referenceModule: ModuleName.APPROVAL_SCHEDULE,
		referenceActivity: 'Building Plan Approval',
		referenceMasterIds: [521],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Construction': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Plot Handover Taken from the client',
		referenceMasterIds: [847],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Slab - Basement': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Column Casting Outer Brickwork & Retaining Walls: Basement',
		referenceMasterIds: [256],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Slab - Stilt': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Column Casting Outer Brickwork & Retaining Walls: Stilt',
		referenceMasterIds: [255],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Slab - GF': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Column Casting Outer Brickwork & Retaining Walls: Ground Floor',
		referenceMasterIds: [254],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Slab - FF': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Column Casting Outer Brickwork & Retaining Walls: First Floor',
		referenceMasterIds: [253],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Slab - SF': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Column Casting Outer Brickwork & Retaining Walls: Second Floor',
		referenceMasterIds: [252],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Slab - TF': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Column Casting Outer Brickwork & Retaining Walls: Third Floor',
		referenceMasterIds: [251],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Brickwork': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Min(Brickwork Partitions(except Lift): Basement or Stilt or Ground Floor)',
		referenceMasterIds: [212, 213, 214],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Services(AC, electrical or plumbing)': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Min(Electrical Part-2(Wall Conduiting, DB fixing along with LV Boxes): Basement or Stilt or Ground Floor)',
		referenceMasterIds: [205, 206, 207],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Flooring': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Min(Tile Flooring- Habitable area Installation: Basement or Stilt or Ground Floor)',
		referenceMasterIds: [114, 115, 116],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Finishing': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Min(Internal Paint Part 1 (Base Preparation + 1st Coat): Basement or Stilt or Ground Floor)',
		referenceMasterIds: [57, 58, 59],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Woodwork (Doors, windows etc)': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Min(Windows(Delivery + Installation): Basement or Stilt or Ground Floor)',
		referenceMasterIds: [51, 52, 53],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Start of Fittings and Fixtures': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Min(Electrical Part-5(Switches + Lights + Fixtures): Basement or Stilt or Ground Floor)',
		referenceMasterIds: [16, 17, 18],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 0,
	},
	'Handover and Completion Certificate': {
		referenceModule: ModuleName.CONSTRUCTION_SCHEDULE,
		referenceActivity: 'Min(Internal Paint Part-2(Final Coat): Basement or Stilt or Ground Floor)',
		referenceMasterIds: [447, 446, 445],
		referenceProperty: 'Actual Start Date',
		beforeDays: 0,
		afterDays: 20,
	},

};

export const ViewPaymentMilestoneReport: React.FC = () => {
	const dispatch = useDispatch();
	const {allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef<any>(null);
	const validSites = allSites.filter((s: Site) => s.status === SiteStatus.UNDER_CONSTRUCTION || s.status === SiteStatus.UNDER_DESIGN);
	const {
		allIds,
		byIds,
		loading,
	} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	React.useEffect(() => {
		console.log('ViewPaymentMilestoneReport component');
		dispatch(
			get({
				where: {
					siteId: {inq: validSites.map(({id}) => id)},
					projectProcessType: {inq: [ProjectProcessType.CONSTRUCTION, ProjectProcessType.APPROVALS]},
					projectProcessMasterId: {inq: Object.values(paymentMilestoneReportSpecifications).reduce((acc: number[], obj) => acc.concat(obj.referenceMasterIds), [])},
				},
			}),
		);
	}, []);

	const items = allIds?.length ? allIds.map(id => byIds[id]) : [];

	const handleSearch = (
		selectedKeys: string[],
		confirm: (param?: FilterConfirmProps) => void,
		dataIndex: DataIndex,
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (
		dataIndex: DataIndex,
	): ColumnType<DataType> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{padding: 8}} onKeyDown={e => {
				e.stopPropagation();
			}}>
				<Input
					ref={searchInput}
					placeholder={`Search ${toTitleCase(dataIndex)}`}
					value={selectedKeys[0]}
					onChange={e => {
						setSelectedKeys(e.target.value ? [e.target.value] : []);
					}
					}
					onPressEnter={() => {
						handleSearch(selectedKeys as string[], confirm, dataIndex);
					}
					}
					style={{marginBottom: 8, display: 'block'}}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => {
							handleSearch(selectedKeys as string[], confirm, dataIndex);
						}
						}
						icon={<SearchOutlined />}
						size='small'
						style={{width: 90}}
					>
						Search
					</Button>
					<Button
						onClick={() => {
							if (clearFilters) {
								handleReset(clearFilters);
							}
						}}
						size='small'
						style={{width: 90}}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
		),
		onFilter: (value, record) =>
			record[dataIndex]?.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase()) ?? false,
		onFilterDropdownVisibleChange(visible) {
			if (visible) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: text => text as string,
	});

	const dataSource = validSites.map(site => {
		const siteItems = items.filter(entry => entry.siteId === site.id);
		const siteData = Object.keys(paymentMilestoneReportSpecifications).map(key => {
			const dates: Date[] = [];
			siteItems.forEach((entry: ProjectProcessSchedule) => {
				if ((paymentMilestoneReportSpecifications[key].referenceMasterIds as number[]).includes(entry.projectProcessMasterId) && entry.startedAt) {
					dates.push(new Date(entry.startedAt));
				}
			});

			let temp = dates.length ? new Date(Math.min(...dates.map(date => date.getTime()))) : '';
			if (temp !== '') {
				temp = subtractDays(temp.toString(), paymentMilestoneReportSpecifications[key].beforeDays as number);
				temp = addDays(temp.toString(), paymentMilestoneReportSpecifications[key].afterDays as number);
			}

			return {
				[key]: formatDateFunction(temp.toString(), false),
			};
		});
		const obj = {
			title: site.name,
		};
		siteData.forEach(item => Object.assign(obj, item));
		return obj;
	});

	return (
		<DefaultLayout currentPath={uiPaths.paymentMilestoneReport}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Payment Milestone Report
						</Typography.Title>
					</Col>
				</Row>
				<Row className='mb-15'>
					<Col span={24}>
						<Table
							size={'small'}
							pagination={false}
							dataSource={dataSource}
							scroll={{x: 1200, y: 500}}
						>
							<Table.Column
								title='Site name'
								fixed='left'
								width={300}
								dataIndex='title'
								key='title'
								{...getColumnSearchProps('title')}
							/>
							<Table.Column
								title= 'Contract Signing'
								width={200}
								dataIndex= 'Contract Signing'
								key= 'Contract Signing'
							/>
							<Table.Column
								title= 'Approval Submission'
								width={200}
								dataIndex= 'Approval Submission'
								key= 'Approval Submission'
							/>
							<Table.Column
								title= 'Handover of Plot'
								width={200}
								dataIndex= 'Start of Construction'
								key= 'Start of Construction'
							/>
							<Table.Column
								title= 'Start of Construction'
								width={200}
								dataIndex= 'Start of Construction'
								key= 'Start of Construction'
							/>
							<Table.Column
								title= 'Start of Slab - Basement'
								width={200}
								dataIndex= 'Start of Slab - Basement'
								key= 'Start of Slab - Basement'
							/>
							<Table.Column
								title= 'Start of Slab - Stilt'
								width={200}
								dataIndex= 'Start of Slab - Stilt'
								key= 'Start of Slab - Stilt'
							/>
							<Table.Column
								title= 'Start of Slab - GF'
								width={200}
								dataIndex= 'Start of Slab - GF'
								key= 'Start of Slab - GF'
							/>
							<Table.Column
								title= 'Start of Slab - FF'
								width={200}
								dataIndex= 'Start of Slab - FF'
								key= 'Start of Slab - FF'
							/>
							<Table.Column
								title= 'Start of Slab - SF'
								width={200}
								dataIndex= 'Start of Slab - SF'
								key= 'Start of Slab - SF'
							/>
							<Table.Column
								title= 'Start of Slab - TF'
								width={200}
								dataIndex= 'Start of Slab - TF'
								key= 'Start of Slab - TF'
							/>
							<Table.Column
								title= 'Start of Brickwork'
								width={200}
								dataIndex= 'Start of Brickwork'
								key= 'Start of Brickwork'
							/>
							<Table.Column
								title= 'Start of Services(AC, electrical or plumbing)'
								width={200}
								dataIndex= 'Start of Services(AC, electrical or plumbing)'
								key= 'Start of Services(AC, electrical or plumbing)'
							/>
							<Table.Column
								title= 'Start of Flooring'
								width={200}
								dataIndex= 'Start of Flooring'
								key= 'Start of Flooring'
							/>
							<Table.Column
								title= 'Start of Woodwork (Doors, windows etc)'
								width={200}
								dataIndex= 'Start of Woodwork (Doors, windows etc)'
								key= 'Start of Woodwork (Doors, windows etc)'
							/>
							<Table.Column
								title= 'Start of Finishing'
								width={200}
								dataIndex='Start of Finishing'
								key= 'Start of Finishing'
							/>
							<Table.Column
								title='Start of Fittings and Fixtures'
								width={200}
								dataIndex= 'Start of Fittings and Fixtures'
								key= 'Start of Fittings and Fixtures'
							/>
							<Table.Column
								title= 'Handover and Completion Certificate'
								width={200}
								dataIndex= 'Handover and Completion Certificate'
								key= 'Handover and Completion Certificate'
							/>
						</Table>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col span={24}>
						<Typography.Text><b>Report Description:</b> This report presents the site wise tentative dates of different defined Payment Milestones. The data is purely based on the reference activities defined at the back end. If there is no data, it means the dates in the reference activity of that particular milestone is not filled.</Typography.Text>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col span={24}>
						<Typography.Text><b>Report Logic:</b>Based upon the reference activities defined at the back end. If you have any query related to this report or a suggestion to improvise the report. Please Contact ruchika.singh@prithu.in</Typography.Text>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
