import React from 'react';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {getById, update, approve, cancel} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {useHistory, useParams} from 'react-router';
import {
	MaterialOrderBudgetStatus,
} from 'app/models/material-order-budget.model';
import {parseNum, toTitleCase, equalNum} from 'app/helpers';
import {
	Col,
	Row,
	Spin,
	Alert,
	Typography,
	Empty,
	Button,
	Space,
	message,
	Form,
	InputNumber,
	Input,
	Modal,
	Select,
	Badge,
	Card,
} from 'antd';

type MyParams = {
	id?: string;
};

// eslint-disable-next-line complexity
export const ViewProjectBudgetQuantityForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();
	const id = parseNum(params?.id ?? '0');
	const {loading, byIds, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.materialOrderBudget,
	);
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const materialOrder = byIds[id]
		? (byIds[id])
		: undefined;
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};
	const [refDrawingLink, setRefDrawingLink] = React.useState(
		materialOrder?.refDrawingLink ?? '',
	);
	const [budgetQuantity, setBudgetQuantity] = React.useState(
		materialOrder?.budgetQuantity ?? '',
	);
	const canApprove = equalNum(materialOrder?.nextApprovedBy, authUser?.id);
	const [showReviseModal, setShowReviseModal] = React.useState<boolean>(false);
	const [revisionReason, setRevisionReason] = React.useState<string>('');
	React.useEffect(() => {
		console.log('ViewEmailTemplateForm.componentDidMount');
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully.');
			history.goBack();
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};

	const onUpdateClicked = () => {
		const materialOrder = byIds[id];
		if (!budgetQuantity) {
			void message.error('Please input project quantity');
			return;
		}

		const approvalData = JSON.stringify({
			status: MaterialOrderBudgetStatus.PENDING,
			budgetQuantity,
			refDrawingLink,
		});
		setBudgetQuantity('');
		setRefDrawingLink('');

		dispatch(
			update(materialOrder.id, {
				approvalData,
			}),
		);
	};

	const onReviseClicked = () => {
		setShowReviseModal(true);
	};

	const handleReviseModalOk = () => {
		const materialOrder = byIds[id];
		if (materialOrder.status !== MaterialOrderBudgetStatus.CANCELLED) {
			if (!revisionReason) {
				void message.error('Please enter a reason for revision.');
				return;
			}
		}

		if (!budgetQuantity) {
			void message.error('Please input project quantity');
			return;
		}

		const approvalData = JSON.stringify({
			status: MaterialOrderBudgetStatus.PENDING,
			revisionReason,
			budgetQuantity,
			refDrawingLink,
		});

		setShowReviseModal(false);
		setRevisionReason('');
		setBudgetQuantity('');
		setRefDrawingLink('');

		dispatch(
			update(materialOrder.id, {
				approvalData,
			}),
		);
	};

	const handleReviseModalCancel = () => {
		setShowReviseModal(false);
		setRevisionReason('');
		setBudgetQuantity('');
		setRefDrawingLink('');
	};

	const onApproveClicked = () => {
		if (
			// eslint-disable-next-line no-alert
			window.confirm(
				'Are you sure you want to approve this project budget quantity',
			)
		) {
			dispatch(approve(id, {}));
		}
	};

	const onCancelClicked = () => {
		// eslint-disable-next-line no-alert
		const cancelReason = window.prompt('Please Give a Reason for Cancellation');
		if (cancelReason) {
			dispatch(cancel(id, {cancelReason}));
		}
	};

	if (!materialOrder) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	let statusColor = '';
	if (materialOrder.status === MaterialOrderBudgetStatus.PENDING) {
		statusColor = 'yellow';
	} else if (materialOrder.status === MaterialOrderBudgetStatus.APPROVED) {
		statusColor = 'green';
	} else if (materialOrder.status === MaterialOrderBudgetStatus.CANCELLED) {
		statusColor = 'Red';
	}

	return (
		<DefaultLayout currentPath={uiPaths.projectBudgetQuantityForm}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Badge.Ribbon
					style={{height: 25}}
					color={statusColor}
					text={materialOrder.status ? toTitleCase(materialOrder.status) : ''}
				>
					<Card size='small'>
						<Row className='mb-15'>
							<Col span={24}>
								<Typography.Title level={3} style={{textAlign: 'center'}}>
									Project Budget Quantity Form
								</Typography.Title>
								<Button className='mb-15' onClick={() => {
									history.goBack();
								}}>
									Back
								</Button>
								<Form {...layout} name='basic'>
									<Form.Item label={<b>Work Type*</b>} name='workType'>
										{materialOrder?.title}
									</Form.Item>
									<Form.Item
										label={<b>Measuring Unit*</b>}
										name='measuringUnit'
									>
										{materialOrder?.measuringUnit}
									</Form.Item>
									<Form.Item
										label={<b>Project Quantity*</b>}
										name='budgetQuantity'
									>
										{materialOrder?.budgetQuantity ? (
											<>
												<Space direction='vertical'>
													{materialOrder.budgetQuantity}
												</Space>
											</>
										) : (
											materialOrder.status === MaterialOrderBudgetStatus.CANCELLED ? (
												<>
													<br />
													<Alert
														message='Revising Project Budget Quantity'
														description='Please make sure you revise the project quantity carefully. Previously uploaded quantities will be removed'
														type='warning'
													/>
												</>
											) : (
												<InputNumber
													value={budgetQuantity}
													onChange={value => {
														setBudgetQuantity(value ?? 0);
													}}
												/>
											)
										)}
									</Form.Item>
									<Form.Item
										label={<b>Reference Drawing Link</b>}
										name='refDrawingLink'
									>
										{materialOrder.refDrawingLink ? (
											materialOrder.refDrawingLink
										) : (
											materialOrder.status === MaterialOrderBudgetStatus.CANCELLED ? [] : (
												<Input
													defaultValue={refDrawingLink}
													value={refDrawingLink}
													onChange={(e: any) => {
														setRefDrawingLink(String(e.target.value));
													}}
												/>
											)
										)}
									</Form.Item>
									{materialOrder?.status ? (
										materialOrder.status === MaterialOrderBudgetStatus.PENDING ? [] : (
											<Form.Item {...tailLayout}>
												<Button
													className='mb-5'
													type='primary'
													onClick={onReviseClicked}
												>
													{materialOrder.status
														=== MaterialOrderBudgetStatus.CANCELLED
														? 'Update'
														: 'Revise'}
												</Button>
											</Form.Item>
										)
									) : (
										<Form.Item {...tailLayout}>
											<Button type='primary' onClick={onUpdateClicked}>
												Update
											</Button>
										</Form.Item>
									)}
								</Form>
							</Col>
						</Row>
					</Card>
				</Badge.Ribbon>
				<Row>
					<Col span={24} className='mt-15' style={{textAlign: 'right'}}>
						{materialOrder.status === MaterialOrderBudgetStatus.PENDING
							&& canApprove ? (
								<Space>
									<Button type='primary' onClick={onApproveClicked}>
									Approve
									</Button>
									<Button type='primary' danger={true} onClick={onCancelClicked}>
									Cancel
									</Button>
								</Space>
							) : (
								[]
							)}
					</Col>
				</Row>
				<Modal
					title='Revise Project Budget Quantity'
					open={showReviseModal}
					onOk={handleReviseModalOk}
					onCancel={handleReviseModalCancel}
				>
					{materialOrder.status === MaterialOrderBudgetStatus.CANCELLED ? [] : (
						<Form.Item label='Revision Reason'>
							<Select
								value={revisionReason ?? undefined}
								style={{width: '100%'}}
								placeholder='Select a Reason for Revision'
								onChange={value => {
									setRevisionReason(value);
								}}
							>
								<Select.Option value='Change in client requirement'>
									Change in client requirement
								</Select.Option>
								<Select.Option value='Internal error from design team'>
									Internal error from design team
								</Select.Option>
								<Select.Option value='Internal error from Quantity Calculation team'>
									Internal error from Quantity Calculation team
								</Select.Option>
							</Select>
						</Form.Item>
					)}
					<Form.Item label='Project Quantity' name='budgetQuantity'>
						<InputNumber
							value={budgetQuantity}
							onChange={value => {
								setBudgetQuantity(value ?? 0);
							}}
						/>
					</Form.Item>
					<Form.Item label='Reference Drawing Link' name='refDrawingLink'>
						<Input
							value={refDrawingLink}
							onChange={(e: any) => {
								setRefDrawingLink(String(e.target.value));
							}}
						/>
					</Form.Item>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};
