import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	message,
	Spin,
	Col,
	Row,
	Typography,
	Button,
	Divider,
	Form,
	Input,
	Select,
} from 'antd';
import {save, update, reset} from './actions';
import {get as getChangeLog} from 'app/components/ChangeLog/actions';
import {useHistory, useParams} from 'react-router';
import {ChangeLogTable} from 'app/components/ChangeLog/ChangeLogTable';
import {type User, UserType} from 'app/models';
import {FileUpload} from '../Common/FileUpload';
import {checkFileErr, parseNum, toTitleCase} from 'app/helpers';
import {UserRole} from 'app/models/enums/user-role.enum';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type UploadFile} from 'app/models/upload-file';
import {UserDetailForm} from './UserDetailForm';

type ParamType = {
	userId?: string;
};

export const ViewUserForm: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<ParamType>();
	const userId = parseNum(params?.userId ?? '0');

	const {byIds, loading, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.user,
	);
	const {allIds: allChangeLogIds, byIds: changeLogByIds} = useSelector(
		(state: Types.RootState) => state.changeLog,
	);
	const {userOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);

	const [user, setUser] = React.useState<User>(
		userId && byIds?.[userId]
			? {...byIds[userId]}
			: {id: 0, name: '', email: '', username: '', panNumber: ''},
	);

	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<
	Record<string, string>
	>({});

	React.useEffect(() => {
		dispatch(reset());
		if (userId) {
			dispatch(getChangeLog({where: {changeModuleId: userId}}));
		}
	}, [userId]);

	React.useEffect(() => {
		if (dataUpdated) {
			history.push({
				pathname: `/${uiPaths.user}`,
			});
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const handleSubmit = () => {
		const data: User = {...user};
		if (fileList?.length && filePrefixKeys) {
			checkFileErr(fileList);
			const file = fileList[0];
			data.profileImage = `${filePrefixKeys[file.uid]}/${file.name}`;
		}

		// If (data.dateJoined) {
		// 	data.dateJoined = new Date(data.dateJoined).toString();
		// } else {
		// 	data.dateJoined = undefined;
		// }
		data.detail = JSON.stringify(data.userDetail);
		delete data.userDetail;

		if (!data.name || !data.username) {
			void message.error('Please enter mandatory fields.');
			return;
		}

		if (data.userType === UserType.EMPLOYEE && !data.reportingUserId) {
			void message.error('Please enter mandatory fields.');
			return;
		}

		if (!data.panNumber) {
			void message.error('Please enter mandatory fields.');
			return;
		}

		delete data.lastName;
		delete data.isAdmin;
		delete data.lastLogin;
		delete data.dateJoined;
		delete data.profileImage;

		data.empNo = user.empNo ?? '';
		data.reportingUserId = user.reportingUserId ?? 0;
		data.onSiteToken = user.onSiteToken ?? '';
		data.fcmToken = user.fcmToken ?? '';
		data.roles = user.roles ?? UserRole.NONE;
		data.isActive = true;
		if (userId) {
			delete data.password;
			dispatch(update(userId, data));
		} else {
			if (!data.password) {
				void message.error('Please enter mandatory fields.');
				return;
			}

			data.id = 0; // Dummy to workaround for id check
			dispatch(save(data));
		}
	};

	const layout = {
		labelCol: {span: 4},
		wrapperCol: {span: 20},
	};
	const tailLayout = {
		wrapperCol: {offset: 4, span: 20},
	};
	return (
		<DefaultLayout>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							{userId ? 'Edit' : 'Add New'} Prithu User
						</Typography.Title>
						<Button onClick={e => {
							history.goBack();
						}}>Back</Button>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Form {...layout} name='basic'>
							<Form.Item label='Name*'>
								<Input
									placeholder={'Enter First Name'}
									value={user.name}
									onChange={(e: any) => {
										setUser({...user, name: String(e.target.value)});
									}
									}
								/>
							</Form.Item>

							<Form.Item label='Username*'>
								<Input
									placeholder={'Enter Username'}
									value={user.username}
									onChange={(e: any) => {
										setUser({...user, username: String(e.target.value)});
									}
									}
								/>
							</Form.Item>

							{userId ? [] : (
								<Form.Item label='Password*'>
									<Input.Password
										placeholder='Enter Password'
										value={user.password}
										onChange={(e: any) => {
											setUser({...user, password: String(e.target.value)});
										}
										}
									/>
								</Form.Item>
							)}

							<Form.Item label='Email*'>
								<Input
									placeholder={'Enter Email'}
									value={user.email}
									onChange={(e: any) => {
										setUser({...user, email: String(e.target.value)});
									}
									}
								/>
							</Form.Item>

							<Form.Item label='Pan Card*'>
								<Input
									placeholder={'Enter Pan Card Number'}
									value={user.panNumber}
									onChange={(e: any) => {
										setUser({...user, panNumber: String(e.target.value)});
									}
									}
								/>
							</Form.Item>

							<Form.Item label='Profile Image'>
								<FileUpload
									single={true}
									prefix={'profile-image'}
									fileList={fileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(list, prefixKeys) => {
										setFileList(list);
										setFilePrefixKeys(prefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item
								label='Mobile Numbers'
								extra={'Enter Multiple Numbers Separated by Comma'}
							>
								<Input
									placeholder={'Enter Mobile Numbers'}
									value={user.mobileNumbers}
									onChange={(e: any) => {
										setUser({...user, mobileNumbers: String(e.target.value)});
									}
									}
								/>
							</Form.Item>

							<Form.Item label='User Type'>
								<Select
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Type'
									optionFilterProp='children'
									defaultValue={user.userType}
									onChange={value => {
										setUser({...user, userType: value as UserType});
									}
									}
									// C filterOption={(input, option) => String(option?.label).includes(input)}
								>
									{Object.values(UserType).map((type: string) => (
										<Select.Option key={type} value={type}>
											{toTitleCase(type)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item label='User Role'>
								<Select
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Role'
									optionFilterProp='children'
									defaultValue={user.roles}
									onChange={value => {
										setUser({...user, roles: value as UserRole});
									}
									}
									// C filterOption={(input, option) => String(option?.label).includes(input)}
								>
									{Object.values(UserRole).map((type: string) => (
										<Select.Option key={type} value={type}>
											{toTitleCase(type, '_')}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item label='On Site Token'>
								<Input
									placeholder={'Enter on Site Token'}
									value={user.onSiteToken}
									onChange={(e: any) => {
										setUser({...user, onSiteToken: String(e.target.value)});
									}
									}
								/>
							</Form.Item>

							{user.userType === UserType.EMPLOYEE ? (
								<>
									<Form.Item
										label='Employee Number'
										extra={
											'This value will be verified and it will not reflect now.'
										}
									>
										<Input
											placeholder={'Enter Employee Number'}
											value={user.empNo}
											onChange={(e: any) => {
												setUser({...user, empNo: String(e.target.value)});
											}
											}
										/>
									</Form.Item>

									<Form.Item
										label='Reporting Manager*'
										extra={
											'This value will be verified and it will not reflect now.'
										}
									>
										<Select
											showSearch={true}
											style={{width: 250}}
											placeholder='Select a User'
											optionFilterProp='children'
											defaultValue={
												user.reportingUserId
													? userOptions.find(
														opt =>
															parseNum(opt.value) === user.reportingUserId,
													)?.label
													: undefined
											}
											onChange={value => {
												setUser({
													...user,
													reportingUserId: parseNum(String(value)),
												});
											}
											}
											// C filterOption={(input, option) => String(option?.label).includes(input)}
										>
											{userOptions.map(
												(option: FilterOptionItem, ix: number) => (
													<Select.Option key={ix} value={option.value}>
														{option.label}
													</Select.Option>
												),
											)}
										</Select>
									</Form.Item>
								</>
							) : (
								[]
							)}

							<UserDetailForm
								currentValue={user.userDetail}
								onValueChange={value => {
									setUser({...user, userDetail: value});
								}
								}
							/>

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={handleSubmit}>
                  Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>

				<Divider>Changes Log</Divider>
				<ChangeLogTable
					dataSource={allChangeLogIds.map(id => changeLogByIds[id])}
				/>
				<br />
				<br />
			</Spin>
		</DefaultLayout>
	);
};
