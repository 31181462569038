import {type ProcessChecklistQuestion} from 'app/models/process-checklist-question.model';
import {type ModuleName} from 'app/models/module.model';
import {type UserRole} from 'app/models/enums/user-role.enum';
import {type ProcessChecklistResponse} from 'app/models/process-checklist-response.model';

export type ProcessChecklist = {
	id: string;
	title: string;
	description?: string;
	moduleName?: ModuleName;
	onActionType?: ProcessChecklistOnActionType;
	inspectionRole?: UserRole;
	processChecklistQuestions?: ProcessChecklistQuestion[];
	processChecklistResponse?: ProcessChecklistResponse;
};

export enum ProcessChecklistOnActionType {
	BEFORE_START = 'BEFORE_START',
	IN_PROGRESS = 'IN_PROGRESS',
	AFTER_COMPLETION = 'AFTER_COMPLETION',
}
