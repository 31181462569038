import {type User} from 'app/models';
import {type UserRole} from 'app/models/enums/user-role.enum';

export type ChangeLog = {
	id: number;
	moduleName: string;
	changeModuleId?: number;
	change: string;
	status?: ChangeLogStatus;
	approver?: UserRole;
	cancelReason?: string;
	createdAt?: string;
	updatedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	changeValue?: ChangeValue;
	createdUser?: User;
	changeModuleUser?: User;
	user?: User;
};

export enum ChangeLogStatus {
	REQUESTED = 'REQUESTED',
	APPROVED = 'APPROVED',
	CANCELED = 'CANCELED',
}

export type ChangeValue = {
	field: string;
	label: string;
	inputType: ChangeInputType;
	idType?: ChangeIdType;
	value: string;
};

export enum ChangeInputType {
	STRING = 'STRING',
	ID = 'ID',
	NUMBER = 'NUMBER',
}

export enum ChangeIdType {
	USER = 'USER',
}
