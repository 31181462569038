import type * as actions from './actions';
import {actionTypes} from './actions';
import {loadState} from 'app/store/helpers';
import {
	getFailureActionType,
	getRequestActionType,
	getSuccessActionType,
} from 'app/store/action-type.util';
import {type ProjectHandoverActivity} from 'app/models/project-handover-activity.model';
import {logoutSuccessful} from '../Common/auth-actions';
import {type FilterDataType} from '../Common/Filter/FilterView';

export type MyAction = typeof actions;

export type MyState = Readonly<{
	byIds: Record<ProjectHandoverActivity['id'], ProjectHandoverActivity>;
	allIds: Array<ProjectHandoverActivity['id']>;
	totalCount: number;
	pageType?: string;
	currentPos: number;
	perPageSize: number;
	filterValue: FilterDataType;
	loading: boolean;
	dataUpdated: boolean;
	errorMessage?: string;
}>;

const initialState: MyState = {
	byIds: {},
	allIds: [],
	totalCount: 0,
	pageType: undefined,
	currentPos: 1,
	perPageSize: 10,
	filterValue: {},
	loading: false,
	dataUpdated: false,
	errorMessage: undefined,
};

const projectHandoverActivity = (
	// eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-unsafe-assignment
	state: MyState = loadState('projectHandoverActivity', initialState),
	action: any,
): MyState => {
	switch (action.type) {
		case logoutSuccessful:
			return initialState;
		case actionTypes.apiFilter:
			return {...state, filterValue: action.payload as FilterDataType};
		case actionTypes.initPage:
			return {
				...state,
				...{
					loading: false,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getRequestActionType(actionTypes.getApi):
		case getRequestActionType(actionTypes.createGetApi):
		case getRequestActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: true,
					dataUpdated: false,
					errorMessage: undefined,
				},
			};
		case getFailureActionType(actionTypes.getApi):
		case getFailureActionType(actionTypes.createGetApi):
		case getFailureActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					errorMessage:
            (action.payload?.response?.data?.error?.message as string)
            ?? undefined,
					loading: false,
					dataUpdated: false,
				},
			};
		case getSuccessActionType(actionTypes.getApi): {
			const items = action.payload.data as ProjectHandoverActivity[];
			return {
				...state,
				...{
					allIds: items.map(({id}) => id),
					byIds: items.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.createGetApi): {
			const list = action.payload.data as ProjectHandoverActivity[];
			return {
				...state,
				...{
					allIds: list.map(({id}) => id),
					byIds: list.reduce<MyState['byIds']>((byIds, event) => {
						byIds[event.id] = event;
						return byIds;
					}, {}),
					loading: false,
					dataUpdated: false,
				},
			};
		}

		case getSuccessActionType(actionTypes.updateApi):
			return {
				...state,
				...{
					loading: false,
					dataUpdated: true,
					errorMessage: undefined,
				},
			};
		default:
			return state;
	}
};

export default projectHandoverActivity;
