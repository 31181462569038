import React from 'react';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from 'app/components/Layout/DefaultLayout';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';
import {ViewProcessChecklistResponse} from './ViewProcessChecklistResponse';

export const ViewDesignChecklistResponse: React.FC = () => (
	<DefaultLayout currentPath={uiPaths.viewDesignChecklistResponse}>
		<ViewProcessChecklistResponse
			title={'Design Schedule Checklist Response'}
			projectProcessType={ProjectProcessType.DESIGN}
		/>
	</DefaultLayout>
);
