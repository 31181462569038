import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	Button,
	Col,
	Empty,
	message,
	Row,
	Space,
	Spin,
	Table,
	Typography,
} from 'antd';
import {type Site, SiteStatus, type SiteStage} from 'app/models';
import {CSVLink} from 'react-csv';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {FilterFormType} from 'app/models/ui-filter';
import {
	toTitleCase,
	formatDateFunction,
} from 'app/helpers';
import {get, onPaginationChange, count, setFilterValue} from './actions';
import {PaginationView} from '../Common/Pagination/PaginationView';

export const ViewAllSite: React.FC = () => {
	const dispatch = useDispatch();
	const {
		byIds,
		allIds,
		loading,
		errorMessage,
		dataUpdated,
		totalCount,
		currentPos,
		perPageSize,
		filterValue,
	} = useSelector((state: Types.RootState) => state.site);

	React.useEffect(() => {
		filterData(filterValue);
	}, [filterValue]);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData(filterValue);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['projectNumber DESC'],
		};

		if (f.notLive) {
			filter.where.isLive = false;
		} else {
			filter.where.isLive = true;
		}

		if (f.name) {
			filter.where.name = {like: `%25${f.name as string}%25`};
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const getCsvData = (data: Site[]) => [
		[
			'Site Name',
			'Address',
			'Site Status',
			'Site Stage',
			'Project Type',
			'Contract Signing Date',
			'Design Brief Date',
			'Date of Floor Plan Finalization',
			'Date of Plot Handover from the Client',
			'Barricading Completion Actual Date',
			'Raft/Footing Actual Completion Date',
			'Mumty Casting Actual Completion Date',
			'Windows Delivery Actual Start Date',
			'Kitchen Installation Completion Date',
			'CC Completion Actual Date',
			'Client Query Dropdown - Finance',
			'Client Query Dropdown - Design ',
			'Client Query Dropdown - Approvals',
			'Client Query Dropdown - Construction',
			'Project Query Dropdown - Finance',
			'Project Query Dropdown - Design ',
			'Project Query Dropdown - Approvals',
			'Project Query Dropdown - Construction',
			'Project Query Dropdown - Procurement',
			'Project Query Dropdown - Quality Calculation',
			'Project Query Dropdown - Contractor Appointment Billing and Termination',
		],
		...data.map(d => [
			d.name,
			d.address,
			toTitleCase(d.status),
			toTitleCase(d.stage),
			d.projectType,
			formatDateFunction(d.contractSigningDate ?? ''),
			formatDateFunction(d.designBriefDate ?? ''),
			formatDateFunction(d.floorPlanDate ?? ''),
			formatDateFunction(d.plotHandoverDate ?? ''),
			formatDateFunction(d.barricadingCompletionDate ?? ''),
			formatDateFunction(d.rfCompletionDate ?? ''),
			formatDateFunction(d.mumtyCastingDate ?? ''),
			formatDateFunction(d.windowsDeliveryDate ?? ''),
			formatDateFunction(d.kitchenInstallationDate ?? ''),
			formatDateFunction(d.ccCompletionDate ?? ''),
			d.cqFinance,
			d.cqDesign,
			d.cqApprovals,
			d.cqConstruction,
			d.pqFinance,
			d.pqDesign,
			d.pqApprovals,
			d.pqConstruction,
			d.pqProcurement,
			d.pqQualityCalculation,
			d.pqContractorAppointmentBT,
		]),
	];

	const statusOptions = Object.values(SiteStatus).map(role => ({
		value: String(role),
		label: toTitleCase(role as string) ?? '',
	}));

	const dataSource = allIds && byIds ? allIds.map(id => byIds[id]) : [];

	const uiFilters = [
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Site Name',
			label: 'Site Name',
			defaultValue: filterValue?.name,
		},
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: filterValue?.status,
		},
		{
			filterKey: 'notLive',
			formType: FilterFormType.BOOLEAN,
			label: 'Not Live',
			defaultValue: filterValue?.notLive,
		},
	];

	const csvData: any[] = getCsvData(dataSource);

	return (
		<DefaultLayout currentPath={uiPaths.site}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
              Sites
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
							filterData(f);
						}} />
					</Col>
				</Row>

				<Row>
					<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
						<Space>
							<Link
								to={{
									pathname: `/${uiPaths.createNewSite}`,
								}}
							>
								<Button type='primary' id={'CreateSiteButton'}>
                  Create New Site
								</Button>
							</Link>
							<CSVLink
								data={csvData}
								filename={'Sites.csv'}
								className='btn btn-primary'
							>
								<Button type='primary'>Download</Button>
							</CSVLink>
						</Space>
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								pageSizeOptions={[
									'10',
									'50',
									'100',
									'500',
									'1000',
									'5000',
								]}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
								scroll={{x: 600, y: 500}}
							>
								<Table.Column
									title='Site Name'
									dataIndex='name'
									key='name'
									render={(name: string, site: Site) => (
										<Link
											to={{
												pathname: `/${uiPaths.siteDetail.replace(
													':siteId',
													site.id.toString(),
												)}`,
											}}
										>
											{name}
										</Link>
									)}
								/>
								<Table.Column
									title='Status'
									dataIndex='status'
									key='status'
									render={(status?: SiteStatus) => toTitleCase(status, '_')}
								/>
								<Table.Column
									title='Stage'
									dataIndex='stage'
									key='stage'
									render={(stage?: SiteStage) => toTitleCase(stage, '_')}
								/>
								<Table.Column
									title='Action'
									dataIndex='action'
									key='action'
									render={(text: string, site: Site) => (
										<Space>
											<Link
												to={{
													pathname: `/${uiPaths.editSite.replace(
														':siteId',
														`${site.id}`,
													)}`,
												}}
											>
												<Button type='primary' size='small' id={'Edit'}>
                          Edit
												</Button>
											</Link>
										</Space>
									)}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
