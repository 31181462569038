import React from 'react';
import {
	type ModuleName,
	type QueryParentType,
	queryParentTypeLabels,
	type Site,
	QueryStatus,
	type User,
} from 'app/models';
import {formatDateFunction, parseNum, toSubstring, toTitleCase} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {Col, Empty, Row, Spin, Table} from 'antd';
import type Types from 'MyTypes';
import {count, get, onAssignedPaginationChange, setAssignedFilterValue} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {FilterFormType, type FilterOptionItem, type UiFilter} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {getQueryDateRange} from 'app/query_helpers';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {useHistory} from 'react-router';

type Props = {
	module?: ModuleName;
	siteId?: number;
	workStatusReportId?: number;
	parentId?: string;
	parentType?: QueryParentType;
};

export const ViewAssignedToMeQuery: React.FC<Props> = ({module, siteId, workStatusReportId, parentId, parentType}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {assignedFilterValue, loading, totalCount, assignedCurrentPos, assignedPerPageSize, byIds, allIds} = useSelector((state: Types.RootState) => state.query);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {allSites: sites} = useSelector((state: Types.RootState) => state.userPermission);

	const statusOptions: FilterOptionItem[] = Object.values(QueryStatus)
		.map(role => ({value: String(role), label: toTitleCase(role as string, '_') ?? ''}));

	React.useEffect(() => {
		if (assignedFilterValue) {
			filterData(assignedFilterValue);
		} else {
			filterData();
		}
	}, [assignedFilterValue]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {
				and: [
					{
						or: [
							{
								forWhom: authUser?.roles?.replace(' & ', ''), /* .replace() is Hack For '_MD & CEO Role' TODO: Remove */
								siteId: {inq: sites.map((site: Site) => site.id)},
							},
							{assignedTo: authUser?.id},
						],
					},
				],
			},
			limit: perPageSize,
			skip: (currentPos ? perPageSize * (currentPos - 1) : 0),
			order: [
				'createdAt DESC',
			],
		};

		if (workStatusReportId) {
			filter.where.workStatusReportId = workStatusReportId;
		}

		if (parentId) {
			filter.where.parentId = parentId;
		}

		if (parentType) {
			filter.where.parentType = parentType;
		}

		if (siteId) {
			filter.where.siteId = parseNum(siteId);
		} else if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.date1 && f.date2) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(...getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt'));
		}

		if (f.userId) {
			filter.where.createdBy = f.userId;
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		dispatch(setAssignedFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const queries = allIds?.length ? allIds.map((id: number) => byIds[id]) : [];
	const siteOptions: FilterOptionItem[] = sites.map(site => ({label: site.name, value: String(site.id)}));

	const columns = [
		{
			title: 'Point Raised',
			width: 400,
			dataIndex: 'content',
			render: (content: string) => toSubstring(content, 80, '...'),
		},
		{
			title: 'Site',
			dataIndex: 'site',
			render: (site: Site) => site ? site.name : '',
		},
		{
			title: 'Raised At',
			width: 100,
			dataIndex: 'createdAt',
			render: (createdAt: string) => formatDateFunction(createdAt),
		},
		{
			title: 'Raised By',
			width: 100,
			dataIndex: 'createdUser',
			render: (createdUser: User) => createdUser ? createdUser.name : '',
		},
		{
			title: 'Raised From',
			width: 100,
			dataIndex: 'parentType',
			render: (parentType: QueryParentType) => parentType ? queryParentTypeLabels[parentType] : '',
		},
		{
			title: 'Status',
			width: 100,
			dataIndex: 'status',
			render: (status: string) => toTitleCase(status),
		},
	];

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status',
			label: 'Status',
			defaultValue: assignedFilterValue?.status,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [assignedFilterValue?.date1 as string, assignedFilterValue?.date2 as string],
		},
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: assignedFilterValue?.siteId,
		},
	];

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row>
				<Col span={24}>
					<FilterView
						uiFilters={uiFilters}
						onSubmit={(f: FilterDataType) => {
							filterData(f);
						}}
					/>
					<br />
				</Col>
			</Row>

			{totalCount ? (
				<Row>
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={assignedCurrentPos ?? 1}
							perPageSize={assignedPerPageSize ?? 10}
							filterValue={assignedFilterValue}
							filterData={filterData}
							onPaginationChange={onAssignedPaginationChange}
						/>
						<Table
							size={'small'}
							pagination={false}
							dataSource={queries}
							columns={columns}
							scroll={{x: 1000, y: 500}}
							onRow={query => ({
								onClick() {
									history.push({
										pathname: `/${uiPaths.queryDetail.replace(':queryId', String(query.id))}`,
									});
								},
							})}
						/>
					</Col>
				</Row>
			) : (
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			)}
		</Spin>
	);
};
