import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@changeLog/API_FILTER',
	paginationChange: '@changeLog/PAGINATION_CHANGE',
	getCountApi: '@changeLog/GET_COUNT_API',
	getApi: '@changeLog/GET_API',
	updateApi: '@changeLog/UPDATE_API',
};

const url = lbBaseUrl + '/change-logs';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const onPaginationChange = (currentPos: number, perPageSize: number) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(`${url}/count?where=${JSON.stringify(where)}`, getHeaders(true)),
	meta: {process: true},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const approve = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/approve`, data, getHeaders(true)),
	meta: {process: true},
});

export const reject = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/reject`, data, getHeaders(true)),
	meta: {process: true},
});
