import {type ProjectProcessSchedule} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {type ProcessChecklistQuestionResponse} from 'app/models/process-checklist-question-response.model';
import {type DrawingSchedule} from 'app/models/drawing-schedule.model';
import {type ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {type User} from './user.model';

export type ProcessChecklistResponse = {
	id?: number;
	siteId: number;
	projectProcessScheduleId?: number;
	drawingScheduleId?: number;
	processChecklistId: string;
	status?: ProcessChecklistResponseStatus;
	projectProcessType: ProjectProcessType;
	cancelReason?: string;
	verifiedAt?: string;
	verifiedBy?: number;
	updatedAt?: string;
	updatedBy?: number;
	submittedAt?: string;
	submittedBy?: number;
	projectProcessSchedule?: ProjectProcessSchedule;
	drawingSchedule?: DrawingSchedule;
	processChecklist?: ProcessChecklist;
	questionResponses?: ProcessChecklistQuestionResponse[];
	nextApprovedUsers?: User[];
	updatedByUser?: User;
};

export enum ProcessChecklistResponseStatus {
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	IN_PROGRESS = 'IN_PROGRESS',
	SUBMITTED = 'SUBMITTED',
}

