import {type Drawing} from './drawing.model';
import {type ProjectProcessMaster} from 'app/components/ProjectProcessMaster/project-process-master';
import {type Site} from 'app/models/site.model';
import {type ProcessChecklistResponse} from 'app/models/process-checklist-response.model';

export type DrawingSchedule = {
	id: number;
	status?: DrawingStatusType;
	scope?: DrawingScopeType;
	drawingType?: DrawingType;
	content: string;
	revisionReason?: string;
	revisionCount?: number;
	siteId: number;
	scheduleDate?: string;
	statusTabId: string;
	projectProcessMasterId?: number;
	createdBy?: number;
	updatedBy?: number;
	createdAt?: string;
	updatedAt?: string;
	completedAt?: string;
	revisedAt?: string;
	drawings: Drawing[];
	projectProcessMaster?: ProjectProcessMaster;
	site?: Site;
	checklistResponses?: ProcessChecklistResponse[];
	maxDelay: number;
};

export enum DrawingStatusType {
	COMPLETED = 'COMPLETED',
	IN_PROGRESS = 'IN_PROGRESS',
	REVISED = 'REVISED',
	STOPPED = 'STOPPED',
}

export enum DrawingScopeType {
	IN_SCOPE = 'IN_SCOPE',
	SCOPE_NOT_DECIDED = 'SCOPE_NOT_DECIDED',
	NOT_IN_SCOPE = 'NOT_IN_SCOPE',
}

export enum DrawingType {
	REFERENCE_DRAWING = 'REFERENCE_DRAWING',
	DRAWING_STATUS = 'DRAWING_STATUS',
	DRAWING_SCHEDULE = 'DRAWING_SCHEDULE',
}
