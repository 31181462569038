import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {Alert, Col, Row, Spin, Typography} from 'antd';
import {type Notification} from 'app/models';
import {NotificationBlock} from './NotificationBlock';
import {uiPaths} from 'app/constants';
import {
	remove,
	getForMe,
} from './actions';
import type Types from 'MyTypes';

export const ViewNotification: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, errorMessage, items = []} = useSelector((state: Types.RootState) => state.notification);

	React.useEffect(() => {
		dispatch(getForMe());
	}, []);

	const handleRemoveClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to remove this notification')) {
			dispatch(remove(id));
		}
	};

	return (
		<DefaultLayout currentPath={uiPaths.notification}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>Notifications</Typography.Title>
					</Col>
				</Row>
				{errorMessage && (
					<Row>
						<Col span={24}>
							<Alert
								message='Error'
								description={errorMessage}
								type='error'
								showIcon={true}
								closable={true}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col span={24}>
						<Row>
							{items.map((item: Notification, ix: number) => (
								<NotificationBlock
									key={ix}
									notification={item}
									canRemove={false}
									handleRemoveClick={handleRemoveClick}
								/>
							))}
						</Row>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
