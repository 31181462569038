import React from 'react';
import {Form, Select, Divider, Input} from 'antd';
import {type ProcessChecklistQuestion} from 'app/models/process-checklist-question.model';
import {type User, UserType} from 'app/models';
import {FileUpload} from 'app/components/Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import {type ProcessChecklistQuestionResponse} from 'app/models/process-checklist-question-response.model';
import {ViewParseLink} from 'app/components/Common/ViewParseLink';
import {notApplicable} from 'app/constants';
import {removeAttachment} from './actions';
import {useDispatch} from 'react-redux';
import {AttachmentsPreviewBlock} from '../ProjectProcessSchedule/AttachmentsPreviewBlock';

type Pops = {
	canWrite?: boolean;
	authUser?: User;
	sno: number;
	checklistResponseId?: number;
	question: ProcessChecklistQuestion;
	questionResponse?: ProcessChecklistQuestionResponse;
	onResponseChange: (response: ProcessChecklistQuestionResponse) => void;
};

// eslint-disable-next-line complexity
export const ChecklistQuestionResponseForm: React.FC<Pops> = ({
	canWrite,
	sno,
	authUser,
	checklistResponseId,
	question,
	questionResponse,
	onResponseChange,
}) => {
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});

	const dispatch = useDispatch();

	const onFileUploadChange = (fileList: UploadFile[], filePrefixKeys: Record<string, string>) => {
		setFileList(fileList);
		setFilePrefixKeys(filePrefixKeys);
		const supportingPhotos = fileList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));
		if (questionResponse) {
			onResponseChange({...questionResponse, ...{supportingPhotos}} as ProcessChecklistQuestionResponse);
		} else {
			onResponseChange({
				processChecklistQuestionId: question?.id,
				processChecklistResponseId: checklistResponseId,
				supportingPhotos,
			} as ProcessChecklistQuestionResponse);
		}
	};

	const onAnswerChange = (response?: string) => {
		if (questionResponse) {
			onResponseChange({...questionResponse, ...{response, remarks: undefined}} as ProcessChecklistQuestionResponse);
		} else {
			onResponseChange({
				processChecklistQuestionId: question?.id,
				processChecklistResponseId: checklistResponseId,
				response,
				remarks: undefined,
			} as ProcessChecklistQuestionResponse);
		}
	};

	const supportingPhotos = questionResponse?.supportingPhotos?.length
		? questionResponse.supportingPhotos.filter(photo => photo.id) : [];

	const handleImageDelete: (id: number) => void = (id => dispatch(removeAttachment(id)));

	return (
		<>
			<Form.Item>
				<b>{sno}. {question.content}</b>
			</Form.Item>
			{authUser?.userType === UserType.CLIENT ? [] : (
				<Form.Item label={<>{'Expected Answer'}</>}>
					{question.requiredResponse}
				</Form.Item>
			)}
			{canWrite ? (
				<>
					<Form.Item label={<>{'Your Answer'}</>}>
						<Select
							style={{width: '100%'}}
							onChange={value => {
								onAnswerChange(String(value));
							}}
							defaultValue={questionResponse?.response ?? ''}
							value={questionResponse?.response ?? ''}
						>
							{question.responseOptions?.map((opt: string, ix: number) =>
								<Select.Option key={ix} value={opt}>{opt}</Select.Option>,
							)}
							<Select.Option key={-1} value={notApplicable}>{notApplicable}</Select.Option>
						</Select>
					</Form.Item>
					{questionResponse?.response === notApplicable ? (
						<Form.Item label={<>{'Please Provide a Reason'}</>}>
							<Input.TextArea
								placeholder={'Enter Reason for not applicable'}
								value={questionResponse?.remarks ?? ''}
								onChange={(e: any) => {
									onResponseChange({...questionResponse, ...{remarks: String(e.target.value)}} as ProcessChecklistQuestionResponse);
								}
								}
							/>
						</Form.Item>
					) : []}
					{questionResponse?.response && question.requiredResponse && question.correctiveMeasures?.[questionResponse?.response] && questionResponse?.response !== question.requiredResponse ? (
						<Form.Item label={<>{'Corrective Measures/Steps'}</>}>
							{question.correctiveMeasures[questionResponse?.response]}
						</Form.Item>
					) : []}
					{questionResponse?.response === notApplicable ? [] : (
						<Form.Item label={<>{`Uploading Supporting Photos ${question.isPhotoRequired ? '(required)' : '(optional)'}`}</>}>
							<FileUpload
								label={'Click to Upload Photos'}
								prefix={'project-checklist-response'}
								fileList={fileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={onFileUploadChange}
							/>
						</Form.Item>
					)}
				</>
			) : (
				<>
					<Form.Item label={<>{'Submitted Answer'}</>}>
						{questionResponse?.response ?? ''}
					</Form.Item>
					{questionResponse?.remarks ? (
						<Form.Item label={<>{'Remarks'}</>}>
							{questionResponse?.remarks}
						</Form.Item>
					) : []}
				</>
			)}
			{authUser?.userType === UserType.CLIENT ? [] : (
				<Form.Item label={<>{'Reference Documents'}</>}>
					{question?.referenceDocuments ? (
						<ViewParseLink parseLink={question.referenceDocuments} />
					) : []}
				</Form.Item>
			)}
			{authUser?.userType === UserType.CLIENT ? [] : (
				<Form.Item label={<>{'Explanation Content'}</>}>
					{question?.referenceDocumentStr ?? []}
				</Form.Item>
			)}

			<Form.Item label={<>Supporting Photos</>}>
				{supportingPhotos.length ? (
					<AttachmentsPreviewBlock
						attachments={supportingPhotos}
						canDelete={canWrite}
						onDelete={id => {
							handleImageDelete(id);
						}}
					/>
				) : []}
			</Form.Item>
			<Divider />
		</>
	);
};

