import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {lbBaseUrl} from 'app/constants';

export const actionTypes = {
	paginationChange: '@notifications/PAGINATION_CHANGE',
	getCountApi: '@notifications/GET_COUNT_API',
	getApi: '@notifications/GET_API',
	getNewCountApi: '@notifications/GET_NEW_COUNT_API',
	updateAllApi: '@notifications/UPDATE_ALL_API',
	deleteApi: '@notifications/DELETE_API',
};

const url = lbBaseUrl + '/notifications';

export const onPaginationChange = (currentPos: number, perPageSize: number) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}?filter=${JSON.stringify(filter)}`, getHeaders(true)),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(`${url}/count?where=${JSON.stringify(where)}`, getHeaders(true)),
	meta: {process: true},
});

export const getForMe = () => ({
	type: actionTypes.getApi,
	payload: axios.get(`${url}/me`, getHeaders(true)),
	meta: {process: true},
});

export const getNewCountForMe = () => ({
	type: actionTypes.getNewCountApi,
	payload: axios.get(`${url}/me/new/count`, getHeaders(true)),
	meta: {process: true},
});

export const updateAll = (data: any, where: any) => ({
	type: actionTypes.updateAllApi,
	payload: axios.patch(`${url}?where=${JSON.stringify(where)}`, data, getHeaders(true)),
	meta: {process: true},
});

export const remove = (id: number) => ({
	type: actionTypes.deleteApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});
