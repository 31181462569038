import {type ProjectProcessMaster, type ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {type Attachment, type Site} from 'app/models';
import {type ProcessChecklistResponse} from 'app/models/process-checklist-response.model';

export type ProjectProcessSchedule = {
	id: number;
	siteId: number;
	projectProcessMasterId: number;
	projectedStartDate?: string;
	projectedEndDate?: string;
	startDate?: string;
	endDate?: string;
	status?: string;
	projectProcessType: ProjectProcessType;
	startedAt?: string;
	completedAt?: string;

	projectProcessMaster?: ProjectProcessMaster;
	site?: Site;
	checklistResponses?: ProcessChecklistResponse[];
	startedPhotos?: Attachment[];
	inProgressPhotos?: Attachment[];
	completedPhotos?: Attachment[];
	warrantyDocuments?: Attachment[];
};

export enum ProjectScheduleStatus {
	STARTED = 'Started',
	NOT_YET_STARTED = 'Not Yet Started',
	COMPLETED = 'Completed',
	PARTIAL_COMPLETED = 'Completed but Minor Work Remains',
	WORK_ON_HOLD = 'Work on Hold',
	STOPPED = 'Stopped',
}

export enum ProjectScheduleStatusOptions {
	STARTED = 'Started',
	COMPLETED = 'Completed',
	NOT_YET_STARTED = 'Not Yet Started',

}
