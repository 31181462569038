
import {Select} from 'antd';
import React from 'react';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type SelectProps} from 'antd/es/select';

export type SiteSelectProps = {
	siteOptions: FilterOptionItem[];
	allOption?: boolean;
	noneOption?: boolean;
	order?: string;
} & Omit<SelectProps<FilterOptionItem | FilterOptionItem[] | string>, 'options' | 'children'>;

export const SiteSelect: React.FC<SiteSelectProps> = ({
	siteOptions,
	allOption,
	noneOption,
	order,
	...props
}) => {
	if (order === 'DESC') {
		siteOptions.sort((a, b) => b.label > a.label ? 1 : -1);
	} else {
		siteOptions.sort((a, b) => a.label > b.label ? 1 : -1);
	}

	return (
		<Select
			showSearch={true}
			optionFilterProp='children'
			// C filterOption={(input, option) => String(option?.label).includes(input)}
			{...props}
		>
			{allOption ? <Select.Option key={-1} value={''}>{'All'}</Select.Option> : []}
			{siteOptions?.length ? siteOptions.map((option: FilterOptionItem, ix: number) =>
				<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
			) : []}
			{noneOption ? <Select.Option key={-2} value={''}>{'None'}</Select.Option> : []}
		</Select>
	);
};
